import * as React from "react"

const MaintenanceIcon = (props) => (
  <svg
    style={{
      enableBackground: "new 0 0 30 30",
    }}
    viewBox="0 0 30 30"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m16.758 21.401 4.819 4.819a2.662 2.662 0 1 0 3.764-3.765l-4.819-4.819-3.764 3.765zM23.998 11.003l-3.201-.8-.8-3.201 3.706-3.706a6.17 6.17 0 0 0-6.24 1.512c-2.41 2.41-1.639 5.547.772 7.957 2.41 2.41 5.547 3.182 7.957.771a6.164 6.164 0 0 0 1.512-6.239l-3.706 3.706zM12.5 11.5 9 8 8 5 4 3 2 5l2 4 3 1 3.5 3.5z" />
    <path d="m17.879 8.879-13 13a3 3 0 1 0 4.243 4.243l13-13-4.243-4.243zM7 25a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
  </svg>
)

export default MaintenanceIcon
