import {
    CREATE_NEW_TASK,
    CREATE_TEMPLATE_TASK, DELETE_TASK_IN_TEMPLATE,
    DELETE_TEMPLATE,
    GET_ALL_MASTER_TEMPLATE, GET_ALL_TASK_BY_TEMPLATE_ID,
    SEARCH_MASTER_TEMPLATE,
    UPDATE_TEMPLATE
} from "../../services/template";
import {createAction} from "../../utils/reducer/reducerUtil";
import {EMPTY_STRING, FAILED, NO_STATEMENT, SUCCESS, TEMPLATE_ACTION_TYPES} from "./template.types";
import {handleClearStateGlobal, handleLoading} from "../global/global.action";
import { GLOBAL_ACTION_TYPES } from "../global/global.types";
import { STATUS_TYPE } from "../../utils/constant/constants";

export const handleGetAllMasterTemplate = (size, page) => async (dispatch) => {
    dispatch(handleLoading(true));

    await GET_ALL_MASTER_TEMPLATE(size, page)
        .then(res => {
            dispatch(handleLoading(false));
            dispatch(createAction(TEMPLATE_ACTION_TYPES.GET_ALL_MASTER_TEMPLATE, res));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED));
        });
};

export const handleClearAllMasterTemplate = () => async (dispatch) => {
    dispatch(createAction(TEMPLATE_ACTION_TYPES.CLEAR_ALL_MASTER_TEMPLATE));
};

export const handleSearchMasterTemplate = (q, page, size) => async (dispatch) => {
    dispatch(handleLoading(true));

    await SEARCH_MASTER_TEMPLATE(q, page, size)
        .then(res => {
            dispatch(handleLoading(false));
            dispatch(createAction(TEMPLATE_ACTION_TYPES.GET_ALL_MASTER_TEMPLATE, res));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED));
        });
};

export const handleCreateMasterTemplateTask = (tName, page) => async (dispatch) => {
    const body = {
        template: {
            name: tName
        },
        taskRequests: []
    }
    await CREATE_TEMPLATE_TASK(body)
        .then(() => {
            dispatch(createAction(TEMPLATE_ACTION_TYPES.CREATE_TEMPLATE));
            dispatch(createAction(TEMPLATE_ACTION_TYPES.ACTION_CREATE_STATUS_TEMPLATE, SUCCESS));
        })
        .catch(e => {
            dispatch(createAction(TEMPLATE_ACTION_TYPES.ACTION_CREATE_STATUS_TEMPLATE, FAILED));
        });
};

export const handleRemoveTemplate = (id) => (dispatch) => {
    DELETE_TEMPLATE(id)
        .then(() => {
            dispatch(createAction(TEMPLATE_ACTION_TYPES.ACTION_REMOVE_STATUS_TEMPLATE, SUCCESS));
            dispatch(handleClearStateGlobal());
        })
        .catch(e => {
            dispatch(createAction(TEMPLATE_ACTION_TYPES.ACTION_REMOVE_STATUS_TEMPLATE, FAILED));
            dispatch(handleClearStateGlobal());
        })
};

export const handleEditTemplate = (id, tName, page) => (dispatch) => {
    const body = {
        template: {
            name: tName
        },
        taskRequests: []
    }
    UPDATE_TEMPLATE(id, body)
        .then(res => {
            dispatch(handleClearStateGlobal());
            dispatch(createAction(TEMPLATE_ACTION_TYPES.ACTION_UPDATE_STATUS_TEMPLATE, SUCCESS));
        })
        .catch((e) => {
            dispatch(createAction(TEMPLATE_ACTION_TYPES.ACTION_UPDATE_STATUS_TEMPLATE, FAILED));
            dispatch(handleClearStateGlobal())
        })
};

export const handleClearTaskByTemplateId = () => dispatch => {
    dispatch(createAction(TEMPLATE_ACTION_TYPES.CLEAR_TASK_BY_TEMPLATE_ID));
}

export const handleClearAllData = () => dispatch => {
    dispatch(createAction(TEMPLATE_ACTION_TYPES.CLEAR_ALL_DATA));
}

export const handleGetTaskByTemplateId = (id) => (dispatch) => {
    GET_ALL_TASK_BY_TEMPLATE_ID(id)
        .then(res => {
            dispatch(createAction(TEMPLATE_ACTION_TYPES.GET_ALL_TASK_BY_TEMPALTE_ID, res));
            dispatch(handleClearStateGlobal());
        })
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED));
            dispatch(handleClearStateGlobal());
        });
};

export const handleClearTask = () => (dispatch) => {
    dispatch(createAction(TEMPLATE_ACTION_TYPES.REMOVE_TASK));
}

export const handleCreateNewTask = (tName, idTemplate) => (dispatch) => {
    const body = {
        name: tName
    };

    CREATE_NEW_TASK(body)
        .then(res => {
            const bodyUpdateTemplate = {
                taskRequests: [res.id]
            }

            UPDATE_TEMPLATE(idTemplate, bodyUpdateTemplate)
                .then(() => {
                    dispatch(handleGetTaskByTemplateId(idTemplate));
                })
                .catch(() => {});
        })
        .catch(() => {});
};

export const handleDeleteTaskInTamplate = (idTemplate, idTask) => (dispatch) => {
    DELETE_TASK_IN_TEMPLATE(idTemplate, idTask)
        .then(() => {
            dispatch(handleGetTaskByTemplateId(idTemplate));
        })
        .catch(e => {
        })
}

export const handleEditTaskByTemplateId = (idTemplate, idTask, tName) => (dispatch) => {
    dispatch(handleDeleteTaskInTamplate(idTemplate, idTask));
    dispatch(handleCreateNewTask(tName, idTemplate));
    dispatch(handleGetTaskByTemplateId(idTemplate));
}

export const handleClearActionStatus = () => dispatch => {
    dispatch(createAction(TEMPLATE_ACTION_TYPES.ACTION_REMOVE_STATUS_TEMPLATE, NO_STATEMENT));
    dispatch(createAction(TEMPLATE_ACTION_TYPES.ACTION_CREATE_STATUS_TEMPLATE, EMPTY_STRING));
    dispatch(createAction(TEMPLATE_ACTION_TYPES.ACTION_UPDATE_STATUS_TEMPLATE, EMPTY_STRING));
}