import {
    CREATE_DSS,
    DELETE_DSS_BY_ID,
    GET_ALL_DSS,
    GET_DSS_BY_ID,
    SEARCH_DSS,
    UPDATE_DSS
} from "../../services/dss";
import {createAction} from "../../utils/reducer/reducerUtil";
import {DSS_ACTION_TYPES} from "./dss.types";
import {
    GET_ASSET_DSS,
    GET_BRAND_BY_USAGE,
    GET_CATEGORY_BY_USAGE,
    GET_TYPE_BY_USAGE,
    UPLOAD_FILE,
    UPLOAD_IMAGE
} from "../../services/asset";
import {GET_ALL_SITE} from "../../services/site";
import {STATUS_TYPE} from "../../utils/constant/constants";
import {handleClearStateGlobal, handleLoading} from "../global/global.action";
import { GLOBAL_ACTION_TYPES } from "../global/global.types";
import { GET_TYPE_BY_BRAND } from "../../services/type";
/*
const defineTypeFreq = (freqMin, freqMax) => {
    let freqType;
    if (freqMin > 400 || (freqMax && freqMin > 400 && freqMax <= 8000)) { // C-Band
        freqType = RADIO_BAND.C_BAND.name.toLowerCase();
    } else if (freqMin > 300 || (freqMax && freqMin > 300 && freqMax <= 3000)) { // UHF
        freqType = RADIO_BAND.UHF.name.toLowerCase();
    } else if (freqMin > 30 || (freqMax && freqMin > 30 && freqMax <= 300)) { // VHF
        freqType = RADIO_BAND.VHF.name.toLowerCase();
    } else if (freqMin >= 3 || (freqMax && freqMin >= 3 && freqMax <= 30)) { // HF
        freqType = RADIO_BAND.HF.name.toLowerCase();
    }
    return freqType;
}
*/
export const handleFetchDSS = (page, size, search) => async (dispatch) => {
    const res = await GET_ALL_DSS(page, size, search);
    if (res && res.content) {
        dispatch(createAction(DSS_ACTION_TYPES.GET_ALL_DSS, res));
    }
}

export const handleUploadFile = (item) => dispatch => {
    const formData = new FormData();
    formData.append("file", item);
    UPLOAD_FILE(formData)
        .then(res => {
            dispatch(createAction(DSS_ACTION_TYPES.UPLOAD_FILE, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
        });
}

export const handleUploadImage = (item) => dispatch => {
    const formData = new FormData();
    formData.append("file", item);
    UPLOAD_IMAGE(formData)
        .then(res => {
            dispatch(createAction(DSS_ACTION_TYPES.UPLOAD_IMAGE, res.id));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
        });
}

export const handleFetchStatusDSS = () => async (dispatch) => {
    const res = await GET_ASSET_DSS();
    if (res) {
        dispatch(createAction(DSS_ACTION_TYPES.GET_STATUS, res));
    }
}
export const handleFetchBrand = () => async (dispatch) => {
    const res = await GET_BRAND_BY_USAGE("supporting");
    if (res && res.length > 0) {
        dispatch(createAction(DSS_ACTION_TYPES.GET_BRAND, res));
    }
}
export const handleFetchCategory = () => async (dispatch) => {
    const res = await GET_CATEGORY_BY_USAGE("supporting")
    if (res && res.length > 0) {
        dispatch(createAction(DSS_ACTION_TYPES.GET_CATEGORY, res));
    }
}
export const handleFetchType = () => async (dispatch) => {
    const res = await GET_TYPE_BY_USAGE("supporting")
    if (res && res.length > 0) {
        dispatch(createAction(DSS_ACTION_TYPES.GET_TYPE, res));
    }
}
export const handleFetchSite = () => async (dispatch) => {
    const res = await GET_ALL_SITE();
    if (res && res.content.length > 0) {
        dispatch(createAction(DSS_ACTION_TYPES.GET_SITE, res));
    }
}

export const handleClearData = () => (dispatch) => {
    dispatch(createAction(DSS_ACTION_TYPES.CLEAR_DATA))
}

export const handleClearNotif = () => dispatch => {
    dispatch(createAction(DSS_ACTION_TYPES.UPLOAD_DSS_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(DSS_ACTION_TYPES.UPDATED, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(DSS_ACTION_TYPES.REMOVED, STATUS_TYPE.NO_STATEMENT));
}

export const handleSaveDSS = (data, images, files, siteId) => async (dispatch) => {
    const payload = {
        dssType: data.dssType,
        merk: data.merk,
        name: data.name,
        brand: data.brand,
        serialNo: data.serialNo,
        site: {id: siteId},
        notes: data.notes,
        status: data.status,
        imageUrlList: images,
        fileUrlList: files.urlArr,
        fileNameList: files.nameArr,
    };

    CREATE_DSS(payload).then(() => {
        dispatch(createAction(DSS_ACTION_TYPES.UPLOAD_DSS_STATUS, STATUS_TYPE.SUCCESS))
    }).catch(e => {
        dispatch(createAction(DSS_ACTION_TYPES.UPLOAD_DSS_STATUS, STATUS_TYPE.FAILED))
    });
    dispatch(handleClearStateGlobal());
    dispatch(createAction(DSS_ACTION_TYPES.IS_LOADING, false));

}

export const handleStartLoading = () => dispatch => {
    dispatch(createAction(DSS_ACTION_TYPES.IS_LOADING, true));
}

export const handleRemoveDSS = (id, siteId, page) => async (dispatch) => {
    dispatch(createAction(DSS_ACTION_TYPES.IS_LOADING, true));
    DELETE_DSS_BY_ID(id)
        .then(() => {
            dispatch(createAction(DSS_ACTION_TYPES.REMOVED, STATUS_TYPE.SUCCESS))
            dispatch(handleClearStateGlobal());
        })
        .catch(() => {
            dispatch(createAction(DSS_ACTION_TYPES.REMOVED, STATUS_TYPE.FAILED))
            dispatch(handleClearStateGlobal());
        })
    dispatch(createAction(DSS_ACTION_TYPES.IS_LOADING, false));
}
export const handleUpdateDSS = (id, data, images, files, siteId, page) => async (dispatch) => {

    const payload = {
        dssType: data.dssType,
        merk: data.merk,
        name: data.name,
        brand: data.brand,
        serialNo: data.serialNo,
        amount: data.amount,
        site: {id: siteId},
        notes: data.notes,
        status: data.status,
        imageUrlList: images,
        fileUrlList: files.urlArr,
        fileNameList: files.nameArr,
    };

    UPDATE_DSS(id, payload)
        .then(() => {
            dispatch(createAction(DSS_ACTION_TYPES.UPDATED, STATUS_TYPE.SUCCESS));
        })
        .catch(() => {
            dispatch(createAction(DSS_ACTION_TYPES.UPDATED, STATUS_TYPE.FAILED));
        })
    dispatch(handleClearStateGlobal());
    dispatch(createAction(DSS_ACTION_TYPES.IS_LOADING, false));
};

export const handleSearchDSS = (q, type, page, size, siteId) => dispatch => {
    SEARCH_DSS(q, type, page, size, siteId)
        .then(res => {
            dispatch(createAction(DSS_ACTION_TYPES.SEARCH, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        })
};

export const handleSearchDSSReport = (q, type, page, size, siteId) => dispatch => {
    SEARCH_DSS(q, type, page, size, siteId)
        .then(res => {
            dispatch(createAction(DSS_ACTION_TYPES.SEARCH_REPORT, res));
        })
        .catch(() => {})
};

export const handleGetDSSById = (id) => dispatch => {
    GET_DSS_BY_ID(id)
        .then(res => {
            dispatch(createAction(DSS_ACTION_TYPES.DETAIL_DSS, res));
        })
        .catch(() => {});
};

export const handleSearchDSSTypeByBrandId = (brandId) => dispatch => {
    GET_TYPE_BY_BRAND(brandId)
        .then(res => {
            dispatch(createAction(DSS_ACTION_TYPES.GET_DSS_TYPES_BY_BRAND_ID, res));
        })
        .catch(() => {});
};

export const handleClearDSSTypesByBrand = () => dispatch => {
    dispatch(createAction(DSS_ACTION_TYPES.CLEAR_DSS_TYPES_BY_BRAND_ID));
}

export const handleClearDetailDSS = () => (dispatch) => {
    dispatch(createAction(DSS_ACTION_TYPES.CLEAR_DETAIL_DSS));
};
