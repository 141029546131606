import {createSelector} from "reselect";

const selectRulerReducer = (state) => state.rulerReducer;



export const selectListener = createSelector(
    [selectRulerReducer],
    (state) => state.eventListener
);
export const selectIsRulerActive = createSelector(
    [selectRulerReducer],
    (state) => state.isRulerActive
);

export const selectRulers = createSelector(
    [selectRulerReducer],
    (state) => state.rulers
);

export const selectPoints = createSelector(
    [selectRulerReducer],
    (state) => state.points
);
export const getPosition = createSelector(
    [selectRulerReducer],
    (state) => state.position
);
export const getHandlerEventListener = createSelector(
    [selectRulerReducer],
    (state) => state.handlerEventListener
);
