import {GET_ALL_ROLE, GET_ROLE_BY_ID} from "../../services/role";
import {createAction} from "../../utils/reducer/reducerUtil";
import {ROLE_ACTION_TYPES} from "./role.types";

export const handleGetAllRole = () => dispatch => {
    GET_ALL_ROLE()
        .then(res => {
            dispatch(createAction(ROLE_ACTION_TYPES.GET_ALL, res));
        })
        .catch(() => {});
}
export const handleGetRoleById = (id) => dispatch => {
    GET_ROLE_BY_ID(id)
        .then(res => {
            dispatch(createAction(ROLE_ACTION_TYPES.GET_BY_ID, res));
        })
        .catch(() => {});
}
export const resetRoleData = () => dispatch => {
    dispatch(createAction(ROLE_ACTION_TYPES.RESET_ROLE_DATA));
}