import * as React from "react"

const TemplateMaintenanceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 8267.72 11692.91"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={7.87}
      strokeMiterlimit={22.926}
      d="M-292.65 165.63h8904.69c677.3 0 1231.45 554.15 1231.45 1231.45v8904.69c0 677.3-554.15 1231.46-1231.45 1231.46H-292.65c-677.3 0-1231.46-554.15-1231.46-1231.46V1397.08c0-677.3 554.15-1231.45 1231.46-1231.45zm304.78 389.1h8295.11c630.96 0 1147.14 516.19 1147.14 1147.14v8295.11c0 630.96-516.19 1147.14-1147.14 1147.14H12.13c-630.96 0-1147.14-516.19-1147.14-1147.14V1701.87c0-630.96 516.19-1147.14 1147.14-1147.14z"
    />
    <ellipse
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={98.43}
      strokeMiterlimit={22.926}
      cx={6496.15}
      cy={3086.67}
      rx={141.77}
      ry={179.51}
    />
    <ellipse
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={98.43}
      strokeMiterlimit={22.926}
      cx={7238.61}
      cy={3409.76}
      rx={141.77}
      ry={179.51}
    />
    <path
      fill="none"
      stroke="#2B2A29"
      strokeWidth={98.43}
      strokeMiterlimit={22.926}
      d="m6898.9 2613.88-402.75 472.79 1211.74 533.22-1085.66 63.04z"
    />
    <path
      fill="none"
      stroke="#2B2A29"
      strokeWidth={98.43}
      strokeMiterlimit={22.926}
      d="m6622.23 3682.93 616.38-273.17-339.71-795.88"
    />
    <ellipse
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={98.43}
      strokeMiterlimit={22.926}
      cx={7707.89}
      cy={3619.89}
      rx={141.77}
      ry={179.51}
    />
    <ellipse
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={98.43}
      strokeMiterlimit={22.926}
      cx={6622.23}
      cy={3682.93}
      rx={141.77}
      ry={179.51}
    />
    <ellipse
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={98.43}
      strokeMiterlimit={22.926}
      cx={6898.9}
      cy={2613.88}
      rx={106.74}
      ry={135.15}
    />
    <path
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={7.87}
      strokeMiterlimit={22.926}
      d="M388.67 1836.27h8208.74c92.34 0 167.89 75.55 167.89 167.89v4876.36c0 92.34-75.55 167.89-167.89 167.89H388.67c-92.34 0-167.89-75.55-167.89-167.89V2004.16c0-92.34 75.55-167.89 167.89-167.89zm386.86 245.63h7435.02c83.63 0 152.06 68.43 152.06 152.07v4416.74c0 83.64-68.43 152.07-152.06 152.07H775.53c-83.63 0-152.06-68.43-152.06-152.07V2233.97c0-83.64 68.43-152.07 152.06-152.07z"
    />
    <path
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={7.87}
      strokeMiterlimit={22.926}
      d="M3537.44 2271.4h4495.83c67.75 0 123.19 40.95 123.19 91v2643.19c0 50.05-55.44 91-123.19 91H3537.44c-67.75 0-123.19-40.95-123.19-91V2362.4c0-50.05 55.44-91 123.19-91zm123.34 77.51h4249.15c64.04 0 116.43 38.7 116.43 86.01v2498.16c0 47.31-52.39 86.01-116.43 86.01H3660.78c-64.04 0-116.43-38.7-116.43-86.01V2434.92c0-47.3 52.39-86.01 116.43-86.01z"
    />
    <path
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={7.87}
      strokeMiterlimit={22.926}
      transform="matrix(0 -.44402 1.60624 0 4314.32 4944.26)"
      d="M0 0h986.11v2263.89H0z"
    />
    <path
      fill="none"
      stroke="#2B2A29"
      strokeWidth={98.43}
      strokeMiterlimit={22.926}
      d="M5487.59 2545.44v742.31h742.31c0-409.97-332.34-742.31-742.31-742.31zM5487.59 4093.25v-742.31h742.31c0 409.96-332.34 742.31-742.31 742.31z"
    />
    <path
      fill="currentColor"
      d="M4359.41 3587.83h276.97v640.84h-276.97zM4728.7 3267.42h276.97v961.25H4728.7zM5097.99 2947h276.97v1281.67h-276.97zM3990.12 3908.25h276.97v320.42h-276.97z"
    />
    <circle
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={7.87}
      strokeMiterlimit={22.926}
      cx={1791.84}
      cy={3290.45}
      r={742.31}
    />
    <circle
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={7.87}
      strokeMiterlimit={22.926}
      cx={5527.12}
      cy={6021.83}
      r={742.31}
    />
    <path
      fill="none"
      stroke="#2B2A29"
      strokeWidth={118.11}
      strokeMiterlimit={22.926}
      d="M1791.84 3290.45v2657.8h3683.77"
    />
    <path
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={7.87}
      strokeMiterlimit={22.926}
      d="M2174.12 8081.09v581.13h4826.21v-581.13H5522.45c-130-392.07-499.53-674.94-935.22-674.94-435.69 0-805.22 282.87-935.22 674.94H2174.13z"
    />
    <circle
      fill="#FEFEFE"
      stroke="#2B2A29"
      strokeWidth={7.87}
      strokeMiterlimit={22.926}
      cx={1370.56}
      cy={7125.97}
      r={1187.46}
    />
    <circle
      fill="#FEFEFE"
      stroke="#2B2A29"
      strokeWidth={7.87}
      strokeMiterlimit={22.926}
      cx={5181.8}
      cy={8622.72}
      r={847.19}
    />
    <g fill="currentColor" stroke="#FEFEFE" strokeMiterlimit={22.926}>
      <path
        strokeWidth={59.06}
        d="m5533.83 7262.91 649.58 375.04-158.88 275.18c82.86 98.31 148.84 211.32 193.45 334.56h317.46v750.07h-317.46c-44.61 123.24-110.59 236.25-193.45 334.56l158.88 275.18-649.58 375.04-158.79-275.03c-62.74 11.1-127.32 16.89-193.25 16.89s-130.5-5.79-193.24-16.89l-158.79 275.03-649.58-375.04 158.87-275.18c-82.86-98.31-148.83-211.32-193.45-334.56h-317.46v-750.07h317.46c44.62-123.24 110.59-236.25 193.45-334.56l-158.87-275.18 649.58-375.04 158.79 275.03c62.74-11.1 127.31-16.89 193.24-16.89s130.5 5.79 193.25 16.89l158.79-275.03zm-352.04 750.4c336.57 0 609.42 272.84 609.42 609.41s-272.85 609.42-609.42 609.42c-336.57 0-609.41-272.85-609.41-609.42 0-336.57 272.84-609.41 609.41-609.41z"
      />
      <path
        strokeWidth={118.11}
        d="m1935.61 4943.34 1042.64 601.97L2723.24 5987c133 157.8 238.9 339.19 310.51 537h509.55v1203.94h-509.55c-71.61 197.81-177.5 379.2-310.51 537l255.01 441.69-1042.64 601.97-254.87-441.45c-100.71 17.82-204.35 27.11-310.18 27.11-105.82 0-209.47-9.3-310.18-27.11L805.51 9308.6l-1042.63-601.97 255.01-441.69c-133-157.8-238.9-339.19-310.51-537h-509.55V6524h509.55c71.61-197.81 177.51-379.2 310.51-537l-255.01-441.69 1042.63-601.97 254.87 441.45c100.71-17.82 204.35-27.11 310.18-27.11 105.82 0 209.47 9.3 310.18 27.11l254.87-441.45zM1370.56 6147.8c540.23 0 978.17 437.94 978.17 978.17s-437.94 978.17-978.17 978.17-978.17-437.94-978.17-978.17 437.94-978.17 978.17-978.17z"
      />
    </g>
  </svg>
)

export default TemplateMaintenanceIcon
