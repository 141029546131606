import React, {createRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Cartesian2, LabelCollection, ScreenSpaceEventHandler, ScreenSpaceEventType} from "cesium";

// Redux
import {
    getHandlerEventListener,
    getPosition,
    selectIsRulerActive,
    selectListener,
    selectPoints,
} from "../../store/ruler/ruler.selector";
import {
    addEventHandler,
    addPoint,
    clearRulers,
    handleRulerButton,
    putPrimitiveLabel,
    storePosition
} from "../../store/ruler/ruler.action";
import {selectViewer} from "../../store/dashboard/dashboard.selector";

// Asset
import {RulerIcon} from "../../assets/svgs";
import {selectRuleInformation} from "../../store/area/area.selector";

export const Ruler = () => {
    const ref = createRef();

    const dispatch = useDispatch();
    const isRulerActive = useSelector(selectIsRulerActive);
    const viewer = useSelector(selectViewer);
    const listener = useSelector(selectListener);
    const points = useSelector(selectPoints);
    const position = useSelector(getPosition);
    const handlerEventListener = useSelector(getHandlerEventListener);
    const rulerInformation = useSelector(selectRuleInformation);

    useEffect(() => {
        ref.current.classList.add("cesium-button", "cesium-toolbar-button");
        const toolbar = document.querySelector("div.cesium-viewer-toolbar");
        const modeButton = document.querySelector("span.cesium-sceneModePicker-wrapper");
        toolbar.insertBefore(ref.current, modeButton);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isRulerActive) {
            const handlerClick = new ScreenSpaceEventHandler(viewer.scene.canvas);
            const ellipsoid = viewer.scene.globe.ellipsoid
            const scene = viewer.scene;

            const labels = new LabelCollection({scene: scene});
            scene.primitives.add(labels);
            dispatch(putPrimitiveLabel(labels));

            const eventListener = (click) => {
                const mousePosition = new Cartesian2(click.position.x, click.position.y);
                const cartesian = viewer.camera.pickEllipsoid(mousePosition, ellipsoid);
                if (cartesian) {
                    return dispatch(storePosition(cartesian))
                }
            }
            handlerClick.setInputAction(eventListener, ScreenSpaceEventType.LEFT_CLICK);
            dispatch(addEventHandler(handlerClick, eventListener));
        } else {
            if (listener && handlerEventListener) {
                handlerEventListener.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
                dispatch(clearRulers(viewer));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRulerActive])

    useEffect(() => {
        if (isRulerActive && position) {
            dispatch(addPoint(points, viewer, position, rulerInformation))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position])

    const handleRulerBtn = () => {
        dispatch(handleRulerButton());
    }

    return (
        <>
            <div
                id="ruler"
                style={{background: !isRulerActive ? '#303336' : 'white'}} ref={ref}
                onClick={handleRulerBtn}
            >
                <div className="ml-[-2px]">
                    <RulerIcon style={{stroke: isRulerActive ? '#303336' : '#fff'}}/>
                </div>
            </div>
        </>
    );
};
