import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { report } from "../../../utils/report/report";

import LambangTNI from "../../../assets/png/Lambang_TNI_AU.png";

const ReportMultipleSupporting = ({
    data,
    assetList,
    statusAsset,
    chartImage,
}) => {
    const [profile, setProfile] = useState([]);

    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem("profile"));
        setProfile(userProfile);
    }, []);

    const handlePdfGenerate = () => {
        // Format
        const doc = new jsPDF("potrait", "px", "a4", "false");

        // Template
        report.templateAsset(
            doc,
            1,
            profile,
            LambangTNI,
            "Supporting Asset"
        );

        // Chart Image
        report.chartImg(doc, chartImage);

        // Chart Detail
        report.chartDetail(doc, data);

        // Table Status
        report.tableStatus(doc, "Supporting", statusAsset);

        doc.setTextColor("#040F0F");
        doc.setFontSize(8);
        doc.setFont(undefined, "normal").text(190, 285, "Supporting Assets Table");

        let pageNumber = 0;

        autoTable(doc, {
            theme: "grid",
            styles: {
                textColor: [0, 0, 0],
                fontSize: 7,
                overflow: "linebreak",
                cellWidth: 62,
            },
            margin: { left: 30, bottom: 60 },
            startY: 295,
            didParseCell(data) {
                if (data.row.index % 2 !== 0) {
                    data.cell.styles.textColor = [0, 0, 0];
                    data.cell.styles.fillColor = "#D3D3D3";
                }
                if (data.column.index === 1) {
                    data.cell.styles.cellPadding = {top: 3, right: 13, bottom: 3, left: 3};
                }
                if (data.column.index === 0) {
                    data.cell.styles.cellWidth = 15;
                }
                data.settings.margin.top = 150;
            },
            didDrawPage: (data) => {
                if(data.pageCount >= 2) {
                    report.templateAsset(doc, data.pageCount, profile, LambangTNI, "Supporting Asset");
                }
            },
            tableWidth: 385,
            head: [
                ["No", "Name", "Brand", "Type", "Serial Number", "Owner", "Status"],
            ],
            body: assetList.map((asset) => {
                pageNumber += 1;
                return [
                    pageNumber,
                    asset?.name,
                    asset?.brand?.name,
                    asset?.type?.name,
                    asset?.serialNo,
                    asset?.site?.siteName,
                    asset?.status,
                ];
            }),
        });

        report.savePdf(doc, "Supporting Asset");
    };

    return (
        <button
            type="button"
            className="text-white text-lg underline mb-3"
            onClick={handlePdfGenerate}
        >
            Create Report
        </button>
    );
};

export default ReportMultipleSupporting;
