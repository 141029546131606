import * as React from "react"

const EditTask = (props) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 18.75H6.5c-.69 0-1.25-.56-1.25-1.25v-12c0-.69.56-1.25 1.25-1.25h3.75V9c0 .41.34.75.75.75h4.8c.1.29.37.5.7.5.41 0 .75-.34.75-.75V9a.776.776 0 0 0-.22-.53l-5.5-5.5a.776.776 0 0 0-.53-.22H6.5c-1.52 0-2.75 1.23-2.75 2.75v12c0 1.52 1.23 2.75 2.75 2.75H8c.41 0 .75-.34.75-.75s-.34-.75-.75-.75Zm3.75-13.44 2.94 2.94h-2.94V5.31Zm7.86 6.06c-.38-.38-.94-.61-1.52-.62-.6-.03-1.17.2-1.55.59l-6.39 6.4c-.13.13-.2.29-.22.47l-.18 2.23c-.02.22.06.44.22.59.14.14.33.22.53.22h.07l2.25-.21a.74.74 0 0 0 .46-.22l6.39-6.4c.8-.79.77-2.22-.06-3.05Zm-1 1.99-6.2 6.21-1.09.1.08-1.06 6.2-6.21c.1-.1.28-.14.46-.15.2 0 .38.07.49.18.24.23.27.72.06.93Z"
                fill="#000"
            />
    </svg>
)

export default EditTask
