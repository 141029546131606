import React, { forwardRef } from "react";

const LabelInput = forwardRef(
    (
        {
            titleName,
            error,
            unit,
            optionss,
            custom = false,
            isSelect = false,
            options = [],
            ...props
        },
        ref
    ) => {

        return (
            <div className="flex flex-col gap-y-4 my-2">
                <div className="flex justify-between items-center px-4">
                    <div className="text-white text-sm">{titleName}</div>
                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                        {!custom ? (
                            <div className={`w-[170px] h-[32px] bg-tertiary text-white p-1 px-2 text-sm rounded-2xl border-2 self-start ${error ? "border-red-500" : "border-white"}`}>
                                <input
                                    ref={ref}
                                    {...props}
                                    autoComplete="on"
                                    className="block w-full shadow-sm sm:text-sm text-white bg-transparent border-none px-1"
                                    placeholder={error?.message}
                                />
                            </div>
                        ) : (
                            <div className="flex gap-x-3">
                                <div className="w-[80px] h-[32px] bg-transparent text-white p-1 px-2 text-sm rounded-2xl border-2 border-white self-start">
                                    <input
                                        ref={ref}
                                        {...props}
                                        autoComplete="on"
                                        className="block w-full shadow-sm sm:text-sm text-white bg-transparent border-none"
                                    />
                                </div>
                                <select
                                    ref={ref}
                                    {...props}
                                    className="select select-sm w-[80px] max-h-[32px] bg-transparent text-white p-1 px-2 text-sm rounded-2xl border-2 border-white self-start"
                                >
                                    {optionss.map((el) => {
                                        return (
                                            <option
                                                value={el.value}
                                                key={el.value}
                                                className="h-[40px] text-black focus:text-gray"
                                            >
                                                {el.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}
                        {!!unit && (
                            <>
                                {!isSelect ? (
                                    <p className="text-sm text-white ml-2">{unit}</p>
                                ) : (
                                    <select
                                        defaultValue={unit}
                                        className="select w-full border-white bg-transparent text-white border-0"
                                    >
                                        {options.map((el) => {
                                            return (
                                                <option
                                                    value={el}
                                                    selected={el === unit}
                                                    key={el}
                                                    className="h-[40px] text-black focus:text-gray"
                                                >
                                                    {el}
                                                </option>
                                            );
                                        })}
                                    </select>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);

export default LabelInput;
