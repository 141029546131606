export const AREA_ACTION_TYPES = {
    TRANSMITTER_POSITION : 'area/TRANSMITTER_POSITION',
    SHOW_HIDE_CHART_LINK_BUDGET : 'area/SHOW_HIDE_CHART_LINK_BUDGET',
    CHANGE_KINETIC_RX : 'area/CHANGE_KINETIC_RX',
    ANY_DATA_CHART_LINK_BUDGET : 'area/ANY_DATA_CHART_LINK_BUDGET',
    REGISTER_KMZ : 'area/REGISTER_KMZ',
    SHOW_HIDE_COVERAGE : 'area/SHOW_HIDE_COVERAGE',
    SHOW_COVERAGE_TX_LINK_BUDGET : 'area/SHOW_COVERAGE_TX_LINK_BUDGET',
    REGISTER_MID_LABEL : 'area/REGISTER_MID_LABEL',
    REGISTER_POLYLINE : 'area/REGISTER_POLYLINE',
    RX_POSITION : 'area/RX_POSITION',
    GET_PM_LIST : 'area/GET_PM_LIST',
    CLEAR_ERROR_MESSAGE : 'area/CLEAR_ERROR_MESSAGE',
    TRANSMITTER_DATA : 'area/TRANSMITTER_DATA',
    IS_TERRAIN_ACTIVE : 'area/IS_TERRAIN_ACTIVE',
    RESET_TERRAIN_ACTION : 'area/RESET_TERRAIN_ACTION',
    TERRAIN_HEIGHT : 'area/TERRAIN_HEIGHT',
    OPEN_INPUT_DATA : 'area/OPEN_INPUT_DATA',
    CLOSE_INPUT_DATA : 'area/CLOSE_INPUT_DATA',
    FETCH_DATA : 'area/FETCH_DATA',
    CLEAR_TRANSMITTER_DATA : 'area/CLEAR_TRANSMITTER_DATA',
    START_POST_DATA_AREA : 'area/START_POST_DATA_AREA',
    POST_SUCCESS : 'area/POST_SUCCESS',
    POST_FAILED : 'area/POST_FAILED',
    COPY_ENTITY_DATA : 'area/COPY_ENTITY_DATA',
    REMOVE_COPY_ENTITY_DATA : 'area/REMOVE_COPY_ENTITY_DATA',
    HANDLE_MULTIPLY_AMOUNT : 'area/HANDLE_MULTIPLY_AMOUNT',
    MULTIPLE_ANALYZE_REQUEST : 'area/MULTIPLE_ANALYZE_REQUEST',
    CREATE_OP : 'area/CREATE_OP',
    STORE_REQ_BODY : 'area/STORE_REQ_BODY',
    SET_COUNT_OP_REQ : 'area/SET_COUNT_OP_REQ',
    ALTITUDE_TO_SUM : 'area/ALTITUDE_TO_SUM',
    CREATE_SIMULATE : 'area/CREATE_SIMULATE',
    FINISH_OP_SIM : 'area/FINISH_OP_SIM',
    STORE_BILLBOARD_TX : 'area/STORE_BILLBOARD_TX',
    STORE_BOLLBOARD_RX : 'area/STORE_BOLLBOARD_RX',
    CREATE_PATH : 'area/CREATE_PATH',
    STORE_KML_DATASOURCE : 'area/STORE_KML_DATASOURCE',
    CLEAR_BILLBOARD : 'area/CLEAR_BILLBOARD',
    CLEAR_KML_SOURCE : 'area/CLEAR_KML_SOURCE',
    CLEAR_ALL : 'area/CLEAR_ALL',
    KINETIC : 'area/KINETIC_TX',
    REGISTER_HANDLER_LISTENER_MOUSE_MOVE : 'area/REGISTER_HANDLER_LISTENER_MOUSE_MOVE',
    SHOW_COVERAGE_NOTIFICATION : 'area/SHOW_COVERAGE_NOTIFICATION',
    RULER_INFORMATION : 'area/RULER_INFORMATION',
}

export const SHOW = "SHOW";
export const NS = "NS";