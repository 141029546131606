import {COVERAGE_RULER_ACTION_TYPES} from './coverageRuler.types'
import {createAction} from "../../utils/reducer/reducerUtil";

export const handleStoreBearing = (bearing) => dispatch => {
    dispatch(createAction(COVERAGE_RULER_ACTION_TYPES.BEARING, bearing)
    )
}
export const handleStoreDistance = (distance) => dispatch => {
    dispatch(createAction(COVERAGE_RULER_ACTION_TYPES.DISTANCE, distance)
    )
}
export const handleStoreName = (name) => dispatch => {
    dispatch(createAction(COVERAGE_RULER_ACTION_TYPES.ENTITY_NAME, name)
    )
}
export const handleRemoveLastIndexInEntityArr = () => dispatch => {
    dispatch(createAction(COVERAGE_RULER_ACTION_TYPES.REMOVE_LAST_ARRAY)
    )
}
export const handleResetReducer = () => dispatch => {
    dispatch(createAction(COVERAGE_RULER_ACTION_TYPES.RESET)
    )
}
export const handleRulerButton = (toggle) => dispatch => {
    dispatch(createAction(COVERAGE_RULER_ACTION_TYPES.HANDLE_RULER_TOGGLE, toggle));
}
export const handleAddEntityIdInArray = (position) => dispatch => {
    dispatch(createAction(COVERAGE_RULER_ACTION_TYPES.ENTITY_ID_ARRAY, position));
}
export const handleClearEntityIdArray = () => dispatch => {
    dispatch(createAction(COVERAGE_RULER_ACTION_TYPES.CLEAR_ENTITY_ID_ARRAY));
}
