import * as React from "react";

const PalletIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.775 14.0062C20.9305 13.3235 21.006 12.625 21 11.9249C20.9625 6.96554 16.8562 2.95304 11.9062 2.99992C9.79153 3.02222 7.75224 3.78848 6.14613 5.16428C4.54003 6.54007 3.46969 8.43751 3.12293 10.5237C2.77616 12.6099 3.17512 14.7515 4.24979 16.5729C5.32446 18.3943 7.00621 19.7791 8.99997 20.4843C9.33912 20.6065 9.70275 20.645 10.06 20.5966C10.4172 20.5483 10.7575 20.4144 11.0519 20.2065C11.3464 19.9985 11.5863 19.7226 11.7514 19.4021C11.9164 19.0816 12.0017 18.726 12 18.3655V17.9999C11.9987 17.7041 12.0561 17.411 12.1687 17.1374C12.2813 16.8639 12.447 16.6153 12.6562 16.4062C12.8654 16.197 13.1139 16.0313 13.3875 15.9187C13.661 15.806 13.9541 15.7487 14.25 15.7499H18.5812C19.0902 15.7502 19.5843 15.578 19.9827 15.2613C20.3812 14.9446 20.6604 14.5021 20.775 14.0062V14.0062Z"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8.25C12.6213 8.25 13.125 7.74632 13.125 7.125C13.125 6.50368 12.6213 6 12 6C11.3787 6 10.875 6.50368 10.875 7.125C10.875 7.74632 11.3787 8.25 12 8.25Z"
      fill="white"
    />
    <path
      d="M7.78125 10.6875C8.40257 10.6875 8.90625 10.1838 8.90625 9.5625C8.90625 8.94118 8.40257 8.4375 7.78125 8.4375C7.15993 8.4375 6.65625 8.94118 6.65625 9.5625C6.65625 10.1838 7.15993 10.6875 7.78125 10.6875Z"
      fill="white"
    />
    <path
      d="M7.78125 15.5625C8.40257 15.5625 8.90625 15.0588 8.90625 14.4375C8.90625 13.8162 8.40257 13.3125 7.78125 13.3125C7.15993 13.3125 6.65625 13.8162 6.65625 14.4375C6.65625 15.0588 7.15993 15.5625 7.78125 15.5625Z"
      fill="white"
    />
    <path
      d="M16.2188 10.6875C16.8401 10.6875 17.3438 10.1838 17.3438 9.5625C17.3438 8.94118 16.8401 8.4375 16.2188 8.4375C15.5974 8.4375 15.0938 8.94118 15.0938 9.5625C15.0938 10.1838 15.5974 10.6875 16.2188 10.6875Z"
      fill="white"
    />
  </svg>
);

export default PalletIcon;
