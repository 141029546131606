import * as React from "react"

const MaintenanceHistoryIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 8267.72 11692.91"
    {...props}
  >
    <g fill="currentColor" strokeMiterlimit={22.926}>
      <path
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M-292.65 165.63h8904.69c677.3 0 1231.45 554.15 1231.45 1231.45v8904.69c0 677.3-554.15 1231.46-1231.45 1231.46H-292.65c-677.3 0-1231.46-554.15-1231.46-1231.46V1397.08c0-677.3 554.15-1231.45 1231.46-1231.45zm304.78 389.1h8295.11c630.96 0 1147.14 516.19 1147.14 1147.14v8295.11c0 630.96-516.19 1147.14-1147.14 1147.14H12.13c-630.96 0-1147.14-516.19-1147.14-1147.14V1701.87c0-630.96 516.19-1147.14 1147.14-1147.14z"
      />
      <path
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M3013.65 1318.35h4288.63c322.53 0 586.41 263.89 586.41 586.41v3654.9c-85.61-29.24-173.98-52.51-264.56-69.51V2176.63c0-291.28-238.32-529.6-529.6-529.6H3221.38c-291.28 0-529.6 238.32-529.6 529.6V7245.5c0 291.28 238.32 529.6 529.6 529.6h2169.96a1904.754 1904.754 0 0 0 101.36 328.69H3013.63c-322.52 0-586.41-263.89-586.41-586.41V1904.76c0-322.53 263.89-586.41 586.41-586.41z"
      />
      <path
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M3146.91 2342.35h4152.27v241.77H3146.91zM3146.91 3335.32h4152.27v241.77H3146.91zM3146.91 4328.3h4152.27v241.77H3146.91zM3146.91 5321.27h4152.27v241.77H3146.91zM3146.91 6314.25h2223.21v241.77H3146.91z"
      />
      <g stroke="#FEFEFE" strokeWidth={78.74}>
        <path d="M7217.2 5633.47c949.26 0 1718.79 769.53 1718.79 1718.78 0 949.26-769.53 1718.78-1718.79 1718.78S5498.42 8301.5 5498.42 7352.25c0-949.26 769.53-1718.78 1718.78-1718.78zm0 390.82c733.41 0 1327.96 594.55 1327.96 1327.96 0 733.41-594.55 1327.96-1327.96 1327.96-733.41 0-1327.96-594.55-1327.96-1327.96 0-733.41 594.55-1327.96 1327.96-1327.96z" />
        <path d="M6257.39 7210.23h1162.33v252.8H6257.39z" />
        <path
          transform="matrix(0 .12668 -.02775 0 7419.72 6534.04)"
          d="M0 0h7333.34v9111.12H0z"
        />
      </g>
      <g stroke="#FEFEFE" strokeWidth={98.43}>
        <path d="M1002.62 4142.68c386.29 201.34 650.07 605.43 650.07 1071.18 0 143.45-25.07 281.03-70.99 408.67L5593.03 9304.7a810.275 810.275 0 0 1 51.07 46.89c311.75 311.76 311.75 817.2 0 1128.96-311.76 311.75-817.2 311.75-1128.96 0a813.051 813.051 0 0 1-46.89-51.07L752.57 6381.64c-98.07 25.72-200.99 39.47-307.12 39.47-666.74 0-1207.24-540.5-1207.24-1207.24 0-465.75 263.79-869.84 650.07-1071.18v1074.65l564.74 351.99 549.59-386.08V4142.7zm3777.7 5474.08c165.3-165.3 433.31-165.3 598.61 0s165.3 433.31 0 598.61-433.31 165.3-598.61 0-165.3-433.31 0-598.61z" />
        <circle
          transform="scale(.15137) rotate(-45 95857.346 -7753.478)"
          r={2796.41}
        />
        <path d="M2211.91 6563.37 4537.9 8889.36c133.93 133.92 133.93 351.06 0 484.98-133.93 133.92-351.06 133.92-484.98 0L1726.93 7048.35c-133.93-133.93-133.93-351.06 0-484.98 133.93-133.93 351.06-133.93 484.98 0z" />
      </g>
      <path
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M6257.39 7210.23h1162.33v252.8H6257.39z"
      />
      <path
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M7171.02 6353.84h248.71v1109.2h-248.71z"
      />
    </g>
  </svg>
)

export default MaintenanceHistoryIcon
