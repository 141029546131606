import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ReactPaginate from "react-paginate";
import {useForm} from "react-hook-form";

import HeaderPage from "../../../components/header/HeaderPage";
import Table from "../../../components/table/Table";
import {
    ACTION_TYPE_DATA_CREATE,
    ACTION_TYPE_DATA_NS,
    ACTION_TYPE_DATA_UPDATE,
    COLUMN_CATEGORY,
    EMPTY_STRING,
    FAILED,
    FILTER_BY_NAME,
    FILTER_BY_USAGE,
    NUMBER_TEN,
    NUMBER_ZERO,
    SUCCESS
} from "../../../store/admin/admin.types";
import SearchBox from "../../../components/search-box/search-box.component";
import {ALERT_ICON_FAILED, ALERT_ICON_SUCCESS, ALERT_TITLE_FAILED, ALERT_TITLE_SUCCESS, ASSET_TYPE, CONFIRMATION_TITLE_REMOVE, CONFIRMATION_TEXT_REMOVE, CONFIRMATION_ICON_REMOVE, adminUsage} from "../../../utils/constant/constants";
import {
    handleClearCategoryStatus,
    handleCreateCategory,
    handleDeleteCategory,
    handleGetCategoryById,
    handleResetFailedStatus,
    handleSearchCategory,
    handleUpdateCategory
} from "../../../store/admin/admin.action";
import {
    selectACategory,
    selectCategories,
    selectCreateCategoryStatus,
    selectUpdateCategoryStatus,
    selectRemoveCategoryStatus,
    selectCreateCategoryMessage,
    selectUpdateCategoryMessage,
    selectRemoveCategoryMessage,
    selectCategoriesTotalPages,
    selectResetFailed,
} from "../../../store/admin/admin.selector";
import {ModalForm} from "../../../components/modal/ModalForm";
import {handleClearStateGlobal, handleLoading} from "../../../store/global/global.action";
import PlusIcon from "../../../assets/icons/PlusIcon";
import {selectAssetToEdit, selectAssetToRemove, selectCurrentItem} from "../../../store/global/global.selector";
import { generalAlert, generalConfirmation } from "../../../utils/notification/notification";

const AssetCategory = () => {
    const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm(({
        defaultValues: {
            name: "",
            usage: "",
        }
    }));
    const [filterSelect, setFilterSelect] = useState(FILTER_BY_USAGE);
    const [searchText, setSearchText] = useState(EMPTY_STRING);
    const [searchTextOnChange, setSearchTextOnChange] = useState(EMPTY_STRING);
    const [page, setPage] = useState(NUMBER_ZERO);
    const [open, setOpen] = useState(false);
    const [actionType, setActionType] = useState(ACTION_TYPE_DATA_NS);

    const categories = useSelector(selectCategories);
    const aCategory = useSelector(selectACategory);
    const createCategoryStatus = useSelector(selectCreateCategoryStatus);
    const updateCategoryStatus = useSelector(selectUpdateCategoryStatus);
    const removeCategoryStatus = useSelector(selectRemoveCategoryStatus);
    const createCategoryMessage = useSelector(selectCreateCategoryMessage);
    const updateCategoryMessage = useSelector(selectUpdateCategoryMessage);
    const removeCategoryMessage = useSelector(selectRemoveCategoryMessage);
    const categoriesTotalPages = useSelector(selectCategoriesTotalPages);
    const assetToRemove = useSelector(selectAssetToRemove);
    const assetToEdit = useSelector(selectAssetToEdit);
    const currentItem = useSelector(selectCurrentItem);
    const resetFailed = useSelector(selectResetFailed);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(handleLoading(true));
        dispatch(handleSearchCategory(page, NUMBER_TEN, filterSelect, searchText));

        return () => {
            reset();
            setFilterSelect(FILTER_BY_USAGE);
            setSearchText(EMPTY_STRING);
            setSearchTextOnChange(EMPTY_STRING);
            setPage(NUMBER_ZERO);
            setActionType(ACTION_TYPE_DATA_NS)
            dispatch(handleClearCategoryStatus());
            dispatch(handleClearStateGlobal());
            dispatch(handleLoading(false));
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (aCategory && actionType === ACTION_TYPE_DATA_UPDATE) {
            setValue("name", aCategory.name);
            setValue("usage", aCategory.usage);
            setOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aCategory])

    useEffect(() => {
        if (resetFailed && resetFailed === FAILED) {
            reset();
            setActionType(ACTION_TYPE_DATA_NS);
            dispatch(handleClearCategoryStatus());
            dispatch(handleClearStateGlobal());
            dispatch(handleResetFailedStatus());
            generalAlert(ALERT_TITLE_FAILED, `Failed to get a category`, ALERT_ICON_FAILED);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetFailed])

    useEffect(() => {
        handleStatus(createCategoryStatus, createCategoryMessage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createCategoryStatus])

    useEffect(() => {
        handleStatus(updateCategoryStatus, updateCategoryMessage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateCategoryStatus])

    useEffect(() => {
        handleStatus(removeCategoryStatus, removeCategoryMessage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeCategoryStatus])

    /**
     * EDIT DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.ADMIN_CATEGORY === assetToEdit && currentItem) {
            setActionType(ACTION_TYPE_DATA_UPDATE);
            dispatch(handleGetCategoryById(currentItem.category));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToEdit]);

    /**
     * REMOVE DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.ADMIN_CATEGORY === assetToRemove && currentItem) {
            generalConfirmation(
                CONFIRMATION_TITLE_REMOVE,
                CONFIRMATION_TEXT_REMOVE(currentItem.name),
                CONFIRMATION_ICON_REMOVE,
                () => {
                    dispatch(handleLoading(true));
                    dispatch(handleDeleteCategory(currentItem.category));
                },
                () => dispatch(handleClearStateGlobal()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToRemove]);

    const handleStatus = (status, textMessage) => {
        if (status !== EMPTY_STRING) {
            if (status === SUCCESS) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_SUCCESS, textMessage, ALERT_ICON_SUCCESS, () => dispatch(handleClearCategoryStatus()));
            } else if (status === FAILED) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_FAILED, textMessage, ALERT_ICON_FAILED, () => dispatch(handleClearCategoryStatus()));
            }
            reset();
            setActionType(ACTION_TYPE_DATA_NS);
            if (categories && categories.content && categories.content.length) {
                dispatch(handleSearchCategory(page, NUMBER_TEN, filterSelect, searchText));
            } else {
                if (page) {
                    dispatch(handleSearchCategory(page - 1, NUMBER_TEN, filterSelect, searchText));
                    setPage(prevPage => prevPage - 1);
                } else {
                    dispatch(handleSearchCategory(0, NUMBER_TEN, filterSelect, searchText));
                    setPage(0);
                }
            }
            dispatch(handleClearStateGlobal());
            setOpen(false);
        }
    };

    const handleSelectFilter = (e) => {
        dispatch(handleSearchCategory(page, NUMBER_TEN, e.target.value, searchText));
        setFilterSelect(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));
    
        setSearchText(searchTextOnChange);
    
        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value;
    
        dispatch(handleSearchCategory(NUMBER_ZERO, NUMBER_TEN, filterSelect, searchText));
    
        setPage(NUMBER_ZERO);
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    };

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        dispatch(handleSearchCategory(e.selected, NUMBER_TEN, filterSelect, searchText));
        setPage(e.selected);
    };

    const handleOpen = () => {
        setActionType(ACTION_TYPE_DATA_CREATE);
        setOpen(true);
    }

    const handleClose = () => {
        reset();
        setActionType(ACTION_TYPE_DATA_NS);
        dispatch(handleClearCategoryStatus());
        dispatch(handleClearStateGlobal());
        dispatch(handleSearchCategory(page, NUMBER_TEN, filterSelect, searchText));
        setOpen(false);
    };

    const handleSave = (data) => {
        dispatch(handleClearCategoryStatus());

        const {name, usage} = data;
        const trimName = name.trimEnd();
        const payload = {name: trimName, usage: data.usage};
        if (actionType === ACTION_TYPE_DATA_CREATE && trimName && usage) {
                dispatch(handleLoading(true));
                dispatch(handleCreateCategory(payload));
        } else if (actionType === ACTION_TYPE_DATA_UPDATE && currentItem) {
            const {category, name: currentName, usage: currentUsage} = currentItem;
            if (category && (currentName !== trimName || currentUsage !== usage)) {
                payload.category = category;
                dispatch(handleLoading(true));
                dispatch(handleUpdateCategory(currentItem.category, payload));
            } else {
                generalAlert(ALERT_TITLE_FAILED, `The input data has not changed`, ALERT_ICON_FAILED);
            }
        }
    }

    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="Asset Category"/>

            <div className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">
                    <form className="flex mt-14 mb-3" onSubmit={handleSearch}>
                        <select
                            name="filter"
                            className="select select-bordered w-[200px] rounded-3xl h-[30px] mr-2"
                            onChange={handleSelectFilter}
                        >
                            <option value={FILTER_BY_USAGE}>USAGE</option>
                            <option value={FILTER_BY_NAME}>CATEGORY NAME</option>
                        </select>
                        <div className="flex justify-start mb-6">
                            <SearchBox
                                name="searchText"
                                value={searchTextOnChange}
                                placeholder="Search Category"
                                className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                                onChangeHandler={onSearchChange}
                            />
                            <span className="flex justify-center items-center text-white text-lg px-1 mr-auto">
                                <button
                                    type="submit"
                                    className="btn btn-primary rounded-full text-white text-xs px-7"
                                >
                                    Search
                                </button>
                            </span>
                            <button
                                type="button"
                                className="flex justify-center items-center text-white gap-x-2 text-lg cursor-pointer ml-[60px]"
                                onClick={handleOpen}
                            >
                                <PlusIcon width={18} height={16}/>
                                Add Category
                            </button>
                        </div>
                    </form>

                    <div className="createModal">
                        <ModalForm
                            open={open}
                            onClose={handleClose}
                            onSave={handleSubmit(handleSave)}
                            title={actionType === ACTION_TYPE_DATA_CREATE ? `Create Category` : `Update Category`}
                            icon="user"
                            btnCloseTitle="Cancel"
                            btnSubmitTitle={actionType === ACTION_TYPE_DATA_CREATE ? `Save` : `Update`}
                            size="w-2/3"
                            closeBtn={true}
                        >
                            <div>
                                <div className="flex flex-row  items-center my-2">
                                    <label className="text-white w-1/3" htmlFor="name">
                                        Name
                                    </label>
                                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                        <input
                                            {...register("name", {
                                                required: "Required!",
                                                pattern: {
                                                    value: /^\S+/,
                                                    message: "Entered value cant start or contain only white spacing"
                                                },
                                            })}
                                            pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                            title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                            className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.name ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                            id="name"
                                            type="text"
                                            autoFocus
                                            placeholder={errors?.name?.message}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row  items-center my-2">
                                    <label className="text-white w-1/3">
                                        Usage
                                    </label>
                                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                        <select
                                            {...register("usage", {
                                                required: "Required!",
                                            })}
                                            className={`select select-bordered w-full bg-white text-black font-thin border-2 focus:border-purple-500 ${errors?.category ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                        >
                                            <option value="" selected disabled>Select Usage</option>
                                            {adminUsage && adminUsage.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.value}>
                                                        {item.label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </ModalForm>
                    </div>

                    {categories && categories.content.length > 0 ? (
                        <Table
                            data={categories.content}
                            column={COLUMN_CATEGORY}
                            assetType={ASSET_TYPE.ADMIN_CATEGORY}
                        />
                    ) : (
                        <p className="flex flex-col text-white text-center pt-10">
                            Data is empty
                        </p>
                    )}

                    <div className="text-white flex flex-col">
                        <div className="flex justify-center  my-6 self-end h-14 items-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={categoriesTotalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={page}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssetCategory;
