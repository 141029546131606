import * as React from "react"

const RadioBrandIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 8267.72 11692.91"
    {...props}
  >
    <g strokeMiterlimit={22.926}>
      <path
        fill="currentColor"
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M-292.65 165.63h8904.69c677.3 0 1231.45 554.15 1231.45 1231.45v8904.69c0 677.3-554.15 1231.46-1231.45 1231.46H-292.65c-677.3 0-1231.46-554.15-1231.46-1231.46V1397.08c0-677.3 554.15-1231.45 1231.46-1231.45zm304.78 389.1h8295.11c630.96 0 1147.14 516.19 1147.14 1147.14v8295.11c0 630.96-516.19 1147.14-1147.14 1147.14H12.13c-630.96 0-1147.14-516.19-1147.14-1147.14V1701.87c0-630.96 516.19-1147.14 1147.14-1147.14z"
      />
      <rect
        fill="currentColor"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        x={975.81}
        y={4332.93}
        width={6572.27}
        height={3090.62}
        rx={285.52}
        ry={134.27}
      />
      <path
        fill="none"
        stroke="#2B2A29"
        strokeWidth={29.53}
        d="M3697 2547h3560.39c66.98 0 124.34 43.36 145.38 103.39 2.88 8.22 5.08 16.76 6.53 25.53l360.44 1409.83c13.11 22.62 20.63 48.85 20.63 76.74V7398.4c0 84.67-69.28 153.95-153.95 153.95H856.71c-84.67 0-153.95-69.28-153.95-153.95V4162.49c0-56.97 31.36-106.98 77.68-133.59 4.58-2.63 9.31-5.04 14.18-7.2l2778.37-1411.69c28.09-38.15 73.29-63.02 124.02-63.02z"
      />
      <circle
        fill="#FEFEFE"
        stroke="#2B2A29"
        strokeWidth={7.87}
        cx={2587.84}
        cy={5812.91}
        r={1261.02}
      />
      <circle
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        cx={4747.77}
        cy={5418.12}
        r={556.69}
      />
      <path
        fill="#FEFEFE"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        d="M4812.33 4666.39c234.82 0 428.31 176.97 454.37 404.82.06.44.11.9.15 1.35l27.22 238-.04-.01c-50.12-255.96-275.61-449.11-546.26-449.11-199.5 0-374.44 104.96-472.73 262.65l-.05-.01 130.28-209.02a455.195 455.195 0 0 1 40-64.18c83.37-111.97 216.75-184.49 367.07-184.49z"
      />
      <circle
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        cx={6445}
        cy={5385.21}
        r={368.83}
      />
      <path
        fill="#FEFEFE"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        d="M6487.78 4887.16c155.58 0 283.77 117.25 301.04 268.21.04.3.07.6.1.89l18.03 157.68-.02-.01c-33.21-169.58-182.61-297.55-361.92-297.55-132.18 0-248.08 69.54-313.2 174.02h-.03l86.31-138.48a301.595 301.595 0 0 1 26.5-42.52c55.23-74.19 143.61-122.23 243.2-122.23z"
      />
      <path
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={29.53}
        d="M2590.92 4803.64c681.84 0 1235.59 531.04 1245.05 1189.61-87.51 611-612.93 1080.68-1248.14 1080.68-621.47 0-1137.89-449.58-1241.89-1041.24-.14-7.44-.29-14.86-.29-22.33 0-666.46 557.52-1206.72 1245.27-1206.72z"
      />
      <g fill="#FEFEFE" stroke="#2B2A29" strokeWidth={9.84}>
        <rect
          x={1557.3}
          y={6094.57}
          width={2061.07}
          height={78.15}
          rx={70.39}
          ry={2.67}
        />
        <rect
          x={1557.3}
          y={5734.76}
          width={2061.07}
          height={78.15}
          rx={70.39}
          ry={2.67}
        />
        <rect
          x={1889.95}
          y={6495.12}
          width={1395.77}
          height={78.15}
          rx={47.67}
          ry={2.67}
        />
        <rect
          x={1889.95}
          y={5290.08}
          width={1395.77}
          height={78.15}
          rx={47.67}
          ry={2.67}
        />
      </g>
      <rect
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={59.06}
        x={5064.98}
        y={6708.77}
        width={1083.56}
        height={452.58}
        rx={37.01}
        ry={15.46}
      />
      <rect
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={59.06}
        x={4003.49}
        y={6708.77}
        width={732.42}
        height={452.58}
        rx={25.01}
        ry={15.46}
      />
      <rect
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={59.06}
        x={6477.65}
        y={6708.77}
        width={732.42}
        height={452.58}
        rx={25.01}
        ry={15.46}
      />
      <g stroke="#2B2A29" strokeWidth={7.87}>
        <path
          fill="currentColor"
          d="M2504.16 7625.77c182.36 148.48 251.5 407.14 151.79 631.77l-790.23 1780.33c-119.26 268.68-436.66 390.93-705.34 271.67l-1297.8-576.05c-268.67-119.26-390.93-436.66-271.67-705.33l790.23-1780.33c99.71-224.63 337.92-346.87 570.36-311.23l346.02-168.46 839.46-408.72 260.01 896.74 107.17 369.62zm-605.67-914.07c139.72 0 252.99 113.27 252.99 252.99 0 139.72-113.27 252.99-252.99 252.99-139.72 0-252.98-113.27-252.98-252.99 0-139.72 113.26-252.99 252.98-252.99z"
        />
        <path
          fill="#2B2A29"
          d="M2777.72 7579.8c189.41 156.94 261.22 430.33 157.66 667.76L2114.6 10129.3c-123.87 283.98-453.54 413.2-732.6 287.15l-211.73-95.64 3.67-5.49c265.71 109.32 574.53-13.28 691.79-277.46l790.23-1780.33c99.71-224.63 30.57-483.3-151.79-631.77L2397 7256.14l-257.16-886.9.19-.94 256.32-126.99 270.06 947.81 111.32 390.68z"
        />
      </g>
    </g>
  </svg>
)

export default RadioBrandIcon
