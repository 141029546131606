import * as React from "react"

const CoverageRulerIcon = (props) => (
  <svg width={30} height={30} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <g transform="translate(-256.766 -80.233) scale(2.08577)">
      <ellipse
        cx={241.399}
        cy={157.474}
        rx={99.644}
        ry={96.382}
        style={{
          fillRule: "nonzero",
          fill: "#00000000",
          paintOrder: "fill",
          strokeWidth: 9,
        }}
      />
      <ellipse
        cx={241.994}
        cy={157.177}
        rx={73.456}
        ry={71.051}
        style={{
          fillRule: "nonzero",
          fill: "#00000000",
          paintOrder: "fill",
          strokeWidth: 9,
        }}
      />
      <ellipse
        cx={243.179}
        cy={157.77}
        rx={44.707}
        ry={43.243}
        style={{
          fillRule: "nonzero",
          fill: "#00000000",
          paintOrder: "fill",
          strokeWidth: 9,
        }}
      />
      <circle
        cx={244.103}
        cy={159.439}
        r={7.414}
        style={{
          fill: "#fff",
          strokeWidth: 5,
          paintOrder: "fill",
        }}
      />
      <circle
        cx={309.809}
        cy={89.203}
        r={7.414}
        style={{
          fill: "#fff",
          paintOrder: "fill",
          strokeWidth: 5,
        }}
      />
      <path
        d="m247.924 154.014 58.126-59.906"
        style={{
          fill: "#fff",
          strokeWidth: 5,
        }}
      />
    </g>
  </svg>
)
export default CoverageRulerIcon
