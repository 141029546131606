export const meterToFeet = (meter) => {
    return Number((Number(meter) * 3.281).toFixed(2));
}
export const feetToMeter = (feet) => {
    return Number((Number(feet) / 3.281).toFixed(4));
}

export const getPercentage = (ammount, total) => {
    if (total === 0) {
        return total;
    } else {
        return Number((ammount / total * 100).toFixed(2))
    }
}

export const nauticalMilesToKm = (nm) => {
    const km = 1.852;
    return Number((Number(nm) * km).toFixed(2));
}

export const kmToNauticalMiles = (km) => {
        const nm = 0.539957;
    return Number((Number(km) * nm).toFixed(2));
}

export const km2ToNauticalMiles2 = (km2) => {
    const x = 0.2915533496;
    return Number((Number(km2) * x).toFixed(2));
}