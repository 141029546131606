export const PABX_ACTION_TYPES = {
    GET_STATUS : 'pabx/GET_STATUS',
    GET_ALL_PABX : 'pabx/GET_ALL_PABX',
    GET_CATEGORY : 'pabx/GET_CATEGORY',
    GET_BRAND : 'pabx/GET_BRAND',
    GET_TYPE : 'pabx/GET_TYPE',
    GET_SITE : 'pabx/GET_SITE',
    GET_PABX_TYPES_BY_BRAND_ID : 'pabx/GET_PABX_TYPES_BY_BRAND_ID',
    CLEAR_PABX_TYPES_BY_BRAND_ID : 'pabx/CLEAR_PABX_TYPES_BY_BRAND_ID',
    CLEAR_DATA : 'pabx/CLEAR_DATA',
    CHANGE_PAGE : 'pabx/CHANGE_PAGE',
    UPLOAD_PABX_STATUS : 'pabx/UPLOAD_PABX_STATUS',
    UPLOAD_IMAGE_STATUS : 'pabx/UPLOAD_IMAGE_STATUS',
    UPLOAD_FILE_STATUS : 'pabx/UPLOAD_FILE_STATUS',
    UPLOAD_IMAGE : 'pabx/UPLOAD_IMAGE',
    UPLOAD_FILE : 'pabx/UPLOAD_FILE',
    IS_LOADING : 'pabx/IS_LOADING',
    REMOVED : 'pabx/REMOVED',
    UPDATED : 'pabx/UPDATED',
    SEARCH : 'pabx/SEARCH',
    SEARCH_REPORT : 'pabx/SEARCH_REPORT',
    DETAIL_PABX : 'pabx/DETAIL_PABX',
    CLEAR_DETAIL_PABX : 'pabx/CLEAR_DETAIL_PABX',

}