import * as React from "react";

const AssetsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1690 1690"
    {...props}
  >
    <g stroke="currentColor">
      <path
        fill="currentColor"
        strokeWidth={1.17}
        d="M184 1h1323c100 0 183 82 183 183v1323c0 100-83 183-183 183H184c-101 0-183-83-183-183V184C1 83 83 1 184 1zm45 57h1232c94 0 171 77 171 171v1232c0 94-77 171-171 171H229c-94 0-171-77-171-171V229c0-94 77-171 171-171z"
      />
      <rect
        fill="none"
        strokeWidth={8.775}
        x={391}
        y={263}
        width={908}
        height={1165}
        rx={29}
        ry={26}
      />
      <path fill="currentColor" strokeWidth={8.775} d="M1299 1152H726V316z" />
      <path
        fill="#FFFF"
        strokeWidth={8.775}
        d="M726 316c0-28-24-51-55-53h599c16 0 29 12 29 26v863L726 316zM420 263v1165c-16 0-29-12-29-27V289c0-14 13-26 29-26z"
      />
      <path
        fill="#183343"
        strokeWidth={8.775}
        d="M849 495h372v71H897zM782 398h439v70H831zM952 646h269v343h-33z"
      />
      <circle fill="#FFFF" strokeWidth={1.17} cx={763} cy={810} r={254} />
      <path
        fill="#183343"
        strokeWidth={1.17}
        d="M726 1009c-94-17-165-100-165-199s71-181 165-198v397z"
      />
      <path
        fill="currentColor"
        strokeWidth={1.17}
        d="M670 765h193.982v99.055H670z"
      />
    </g>
  </svg>
);

export default AssetsIcon;
