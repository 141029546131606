export const DASHBOARD_ACTION_TYPES = {
    PUT_RESULT : 'dashboard/PUT_RESULT',
    PUT_NETWORK : 'dashboard/PUT_NETWORK',
    FETCH_RESULT_START : 'dashboard/FETCH_RESULT_START',
    FETCH_ALL_SITE : 'dashboard/FETCH_ALL_SITE',
    SHOW_ALL_COVERAGE : 'dashboard/SHOW_ALL_COVERAGE',
    SHOW_ALL_LABEL : 'dashboard/SHOW_ALL_LABEL',
    SHOW_COVERAGE_BY_ID : 'dashboard/SHOW_COVERAGE_BY_ID',
    REGISTER_VIEWER : 'dashboard/REGISTER_VIEWER',
    HANDLE_SHOW_HIDE_FILTER_TOOLBAR : 'dashboard/HANDLE_FILTER_TOOLBAR',
    HANDLE_CLOSE_FILTER_TOOLBAR : 'dashboard/HANDLE_CLOSE_FILTER_TOOLBAR',
    HANDLE_SHOW_DETAIL : 'dashboard/HANDLE_SHOW_DETAIL',
    REGISTER_EVENT_LISTENER : 'dashboard/REGISTER_EVENT_LISTENER',
    REMOVE_LISTENER : 'dashboard/REMOVE_LISTENER',
    SAVE_ENTITY : 'dashboard/SAVE_ENTITY',
    REGISTER_HANDLER_LISTENER : 'dashboard/REGISTER_HANDLER_LISTENER',
    PRIMITIVE : 'dashboard/PRIMITIVE',
}