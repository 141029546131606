import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";

import HeaderPage from "../../components/header/HeaderPage";
import Table from "../../components/table/Table";
import {useDispatch, useSelector} from "react-redux";
import {selectHistoryData, selectHistoryDataReport} from "../../store/maintenance/maintenance.selector";
import {handleEditMaintenanceData, handleGetAllMaintenanceData, handleGetAllMaintenanceDataReport} from "../../store/maintenance/maintenance.action";
import {ASSET_TYPE, MAINTENANCE_TYPE, MAX_REQUEST_SIZE, USER_MABES, siteList} from "../../utils/constant/constants";
import SearchBox from "../../components/search-box/search-box.component";
import {selectCurrentItem, selectDetailAsset} from "../../store/global/global.selector";
import {useNavigate} from "react-router-dom";
import ReportMultipleMaintenanceHistory from "../../components/report/asset/ReportMultipleMaintenanceHistory";
import {selectCurrentUser} from "../../store/user/user.selector";
import {handleLoading} from "../../store/global/global.action";

const COLUMN = [
    {heading: "Name", value: "name"},
    {heading: "Owner", value: "owner"},
    {heading: "Serial Number", value: "serialNo"},
    {heading: "Date Maintenance", value: "date"},
    {heading: "Asset Condition", value: "condition"},
];

const MaintenanceHistory = () => {
    const [searchText, setSearchText] = useState("");
    const [searchTextOnChange, setSearchTextOnChange] = useState("");
    const [searchType, setSearchType] = useState("name");
    const [searchOwner, setSearchOwner] = useState(USER_MABES);
    const [page, setPage] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const historyData = useSelector(selectHistoryData);
    const historyDataReport = useSelector(selectHistoryDataReport);
    const assetToDetail = useSelector(selectDetailAsset);
    const selectedItem = useSelector(selectCurrentItem);
    const currentUser = useSelector(selectCurrentUser);

    useEffect(() => {
        dispatch(handleLoading(true));
        dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.HISTORY, searchText, "name", 0, 10, `history`));
        dispatch(handleGetAllMaintenanceDataReport(MAINTENANCE_TYPE.HISTORY, searchText, "name", 0, MAX_REQUEST_SIZE, `history`));

        return () => {
            setPage(0);
            dispatch(handleLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * DETAIL
     */
    useEffect(() => {
        if (ASSET_TYPE.MAINTENANCE_HISTORY === assetToDetail && selectedItem) { // Detail maintenance asset
            dispatch(handleEditMaintenanceData(MAINTENANCE_TYPE.DATA, true, selectedItem, navigate, false, true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToDetail]);

    useEffect(() => {
        if (searchType === "owner") {
            dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.HISTORY, searchOwner, searchType, 0, 10, `history`));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchType, searchOwner]);

    const onSearch = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));

        setSearchText(searchTextOnChange);

        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value.toLowerCase();
        const type = searchText === "" ? "name" : searchType;

        dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.HISTORY, searchText, type, 0, 10, `history`));

        setPage(0);
    }

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        if (searchType === "owner") {
            dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.HISTORY, searchOwner, searchType, e.selected, 10, `history`));
        } else if (searchType !== "owner" && historyData && historyData.data) {
            dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.HISTORY, searchText, searchType, e.selected, 10, `history`));
        }
        setPage(e.selected);
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    }

    const handleSearchOwner = (e) => {
        setSearchOwner(e.target.value);
        setPage(0);
    };

    return (
        <div className="bg-tertiary min-h-screen py-4">
            <HeaderPage title="Maintenance History" />

            <div className="pb-20 w-[92%] mx-auto mt-24">
                <form className="flex mb-6" onSubmit={onSearch}>
                    <select
                        name="filter"
                        className="select select-bordered w-[200px] rounded-3xl h-[30px]"
                        onChange={(e) => setSearchType(e.target.value)}
                    >
                        <option selected disabled>Select filter</option>
                        <option value="name">Filter by Name</option>
                        {currentUser?.siteName === USER_MABES && (
                            <option value="owner">Filter by Owner</option>
                        )}
                        <option value="condition">Filter by Asset Condition</option>
                    </select>
                    {searchType !== "owner" ? (
                        <SearchBox 
                            name="searchText"
                            value={searchTextOnChange}
                            placeholder="Search Schedule"
                            className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                            onChangeHandler={onSearchChange}
                        />
                    ) : (
                        <select
                            name="filter"
                            className="select min-w-[300px] mx-3 rounded-full px-5"
                            onChange={handleSearchOwner}
                        >
                            {siteList && siteList.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                    <span className="flex justify-center items-center text-white text-lg px-5 mr-auto">
                        <button
                            type="submit"
                            className="btn btn-primary rounded-full text-white text-xs px-7"
                        >
                            Search
                        </button>
                    </span>

                    <ReportMultipleMaintenanceHistory data={historyDataReport?.data} />
                </form>

                {historyData && historyData.data && historyData.data.length > 0 ? (
                    <Table 
                        data={historyData.data} 
                        column={COLUMN} 
                        assetType={ASSET_TYPE.MAINTENANCE_HISTORY}
                    />
                ) : (
                    <p className="flex flex-col text-white text-xl text-center pt-10">
                        Data is empty
                    </p>
                )}

                <div className="text-white flex flex-col">
                    <div className="flex justify-center  my-6 self-end h-14 items-center">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={historyData && historyData.totalPage && historyData.totalPage}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            forcePage={page}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaintenanceHistory;
