import React, {useCallback, useEffect, useRef, useState} from "react";

import {ScreenSpaceEventHandler, ScreenSpaceEventType,} from "cesium";
import {ANALYZE_TYPE, PM} from "../../utils/constant/constants";
import {useDispatch, useSelector} from "react-redux";
import {
    selectChangeKineticRx,
    selectChartData,
    selectDataEntityFromOtherPage,
    selectKinetic,
    selectListenerMouseMove,
    selectPathResponse,
    selectRxPosition,
    selectShowChartLinkBudget,
    selectSignalPowerAtReceiverDBm,
} from "../../store/area/area.selector";
import {flyToIndonesia, registerViewer} from "../../store/dashboard/dashboard.action";
import {
    handleAnyDataChartLinkBudget,
    handleClearData, handleClearLinkBudget,
    handleDrawTx,
    handleLinkBudgetAction,
    handleMouseMoveEventToGetPositionAndBearing,
    handleRegisterKmz,
    handleRegisterMidLabel,
    showCoverageLinkBudget,
} from "../../store/area/area.action";
import {MapViewer} from "../../components/map/MapViewer";
import WhiteTower from "../../components/toolbars/WhiteTower";
import RedTower from "../../components/toolbars/RedTower";
import RightPannel from "../../components/drawer/RightPannel";
import {addMidLabel, getTerrainHeightByDegree} from "../../utils/cesium/cesiumUtils";
import ChartLinkBudget from "../../components/chart/ChartLinkBudget";
import ShowPannel from "../../components/drawer/ShowPannel";
import Recycle from "../../components/toolbars/Recycle";
import {handleLoading} from "../../store/global/global.action";
import {handleRedTowerToggle, handleWhiteTowerToggle} from "../../store/tower/tower.action";
import { generalAlert } from "../../utils/notification/notification";

const RadioLink = () => {
    const ref = useRef(null); // viewer
    const refReport = useRef(null); // report
    const [handler, setHandler] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [chartLabel, setChartLabel] = useState(null);
    const [obstacle, setObstacle] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [terrainHeight, setTerrainHeight] = useState(0);
    const [oriTxAlt, setOriTxAlt] = useState(0);
    const [txAlt, setTxAlt] = useState(0);

    const dispatch = useDispatch();
    const showChartLinkBudget = useSelector(selectShowChartLinkBudget);
    const dataEntityFromDashboard = useSelector(selectDataEntityFromOtherPage);
    const signalPowerAtReceiverDBm = useSelector(selectSignalPowerAtReceiverDBm);
    const chartInformation = useSelector(selectChartData);
    const changeKineticRx = useSelector(selectChangeKineticRx);
    const pathRes = useSelector(selectPathResponse);
    const rxPosition = useSelector(selectRxPosition);
    const kinetickRx = useSelector(selectKinetic);
    const listenerMouseMove = useSelector(selectListenerMouseMove);

    useEffect(() => {
        const viewer = ref.current.cesiumElement;
        dispatch(registerViewer(viewer));
        flyToIndonesia(viewer);
        const handlerClick = new ScreenSpaceEventHandler(viewer.scene.canvas);
        setHandler(handlerClick);

        if (dataEntityFromDashboard) {
            setOriTxAlt(dataEntityFromDashboard.data.transmitter.alt);
            viewer.scene.globe.tileLoadProgressEvent.addEventListener(handleTileLoadListener(viewer));

            // dispatch(handleDrawTxLinkBudget(dataEntityFromDashboard.data, viewer));
            dispatch(handleRegisterKmz(viewer, dataEntityFromDashboard.result.kmz));

            handleInfoLabelWhenMouseMove(viewer);
        } else {
            generalAlert("Warning", "Select TX Before Using Radio Link Budget", "warning");
        }

        return () => {
            if (viewer) {
                dispatch(handleLoading(false));
                setTerrainHeight(0);
                dispatch(handleClearLinkBudget());
                dispatch(handleClearData(viewer));
                handlerClick.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
                setChartData(null);
                setChartLabel(null);
                if (viewer.dataSources && viewer.entities && viewer.scene) {
                    viewer.dataSources.removeAll();
                    viewer.entities.removeAll();
                    viewer.scene.globe.depthTestAgainstTerrain = false;
                }

                if (listenerMouseMove) {
                    listenerMouseMove.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
                }
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ((changeKineticRx && changeKineticRx.latitude && changeKineticRx.longitude
                && dataEntityFromDashboard && dataEntityFromDashboard.data) ||
            ((kinetickRx && kinetickRx.alt !== null) && dataEntityFromDashboard && dataEntityFromDashboard.data)) {
            const cpData = JSON.parse(JSON.stringify(dataEntityFromDashboard.data));
            const {latitude, longitude} = changeKineticRx;
            // path request
            // dispatch(showCoverageLinkBudget(cpData, latitude, longitude, ref.current.cesiumElement, txAlt));
            if (kinetickRx && kinetickRx.alt !== null && kinetickRx.for && kinetickRx.for === "rx") {
                dispatch(showCoverageLinkBudget(cpData, latitude, longitude, ref.current.cesiumElement, txAlt, kinetickRx.alt));
            } else {
                dispatch(showCoverageLinkBudget(cpData, latitude, longitude, ref.current.cesiumElement, txAlt));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeKineticRx])

    useEffect(() => {
        if (dataEntityFromDashboard && dataEntityFromDashboard.data) {
            const alt = dataEntityFromDashboard.data.transmitter.alt + terrainHeight
            dataEntityFromDashboard.data.transmitter.originalTxAlt = oriTxAlt;
            setTxAlt(alt);
            const transmitter = dataEntityFromDashboard.data.transmitter;
            dispatch(handleDrawTx(transmitter.lon, transmitter.lat, alt, ref.current.cesiumElement));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [terrainHeight]);

    useEffect(() => {
        if (handler && dataEntityFromDashboard) {
            const cpData = JSON.parse(JSON.stringify(dataEntityFromDashboard.data));

            cpData.receiver.alt = kinetickRx && kinetickRx.alt ?
                kinetickRx.alt : cpData.receiver.alt;

            dispatch(
                handleLinkBudgetAction(
                    ref.current.cesiumElement,
                    cpData,
                    handler,
                    txAlt
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handler]);

    useEffect(() => {
        if (signalPowerAtReceiverDBm) {
            const viewer = ref.current.cesiumElement;
            const {rx, tx} = rxPosition;
            const {txLat, txLon, txAlt} = tx;
            const {rxLat, rxLon, rxAlt} = rx;
            const {dbm} = signalPowerAtReceiverDBm;
            const point1 = {latitude: txLat, longitude: txLon, altitude: txAlt};
            const point2 = {latitude: rxLat, longitude: rxLon, altitude: rxAlt};
            const label = addMidLabel(viewer, point1, point2, `${dbm} dBm`);
            dispatch(handleRegisterMidLabel(label));
            dispatch(handleLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signalPowerAtReceiverDBm]);

    useEffect(() => {
        if (chartInformation) {
            const {distanceArr, terrainArr, losArr, fresnelArr, obstacle} = chartInformation;
            setObstacle(obstacle);
            setChartLabel(distanceArr);
            setChartData([
                {
                    label: "Terrain",
                    data: terrainArr,
                },
                {
                    label: "LOS",
                    data: losArr,
                },
                {
                    label: "Fresnel",
                    data: fresnelArr,
                },
            ]);
            dispatch(handleAnyDataChartLinkBudget(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartInformation]);

    const handleInfoLabelWhenMouseMove = viewer => {
        if (listenerMouseMove) {
            listenerMouseMove.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
            viewer.entities.removeById("ms");
        }

        if (dataEntityFromDashboard) {
            const latitude = dataEntityFromDashboard.data.transmitter.lat;
            const longitude = dataEntityFromDashboard.data.transmitter.lon;
            dispatch(handleMouseMoveEventToGetPositionAndBearing(viewer, latitude, longitude, true));
        }
    };

    const handleTileLoadListener = viewer => () => {
        if (viewer.scene.globe.tilesLoaded) {
            if (viewer.baseLayerPicker.viewModel.selectedTerrain.name === `Cesium World Terrain`) {
                const lon = dataEntityFromDashboard.data.transmitter.lon;
                const lat = dataEntityFromDashboard.data.transmitter.lat;

                getTerrainHeightByDegree(lat, lon).then((result) => {
                        setTerrainHeight(result)
                    }
                );
            } else {
                setTerrainHeight(0);
            }
        }
    };

    const detailTxAndRx = () => {
        if (pathRes) {
            const {
                distanceToReceiverKm,
                downtiltAngleDeg,
                azimuthToReceiverDeg,
                fieldStrengthAtReceiverdBuVm,
                freeSpacePathLossdB,
                computedPathLossDB,
                signalPowerAtReceiverDBm,
                antennaGainDBd,
                antennaGainDBi,
                erpW,
                eirpW,
                erpDBm,
                eirpDBm,
            } = pathRes.resultPath.transmitters[0];
            const {frq} = pathRes.pathReqDto.transmitter;
            const {receiverGainDBd, receiverGainDBi} = pathRes.resultPath.receiver[0];
            const {pm} = pathRes.pathReqDto.model;
            const model = PM.find((d) => d.value === pm);

            return (
                <div className="text-xs text-white px-12 pt-3">
                    <div className="flex gap-x-2">
                        <p>Distance: {distanceToReceiverKm} Km</p>
                        <p>Bearing to Rx: {azimuthToReceiverDeg}&#176;</p>
                        <p>Downtilt to Rx: {downtiltAngleDeg}&#176;</p>
                    </div>
                    <div className="flex gap-x-2">
                        <p>Frequency: {frq} MHz</p>
                        <p>Model: {model.label}</p>
                        <p>Freespace Loss: {freeSpacePathLossdB}dB</p>
                        <p>Path Loss: {computedPathLossDB}dB</p>
                        <p>Received-power: {signalPowerAtReceiverDBm}dBm</p>
                        <p>Field-strength: {fieldStrengthAtReceiverdBuVm} dBuV/m</p>
                    </div>
                    <div className="flex gap-x-2">
                        <p>
                            Tx antenna gain: {antennaGainDBd}dB / {antennaGainDBi}dBi
                        </p>
                        <p>
                            ERP: {erpW}W / {erpDBm}dBm
                        </p>
                        <p>
                            EIRP: {eirpW}W / {eirpDBm}dBm
                        </p>
                    </div>
                    <div className="flex gap-x-2">
                        <p>
                            Rx antenna gain: {receiverGainDBd}dBd / {receiverGainDBi}dBi
                        </p>
                    </div>
                </div>
            );
        }
    };

    const downloadChart = useCallback(() => {
        return refReport.current.toBase64Image();
    }, []);

    const handleClearMap = () => {
        if (ref.current.cesiumElement) {
            setChartData(null);
            setChartLabel(null);
            dispatch(handleAnyDataChartLinkBudget(false));

            const viewer = ref.current.cesiumElement;
            // reset
            // dispatch(createAction(AREA_ACTION_TYPES.SHOW_HIDE_CHART_LINK_BUDGET, false));
            // if (tileLoadListener) {
            //     viewer.scene.globe.tileLoadProgressEvent.removeEventListener(tileLoadListener);
            // }
            // if (handler) {
            //     handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
            // }

            // const handlerClick2 = new ScreenSpaceEventHandler(viewer.scene.canvas);
            // setHandler(handlerClick2);

            if (dataEntityFromDashboard) {
                setOriTxAlt(dataEntityFromDashboard.data.transmitter.alt);
                // viewer.scene.globe.tileLoadProgressEvent.addEventListener(handleTileLoadListener(viewer));
                const {lat, lon, alt} = dataEntityFromDashboard.data.transmitter
                dispatch(handleDrawTx(lon, lat, alt + terrainHeight, viewer));
                // dispatch(handleDrawTxLinkBudget(dataEntityFromDashboard.data, viewer));
                dispatch(handleRegisterKmz(viewer, dataEntityFromDashboard.result.kmz));

                handleInfoLabelWhenMouseMove(viewer);
            }

            dispatch(handleWhiteTowerToggle(false))
            dispatch(handleRedTowerToggle(false))
        }
    }

    return (
        <>
            <div
                style={{
                    height: "calc(100% - 77px)",
                }}
            >
                <div className="flex flex-row">
                    <MapViewer ref={ref} isOpen={isOpen}>
                        <div>
                            <Recycle callBackClearMap={handleClearMap}/>
                            <WhiteTower/>
                            <RedTower/>
                        </div>
                    </MapViewer>
                    <RightPannel
                        analyzeType={ANALYZE_TYPE.LINK_BUDGET}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        chartImage={downloadChart}
                    />
                </div>
                <ShowPannel
                    isOpen={isOpen}
                    setIsOpen={() => {
                        setIsOpen((prevIsOpen) => !prevIsOpen);
                    }}
                />
            </div>

            {showChartLinkBudget &&
                chartLabel &&
                chartLabel.length &&
                chartData &&
                chartData.length && (
                    <div
                        className={`absolute bottom-0 bg-[#000000a9] text-gray-700 h-[450px] ${
                            isOpen
                                ? "w-[73vw] transition-width duration-300"
                                : "w-[95vw]"
                        }`}
                    >
                        {detailTxAndRx()}
                        <section className="px-6 pt-8">
                            <ChartLinkBudget
                                ref={refReport}
                                isOpen={isOpen}
                                chartLabel={chartLabel}
                                chartData={chartData}
                                anyObstacle={obstacle}
                            />
                        </section>
                    </div>
                )}
        </>
    );
};

export default RadioLink;
