import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ReactPaginate from "react-paginate";
import {useForm} from "react-hook-form";

import HeaderPage from "../../../components/header/HeaderPage";
import Table from "../../../components/table/Table";
import {ModalForm} from "../../../components/modal/ModalForm";
import {
    selectAllMasterTemplate,
    selectAllTaskByTemplateId,
    selectCreateStatus,
    selectRemoveStatus,
    selectTotalPages,
    selectUpdateStatus
} from "../../../store/template/template.selector";
import {
    handleClearActionStatus,
    handleClearTask,
    handleCreateMasterTemplateTask,
    handleCreateNewTask,
    handleDeleteTaskInTamplate,
    handleEditTaskByTemplateId,
    handleEditTemplate,
    handleGetAllMasterTemplate,
    handleGetTaskByTemplateId,
    handleRemoveTemplate,
    handleSearchMasterTemplate
} from "../../../store/template/template.action";
import {
    ALERT_ICON_FAILED,
    ALERT_ICON_SUCCESS,
    ALERT_TEXT_CREATE_FAILED,
    ALERT_TEXT_CREATE_SUCCESS,
    ALERT_TEXT_REMOVE_FAILED,
    ALERT_TEXT_REMOVE_SUCCESS,
    ALERT_TEXT_UPDATE_FAILED,
    ALERT_TEXT_UPDATE_SUCCESS,
    ALERT_TITLE_FAILED,
    ALERT_TITLE_SUCCESS,
    ASSET_TYPE,
    COLUMN_TASK_TEMPLATE,
    COLUMN_TEMPLATE,
    CONFIRMATION_ICON_REMOVE,
    CONFIRMATION_TEXT_REMOVE,
    CONFIRMATION_TITLE_REMOVE,
} from "../../../utils/constant/constants";
import {handleClearStateGlobal, handleLoading} from "../../../store/global/global.action";
import {
    selectAssetToEdit,
    selectAssetToRemove,
    selectCurrentItem,
    selectDetailAsset
} from "../../../store/global/global.selector";
import {EMPTY_STRING, FAILED, SUCCESS} from "../../../store/template/template.types";
import {generalAlert, generalConfirmation} from "../../../utils/notification/notification";
import SearchBox from "../../../components/search-box/search-box.component";


const TemplateMaintenance = () => {
    const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm(({
        defaultValues: {
            taskText: "",
        }
    }));
    const [isUpdate, setIsUpdate] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [open, setOpen] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const [searchText, setSearchText] = useState(EMPTY_STRING);
    const [searchTextOnChange, setSearchTextOnChange] = useState(EMPTY_STRING);
    const [page, setPage] = useState(0);
    const [editable, setEditable] = useState({});
    const [isTaskEditable, setIsTaskEditable] = useState(true);
    
    const assetToDetail = useSelector(selectDetailAsset);
    const assetToRemove = useSelector(selectAssetToRemove);
    const assetToEdit = useSelector(selectAssetToEdit);
    const selectedItem = useSelector(selectCurrentItem);
    const taskBySelectedTemplate = useSelector(selectAllTaskByTemplateId);
    const allMasterTemplate = useSelector(selectAllMasterTemplate);
    const totalPages = useSelector(selectTotalPages);
    const createStatus = useSelector(selectCreateStatus);
    const updateStatus = useSelector(selectUpdateStatus);
    const removeStatus = useSelector(selectRemoveStatus);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(handleLoading(true));
        dispatch(handleGetAllMasterTemplate(10, page));

        return () => {
            dispatch(handleClearStateGlobal());
            setOpen(false);
            setTemplateName(EMPTY_STRING);
            dispatch(handleLoading(false));
            setIsUpdate(false);
            setSelectedTemplate(null);
            setSelectedTask(null);
            setSearchText(EMPTY_STRING);
            setSearchTextOnChange(EMPTY_STRING);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * CREATE TEMPLATE MAINTENANCE
     */
    useEffect(() => {
        if (createStatus) {
            const isSuccess = SUCCESS === createStatus;
            const isFailed = FAILED === createStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_CREATE_SUCCESS : ALERT_TEXT_CREATE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => dispatch(handleClearActionStatus())
                );
                dispatch(handleSearchMasterTemplate(searchText, page, 10));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createStatus]);

    /**
     * EDIT TEMPLATE MAINTENANCE
     */
    useEffect(() => {
        if (ASSET_TYPE.TEMPLATE === assetToEdit && selectedItem) {
            setIsUpdate(true);
            setTemplateName(selectedItem.name);
            setSelectedTemplate(selectedItem);
            dispatch(handleClearStateGlobal());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToEdit])

    useEffect(() => {
        if (updateStatus) {
            const isSuccess = SUCCESS === updateStatus;
            const isFailed = FAILED === updateStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_UPDATE_SUCCESS : ALERT_TEXT_UPDATE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => dispatch(handleClearActionStatus())
                );
                dispatch(handleSearchMasterTemplate(searchText, page, 10));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStatus]);

    /**
     * REMOVE TEMPLATE MAINTENANCE
     */
    useEffect(() => {
        if (ASSET_TYPE.TEMPLATE === assetToRemove && selectedItem) {
            generalConfirmation(
                CONFIRMATION_TITLE_REMOVE,
                CONFIRMATION_TEXT_REMOVE(selectedItem.name),
                CONFIRMATION_ICON_REMOVE,
                () => {
                    dispatch(handleLoading(true));
                    dispatch(handleRemoveTemplate(selectedItem.id))
                    setTemplateName(EMPTY_STRING);
                },
                () => dispatch(handleClearStateGlobal()),
            )
            setIsUpdate(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToRemove]);

    useEffect(() => {
        if (removeStatus) {
            const isSuccess = SUCCESS === removeStatus;
            const isFailed = FAILED === removeStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_REMOVE_SUCCESS : ALERT_TEXT_REMOVE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => dispatch(handleClearActionStatus())
                );
            }
            if (allMasterTemplate && allMasterTemplate.length) {
                dispatch(handleSearchMasterTemplate(searchText, page, 10));
            } else {
                if (page) {
                dispatch(handleSearchMasterTemplate(searchText, page - 1, 10));
                    setPage(prevPage => prevPage - 1);
                } else {
                    dispatch(handleSearchMasterTemplate(searchText, 0, 10));
                    setPage(0);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeStatus]);

    /**
     * DETAIL TEMPLATE MAINTENANCE
     */
    useEffect(() => {
        if (ASSET_TYPE.TEMPLATE === assetToDetail && selectedItem) {
            dispatch(handleGetTaskByTemplateId(selectedItem.id));
            setSelectedTemplate(selectedItem);
            setEditable(selectedItem?.editable)
            setTemplateName(EMPTY_STRING);
            setIsUpdate(false);
            setOpen(!open);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToDetail]);

    /**
     * EDIT TEMPLATE TASK
     */
    useEffect(() => {
        if (ASSET_TYPE.TEMPLATE_TASK === assetToEdit && selectedItem) {
            // edit task by template id
            setValue("taskText", selectedItem.name)
            setSelectedTask(selectedItem);
            setIsUpdate(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToEdit]);

    /**
     * REMOVE TEMPLATE TASK
     */
    useEffect(() => {
        if (ASSET_TYPE.TEMPLATE_TASK === assetToRemove && selectedItem) {
            // remove task by template id
            if (selectedTemplate) {
                dispatch(handleDeleteTaskInTamplate(selectedTemplate.id, selectedItem.id));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToRemove]);

    const handleOpenClose = () => {
        reset();
        dispatch(handleClearStateGlobal());
        dispatch(handleClearTask());
        setIsTaskEditable(true);
        setSelectedTemplate(null);
        setOpen(!open);
    };

    const handleApply = (e) => {
        e.preventDefault();
        dispatch(handleClearActionStatus());
        
        if (templateName !== EMPTY_STRING) {
            if (isUpdate) {
                dispatch(handleLoading(true));
                dispatch(handleEditTemplate(selectedTemplate.id, templateName, page));
                setSelectedTemplate(null);
                setIsUpdate(false);
            } else {
                dispatch(handleLoading(true));
                dispatch(handleCreateMasterTemplateTask(templateName, page));
            }
            setSelectedTemplate(null);
            setTemplateName(EMPTY_STRING);
        }
    };

    const handleSearchTemplate = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));
    
        setSearchText(searchTextOnChange);
    
        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value;
    
        dispatch(handleSearchMasterTemplate(searchText, 0, 10));
    
        setPage(0);
    };

    const handleSumbitTask = (data) => {
        // save task for template by id'
        if (isUpdate && selectedTemplate && selectedTask) {
            dispatch(handleEditTaskByTemplateId(selectedTemplate.id, selectedTask.id, data.taskText));
            setSelectedTask(null);
            setIsUpdate(false);
        } else {
            dispatch(handleCreateNewTask(data.taskText, selectedTemplate.id));
        }
        reset();
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    };

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        dispatch(handleSearchMasterTemplate(searchText, e.selected, 10));
        setPage(e.selected);
    };

    const handleEditableTask = (data) => {
        setIsTaskEditable(data);
    };

    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="Template Maintenance"/>

            <div className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">
                    <form className="flex justify-start mb-6" onSubmit={handleSearchTemplate}>
                        <SearchBox
                            name="searchText"
                            value={searchTextOnChange}
                            placeholder="Search Template Maintenance"
                            className="input min-w-[500px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                            onChangeHandler={onSearchChange}
                        />
                        <span className="flex justify-center items-center text-white text-lg px-1 mr-auto">
                            <button
                                type="submit"
                                className="btn btn-primary rounded-full text-white text-xs px-7"
                            >
                                Search
                            </button>
                        </span>
                    </form>
                    <div className="createModal">
                        <ModalForm
                            open={open}
                            onClose={handleOpenClose}
                            title="Add Task"
                            btnCloseTitle="Cancel"
                            btnSubmitTitle="Save"
                            size="w-2/3"
                            closeBtn={true}
                            btn={false}
                        >

                            <div className="flex mx-5 gap-x-8 mt-10 pb-5">
                                <div className="w-[50%] text-black">
                                    {taskBySelectedTemplate && taskBySelectedTemplate.length > 0 ? (
                                        <Table
                                            data={taskBySelectedTemplate}
                                            column={COLUMN_TASK_TEMPLATE}
                                            assetType={ASSET_TYPE.TEMPLATE_TASK}
                                            action={editable}
                                        />
                                    ) : (
                                        <p className="flex flex-col text-white text-xl text-center pt-10">
                                            Task is empty
                                        </p>
                                    )}
                                </div>

                                <div className="w-[50%] mb-20">
                                    <form>
                                        <div className="flex flex-col mb-5">
                                            <label htmlFor="taskText" className="font-bold mb-1">Task Template</label>
                                            <input
                                                id="taskText"
                                                {...register("taskText", {
                                                    required: "Required!",
                                                    pattern: {
                                                        value: /^\S+/,
                                                        message: "Entered value cant start or contain only white spacing"
                                                    },
                                                })}
                                                pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                                title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                                className={`input input-bordered w-full text-black text-sm mt-1 mb-4 border-2 focus:border-purple-500 ${errors?.taskText ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                                disabled={!isTaskEditable}
                                                placeholder={errors.taskText ? errors?.taskText?.message : "Task Name"}
                                            />
                                        </div>
                                        <div className="flex justify-end">
                                            <button
                                                type="button"
                                                onClick={handleOpenClose}
                                                className="btn btn-outline text-white text-xs px-7 mr-2"
                                            >
                                                Done
                                            </button>
                                            <button
                                                type="submit"
                                                onClick={handleSubmit(handleSumbitTask)}
                                                className="btn btn-primary text-white text-xs px-10"
                                                disabled={!isTaskEditable}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </ModalForm>
                    </div>

                    {allMasterTemplate && allMasterTemplate.length > 0 ? (
                        <Table
                            data={allMasterTemplate}
                            column={COLUMN_TEMPLATE}
                            assetType={ASSET_TYPE.TEMPLATE}
                            isTaskEditable={handleEditableTask}
                        />
                    ) : (
                        <p className="flex flex-col text-white text-center pt-10">
                            Data is empty
                        </p>
                    )}

                    <div className="text-white flex flex-col">
                        <div className="flex justify-center  my-6 self-end h-14 items-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={page}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-[40%] mt-28">
                    <form onSubmit={handleApply}>
                        <div className="flex flex-col mb-5">
                            <label htmlFor="templateName" className="font-bold text-white mb-1">Create Template</label>
                            <input
                                id="templateName"
                                name="templateName"
                                pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                className="input input-bordered w-full text-black text-sm border-2 focus:border-purple-500"
                                placeholder="Template Name"
                                value={templateName}
                                onChange={(e) => setTemplateName(e.target.value)}
                            />
                        </div>
                        <div className="flex gap-x-4 mt-7 justify-end">
                            <button
                                type="button"
                                onClick={() => setTemplateName("")}
                                className="btn btn-outline text-white text-xs px-7"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary text-white text-xs px-10"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default TemplateMaintenance;
