import { Cartesian2, ScreenSpaceEventType } from "cesium";
import {
    ADD_KINETIC,
    CREATE_FO,
    DELETE_ASSET,
    DELETE_KINETIC,
    GET_DETAIL_BY_ID,
    GET_DETAIL_MULTI,
    SEARCH,
    UPDATE_FO
} from "../../services/fo";
import { addMidLabel, addPolyline, createEntityPointWithLabel, getDistanceString } from "../../utils/cesium/cesiumUtils";
import { STATUS_TYPE } from "../../utils/constant/constants";
import { createAction } from "../../utils/reducer/reducerUtil";
import { handleClearStateGlobal, handleLoading } from "../global/global.action";
import { GLOBAL_ACTION_TYPES } from "../global/global.types";
import { FO_ACTION_TYPES } from "./fo.types";
import { createPayloadAddKinetic, getAllId } from "./fo.util";

const addPolylineEntity = (polylineEntity) => dispatch => {
    dispatch(createAction(FO_ACTION_TYPES.ADD_POLYLINE, polylineEntity));
}
const addLabelEntity = (labelEntity) => dispatch => {
    dispatch(createAction(FO_ACTION_TYPES.ADD_DISTANCE_LABEL, labelEntity));
}
const addDistanceNumber = (text) => dispatch => {
    dispatch(createAction(FO_ACTION_TYPES.ADD_DISTANCE_NUMBER, Number(text.split(" ")[0])));
}

export const handleEditAsset = (data, goToAsset) => dispatch => {
    dispatch(createAction(FO_ACTION_TYPES.GO_TO_EDIT_ASSET, {data, goToAsset}));
}

export const handleAfterGoToEdit = () => dispatch => {
    dispatch(createAction(FO_ACTION_TYPES.GO_TO_EDIT_ASSET, {data: null, goToAsset: false}));
}

export const handleUpdateData = (assetId, payload, navigate) => dispatch => {
    UPDATE_FO(assetId, payload)
        .then(res => {
            dispatch(createAction(FO_ACTION_TYPES.UPDATED, STATUS_TYPE.SUCCESS));

            dispatch(createAction(FO_ACTION_TYPES.UPDATE_DATA, res));
            navigate(-1);
        })
        .catch(e => {
            dispatch(createAction(FO_ACTION_TYPES.UPDATED, STATUS_TYPE.FAILED));
        });
}

export const handleDeletePoint = (assetId, payload, points = null, updatePayload = null, navigate) => dispatch => {
    DELETE_KINETIC(assetId, payload)
        .then(res => {
            dispatch(createAction(FO_ACTION_TYPES.DELETE_KINETIC_POINT, res));
            if (points) {
                dispatch(handleAddPoint(assetId, createPayloadAddKinetic(points), updatePayload, navigate));
            } else {
                // update data
                dispatch(handleUpdateData(assetId, updatePayload, navigate));
            }
        })
        .catch(() => {});
}

export const handleAddPoint = (assetId, payload, updatePayload = null, navigate) => dispatch => {
    ADD_KINETIC(assetId, payload)
        .then(res => {
            dispatch(createAction(FO_ACTION_TYPES.ADD_KINETIC_POINT, res));
            // update data
            dispatch(handleUpdateData(assetId, updatePayload, navigate));
        })
        .catch(() => {});
}

export const handleMultiDetail = (payload) => dispatch => {
    GET_DETAIL_MULTI(payload)
        .then(res => {
            dispatch(createAction(FO_ACTION_TYPES.DETAIL_MULTI, res));
        })
        .catch(() => {});
};
export const handleGetDetailAsset = (id) => dispatch => {
    GET_DETAIL_BY_ID(id)
        .then(res => {
            dispatch(createAction(FO_ACTION_TYPES.EDIT_ASSET, res));
        })
        .catch(() => {});
};

export const handleDeleteAsset = (assetId, page) => dispatch => {
    DELETE_ASSET(assetId)
        .then(() => {
            dispatch(createAction(FO_ACTION_TYPES.REMOVED, STATUS_TYPE.SUCCESS))
            dispatch(handleSearch("", "name", 10, page));
            dispatch(handleClearStateGlobal());
        })
        .catch(e => {
            dispatch(createAction(FO_ACTION_TYPES.REMOVED, STATUS_TYPE.FAILED))
            dispatch(handleClearStateGlobal());
        });
}

export const handleSearch = (query, queryType, size, page) => dispatch => {
    SEARCH(query.toLowerCase(), queryType, size, page)
        .then(res => {
            dispatch(createAction(FO_ACTION_TYPES.SEARCH_RESPONSE, res));
            dispatch(handleMultiDetail(getAllId(res)))
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        });
}

export const handleRegisterClickEvent = (viewer, handlerClick) => dispatch => {
    const ellipsoid = viewer.scene.globe.ellipsoid
    handlerClick.setInputAction((click) => {
        const mousePosition = new Cartesian2(click.position.x, click.position.y);
        const cartesian = viewer.camera.pickEllipsoid(mousePosition, ellipsoid);
        if (cartesian) {
            const cartographic = ellipsoid.cartesianToCartographic(cartesian);
            const lat = parseFloat((cartographic.latitude * 180 / Math.PI).toFixed(6));
            const lon = parseFloat((cartographic.longitude * 180 / Math.PI).toFixed(6));
            // store kinetic
            dispatch(handleAddAPoint(lat, lon, cartesian));

        }
    }, ScreenSpaceEventType.LEFT_CLICK);
};

export const handleAddAPoint = (lat, lon, cartesian) => dispatch => {
    dispatch(createAction(FO_ACTION_TYPES.ADD_POINT, {lat, lon, cartesianPosition: cartesian}));
}

export const handleEntityPoint = (viewer, cartesian, textLabel) => dispatch => {
    const point = createEntityPointWithLabel(viewer, cartesian, textLabel);
    dispatch(createAction(FO_ACTION_TYPES.ADD_ENTITY_POINT, point));
}

export const handleEntityLine = (viewer, point1, point2) => dispatch => {

    const polylineEntity = addPolyline(viewer, point1, point2);
    // create label
    const text = getDistanceString(point1, point2, true)
    const labelEntity = addMidLabel(viewer, point1, point2, text);

    dispatch(addDistanceNumber(text));
    dispatch(addPolylineEntity(polylineEntity));
    dispatch(addLabelEntity(labelEntity));
}


export const handleRemoveAPoint = (index) => dispatch => {
    dispatch(createAction(FO_ACTION_TYPES.REMOVE_A_POINT, index));
}
export const handleRemoveAPolylineAndAMidlabel = () => dispatch => {
    dispatch(createAction(FO_ACTION_TYPES.REMOVE_A_POLYLINE_AND_A_MID_LABEL));
}

export const handlePostFO = (payload, navigate) => dispatch => {
    CREATE_FO(payload)
        .then(res => {
            dispatch(createAction(FO_ACTION_TYPES.UPLOAD_FO_STATUS, STATUS_TYPE.SUCCESS));
            dispatch(createAction(FO_ACTION_TYPES.RESPONSE_POST_FO, res));
            navigate(-1);
        })
        .catch(e => {
            dispatch(createAction(FO_ACTION_TYPES.UPLOAD_FO_STATUS, STATUS_TYPE.FAILED));
        });
}

export const handleClearStorage = () => dispatch => {
    dispatch(createAction(FO_ACTION_TYPES.CLEAR_STORAGE));
}
export const handleClearExcForUpdate = (detailAsset) => dispatch => {
    dispatch(createAction(FO_ACTION_TYPES.CLEAR_STORAGE_EXCEPT_UPDATE, detailAsset));
}

export const handleClearNotif = () => dispatch => {
    dispatch(createAction(FO_ACTION_TYPES.UPLOAD_FO_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(FO_ACTION_TYPES.UPDATED, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(FO_ACTION_TYPES.REMOVED, STATUS_TYPE.NO_STATEMENT));
}