import React from "react";

import HeaderPage from "../../components/header/HeaderPage";
import { LICENSE_KEY, PART_NUMBER, SERIAL_NUMBER } from "../../utils/constant/constants";
import RDCSLogo from "../../assets/png/title_logo.png";
import { ActivationIcon, ActiveIcon } from "../../assets/svgs";

const About = () => {
    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="Product Information"/>

            <main className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">
                    <div className="mx-4 mb-10">
                        <img className="my-3" src={RDCSLogo} alt="logo" />
                        <h2 className="text-white text-xl">Radio Diagnostic Coverage Simulation</h2>
                    </div>

                    <div className="bg-[#183343] text-white w-3/4 rounded-md mt-10 p-4">
                        <div className="flex justify-between items-center">
                            <div className="flex gap-x-2 items-center">
                                <ActivationIcon />
                                <p>Activation State</p>
                            </div>

                            <div className="flex gap-x-2 items-center">
                                <p className="font-bold">Active</p>
                                <ActiveIcon />
                            </div>
                        </div>

                        <br />

                        <p>RDCS is acivated with a license linked to your Hardware with details :</p>

                        <br />

                        <p>Serial Number: <b>{`${SERIAL_NUMBER}`}</b></p>
                        <p>Part Number: <b>{`${PART_NUMBER}`}</b></p>
                        <p>License Key: <b>{`${LICENSE_KEY}`}</b></p>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default About;