import React, {forwardRef} from "react";
import { CurrentLocationIcon } from "../../assets/svgs";


const LabelSelect = forwardRef(({titleName, unit, options, type, getCurrentPosition, ...props}, ref) => {
    const RenderIcon = ({type}) => {
        switch (type) {
            case "cl":
                return <CurrentLocationIcon />;
            default:
                return "";
        }
    };

    return (
        <div className="flex flex-col gap-y-3 my-2">
            <div className="flex justify-between items-center px-4">
                <div className="text-white text-sm">{titleName}</div>
                    <div className="flex flex-row w-3/5 items-center justify-start ml-6 h-[40px]">
                    <select ref={ref} {...props}
                            className="select w-full max-w-[170px] border-2 border-white bg-transparent text-white rounded-3xl text-md min-h-[32px] h-[32px] font-thin"
                        >
                            {options && options.length > 0 &&
                                options.map((el) => {
                                return (
                                    <option
                                        value={el.value}
                                        key={el.value}
                                        className="h-[40px] text-black focus:text-gray"
                                    >
                                        {el.label}
                                    </option>
                                )
                            })}
                    </select>   
                    {!!unit && (
                        <p className="text-md text-white ml-2">{unit}</p>
                    )}
                    {!!type && (
                        <button
                            type="button"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Get Current Location"
                            className="ml-2"
                            onClick={getCurrentPosition} 
                        >
                            <RenderIcon type={type} />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
})

export default LabelSelect;
