import {BASE_URL} from "../../services/core";

export const MAX_REQUEST_SIZE = Number(process.env.REACT_APP_MAX_REQUEST_SIZE);
export const PLATFORM = process.env.REACT_APP_PLATFORM;

export const BASE_OSM_SERVER = process.env.REACT_APP_BASE_OSM_SERVER;

export const SERIAL_NUMBER = process.env.REACT_APP_SERIAL_NUMBER;
export const PART_NUMBER = process.env.REACT_APP_PART_NUMBER;
export const LICENSE_KEY = process.env.REACT_APP_LICENSE_KEY;

export const MAX_TIMEOUT = 60000;

export const USER_MABES = "MABES"

export const COORDINATE_FORMAT = [
    {value: "Decimal Degrees", label: "Decimal Degrees", unit: "D.D"},
    {value: "Degrees Minutes Seconds", label: "Degrees Minutes Seconds", unit: "D.M.S"},
    {value: "Degrees Decimal Minutes", label: "Degrees Decimal Minutes", unit: "D.D.M"}
];

export const COORDINATE_TYPE = {
    DD: "Decimal Degrees",
    DMS: "Degrees Minutes Seconds",
    DDM: "Degrees Decimal Minutes",
}

export const UNIT = {
    FEET: 'feet',
    METER: 'm',
    KM: 'KM',
    NM: 'NM',
    NM2: 'NM2',
    KM2: 'KM2',
}
export const DIRECTION = [{value: "v", label: "Vertical"}, {value: "h", label: "Horizontal"}];
export const Connectors = [
    {value: 0, label: 0},
    {value: 1, label: 1},
    {value: 2, label: 2},
    {value: 3, label: 3},
    {value: 4, label: 4},
    {value: 5, label: 5},
    {value: 6, label: 6},

];
export const MultiplyAltitude = [
    {value: 3, label: 3},
    {value: 4, label: 4},
    {value: 5, label: 5},
    {value: 6, label: 6},
    {value: 7, label: 7},

];

export const Context = [
    {
        value: 1,
        label: "Conservative / Urban",
    },
    {
        value: 2,
        label: "Average / Mixed",
    },
    {
        value: 3,
        label: "Optimistic / Unobstructed",
    },
];
export const Diffraction = [
    {
        value: 0,
        label: "Off (LOS)",
    },
    {
        value: 1,
        label: "Knife edge",
    },
];

export const PM = [
    {
        value: 1,
        label: "ITM / Longley-Rice (<20GHz)",
    },
    {
        value: 2,
        label: "Line Of Sight",
    },
    {
        value: 3,
        label: "Okumura-Hata (0.15 - 1.5GHz)",
    },
    {
        value: 4,
        label: "IRU-R P.529 (VHF/UHF)",
    },
    {
        value: 5,
        label: "SUI Microwave (1.9-11GHz)",
    },
    {
        value: 6,
        label: "COST231-Hata (0.15-2GHz)",
    },
    {
        value: 7,
        label: "ITU-R P.525 (Free Space Model)",
    },
    {
        value: 8,
        label: "Reserved",
    },
    {
        value: 9,
        label: "Ericsson 9999 (0.15 - 1.9GHz)",
    },
    {
        value: 10,
        label: "Plane Earth Loss",
    },
    {
        value: 11,
        label: "Egli VHF/UHF (1.5GHz)",
    }
]

export const Landcover = [
    {
        value: 0,
        label: "Disabled",
    },
    {
        value: 1,
        label: "Enabled",
    },
    {
        value: 2,
        label: "Enabled + Buildings",
    },
];

export const CustomCluster = [
    {
        value: 0,
        label: "Disabled"
    },
    // {
    //     value: 1,
    //     label: "Enabled on DSM"
    // },
    // {
    //     value: 2,
    //     label: "Enabled on DTM"
    // }
]

export const ColorScheme = [{
    value: "RAINBOW.dBm",
    label: "RAINBOW.dBm",
},];

export const Profile = [
    {
        value: "Minimal.clt",
        label: "Minimal.clt",
    },
    {
        value: "Desert.clt",
        label: "Desert.clt",
    },
    {
        value: "Temperate.clt",
        label: "Temperate.clt",
    },
    {
        value: "Tropical.clt",
        label: "Tropical.clt",
    },
    {
        value: "Urban.clt",
        label: "Urban.clt",
    },

];

export const Resolution = [
    {
        value: 1,
        label: "1m / 3ft",
    },
    {
        value: 2,
        label: "2m / 6ft",
    },
    {
        value: 4,
        label: "4m / 12ft",
    },
    {
        value: 5,
        label: "5m / 16ft",
    },
    {
        value: 10,
        label: "10m / 32ft",
    },
    {
        value: 20,
        label: "20m / 65ft",
    },
    {
        value: 30,
        label: "30m / 98ft",
    },
    {
        value: 60,
        label: "60m / 197ft",
    },
    {
        value: 90,
        label: "90m / 295ft",
    },
    {
        value: 180,
        label: "180m / 591ft",
    },
    {
        value: 300,
        label: "300m / 984ft",
    },
];

export const patterns =
    [
        {
            "id": 1,
            "label": "OEM Half-wave dipole",
            "value": "OEM Half-wave dipole",
            "crfId": 39,
            "image_left":  BASE_URL + "/image/osm_1.jpeg",
            "image_right":  BASE_URL + "/image/osm_2.jpeg"
        },
        {
            "id": 2,
            "label": "Argus Te.  02Z7GK036HPN01774C.ADF",
            "value": "Argus Te.  02Z7GK036HPN01774C.ADF",
            "crfId": 10,
            "image_left":  BASE_URL + "/image/argus_1.jpeg",
            "image_right":  BASE_URL + "/image/argus_2.jpeg"
        },
        {
            "id": 3,
            "label": "Generic 01RZIF03MEDP04JZTE.ADF",
            "value": "Generic 01RZIF03MEDP04JZTE.ADF",
            "crfId": 8,
            "image_left":  BASE_URL + "/image/generic_1.jpeg",
            "image_right":  BASE_URL + "/image/generic_2.jpeg"
        },
        {
            "id": 4,
            "label": "Iridium 03VOMB04LFPH00URKA.ADF",
            "value": "Iridium 03VOMB04LFPH00URKA.ADF",
            "crfId": 9,
            "image_left": BASE_URL + "/image/iridium_1.jpeg",
            "image_right": BASE_URL + "/image/iridium_2.jpeg"
        }
    ];

export const CloaxialType = [
    {
        value: 'Belden_8215_RG_6A',
        label: 'Belden 8215 RG_6A',
        id: 1,
        k1: 0.8626666667,
        k2: 0.005216666667
    },
    {
        value: 'Belden_8237_RG_8',
        id: 2,
        label: 'Belden 8237 RG 8',
        k1: 0.5550833333,
        k2: 0.006695833333
    },
    {
        value: 'Belden_9913_RG_8',
        id: 3,
        label: 'Belden 9913 RG 8',
        k1: 0.3950833333,
        k2: 0.002170833333
    },
    {
        value: 'Belden_9258_RG_8X',
        label: 'Belden 9258RG 8X',
        id: 4,
        k1: 0.8826666667,
        k2: 0.01876666667
    },
    {
        label: 'Belden 8213 RG 11',
        id: 5,
        value: 'Belden_8213_RG_11',
        k1: 0.3705833333,
        k2: 0.005245833333
    },
    {
        value: 'Belden_8261_RG_11A',
        label: 'Belden 8261 RG 11A',
        id: 6,
        k1: 0.555,
        k2: 0.010275
    },
    {
        value: 'Belden_8240_RG_58',
        label: 'Belden 8240 RG 58',
        id: 7,
        k1: 1.121583333,
        k2: 0.01237083333
    },
    {
        label: 'Belden 9201 RG 58',
        id: 8,
        value: 'Belden_9201_RG_58',
        k1: 1.160416667,
        k2: 0.01725416667
    },
    {
        value: 'Belden_8219_RG_58A',
        label: 'Belden 8219 RG 58A',
        id: 9,
        k1: 1.273583333,
        k2: 0.01857083333
    },
    {
        value: 'Belden_8259_RG_58C',
        label: 'Belden 8259 RG 58C',
        id: 10,
        k1: 1.486916667,
        k2: 0.02742916667
    },
    {
        label: 'Belden 8212 RG 59',
        id: 11,
        value: 'Belden_8212_RG_59',
        k1: 0.972,
        k2: 0.004825
    },
    {
        value: 'Belden_8263_RG_59B',
        label: 'Belden 8263 RG 59B',
        id: 12,
        k1: 1.052333333,
        k2: 0.005708333333
    },
    {
        label: 'Belden_9269_RG_62A',
        value: 'Belden_9269_RG_62A',
        id: 13,
        k1: 0.8608333333,
        k2: 0.001283333333
    },
    {
        value: 'Belden_83241_RG_141A',
        label: 'Belden 83241 RG 141A',
        id: 14,
        k1: 1.226083333,
        k2: 0.005220833333
    },
    {
        label: 'Belden 8216 RG 174',
        id: 15,
        value: 'Belden_8216_RG_174',
        k1: 2.49875,
        k2: 0.0313375
    },
    {
        value: 'Belden_8267_RG_213',
        label: 'Belden 8267 RG 213',
        id: 16,
        k1: 0.6233333333,
        k2: 0.007083333333
    },
    {
        label: 'Belden MRG2142 RG 214',
        id: 17,
        value: 'Belden_MRG2142_RG_214',
        k1: 0.6233333333,
        k2: 0.007083333333
    },
    {
        value: 'Davis_RF_Bury_Flex',
        label: 'Davis RF Bury Flex',
        id: 18,
        k1: 0.4608333333,
        k2: 0.001033333333
    },
    {
        label: 'TMS LMR 100A',
        id: 19,
        value: 'TMS_LMR_100A',
        k1: 2.312833333,
        k2: 0.006008333333
    },
    {
        value: 'TMS_LMR_200',
        id: 20,
        label: 'TMS LMR 200',
        k1: 1.051583333,
        k2: 0.001120833333
    },
    {
        label: 'TMS LMR 240',
        id: 21,
        value: 'TMS_LMR_240',
        k1: 0.7965833333,
        k2: 0.001045833333
    },
    {
        label: 'TMS LMR 400',
        id: 22,
        value: 'TMS_LMR_400',
        k1: 0.3941666667,
        k2: 0.001016666667
    },
    {
        value: 'TMS_LMR_600',
        id: 23,
        label: 'TMS LMR 600',
        k1: 0.2478333333,
        k2: 0.0008583333333
    },
    {
        value: 'TMS_LMR_900',
        id: 24,
        label: 'TMS LMR 900',
        k1: 0.1700833333,
        k2: 0.0004958333333
    },
    {
        label: 'Wireman CQ102 RG 8',
        id: 25,
        value: 'Wireman_CQ102_RG_8',
        k1: 0.39725,
        k2: 0.0021625
    },
    {
        value: 'Wireman_CQ106_RG_8',
        id: 26,
        label: 'Wireman CQ106 RG 8',
        k1: 0.4608333333,
        k2: 0.001033333333
    },
    {
        value: 'Wireman_CQ125_RG_58',
        id: 27,
        label: 'Wireman CQ125 RG 58',
        k1: 1.345166667,
        k2: 0.01181666667
    },
    {
        label: 'Wireman CQ127 RG 58C',
        id: 28,
        value: 'Wireman_CQ127_RG_58C',
        k1: 1.505333333,
        k2: 0.02745833333
    },
    {
        label: 'Wireman CQ110 RG 213',
        id: 29,
        value: 'Wireman_CQ110_RG_213',
        k1: 0.4513333333,
        k2: 0.01200833333
    },
    {
        value: 'Tandy_Cable_RG_8X',
        label: 'Tandy Cable RG 8X',
        id: 30,
        k1: 1.024083333,
        k2: 0.01027083333
    },
    {
        value: 'Tandy_Cable_RG_58',
        label: 'Tandy Cable RG 58',
        id: 31,
        k1: 1.191083333,
        k2: 0.01437083333
    },
    {
        value: 'Tandy_Cable_RG_59',
        label: 'Tandy Cable RG 59',
        id: 32,
        k1: 0.941,
        k2: 0.001975
    },
    {
        value: 'Andrew_Heliax_LDF4_50A',
        label: 'Andrew Heliax LDF4 50A',
        id: 33,
        k1: 0.211,
        k2: 0.000625
    },
    {
        label: 'Andrew Heliax LDF5 50A',
        value: 'Andrew_Heliax_LDF5_50A',
        id: 34,
        k1: 0.1140833333,
        k2: 0.0004958333333
    },
    {
        value: 'Andrew_Heliax_LDF6_50A',
        label: 'Andrew Heliax LDF6 50A',
        id: 35,
        k1: 0.07891666667,
        k2: 0.0004541666667
    },
    {
        value: 'Wireman_551_Ladder_Line',
        label: 'Wireman 551 Ladder Line',
        id: 36,
        k1: 0.16275,
        k2: 0.0039375
    },
    {
        value: 'Wireman_552_Ladder_Line',
        label: 'Wireman 552 Ladder Line',
        id: 37,
        k1: 0.1674166667,
        k2: 0.003279166667
    },
    {
        value: 'Wireman_553_Ladder_Line',
        label: 'Wireman 553 Ladder Line',
        id: 38,
        k1: 0.2040833333,
        k2: 0.002945833333
    },
    {
        value: 'Wireman_554_Ladder_Line',
        label: 'Wireman 554 Ladder Line',
        id: 39,
        k1: 0.136,
        k2: 0.005575
    },
    {
        value: 'Wireman_551_wet',
        label: 'Wireman 551 wet',
        id: 40,
        k1: 0.18525,
        k2: 0.2354375
    },
    {
        value: 'Wireman_552_wet',
        label: 'Wireman 552 wet',
        id: 41,
        k1: 0.1819166667,
        k2: 0.2028291667
    },
    {
        value: 'Wireman_553_wet',
        label: 'Wireman 553 wet',
        id: 42,
        k1: 0.2389166667,
        k2: 0.1807791667
    },
    {
        value: 'Wireman_554_wet',
        label: 'Wireman 554 wet',
        id: 43,
        k1: 0.1013333333,
        k2: 0.2544583333
    },
    {
        value: 'Generic_300_ohm_Tubular',
        label: 'Generic 300 ohm Tubular',
        id: 44,
        k1: 0.3338333333,
        k2: 0.002233333333
    },
    {
        value: 'Generic_450_ohm_Window',
        label: 'Generic 450 ohm Window',
        id: 45,
        k1: 0.08933333333,
        k2: 0.0007833333333
    },
    {
        value: 'Generic_600_ohm_Open',
        label: 'Generic 600 ohm Open',
        id: 46,
        k1: 0.0625,
        k2: 0.000325
    },
    {
        value: 'Ideal_lossless_50_ohm',
        label: 'Ideal lossless 50 ohm',
        id: 47,
        k1: 0,
        k2: 0
    },
    {
        value: 'Ideal_lossless_75_ohm',
        label: 'Ideal lossless 75 ohm',
        id: 48,
        k1: 0,
        k2: 0
    }
]

export const ANALYZE_TYPE = {
    NS: 'No Statement',
    GTA: 'Ground to Air',
    GTG: 'Ground to Ground',
    JAMMER: 'Jammer',
    MULTIPLE: 'Multiple',
    LINK_BUDGET: 'Link Budget',
    MESH_SIMULATION: 'Mesh Simulation',
    FREQUENCY_ALLOCATION: 'Frequency Allocation',
    OPERATION_PLANNING: 'Operation Planning',
}

export const STATUS_TYPE = {
    FAILED: 'Failed',
    SUCCESS: 'Success',
    NO_STATEMENT: 'No Statement',
    ON_PROGRESS: 'On Progress'
}

export const ASSET_TYPE = {
    RADIO: "Radio",
    LOGGING: "LOGGING",
    ADMIN_CATEGORY: "Admin Category",
    ADMIN_BRAND: "Admin Brand",
    ADMIN_TYPE: "Admin Type",
    SIMULATION_RESULT: "Simulation Result",
    USER_MANAGER: "User Manager",
    TOWER: "Tower",
    TOWER_CIVIL: "Tower Civil",
    FIBER_OPTIC: "Fiber Optic",
    ELECTRONIC: "Electronic WS",
    FREQUENCY_ALLOCATION: "Frequency Allocation",
    PABX: "PABX",
    SUPPORTING: "Supporting",
    DISTRIBUTION: "Distribution",
    ANTENNA: "Antenna",
    OP: "Operation Planning",
    OP_DETAIL: "Operation Planning Detail",
    SELECT_ASSET_MAINTENANCE: "Select Asset Maintenance",
    MAINTENANCE_DATA: "Maintenance Data",
    MAINTENANCE_SCHEDULE: "Maintenance Schedule",
    MAINTENANCE_HISTORY: "Maintenance History",
    TEMPLATE: "Template",
    TEMPLATE_TASK: "Template Task",
    LOAD_RADIO: "Load Radio",
    LOAD_ANTENNA: "Load Antenna",
    NS: "No Statement"
}
export const ASSET_ACTION_TYPE = {
    CREATE: "Create",
    UPDATE: "Update",
    DETAIL: "Detail",
    NS: "No Statement",
}

export const MONTH_NAMES = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
export const MONTH_IN_NUMBER = ["01", "02", "03", "04", "05", "06",
    "07", "08", "09", "10", "11", "12"
];

export const HEADING_SELECT_ASSET_MAINTENANCE = [
    {heading: "Name", value: "name"},
    {heading: "Brand", value: "brand"},
    {heading: "Type", value: "type"},
    {heading: "Serial Number", value: "serialNo"},
    {heading: "Owner", value: "owner"},
];

export const MAINTENANCE_TYPE = {
    DATA: "data",
    SCHEDULE: "schedule",
    HISTORY: "history"
}

export const MAINTENANCE_STATUS = {
    DONE: "DONE",
    IN_PROGRESS: "INPROGRESS",
    ESCALATION: "ESCALATION",
    HOLD: "HOLD"
}

export const PROCESS_TYPE = {
    START: `start`,
    UPDATE: `update`,
    SUCCESS: `success`,
    FAILED: `failed`,
    NS: `no statement`,
}

export const RADIO_BAND = {
    ALL: {name: `ALL`, min: 3, max: 8000},
    HF: {name: `HF`, min: 3, max: 30},
    VHF: {name: `VHF`, min: 30, max: 300},
    UHF: {name: `UHF`, min: 300, max: 3000},
    VUHF: {name: `VUHF`, min: 30, max: 3000},
    C_BAND: {name: `C-Band`, min: 4000, max: 8000},
}

export const LOAD_RADIO_COLOMN = [
    {heading: "Name", value: "name"},
    {heading: "Brand", value: "brand.name"},
    {heading: "Type", value: "type.name"},
    {heading: "Frequency", value: "rangeFrequency"},
    {heading: "TX Power", value: "power"},
    {heading: "Bandwith", value: "bandwith"},
];
export const LOAD_ANTENNA_COLOMN = [
    {heading: "Name", value: "name"},
    {heading: "Brand", value: "brand.name"},
    {heading: "Type", value: "type.name"},
    {heading: "Gain", value: "antennaPower"},
    {heading: "Tipe Coaxial", value: "coaxialType.name"},
];
export const LOAD_RADIO_COLOMN_FREQUENCY = [
    {heading: "Name", value: "name"},
    {heading: "Brand", value: "brand.name"},
    {heading: "Type", value: "type.name"},
    {heading: "Frequency", value: "rangeFrequency"},
];
export const COLUMN_TEMPLATE = [
    {heading: "Template Name", value: "name"}
];

export const COLUMN_TASK_TEMPLATE = [
    {heading: "Task", value: "name"}
];

export const BOOL_TYPE = {
    YES: {bool: true, label: 'yes'},
    NO: {bool: false, label: 'no'}
}

export const VALIDATION_PARAMETER = {
    FREQUENCY: {min: 30, max: 6000},
    TX_POWER: {min: 0.001, max: 5000000},
    BANDWIDTH: {min: 0.001, max: 200},
    TRANSMITTER_GAIN: {min: 0, max: 60},
    ANTENNA_AZIMUTH: {min: 0, max: 359},
    ANTENNA_TILT: {min: -90, max: 90},
    RECEIVER_GAIN: {min: -30, max: 60},
    ALTITUDE_METER_GTA: {min: 1, max: 300},
    ALTITUDE_METER_GTG: {min: 0.5, max: 300},
    ALTITUDE_METER_MESH: {min: 0.5, max: 18288},
    ALTITUDE_FEET_JAMMER: {min: 0.4998, max: 18287.11},
    ALTITUDE_METER_JAMMER: {min: 0.5, max: 18288},
    RECEIVER_ALTITUDE_FEET: {min: 0.3, max: 18287.11},
    RECEIVER_ALTITUDE_METER: {min: 1, max: 300},
    SENSITIVITY: {min: -120, max: -40},
    RELIABILITY: {min: 50, max: 99},
    RADIUS_NM: {min: 1.85, max: 399.99},
    RADIUS_KM: {min: 0.1, max: 50},
    RADIUS_JAMMER_NM: {min: 1.85, max: 399.99},
    RADIUS_JAMMER_KM: {min: 0.1, max: 50},
}

export const adminUsage = [
    { label: "Radio", value: "radio" },
    { label: "Antenna", value: "antenna" },
    { label: "Tower", value: "tower" },
    { label: "PABX", value: "pabx" },
    { label: "Supporting", value: "supporting" },
];

// =====================================
// CONFIRMATION
// =====================================
export const CONFIRMATION_TITLE_REMOVE = `Remove Confirmation`
export const CONFIRMATION_TEXT_REMOVE = (item) => `Are you sure want to remove ${item}?`;
export const CONFIRMATION_TEXT_REMOVE_ASSET = (item) => `This Asset might be related to Maintenance.<br>Are you sure want to remove ${item}?`;
export const CONFIRMATION_ICON_REMOVE = `warning`;

// =====================================
// ALERT
// =====================================
export const ALERT_TITLE_SUCCESS = `Success`;
export const ALERT_TEXT_REMOVE_SUCCESS = `Data Has Been Removed`;
export const ALERT_TEXT_CREATE_SUCCESS = `Data Has Been Created`;
export const ALERT_TEXT_UPDATE_SUCCESS = `Data Has Been Updated`;
export const ALERT_ICON_SUCCESS = `success`;
export const ALERT_TITLE_FAILED = `Failed`;
export const ALERT_TEXT_REMOVE_FAILED = `Failed to Remove data`;
export const ALERT_TEXT_CREATE_FAILED = `Failed to Create data`;
export const ALERT_TEXT_UPDATE_FAILED = `Failed to Update data`;
export const ERROR_MESSAGE_ALREADY_DONE = `The maintenance cannot be done before scheduled date`;
export const ALERT_ICON_FAILED = `error`;

// =====================================
// IMAGE POSITION FOR REPORT ASSET
// =====================================
export const DEFAULT_TITLE_POSITION = 83;
export const DEFAULT_IMAGE_POSITION_X = 51.5;
export const DEFAULT_LINE_X1 = 50;
export const DEFAULT_LINE_X2 = 153;
export const SPACE = 120;

// =====================================
// SITE LIST
// =====================================
export const siteList = [
    {label: "MABES", value: "MABES"},
    {label: "SATKOMLEK KOOPSUDNAS", value: "SATKOMLEK KOOPSUDNAS"},
    {label: "SATKOMLEK KOOPSUD 1", value: "SATKOMLEK KOOPSUD 1"},
    {label: "KOSEK 1", value: "KOSEK 1"},
    {label: "DEPOHAR 40", value: "DEPOHAR 40"},
    {label: "LANUD RSN", value: "LANUD RSN"},
    {label: "LANUD RHF", value: "LANUD RHF"},
    {label: "LANUD HLO", value: "LANUD HLO"},
    {label: "LANUD IWJ", value: "LANUD IWJ"},
    {label: "LANUD SPR", value: "LANUD SPR"},
];