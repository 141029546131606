import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LoginForm from '../../components/form/LoginForm';

import { isAuthed } from '../../store/user/user.selector';

const Login = () => {
  const authed = useSelector(isAuthed)
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("accesstoken_nccsa")) {
      navigate("/");
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [authed])

  return (
    <div className="w-screen h-screen bg-[url('../src/assets/png/background.png')] bg-no-repeat bg-cover bg-fixed bg-right flex items-center">
      <LoginForm />
    </div>
  );
};

export default Login;
