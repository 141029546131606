import {createSelector} from "reselect";

const selectFoReducer = (state) => state.foReducer;

export const selectPoints = createSelector(
    [selectFoReducer],
    (state) => {
        if (state.points && state.points.length) {
            return state.points;
        } else {
            return null;
        }
    }
)
export const selectPointEntities = createSelector(
    [selectFoReducer],
    (state) => {
        if (state.pointEntities && state.pointEntities.length) {
            return state.pointEntities;
        } else {
            return null;
        }
    }
)
export const selectPolylineEntities = createSelector(
    [selectFoReducer],
    (state) => {
        if (state.polylineEntities && state.polylineEntities.length) {
            return state.polylineEntities;
        } else {
            return null;
        }
    }
)
export const selectMidLabelEntities = createSelector(
    [selectFoReducer],
    (state) => {
        if (state.midLabelEntities && state.midLabelEntities.length) {
            return state.midLabelEntities;
        } else {
            return null;
        }
    }
)
export const selectDistanceNumber = createSelector(
    [selectFoReducer],
    (state) => {
        if (state.distanceNumbers && state.distanceNumbers.length) {
            return state.distanceNumbers;
        } else {
            return null;
        }
    }
)
export const selectSearchResponse = createSelector(
    [selectFoReducer],
    (state) => {
        if (state.searchResponse && state.searchResponse.content && state.searchResponse.content.length) {

            const data = [];
            state.searchResponse.content.forEach(d => {
                data.push({
                    id: d.distributionAssetsDto.id,
                    name: d.distributionAssetsDto.name,
                    core: Number(d.routePathDto.core),
                    totalLength: d.routePathDto.totalCableLength,
                    points: d.routePathDto.numberOfPoints,
                    siteName: d.distributionAssetsDto.site.siteName,
                    isChecked: false,
                })
            });
            return data;
        } else {
            return null;
        }
    }
)

export const selectTotalPages = createSelector(
    [selectFoReducer],
    (state) => {
        if (state.searchResponse) {
            return state.searchResponse.totalPages;
        } else {
            return null
        }
    }
)
export const selectTotalElement = createSelector(
    [selectFoReducer],
    (state) => {
        if (state.searchResponse) {
            return state.searchResponse.totalElements;
        } else {
            return null
        }
    }
)
export const selectDataFromList = createSelector(
    [selectFoReducer],
    (state) => {
        if (state.goToAsset) {
            return state.goToAsset.data;
        } else {
            return null
        }
    }
)
export const selectGoToAsset = createSelector(
    [selectFoReducer],
    (state) => {
        if (state.goToAsset) {
            return state.goToAsset.goToAsset;
        } else {
            return null
        }
    }
)
export const selectDetailAsset = createSelector(
    [selectFoReducer],
    (state) => {
        if (state.detailAsset) {
            return state.detailAsset;
        } else {
            return null
        }
    }
)
export const selectDetailMultiAsset = createSelector(
    [selectFoReducer],
    (state) => {
        if (state.detailMultiAsset) {
            return state.detailMultiAsset;
        } else {
            return null
        }
    }
)

export const selectUpdateStatus = createSelector(
    [selectFoReducer],
    (state) => state.updateStatus
)
export const selectRemoveStatus = createSelector(
    [selectFoReducer],
    (state) => state.removeStatus
)
export const selectUploadedStatus = createSelector(
    [selectFoReducer],
    (state) => state.uploadFoStatus
)
