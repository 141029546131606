import { useSelector } from "react-redux";
import { isAuthed } from "../../store/user/user.selector";

const RequiredCredential = ({ children }) => {
    const authed = useSelector(isAuthed);

    return localStorage.getItem('accesstoken_nccsa') && authed && (
        children
    );
}

export default RequiredCredential