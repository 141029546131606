import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import ReactPaginate from "react-paginate";

import {Viewer, Worker} from "@react-pdf-viewer/core";
import {zoomPlugin} from '@react-pdf-viewer/zoom';
import {pageNavigationPlugin} from '@react-pdf-viewer/page-navigation';

import {
    selectDetailSelectedAssetData,
    selectGoToEdit,
    selectProcess,
    selectSelectedAssetType,
    selectSelectedMaintenanceData,
    selectSelectedMaintenanceType
} from "../../../store/maintenance/maintenance.selector";
import {
    handleClearMaintenanceData,
    handleCreateMaintenance,
    handleEditMaintenanceData,
    handleUpdateMaintenance,
    handleClearNotif
} from "../../../store/maintenance/maintenance.action";
import {
    selectAllMasterTemplate,
    selectAllTaskByTemplateId,
    selectTotalPages,
    selectUpdateTaskByTemplateId
} from "../../../store/template/template.selector";
import {
    handleClearAllData,
    handleClearAllMasterTemplate,
    handleGetAllMasterTemplate,
    handleGetTaskByTemplateId,
    handleSearchMasterTemplate
} from "../../../store/template/template.action";
import {handleClearStateGlobal, handleLoading} from "../../../store/global/global.action";
import Table from "../../../components/table/Table";
import Modals from "../../../components/modal/Modals";
import HeaderPage from "../../../components/header/HeaderPage";
import SearchBox from "../../../components/search-box/search-box.component";
import ReportMaintenance from "../../../components/report/asset/ReportMaintenance"
import {DownloadIcon} from "../../../assets/svgs";
import {
    COLUMN_TEMPLATE,
    MAINTENANCE_STATUS,
    MAINTENANCE_TYPE,
    MONTH_IN_NUMBER,
    PROCESS_TYPE
} from "../../../utils/constant/constants";
import {DELETE_MAINTENANCE_TASK_BY_ID} from "../../../services/maintenance";
import {downloadPdf} from "../../../utils/download/download";

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Import styles
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
// Import styles
import '@react-pdf-viewer/zoom/lib/styles/index.css';

export const getDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return `${dd} / ${mm} / ${yyyy}`;
}

const CreateMaintenance = () => {
    const [open, setOpen] = useState(false);
    const [fromSchedule, setFromSchedule] = useState(false);
    const [fromHistory, setFromHistory] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchTextOnChange, setSearchTextOnChange] = useState("");
    const [allTaskByTemplateId, setAllTaskByTemplateId] = useState([]);
    const [taskForm, setTaskForm] = useState([]);
    const [pdfToRead, setPdfToRead] = useState("");
    const [urlFiles, setUrlFiles] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const detailSelectedAssetData = useSelector(selectDetailSelectedAssetData)
    const selectedMaintenanceType = useSelector(selectSelectedMaintenanceType)
    const selectedAssetType = useSelector(selectSelectedAssetType)
    const goToEdit = useSelector(selectGoToEdit);
    const selectedMaintenanceData = useSelector(selectSelectedMaintenanceData);
    const processType = useSelector(selectProcess);
    const allMasterTemplate = useSelector(selectAllMasterTemplate);
    const totalPages = useSelector(selectTotalPages);
    const allTaskById = useSelector(selectAllTaskByTemplateId);
    const updateTaskByTemplateId = useSelector(selectUpdateTaskByTemplateId);

    const zoomPluginInstance = zoomPlugin();
    const {ZoomInButton, ZoomOutButton, ZoomPopover} = zoomPluginInstance;

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const {GoToPreviousPageButton, GoToNextPageButton, CurrentPageLabel, NumberOfPages} = pageNavigationPluginInstance;

    const {
        register,
        formState: {errors},
        handleSubmit,
        getValues,
        setValue,
        watch
    } = useForm(({
        defaultValues: {
            name: "",
            owner: "",
            brand: "",
            type: "",
            serialNo: "",
            maintenance: selectedMaintenanceType && `${selectedMaintenanceType.type} (${selectedMaintenanceType.valueType})`,
            datetime: getDate(),
            action: "",
            condition: "",
            status: "",
            escalation: "",
            escalationTo: "",
        }
    }));


    useEffect(() => {
        let isAssetSubscribed = true;
        if (detailSelectedAssetData && detailSelectedAssetData.docAsset &&
            detailSelectedAssetData.docAsset.length && isAssetSubscribed) {
            const {docAsset} = detailSelectedAssetData;
            let secureUrl = docAsset[0];
            if (process.env.REACT_APP_NODE_ENV === "production") {
                secureUrl = docAsset[0].replace('http://', 'https://');
            }
            setPdfToRead(secureUrl)
        }

        return () => {
            isAssetSubscribed = false;
            clearAllState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (goToEdit && selectedMaintenanceData && processType === PROCESS_TYPE.UPDATE) {
    
            dispatch(handleEditMaintenanceData(MAINTENANCE_TYPE.DATA, false, null, null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goToEdit])

    useEffect(() => {
        if (selectedMaintenanceType) {
            setValue("maintenance", `${selectedMaintenanceType.type} (${selectedMaintenanceType.valueType})`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMaintenanceType])

    useEffect(() => {
        if (selectedMaintenanceData && goToEdit && processType === PROCESS_TYPE.UPDATE) {
            const scheduleDate = new Date(selectedMaintenanceData.createdAt);
            const day = scheduleDate.getDate();
            const month = MONTH_IN_NUMBER[scheduleDate.getMonth()];
            const year = scheduleDate.getFullYear();
            const date = `${day} / ${month.toUpperCase()} / ${year}`

            setValue("datetime", date);
            setValue("condition", selectedMaintenanceData.condition);
            setValue("status", selectedMaintenanceData.status);

            setValue("note", selectedMaintenanceData.note);
            setValue("action", selectedMaintenanceData.action);
            setValue("escalation", selectedMaintenanceData.escalation);
            setValue("escalationTo", selectedMaintenanceData.escalationTo);
            setIsUpdate(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMaintenanceData])

    useEffect(() => {
        if (detailSelectedAssetData) {
            setValue("name", detailSelectedAssetData.name);
            setValue("owner", detailSelectedAssetData.owner);
            setValue("brand", detailSelectedAssetData.brand);
            setValue("type", detailSelectedAssetData.type);
            setValue("serialNo", detailSelectedAssetData.serialNo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailSelectedAssetData])

    useEffect(() => {
        if (updateTaskByTemplateId && !taskForm.length && !allTaskByTemplateId.length) {
            updateTaskByTemplateId.forEach(t => {
                if (t.task && t.task.id) {
                    // get data for table
                    allTaskByTemplateId.push(t.task);
                    // task form
                    const newObj = {}
                    newObj.task = {id: t.task.id, name: t.task.name};
                    newObj.completed = t.completed ? 'yes' : 'no';
                    newObj.status = t.status.toLowerCase();
                    newObj.id = t.id;
                    taskForm.push(newObj)
                }
                if (t.maintenanceDataFromSchedule) {
                    setFromSchedule(true);
                }
                if (t.maintenanceDataFromHistory) {
                    setFromHistory(true);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateTaskByTemplateId])


    /**
     * Load All Task from template id
     */
    useEffect(() => {
        if (allTaskById) {
            taskForm.length = 0 // clear array
            allTaskById.forEach(d => {
                const newObj = {}
                newObj.task = {id: d.id, name: d.name};
                newObj.completed = 'yes'
                newObj.status = 'good';
                taskForm.push(newObj)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allTaskById]);


    useEffect(() => {
        if (detailSelectedAssetData) {
            let fls = [];

            // Receive detail Asset File
            if (detailSelectedAssetData.docAsset && detailSelectedAssetData.docName && detailSelectedAssetData.docAsset.length
                && detailSelectedAssetData.docAsset.length === detailSelectedAssetData.docName.length) {

                const label = detailSelectedAssetData.docName;
                const value = detailSelectedAssetData.docAsset;
                fls = label.map((item, index) => {
                    return {
                        label: item,
                        value: value[index],
                    }
                });
                setUrlFiles(fls);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const clearAllState = () => {
        dispatch(handleClearAllData());
        dispatch(handleClearStateGlobal());
        setTaskForm([]);
        setAllTaskByTemplateId([]);
        setIsUpdate(false)
        setFromSchedule(false)
        setFromHistory(false)
    };

    const handleOpen = () => {
        setOpen(true);
        dispatch(handleGetAllMasterTemplate(10, 0));
    }

    const handleClose = () => {
        setOpen(false);
        setSearchText("");
    }

    const handleClearTemplate = () => {
        dispatch(handleClearAllMasterTemplate());
        setAllTaskByTemplateId([]);
    }

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    };

    const handleSelectRow = (id) => {
        dispatch(handleGetTaskByTemplateId(id));
    }

    const handleLoadTask = () => {
        // remove previous task
        if (updateTaskByTemplateId && updateTaskByTemplateId.length) {
            updateTaskByTemplateId.forEach(task => {
                DELETE_MAINTENANCE_TASK_BY_ID(task.id)
                    .then(() => {})
                    .catch(() => {})
            })
        }

        setAllTaskByTemplateId(allTaskById);
        setOpen(false);
    }

    const handleTaskForm = (e) => {
        const split = e.target.id.split("-");
        const firstStr = split.shift();
        const newSplit = split.join("-");
        taskForm.forEach(t => {
            if (t.task.id === newSplit) {
                if (firstStr === 'status') {
                    t.status = e.target.value;
                } else {
                    t.completed = e.target.value;
                }
            }
        })
    }
    const onChecked = (id, type, selected) => {
        if (taskForm.length) {
            const task = taskForm.find(d => d.task.id === id);
            if (type === 'status') {
                return task.status === selected;
            } else {
                return task.completed === selected;
            }
        }
    }

    const onSubmit = (data) => {
        dispatch(handleClearNotif());

        if (data.condition && data.status !== "" &&
            detailSelectedAssetData && selectedMaintenanceType && selectedAssetType && processType) {
            const isPrev = selectedMaintenanceType.type.toLowerCase() === "preventive";
            const isEscalation = data.status === MAINTENANCE_STATUS.ESCALATION;

            let body = {
                siteDto: {id: detailSelectedAssetData.siteId},
                assetId: detailSelectedAssetData.assetId,
                assetType: selectedAssetType.toUpperCase(),
                status: data.status,
                condition: data.condition,
                location: isEscalation ? data.escalationTo : detailSelectedAssetData.owner,
                preventive: isPrev ? selectedMaintenanceType.valueType : null,
                corrective: isPrev ? null : selectedMaintenanceType.valueType,
                note: data.note,
                action: data.action,
                history: processType === PROCESS_TYPE.UPDATE ? selectedMaintenanceData.history : false,
                schedule: processType === PROCESS_TYPE.UPDATE ? selectedMaintenanceData.schedule : false,
                scheduleStatus: processType === PROCESS_TYPE.UPDATE ? selectedMaintenanceData.scheduleStatus : `WAITING`,
                scheduleDate: processType === PROCESS_TYPE.UPDATE ? selectedMaintenanceData.scheduleDate : null,
                assetName: detailSelectedAssetData.name,
                serialNo: detailSelectedAssetData.serialNo,
                escalationTo: isEscalation ? data.escalationTo : null,
                escalation: isEscalation ? data.escalation : null,
            }

            if (taskForm) {
                // change value of completed task
                taskForm.forEach(task => task.completed = task.completed === "yes")
                taskForm.forEach(task => task.status = task.status.toUpperCase())
            }
            if (isUpdate) {
                dispatch(handleLoading(true));
                dispatch(handleUpdateMaintenance(selectedMaintenanceData.id, body, navigate, MAINTENANCE_TYPE.DATA, taskForm, fromSchedule, updateTaskByTemplateId));
            } else {
                dispatch(handleLoading(true));
                dispatch(handleCreateMaintenance(body, navigate, MAINTENANCE_TYPE.DATA, taskForm));
            }
            
            clearAllState();
            dispatch(handleClearMaintenanceData());
        }
    }

    // const handleSearchTemplate = (e) => {
    //     e.preventDefault();
    //     dispatch(handleSearchMasterTemplate(searchText, 0, 10));
    // }

    const handleSearchTemplate = (e) => {
        e.preventDefault();
    
        setSearchText(searchTextOnChange);
    
        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value;
    
        dispatch(handleSearchMasterTemplate(searchText, 0, 10));
    };

    const handlePageClickSearchTemplate = (e) => {
        dispatch(handleSearchMasterTemplate(searchText, e.selected, 10));
    }

    const renderStatus = (status) => {
        if (MAINTENANCE_STATUS.ESCALATION === status) {
            return (
                <>
                    <div className="flex items-center mb-4">
                        <label htmlFor="escalationlevel"
                                className="max-w-[169px] text-white font-bold px-3 min-w-[170px]">
                            Escalation :
                        </label>
                        <select
                            name="escalationlevel"
                            id="escalationlevel"
                            {...register("escalation", {
                                required: "Required!",
                            })}
                            disabled={fromHistory}
                            className={`select select-primary w-[450px] font-normal ${errors?.escalation ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                        >
                            <option value="" disabled selected>-- Select Escalation Level --</option>
                            <option value="medium">Medium Level</option>
                            <option value="heavy">Heavy Level</option>
                        </select>
                    </div>

                    <div className="flex items-center mb-4">
                        <label htmlFor="escalationunit"
                                className="max-w-[169px] text-white font-bold px-3 min-w-[170px]">
                            Escalation Unit :
                        </label>
                        <select
                            name="escalationunit"
                            id="escalationunit"
                            {...register("escalationTo", {
                                required: "Required!",
                            })}
                            disabled={fromHistory}
                            className={`select select-primary w-[450px] font-normal ${errors?.escalationTo ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                        >
                            <option value="" disabled selected>-- Select Escalation Level --</option>
                            <option value="GPL">GPL</option>
                            <option value="DEPO 40">DEPO 40</option>
                            <option value="Vendor">Vendor</option>
                        </select> 
                    </div>
                </>
            )
        }
    }

    return (
        <div className="bg-tertiary min-h-screen px-16 py-4 pb-20">
            <HeaderPage title={fromHistory ? "History Maintenance" : "Create Maintenance"}/>

            <div className="flex mt-10 gap-x-5">
                <section className="w-[40%] mt-10">
                    <h1 className="font-bold text-white px-3 mb-5">Asset</h1>

                    <form>
                        <div className="flex items-center mb-4">
                            <label htmlFor="name" className="text-white font-bold px-3 min-w-[170px]">
                                Name :
                            </label>
                            <input
                                name="name"
                                id="name"
                                type="text"
                                {...register("name")}
                                disabled
                                className="input w-[450px]"
                            />
                        </div>

                        <div className="flex items-center mb-4">
                            <label htmlFor="owner" className="text-white font-bold px-3 min-w-[170px]">
                                Owner :
                            </label>
                            <input
                                name="owner"
                                id="owner"
                                type="text"
                                {...register("owner")}
                                disabled
                                className="input w-[450px]"
                            />
                        </div>

                        <div className="flex items-center mb-4">
                            <label htmlFor="brand" className="text-white font-bold px-3 min-w-[170px]">
                                Brand :
                            </label>
                            <input
                                name="brand"
                                id="brand"
                                type="text"
                                {...register("brand")}
                                disabled
                                className="input w-[450px]"
                            />
                        </div>

                        <div className="flex items-center mb-4">
                            <label htmlFor="type" className="text-white font-bold px-3 min-w-[170px]">
                                Type :
                            </label>
                            <input
                                name="type"
                                id="type"
                                type="text"
                                {...register("type")}
                                disabled
                                className="input w-[450px]"
                            />
                        </div>

                        <div className="flex items-center mb-4">
                            <label htmlFor="serialNo" className="text-white font-bold px-3 min-w-[170px]">
                                Serial Number :
                            </label>
                            <input
                                name="serialNo"
                                id="serialNo"
                                type="text"
                                {...register("serialNo")}
                                disabled
                                className="input w-[450px]"
                            />
                        </div>

                        <div className="flex items-center mb-4">
                            <label htmlFor="maintenance" className="text-white font-bold px-3 min-w-[170px]">
                                Maintenance :
                            </label>
                            <input
                                name="maintenance"
                                id="maintenance"
                                type="text"
                                {...register("maintenance")}
                                disabled
                                className="input w-[450px]"
                            />
                        </div>

                        <div className="flex items-center mb-4">
                            <label htmlFor="date/time" className="text-white font-bold px-3 min-w-[170px]">
                                {processType === PROCESS_TYPE.UPDATE && goToEdit ? `Created At :` : `Date / Time :`}
                            </label>
                            <input
                                name="datetime"
                                id="datetime"
                                type="text"
                                {...register("datetime")}
                                disabled
                                className="bg-tertiary w-[450px] text-white px-3"
                            />
                        </div>

                        {!fromHistory && (
                            <div className="flex justify-end gap-3">
                                <button type="button" onClick={handleClearTemplate}
                                        className="btn btn-outline text-white">
                                    Clear
                                </button>
                                <button type="button" onClick={handleOpen}
                                        className="btn btn-primary text-white mr-12">
                                    Load Template
                                </button>
                            </div>
                        )}

                        {
                            allTaskByTemplateId && taskForm && (
                                allTaskByTemplateId.map((task) => {
                                    return (
                                        <div key={task.id} className="text-white text-lg mt-5 ml-3">
                                            <div className="mb-2">{task.name}</div>

                                            <div className="flex">
                                                <span className="w-[160px]">Action :</span>
                                                <div className="flex items-center mb-3">
                                                    <input type="radio" id={`action-${task.id}`} name={`action-${task.id}`}
                                                        value={"yes"}
                                                        disabled={fromHistory}
                                                        defaultChecked={onChecked(task.id, "action", "yes")}
                                                        onChange={handleTaskForm}
                                                        className="radio bg-white"
                                                    />
                                                    <label htmlFor="yes" className="px-2 w-[90px]">Yes</label>
                                                </div>
                                                <div className="flex items-center mb-3">
                                                    <input type="radio" id={`action-${task.id}`} name={`action-${task.id}`}
                                                        value={"no"}
                                                        disabled={fromHistory}
                                                        defaultChecked={onChecked(task.id, "action", "no")}
                                                        onChange={handleTaskForm}
                                                        className="radio bg-white"
                                                    />
                                                    <label htmlFor="no" className="px-2 w-[90px]">No</label>
                                                </div>
                                            </div>

                                            <div className="flex mb-3">
                                                <span className="w-[160px]">Status :</span>
                                                <div className="flex items-center">
                                                    <input type="radio" id={`status-${task.id}`} name={`status-${task.id}`}
                                                        value="good"
                                                        disabled={fromHistory}
                                                        defaultChecked={onChecked(task.id, "status", "good")}
                                                        onChange={handleTaskForm}
                                                        className="radio bg-white"
                                                    />
                                                    <label htmlFor="good" className="px-2 w-[90px]">Good</label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input type="radio" id={`status-${task.id}`} name={`status-${task.id}`}
                                                        value="trouble"
                                                        disabled={fromHistory}
                                                        defaultChecked={onChecked(task.id, "status", "trouble")}
                                                        onChange={handleTaskForm}
                                                        className="radio bg-white"
                                                    />
                                                    <label htmlFor="trouble" className="px-2 w-[90px]">Trouble</label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input type="radio" id={`status-${task.id}`} name={`status-${task.id}`}
                                                        value="broken"
                                                        disabled={fromHistory}
                                                        defaultChecked={onChecked(task.id, "status", "broken")}
                                                        onChange={handleTaskForm}
                                                        className="radio bg-white"
                                                    />
                                                    <label htmlFor="broken" className="px-2 w-[90px]">Broken</label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        }

                        <div className="flex items-center mt-10 mb-4">
                            <label htmlFor="action" className="text-white font-bold px-3 min-w-[170px]">
                                Action :
                            </label>
                            <textarea
                                name="action"
                                id="action"
                                disabled={fromHistory}
                                {...register("action", {
                                    pattern: {
                                        value: /^\S+/,
                                        message: "Entered value cant start or contain only white spacing"
                                    },
                                })}
                                pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                className={`textarea w-[450px] min-h-[200px] text-black text-sm border-2 focus:border-purple-500 ${errors?.action ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                            ></textarea>
                        </div>

                        <div className="flex items-center mb-4">
                            <label htmlFor="note" className="text-white font-bold px-3 min-w-[170px]">
                                Note :
                            </label>
                            <textarea
                                name="note"
                                id="note"
                                disabled={fromHistory}
                                {...register("note", {
                                    pattern: {
                                        value: /^\S+/,
                                        message: "Entered value cant start or contain only white spacing"
                                    },
                                })}
                                pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                className={`textarea w-[450px] min-h-[200px] text-black text-sm border-2 focus:border-purple-500 ${errors?.note ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                            ></textarea>
                        </div>

                        <div className="flex items-center mb-4">
                            <label htmlFor="condition" className="text-white font-bold px-3 min-w-[170px]">
                                Asset Condition :
                            </label>
                            <select
                                name="condition"
                                id="condition"
                                disabled={fromHistory}
                                {...register("condition", {
                                    required: "Required!",
                                })}
                                className={`select select-primary w-[450px] font-normal ${errors?.condition ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                            >
                                <option value="" disabled selected>-- Select Condition --</option>
                                <option value="good">Good</option>
                                <option value="unserviceable">Unserviceable</option>
                                {watch("status") !== MAINTENANCE_STATUS.DONE && <option value="inservice">In Service</option>}
                            </select>
                        </div>

                        <div className="flex items-center mb-4">
                            <label htmlFor="status" className="max-w-[169px] text-white font-bold px-3 min-w-[170px]">
                                Maintenance Status :
                            </label>
                            <select
                                name="status"
                                id="status"
                                disabled={fromHistory}
                                {...register("status", {
                                    required: "Required!",
                                })}
                                className={`select select-primary w-[450px] font-normal ${errors?.status ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                            >
                                <option value="" disabled selected>-- Select Status --</option>
                                {watch("condition") !== "inservice" && <option value={MAINTENANCE_STATUS.DONE}>Done</option>}
                                <option value={MAINTENANCE_STATUS.IN_PROGRESS}>In Progress</option>
                                <option value={MAINTENANCE_STATUS.ESCALATION}>Escalation</option>
                                <option value={MAINTENANCE_STATUS.HOLD}>Hold</option>
                            </select>
                        </div>

                        <div>{renderStatus(watch("status"))}</div>

                        <div className="flex justify-end gap-x-4 mt-10 mr-10">
                            <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="btn btn-outline text-white"
                            >
                                Cancel
                            </button>
                            {!fromHistory && (
                                <button
                                    type="submit"
                                    onClick={handleSubmit(onSubmit)}
                                    className="btn btn-primary text-white px-6"
                                >
                                    Apply
                                </button>
                            )}
                            {processType === PROCESS_TYPE.UPDATE && (
                                <ReportMaintenance
                                    title={fromHistory ? "Maintenance History" : "Maintenance Data"}
                                    data={detailSelectedAssetData}
                                    action={getValues("action")}
                                    condition={getValues("condition")}
                                    status={getValues("status")}
                                    note={getValues("note")}
                                    maintenanceType={selectedMaintenanceType}
                                    task={taskForm}
                                />
                            )}
                        </div>

                    </form>
                </section>

                <section className="w-[60%] mt-10">
                    <h1 className="font-bold text-white px-3 mb-4">Docs Asset</h1>

                    {detailSelectedAssetData && urlFiles && urlFiles.map((item, index) => {
                            return (
                                <ol key={index} className="text-white mb-5 px-2">
                                    <li className="flex items-baseline my-3">
                                        <p>{`${index + 1}.`}</p>
                                        <button
                                            onClick={() => downloadPdf(item)}
                                            className="bg-white rounded-md p-1 mx-2 max-h-6"
                                        >
                                            <DownloadIcon width={18} height={16}/>
                                        </button>
                                        <p className="cursor-pointer" onClick={() => {
                                            let secureUrl = item.value;
                                            if (process.env.REACT_APP_NODE_ENV === "production") {
                                                secureUrl = item.value.replace('http://', 'https://');
                                            }

                                            setPdfToRead(secureUrl)
                                        }}>
                                            {item.label}
                                        </p>
                                    </li>
                                </ol>
                            )
                    })}

                    {pdfToRead ? (
                        <article>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
                                <div className="h-[570px]">
                                    <div
                                        style={{
                                            alignItems: "center",
                                            backgroundColor: "#ddcfcf",
                                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                            display: "flex",
                                            justifyContent: "center",
                                            padding: "4px"
                                        }}
                                    >
                                        <ZoomOutButton/>
                                        <ZoomPopover/>
                                        <ZoomInButton/>
                                    </div>
                                    <Viewer fileUrl={pdfToRead}
                                            plugins={[zoomPluginInstance, pageNavigationPluginInstance]}></Viewer>
                                    <div
                                        className="flex justify-center items-center bg-[#ddcfcf] border-b-2 border-neutral-800 p-2"
                                    >
                                        <GoToPreviousPageButton/>
                                        <div className="bg-white mx-3 px-3 py-1 rounded-md">
                                            <CurrentPageLabel/>
                                        </div>
                                        <div className="mr-3">
                                            {"/ "}<NumberOfPages/>
                                        </div>
                                        <GoToNextPageButton/>
                                    </div>
                                </div>
                            </Worker>
                        </article>
                    ) : (
                        <p className="text-white px-3 mb-4">File is empty</p>
                    )}
                </section>

                <div className="createModal">
                    <Modals
                        open={open}
                        onClose={handleClose}
                        onSave={handleLoadTask}
                        btnCloseTitle="Cancel"
                        btnSubmitTitle="Load Template"
                        title={"Template Maintenance"}
                        size="w-350"
                        height="h-3/4"
                        closeBtn={true}
                    >
                        <form className="flex justify-start mt-10" onSubmit={handleSearchTemplate}>
                            <SearchBox
                                name="searchText"
                                value={searchTextOnChange}
                                placeholder="Search Template"
                                className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary border-white"
                                onChangeHandler={onSearchChange}
                            />
                            <span className="flex justify-center items-center text-white text-lg px-1 mr-auto">
                                <button
                                    type="submit"
                                    className="btn btn-primary rounded-full text-white text-xs px-7"
                                >
                                    Search
                                </button>
                            </span>
                        </form>

                        {allMasterTemplate && allMasterTemplate.length > 0 ? (
                            <div className="mt-10">
                                <Table
                                    data={allMasterTemplate}
                                    column={COLUMN_TEMPLATE}
                                    action={false}
                                    handleSelectRow={handleSelectRow}
                                />
                            </div>
                        ) : (
                            <p className="w-[50%] flex flex-col text-white text-center pt-10">
                                Template is empty
                            </p>
                        )}

                        <div className="text-white flex flex-col">
                            <div className="flex justify-center my-6 self-end h-14 items-center">
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={totalPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClickSearchTemplate}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    </Modals>
                </div>
            </div>
        </div>
    )
}

export default CreateMaintenance;