import * as React from "react"

const MaintenanceScheduleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 8267.72 11692.91"
    {...props}
  >
    <g strokeMiterlimit={22.926}>
      <path
        fill="currentColor"
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M-292.65 165.63h8904.69c677.3 0 1231.45 554.15 1231.45 1231.45v8904.69c0 677.3-554.15 1231.46-1231.45 1231.46H-292.65c-677.3 0-1231.46-554.15-1231.46-1231.46V1397.08c0-677.3 554.15-1231.45 1231.46-1231.45zm304.78 389.1h8295.11c630.96 0 1147.14 516.19 1147.14 1147.14v8295.11c0 630.96-516.19 1147.14-1147.14 1147.14H12.13c-630.96 0-1147.14-516.19-1147.14-1147.14V1701.87c0-630.96 516.19-1147.14 1147.14-1147.14z"
      />
      <rect
        fill="none"
        stroke="#2B2A29"
        strokeWidth={78.74}
        x={2165.56}
        y={1866.28}
        width={6835.1}
        height={5869.69}
        rx={724.86}
        ry={962.62}
      />
      <path
        fill="currentColor"
        stroke="#2B2A29"
        strokeWidth={78.74}
        d="M2165.56 1866.27h6835.1v1964.39h-6835.1z"
      />
      <path
        fill="currentColor"
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M4980.34 4240.79h1205.55v1120.65H4980.34zM6988.59 4240.79h1205.55v1120.65H6988.59zM2972.08 4240.79h1205.55v1120.65H2972.08zM4980.34 5681.44h1205.55v1120.65H4980.34zM6988.59 5681.44h1205.55v1120.65H6988.59zM2972.08 5681.44h1205.55v1120.65H2972.08z"
      />
      <path
        fill="currentColor"
        stroke="#FEFEFE"
        strokeWidth={98.43}
        d="M732.95 3477.39c379.28 197.69 638.28 594.44 638.28 1051.74 0 140.85-24.62 275.93-69.7 401.25l3938.54 3615.35a795.331 795.331 0 0 1 50.15 46.04c306.09 306.1 306.09 802.38 0 1108.48-306.1 306.09-802.38 306.09-1108.47 0a795.58 795.58 0 0 1-46.04-50.15L487.45 5675.71c-96.28 25.26-197.34 38.76-301.55 38.76-654.65 0-1185.34-530.69-1185.34-1185.33 0-457.3 259-854.06 638.28-1051.74v1055.14l554.5 345.6 539.62-379.07V3477.4zM4442.1 8852.14c162.3-162.3 425.44-162.3 587.74 0s162.3 425.44 0 587.74-425.44 162.3-587.74 0-162.3-425.44 0-587.74z"
      />
      <circle
        fill="currentColor"
        stroke="#FEFEFE"
        strokeWidth={98.43}
        transform="rotate(-45.001 13408.294 -1143.649) scale(.14862)"
        r={2796.41}
      />
      <path
        fill="currentColor"
        stroke="#FEFEFE"
        strokeWidth={98.43}
        d="m1920.31 5854.15 2283.78 2283.78c131.5 131.5 131.5 344.69 0 476.19-131.49 131.49-344.69 131.49-476.19 0L1444.12 6330.34c-131.5-131.5-131.5-344.69 0-476.19s344.69-131.5 476.19 0z"
      />
    </g>
  </svg>
)

export default MaintenanceScheduleIcon
