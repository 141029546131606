export const downloadPdf = (url) => {
    let secureUrl = url.value;
    if (process.env.REACT_APP_NODE_ENV === "production") {
        secureUrl = url.value.replace('http://', 'https://');
    }
    fetch(secureUrl)
        .then(response => {
            response.arrayBuffer().then(function(buffer) {
                const urlBlob = window.URL.createObjectURL(new Blob([buffer], { type: 'application/pdf' }));
                const link = document.createElement('a');
                link.href = urlBlob;
                link.setAttribute('download', url.label);
                document.body.appendChild(link);
                link.click();
            });
        })
        .catch(error => {
            console.error('Error:', error);
        });
};

export const downloadImage = (url, index) => {
    let secureUrl = url;
    if (process.env.REACT_APP_NODE_ENV === "production") {
        secureUrl = url.replace('http://', 'https://');
    }

    fetch(secureUrl)
        .then(response => {
            response.arrayBuffer().then(function(buffer) {
                const urlBlob = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement('a');
                link.href = urlBlob;
                link.setAttribute('download', `Radio Image ${index}.jpg`);
                document.body.appendChild(link);
                link.click();
            });
        })
        .catch(error => {
            console.error('Error:', error);
        });
};