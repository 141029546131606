import React, { forwardRef } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
  // interaction: {
  //     mode: "y",
  // },
    responsive: true,
    plugins: {
        legend: {
            position: "top",
        },
        title: {
            display: true,
        },
    },
    borderCapStyle: "square",
    indexAxis: "y",
    scales: {
        y: {
            grid: {
                display: true,
                borderColor: "black",
            },
            title: {
            display: true,
            text: "High (Feet)",
            color: "black",
            font: {
                weight: "bold",
            },
            padding: 10,
            },
            ticks: {
                color: "black",
            },
        },
        x: {
            grid: {
                display: true,
                borderColor: "black",
            },
            title: {
                display: true,
                text: "Range (NM)",
                color: "black",
                font: {
                weight: "bold",
                },
                padding: 10,
            },
            ticks: {
                color: "black",
            },
        },
    },
};

const ChartOperation = forwardRef(({ chartLabel, chartData, height }, ref) => {
    const labels = chartLabel;
    let cusDataSets = [];
    if (chartData.length > 1) {
        cusDataSets.push(
            {
                label: chartData[0].label,
                data: chartData[0].data,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
                label: chartData[1].label,
                data: chartData[1].data,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            }
        );
        } else {
        cusDataSets.push({
            label: chartData[0].label,
            data: chartData[0].data,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
        });
    }

    const data = {
        labels,
        datasets: cusDataSets,
    };

    return (
        <React.Fragment>
            <Line ref={ref} data={data} options={options} height={height} />
        </React.Fragment>
    );
});

export default ChartOperation;
