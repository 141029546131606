import {ACTION_GET, ACTION_PUT, ACTION_POST, ACTION_DELETE, BASE_URL} from "./core";


export const SEARCH_CATEGORY = (page, size, type, q) => {
    const url = `${BASE_URL}/refassetcategory/search?page=${page}&size=${size}&type=${type}&q=${q.toLowerCase()}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const GET_CATEGORY_BY_ID = (id) => {
    const url = `${BASE_URL}/refassetcategory/detail?category=${id}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const CREATE_CATEGORY = (param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/refassetcategory`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const UPDATE_CATEGORY = (id, param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/refassetcategory/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const DELETE_CATEGORY_BY_ID = (id) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/refassetcategory/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

