import * as React from "react";

const ElectronicIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1229 1229"
    {...props}
  >
    <g fill="currentColor">
      <path
        stroke="currentColor"
        strokeWidth={0.851}
        d="M134 0h962c73 0 133 60 133 134v962c0 73-60 133-133 133H134c-74 0-134-60-134-133V134C0 60 60 0 134 0zm32 42h897c68 0 124 56 124 124v897c0 68-56 124-124 124H166c-68 0-124-56-124-124V166C42 98 98 42 166 42z"
      />
      <path
        stroke="currentColor"
        strokeWidth={10.636}
        d="m836 393 221 225h-73v444H245V618h-73l221-225 222-226z"
      />
      <path
        stroke="#332C2B"
        strokeWidth={12.763}
        d="m531 531 94 55-23 39c12 14 21 31 28 48h45v109h-45c-7 17-16 34-28 48l23 40-94 54-23-40c-9 2-18 3-28 3-9 0-19-1-28-3l-23 40-93-54 23-40c-12-14-22-31-28-48h-46V673h46c6-17 16-34 28-48l-23-39 93-55 23 40c9-2 19-2 28-2 10 0 19 0 28 2l23-40zm-51 109c49 0 88 39 88 88 0 48-39 87-88 87-48 0-88-39-88-87 0-49 40-88 88-88z"
      />
      <path
        stroke="#332C2B"
        strokeWidth={3.191}
        d="m477 680 24 40-4 2 17 25-4 2 17 26-43-31 5-2-27-21 5-2-34-35z"
      />
      <path
        stroke="#332C2B"
        strokeWidth={6.382}
        d="m854 740 59 34-15 24c8 9 14 19 18 30h28v68h-28c-4 11-10 21-18 30l15 25-59 33-14-24c-6 1-11 1-17 1s-12 0-18-1l-14 24-58-33 14-25c-7-9-13-19-17-30h-29v-68h29c4-11 10-21 17-30l-14-24 58-34 14 25c6-1 12-2 18-2s11 1 17 2l14-25zm-31 67c30 0 54 25 54 55s-24 55-54 55c-31 0-55-25-55-55s24-55 55-55z"
      />
      <path
        stroke="#332C2B"
        strokeWidth={3.191}
        d="m821 833 15 24-3 1 11 16-3 2 11 16-27-19 3-2-17-13 3-1-21-22z"
      />
    </g>
  </svg>
);

export default ElectronicIcon;
