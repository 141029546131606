import React from 'react';

const SearchBox = ({
    name,
    value,
    placeholder,
    className,
    onChangeHandler
}) => {
    return (
        <input
            type="search"
            name={name}
            value={value}
            placeholder={placeholder}
            className={className}
            onChange={onChangeHandler}
        />
    )
}

export default SearchBox;