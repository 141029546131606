import axios from "axios";
import { ACTION_GET, ACTION_PUT, ACTION_POST, ACTION_DELETE } from "./core";

// export const BASE_URL_SERVER_MABES = process.env.REACT_APP_BASE_SERVER_MABES_URL;
export const BASE_URL_SERVER_MABES = process.env.REACT_APP_BASE_SERVER_MABES_URL;

export const GET_TOWER_CIVIL_FROM_SERVER = (url) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL_SERVER_MABES}/distributionassets/${url}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject({...err.response.data, status: err.response.status});
            });
    });

}

export const GET_ALL_SITE = () => {
    const url = `${BASE_URL_SERVER_MABES}/site?size=100`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
        .then((result) => {
            resolve(result);
        })
        .catch((error) => {
            reject(error);
        });
    });
};

export const CREATE_TOWER_SERVER = (param) => {
    const url = `${BASE_URL_SERVER_MABES}/distributionassets`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const UPDATE_CIVIL = (id, param) => {
    const url = `${BASE_URL_SERVER_MABES}/distributionassets/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const SEARCH_TOWER_SERVER = (q, type, page, size, siteId) => {
    const url = `${BASE_URL_SERVER_MABES}/distributionassets/search/5?q=${q.toLowerCase()}&type=${type}&page=${page}&size=${size}&site=${siteId}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const DELETE_TOWER_SERVER_BY_ID = (param) => {
    const url = `${BASE_URL_SERVER_MABES}/distributionassets/${param}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};