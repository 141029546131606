import {ACTION_GET, ACTION_POST, ACTION_PUT, BASE_URL} from "./core";

export const GET_ALL_NETWORKS = () => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/analyze/network`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};


export const GET_ALL_USAGE_PM = () => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/refMaster/usage/pm?page=0&size=2000`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const POST_SITE = (body) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/analyze/area`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, body)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const PUT_SITE = (id) => {
    const url = `${BASE_URL}/analyze/${id}/save`
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, {})
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const POST_OP = (body) => {
    const url = `${BASE_URL}/op/create`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, body)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export const CREATE_SIMULATE = (body) => {
    const url = `${BASE_URL}/op/create/simulate`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, body)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const CREATE_PATH = (body) => {
    const url = `${BASE_URL}/analyze/path`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, body)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const CREATE_POINT = (body) => {
    const url = `${BASE_URL}/analyze/points`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, body)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};


