export const USER_ACTION_TYPES = {
    SIGN_IN_START: 'user/SIGN_IN_START',
    CLEAR_A_USER_DATA: 'user/CLEAR_A_USER_DATA',
    GET_A_USER: 'user/GET_A_USER',
    REMOVE_USER_STATUS: 'user/REMOVE_USER_STATUS',
    GET_ALL_USER: 'user/GET_ALL_USER',
    CHANGED_NAME: 'user/CHANGED_NAME',
    UPDATE_USER: 'user/UPDATE_USER',
    CLEAR_UPDATE_USER_STATUS: 'user/CLEAR_UPDATE_USER_STATUS',
    RESET_CREATE_USER_DATA: 'user/RESET_CREATE_USER_DATA',
    CREATE_USER: 'user/CREATE_USER',
    SIGN_IN_SUCCESS: 'user/SIGN_IN_SUCCESS',
    SIGN_IN_FAILED: 'user/SIGN_IN_FAILED',
    SIGN_OUT_START: 'user/SIGN_OUT_START',
    SIGN_OUT_SUCCESS: 'user/SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILED: 'user/SIGN_OUT_FAILED',
    REMOVE_ERROR: 'user/REMOVE_ERROR',
    CHECK_USER_SESSION: 'user/CHECK_USER_SESSION',
    AUTHED: 'user/AUTHED',
    RESET_REDUCER: 'user/RESET_REDUCER',
    CLEAR_ALL_USER_DATA: 'user/CLEAR_ALL_USER_DATA',
}

export const DEFAULT_SIZE_REQUEST = 10;