import {FREQUENCY_ACTION_TYPES} from "./frequency.types";
import {RADIO_BAND} from "../../utils/constant/constants";

export const INITIAL_STATE = {
    allSite: null,
    clickListener: null,
    dataRadio: null,
    filteredBand: RADIO_BAND.ALL,
    siteId: -1,
    siteEntities: null, // []
}
export const frequencyReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case FREQUENCY_ACTION_TYPES.GET_ALL_SITE:
            return {...state, allSite: payload}
        case FREQUENCY_ACTION_TYPES.REGISTER_HANDLER_LISTENER:
            return {...state, clickListener: payload}
        case FREQUENCY_ACTION_TYPES.GET_RADIO:
            return {...state, dataRadio: payload}
        case FREQUENCY_ACTION_TYPES.FILTERED_BAND:
            return {...state, filteredBand: payload}
        case FREQUENCY_ACTION_TYPES.SITE_ID:
            return {...state, siteId: payload}
        case FREQUENCY_ACTION_TYPES.SITE_ENTITIES:
            return {...state, siteEntities: payload}
        case FREQUENCY_ACTION_TYPES.CLEAR_DATA:
            return INITIAL_STATE;
        default:
            return {...state}
    }
}