export const getNumberIdEntity = (idBillboard) => {
    let res = null;
    if (idBillboard.includes("-")) {
        res = idBillboard.split("-")[1];
    }
    return res;
}

export function getChartData(responsePath, copyChartData) {
    let copy = {...copyChartData};
    if (responsePath && responsePath.resultPoint &&
        responsePath.resultPoint.transmitters && responsePath.resultPoint.transmitters.length) {
        const transmittersLength = responsePath.resultPoint.transmitters.length;
        // nodes
        copy.nodes = transmittersLength + 1;
        let count = 0;
        let countA = 0;
        let countB = 0;
        let countC = 0;
        responsePath.resultPoint.transmitters.forEach(d => {
            count += d.signalPowerAtReceiverDBm;
            if (d.signalPowerAtReceiverDBm >= -80) {
                countA += 1;
            } else if (d.signalPowerAtReceiverDBm < -80 && d.signalPowerAtReceiverDBm >= -90) {
                countB += 1;
            } else {
                countC += 1;
            }
        })
        // mean
        copy.mean = Number((count / transmittersLength).toFixed(2));
        copy.persentageA = countA / transmittersLength * 100;
        copy.persentageB = countB / transmittersLength * 100;
        copy.persentageC = countC / transmittersLength * 100;
    }
    return copy;
}

export function addIntoArray(state, payload) {
    let copyArray = []
    if (state && state.length) {
        copyArray = [...state];
    }
    copyArray.push(payload)
    return copyArray;
}