import * as React from "react";

const Antenna2Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1126 1126"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="#FFF"
      strokeWidth={7.793}
      d="m440 821-42 42c-31 31-105 9-164-50-59-60-81-133-50-164l3-3 36-36c-27 33-4 104 53 161 59 59 133 82 164 50z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.779}
      d="M256 462c63-63 185-43 272 45 3 2 5 5 7 7 3 2 5 5 8 7 87 88 108 210 45 273-25 25-59 36-97 36l-73-1c-37 5-89-19-134-64-44-44-68-96-64-133l-1-73c0-38 12-72 37-97z"
    />
    <path
      fill="#183343"
      stroke="none"
      strokeWidth={0.779}
      d="M556 762c51-51 34-149-37-220l-6-6c-1-2-3-4-5-6-71-71-170-87-220-37-51 51-35 150 36 221 2 2 4 4 6 5l6 6c71 71 169 88 220 37z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.779}
      d="M458 801c31-31 8-104-51-164-59-59-132-81-164-50-31 31-9 105 51 164 59 59 132 82 164 50z"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={0.779}
      d="M593 768c54-54 29-168-58-254-86-86-200-112-254-58s-28 168 58 254c86 87 200 112 254 58z"
    />
    <path
      fill="#FFFF"
      stroke="currentColor"
      strokeWidth={0.779}
      d="M259 577c11 44 39 92 80 133 39 40 85 67 127 79 17-36-6-99-59-152-51-51-112-74-148-60z"
    />
    <g fill="currentColor" stroke="#FEFEFE" strokeWidth={5.845}>
      <path d="m468 571-51 30-93 56c10 16 23 33 39 48 5 5 10 10 15 14l68-85 37-46c-8-2-14-9-15-17z" />
      <path d="M488 589c12 1 22-8 23-20s-8-22-20-23-23 8-23 20c-1 12 8 22 20 23z" />
    </g>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={0.779}
      d="M954 877h2.062v.687H954z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.779}
      d="M859 339c76 83 73 193 1 267 19-73-1-155-63-222-68-74-169-114-269-113 110-46 251-19 331 68zm-113 67c61 66 58 154 1 213 15-58-1-123-50-177-54-59-136-91-215-90 88-38 200-16 264 54zm-261 24c55-23 126-10 166 34 37 41 36 96 0 133 9-36-1-77-31-110-34-38-85-57-135-57z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.779}
      d="M122 0h882c67 0 121 55 121 122v882c0 67-54 121-121 121H122c-67 0-122-54-122-121V122C0 55 55 0 122 0zm30 39h821c63 0 114 51 114 113v821c0 63-51 114-114 114H152c-62 0-113-51-113-114V152C39 90 90 39 152 39z"
    />
  </svg>
);

export default Antenna2Icon;
