export const ELECTRONIC_ACTION_TYPES = {
    GET_STATUS : 'electronic/GET_STATUS',
    GET_ALL_ELECTRONIC : 'electronic/GET_ALL_ELECTRONIC',
    GET_CATEGORY : 'electronic/GET_CATEGORY',
    GET_BRAND : 'electronic/GET_BRAND',
    GET_TYPE : 'electronic/GET_TYPE',
    GET_SITE : 'electronic/GET_SITE',
    CLEAR_DATA : 'electronic/CLEAR_DATA',
    CHANGE_PAGE : 'electronic/CHANGE_PAGE',
    UPLOAD_ELECTRONIC_STATUS : 'electronic/UPLOAD_ELECTRONIC_STATUS',
    UPLOAD_IMAGE_STATUS : 'electronic/UPLOAD_IMAGE_STATUS',
    UPLOAD_FILE_STATUS : 'electronic/UPLOAD_FILE_STATUS',
    UPLOAD_IMAGE : 'electronic/UPLOAD_IMAGE',
    UPLOAD_FILE : 'electronic/UPLOAD_FILE',
    IS_LOADING : 'electronic/IS_LOADING',
    REMOVED : 'electronic/REMOVED',
    UPDATED : 'electronic/UPDATED',
    SEARCH : 'electronic/SEARCH',
    DETAIL_ELECTRONIC : 'electronic/DETAIL_ELECTRONIC',
    CLEAR_DETAIL_ELECTRONIC : 'electronic/CLEAR_DETAIL_ELECTRONIC',
}