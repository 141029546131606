import * as React from "react";

const PabxIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1920 1920"
    {...props}
  >
    <g stroke="currentColor">
      <path fill="currentColor" strokeWidth={1.329} d="M552 868h87l-87-168z" />
      <path
        fill="none"
        strokeWidth={9.966}
        d="M268 851h359l36 56h429l-36-56h70c11 0 25 8 32 18l468 635H750L268 851z"
      />
      <path fill="currentColor" strokeWidth={1.329} d="M431 1278H263v-271z" />
      <path
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.55262 0 .37773 .73005 552.161 689.313)"
        d="M0 0h769v318H0z"
      />
      <path
        fill="#FEFEFE"
        strokeWidth={1.329}
        transform="matrix(.27487 0 .22801 .36313 379.509 924.152)"
        d="M0 0h769v318H0z"
      />
      <path
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.22015 0 .18117 .29083 394.386 947.113)"
        d="M0 0h769v318H0z"
      />
      <rect
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.17177 0 .14136 .1942 1038.63 991.051)"
        width={769}
        height={318}
        rx={60}
        ry={60}
      />
      <rect
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.17177 0 .14136 .1942 884.145 991.051)"
        width={769}
        height={318}
        rx={60}
        ry={60}
      />
      <rect
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.17177 0 .14136 .1942 727.858 991.051)"
        width={769}
        height={318}
        rx={60}
        ry={60}
      />
      <rect
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.17177 0 .14136 .1942 1103.86 1084)"
        width={769}
        height={318}
        rx={60}
        ry={60}
      />
      <rect
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.17177 0 .14136 .1942 949.378 1084)"
        width={769}
        height={318}
        rx={60}
        ry={60}
      />
      <rect
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.17177 0 .14136 .1942 793.091 1084)"
        width={769}
        height={318}
        rx={60}
        ry={60}
      />
      <rect
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.17177 0 .14136 .1942 1172.22 1170.98)"
        width={769}
        height={318}
        rx={60}
        ry={60}
      />
      <rect
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.17177 0 .14136 .1942 1017.74 1170.98)"
        width={769}
        height={318}
        rx={60}
        ry={60}
      />
      <rect
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.17177 0 .14136 .1942 861.448 1170.98)"
        width={769}
        height={318}
        rx={60}
        ry={60}
      />
      <rect
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.17177 0 .14136 .1942 1258.53 1285.14)"
        width={769}
        height={318}
        rx={60}
        ry={60}
      />
      <rect
        fill="currentColor"
        strokeWidth={1.329}
        transform="matrix(.17177 0 .14136 .1942 947.761 1285.14)"
        width={769}
        height={318}
        rx={60}
        ry={60}
      />
      <path fill="none" strokeWidth={1.329} d="M749 1527v177" />
      <path
        fill="currentColor"
        strokeWidth={1.329}
        d="M749 1527h879v204H749zM733 1511 265 876v88l468 738z"
      />
      <path
        fill="currentColor"
        strokeWidth={1.329}
        d="M208 1h1503c115 0 208 93 208 207v1503c0 115-93 208-208 208H208c-114 0-207-93-207-208V208C1 94 94 1 208 1zm52 65h1400c106 0 193 87 193 194v1400c0 106-87 193-193 193H260c-107 0-194-87-194-193V260c0-107 87-194 194-194z"
      />
      <path fill="none" strokeWidth={1.329} d="M1859 1292h3.778v1.259H1859z" />
      <path
        fill="currentColor"
        strokeWidth={1.329}
        d="M1610 352c103 113 99 262 2 363 25-99-3-210-87-302-92-101-231-155-366-154 149-64 342-27 451 93zm-154 91c82 90 79 210 1 290 20-79-2-168-69-241-73-81-184-124-293-123 120-51 274-22 361 74zm-356 33c75-32 171-14 226 46 51 56 49 131 0 182 13-50-1-105-43-151-46-51-115-78-183-77z"
      />
    </g>
  </svg>
);

export default PabxIcon;
