import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import ReactPaginate from "react-paginate";

import HeaderPage from "../../components/header/HeaderPage";
import Table from "../../components/table/Table";

import PlusIcon from "../../assets/svgs/PlusIcon";
import ChartOperation from "../../components/chart/ChartOperation";
import {
    handleCurrentSite,
    handleDeactivateOP,
    handleFetchAllOpData,
    handleGetActualDto,
    handleNotifPageNumber,
    handleRemoveOP,
    handleResetActionStatus,
    handleStorePage
} from "../../store/op/op.action";
import {
    selectAnOpData,
    selectDataTable,
    selectDistanceActualLabel,
    selectDistanceSimulationLabel,
    selectHeaderColumn,
    selectHighLabel,
    selectNotifFromOpPage,
    selectPageNumber,
    selectRemoveOPStatus,
    selectSizePage,
    selectTotalPage
} from "../../store/op/op.selector";
import {
    ALERT_ICON_FAILED,
    ALERT_ICON_SUCCESS,
    ALERT_TEXT_REMOVE_FAILED,
    ALERT_TEXT_REMOVE_SUCCESS,
    ALERT_TITLE_FAILED,
    ALERT_TITLE_SUCCESS,
    ASSET_TYPE,
    CONFIRMATION_ICON_REMOVE,
    CONFIRMATION_TEXT_REMOVE,
    CONFIRMATION_TITLE_REMOVE,
    STATUS_TYPE
} from "../../utils/constant/constants";
import {
    selectAssetToEdit,
    selectAssetToRemove,
    selectCurrentItem,
    selectDetailAsset
} from "../../store/global/global.selector";
import {handleClearStateGlobal, handleLoading} from "../../store/global/global.action";
import {generalAlert, generalConfirmation} from "../../utils/notification/notification";

const OperationData = () => {

    
    const dispatch = useDispatch();

    const dataTable = useSelector(selectDataTable);
    const headerColumn = useSelector(selectHeaderColumn);
    const anOpData = useSelector(selectAnOpData);
    const heighLabel = useSelector(selectHighLabel);
    const distanceSimLabel = useSelector(selectDistanceSimulationLabel);
    const distanceActualLabel = useSelector(selectDistanceActualLabel);
    const totalPages = useSelector(selectTotalPage);
    const sizePage = useSelector(selectSizePage);
    const currentItem = useSelector(selectCurrentItem);
    const detailAsset = useSelector(selectDetailAsset);
    const assetToEdit = useSelector(selectAssetToEdit);
    const asseToRemove = useSelector(selectAssetToRemove);
    const removeOPStatus = useSelector(selectRemoveOPStatus);
    const notifFromOpPage = useSelector(selectNotifFromOpPage);
    const pageNumber = useSelector(selectPageNumber);

    const [chartLabel, setChartLabel] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [page, setPage] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(handleLoading(true));
        if (notifFromOpPage) {
            dispatch(handleFetchAllOpData(pageNumber, 10));
            setPage(pageNumber)
        } else {
            dispatch(handleDeactivateOP());
            dispatch(handleFetchAllOpData(page, 10));
            dispatch(handleStorePage(page));
        }
        dispatch(handleNotifPageNumber(false));

        return () => {
            dispatch(handleDeactivateOP());// clear all state
            setPage(0);
            dispatch(handleLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (anOpData) {
            setChartLabel(heighLabel)
            let dataArr = []
            if (distanceSimLabel) {
                dataArr.push(distanceSimLabel)
            }
            if (distanceActualLabel) {
                dataArr.push(distanceActualLabel)
            }
            setChartData(dataArr)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anOpData]);

    useEffect(()=>{
        if (removeOPStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === removeOPStatus;
            const isFailed = STATUS_TYPE.FAILED === removeOPStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_REMOVE_SUCCESS : ALERT_TEXT_REMOVE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => dispatch(handleResetActionStatus())
                );
            }
            if (dataTable && dataTable.length) {
                dispatch(handleFetchAllOpData(page, 10));
            } else {
                if (page) {
                    dispatch(handleFetchAllOpData(page - 1, 10));
                    setPage(prevPage => prevPage - 1);
                } else {
                    dispatch(handleFetchAllOpData(0, 10));
                    setPage(0);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeOPStatus])

    useEffect(() => {
        if (currentItem) {
            if (ASSET_TYPE.OP === detailAsset) {
                dispatch(handleStorePage(page));
                navigate(`/operationdata/detail/${currentItem.id}`)
            } else if (ASSET_TYPE.OP === assetToEdit) {
                dispatch(handleStorePage(page));
                navigate(`/operationdata/addactualdata/${currentItem.id}`)
            } else if (ASSET_TYPE.OP === asseToRemove) {
                generalConfirmation(
                    CONFIRMATION_TITLE_REMOVE,
                    CONFIRMATION_TEXT_REMOVE(currentItem.name),
                    CONFIRMATION_ICON_REMOVE,
                    () => dispatch(handleRemoveOP(currentItem.id, page, sizePage)),
                    () => {
                        dispatch(handleClearStateGlobal());
                        setChartData([]);
                    },
                )
            }
            dispatch(handleClearStateGlobal());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentItem]);

    const handleSelectRow = (id) => {
        dispatch(handleGetActualDto(id)); // get actual dto get lates date if any, or display just sumulation data
    };

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        dispatch(handleFetchAllOpData(e.selected, sizePage));
        setPage(e.selected);
    };

    const handleCreateReport = (data) => {
        dispatch(handleCurrentSite(data));
    };

    return (
        <div className="bg-tertiary min-h-screen px-20 py-4">
            <HeaderPage title="Operation Data"/>

            <div className="flex mt-10 gap-x-16">
                <div className="w-[40%] mt-10">
                    <div className="flex justify-end mb-6">
                        <NavLink
                            to="/dashboard/planning"
                            className="flex justify-center items-center text-white gap-x-2 text-lg cursor-pointer"
                        >
                            <button className="cursor-pointer">
                                <PlusIcon/>
                            </button>
                            Add Operation Plan
                        </NavLink>
                    </div>

                    {dataTable && headerColumn && dataTable.length > 0 && headerColumn.length > 0 ? (
                        <>
                            <Table
                                data={dataTable}
                                column={headerColumn}
                                handleSelectRow={handleSelectRow}
                                assetType={ASSET_TYPE.OP}
                                createReport={handleCreateReport}
                            />
                        </>
                    ) : (
                        <p className="flex flex-col text-white text-lg text-center pt-10">
                            Data is empty
                        </p>
                    )}

                    <div className="text-white flex flex-col">
                        <div className="flex justify-center  my-6 self-end h-14 items-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={page}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-[60%] mt-24">
                    {chartLabel && chartData &&
                        chartLabel.length > 0 && chartData.length > 0 ? (
                            <div className="bg-[#F8F8F8] rounded-lg pr-10 pl-5 py-5">
                                <ChartOperation chartLabel={chartLabel} chartData={chartData}/>
                            </div>
                    ) : (
                        <p className="flex flex-col text-white text-lg text-center pt-10">
                            Data is empty
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OperationData;
