import React, { forwardRef } from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    elements: {
        point: {
            radius: 0,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
        // ticks: {
        //     display: false
        // },
            title: {
                display: true,
                text: "Km",
                color: "gray",
            },
        },
        y: {
            title: {
                display: true,
                text: "m AGL",
                color: "gray",
            },
        },
    },
    plugins: {
        legend: {
            position: "top",
        },
    },
};

const ChartLinkBudget = forwardRef(({ chartLabel, chartData, anyObstacle, isOpen }, ref) => {
    const labels = chartLabel;
    // console.log(JSON.stringify(chartData[1].data))
    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: chartData[0].label,
                data: chartData[0].data,
                borderColor: "rgb(68, 235, 53)",
                backgroundColor: "rgba(68, 235, 53, 0.5)",
            },
            {
                label: chartData[1].label,
                data: chartData[1].data,
                borderColor: anyObstacle ? "rgb(255, 0, 0)" : "rgb(0, 0, 255)",
                backgroundColor: anyObstacle
                ? "rgb(255, 0, 0, 0.5)"
                : "rgba(0, 0, 255, 0.5)",
            },
            {
                label: chartData[2].label,
                data: chartData[2].data,
                borderColor: "rgb(238, 75, 43)",
                backgroundColor: "rgba(238, 75, 43, 0.5)",
                borderDash: [5,4],
            },
        ],
    };

    return (
        <React.Fragment>
            <Line ref={ref} data={data} options={options} height={345} />
        </React.Fragment>
    );
});

export default ChartLinkBudget;
