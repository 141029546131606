import {LOGIN} from "../../services/user";

export const login = async (body) => {
    const result = {
        data: null,
        isAuthed: false
    }
    await LOGIN({...body})
        .then((res) => {
            result.data = res;
            result.isAuthed = true;
            localStorage.setItem('profile', JSON.stringify(res))
            localStorage.setItem("accesstoken_nccsa", res.accessToken);
        })
        .catch(() => {});

    return result;
}

// export const logout = () => {
//     return new Promise((res) => {
//         setAuthed(false);
//         res(false)
//     });
// }