import React from "react";

const LabelValue = ({ data, handleShowKeys }) => {
    return (
        <div className="flex flex-col gap-y-4 my-2">
            {data.map((val) => (
                <div className="flex justify-between items-center px-4" key={val.title}>
                    <div className="text-white text-sm w-1/3">{val.title}</div>

                    {val.type !== "click" && (
                        <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                            {typeof (val.value) === "string" && val.title !== "Coverage" && val.title !== "Efficiency" ? (
                                <select
                                    className={`bg-${val.bg ? val.bg : 'transparent'} select w-[170px] min-h-[32px] h-[32px] border-2 border-white rounded-3xl text-white text-md font-thin`}
                                >
                                    <option
                                        value={val.value}
                                        className="h-[40px] text-black focus:text-gray"
                                    >
                                        {val.value}
                                    </option>
                                </select>
                            ) : (
                                <div className={`bg-${val.bg ? val.bg : 'transparent'} input w-[170px] min-h-[32px] h-[32px] border-2 border-white rounded-3xl text-white text-sm py-[4px] pl-4`}>
                                    {val.value}
                                </div>
                            )}
                            {!!val.unit && (
                                <p className="text-sm text-white ml-2">{val.unit}</p>
                            )}
                        </div>
                    )}

                    {val.type === "click" && (
                        <div
                            onClick={handleShowKeys}
                            className="w-3/5 text-white p-1 cursor-pointer text-sm ml-5"
                        >
                            {val.value}
                        </div>
                    )}
                </div>
            ))}
            <>
                {data.map((val) => {
                    return val?.images && (
                        <div 
                            className="flex flex-row justify-center items-center gap-x-2" 
                            key={val.images[0]}
                        >
                            <img src={val.images[0]} alt="pattern" width="150" height={100} />
                            <img src={val.images[1]} width="150" height={100} alt="pattern" />
                        </div>
                )})}
            </>
        </div>
    );
};

export default LabelValue;
