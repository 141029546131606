import {ACTION_GET, ACTION_PUT, ACTION_POST, ACTION_DELETE, BASE_URL} from "./core";


export const SEARCH_TYPE = (page, size, type, q) => {
    const url = `${BASE_URL}/refassettype?page=${page}&size=${size}&q=${q}&type=${type}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const GET_TYPE_BY_ID = (id) => {
    const url = `${BASE_URL}/refassettype/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};


export const GET_TYPE_BY_USAGE = (usage) => {
    const url = `${BASE_URL}/refassettype/usage/${usage}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const GET_TYPE_BY_BRAND = (brandId) => {
    const url = `${BASE_URL}/refassettype/brand/${brandId}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const CREATE_TYPE = (param) => {
    const url = `${BASE_URL}/refassettype`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const UPDATE_TYPE = (id, param) => {
    const url = `${BASE_URL}/refassettype/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const DELETE_TYPE_BY_ID = (id) => {
    const url = `${BASE_URL}/refassettype/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

