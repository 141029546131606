import {OP_ACTION_TYPES} from "./op.types";
import {STATUS_TYPE} from "../../utils/constant/constants";


const INITIAL_STATE = {
    headerColumn: [
        {heading: "Operation Name", value: "name"}
    ],
    headerColSimPageDetail: [
        {heading: "Altitude", value: "altitude"},
        {heading: "Distance", value: "distance"},
    ],
    allOpData: null, // {content[{id, name}]}
    anOpData: null, // {id, name, siulationDtos[{id, opId, heigh, distance, area}], actualDtos[{id, opId, heigh, distance, area}]}
    currentSite: "",
    postActualSuccess: false,
    actualDtosDateResponse : null,
    removeActualStatus : STATUS_TYPE.NO_STATEMENT,
    removeOPStatus : STATUS_TYPE.NO_STATEMENT,
    pageNumber : 0,
    notifFromOpPage : false,
}

export const opReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case OP_ACTION_TYPES.NOTIF_PAGE_NUMBER_FROM_OP_PAGE:
            return {...state, notifFromOpPage: payload}
        case OP_ACTION_TYPES.PAGE_NUMBER:
            return {...state, pageNumber: payload}
        case OP_ACTION_TYPES.REMOVE_OP_STATUS:
            return {...state, removeOPStatus: payload}
        case OP_ACTION_TYPES.REMOVE_ACTUAL_STATUS:
            return {...state, removeActualStatus: payload}
        case OP_ACTION_TYPES.GET_ALL_SIMULATION:
            return {...state, allOpData: payload}
        case OP_ACTION_TYPES.ACTUAL_DTO_DATES_RESPONSE:
            return {...state, actualDtosDateResponse: payload}
        case OP_ACTION_TYPES.GET_OP_BY_ID:
            return {...state, anOpData: payload}
        case OP_ACTION_TYPES.CLEAR_AN_OP_DATA:
            return {...state, anOpData: INITIAL_STATE.anOpData}
        case OP_ACTION_TYPES.POST_ACTUAL_SUCCESS:
            return {...state, postActualSuccess: true}
        case OP_ACTION_TYPES.SHOW_CURRENT_SITE:
            return {...state, currentSite: payload}
        case OP_ACTION_TYPES.CLEAR_ALL_STATE:
            return {...INITIAL_STATE, currentSite: state.currentSite};
        default:
            return state;
    }
}