import React from 'react'

const ShowPannel = ({ isOpen, setIsOpen }) => {
  return (
    <>
        <button
            type="button"
            onClick={setIsOpen}
            className={`bg-black w-7 h-20 text-white text-xl items-center absolute top-[45vh] right-0 mt-[-25px] rounded-l-lg ${isOpen ? 'hidden' : ''}`}
        >
          &#10094;
        </button>
    </>
  )
}

export default ShowPannel