export const getCurrentDate = () => {
    // Create a new Date object
    const date = new Date();
    
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];

    // Get the day, month, and year
    let day = String(date.getDate()).padStart(2, '0')
    let month = String(monthNames[date.getMonth()]).padStart(2, '0')
    let year = date.getFullYear();

    // Return the date in the format day/month/year
    return `${day} / ${month} / ${year}`;
}

export const getCurrentTime = () => {
    // Create a new Date object
    const date = new Date();

    // Get the hours, and minutes
    let hours = date.getHours();
    let minutes = String(date.getMinutes()).padStart(2, '0');

    // Return the time in the format hours:minutes
    return `${hours}:${minutes}`;
}