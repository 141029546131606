import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";

import HeaderPage from "../../../components/header/HeaderPage";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDetailSelectedAssetData,
    selectNameSelectedAssetData,
    selectProcess,
    selectSelectedAssetType,
    selectSelectedMaintenanceData,
    selectSelectedMaintenanceType
} from "../../../store/maintenance/maintenance.selector";
import {
    handleClearMaintenanceData,
    handleCreateMaintenance,
    handleUpdateMaintenance,
} from "../../../store/maintenance/maintenance.action";
import {MAINTENANCE_TYPE, PROCESS_TYPE} from "../../../utils/constant/constants";
import { handleLoading } from "../../../store/global/global.action";

const TypeSchedule = () => {
    const [isUpdate, setIsUpdate] = useState(false);

    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue,
        watch,
    } = useForm({
        type: "",
        date: "",
        status: "",
        preventive: "",
        corrective: ""
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const nameSelectedAssetData = useSelector(selectNameSelectedAssetData);
    const detailSelectedAssetData = useSelector(selectDetailSelectedAssetData);
    const selectedAssetType = useSelector(selectSelectedAssetType);
    const selectedMaintenanceType = useSelector(selectSelectedMaintenanceType);
    const selectedMaintenanceData = useSelector(selectSelectedMaintenanceData);
    const processType = useSelector(selectProcess);

    useEffect(() => {
        if (selectedMaintenanceType && processType === PROCESS_TYPE.UPDATE) {
            setValue("type", selectedMaintenanceType.type);
            const isPreventive = selectedMaintenanceType.type === `preventive`;
            if (isPreventive) {
                setValue("preventive", selectedMaintenanceType.valueType);
            } else {
                setValue("corrective", selectedMaintenanceType.valueType);
            }
            setIsUpdate(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMaintenanceType]);


    useEffect(() => {
        if (selectedMaintenanceData && processType === PROCESS_TYPE.UPDATE) {
            setValue("status", selectedMaintenanceData.scheduleStatus);
            const scheduleDate = new Date(selectedMaintenanceData.scheduleDate);
            const day = scheduleDate.getDate() < 10 ? `0` + scheduleDate.getDate() : scheduleDate.getDate();
            const month = (scheduleDate.getMonth() + 1) < 10 ? `0` + (scheduleDate.getMonth() + 1) : (scheduleDate.getMonth() + 1);
            const year = scheduleDate.getFullYear();
            const date = `${year}-${month}-${day}`
            
            setValue("date", date);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMaintenanceData]);


    const handleApply = (data) => {
        if (data.type !== "" && data.date !== "" && data.status !== "" &&
            (data.corrective !== "" || data.preventive !== "") &&
            detailSelectedAssetData && selectedAssetType) {

            const isPrev = data?.type.toLowerCase() === "preventive";

            const body = {
                siteDto: {id: detailSelectedAssetData.siteId},
                assetId: detailSelectedAssetData.assetId,
                assetType: selectedAssetType.toUpperCase(),
                status: "",
                condition: "",
                location: detailSelectedAssetData.owner,
                preventive: isPrev ? data?.preventive : null,
                corrective: isPrev ? null : data?.corrective,
                note: "",
                action: "",
                history: false,
                schedule: true,
                scheduleStatus: data.status,
                scheduleDate: data.date,
                assetName: detailSelectedAssetData.name,
                serialNo: detailSelectedAssetData.serialNo,
                escalationTo: "",
                escalation: "",
            }
            if (isUpdate) {
                dispatch(handleLoading(true));
                dispatch(handleUpdateMaintenance(selectedMaintenanceData.id, body, navigate, MAINTENANCE_TYPE.SCHEDULE, null));
            } else {
                dispatch(handleLoading(true));
                dispatch(handleCreateMaintenance(body, navigate, MAINTENANCE_TYPE.SCHEDULE, null));
            }
            setIsUpdate(false);
            dispatch(handleClearMaintenanceData());
        }
    }

    const renderType = (type) => {
        if (type === "preventive") {
            return (
                <div className="flex flex-col mt-5">
                    <label htmlFor="preventive" className="text-white font-bold px-3 mb-2">
                        Preventive
                    </label>
                    <select
                        name="preventive"
                        id="preventive"
                        {...register("preventive", {
                            required: "Required!",
                        })}
                        className={`select select-primary w-full font-normal ${errors?.preventive ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                    >
                        <option value="" disabled selected>-- Select Type --</option>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="semester">Semester</option>
                        <option value="yearly">Yearly</option>
                    </select>
                </div>
            )
        } else if (type === "corrective") {
            return (
                <div className="flex flex-col mt-5">
                    <label htmlFor="corrective" className="text-white font-bold px-3 mb-2">
                        Corrective
                    </label>
                    <select
                        name="corrective"
                        id="corrective"
                        {...register("corrective", {
                            required: "Required!",
                        })}
                        className={`select select-primary w-full font-normal ${errors?.corrective ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                    >
                        <option value="" disabled selected>-- Select Type --</option>
                        <option value="light">Light Level</option>
                        <option value="medium">Medium Level</option>
                        <option value="heavy">Heavy Level</option>
                    </select>
                </div>
            )
        }
    }

    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="Maintenance Schedule Type"/>

            <div className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">
                    <form onSubmit={handleSubmit(handleApply)}>
                        <div className="flex flex-col">
                            <label htmlFor="selectedAsset" className="text-white font-bold px-3 mb-2">
                                Selected Asset
                            </label>
                            <input
                                name="selectedAsset"
                                id="selectedAsset"
                                type="text"
                                value={nameSelectedAssetData && nameSelectedAssetData}
                                disabled
                                className="input min-w-[300px] rounded-lg p-5 focus:outline-white focus:outline-1 focus:border-primary"
                            />
                        </div>

                        <div className="flex flex-col mt-5">
                            <label htmlFor="type" className="text-white font-bold px-3 mb-2">
                                Maintenance Type
                            </label>
                            <select
                                name="type"
                                id="type"
                                {...register("type", {
                                    required: "Required!",
                                })}
                                className={`select select-primary w-full font-normal ${errors?.type ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                            >
                                <option value="" disabled selected>-- Select Type --</option>
                                <option value="preventive">Preventive</option>
                                <option value="corrective">Corrective</option>
                            </select>
                        </div>

                        <div>{renderType(watch("type"))}</div>

                        <div className="flex flex-col mt-5">
                            <label htmlFor="date" className="text-white font-bold px-3 mb-2">
                                Date
                            </label>
                            <input
                                name="date"
                                id="date"
                                type="date"
                                {...register("date", {
                                    required: "Required!",
                                })}
                                className={`input min-w-[300px]" ${errors?.date ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                            />
                        </div>

                        <div className="flex justify-end gap-x-4 mt-10">
                            <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="btn btn-outline text-white"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary text-white">
                                Apply
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TypeSchedule;