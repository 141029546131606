import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import {useNavigate} from "react-router-dom";

import HeaderPage from "../../../components/header/HeaderPage";
import Table from "../../../components/table/Table";

import PlusIcon from "../../../assets/svgs/PlusIcon";
import Calendar from "../../../components/calendar/Calendar";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCreateStatus,
    selectEvents,
    selectMaintenanceScheduleData,
    selectRemoveStatus,
    selectUpdateStatus
} from "../../../store/maintenance/maintenance.selector";
import {
    handleClearNotif,
    handleEditMaintenanceData,
    handleGetAllMaintenanceData,
    handleNewMaintenance,
    handleRemoveMaintenanceData
} from "../../../store/maintenance/maintenance.action";
import {
    ALERT_ICON_FAILED,
    ALERT_ICON_SUCCESS,
    ALERT_TEXT_CREATE_FAILED,
    ALERT_TEXT_CREATE_SUCCESS,
    ALERT_TEXT_REMOVE_FAILED,
    ALERT_TEXT_REMOVE_SUCCESS,
    ALERT_TEXT_UPDATE_FAILED,
    ALERT_TEXT_UPDATE_SUCCESS,
    ALERT_TITLE_FAILED,
    ALERT_TITLE_SUCCESS,
    ASSET_TYPE,
    CONFIRMATION_ICON_REMOVE,
    CONFIRMATION_TEXT_REMOVE,
    CONFIRMATION_TITLE_REMOVE,
    MAINTENANCE_TYPE,
    STATUS_TYPE,
    USER_MABES,
    siteList
} from "../../../utils/constant/constants";
import {handleClearStateGlobal, handleLoading} from "../../../store/global/global.action";
import {
    selectAssetToEdit,
    selectAssetToRemove,
    selectCurrentItem,
    selectDetailAsset
} from "../../../store/global/global.selector";
import SearchBox from "../../../components/search-box/search-box.component";
import {generalAlert, generalConfirmation} from "../../../utils/notification/notification";
import {selectCurrentUser} from "../../../store/user/user.selector";

const COLUMN = [
    {heading: "Name", value: "name"},
    {heading: "Owner", value: "owner"},
    {heading: "Serial Number", value: "serialNo"},
    {heading: "Date", value: "date"},
    {heading: "Time Left", value: "timeLeft"},
    {heading: "Maintenance Type", value: "type"},
    {heading: "Status", value: "status"},
];

const MaintenanceSchedule = () => {
    const [searchText, setSearchText] = useState("");
    const [searchTextOnChange, setSearchTextOnChange] = useState("");
    const [searchType, setSearchType] = useState("name");
    const [searchOwner, setSearchOwner] = useState(USER_MABES);
    const [page, setPage] = useState("");
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const maintenanceScheduleData = useSelector(selectMaintenanceScheduleData);
    const events = useSelector(selectEvents);
    const assetToDetail = useSelector(selectDetailAsset);
    const assertToRemove = useSelector(selectAssetToRemove);
    const assetToEdit = useSelector(selectAssetToEdit);
    const currentItem = useSelector(selectCurrentItem);
    const removeStatus = useSelector(selectRemoveStatus);
    const createStatus = useSelector(selectCreateStatus);
    const updateStatus = useSelector(selectUpdateStatus);
    const currentUser = useSelector(selectCurrentUser);

    useEffect(() => {
        dispatch(handleLoading(true));
        dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.SCHEDULE, searchText, "name", 0, 10, `schedule`));

        return () => {
            dispatch(handleClearStateGlobal());
            setPage(0);
            dispatch(handleLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (ASSET_TYPE.MAINTENANCE_SCHEDULE === assetToEdit && currentItem) { // EDIT DATA SHCEDULE
            dispatch(handleEditMaintenanceData(MAINTENANCE_TYPE.SCHEDULE, true, currentItem, navigate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToEdit]);

    useEffect(() => {
        if (ASSET_TYPE.MAINTENANCE_SCHEDULE === assetToDetail && currentItem) { // EDIT DATA MAINTENANCE
            dispatch(handleEditMaintenanceData(MAINTENANCE_TYPE.DATA, true, currentItem, navigate, true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToDetail]);

    /**
     * CREATE
     */
    useEffect(() => {
        if (createStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === createStatus;
            const isFailed = STATUS_TYPE.FAILED === createStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_CREATE_SUCCESS : ALERT_TEXT_CREATE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => dispatch(handleClearNotif())
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createStatus]);

    /**
     * UPDATE
     */
    useEffect(() => {
        if (updateStatus) {
            if (STATUS_TYPE.SUCCESS === updateStatus) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_SUCCESS, ALERT_TEXT_UPDATE_SUCCESS, ALERT_ICON_SUCCESS, () => dispatch(handleClearNotif()))
            } else if (STATUS_TYPE.FAILED === updateStatus) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_FAILED, ALERT_TEXT_UPDATE_FAILED, ALERT_ICON_FAILED, () => dispatch(handleClearNotif()))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStatus]);

    /**
     * REMOVE
     */
    useEffect(() => {
        if (ASSET_TYPE.MAINTENANCE_SCHEDULE === assertToRemove && currentItem) {
            generalConfirmation(
                CONFIRMATION_TITLE_REMOVE,
                CONFIRMATION_TEXT_REMOVE(currentItem.name),
                CONFIRMATION_ICON_REMOVE,
                () => {
                    dispatch(handleLoading(true));
                    dispatch(handleRemoveMaintenanceData(MAINTENANCE_TYPE.SCHEDULE, currentItem.id, searchType, page));
                },
                () => dispatch(handleClearStateGlobal()),
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assertToRemove]);

    useEffect(() => {
        if (removeStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === removeStatus;
            const isFailed = STATUS_TYPE.FAILED === removeStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_REMOVE_SUCCESS : ALERT_TEXT_REMOVE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => dispatch(handleClearNotif())
                );
            }
            if (maintenanceScheduleData && maintenanceScheduleData.data && maintenanceScheduleData.data.length) {
                dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.DATA, searchText, searchType, page, 10, `schedule`));
            } else {
                if (page) {
                    dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.DATA, searchText, searchType, page - 1, 10, `schedule`));
                    setPage(prevPage => prevPage - 1);
                } else {
                    dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.DATA, searchText, searchType, 0, 10, `schedule`));
                    setPage(0);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeStatus]);

    useEffect(() => {
        if (searchType === "owner") {
            dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.SCHEDULE, searchOwner, searchType, 0, 10, `schedule`));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchType, searchOwner]);

    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));

        setSearchText(searchTextOnChange);

        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value.toLowerCase();
        const type = searchText === "" ? "name" : searchType;

        dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.SCHEDULE, searchText, type, 0, 10, `schedule`));

        setPage(0);
    }

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        if (searchType === "owner") {
            dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.SCHEDULE, searchOwner, searchType, e.selected, 10, `schedule`));
        } else if (searchType !== "owner" && maintenanceScheduleData && maintenanceScheduleData.data) {
            dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.SCHEDULE, searchText, searchType, e.selected, 10, `schedule`));
        }
        setPage(e.selected);
    };

    const handleCreateSchedule = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));
        dispatch(handleNewMaintenance());
        navigate("/assetdata/maintenance-schedule/asset-schedule");
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    };

    const handleSearchOwner = (e) => {
        setSearchOwner(e.target.value);
        setPage(0);
    };

    const handleBackgroundColorEvent = (status) => {
        switch (status) {
            case `Waiting`:
                return "";
            case `Done`:
                return "#48b527";
            case `Skipped`:
                return "#d91a1a";
            default:
                return ""
        }
    }

    const formatSchedule = () => {
        return events?.flatMap(obj => obj.date.map((date, i) => {
            const { scheduleId = null, title, dueDateStatus } = obj;
            return {
                scheduleId: scheduleId[i] ? scheduleId[i] : null,
                title,
                date,
                dueDateStatus: dueDateStatus[i],
                backgroundColor: handleBackgroundColorEvent(dueDateStatus[i])
            };
        })) || [];
    };

    return (
        <div className="bg-tertiary min-h-screen py-4">
            <HeaderPage title="Maintenance Schedule" />

            <div className="pb-20 w-[92%] mx-auto mt-24">
                <form className="flex mb-6" onSubmit={handleSearch}>
                    <select
                        name="filter"
                        className="select select-bordered w-[200px] rounded-3xl h-[30px]"
                        onChange={(e) => setSearchType(e.target.value)}
                    >
                        <option selected disabled>Select filter</option>
                        <option value="name">Filter by Name</option>
                        {currentUser?.siteName === USER_MABES && (
                            <option value="owner">Filter by Owner</option>
                        )}
                        <option value="status">Filter by Status</option>
                    </select>
                    {searchType !== "owner" ? (
                        <SearchBox 
                            name="searchText"
                            value={searchTextOnChange}
                            placeholder="Search Schedule"
                            className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                            onChangeHandler={onSearchChange}
                        />
                    ) : (
                        <select
                            name="filter"
                            className="select min-w-[300px] mx-3 rounded-full px-5"
                            onChange={handleSearchOwner}
                        >
                            {siteList && siteList.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                    <span className="flex justify-center items-center text-white text-lg px-5 mr-auto">
                        <button
                            type="submit"
                            className="btn btn-primary rounded-full text-white text-xs px-7"
                        >
                            Search
                        </button>
                    </span>
                    <span
                        className="flex justify-center items-center text-white gap-x-2 text-lg cursor-pointer"
                        onClick={handleCreateSchedule}
                    >
                        <button className="cursor-pointer">
                            <PlusIcon/>
                        </button>
                        Add Schedule
                    </span>
                </form>

                {maintenanceScheduleData && maintenanceScheduleData.data && maintenanceScheduleData.data.length > 0 ? (
                    <Table
                        data={maintenanceScheduleData.data}
                        column={COLUMN}
                        assetType={ASSET_TYPE.MAINTENANCE_SCHEDULE}
                    />
                ) : (
                    <p className="flex flex-col text-white text-xl text-center pt-10">
                        Data is empty
                    </p>
                )}

                <div className="text-white flex flex-col">
                    <div className="flex justify-center  my-6 self-end h-14 items-center">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={maintenanceScheduleData && maintenanceScheduleData.totalPage && maintenanceScheduleData.totalPage}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            forcePage={page}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>

                <div className="bg-white rounded-lg p-6 mt-10">
                    <Calendar events={formatSchedule()}/>
                </div>
            </div>
        </div>
    );
};

export default MaintenanceSchedule;
