import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { getNewPositionImage, report } from "../../../utils/report/report";
import {
    DEFAULT_IMAGE_POSITION_X, 
    DEFAULT_LINE_X1, 
    DEFAULT_LINE_X2, 
    DEFAULT_TITLE_POSITION, 
    SPACE } 
from "../../../utils/constant/constants";

import LambangTNI from "../../../assets/png/Lambang_TNI_AU.png";

const ReportRadio = ({ selectedData, selectedImage, btnCreateReport }) => {
    const [profile, setProfile] = useState([]);

    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem("profile"));
        setProfile(userProfile);
    }, []);

    const handlePdfGenerate = () => {
        // Format
        const doc = new jsPDF("potrait", "px", "a4", "false");

        // Template
        report.templateAsset(doc, 1, profile, LambangTNI, "Radio Asset");

        autoTable(doc, {
            theme: "grid",
            styles: {
                textColor: [0, 0, 0],
                fontSize: 8,
                overflow: "linebreak",
                cellWidth: 193,
            },
            margin: { left: 30 },
            startY: 150,
            didParseCell(data) {
                if (data.row.index % 2 === 0) {
                    data.cell.styles.textColor = [0, 0, 0];
                    data.cell.styles.fillColor = "#D3D3D3";
                }
                if (data.column.index === 1) {
                    data.cell.styles.fontStyle = "bold";
                }
            },
            body: [
                ["Name", selectedData?.name],
                ["Category", selectedData?.category?.name],
                ["Brand Name", selectedData?.brand?.name],
                ["Type", selectedData?.type?.name],
                ["Serial Number", selectedData?.serialNo],
                ["Part Number", selectedData?.partNo],
                ["Status", selectedData?.status],
                ["Frequency", `${selectedData?.freq} MHz`],
                ["Range", `${selectedData?.freq} To ${selectedData?.freqMax} MHz`],
                ["Transmit Power", `${selectedData?.power} W`],
                ["Bandwidth", `${selectedData?.bandwith}`],
                ["Radio Owner", selectedData?.site?.siteName],
            ],
        });

        if (selectedImage.length > 0) {
            // Add new page
            report.addPage(doc);

            // Template Appendix
            report.templateAppendix(doc, 2, profile, LambangTNI);
        }

        // Asset Image
        let titlePosition = [DEFAULT_TITLE_POSITION];
        let imagePositionX = [DEFAULT_IMAGE_POSITION_X];
        let lineX1 = [DEFAULT_LINE_X1];
        let lineX2 = [DEFAULT_LINE_X2];

        if (selectedImage.length > 1) {
            for (let i = 1; i < selectedImage.length; i++) {
                titlePosition.push(titlePosition[i - 1] + SPACE);
                imagePositionX.push(imagePositionX[i - 1] + SPACE);
                lineX1.push(lineX1[i - 1] + SPACE);
                lineX2.push(lineX2[i - 1] + SPACE);
            }
        }

        const sliceTitlePosition = titlePosition.slice(0, 3);
        const sliceImagePositionX = imagePositionX.slice(0, 3);
        const sliceLineX1 = lineX1.slice(0, 3);
        const sliceLineX2 = lineX2.slice(0, 3);

        const newTitlePosition = getNewPositionImage(sliceTitlePosition, 2);
        const newImagePositionX = getNewPositionImage(sliceImagePositionX, 2);
        const newLineX1 = getNewPositionImage(sliceLineX1, 2);
        const newLineX2 = getNewPositionImage(sliceLineX2, 2);

        let images = selectedImage.map((image, i) => ({
            imageUrl: image,
            titlePosition: newTitlePosition[i],
            imagePositionX: newImagePositionX[i],
            lineX1: newLineX1[i],
            lineX2: newLineX2[i],
        }));

        images.map((image, index) => {
            return report.assetImg(
                doc,
                index,
                image.imageUrl,
                image.titlePosition,
                image.imagePositionX,
                image.lineX1,
                image.lineX2
            );
        });

        report.savePdf(doc, `Radio ${selectedData.name}`);
    };

    return (
        <button
            type="button"
            className="btn btn-primary text-white min-w-[100px] ml-4"
            onClick={handlePdfGenerate}
        >
            {btnCreateReport}
        </button>
    );
};

export default ReportRadio;
