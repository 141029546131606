import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

import {
    handleCheckBoxAsset,
    handleEditAsset,
    handleRemoveAsset,
    handleShowDetailAsset,
} from "../../store/global/global.action";
import {selectCurrentUser} from "../../store/user/user.selector";

import {ASSET_TYPE, MAX_REQUEST_SIZE, USER_MABES} from "../../utils/constant/constants";

import EditIcon from "../../assets/svgs/EditIcon";
import DeleteIcon from "../../assets/svgs/DeleteIcon";
import DetailIcon from "../../assets/svgs/CopyIcon";
import {EditTask} from "../../assets/svgs";
import MaintenanceIcon from "../../assets/svgs/MaintenanceIcon";
import {generalAlert} from "../../utils/notification/notification";
import {MESH} from "../../store/admin/admin.types";

const Table = ({
                   data,
                   column,
                   assetType,
                   analyzeType,
                   assetDetail,
                   handleSelectRow,
                   isTaskEditable,
                   createReport,
                   action = true,
               }) => {
    const [tableData, setTableData] = useState([]);
    const offset = 0;
    const perPage = MAX_REQUEST_SIZE;

    const dispatch = useDispatch();
    const location = useLocation();

    const currentUser = useSelector(selectCurrentUser);

    useEffect(() => {
        let slice;
        if (ASSET_TYPE.OP_DETAIL === assetType) {
            slice = data.slice(offset, offset + 100);
        } else if (
            ASSET_TYPE.MAINTENANCE_DATA === assetType ||
            ASSET_TYPE.MAINTENANCE_SCHEDULE === assetType ||
            ASSET_TYPE.MAINTENANCE_HISTORY === assetType
        ) {
            slice = data.slice(offset, offset + 50);
        } else {
            slice = data.slice(offset, offset + perPage);
        }

        setTableData(slice);

        return () => {
            setTableData([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div className="flex flex-col select-none">
            <div className="overflow-hidden rounded-lg">
                <table className="border-collapse border min-w-full py-[5px] px-[10px]">
                    <thead>
                    <tr>
                        <th className="py-3 px-3 text-left bg-[#F8F8F8] w-[50px]">
                            No
                        </th>
                        {column.map((item, index) => (
                            <TableHeadItem index={index} item={item} location={location}/>
                        ))}
                        {action && (
                            <th className="py-3 px-3 text-left bg-[#F8F8F8] w-[120px]">
                                Action
                            </th>
                        )}
                    </tr>
                    </thead>
                    <tbody className="bg-white">
                    {tableData.map((item, index) => {
                        let number = index + 1;
                        return (
                            <TableRow
                                number={number}
                                item={item}
                                column={column}
                                dispatch={dispatch}
                                assetType={assetType}
                                analyzeType={analyzeType}
                                assetDetail={assetDetail}
                                currentUser={currentUser}
                                handleSelectRow={handleSelectRow}
                                action={action}
                                isTaskEditable={isTaskEditable}
                                createReport={createReport}
                            />
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const capitalizeFirstLowercaseRest = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const TableHeadItem = ({item, index, location}) => (
    <th
        key={index}
        className={`py-3 px-4 text-left bg-white ${
            location.pathname.indexOf("/assetdata/fo") > -1 && index === 1
                ? "w-[300px]"
                : location.pathname.indexOf("/assetdata/fo") > -1 && index === 3
                    ? "w-[240px]"
                    : "w-fit"
        }`}
    >
        {item.heading}
    </th>
);

const TableRow = ({
                      item,
                      column,
                      number,
                      dispatch,
                      assetType,
                      analyzeType,
                      assetDetail,
                      currentUser,
                      handleSelectRow,
                      isTaskEditable,
                      createReport,
                      action,
                  }) => {
    return (
        <tr
            key={item.id}
            className="bg-white even:bg-[#F8F8F8] hover:bg-[#ddd] focus:bg-[#ddd]"
            onClick={() => handleSelectRow && handleSelectRow(item.id)}
            tabIndex="0"
        >
            <td className="py-5 px-4 text-left">{number}</td>
            {column.map((columnItem, index) => {
                if (columnItem.value.includes(".")) {
                    const itemSplit = columnItem.value.split(".");
                    return (
                        <td key={index} className="py-3 px-4 text-left">
                            {item[itemSplit[0]][itemSplit[1]] ?? ""}
                        </td>
                    );
                }

                if (
                    columnItem.value === "color"
                ) {
                    return (
                        <td key={index} className="py-3 px-4 text-left">
                            <div
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title={item[`${columnItem.value}`]}
                                style={{backgroundColor: `${item[columnItem.value].toLowerCase()}`}}
                                className={`w-7 h-7 rounded-full`}
                            />
                        </td>
                    );
                }

                if (
                    columnItem.value === "status" ||
                    columnItem.value === "mainStatus" ||
                    columnItem.value === "condition"
                ) {
                    return (
                        <td key={index} className="py-3 px-4 text-left">
                            <span className={`row-${item[columnItem.value].toLowerCase()}`}>
                                {capitalizeFirstLowercaseRest(item[`${columnItem.value}`]) ??
                                    ""}
                            </span>
                        </td>
                    );
                }


                return (
                    <td key={index} className="py-3 px-4 text-left">
                        {item[`${columnItem.value}`] ?? ""}
                    </td>
                );
            })}
            {action && (
                <td className="py-6 flex space-x-4">
                    {
                        ASSET_TYPE.MAINTENANCE_HISTORY !== assetType &&
                        ASSET_TYPE.MAINTENANCE_SCHEDULE !== assetType &&
                        ASSET_TYPE.SIMULATION_RESULT !== assetType &&
                        ASSET_TYPE.USER_MANAGER !== assetType && (
                            <button
                                type="button"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title={ASSET_TYPE.TEMPLATE === assetType ? "Update Name" : "Update"}
                                onClick={() => {
                                    if (assetType === "Fiber Optic" && currentUser.siteName !== item.siteName) return generalAlert("Failed", "Unable to Update Routes from Another Site", "error");

                                    if (assetType === "Operation Planning" && currentUser.siteName !== item.siteDto?.siteName) return generalAlert("Failed", "Unable to Update Operation Data from Another Site", "error");

                                    if (assetType === "Maintenance Data" && currentUser.siteName !== item.owner) return generalAlert("Failed", "Unable to Update Maintenance Data from Another Site", "error");

                                    if (assetType === "Maintenance Schedule" && currentUser.siteName !== item.owner) return generalAlert("Failed", "Unable to Update Maintenance Schedule from Another Site", "error");

                                    if (assetType === "Template" && currentUser.siteName === USER_MABES && !item.editable) return generalAlert("Failed", "Unable to Update Template from Another Site", "error");

                                    if (assetType === "Frequency Allocation" && currentUser.siteName === USER_MABES && !item.editable) return generalAlert("Failed", "Unable to Update Frequency Allocation from Another Site", "error");

                                    if (item.site && currentUser.siteName !== item.site.siteName) return generalAlert("Failed", "Unable to Update Assets from Another Site", "error");

                                    dispatch(handleEditAsset(assetType, item));
                                    if (assetDetail) {
                                        assetDetail(item.id);
                                    }
                                }}
                            >
                                <EditIcon width={18} height={16}/>
                            </button>
                        )
                    }
                    {
                        ASSET_TYPE.MAINTENANCE_HISTORY !== assetType && (
                            <button
                                type="button"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="Delete"
                                onClick={() => {
                                    if (assetType === "Fiber Optic"  && currentUser.siteName !== item.siteName) return generalAlert("Failed", "Unable to Remove Routes from Another Site", "error");

                                    if (assetType === "Operation Planning" && currentUser.siteName !== item.siteDto?.siteName) return generalAlert("Failed", "Unable to Remove Operation Data from Another Site", "error");

                                    if (assetType === "Maintenance Data" && currentUser.siteName !== item.owner) return generalAlert("Failed", "Unable to Remove Maintenance Data from Another Site", "error");

                                    if (assetType === "Maintenance Schedule" && currentUser.siteName !== item.owner) return generalAlert("Failed", "Unable to Remove Maintenance Schedule from Another Site", "error");

                                    if (assetType === "Template" && currentUser.siteName === USER_MABES && !item.editable) return generalAlert("Failed", "Unable to Remove Template from Another Site", "error");

                                    if (assetType === "Frequency Allocation" && currentUser.siteName === USER_MABES && !item.editable) return generalAlert("Failed", "Unable to Remove Frequency Allocation from Another Site", "error");

                                    if (item.site && currentUser.siteName !== item.site.siteName) return generalAlert("Failed", "Unable to Remove Assets from Another Site", "error");

                                    if (assetType === "Simulation Result" && currentUser.siteName === USER_MABES && currentUser.siteName !== item.siteName && analyzeType !== MESH) return generalAlert("Failed", "Unable to Remove Simulation from Another Site", "error");

                                    dispatch(handleRemoveAsset(assetType, item))
                                }}
                            >
                                <DeleteIcon width={17} height={16}/>
                            </button>
                        )
                    }
                    {
                        ASSET_TYPE.TEMPLATE_TASK !== assetType &&
                        ASSET_TYPE.MAINTENANCE_DATA !== assetType &&
                        ASSET_TYPE.MAINTENANCE_SCHEDULE !== assetType &&
                        ASSET_TYPE.FIBER_OPTIC !== assetType &&
                        ASSET_TYPE.FREQUENCY_ALLOCATION !== assetType &&
                        ASSET_TYPE.SIMULATION_RESULT !== assetType &&
                        ASSET_TYPE.ADMIN_CATEGORY !== assetType &&
                        ASSET_TYPE.ADMIN_BRAND !== assetType &&
                        ASSET_TYPE.ADMIN_TYPE !== assetType && (
                            <button
                                type="button"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title={ASSET_TYPE.TEMPLATE === assetType ? "Detail Task" : "Detail"}
                                onClick={() => {
                                    dispatch(handleShowDetailAsset(assetType, item));
                                    if (assetType === "Operation Planning") createReport(item.siteDto?.siteName);
                                    if (assetType === "Template" && currentUser.siteName === USER_MABES) {
                                        isTaskEditable(item.editable);
                                    }
                                    if (assetDetail) {
                                        assetDetail(item.id);
                                    }
                                }}
                            >
                                {ASSET_TYPE.TEMPLATE === assetType ? (
                                    <EditTask width={20} height={20}/>
                                ) : (
                                    <DetailIcon width={13} height={14}/>
                                )}
                            </button>
                        )
                    }
                    {
                        ASSET_TYPE.MAINTENANCE_SCHEDULE === assetType && (
                            <button
                                type="button"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="Maintenance"
                                onClick={() => {
                                    if (assetType === "Maintenance Schedule" && currentUser.siteName !== item.owner) return generalAlert("Failed", "Unable to Maintain Schedule from Another Site", "error");

                                    dispatch(handleShowDetailAsset(assetType, item))
                                }}
                            >
                                <MaintenanceIcon width={18} height={16}/>
                            </button>
                        )
                    }
                    {
                        ASSET_TYPE.FIBER_OPTIC === assetType && (
                            <input
                                // key={item.id}
                                type="checkbox"
                                className="checkbox checkbox-primary checkbox-sm"
                                checked={item.isChecked}
                                onClick={() => dispatch(handleCheckBoxAsset(assetType, item))}
                            />
                        )
                    }
                </td>
            )}
        </tr>
    );
};

export default Table;
