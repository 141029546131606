import {USER_ACTION_TYPES} from "./user.types";
import {EMPTY_STRING} from "../admin/admin.types";

const INITIAL_STATE = {
    currentUser: null,
    isLoading: false,
    error: {
        isError: false,
        errorMessage: ""
    },
    authed: false,
    createdUser: "IDLE",
    updatedUser: EMPTY_STRING,
    allUserData: null,
    removeUserStatus: EMPTY_STRING,
    aUserData: null,
};

export const userReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case USER_ACTION_TYPES.REMOVE_ERROR:
            return {...state, error: {isError: false, errorMessage: ""}};
        case USER_ACTION_TYPES.SIGN_OUT_FAILED:
        case USER_ACTION_TYPES.SIGN_IN_FAILED:
            return {...state, error: payload, authed: false, isLoading: false};
        case USER_ACTION_TYPES.SIGN_IN_SUCCESS:
            return {...state, currentUser: payload, authed: true, error: false, isLoading: false}
        case USER_ACTION_TYPES.SIGN_OUT_SUCCESS:
            return {...state, currentUser: null};
        case USER_ACTION_TYPES.SIGN_IN_START:
            return {...state, isLoading: true};
        case USER_ACTION_TYPES.CREATE_USER:
            return {...state, createdUser: payload};
        case USER_ACTION_TYPES.RESET_CREATE_USER_DATA:
            return {...state, createdUser: "IDLE"};
        case USER_ACTION_TYPES.GET_ALL_USER:
            return {...state, allUserData: payload};
        case USER_ACTION_TYPES.REMOVE_USER_STATUS:
            return {...state, removeUserStatus: payload};
        case USER_ACTION_TYPES.GET_A_USER:
            return {...state, aUserData: payload};
        case USER_ACTION_TYPES.CLEAR_A_USER_DATA:
            return {...state, aUserData: null};
        case USER_ACTION_TYPES.CLEAR_ALL_USER_DATA:
            return {...state, allUserData: null};
        case USER_ACTION_TYPES.CLEAR_UPDATE_USER_STATUS:
            return {...state, updatedUser: INITIAL_STATE.updatedUser};
        case USER_ACTION_TYPES.RESET_REDUCER:
            return INITIAL_STATE;
        case USER_ACTION_TYPES.UPDATE_USER:
            return {...state, updatedUser: payload};
        case USER_ACTION_TYPES.CHANGED_NAME:
            return {...state, currentUser: payload};
        case USER_ACTION_TYPES.CHECK_USER_SESSION:
            if (payload.active) {
                return {...state, currentUser: payload.profile, authed: payload.active, error: false, isLoading: false};
            } else {
                return INITIAL_STATE;
            }
        default:
            return state;
    }
}