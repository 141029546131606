import {ACTION_DELETE, ACTION_GET, BASE_URL} from "./core";

export const GET_AREA_ARCHIEVE = (param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/archive`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const FILTER_ARCHIVE = (areaType, analyzeType, page, q) => {
    const url = `${BASE_URL}/archive?page=${page}&size=10&ct=${areaType}&at=${analyzeType}&name=${q}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};


export const DELETE_ARCHIVE = (id) => {
    const url = `${BASE_URL}/archive/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};