import React from "react";

const ReceiverIcon = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.58579 12.4142C8.80474 11.6332 8.80474 10.3668 9.58579 9.58579C10.3668 8.80474 11.6332 8.80474 12.4142 9.58579C13.1953 10.3668 13.1953 11.6332 12.4142 12.4142M6.75736 15.2426C4.41421 12.8995 4.41421 9.1005 6.75736 6.75736C9.1005 4.41421 12.8995 4.41421 15.2426 6.75736C17.5858 9.1005 17.5858 12.8995 15.2426 15.2426M3.92893 18.0711C0.0236895 14.1658 0.023689 7.83418 3.92893 3.92893C7.83418 0.0236893 14.1658 0.0236893 18.0711 3.92893C21.9763 7.83418 21.9763 14.1658 18.0711 18.0711"
        stroke="#BFBDBA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReceiverIcon;
