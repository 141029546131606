import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_SERVER_URL;

export const SERVER_MABES_KEY = process.env.REACT_APP_BASE_SERVER_MABES_KEY;

axios.interceptors.request.use(
    (config) => {

        const serverUrl = config.url.includes("server");
        // console.log(`server url includes server = ${serverUrl}`)
        // console.log(`server url = ${config.url}`)
        // console.log(`SERVER_MABES_KEY = ${SERVER_MABES_KEY}`)
        // console.log(`localStorage.getItem("accesstoken_nccsa") = ${localStorage.getItem("accesstoken_nccsa")}`)

        const token = localStorage.getItem("accesstoken_nccsa");

        if (serverUrl) {
            config.headers['authorization'] = `ApiKey ${SERVER_MABES_KEY}`;
        } else {
            if (localStorage.getItem("accesstoken_nccsa")) {
                config.headers['authorization'] = `Bearer ${token}`;
            }
        }
        config.headers['content-type'] = `application/json`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/** --------------------------------------------------------------------------------- */
/** ================================= AXIOS ACTIONS ================================= */
/** --------------------------------------------------------------------------------- */
let configFile = {
    headers: {
        "Content-type": "multipart/form-data",
    },
};

export const ACTION_GET = (url, params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {params})
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    localStorage.removeItem("profile");
                    localStorage.removeItem("accesstoken_nccsa");
                    window.location.reload();
                } else if (err.response && err.response.data &&
                    err.response.data.message && err.response.data.message === "Invalid Token") {
                    window.location.reload();
                }
                reject({...err.response.data, status: err.response.status});
            });
    });
};

export const ACTION_POST = (url, body) => {
    return new Promise((resolve, reject) => {
        axios
            .post(url, body)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject({...err.response.data, status: err.response.status});
            });
    });
};

export const ACTION_POST_NO_BODY = (url) => {
    return new Promise((resolve, reject) => {
        axios
            .post(url)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject({...err.response.data, status: err.response.status});
            });
    });
};

export const ACTION_POST_FILE = (url, body) => {
    return new Promise((resolve, reject) => {
        axios
            .post(url, body, configFile)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject({...err.response.data, status: err.response.status});
            });
    });
};


export const ACTION_PATCH = (url, param) => {
    return new Promise((resolve, reject) => {
        axios
            .patch(url, param)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject({...err.response.data, status: err.response.status});
            });
    });
};

export const ACTION_PUT = (url, body) => {
    return new Promise((resolve, reject) => {
        axios
            .put(url, body)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject({...err.response.data, status: err.response.status});
            });
    });
};

export const ACTION_DELETE = (url) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(url)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject({...err.response.data, status: err.response.status});
            });
    });
};
export const ACTION_DELETE_WITH_BODY = (url, body) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(url, {data: body})
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject({...err.response.data, status: err.response.status});
            });
    });
};

