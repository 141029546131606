import React from "react";
import {useNavigate} from "react-router-dom";
import { useForm } from "react-hook-form";

import HeaderPage from "../../../components/header/HeaderPage";
import {selectNameSelectedAssetData} from "../../../store/maintenance/maintenance.selector";
import {useDispatch, useSelector} from "react-redux";
import {handleSelectMaintenanceType} from "../../../store/maintenance/maintenance.action";

const TypeMaintenance = () => {
    const {
        register,
        formState: {errors},
        handleSubmit,
        watch,
    } = useForm(({
        defaultValues: {
            type: "corrective",
            preventive: "",
            corrective: "",
        }
    }));

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const nameSelectedAssetData = useSelector(selectNameSelectedAssetData);

    const onSubmit = (data) => {
        if (data.type !== "" && (data.corrective !== "" || data.preventive !== "")) {
            const typeValue = data.preventive ? data.preventive : data.corrective;
            dispatch(handleSelectMaintenanceType(data.type, typeValue));
            navigate("/assetdata/maintenance-data/create-maintenance");
        }
    }

    const renderType = (type) => {
        if (type === "preventive") {
            return (
                <div className="flex flex-col mt-5">
                    <label htmlFor="preventive" className="text-white font-bold px-3 mb-2">
                        Preventive
                    </label>
                    <select
                        name="preventive"
                        id="preventive"
                        {...register("preventive", {
                            required: "Required!",
                        })}
                        className={`select select-primary w-full font-normal ${errors?.preventive ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                    >
                        <option value="" disabled selected>-- Select Type --</option>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="semester">Semester</option>
                        <option value="yearly">Yearly</option>
                    </select>
                </div>
            )
        } else if (type === "corrective") {
            return (
                <div className="flex flex-col mt-5">
                    <label htmlFor="corrective" className="text-white font-bold px-3 mb-2">
                        Corrective
                    </label>
                    <select
                        name="corrective"
                        id="corrective"
                        {...register("corrective", {
                            required: "Required!",
                        })}
                        className={`select select-primary w-full font-normal ${errors?.corrective ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                    >
                        <option value="" disabled selected>-- Select Type --</option>
                        <option value="light">Light Level</option>
                        <option value="medium">Medium Level</option>
                        <option value="heavy">Heavy Level</option>
                    </select>
                </div>
            )
        }
    }

    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="Maintenance Type" />

            <div className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col">
                            <label htmlFor="selectedAsset" className="text-white font-bold px-3 mb-2">
                                Selected Asset
                            </label>
                            <input
                                name="selectedAsset"
                                id="selectedAsset"
                                type="text"
                                value={nameSelectedAssetData && nameSelectedAssetData}
                                disabled
                                className="input min-w-[300px] rounded-lg p-5 focus:outline-white focus:outline-1 focus:border-primary"
                            />
                        </div>

                        <div className="flex flex-col mt-5">
                            <label htmlFor="type" className="text-white font-bold px-3 mb-2">
                                Maintenance Type
                            </label>
                            <select
                                name="type"
                                id="type"
                                {...register("type", {
                                    required: "Required!",
                                })}
                                disabled
                                className={`select select-primary w-full font-normal ${errors?.type ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                            >
                                <option value="corrective" selected>Corrective</option>
                            </select>
                        </div>

                        <div>{renderType(watch("type"))}</div>

                        <div className="flex justify-end gap-x-4 mt-10">
                            <button
                                onClick={() => navigate(-1)}
                                className="btn btn-outline text-white"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                // onClick={handleSubmit}
                                className="btn btn-primary text-white"
                            >
                                Apply
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TypeMaintenance;