import * as React from "react";

const AntennaIcon = (props) => (
  <svg
    width={22}
    height={21}
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9498 4.05022C15.5593 3.65969 14.9261 3.65969 14.5356 4.05022C14.1451 4.44074 14.1451 5.07391 14.5356 5.46443L15.9498 4.05022ZM14.5356 12.5355C14.1451 12.926 14.1451 13.5592 14.5356 13.9497C14.9261 14.3402 15.5593 14.3402 15.9498 13.9497L14.5356 12.5355ZM6.05026 13.9497C6.44078 14.3402 7.07395 14.3402 7.46447 13.9497C7.855 13.5592 7.855 12.926 7.46447 12.5355L6.05026 13.9497ZM7.46447 5.46443C7.855 5.07391 7.855 4.44074 7.46447 4.05022C7.07395 3.65969 6.44078 3.65969 6.05026 4.05022L7.46447 5.46443ZM3.22161 16.7782C3.61214 17.1687 4.2453 17.1687 4.63583 16.7782C5.02635 16.3877 5.02635 15.7545 4.63583 15.364L3.22161 16.7782ZM4.63583 2.63606C5.02635 2.24554 5.02635 1.61237 4.63583 1.22185C4.2453 0.831324 3.61214 0.831324 3.22161 1.22185L4.63583 2.63606ZM18.7784 1.22185C18.3879 0.831324 17.7547 0.831324 17.3642 1.22185C16.9737 1.61237 16.9737 2.24554 17.3642 2.63606L18.7784 1.22185ZM17.3642 15.364C16.9737 15.7545 16.9737 16.3877 17.3642 16.7782C17.7547 17.1687 18.3879 17.1687 18.7784 16.7782L17.3642 15.364ZM10 20C10 20.5523 10.4477 21 11 21C11.5523 21 12 20.5523 12 20H10ZM14.5356 5.46443C16.4882 7.41705 16.4882 10.5829 14.5356 12.5355L15.9498 13.9497C18.6835 11.216 18.6835 6.78389 15.9498 4.05022L14.5356 5.46443ZM7.46447 12.5355C5.51185 10.5829 5.51185 7.41705 7.46447 5.46443L6.05026 4.05022C3.31659 6.78389 3.31659 11.216 6.05026 13.9497L7.46447 12.5355ZM4.63583 15.364C1.12111 11.8493 1.12111 6.15078 4.63583 2.63606L3.22161 1.22185C-1.07416 5.51762 -1.07416 12.4824 3.22161 16.7782L4.63583 15.364ZM17.3642 2.63606C20.8789 6.15078 20.8789 11.8493 17.3642 15.364L18.7784 16.7782C23.0742 12.4824 23.0742 5.51762 18.7784 1.22185L17.3642 2.63606ZM12 9C12 9.55228 11.5523 10 11 10V12C12.6569 12 14 10.6569 14 9H12ZM11 10C10.4477 10 10 9.55228 10 9H8.00001C8.00001 10.6569 9.34315 12 11 12V10ZM10 9C10 8.44772 10.4477 8 11 8V6C9.34315 6 8.00001 7.34315 8.00001 9H10ZM11 8C11.5523 8 12 8.44772 12 9H14C14 7.34315 12.6569 6 11 6V8ZM12 20V11H10V20H12Z"
      fill="white"
    />
  </svg>
);

export default AntennaIcon;
