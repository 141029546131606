import {MANNET_ACTION_TYPES, NOTIFY_ACTION_TYPES} from "./mannet.types";
import {addIntoArray, getChartData} from "./mannet.utility";
import {STATUS_TYPE} from "../../utils/constant/constants";

const INITIAL_STATE = {
    billboardData: [], // [{latitude, longitude, height, id}]
    verticalLineData: [], // [{latitude, longitude, height, id}]
    horizontalLineData: [], // [{rxLat, rxLon, rxAlt, txLat, txLon, txAlt, id}]
    dbmLabelData: [], // [{}]
    notifyRightPannel: NOTIFY_ACTION_TYPES.NS,
    failedMessage: null,
    responsePoints: null, // {}
    chartData: {nodes: 0, mean: 0, persentageA: 0, persentageB: 0, persentageC: 0},
    selectEntityListener: null,
    selectedEntityId: null,
    originalPayloadArray: [],
    vLineDataSource: [],
    hLineDataSources: [],
    mannetNetworks: null,
    mannets: null,
    drawingProgressStatusReport: STATUS_TYPE.NO_STATEMENT,
    totalProgress: 0,
    progressCount: 0,
    isLoading: false,
    terrainHeight: [],
}


export const mannetReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case MANNET_ACTION_TYPES.TERRAIN_HEIGHT:
            return {...state, terrainHeight: addIntoArray(state.terrainHeight, payload)};
        case MANNET_ACTION_TYPES.PROGRESS_COUNT:
            return {...state, progressCount: state.progressCount + 1};
        case MANNET_ACTION_TYPES.IS_LOADING:
            let progressCount = payload ? state.progressCount : 0;
            return {...state, isLoading: payload, progressCount: progressCount};
        case MANNET_ACTION_TYPES.TOTAL_PROGRESS:
            return {...state, totalProgress: payload};
        case MANNET_ACTION_TYPES.DRAW_PROGRESS_STATUS_REPORT:
            return {...state, drawingProgressStatusReport: payload};
        case MANNET_ACTION_TYPES.MODIFY_BILLBOARD_AND_VLINE_DATA:
            const {billboardData, verticalLineData} = payload;
            return {...state, billboardData, verticalLineData};
        case MANNET_ACTION_TYPES.CLEAR_STORAGE:
            return INITIAL_STATE;
        case MANNET_ACTION_TYPES.REGISTER_VERTICAL_LINE_DATESOURCES:
            let copyVLineDataSources = [];
            if (state.vLineDataSource && state.vLineDataSource.length) {
                copyVLineDataSources = [...state.vLineDataSource];
            }
            copyVLineDataSources.push(payload);
            return {...state, vLineDataSource: copyVLineDataSources};
        case MANNET_ACTION_TYPES.REGISTER_BILLBOARD:
            let copyBillboardData;
            if (state.billboardData && state.billboardData.length) {
                copyBillboardData = [...state.billboardData];
                copyBillboardData.push(payload);
            } else {
                copyBillboardData = []
                copyBillboardData.push(payload);
            }
            return {...state, billboardData: copyBillboardData};
        case MANNET_ACTION_TYPES.REGISTER_VERTICAL_LINE:
            let copyVerticalLineData = [];
            if (state.verticalLineData && state.verticalLineData.length) {
                copyVerticalLineData = [...state.verticalLineData];
            }
            copyVerticalLineData.push(payload);
            return {...state, verticalLineData: copyVerticalLineData};
        case MANNET_ACTION_TYPES.NOTIFY_RIGHT_PANNEL:
            return {...state, notifyRightPannel: NOTIFY_ACTION_TYPES.CREATE_POINT};
        case MANNET_ACTION_TYPES.REGISTER_HANDLER_LISTENER:
            return {...state, selectEntityListener: payload};
        case MANNET_ACTION_TYPES.SELECTED_ENTITY:
            return {...state, selectedEntityId: payload};
        case MANNET_ACTION_TYPES.FAILED_MESSAGE:
            return {...state, failedMessage: payload};
        case MANNET_ACTION_TYPES.MODIFY_ORIGINAL_PAYLOAD:
            return {...state, originalPayloadArray: payload};
        case MANNET_ACTION_TYPES.REGISTER_HORIZONTAL_LINE_DATASOURCES:
            let copyHLineDataSources = [];
            if (state.hLineDataSources && state.hLineDataSources.length) {
                copyHLineDataSources = [...state.hLineDataSources];
            }
            copyHLineDataSources.push(payload);
            return {...state, hLineDataSources: copyHLineDataSources};
        case MANNET_ACTION_TYPES.COPY_ORIGINAL_PAYLOAD:
            const copyArr = addIntoArray(state.originalPayloadArray, payload);
            return {...state, originalPayloadArray: copyArr};
        case MANNET_ACTION_TYPES.RESPONSE_POINT:
            const chartData = getChartData(payload, state.chartData);
            return {...state, responsePoints: payload, chartData};
        case MANNET_ACTION_TYPES.CLEAR_NOTIFY:
            return {...state, notifyRightPannel: NOTIFY_ACTION_TYPES.NS};
        case MANNET_ACTION_TYPES.MODIFY_BILLBOARD_DATA:
            return {...state, billboardData: payload};
        case MANNET_ACTION_TYPES.CLEAR_BILLBOARD:
            return {...state, billboardData: null};
        case MANNET_ACTION_TYPES.CLEAR_ERROR_MSESAGE:
            return {...state, failedMessage: null};
        case MANNET_ACTION_TYPES.REGISTER_DBM_LABEL:
            let copyDbmLabelData;
            if (state.dbmLabelData && state.dbmLabelData.length) {
                copyDbmLabelData = [...state.dbmLabelData];
                copyDbmLabelData.push(payload);
            } else {
                copyDbmLabelData = []
                copyDbmLabelData.push(payload);
            }
            copyDbmLabelData.push(payload);
            return {...state, dbmLabelData: copyDbmLabelData};
        case MANNET_ACTION_TYPES.REGISTER_HORIZONTAL_LINE:
            let copyHorizontalLineData;
            if (state.horizontalLineData && state.horizontalLineData.length) {
                copyHorizontalLineData = [...state.horizontalLineData];
                copyHorizontalLineData.push(payload);
            } else {
                copyHorizontalLineData = []
                copyHorizontalLineData.push(payload);
            }
            copyHorizontalLineData.push(payload);
            return {...state, horizontalLineData: copyHorizontalLineData};
        case MANNET_ACTION_TYPES.FETCH_MANNET_NETWORKS:
            return {...state, mannetNetworks: payload};
        case MANNET_ACTION_TYPES.FETCH_MANNET:
            return {...state, mannets: payload};
        default:
            return state;
    }
}