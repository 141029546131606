import React from "react";
import ReactPaginate from "react-paginate";

import Table from "../../components/table/Table";
import SearchBox from "../../components/search-box/search-box.component";

import {
    ALERT_ICON_FAILED,
    ALERT_TITLE_FAILED,
    ASSET_TYPE,
    LOAD_ANTENNA_COLOMN,
    LOAD_RADIO_COLOMN,
    LOAD_RADIO_COLOMN_FREQUENCY,
    UNIT,
    VALIDATION_PARAMETER,
} from "../../utils/constant/constants";
import {generalAlert} from "../../utils/notification/notification";

export function anyObstacle(res) {
    let anyObstacle = false;
    let fre = []
    let dist = []
    let los = []
    let ter = []
    let ret = {fre, dist, los, ter, anyObstacle}
    if (res && res.resultPath && res.resultPath.receiver && res.resultPath.receiver.length &&
        res.resultPath.transmitters && res.resultPath.transmitters.length &&
        res.resultPath.transmitters[0].terrain && res.resultPath.transmitters[0].terrain.length &&
        res.resultPath.transmitters[0].distance.length) {
        const groundElevationMRX = res.resultPath.receiver[0].groundElevationM;
        const antennaHeightMRX = res.resultPath.receiver[0].antennaHeightM;
        const groundElevationMTX = res.resultPath.transmitters[0].groundElevationM;
        const antennaHeightMTX = res.resultPath.transmitters[0].antennaHeightM;

        const totalHeightRX = groundElevationMRX + antennaHeightMRX;
        const totalHeightTX = groundElevationMTX + antennaHeightMTX;
        const terrainArr = res.resultPath.transmitters[0].terrain;
        const terrainArrLength = terrainArr.length;
        const distanceArr = res.resultPath.transmitters[0].distance;
        const fresnelData = res.resultPath.transmitters[0].fresnel;

        const dataLength = Math.abs(totalHeightTX - totalHeightRX);
        const diff = totalHeightTX - totalHeightRX;
        const countBy = dataLength / terrainArrLength;

        const losArr = [totalHeightTX]
        // console.log("before = " + losArr)
        for (let i = 0; i < terrainArrLength - 1; i++) {
            if (diff > 0) {
                losArr.push(losArr[i] - countBy);
            } else {
                losArr.push(countBy + losArr[i]);
            }
        }
        // console.log("after = " + losArr)
        let fresnelArr = [];
        for (let i = 0; i < terrainArrLength; i++) {
            fresnelArr.push(losArr[i] - Math.abs(fresnelData[i]));
        }

        console.log(`DIS SIZE = ${distanceArr.length}`)
        console.log(`LOS SIZE = ${losArr.length}`)
        console.log(`TER SIZE = ${terrainArr.length}`)
        let obstacle = false;
        for (let i = 0; i < distanceArr.length; i++) {
            if (terrainArr[i] > losArr[i]) {
                obstacle = true;
            }
        }
        ret.anyObstacle = obstacle;
        ret.ter = terrainArr;
        ret.los = losArr;
        ret.dist = distanceArr;
        ret.fre = fresnelArr;
    }
    return ret;
    // if (
    //     res.resultPath &&
    //     res.resultPath.transmitters &&
    //     res.resultPath.transmitters.length &&
    //     res.resultPath.transmitters[0].obstructions &&
    //     res.resultPath.transmitters[0].obstructions.length
    // ) {
    //     anyObstacle = true;
    // }
}

export function handleHeaderModalRadioOrAntenna(
    searchText,
    loadMode,
    onSearchChange,
    handleSearchRadioByName
) {
    return (
        <form className="flex justify-start mb-6" onSubmit={handleSearchRadioByName}>
            <SearchBox
                name="searchText"
                value={searchText}
                placeholder={loadMode === "radio" ? "Search Radio" : "Search Antenna"}
                className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary border-black"
                onChangeHandler={onSearchChange}
            />
            <span className="flex justify-center items-center text-white text-lg px-1 mr-auto">
                <button
                    type="submit"
                    className="btn btn-primary rounded-full text-white text-xs px-7"
                >
                    Search
                </button>
            </span>
        </form>
    );
}

export function handleModalLoadRadioOrAntenna(
    loadMode,
    radioData,
    handleSelectedRow,
    handlePageNumber,
    antennaData,
    source,
    pageLoadDataRadio,
    pageLoadDataAntenna
) {
    if (loadMode === "radio") {
        if (
            radioData &&
            radioData.radio &&
            radioData.radio.content &&
            radioData.radio.content.length
        ) {
            return (
                <>
                    <Table
                        data={radioData.radio.content}
                        column={source === "frequencyAllocation" ? LOAD_RADIO_COLOMN_FREQUENCY : LOAD_RADIO_COLOMN}
                        assetType={ASSET_TYPE.LOAD_RADIO}
                        action={false}
                        handleSelectRow={handleSelectedRow}
                    />
                    <div className="text-white flex flex-col">
                        <div className="flex justify-center  my-6 self-end h-14 items-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={radioData.radio.totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={pageLoadDataRadio}
                                onPageChange={handlePageNumber}
                                containerClassName={"pagination-white"}
                                activeClassName={"active-white"}
                            />
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <p className="flex flex-col text-black text-center pt-20">
                    Data is empty
                </p>
            );
        }
    } else {
        if (
            antennaData &&
            antennaData.antenna &&
            antennaData.antenna.content &&
            antennaData.antenna.content.length
        ) {
            return (
                <>
                    <Table
                        data={antennaData.antenna.content}
                        column={LOAD_ANTENNA_COLOMN}
                        assetType={ASSET_TYPE.LOAD_ANTENNA}
                        action={false}
                        handleSelectRow={handleSelectedRow}
                    />
                    <div className="text-white flex flex-col">
                        <div className="flex justify-center  my-6 self-end h-14 items-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={antennaData.antenna.totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={pageLoadDataAntenna}
                                onPageChange={handlePageNumber}
                                containerClassName={"pagination-white"}
                                activeClassName={"active-white"}
                            />
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <p className="flex flex-col text-black text-center pt-20">
                    Data is empty
                </p>
            );
        }
    }
}

const between = (x, min, max) => {
    return x >= min && x <= max;
};

export const handleValueValidation = (copyPoint) => {
    // Lat, Lon
    if (
        copyPoint.transmitter.lat === 0 && copyPoint.transmitter.lon === 0
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Check your Latitude and Longitude (0, 0)",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Frequency
    if (
        !between(
            copyPoint.transmitter.frq,
            VALIDATION_PARAMETER.FREQUENCY.min,
            VALIDATION_PARAMETER.FREQUENCY.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Frequency Out of Range (30 s/d 6000) Mhz",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // TX Power
    if (
        !between(
            copyPoint.transmitter.txw,
            VALIDATION_PARAMETER.TX_POWER.min,
            VALIDATION_PARAMETER.TX_POWER.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "TX Power Out of Range (0.001 s/d 5000000) Watt",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Bandwidth
    if (
        !between(
            copyPoint.transmitter.bwi,
            VALIDATION_PARAMETER.BANDWIDTH.min,
            VALIDATION_PARAMETER.BANDWIDTH.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Bandwidth Power Out of Range (0.001 s/d 200) MHz",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Transmitter Gain
    if (
        !between(
            copyPoint.antenna.txg,
            VALIDATION_PARAMETER.TRANSMITTER_GAIN.min,
            VALIDATION_PARAMETER.TRANSMITTER_GAIN.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Transmitter Gain Out of Range (0 s/d 60) dBi",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Antenna Azimuth
    if (
        !between(
            copyPoint.antenna.azi,
            VALIDATION_PARAMETER.ANTENNA_AZIMUTH.min,
            VALIDATION_PARAMETER.ANTENNA_AZIMUTH.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Antenna Azimuth Out of Range (0 s/d 359) Degree",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Antenna Tilt
    if (
        !between(
            copyPoint.antenna.tlt,
            VALIDATION_PARAMETER.ANTENNA_TILT.min,
            VALIDATION_PARAMETER.ANTENNA_TILT.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Antenna Tilt Out of Range (-90 s/d 90) Degree",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Receiver Gain
    if (
        !between(
            copyPoint.receiver.rxg,
            VALIDATION_PARAMETER.RECEIVER_GAIN.min,
            VALIDATION_PARAMETER.RECEIVER_GAIN.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "RX Antenna Gain Out of Range (-30 s/d 60) dBi",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Sensitivity
    if (
        !between(
            copyPoint.receiver.rxs,
            VALIDATION_PARAMETER.SENSITIVITY.min,
            VALIDATION_PARAMETER.SENSITIVITY.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Sensitivity Out of Range (-120 s/d -40) dBm",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Reliability
    if (
        !between(
            copyPoint.model.rel,
            VALIDATION_PARAMETER.RELIABILITY.min,
            VALIDATION_PARAMETER.RELIABILITY.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Reliability Out of Range (0 s/d 100) %",
            ALERT_ICON_FAILED
        );
        return false;
    }

    if (copyPoint.coverageType === "GTA") {
        // High Tower GTA
        if (
            !between(
                copyPoint.transmitter.alt,
                VALIDATION_PARAMETER.ALTITUDE_METER_GTA.min,
                VALIDATION_PARAMETER.ALTITUDE_METER_GTA.max
            )
        ) {
            generalAlert(
                ALERT_TITLE_FAILED,
                "Tower Height Out of Range (1 s/d 300) Meter",
                ALERT_ICON_FAILED
            );
            return false;
        }

        // Airbone Altitude GTA
        if (
            !between(
                copyPoint.receiver.alt,
                VALIDATION_PARAMETER.RECEIVER_ALTITUDE_FEET.min,
                VALIDATION_PARAMETER.RECEIVER_ALTITUDE_FEET.max
            )
        ) {
            generalAlert(
                ALERT_TITLE_FAILED,
                "Altitude Airborne Out of Range (1 s/d 60000) Feet",
                ALERT_ICON_FAILED
            );
            return false;
        }

        // Radius GTA
        if (
            !between(
                copyPoint.output.rad,
                VALIDATION_PARAMETER.RADIUS_NM.min,
                VALIDATION_PARAMETER.RADIUS_NM.max
            )
        ) {
            generalAlert(
                ALERT_TITLE_FAILED,
                "Radius Out of Range (1 s/d 215.98) NM",
                ALERT_ICON_FAILED
            );
            return false;
        }
    } else if (copyPoint.coverageType === "GTG") {
        // High Tower GTG
        if (
            !between(
                copyPoint.transmitter.alt,
                VALIDATION_PARAMETER.ALTITUDE_METER_GTG.min,
                VALIDATION_PARAMETER.ALTITUDE_METER_GTG.max
            )
        ) {
            generalAlert(
                ALERT_TITLE_FAILED,
                "Tower Height Out of Range (0.5 s/d 300) Meter",
                ALERT_ICON_FAILED
            );
            return false;
        }

        // Receiver Altitude GTG
        if (
            !between(
                copyPoint.receiver.alt,
                VALIDATION_PARAMETER.RECEIVER_ALTITUDE_METER.min,
                VALIDATION_PARAMETER.RECEIVER_ALTITUDE_METER.max
            )
        ) {
            generalAlert(
                ALERT_TITLE_FAILED,
                "Altitude RX Out of Range (1 s/d 300) Meter",
                ALERT_ICON_FAILED
            );
            return false;
        }

        // Radius GTG
        if (
            !between(
                copyPoint.output.rad,
                VALIDATION_PARAMETER.RADIUS_KM.min,
                VALIDATION_PARAMETER.RADIUS_KM.max
            )
        ) {
            generalAlert(
                ALERT_TITLE_FAILED,
                "Radius Out of Range (0.1 s/d 50) KM",
                ALERT_ICON_FAILED
            );
            return false;
        }
    } else if (copyPoint.coverageType === "JAMMER") {
        // High Jammer (Feet) Jammer
        if (
            copyPoint.transmitter.altUnit === UNIT.FEET &&
            !between(
                copyPoint.transmitter.alt,
                VALIDATION_PARAMETER.ALTITUDE_FEET_JAMMER.min,
                VALIDATION_PARAMETER.ALTITUDE_FEET_JAMMER.max
            )
        ) {
            generalAlert(
                ALERT_TITLE_FAILED,
                "Jammer Height Out of Range (1.64 s/d 60000) Feet",
                ALERT_ICON_FAILED
            );
            return false;
        }
        // High Jammer (Meter) Jammer
        if (
            copyPoint.transmitter.altUnit === UNIT.METER &&
            !between(
                copyPoint.transmitter.alt,
                VALIDATION_PARAMETER.ALTITUDE_METER_JAMMER.min,
                VALIDATION_PARAMETER.ALTITUDE_METER_JAMMER.max
            )
        ) {
            generalAlert(
                ALERT_TITLE_FAILED,
                "Jammer Height Out of Range (0.5 s/d 18288) Meter",
                ALERT_ICON_FAILED
            );
            return false;
        }

        // Receiver Altitude (Feet) Jammer
        if (
            copyPoint.receiver.altUnit === UNIT.FEET &&
            !between(
                copyPoint.receiver.alt,
                VALIDATION_PARAMETER.ALTITUDE_FEET_JAMMER.min,
                VALIDATION_PARAMETER.ALTITUDE_FEET_JAMMER.max
            )
        ) {
            generalAlert(
                ALERT_TITLE_FAILED,
                "Altitude RX Out of Range (1.64 s/d 60000) Feet",
                ALERT_ICON_FAILED
            );
            return false;
        }
        // Receiver Altitude (Meter) Jammer
        if (
            copyPoint.receiver.altUnit === UNIT.METER &&
            !between(
                copyPoint.receiver.alt,
                VALIDATION_PARAMETER.ALTITUDE_METER_JAMMER.min,
                VALIDATION_PARAMETER.ALTITUDE_METER_JAMMER.max
            )
        ) {
            generalAlert(
                ALERT_TITLE_FAILED,
                "Altitude RX Out of Range (0.5 s/d 18288) Meter",
                ALERT_ICON_FAILED
            );
            return false;
        }

        // Radius (NM) Jammer
        if (
            copyPoint.output.radUnit === UNIT.NM &&
            !between(
                copyPoint.output.rad,
                VALIDATION_PARAMETER.RADIUS_JAMMER_NM.min,
                VALIDATION_PARAMETER.RADIUS_JAMMER_NM.max
            )
        ) {
            generalAlert(
                ALERT_TITLE_FAILED,
                "Radius Out of Range (1 s/d 215.98) NM",
                ALERT_ICON_FAILED
            );
            return false;
        }
        // Radius (KM) Jammer
        if (
            copyPoint.output.radUnit === UNIT.KM &&
            !between(
                copyPoint.output.rad,
                VALIDATION_PARAMETER.RADIUS_JAMMER_KM.min,
                VALIDATION_PARAMETER.RADIUS_JAMMER_KM.max
            )
        ) {
            generalAlert(
                ALERT_TITLE_FAILED,
                "Radius Out of Range (0.1 s/d 50) KM",
                ALERT_ICON_FAILED
            );
            return false;
        }
    }

    return true;
};

export const handleValidationMeshSimulation = (point, errors) => {
    if (Object.keys(errors).length > 0) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Please Check your Input Field",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Tower Height
    if (
        !between(
            point.transmitter.alt,
            VALIDATION_PARAMETER.ALTITUDE_METER_MESH.min,
            VALIDATION_PARAMETER.ALTITUDE_METER_MESH.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Tower Height Out of Range (0.5 s/d 18288) Meter",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Frequency
    if (
        !between(
            point.transmitter.frq,
            VALIDATION_PARAMETER.FREQUENCY.min,
            VALIDATION_PARAMETER.FREQUENCY.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Frequency Out of Range (30 s/d 6000) Mhz",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // TX Power
    if (
        !between(
            point.transmitter.txw,
            VALIDATION_PARAMETER.TX_POWER.min,
            VALIDATION_PARAMETER.TX_POWER.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "TX Power Out of Range (0.001 s/d 5000000) Watt",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Bandwidth
    if (
        !between(
            point.transmitter.bwi,
            VALIDATION_PARAMETER.BANDWIDTH.min,
            VALIDATION_PARAMETER.BANDWIDTH.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Bandwidth Power Out of Range (0.001 s/d 200) MHz",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Transmitter Gain
    if (
        !between(
            point.antenna.txg,
            VALIDATION_PARAMETER.TRANSMITTER_GAIN.min,
            VALIDATION_PARAMETER.TRANSMITTER_GAIN.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Transmitter Gain Out of Range (0 s/d 60) dBi",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Antenna Azimuth
    if (
        !between(
            point.antenna.azi,
            VALIDATION_PARAMETER.ANTENNA_AZIMUTH.min,
            VALIDATION_PARAMETER.ANTENNA_AZIMUTH.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Antenna Azimuth Out of Range (0 s/d 359) Degree",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Antenna Tilt
    if (
        !between(
            point.antenna.tlt,
            VALIDATION_PARAMETER.ANTENNA_TILT.min,
            VALIDATION_PARAMETER.ANTENNA_TILT.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Antenna Tilt Out of Range (-90 s/d 90) Degree",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Efficiency
    if (point.additionalDTO.effieciency < 61) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Efficiency should not be below 61 %",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Receiver Altitude
    if (
        !between(
            point.receiver.alt,
            VALIDATION_PARAMETER.ALTITUDE_METER_MESH.min,
            VALIDATION_PARAMETER.ALTITUDE_METER_MESH.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Altitude RX Out of Range (0.5 s/d 18288) Meter",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Receiver Gain
    if (
        !between(
            point.receiver.rxg,
            VALIDATION_PARAMETER.RECEIVER_GAIN.min,
            VALIDATION_PARAMETER.RECEIVER_GAIN.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "RX Antenna Gain Out of Range (-30 s/d 60) dBi",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Sensitivity
    if (
        !between(
            point.receiver.rxs,
            VALIDATION_PARAMETER.SENSITIVITY.min,
            VALIDATION_PARAMETER.SENSITIVITY.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Sensitivity Out of Range (-120 s/d -40) dBm",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Reliability
    if (
        !between(
            point.model.rel,
            VALIDATION_PARAMETER.RELIABILITY.min,
            VALIDATION_PARAMETER.RELIABILITY.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Reliability Out of Range (0 s/d 100) %",
            ALERT_ICON_FAILED
        );
        return false;
    }

    // Radius
    if (
        !between(
            point.output.rad,
            VALIDATION_PARAMETER.RADIUS_NM.min,
            VALIDATION_PARAMETER.RADIUS_NM.max
        )
    ) {
        generalAlert(
            ALERT_TITLE_FAILED,
            "Radius Out of Range (1 s/d 215.98) NM",
            ALERT_ICON_FAILED
        );
        return false;
    }

    return true
}
