import {
    CREATE_CIVIL,
    DELETE_CIVIL_BY_ID,
    GET_CIVIL_BY_ID,
    SEARCH_CIVIL,
    UPDATE_CIVIL
} from "../../services/towerCivil";
import {createAction} from "../../utils/reducer/reducerUtil";
import {CIVIL_ACTION_TYPES} from "./civil.types";
import {
    GET_ASSET_CIVIL,
    UPLOAD_FILE,
    UPLOAD_IMAGE
} from "../../services/asset";
import {GET_ALL_SITE} from "../../services/site";
import {STATUS_TYPE} from "../../utils/constant/constants";
import {handleClearStateGlobal, handleLoading} from "../global/global.action";
import { GLOBAL_ACTION_TYPES } from "../global/global.types";


export const handleUploadFile = (item) => dispatch => {
    const formData = new FormData();
    formData.append("file", item);
    UPLOAD_FILE(formData)
        .then(res => {
            dispatch(createAction(CIVIL_ACTION_TYPES.UPLOAD_FILE, res.id));
        })
        .catch(() => {});
}

export const handleUploadImage = (item) => dispatch => {
    const formData = new FormData();
    formData.append("file", item);
    UPLOAD_IMAGE(formData)
        .then(res => {
            dispatch(createAction(CIVIL_ACTION_TYPES.UPLOAD_IMAGE, res.id));
        })
        .catch(() => {});
}

export const handleFetchStatusCivil = () => async (dispatch) => {
    const res = await GET_ASSET_CIVIL();
    if (res) {
        dispatch(createAction(CIVIL_ACTION_TYPES.GET_STATUS, res));
    }
}

export const handleFetchSite = () => async (dispatch) => {
    const res = await GET_ALL_SITE();
    if (res && res.content.length > 0) {
        dispatch(createAction(CIVIL_ACTION_TYPES.GET_SITE, res));
    }
}

export const handleClearData = () => (dispatch) => {
    dispatch(createAction(CIVIL_ACTION_TYPES.CLEAR_DATA))
}

export const handleClearNotif = () => dispatch => {
    dispatch(createAction(CIVIL_ACTION_TYPES.UPLOAD_CIVIL_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(CIVIL_ACTION_TYPES.UPDATED, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(CIVIL_ACTION_TYPES.REMOVED, STATUS_TYPE.NO_STATEMENT));
}

export const handleSaveCivil = (data, siteId) => async (dispatch) => {

    const payload = {
        name: data.name,
        lon: data.lon,
        lat: data.lat,
        operator: data.operator,
        type: {id: 5}
    };

    CREATE_CIVIL(payload).then(() => {
        dispatch(createAction(CIVIL_ACTION_TYPES.UPLOAD_CIVIL_STATUS, STATUS_TYPE.SUCCESS))
    }).catch(e => {
        dispatch(createAction(CIVIL_ACTION_TYPES.UPLOAD_CIVIL_STATUS, STATUS_TYPE.FAILED))
    });
    dispatch(handleClearStateGlobal());
    dispatch(createAction(CIVIL_ACTION_TYPES.IS_LOADING, false));

}

export const handleStartLoading = () => dispatch => {
    dispatch(createAction(CIVIL_ACTION_TYPES.IS_LOADING, true));
}

export const handleRemoveCivil = (id, siteId, page) => async (dispatch) => {
    dispatch(createAction(CIVIL_ACTION_TYPES.IS_LOADING, true));
    DELETE_CIVIL_BY_ID(id)
        .then(() => {
            dispatch(createAction(CIVIL_ACTION_TYPES.REMOVED, STATUS_TYPE.SUCCESS))
            dispatch(handleClearStateGlobal());
        })
        .catch(() => {
            dispatch(createAction(CIVIL_ACTION_TYPES.REMOVED, STATUS_TYPE.FAILED))
        })
    dispatch(createAction(CIVIL_ACTION_TYPES.IS_LOADING, false));
}
export const handleUpdateCivil = (id, data, siteId, page) => async (dispatch) => {

    const payload = {
        name: data.name,
        lon: data.lon,
        lat: data.lat,
        operator: data.operator,
        type: {id: 5}
    };

    UPDATE_CIVIL(id, payload)
        .then(() => {
            dispatch(createAction(CIVIL_ACTION_TYPES.UPDATED, STATUS_TYPE.SUCCESS));
        })
        .catch(() => {
            dispatch(createAction(CIVIL_ACTION_TYPES.UPDATED, STATUS_TYPE.FAILED));
        })
    dispatch(handleClearStateGlobal());
    dispatch(createAction(CIVIL_ACTION_TYPES.IS_LOADING, false));
};

export const handleSearchCivil = (q, type, page, size, siteId) => dispatch => {
    SEARCH_CIVIL(q, type, page, size, siteId)
        .then(res => {
            dispatch(createAction(CIVIL_ACTION_TYPES.SEARCH, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED));
        })
};

export const handleGetCivilById = (id) => dispatch => {
    GET_CIVIL_BY_ID(id)
        .then(res => {
            dispatch(createAction(CIVIL_ACTION_TYPES.DETAIL_CIVIL, res));
        })
        .catch(() => {});
};
