import * as React from "react";

const JammerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 11802 11802"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={3.531}
      d="M3576 3805c0-186 50-336 113-336 62 0 112 150 112 336v3062h-225V3805zM2097 7233h5301v1923H2097zM4479 3805c0-186 50-336 113-336 62 0 112 150 112 336v3062h-225V3805z"
    />
    <path
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={3.531}
      d="M7429 7155H2128l671-317h5301zM7466 7207v1923l671-317V6890z"
    />
    <circle
      fill="currentColor"
      stroke="#FEFEFE"
      strokeWidth={17.654}
      cx={3482}
      cy={8306}
      r={346}
    />
    <path
      fill="currentColor"
      stroke="#FEFEFE"
      strokeWidth={17.654}
      d="M3522 7839c146 0 266 110 283 252v1l17 147c-32-159-172-279-340-279-124 0-233 65-294 164l81-130c7-14 16-28 25-40 52-70 135-115 228-115z"
    />
    <path
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={3.531}
      d="M9239 3408c632 693 610 1614 9 2234 154-611-15-1294-530-1859-568-623-1423-954-2258-949 922-390 2106-163 2779 574zm-948 561c506 554 488 1291 8 1786 123-489-12-1035-424-1486-454-498-1137-763-1804-758 737-312 1683-131 2220 458zm-2191 200c460-195 1052-82 1388 286 316 347 305 807 5 1117 77-306-7-647-265-929-284-312-711-477-1128-474z"
    />
    <path
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={3.531}
      d="M1280 2h9242c703 0 1279 575 1279 1278v9242c0 703-576 1279-1279 1279H1280c-703 0-1278-576-1278-1279V1280C2 577 577 2 1280 2zm316 404h8610c655 0 1191 535 1191 1190v8610c0 655-536 1191-1191 1191H1596c-655 0-1190-536-1190-1191V1596c0-655 535-1190 1190-1190z"
    />
    <path
      fill="currentColor"
      fillRule="nonzero"
      stroke="#332C2B"
      strokeWidth={3.531}
      d="M6907 5499c0-335-272-607-607-607-336 0-608 272-608 607 0 336 272 608 608 608 335 0 607-272 607-608zm-948-399c92-78 211-125 341-125 289 0 524 235 524 524 0 121-41 242-125 341l-740-740zm681 798c-92 79-210 126-340 126-290 0-525-235-525-525 0-121 41-241 126-340l739 739z"
    />
  </svg>
);

export default JammerIcon;
