import {ASSET_ACTION_TYPES} from "./asset.types";

const INITIAL_STATE = {
    assets: null,
    sites: null,
    isTooltipsOpen: false,
    handler: null,
    eventListener: null,
    totalAsset: null,  // {}
    stopReq: false,
    selectedSite: null,
};

export const assetReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case ASSET_ACTION_TYPES.FETCH_DATA_ASSET:
            return {...state, assets: payload, isTooltipsOpen: false}
        case ASSET_ACTION_TYPES.FETCH_DATA_SITE:
            return {...state, sites: payload}
        case ASSET_ACTION_TYPES.OPEN_TOOLTIPS:
            return {...state, isTooltipsOpen: payload, stopReq: payload};
        case ASSET_ACTION_TYPES.REGISTER_HANDLER_LISTENER:
            const {handler, eventListener} = payload
            return {...state, handler, eventListener}
        case ASSET_ACTION_TYPES.FETCH_TOTAL_ASSET:
            return {...state, totalAsset: payload}
        case ASSET_ACTION_TYPES.SELECTED_SITE_ID:
            return {...state, selectedSite: payload}
        default:
            return state;
    }
}