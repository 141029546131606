import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";

import {GET_ALL_SITE} from "../../../services/site";
import HeaderPage from "../../../components/header/HeaderPage";
import Table from "../../../components/table/Table";
import { handleLoading } from "../../../store/global/global.action";
import { GLOBAL_ACTION_TYPES } from "../../../store/global/global.types";
import { STATUS_TYPE } from "../../../utils/constant/constants";
import { createAction } from "../../../utils/reducer/reducerUtil";

const OwnerType = () => {
    const [data, setData] = useState([]);

    const dispatch = useDispatch();

    const column = [{ heading: "Owner Type", value: "siteName" }];

    useEffect(() => {
        dispatch(handleLoading(true));
        getSiteData();

        return () => {
            dispatch(handleLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSiteData = () => {
        GET_ALL_SITE().then((res) => {
            setData(res.content);
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED));
        })
    };

    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="Owner Type" />

            <div className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">

                    {data.length > 0 ? (
                        <Table data={data} column={column} action={false}/>
                    ) : (
                        <p className="flex flex-col text-white text-center pt-10">
                            Data is empty
                        </p>
                    )}

                    <div className="text-white flex flex-col">
                        <div className="flex justify-center  my-6 self-end h-14 items-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={1}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OwnerType;
