import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import {useNavigate} from "react-router-dom";

import HeaderPage from "../../../components/header/HeaderPage";
import Table from "../../../components/table/Table";

import PlusIcon from "../../../assets/svgs/PlusIcon";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCreateStatus,
    selectDataReport,
    selectDataTable, selectErrorMessage,
    selectRemoveStatus,
    selectSendToLogStatus,
    selectUpdateStatus
} from "../../../store/maintenance/maintenance.selector";
import {
    handleClearNotif,
    handleEditMaintenanceData,
    handleGetAllMaintenanceData,
    handleGetAllMaintenanceDataReport,
    handleNewMaintenance,
    handleRemoveMaintenanceData,
} from "../../../store/maintenance/maintenance.action";
import {selectCurrentUser} from "../../../store/user/user.selector";
import {
    ALERT_ICON_FAILED,
    ALERT_ICON_SUCCESS,
    ALERT_TEXT_CREATE_FAILED,
    ALERT_TEXT_CREATE_SUCCESS,
    ALERT_TEXT_REMOVE_FAILED,
    ALERT_TEXT_REMOVE_SUCCESS,
    ALERT_TEXT_UPDATE_FAILED,
    ALERT_TEXT_UPDATE_SUCCESS,
    ALERT_TITLE_FAILED,
    ALERT_TITLE_SUCCESS,
    ASSET_TYPE,
    CONFIRMATION_ICON_REMOVE,
    CONFIRMATION_TEXT_REMOVE,
    CONFIRMATION_TITLE_REMOVE,
    MAINTENANCE_TYPE,
    MAX_REQUEST_SIZE,
    STATUS_TYPE,
    siteList, ERROR_MESSAGE_ALREADY_DONE, USER_MABES
} from "../../../utils/constant/constants";
import {
    selectAssetToEdit,
    selectAssetToRemove,
    selectCurrentItem,
} from "../../../store/global/global.selector";
import {handleClearStateGlobal, handleLoading} from "../../../store/global/global.action";
import SearchBox from "../../../components/search-box/search-box.component";
import ReportMultipleMaintenanceData from "../../../components/report/asset/ReportMultipleMaintenanceData";
import {generalAlert, generalConfirmation} from "../../../utils/notification/notification";

const COLUMN = [
    {heading: "Name", value: "name"},
    {heading: "Owner", value: "owner"},
    {heading: "Serial Number", value: "serialNo"},
    {heading: "Asset Condition", value: "condition"},
    {heading: "Maintenance Status", value: "mainStatus"},
    {heading: "Asset Location", value: "location"},
]

const MaintenanceData = () => {

    const [searchText, setSearchText] = useState("");
    const [searchTextOnChange, setSearchTextOnChange] = useState("");
    const [searchType, setSearchType] = useState("name");
    const [searchOwner, setSearchOwner] = useState(USER_MABES);
    const [page, setPage] = useState(0);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const dataTable = useSelector(selectDataTable);
    const dataReport = useSelector(selectDataReport);
    const currentUser = useSelector(selectCurrentUser);
    const assertToRemove = useSelector(selectAssetToRemove);
    const assetToEdit = useSelector(selectAssetToEdit);
    const currentItem = useSelector(selectCurrentItem);
    const removeStatus = useSelector(selectRemoveStatus);
    const createStatus = useSelector(selectCreateStatus);
    const updateStatus = useSelector(selectUpdateStatus);
    const errorMessage = useSelector(selectErrorMessage);
    const sendToLogStatus = useSelector(selectSendToLogStatus);

    useEffect(() => {
        dispatch(handleLoading(true));

        if (currentUser) {
            dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.DATA, searchText, "name", 0, 10, `all`));
            dispatch(handleGetAllMaintenanceDataReport(MAINTENANCE_TYPE.DATA, searchText, "name", 0, MAX_REQUEST_SIZE, `all`));
        }

        return () => {
            dispatch(handleClearStateGlobal());
            setSearchType("");
            setSearchText("");
            setPage(0);
            dispatch(handleLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * EDIT DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.MAINTENANCE_DATA === assetToEdit && currentItem) {
            dispatch(handleEditMaintenanceData(MAINTENANCE_TYPE.DATA, true, currentItem, navigate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToEdit])

    /**
     * CREATE
     */
    useEffect(() => {
        if (createStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === createStatus;
            const isFailed = STATUS_TYPE.FAILED === createStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_CREATE_SUCCESS : ALERT_TEXT_CREATE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => dispatch(handleClearNotif())
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createStatus]);

    /**
     * UPDATE
     */
    useEffect(() => {
        if (updateStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === updateStatus;
            const isFailed = STATUS_TYPE.FAILED === updateStatus;
            let cpyErrorMessage = ALERT_TEXT_UPDATE_FAILED;
            if (errorMessage === "Maintenance is already Done") {
                cpyErrorMessage = ERROR_MESSAGE_ALREADY_DONE;
            }
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_UPDATE_SUCCESS : cpyErrorMessage,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => dispatch(handleClearNotif())
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStatus]);

    /**
     * REMOVE
     */
    useEffect(() => {
        if (ASSET_TYPE.MAINTENANCE_DATA === assertToRemove && currentItem) {
            generalConfirmation(
                CONFIRMATION_TITLE_REMOVE,
                CONFIRMATION_TEXT_REMOVE(currentItem.name),
                CONFIRMATION_ICON_REMOVE,
                () => {
                    dispatch(handleLoading(true));
                    dispatch(handleRemoveMaintenanceData(MAINTENANCE_TYPE.DATA, currentItem.id, searchType, page));
                },
                () => dispatch(handleClearStateGlobal()),
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assertToRemove]);

    useEffect(() => {
        if (removeStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === removeStatus;
            const isFailed = STATUS_TYPE.FAILED === removeStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_REMOVE_SUCCESS : ALERT_TEXT_REMOVE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => dispatch(handleClearNotif())
                );
            }
            if (dataTable && dataTable.data && dataTable.data.length) {
                dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.DATA, searchText, searchType, page, 10, `all`));
                dispatch(handleGetAllMaintenanceDataReport(MAINTENANCE_TYPE.DATA, searchText, searchType, 0, MAX_REQUEST_SIZE, `all`));
            } else {
                if (page) {
                    dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.DATA, searchText, searchType, page - 1, 10, `all`));
                    dispatch(handleGetAllMaintenanceDataReport(MAINTENANCE_TYPE.DATA, searchText, searchType, 0, MAX_REQUEST_SIZE, `all`));
                    setPage(prevPage => prevPage - 1);
                } else {
                    dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.DATA, searchText, searchType, 0, 10, `all`));
                    dispatch(handleGetAllMaintenanceDataReport(MAINTENANCE_TYPE.DATA, searchText, searchType, 0, MAX_REQUEST_SIZE, `all`));
                    setPage(0);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeStatus]);

    useEffect(() => {
        if (sendToLogStatus) {
            if (STATUS_TYPE.SUCCESS === sendToLogStatus) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_SUCCESS, "Data has been send to history", ALERT_ICON_SUCCESS, () => dispatch(handleClearNotif()));
            } else if (STATUS_TYPE.FAILED === sendToLogStatus) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_FAILED, "Cannot send data to history", ALERT_ICON_FAILED, () => dispatch(handleClearNotif()))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendToLogStatus]);

    useEffect(() => {
        if (searchType === "owner") {
            dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.DATA, searchOwner, searchType, 0, 10, `all`));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchType, searchOwner]);

    const onSearch = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));

        setSearchText(searchTextOnChange);

        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value.toLowerCase();
        const type = searchText === "" ? "name" : searchType;

        dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.DATA, searchText, type, 0, 10, `all`));

        setPage(0);
    }

    /**
     * Do Nothing
     * @param id selected id
     */

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        if (searchType === "owner") {
            dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.DATA, searchOwner, searchType, e.selected, 10, `all`));
        } else if (searchType !== "owner" && dataTable && dataTable.data) {
            dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.DATA, searchText, searchType, e.selected, 10, `all`));
        }
        setPage(e.selected);
    };
    
    const createMaintenanceData = (e) => {
        e.preventDefault();
        dispatch(handleNewMaintenance())
        navigate("/assetdata/maintenance-data/asset-maintenance");
    }

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    }

    const handleSearchOwner = (e) => {
        setSearchOwner(e.target.value);
        setPage(0);
    }

    return (
        <div className="bg-tertiary min-h-screen py-4">
            <HeaderPage title="Maintenance Data" />

            <div className="pb-20 w-[92%] mx-auto mt-24">
                <form className="flex mb-6" onSubmit={onSearch}>
                    <select
                        name="filter"
                        className="select select-bordered w-[200px] rounded-3xl h-[30px]"
                        onChange={(e) => setSearchType(e.target.value)}
                    >
                        <option selected disabled>Select filter</option>
                        <option value="name">Filter by Name</option>
                        {currentUser?.siteName === USER_MABES && (
                            <option value="owner">Filter by Owner</option>
                        )}
                        <option value="condition">Filter by Asset Condition</option>
                        <option value="status">Filter by Status</option>
                    </select>
                    {searchType !== "owner" ? (
                        <SearchBox 
                            name="searchText"
                            value={searchTextOnChange}
                            placeholder="Search Maintenance Data"
                            className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                            onChangeHandler={onSearchChange}
                        />
                    ) : (
                        <select
                            name="filter"
                            className="select min-w-[300px] mx-3 rounded-full px-5"
                            onChange={handleSearchOwner}
                        >
                            {siteList && siteList.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                    <span className="flex justify-center items-center text-white text-lg px-5 mr-auto">
						<button
                            type="submit"
                            className="btn btn-primary rounded-full text-white text-xs px-7"
                        >
							Search
						</button>
					</span>
                    <ReportMultipleMaintenanceData data={dataReport?.data} />
                    <span
                        className="flex justify-center items-center text-white gap-x-2 text-lg cursor-pointer"
                        onClick={createMaintenanceData}
                    >
						<button className="cursor-pointer">
							<PlusIcon/>
						</button>
						Add Maintenance
					</span>
                </form>

                {dataTable && dataTable.data && dataTable.data.length > 0 ? (
                    <Table 
                        data={dataTable.data}
                        column={COLUMN}
                        assetType={ASSET_TYPE.MAINTENANCE_DATA}
                    />
                ) : (
                    <p className="flex flex-col text-white text-xl text-center pt-10">
                        Data is empty
                    </p>
                )}

                <div className="text-white flex flex-col">
                    <div className="flex justify-center  my-6 self-end h-14 items-center">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={dataTable && dataTable.totalPage && dataTable.totalPage}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            forcePage={page}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaintenanceData;
