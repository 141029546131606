import {createSelector} from "reselect";

const selectRoleReducer = (state) => state.roleReducer;

export const selectAllRole = createSelector(
    [selectRoleReducer],
    (state) => state.allRole
);
export const selectRoleData = createSelector(
    [selectRoleReducer],
    (state) => state.roleData
);