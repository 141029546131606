import {DISTRIBUTION_ACTION_TYPES} from "./distribution.types";

const INITIAL_STATE = {
    assets: null,
    sites: null,
    isTooltipsOpen: false,
    handler: null,
    eventListener: null,
    totalAsset:null,  // {}
    selectedSite: null,
    towerCivilData: null,
    workshopData: null,
    foData: null,

};

export const distributionReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case DISTRIBUTION_ACTION_TYPES.FETCH_FO:
            return {...state, foData: payload}
        case DISTRIBUTION_ACTION_TYPES.FETCH_TOWER_CIVIL:
            return {...state, towerCivilData: payload}
        case DISTRIBUTION_ACTION_TYPES.FETCH_WORKSHOP:
            return {...state, workshopData: payload}
        case DISTRIBUTION_ACTION_TYPES.FETCH_DATA_ASSET:
            return {...state, assets: payload, isTooltipsOpen: false}
        case DISTRIBUTION_ACTION_TYPES.FETCH_DATA_SITE:
            return {...state, sites: payload}
        case DISTRIBUTION_ACTION_TYPES.OPEN_TOOLTIPS:
            return {...state, isTooltipsOpen: payload}
        case DISTRIBUTION_ACTION_TYPES.REGISTER_HANDLER_LISTENER:
            const {handler, eventListener} = payload
            return {...state, handler, eventListener}
        case DISTRIBUTION_ACTION_TYPES.FETCH_TOTAL_ASSET:
            return {...state, totalAsset: payload}
        case DISTRIBUTION_ACTION_TYPES.SELECTED_SITE_ID:
            return {...state, selectedSite: payload}
        default:
            return state;
    }
}