import {CIVIL_ACTION_TYPES} from "./civil.types";
import {STATUS_TYPE} from "../../utils/constant/constants";

const INITIAL_STATE = {
    status: null,  // {}
    civils: null, // {radio:{content:[]}}
    detailCivil: null,
    sites: null, // {content:[]}
    
    column: [
        {heading: "Name", value: "name"},
        {heading: "Latitude", value: "lat"},
        {heading: "Longitude", value: "lon"},
        {heading: "Operator", value: "operator"},
    ],
    uploadCivilStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedImages: [],
    uploadImageStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedFiles: [],
    uploadFileStatus: STATUS_TYPE.NO_STATEMENT,
    isLoading: false,
    removeStatus: STATUS_TYPE.NO_STATEMENT,
    updateStatus: STATUS_TYPE.NO_STATEMENT,
}

export const civilReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case CIVIL_ACTION_TYPES.GET_ALL_CIVIL:
            return {...state, civils: payload};
        case CIVIL_ACTION_TYPES.SEARCH:
            return {...state, civils: payload};
        case CIVIL_ACTION_TYPES.DETAIL_CIVIL:
            return {...state, detailCivil: payload};
        case CIVIL_ACTION_TYPES.CLEAR_DETAIL_CIVIL:
            return {...state, detailCivil: null};
        case CIVIL_ACTION_TYPES.GET_CATEGORY:
            return {...state, categories: payload};
        case CIVIL_ACTION_TYPES.GET_TYPE:
            return {...state, types: payload};
        case CIVIL_ACTION_TYPES.GET_BRAND:
            return {...state, brands: payload};
        case CIVIL_ACTION_TYPES.GET_SITE:
            return {...state, sites: payload};
        case CIVIL_ACTION_TYPES.CLEAR_DATA:
            return INITIAL_STATE;
        case CIVIL_ACTION_TYPES.UPLOAD_CIVIL_STATUS:
            return {...state, uploadCivilStatus: payload, uploadedFiles: [], uploadedImages: []}
        case CIVIL_ACTION_TYPES.UPLOAD_IMAGE:
            return {...state, uploadedImages: payload}
        case CIVIL_ACTION_TYPES.UPLOAD_IMAGE_STATUS:
            return {...state, uploadImageStatus: payload}
        case CIVIL_ACTION_TYPES.UPLOAD_FILE:
            return {...state, uploadedFiles: payload}
        case CIVIL_ACTION_TYPES.UPLOAD_FILE_STATUS:
            return {...state, uploadFileStatus: payload}
        case CIVIL_ACTION_TYPES.REMOVED:
            return {...state, removeStatus: payload}
        case CIVIL_ACTION_TYPES.UPDATED:
            return {...state, updateStatus: payload}
        default:
            return state;
    }
}