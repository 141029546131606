import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

// Redux
import { selectDataTableAsset } from "../../../store/maintenance/maintenance.selector";
import {
    handleCollectAssetData,
    handleGetSite, handleResetAllAsset,
    handleSaveAssetType,
    handleSearchAsset,
} from "../../../store/maintenance/maintenance.action";
import { selectCurrentUser } from "../../../store/user/user.selector";

// Utils
import { ASSET_TYPE } from "../../../utils/constant/constants";

// Components
import HeaderPage from "../../../components/header/HeaderPage";
import Table from "../../../components/table/Table";
import { handleLoading } from "../../../store/global/global.action";
import SearchBox from "../../../components/search-box/search-box.component";

const SelectAsset = () => {
    const [assetType, setAssetType] = useState("");
    const [searchText, setSearchText] = useState("");
    const [searchTextOnChange, setSearchTextOnChange] = useState("");
    const [page, setPage] = useState(0);
    const [selectRow, setSelectRow] = useState(false);

    const COLUMN = [
        {heading: "Name", value: "name"},
        {heading: "Brand", value: "brand"},
        {heading: "Type", value: "type"},
        {heading: "Serial Number", value: "serialNo"},
        {heading: "Owner", value: "owner"},
    ];

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const dataTableAsset = useSelector(selectDataTableAsset);
    const currentUser = useSelector(selectCurrentUser);

    useEffect(() => {
        dispatch(handleLoading(true));
        dispatch(handleGetSite());
        return () => {
            dispatch(handleResetAllAsset());
            setSelectRow(false);
            dispatch(handleLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataTableAsset || !dataTableAsset) {
            dispatch(handleLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTableAsset]);
    
    const handleSelectRow = (id) => {
        setSelectRow(true);
        dispatch(handleCollectAssetData(id, assetType));
    }

    const handleAssetType = (e) => {
        dispatch(handleLoading(true));
        setAssetType(e.target.value);
        dispatch(handleSaveAssetType(e.target.value));
        dispatch(handleSearchAsset(searchText, e.target.value, currentUser.siteId, 0));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));
        if (assetType !== "") {
            navigate("/assetdata/maintenance-data/type-maintenance");
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if (assetType !== "") {
            dispatch(handleLoading(true));
        }
    
        setSearchText(searchTextOnChange);
    
        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value.toLowerCase();
    
        dispatch(handleSearchAsset(searchText, assetType, currentUser.siteId, 0));
    
        setPage(0);
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    };

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        dispatch(handleSearchAsset(searchText, assetType, currentUser.siteId, e.selected));
        setPage(e.selected);
    };

    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="Maintenance Asset"/>

            <div className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">
                    <form onSubmit={handleSearch}>
                        <div className="flex flex-col">
                            <label htmlFor="asset" className="text-white font-bold px-3 mb-2">
                                Asset
                            </label>
                            <select
                                name="asset"
                                id="asset"
                                value={assetType}
                                onChange={handleAssetType}
                                className="select select-primary w-full font-normal"
                            >
                                <option value="" disabled selected>-- Select Asset --</option>
                                <option value="radio">Radio</option>
                                <option value="antenna">Antenna</option>
                                <option value="tower">Tower</option>
                                <option value="pabx">PABX</option>
                                <option value="dss">Supporting</option>
                            </select>
                        </div>

                        <div className="flex mb-6 mt-14">
                            <SearchBox 
                                name="searchText"
                                value={searchTextOnChange}
                                placeholder="Search Asset"
                                className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                                onChangeHandler={onSearchChange}
                            />
                            <span className="flex justify-center items-center text-white text-lg px-5 mr-auto">
                                <button
                                    type="submit"
                                    className="btn btn-primary rounded-full text-white text-xs px-7"
                                >
                                    Search
                                </button>
                            </span>
                        </div>

                        {dataTableAsset && dataTableAsset.length > 0 ? (
                            <div>
                                <Table
                                    data={dataTableAsset}
                                    column={COLUMN}
                                    assetType={ASSET_TYPE.SELECT_ASSET_MAINTENANCE}
                                    action={false}
                                    handleSelectRow={handleSelectRow}
                                />
                                <div className="text-white flex flex-col">
                                    <div className="flex justify-center  my-6 self-end h-14 items-center">
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={dataTableAsset[0] ? dataTableAsset[0].totalPages : 1}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            forcePage={page}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p className="flex flex-col text-white text-lg text-center pt-10">
                                Data is empty
                            </p>
                        )}


                        <div className="flex justify-end gap-x-4 mt-10">
                            <button
                                onClick={() => navigate("/assetdata/maintenance-data")}
                                className="btn btn-outline text-white"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                disabled={!selectRow}
                                className="btn btn-primary text-white px-6"
                            >
                                Apply
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SelectAsset;