import * as React from "react";

const MeshSimulation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1419 1419"
    {...props}
  >
    <g stroke={props.stroke}>
      <ellipse
        fill="currentColor"
        strokeWidth={14.735}
        cx={1079}
        cy={496}
        rx={106}
        ry={134}
      />
      <path
        fill="currentColor"
        strokeWidth={0.982}
        d="M154 0h1111c85 0 154 70 154 154v1111c0 85-69 154-154 154H154c-84 0-154-69-154-154V154C0 70 70 0 154 0zm38 49h1035c79 0 143 64 143 143v1035c0 79-64 143-143 143H192c-79 0-143-64-143-143V192c0-79 64-143 143-143z"
      />
      <ellipse
        fill="currentColor"
        strokeWidth={14.735}
        cx={710}
        cy={283}
        rx={106}
        ry={134}
      />
      <ellipse
        fill="currentColor"
        strokeWidth={14.735}
        cx={340}
        cy={496}
        rx={106}
        ry={134}
      />
      <ellipse
        fill="currentColor"
        strokeWidth={14.735}
        cx={340}
        cy={923}
        rx={106}
        ry={134}
      />
      <path fill="none" strokeWidth={14.735} d="M647 391 403 815" />
      <ellipse
        fill="currentColor"
        strokeWidth={14.735}
        cx={894}
        cy={1029}
        rx={106}
        ry={134}
      />
      <path
        fill="none"
        strokeWidth={14.735}
        d="m806 339 177 102m53 178-99 288M340 630v159M613 339 437 441M445 943l345 66M437 867l546-315M710 283l184 746"
      />
    </g>
  </svg>
);

export default MeshSimulation;
