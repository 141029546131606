import {createSelector} from "reselect";
import {getPercentage} from "../../utils/calculate/convert";

const selectAssetReducer = (state) => state.assetReducer;

export const selectAssets = createSelector(
    [selectAssetReducer],
    (state) => state.assets
)

export const selectCardData = createSelector(
    [selectAssets],
    (data) => {
        if (data) {
            return [
                {
                    id: 1,
                    name: "Total Asset",
                    item: data.totalCount ?? 0,
                    icon: "total",
                },
                {
                    id: 2,
                    name: "Tower",
                    item: data.towerDetail?.total ?? 0,
                    icon: "tower",
                },
                {
                    id: 3,
                    name: "Radio",
                    item: data.radioDetail?.total ?? 0,
                    icon: "radio",
                },
                {
                    id: 4,
                    name: "PABX",
                    item: data.pabxDetail?.total ?? 0,
                    icon: "pabx",
                },
                {
                    id: 5,
                    name: "Antenna",
                    item: data.antennaDetail?.total ?? 0,
                    icon: "antenna",
                },
                {
                    id: 6,
                    name: "Supporting",
                    item: data.dssDetail?.total ?? 0,
                    icon: "supp",
                },
            ];
        } else {
            return [];
        }
    }
)

export const selectChartData = createSelector(
    [selectAssets],
    (state) => {
        if (state) {
            return [
                {
                    datasets: [
                        {
                            label: "Radio",
                            data: [
                                getPercentage(state.radioDetail.spare, state.radioDetail.total),
                                getPercentage(state.radioDetail.service, state.radioDetail.total),
                                getPercentage(state.radioDetail.unservice, state.radioDetail.total),
                            ],
                            backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                            borderColor: ["#040f0f"],
                            borderWidth: 10,
                        },
                    ],
                },
                {
                    datasets: [
                        {
                            label: "Antenna",
                            data: [
                                getPercentage(state.antennaDetail.spare, state.antennaDetail.total),
                                getPercentage(state.antennaDetail.service, state.antennaDetail.total),
                                getPercentage(state.antennaDetail.unservice, state.antennaDetail.total),
                            ],
                            backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                            borderColor: ["#040f0f"],
                            borderWidth: 10,
                        },
                    ],
                },
                {
                    datasets: [
                        {
                            label: "Tower",
                            data: [
                                getPercentage(state.towerDetail.spare, state.towerDetail.total),
                                getPercentage(state.towerDetail.service, state.towerDetail.total),
                                getPercentage(state.towerDetail.unservice, state.towerDetail.total),
                            ],
                            backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                            borderColor: ["#040f0f"],
                            borderWidth: 10,
                        },
                    ],
                },
                {
                    datasets: [
                        {
                            label: "PABX",
                            data: [
                                getPercentage(state.pabxDetail.spare, state.pabxDetail.total),
                                getPercentage(state.pabxDetail.service, state.pabxDetail.total),
                                getPercentage(state.pabxDetail.unservice, state.pabxDetail.total),
                            ],
                            backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                            borderColor: ["#040f0f"],
                            borderWidth: 10,
                        },
                    ],
                },
                {
                    datasets: [
                        {
                            label: "Supporting",
                            data: [
                                getPercentage(state.dssDetail.spare, state.dssDetail.total),
                                getPercentage(state.dssDetail.service, state.dssDetail.total),
                                getPercentage(state.dssDetail.unservice, state.dssDetail.total),
                            ],
                            backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                            borderColor: ["#040f0f"],
                            borderWidth: 10,
                        },
                    ],
                },
            ];
        } else {
            return [];
        }
    }
)

export const selectSites = createSelector(
    [selectAssetReducer],
    (state) => state.sites
)
export const selectHandler = createSelector(
    [selectAssetReducer],
    (state) => state.handler
)
export const selectEventListener = createSelector(
    [selectAssetReducer],
    (state) => state.eventListener
)
export const selectIsToolTipsOpen = createSelector(
    [selectAssetReducer],
    (state) => state.isTooltipsOpen
)
export const selectTotalAsset = createSelector(
    [selectAssetReducer],
    (state) => state.totalAsset
)
export const selectSelectedSite = createSelector(
    [selectAssetReducer],
    (state) => state.selectedSite
)