import React, {useEffect, useState} from "react";
import jsPDF from "jspdf";
import {useSelector} from "react-redux";

import {selectViewer} from "../../../store/dashboard/dashboard.selector";
import {report} from "../../../utils/report/report";
import {takeSnap} from "../../../utils/cesium/cesiumUtils";

import LambangTNI from "../../../assets/png/Lambang_TNI_AU.png";

const ReportMesh = ({ selectedData, meshMean, chartImage }) => {
    const [profile, setProfile] = useState([]);

    const viewer = useSelector(selectViewer);

    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem("profile"));
        setProfile(userProfile);
    }, []);

    const handlePdfGenerate = () => {
        // Format
        const doc = new jsPDF("potrait", "px", "a4", "false");

        selectedData.forEach((item, index) => {
            if (item) {
                const { name, network } = item;
                const { lat: latitude, lon: longitude, alt: highTower, frq: frequency, txw: txPower, bwi: bandwidth } = item?.transmitter;
                const {
                    txl: loss,
                    txg: transmitterGain,
                    pol: polarization,
                    azi: azimuth,
                    tlt: tilt,
                } = item?.antenna
                const {
                    alt: heightAGL,
                    rxg: receiverGain,
                    rxs: sensitivity,
                } = item?.receiver
                const {
                    nf: noiseFloor,
                } = item?.output
                const { rel: reliability } = item?.model;
                const {
                    coaxialTypeDto: { name: coaxialType },
                    coaxialLength,
                    connector,
                    erp,
                    eirp,
                    effieciency,
                    patternDto: { name: patternName , img1: patternImg1, img2: patternImg2}
                } = item?.additionalDTO;
    
                return (
                    // Template
                    report.template(
                        doc,
                        index + 1,
                        profile,
                        LambangTNI,
                        "Report Mesh Simulation",
                    ),
            
                    // Ground Transmitter / TX
                    report.transmitterMesh(
                        doc,
                        "Mesh",
                        name,
                        network,
                        index + 1,
                        latitude,
                        longitude,
                        highTower,
                        frequency,
                        txPower,
                        bandwidth,
                    ),
            
                    // Feeder
                    report.feederMesh(
                        doc,
                        coaxialType,
                        coaxialLength,
                        connector,
                        loss,
                        erp,
                        eirp,
                        effieciency,
                    ),
            
                    // Receiver / RX
                    report.receiverMesh(
                        doc,
                        "Mesh",
                        heightAGL,
                        receiverGain,
                        sensitivity,
                        noiseFloor,
                    ),
            
                    // Propagation Model
                    report.propagationModelMesh(
                        doc,
                        "ITU-R P.525 (Free space model)",
                        reliability,
                    ),
            
                    // Antenna
                    report.antennaMesh(
                        doc,
                        transmitterGain,
                        azimuth,
                        tilt,
                        polarization,
                        patternName,
                        patternImg1,
                        patternImg2,
                    ),
            
                    // Add new page
                    report.addPage(doc)
                )
            }
        })

        // Template appendix
        report.templateAppendix(
            doc,
            selectedData.length + 1,
            profile,
            LambangTNI,
        )

        // Image Coverage
        report.coverageImg(doc, takeSnap(viewer))

        // Rectangle
        doc.setFillColor("#242627");
        doc.roundedRect(60, 290, 93, 55, 2, 2, "F");

        doc.setFontSize(5);
        doc.setTextColor("#FFFFFF");

        // Nodes
        doc.setFont(undefined, "normal").text(`${selectedData.length} Nodes`, 70, 300);
        doc.setFont(undefined, "normal").text(`Mesh: ${meshMean} dBm`, 70, 305);

        // A, B, C
        doc.setFont(undefined, "normal").text("A: >-80", 120, 325);
        doc.setFont(undefined, "normal").text("B: -90 to -80", 120, 330);
        doc.setFont(undefined, "normal").text("C: <-90", 120, 335);

        // Chart Image
        report.chartImgMesh(doc, chartImage);

        report.savePdf(doc, "Mesh Simulation")
    };


    return (
        <button
            type="button"
            className="btn btn-primary text-white ml-4"
            onClick={handlePdfGenerate}
        >
            Create Report
        </button>
    )
};

export default ReportMesh;
