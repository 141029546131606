import {DASHBOARD_ACTION_TYPES} from "./dashboard.types";

const INITIAL_STATE = {
    resultList: null,
    networkList: [],
    showAllCoverage: false,
    showAllLabel: true,
    viewer: null,
    toggleFilterBtn: false,
    entityEventListener: null,
    detailEntity: null,
    mouseMoveListener: null,
    allSite: null,
    primitive: null,
}

export const dashboardReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case DASHBOARD_ACTION_TYPES.PRIMITIVE:
            return {...state, primitive: payload}
        case DASHBOARD_ACTION_TYPES.FETCH_ALL_SITE:
            return {...state, allSite: payload}
        case DASHBOARD_ACTION_TYPES.REGISTER_HANDLER_LISTENER:
            return {...state, mouseMoveListener: payload}
        case DASHBOARD_ACTION_TYPES.HANDLE_SHOW_DETAIL:
            return {...state, detailEntity: payload}
        case DASHBOARD_ACTION_TYPES.SAVE_ENTITY:
            let newObj = {...state.resultList}
            for (let i = 0; i < newObj.content.length; i++) {
                if (newObj.content[i].id === payload.id) {
                    newObj.content[i].saved = payload.saved;
                    break;
                }
            }
            return {...state, detailEntity: payload, resultList: newObj}
        case DASHBOARD_ACTION_TYPES.REGISTER_EVENT_LISTENER:
            return {...state, entityEventListener: payload}
        case DASHBOARD_ACTION_TYPES.HANDLE_SHOW_HIDE_FILTER_TOOLBAR:
            return {...state, toggleFilterBtn: !state.toggleFilterBtn}
        case DASHBOARD_ACTION_TYPES.HANDLE_CLOSE_FILTER_TOOLBAR:
            return {...state, toggleFilterBtn: false}
        case DASHBOARD_ACTION_TYPES.FETCH_RESULT_START:
            return {...state}
        case DASHBOARD_ACTION_TYPES.PUT_NETWORK:
            return {...state, networkList: payload};
        case DASHBOARD_ACTION_TYPES.PUT_RESULT:
            const {data} = payload
            return {...state, resultList: data, showAllCoverage: false}
        case DASHBOARD_ACTION_TYPES.REGISTER_VIEWER:
            return {...state, viewer: payload}
        case DASHBOARD_ACTION_TYPES.SHOW_ALL_COVERAGE:
            const {changeAllCoverage, ticked} = payload
            return {...state, showAllCoverage: ticked, resultList: changeAllCoverage}
        case DASHBOARD_ACTION_TYPES.SHOW_ALL_LABEL:
            const {changeAllLabel, istTicked} = payload
            return {...state, showAllLabel: istTicked, resultList: changeAllLabel}
        case DASHBOARD_ACTION_TYPES.SHOW_COVERAGE_BY_ID:
            let restrictShowAllCoverage = false; // initial value

            const {changeCoverageById, showCoverage} = payload;
            let newDetailEntity;

            const content = changeCoverageById.content

            if (!showCoverage) {
                restrictShowAllCoverage = false;
            } else {
                let allCheck = false;
                if (content.length > 1) {
                    for (let i = 0; i < content.length - 1; i++) {
                        if (content[i].coverageStatus && content[i + 1].coverageStatus) {
                            allCheck = true;
                        } else {
                            allCheck = false;
                            break;
                        }
                    }
                } else if (content.length === 1) {
                    allCheck = content[0].coverageStatus;
                }
                restrictShowAllCoverage = allCheck;
            }

            if (!!content && !!state.detailEntity) {
                for (let i = 0; i < content.length; i++) {
                    if (state.detailEntity.id === content[i].id) {
                        newDetailEntity = {...content[i]};
                        break;
                    }
                }
            }
            return {
                ...state,
                detailEntity: newDetailEntity,
                showAllCoverage: restrictShowAllCoverage,
                resultList: changeCoverageById
            }
        default :
            return state;
    }
}