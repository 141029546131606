import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { report } from "../../../utils/report/report";

import LambangTNI from "../../../assets/png/Lambang_TNI_AU.png";

const ReportMaintenance = ({
    title,
    data,
    action,
    condition,
    status,
    note,
    maintenanceType,
    task,
}) => {
    const [profile, setProfile] = useState([]);

    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem("profile"));
        setProfile(userProfile);
    }, []);

    const getDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        return `${dd} / ${mm} / ${yyyy}`;
    };

    const handlePdfGenerate = () => {
        // Format
        const doc = new jsPDF("potrait", "px", "a4", "false");

        // Template
        report.templateAsset(doc, 1, profile, LambangTNI, title);

        autoTable(doc, {
            theme: "grid",
            styles: {
                textColor: [0, 0, 0],
                fontSize: 8,
                overflow: "linebreak",
                cellWidth: 193,
            },
            margin: { left: 30 },
            startY: 150,
            didParseCell(data) {
                if (data.row.index % 2 === 0) {
                    data.cell.styles.textColor = [0, 0, 0];
                    data.cell.styles.fillColor = "#D3D3D3";
                }
                if (data.column.index === 0) {
                    data.cell.styles.fontStyle = "bold";
                }
                if (data.column.index === 1) {
                    data.cell.styles.cellPadding = {top: 3, right: 13, bottom: 3, left: 3};
                }
            },
            body: [
                ["Name", data?.name],
                ["Owner", data?.owner],
                ["Brand", data?.brand],
                ["Type", data?.type],
                ["Serial Number", data?.serialNo],
                [
                    "Maintenance",
                    `${maintenanceType.type} (${maintenanceType.valueType})`,
                ],
                ["Date / Time", getDate()],
                ["Action", action],
                ["Note", note],
                ["Condition Asset", condition],
                ["Maintenance Status", status],
            ],
        });
        
        if (task.length) {
            doc.setTextColor("#040F0F");
            doc.setFontSize(8);
            doc.setFont(undefined, "normal").text(210, 345, "Task Table");

            
            let pageNumber = 0;
            autoTable(doc, {
                theme: "grid",
                styles: {
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    overflow: "linebreak",
                    cellWidth: 62,
                },
                margin: { left: 30, bottom: 60 },
                startY: 350,
                didParseCell(data) {
                    if (data.row.index % 2 !== 0) {
                        data.cell.styles.textColor = [0, 0, 0];
                        data.cell.styles.fillColor = "#D3D3D3";
                    }
                    if (data.column.index === 1) {
                        data.cell.styles.cellPadding = {top: 3, right: 13, bottom: 3, left: 3};
                        data.cell.styles.cellWidth = 250;
                    }
                    if (data.column.index === 0) {
                        data.cell.styles.cellWidth = 15;
                    }
                    data.settings.margin.top = 150;
                },
                didDrawPage: (data) => {
                    if(data.pageCount >= 2) {
                        report.templateAsset(doc, data.pageCount, profile, LambangTNI, title);
                    }
                },
                head: [
                    ["No", "Task Name", "Action", "Status"],
                ],
                body: task.map((item) => {
                    pageNumber += 1;
                    return [
                        pageNumber,
                        item?.task?.name,
                        item?.completed,
                        item?.status,
                    ];
                }),
            });
        }

        report.savePdf(doc, title);
    };

    return (
        <button
            type="button"
            className="btn btn-primary text-white px-6"
            onClick={handlePdfGenerate}
        >
            Create Report
        </button>
    );
};

export default ReportMaintenance;
