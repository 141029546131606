export const MANNET_ACTION_TYPES = {
    MODIFY_ORIGINAL_PAYLOAD : "mannet/MODIFY_ORIGINAL_PAYLOAD",
    CLEAR_BILLBOARD : "mannet/CLEAR_BILLBOARD",
    CLEAR_STORAGE : "mannet/CLEAR_STORAGE",
    CLEAR_ERROR_MSESAGE : "mannet/CLEAR_ERROR_MSESAGE",
    RESPONSE_POINT : "mannet/RESPONSE_POINT",
    COPY_ORIGINAL_PAYLOAD : "mannet/COPY_ORIGINAL_PAYLOAD",
    CLEAR_NOTIFY : "mannet/CLEAR_NOTIFY",
    NOTIFY_RIGHT_PANNEL : "mannet/NOTIFY_RIGHT_PANNEL",
    MODIFY_BILLBOARD_AND_VLINE_DATA : "mannet/MODIFY_BILLBOARD_AND_VLINE_DATA",
    REGISTER_HORIZONTAL_LINE_DATASOURCES : "mannet/REGISTER_HORIZONTAL_LINE_DATASOURCES",
    REGISTER_BILLBOARD : "mannet/REGISTER_BILLBOARD",
    REGISTER_VERTICAL_LINE_DATESOURCES : "mannet/REGISTER_VERTICAL_LINE_DATESOURCES",
    REGISTER_VERTICAL_LINE : "mannet/REGISTER_VERTICAL_LINE",
    REGISTER_DBM_LABEL : "mannet/REGISTER_DBM_LABEL",
    REGISTER_HORIZONTAL_LINE : "mannet/REGISTER_HORIZONTAL_LINE",
    ADD_CZML_SOURCE : "mannet/ADD_CZML_SOURCE",
    FAILED_MESSAGE : "mannet/FAILED_MESSAGE",
    REGISTER_HANDLER_LISTENER : "mannet/REGISTER_HANDLER_LISTENER",
    SELECTED_ENTITY : "mannet/SELECTED_ENTITY",
    FETCH_MANNET_NETWORKS : "mannet/FETCH_MANNET_NETWORKS",
    FETCH_MANNET : "mannet/FETCH_MANNET",
    MODIFY_BILLBOARD_DATA : "mannet/MODIFY_BILLBOARD_DATA",
    CREATE_MANNET : "mannet/CREATE_MANNET",
    DELETE_MANNET : "mannet/DELETE_MANNET",
    UPDATE_MANNET : "mannet/UPDATE_MANNET",
    DRAW_PROGRESS_STATUS_REPORT : "mannet/LOAD_STATUS",
    TOTAL_PROGRESS : "mannet/TOTAL_PROGRESS",
    IS_LOADING : "mannet/IS_LOADING",
    PROGRESS_COUNT : "mannet/PROGRESS_COUNT",
    TERRAIN_HEIGHT : "mannet/TERRAIN_HEIGHT",
}

export const NOTIFY_ACTION_TYPES = {
    NS : "No Statement",
    CREATE_POINT : " Create Point",

}