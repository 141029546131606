import React, {useEffect, useRef, useState} from "react";
import ReactPaginate from "react-paginate";
import PlusIcon from "../../../assets/svgs/PlusIcon";

import {useNavigate} from "react-router-dom";

import CardAssets from "../../../components/card/CardAssets";
import HeaderPage from "../../../components/header/HeaderPage";
import Table from "../../../components/table/Table";
import {MapViewer} from "../../../components/map/MapViewer";
import {
    ALERT_ICON_FAILED,
    ALERT_ICON_SUCCESS,
    ALERT_TEXT_CREATE_FAILED,
    ALERT_TEXT_CREATE_SUCCESS,
    ALERT_TEXT_REMOVE_FAILED,
    ALERT_TEXT_REMOVE_SUCCESS,
    ALERT_TEXT_UPDATE_FAILED,
    ALERT_TEXT_UPDATE_SUCCESS,
    ALERT_TITLE_FAILED,
    ALERT_TITLE_SUCCESS,
    ASSET_TYPE,
    CONFIRMATION_ICON_REMOVE,
    CONFIRMATION_TEXT_REMOVE,
    CONFIRMATION_TITLE_REMOVE,
    STATUS_TYPE,
    USER_MABES
} from "../../../utils/constant/constants";
import {column, putDrawingData} from "../../../store/fo/fo.util";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDetailMultiAsset,
    selectRemoveStatus,
    selectSearchResponse,
    selectTotalElement,
    selectTotalPages,
    selectUpdateStatus,
    selectUploadedStatus
} from "../../../store/fo/fo.selector";
import {handleClearNotif, handleDeleteAsset, handleEditAsset, handleSearch} from "../../../store/fo/fo.action";
import {
    selectAssetToCheck,
    selectAssetToEdit,
    selectAssetToRemove,
    selectCurrentItem
} from "../../../store/global/global.selector";
import {handleClearStateGlobal, handleLoading} from "../../../store/global/global.action";
import {
    addMidLabel,
    addPolylineMoreThenTwoPoint,
    flyToIndnesia,
    removeImageryProviderForTerrain
} from "../../../utils/cesium/cesiumUtils";
import {registerViewer} from "../../../store/dashboard/dashboard.action";
import {generalAlert, generalConfirmation} from "../../../utils/notification/notification";
import SearchBox from "../../../components/search-box/search-box.component";
import ReportFO from "../../../components/report/asset/ReportFO";
import {selectCurrentUser} from "../../../store/user/user.selector";
import {SceneMode} from "cesium";


const FODistribution = () => {
    const ref = useRef(null); // viewer

    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchTextOnChange, setSearchTextOnChange] = useState("");
    const [searchType, setSearchType] = useState("");
    const [page, setPage] = useState(0);
    const [checkBoxData, setCheckBoxData] = useState([]);
    const [drawingData, setDrawingData] = useState([]); // [ {id: <assetId>, line: [<entityLine>], label: [<entityLabel>], pathDtos: [<pathDtos>]} ]
    const [deletedId, setDeletedId] = useState(0);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchResponse = useSelector(selectSearchResponse);
    const totalPages = useSelector(selectTotalPages);
    const totalElement = useSelector(selectTotalElement);
    const assetToRemove = useSelector(selectAssetToRemove);
    const assetToEdit = useSelector(selectAssetToEdit);
    const assetToCheck = useSelector(selectAssetToCheck);
    const currentItem = useSelector(selectCurrentItem);
    const currentUser = useSelector(selectCurrentUser);
    const detailMultiAsset = useSelector(selectDetailMultiAsset);
    const updateStatus = useSelector(selectUpdateStatus);
    const removeStatus = useSelector(selectRemoveStatus);
    const uploadedFoStatus = useSelector(selectUploadedStatus);


    useEffect(() => {
        const viewer = ref.current.cesiumElement;
        dispatch(registerViewer(viewer));
        removeImageryProviderForTerrain(viewer);
        viewer.scene.mode = SceneMode.COLUMBUS_VIEW;
        flyToIndnesia(viewer, true)
        dispatch(handleClearStateGlobal());
        dispatch(handleLoading(true));
        dispatch(handleSearch("", "name", 10, 0));
        return () => {
            dispatch(handleClearStateGlobal());
            dispatch(handleClearNotif());
            dispatch(handleLoading(false));
            setSearchText("");
            setData([]);
            setPage(0);
            setCheckBoxData([]);
            drawingData.forEach(e => {
                viewer.entities.remove(e.line);
                viewer.entities.remove(e.label());
            })
            setDrawingData([]);
            setDeletedId(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        // if (searchResponse) {
        const cpSearchResponse = JSON.parse(JSON.stringify(searchResponse));

        // copy data when checkStatus = true
        const checkedStatusIdTemp = []
        const checkedStatusDataTemp = []
        const cpCbData = [...checkBoxData];
        cpCbData.forEach(d => {
            if (d.checkStatus) {
                checkedStatusIdTemp.push(d.id);
                checkedStatusDataTemp.push({data: d.data})
            }
        })

        // new Data
        const cbDataTemp = [];

        if (cpSearchResponse) {
            cpSearchResponse.forEach(d => {
                cbDataTemp.push({id: d.id, checkStatus: false, data: d}) // set default check to false
            });
        }
        

        let resultArr;

        if (checkedStatusIdTemp.length) {
            for (let i = 0; i < cbDataTemp.length; i++) {
                if (checkedStatusIdTemp.includes(cbDataTemp[i].id)) {
                    cpSearchResponse[i].isChecked = true;
                    cbDataTemp[i].checkStatus = true;
                    const indexOf = checkedStatusIdTemp.indexOf(cbDataTemp[i].id);
                    checkedStatusIdTemp.splice(indexOf, 1)
                    checkedStatusDataTemp.splice(indexOf, 1)
                }
            }
            if (checkedStatusIdTemp.length) {
                for (let i = 0; i < checkedStatusIdTemp.length; i++) {
                    const d = checkedStatusIdTemp[i];
                    const dt = checkedStatusDataTemp[i];
                    cbDataTemp.push({id: d, checkStatus: true, data : {...dt.data}});
                }
            }

            resultArr = [...cbDataTemp];
            
        } else {
            resultArr = [...cbDataTemp];
        }

        setCheckBoxData(resultArr);

        setData(cpSearchResponse);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResponse])


    /**
     * Check FO DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.FIBER_OPTIC === assetToCheck && currentItem && detailMultiAsset) {
            const cpSearchResponse = JSON.parse(JSON.stringify(searchResponse));
            cpSearchResponse.forEach(d => {
                if (d.id === currentItem.id) {
                    d.isChecked = !currentItem.isChecked;
                }
            });


            const viewer = ref.current.cesiumElement;
            const cpCheckBoxData = [...checkBoxData];
            const cpDetailMulti = JSON.parse(JSON.stringify(detailMultiAsset));

            let pathDtos = [];
            let id = -1;
            let line = null;
            let label = null;

            const di = cpDetailMulti.findIndex(e => e.distributionAssetsDto.id === currentItem.id);
            const ci = cpCheckBoxData.findIndex(e => e.id === currentItem.id);

            if (di !== -1 && ci !== -1) {
                id = currentItem.id;
                // handle selected data
                cpCheckBoxData[ci].checkStatus = !cpCheckBoxData[ci].checkStatus;

                if (cpCheckBoxData[ci].checkStatus) { // Ticked
                    if (cpDetailMulti[di].pathDtos.length > 1) {
                        handleTicked(pathDtos, cpDetailMulti, di, line, viewer, label, id);
                    }
                } else { // Unticked
                    handleUnTicked(id, viewer);
                }
            }
            setCheckBoxData(cpCheckBoxData);
            cpCheckBoxData.forEach(d => {
                if (d.checkStatus) {
                    const index = cpSearchResponse.findIndex(dsr => dsr.id === d.id);
                    if (cpSearchResponse[index]) {
                        cpSearchResponse[index].isChecked = true;
                    }
                }
            })
            setData(cpSearchResponse);
            dispatch(handleClearStateGlobal());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToCheck]);

    useEffect(() => {
        if (uploadedFoStatus) {
            if (STATUS_TYPE.SUCCESS === uploadedFoStatus) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_SUCCESS, ALERT_TEXT_CREATE_SUCCESS, ALERT_ICON_SUCCESS, () => dispatch(handleClearNotif()))
            } else if (STATUS_TYPE.FAILED === uploadedFoStatus) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_FAILED, ALERT_TEXT_CREATE_FAILED, ALERT_ICON_FAILED, () => dispatch(handleClearNotif()))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedFoStatus])

    useEffect(() => {
        if (updateStatus) {
            if (STATUS_TYPE.SUCCESS === updateStatus) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_SUCCESS, ALERT_TEXT_UPDATE_SUCCESS, ALERT_ICON_SUCCESS, () => dispatch(handleClearNotif()))
            } else if (STATUS_TYPE.FAILED === updateStatus) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_FAILED, ALERT_TEXT_UPDATE_FAILED, ALERT_ICON_FAILED, () => dispatch(handleClearNotif()))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStatus])

    useEffect(() => {
        if (removeStatus) {
            if (STATUS_TYPE.SUCCESS === removeStatus) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_SUCCESS, ALERT_TEXT_REMOVE_SUCCESS, ALERT_ICON_SUCCESS, afterRemove)
            } else if (STATUS_TYPE.FAILED === removeStatus) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_FAILED, ALERT_TEXT_REMOVE_FAILED, ALERT_ICON_FAILED, () => dispatch(handleClearNotif()))
            }
            if (data && data.length) {
                dispatch(handleSearch(searchText, searchType, 10, page));
            } else {
                if (page) {
                    dispatch(handleSearch(searchText, searchType, 10, page - 1));
                    setPage(prevPage => prevPage - 1);
                } else {
                    dispatch(handleSearch(searchText, searchType, 10, 0));
                    setPage(0);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeStatus])

    /**
     * EDIT FO DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.FIBER_OPTIC === assetToEdit && currentItem) {

            if (currentUser?.siteName !== currentItem?.siteName) return;

            dispatch(handleEditAsset(currentItem, true));
            navigate("/assetdata/fo/addroute");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToEdit]);

    /**
     * REMOVE DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.FIBER_OPTIC === assetToRemove && currentItem) {
            if (currentUser?.siteName !== currentItem?.siteName) return;
            
            generalConfirmation(
                CONFIRMATION_TITLE_REMOVE,
                CONFIRMATION_TEXT_REMOVE(currentItem.name),
                CONFIRMATION_ICON_REMOVE,
                () => {
                    dispatch(handleLoading(true));
                    dispatch(handleDeleteAsset(currentItem.id, page));
                    setDeletedId(currentItem.id);
                },
                () => dispatch(handleClearStateGlobal()));
                
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [assetToRemove]);

    const afterRemove = () => {
        dispatch(handleClearNotif());
        if (deletedId !== 0) {
            handleUnTicked(deletedId, ref.current.cesiumElement);
        }
        setDeletedId(0);
    }

    const handleTicked = (pathDtos, cpDetailMulti, di, line, viewer, label, id) => {
        pathDtos = cpDetailMulti[di].pathDtos;

        let points = [];
        pathDtos.forEach(e => points.push(e.lon, e.lat));

        line = addPolylineMoreThenTwoPoint(viewer, points);
        label = addMidLabel(viewer,
            {longitude: points[0], latitude: points[1]},
            {longitude: points[2], latitude: points[3]},
            `${cpDetailMulti[di].distributionAssetsDto.name} - ${cpDetailMulti[di].routePathDto.totalCableLength} meter`);
        setDrawingData(putDrawingData(drawingData, id, pathDtos, line, label));
    };

    const handleUnTicked = (id, viewer) => {
        const cpDrawingData = [...drawingData];
        const data = cpDrawingData.find(e => e.id === id);
        if (data) {
            viewer.entities.remove(data.line);
            viewer.entities.remove(data.label);
        }
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    };

    const onSearch = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));

        setSearchText(searchTextOnChange);

        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value.toLowerCase();

        dispatch(handleSearch(searchText, searchType, 10, 0));

        setPage(0);
    }

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        dispatch(handleSearch(searchText, searchType, 10, e.selected));
        setPage(e.selected);
    };

    const handleOpenAddRoute = () => {
        navigate("/assetdata/fo/addroute");
    }

    const filterCheckedData = checkBoxData.filter(data => data.data && data.checkStatus)

    return (
        <div className="bg-tertiary min-h-screen px-20 py-4">
            <HeaderPage title="Fiber Optic" icon="tower"/>

            <div className="mt-10">
                <CardAssets asset="Number of Routes Fiber Optic" item={totalElement ? totalElement : 0} icon="fo"/>
            </div>

            <div className="pb-20 mx-auto mt-24">
                <div className="flex justify-between">
                    <form className="flex mb-6" onSubmit={onSearch}>
                        <select
                            name="filter"
                            className="select select-bordered w-[200px] rounded-3xl h-[30px]"
                            onChange={(e) => setSearchType(e.target.value)}
                        >
                            <option selected disabled>Select filter</option>
                            <option value="name">Filter by Name</option>
                            {currentUser?.siteName === USER_MABES && (
                                <option value="owner">Filter by Owner</option>
                            )}
                        </select>
                        <SearchBox
                            name="searchText"
                            value={searchTextOnChange}
                            placeholder="Search Fiber Optic"
                            className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                            onChangeHandler={onSearchChange}
                        />
                        <span className="flex justify-center items-center text-white text-lg px-5 mr-auto">
                            <button
                                type="submit"
                                className="btn btn-primary rounded-full text-white text-xs px-7"
                            >
                                Search
                            </button>
                        </span>
                    </form>

                    <div className="flex justify-center items-center">
                        <button
                            type="button"
                            className="flex justify-center items-center text-white gap-x-2 text-lg cursor-pointer"
                            onClick={handleOpenAddRoute}
                        >
                            <PlusIcon width={18} height={16}/>
                            Add Route
                        </button>
                    </div>
                </div>

                {data && data.length > 0 ? (
                    <div>
                        <Table
                            data={data}
                            column={column}
                            assetType={ASSET_TYPE.FIBER_OPTIC}
                        />
                    </div>
                ) : (
                    <p className="flex flex-col text-white text-xl text-center pt-10">
                        Data is empty
                    </p>
                )}

                <div className="text-white flex flex-col">
                    <div className="flex justify-center  my-6 self-end h-14 items-center">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={totalPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            forcePage={page}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            useBrowserRecommendedResolution
                        />
                    </div>
                </div>

                <ReportFO data={filterCheckedData} />

                <div className="mt-10 border-solid border-8 border-white rounded-lg">
                    <MapViewer ref={ref} isScheneModePickerActive={false}>
                    </MapViewer>
                </div>

            </div>
        </div>
    );
};

export default FODistribution;
