import { STATUS_TYPE } from "../../utils/constant/constants";
import { FREQUENCY_ALLOCATION_ACTION_TYPES } from "./frequencyallocation.types";

const INITIAL_STATE = {
    frequencyAllocations: null, // {radio:{content:[]}}
    uploadFrequencyAllocationStatus: STATUS_TYPE.NO_STATEMENT,
    updateStatus: STATUS_TYPE.NO_STATEMENT,
    removeStatus: STATUS_TYPE.NO_STATEMENT,
    selectedSiteId: "",
    handlerListener: null,
    siteEntities: null,
    circleEntity: null,
}

export const frequencyAllocationReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case FREQUENCY_ALLOCATION_ACTION_TYPES.CIRCLE_ENTITY:
            return {...state, circleEntity: payload};
        case FREQUENCY_ALLOCATION_ACTION_TYPES.SITE_ENTITIES:
            return {...state, siteEntities: payload};
        case FREQUENCY_ALLOCATION_ACTION_TYPES.SELECTED_SITE_ID:
            return {...state, selectedSiteId: payload};
        case FREQUENCY_ALLOCATION_ACTION_TYPES.REGISTER_HANDLER_LISTENER:
            return {...state, handlerListener: payload};
        case FREQUENCY_ALLOCATION_ACTION_TYPES.SEARCH:
            return {...state, frequencyAllocations: payload};
        case FREQUENCY_ALLOCATION_ACTION_TYPES.UPLOAD_FREQUENCY_ALLOCATION_STATUS:
            return {...state, uploadFrequencyAllocationStatus: payload}
        case FREQUENCY_ALLOCATION_ACTION_TYPES.UPDATED:
            return {...state, updateStatus: payload}
        case FREQUENCY_ALLOCATION_ACTION_TYPES.REMOVED:
            return {...state, removeStatus: payload}
        case FREQUENCY_ALLOCATION_ACTION_TYPES.CLEAR_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
}