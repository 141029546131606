import {createSelector} from "reselect";

const selectMannetReducer = (state) => state.mannetReducer;

export const selectVLineDataSources = createSelector(
    [selectMannetReducer],
    (data) => data.vLineDataSource
);
export const selectDbmLabelData= createSelector(
    [selectMannetReducer],
    (data) => data.dbmLabelData
);
export const selectHLineDataSources = createSelector(
    [selectMannetReducer],
    (data) => data.hLineDataSources
);
export const selectBillboardData = createSelector(
    [selectMannetReducer],
    (data) => data.billboardData
);
export const selectVerticalLineData = createSelector(
    [selectMannetReducer],
    (data) => data.verticalLineData
);

export const selectNotifyRightPanel = createSelector(
    [selectMannetReducer],
    (data) => data.notifyRightPannel
)
export const selectEntityListener = createSelector(
    [selectMannetReducer],
    (data) => data.selectEntityListener
)
export const selectedEntityId = createSelector(
    [selectMannetReducer],
    (data) => data.selectedEntityId
)

export const selectFailedMessage = createSelector(
    [selectMannetReducer],
    (data) => {
        if (data.failedMessage) {
            return data.failedMessage
        } else {
            return null;
        }
    }
)

export const selectChartData = createSelector(
    [selectMannetReducer],
    (data) => data.chartData
)

export const selectResponsePoints = createSelector(
    [selectMannetReducer],
    (data) => data.responsePoints
)
export const originalPayload = createSelector(
    [selectMannetReducer],
    (data) => data.originalPayloadArray
)
export const selectMannets = createSelector(
    [selectMannetReducer],
    (data) => data.mannets
)
export const selectDrawingProgressStatusReport = createSelector(
    [selectMannetReducer],
    (data) => data.drawingProgressStatusReport
)
export const selectMannetNetworks = createSelector(
    [selectMannetReducer],
    (state) => {
        if (state.mannetNetworks && state.mannetNetworks.content) {
            return state.mannetNetworks.content.map(el => {
                return Object.assign(
                    {},
                    {
                        label: el.network,
                        value: el.network,
                    }
                );
            })
        } else {
            return null;
        }
    }
)

export const selectTotalProgress = createSelector(
    [selectMannetReducer],
    (state) => state.totalProgress
);
export const selectIsLoading = createSelector(
    [selectMannetReducer],
    (state) => state.isLoading
);
export const selectProgressCount = createSelector(
    [selectMannetReducer],
    (state) => state.progressCount
);
export const selectTerrainHeight = createSelector(
    [selectMannetReducer],
    (state) => state.terrainHeight
);