export const CIVIL_ACTION_TYPES = {
    GET_STATUS : 'civil/GET_STATUS',
    GET_ALL_CIVIL : 'civil/GET_ALL_CIVIL',
    GET_CATEGORY : 'civil/GET_CATEGORY',
    GET_BRAND : 'civil/GET_BRAND',
    GET_TYPE : 'civil/GET_TYPE',
    GET_SITE : 'civil/GET_SITE',
    CLEAR_DATA : 'civil/CLEAR_DATA',
    CHANGE_PAGE : 'civil/CHANGE_PAGE',
    UPLOAD_CIVIL_STATUS : 'civil/UPLOAD_CIVIL_STATUS',
    UPLOAD_IMAGE_STATUS : 'civil/UPLOAD_IMAGE_STATUS',
    UPLOAD_FILE_STATUS : 'civil/UPLOAD_FILE_STATUS',
    UPLOAD_IMAGE : 'civil/UPLOAD_IMAGE',
    UPLOAD_FILE : 'civil/UPLOAD_FILE',
    IS_LOADING : 'civil/IS_LOADING',
    REMOVED : 'civil/REMOVED',
    UPDATED : 'civil/UPDATED',
    SEARCH : 'civil/SEARCH',
    DETAIL_CIVIL : 'civil/DETAIL_CIVIL',
    CLEAR_DETAIL_CIVIL : 'civil/CLEAR_DETAIL_CIVIL',
}