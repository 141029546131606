export const TEMPLATE_ACTION_TYPES = {
    CREATE_TEMPLATE : `template/CREATE_TEMPLATE`,
    CLEAR_ALL_DATA : `template/CLEAR_ALL_DATA`,
    CREATE_NEW_TASK : `template/CREATE_NEW_TASK`,
    SEARCH_MASTER_TAMPLATE : `template/SEARCH_MASTER_TEMPLATE`,
    GET_ALL_TASK_BY_TEMPALTE_ID :`template/GET_ALL_TASK_BY_TEMPALTE_ID`,
    GET_TASK_BY_TEMPALTE_ID :`template/GET_TASK_BY_TEMPALTE_ID`,
    GET_ALL_MASTER_TEMPLATE :`template/GET_ALL_MASTER_TEMPLATE`,
    CLEAR_ALL_MASTER_TEMPLATE :`template/CLEAR_ALL_MASTER_TEMPLATE`,
    CLEAR_TASK_BY_TEMPLATE_ID :`template/CLEAR_TASK_BY_TEMPLATE_ID`,
    REMOVE_TEMPLATE :`template/REMOVE_TEMPLATE`,
    REMOVE_TASK :`template/REMOVE_TASK`,
    ACTION_CREATE_STATUS_TEMPLATE : "template/ACTION_CREATE_STATUS_TEMPLATE",
    ACTION_UPDATE_STATUS_TEMPLATE : "template/ACTION_UPDATE_STATUS_TEMPLATE",
    ACTION_REMOVE_STATUS_TEMPLATE : "template/ACTION_REMOVE_STATUS_TEMPLATE",
}

export const EMPTY_STRING = "";
export const NO_STATEMENT = "No Statement";
export const SUCCESS = "SUCCESS";
export const FAILED = "FAILED";