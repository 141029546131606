import * as React from "react";

const GroundToAirIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1779 1779"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="#FEFEFE"
      strokeWidth={4.443}
      d="M1079 524c75 55 100 152 67 234-5-68-40-135-101-180-67-49-151-59-222-35 67-67 176-77 256-19z"
    />
    <circle
      fill="currentColor"
      stroke="#FEFEFE"
      strokeWidth={4.443}
      cx={889}
      cy={786}
      r={106}
    />
    <path
      fill="currentColor"
      stroke="#FEFEFE"
      strokeWidth={4.443}
      d="M1188 376c120 87 161 243 107 375-7-110-63-217-161-288-107-79-241-96-355-56 108-108 282-124 409-31z"
    />
    <path
      fill="currentColor"
      stroke="#FEFEFE"
      strokeWidth={4.443}
      d="M1318 204c150 109 201 304 134 469-9-138-79-271-201-361-135-98-302-119-445-70 136-135 353-155 512-38zM701 1047c-75-55-100-153-67-235 5 69 40 136 101 180 67 49 150 60 222 35-68 68-176 78-256 20z"
    />
    <path
      fill="currentColor"
      stroke="#FEFEFE"
      strokeWidth={4.443}
      d="M591 1195c-120-88-160-244-107-375 8 109 64 216 161 288 108 78 241 95 356 56-109 108-282 124-410 31z"
    />
    <path
      fill="currentColor"
      stroke="#FEFEFE"
      strokeWidth={4.443}
      d="M461 1367c-150-110-200-305-134-469 10 137 79 271 202 360 134 99 301 119 445 70-136 135-353 155-513 39z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.185}
      d="M193 1h1393c106 0 192 86 192 192v1393c0 106-86 192-192 192H193c-106 0-192-86-192-192V193C1 87 87 1 193 1zm48 60h1297c99 0 179 81 179 180v1297c0 99-80 179-179 179H241c-99 0-180-80-180-179V241c0-99 81-180 180-180z"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={1.185}
      d="M1318 204c150 109 201 304 134 469-9-138-79-271-201-361-135-98-302-119-445-70 136-135 353-155 512-38zm-130 172c120 87 161 243 107 375-7-110-63-217-161-288-107-79-241-96-355-56 108-108 282-124 409-31zM823 543c67-67 176-77 256-19 75 55 100 152 67 234-5-68-40-135-101-180-67-49-151-59-222-35zm66 137c59 0 107 47 107 106s-48 107-107 107c-58 0-106-48-106-107s48-106 106-106zM634 812c5 69 40 136 101 180 67 49 150 60 222 35-68 68-176 78-256 20-75-55-100-153-67-235zm-150 8c8 109 64 216 161 288 108 78 241 95 356 56-109 108-282 124-410 31-120-88-160-244-107-375zm45 438c134 99 301 119 445 70-136 135-353 155-513 39-150-110-200-305-134-469 10 137 79 271 202 360z"
    />
  </svg>
);

export default GroundToAirIcon;
