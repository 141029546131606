import React, {createRef, useEffect} from "react";
import FilterIcon from "../../assets/png/whitetower.png";
import {useDispatch, useSelector} from "react-redux";
import {
    selectIsWhiteToggleActive,
    selectTowerDistributionData,
} from "../../store/tower/tower.selector";
import {handleWhiteTower, handleWhiteTowerToggle} from "../../store/tower/tower.action";
import {selectViewer} from "../../store/dashboard/dashboard.selector";


const WhiteTower = () => {
    const ref = createRef();
    const dispatch = useDispatch();
    const towerDistribution = useSelector(selectTowerDistributionData);
    const viewer = useSelector(selectViewer);
    const isToggleActive = useSelector(selectIsWhiteToggleActive);

    useEffect(() => {
        dispatch(handleWhiteTowerToggle(false));

        ref.current.classList.add("cesium-button", "cesium-toolbar-button");
        const toolbar = document.querySelector("div.cesium-viewer-toolbar");
        const modeButton = document.querySelector("span.cesium-sceneModePicker-wrapper");
        toolbar.insertBefore(ref.current, modeButton);

        return () => {
            dispatch(handleWhiteTowerToggle(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClick = async () => {
        dispatch(handleWhiteTowerToggle(!isToggleActive));
        dispatch(handleWhiteTower(viewer, isToggleActive, towerDistribution));
    }
    return (
        <>
            <div id="whitetower"
                 style={{background: isToggleActive ? 'white' : '#303336'}} ref={ref}
                 onClick={handleClick}>
                <img src={FilterIcon} alt="whitetower" width={24} height={20} className="h-[28px] ml-[2.5px]"/>
            </div>
        </>

    );
};

export default WhiteTower
