import React, {createRef, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Cartesian3, Cartographic, defined, ScreenSpaceEventHandler, ScreenSpaceEventType} from "cesium";

// Redux
import {selectViewer} from "../../store/dashboard/dashboard.selector";

// Asset
import {CoverageRulerIcon} from "../../assets/svgs";
import {selectDataEntityFromOtherPage, selectRuleInformation} from "../../store/area/area.selector";
import {
    handleAddEntityIdInArray,
    handleClearEntityIdArray, handleRemoveLastIndexInEntityArr,
    handleResetReducer,
    handleRulerButton,
    handleStoreName
} from "../../store/coverageRuler/coverageRuler.action";
import {selectEntityId, selectEntityIdArray, selectRulerToggle} from "../../store/coverageRuler/coverageRuler.selector";
import {createPolylinePointAndLabel, getDistanceString} from "../../utils/cesium/cesiumUtils";

export const CoverageRuler = () => {
    const ref = createRef();

    const dispatch = useDispatch();

    const isRulerActive = useSelector(selectRulerToggle);
    const viewer = useSelector(selectViewer);
    const rulerInformation = useSelector(selectRuleInformation);
    const dataEntityFromDashboard = useSelector(selectDataEntityFromOtherPage);
    const entityId = useSelector(selectEntityId);
    const entityArray = useSelector(selectEntityIdArray);

    // const [rulerToggle, setRulerToggle] = useState(false);
    const [oldDataDashboardId, setOldDataDashboardId] = useState("");
    const [changeEntity, setChangeEntity] = useState(false);
    const [handler, setHandler] = useState(null);
    const [notif, setNotif] = useState(false);

    useEffect(() => {
        ref.current.classList.add("cesium-button", "cesium-toolbar-button");
        const toolbar = document.querySelector("div.cesium-viewer-toolbar");
        const modeButton = document.querySelector("span.cesium-sceneModePicker-wrapper");
        toolbar.insertBefore(ref.current, modeButton);

        return () => {
            clearData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const handlerClick = new ScreenSpaceEventHandler(viewer.scene.canvas);
        if (isRulerActive) {
            handlerClick.setInputAction(eventListener, ScreenSpaceEventType.LEFT_CLICK);
            setHandler(handlerClick);
        } else {
            clearData();
        }

        return () => {
            handlerClick.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRulerActive, changeEntity])

    useEffect(() => {
        if (dataEntityFromDashboard) {
            if (dataEntityFromDashboard.data.name) {
                dispatch(handleStoreName(dataEntityFromDashboard.data.name)); // get selected name for coverage ruler
            }
            let id = dataEntityFromDashboard.id;
            if (oldDataDashboardId !== id) {
                setChangeEntity(true);
                setOldDataDashboardId(id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataEntityFromDashboard]);

    useEffect(() => {
        if (notif) {
            if (changeEntity) {
                if (entityArray && entityArray.length) {
                    const byId = viewer.entities.getById(`${entityArray[entityArray.length - 1].id}ruler`);
                    if (byId) {
                        viewer.entities.remove(byId);
                    }
                    dispatch(handleRemoveLastIndexInEntityArr());
                }
                setChangeEntity(false);
                if (handler) {
                    handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
                    setHandler(null);
                }
            }
            setNotif(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notif])

    useEffect(() => {
        if (
            rulerInformation && rulerInformation.latitude && rulerInformation.longitude && rulerInformation.bearing &&
            dataEntityFromDashboard && dataEntityFromDashboard.data && dataEntityFromDashboard.data.transmitter &&
            entityId) {

            const cartesian = Cartesian3.fromDegrees(entityId.lon, entityId.lat, entityId.alt);
            const transmitter = dataEntityFromDashboard.data.transmitter;
            const cartesianDegreesArray = Cartesian3.fromDegreesArray([
                entityId.lon, entityId.lat,
                transmitter.lon, transmitter.lat,
            ])

            const text = getDistanceString(
                {longitude: entityId.lon, latitude: entityId.lat},
                {longitude: transmitter.lon, latitude: transmitter.lat}
            )
            let result = "";
            const latString = `Lat : ${rulerInformation.latitude}`;
            const lonString = `Lon: ${rulerInformation.longitude}`;
            const bearing = `Bearing : ${rulerInformation.bearing}`
            const distance = `Distance : ${text}`
            result = result.concat(latString, "\n", lonString, "\n", bearing, "\n", distance);
            const byId = viewer.entities.getById(`${entityId.id}ruler`);
            if (!byId) {
                createPolylinePointAndLabel(viewer, entityId.id, cartesian, cartesianDegreesArray, result);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId])

    const eventListener = (event) => {
        const scene = viewer.scene;
        const ray = viewer.camera.getPickRay(event.position);
        const mousePosition = scene.globe.pick(ray, scene);

        if (defined(mousePosition)) {
            const cartographic = Cartographic.fromCartesian(mousePosition);
            const lat = parseFloat((cartographic.latitude * 180 / Math.PI).toFixed(6));
            const lon = parseFloat((cartographic.longitude * 180 / Math.PI).toFixed(6));
            let alt = cartographic.height;
            if (viewer.baseLayerPicker.viewModel.selectedTerrain.name !== `Cesium World Terrain`) {
                alt = 0;
            }
            dispatch(handleAddEntityIdInArray({lat, lon, alt}));
            setNotif(true);
        }

    }


    const clearData = () => {
        if (entityArray && entityArray.length) {
            entityArray.forEach(entity => {
                const id = `${entity.id}ruler`
                const byId = viewer.entities.getById(id);
                if (byId) {
                    viewer.entities.remove(byId);
                }
            })
        }
        // setRulerToggle(false);
        setChangeEntity(false);
        setOldDataDashboardId("")
        dispatch(handleRulerButton(false));
        dispatch(handleResetReducer());
        dispatch(handleClearEntityIdArray());
        if (handler) {
            handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
            setHandler(null);
        }
    };

    const handleRulerBtn = () => {
        let rulerToggleTemp = !isRulerActive;
        dispatch(handleRulerButton(rulerToggleTemp));
    }

    return (
        <>
            <div
                id="ruler"
                style={{background: !isRulerActive ? '#303336' : 'white'}} ref={ref}
                onClick={handleRulerBtn}
            >
                <div className="">
                    <CoverageRulerIcon style={{stroke: isRulerActive ? '#303336' : '#fff'}}/>
                </div>
            </div>
            {" "}
        </>
    );
};
