/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ChartOperation from "../../components/chart/ChartOperation";
import HeaderPage from "../../components/header/HeaderPage";
import Table from "../../components/table/Table";
import {useDispatch, useSelector} from "react-redux";
import {
    handleCurrentSite,
    handleGetOPDataByIdAndDate,
    handleNotifPageNumber
} from "../../store/op/op.action";
import {
    selectActualDateResponse,
    selectAnOpData,
    selectCurrentSite,
    selectDataTableAct,
    selectDataTableSim,
    selectDistanceActualLabel,
    selectDistanceSimulationLabel,
    selectHeaderSim,
    selectHighLabel
} from "../../store/op/op.selector";
import {ASSET_TYPE} from "../../utils/constant/constants";
import ReportOperation from "../../components/report/analysis/ReportOperation";

const OperationDataDetail = () => {
    let ref = useRef(null);

    const [chartLabel, setChartLabel] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [filterDate, setFilterDate] = useState("");

    const dispatch = useDispatch();

    const id = useParams();
    const anOpData = useSelector(selectAnOpData);
    const heighLabel = useSelector(selectHighLabel);
    const distanceSimLabel = useSelector(selectDistanceSimulationLabel);
    const dataTableSim = useSelector(selectDataTableSim);
    const headerColSim = useSelector(selectHeaderSim);
    const actualSimLabel = useSelector(selectDistanceActualLabel);
    const dataTableAct = useSelector(selectDataTableAct);
    const actualDateResponse = useSelector(selectActualDateResponse);
    const currentSite = useSelector(selectCurrentSite);

    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            dispatch(handleCurrentSite(""));
            setFilterDate("");
        }
    }, [])

    const dataReport = anOpData?.simulationDtos[0].request;

    useEffect(() => {
        if (anOpData) {
            setChartLabel(heighLabel)
            let dataArr = []
            if (distanceSimLabel) {
                dataArr.push(distanceSimLabel)
            }
            if (actualSimLabel) {
                dataArr.push(actualSimLabel)
            }
            setChartData(dataArr)
        }
    }, [anOpData])

    useEffect(()=> {
        if (actualDateResponse) {
            setFilterDate(actualDateResponse[actualDateResponse.length - 1]);
        }
    }, [actualDateResponse])

    const handleFilteredDate = (e) => {
        setFilterDate(e.target.value);
        dispatch(handleGetOPDataByIdAndDate(id.id, e.target.value));
    }

    const downloadChart = useCallback(() => {
        const chartPNG = ref.current.toBase64Image();
        return chartPNG;
    }, []);

    return (
        <div className="bg-tertiary min-h-screen px-16 py-4 pb-20">
            <HeaderPage title={anOpData?.name}/>

            <div
                className="flex gap-x-3 items-center text-white text-2xl w-fit cursor-pointer"
                onClick={() => {
                    dispatch(handleNotifPageNumber(true));
                    navigate(-1)
                }}
            >
                <button
                    type="button"
                    className="btn btn-sm btn-circle"
                >
                    &#10094;
                </button>
                <span>Back</span>
            </div>

            <ReportOperation 
                title={anOpData?.name}
                date={filterDate}
                parameterData={dataReport}
                dataSim={dataTableSim}
                dataAct={dataTableAct}
                chartImage={downloadChart}
                currentSite={currentSite}
            />

            <div className="bg-[#F8F8F8] w-full rounded-lg pr-10 pl-5 py-5 mt-10">
                {chartLabel &&
                    chartData &&
                    chartLabel.length > 0 &&
                    chartData.length > 0 && (
                        <ChartOperation ref={ref} chartLabel={chartLabel} chartData={chartData} height={100}/>
                )}
            </div>

            <div className="flex mt-10 gap-x-16">
                {dataTableSim && (
                    <div className="w-[50%]">
                        <div className="flex justify-between mb-5">
                            <p className="text-white px-3 font-semibold self-center">Simulation Data</p>

                            <select
                                name="filter"
                                className="select select-bordered select-sm w-[200px] rounded-3xl"
                                value={filterDate}
                                onChange={handleFilteredDate}
                            >
                                {actualDateResponse ? actualDateResponse.map((d, i) => (
                                    <option disabled={actualDateResponse.length === 1}
                                            key={i}
                                            value={d}>{d}</option>
                                )) : (
                                    <option disabled>Actual data is empty</option>
                                )}
                            </select>
                        </div>
                        <div className="max-h-[368px] overflow-y-auto">
                            <Table
                                data={dataTableSim}
                                column={headerColSim}
                                assetType={ASSET_TYPE.OP_DETAIL}
                                action={false}
                            />
                        </div>
                    </div>
                )}

                {dataTableAct && (
                    <div className="w-[50%]">
                        <div className="flex items-center h-[31px] mb-5">
                            <p className="text-white px-3 font-semibold">Actual Mission</p>
                        </div>
                        <div className="max-h-[368px] overflow-y-auto">
                            <Table
                                data={dataTableAct}
                                column={headerColSim}
                                assetType={ASSET_TYPE.OP_DETAIL}
                                action={false}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default OperationDataDetail;