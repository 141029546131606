import Swal from "sweetalert2";

export const generalAlert = (title, text, icon, clear) => {
    Swal.fire({
        title: title,
        html: text,
        icon: icon,
        backdrop: false,
        showClass: {
            popup: "scale-in-center",
        },
        hideClass: {
            popup: "",
        },
        showCancelButton: false,
        confirmButtonColor: "#031952",
        confirmButtonText: "<p class='font-semibold'>Okay</p>",
    }).then(() => {
        if (clear) {
            clear();
        }
    });
};

export const generalConfirmation = (title, text, icon, confirmation, clear) => {
    Swal.fire({
        title: title,
        html: text,
        icon: icon,
        showClass: {
            backdrop: "swal2-noanimation",
            popup: "scale-in-center",
        },
        hideClass: {
            popup: "",
        },
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#000153",
        cancelButtonColor: "#E3DAC9",
        confirmButtonText: "<p class='font-semibold'>Yes, Remove!</p>",
        cancelButtonText: "<p class='font-semibold text-black'>Cancel</p>",
        iconColor: "#FF3A30",
    }).then((result) => {
        if (result.isConfirmed) {
            if (confirmation) {
                confirmation()
            }
        }
        if (clear) {
            clear();
        }
    });
};
