import {createAction} from "../../utils/reducer/reducerUtil";
import {GLOBAL_ACTION_TYPES} from "./global.types";
import {PULL_ALL, SYNC_ALL} from "../../services/sync";
import {STATUS_TYPE} from "../../utils/constant/constants";

export const handleEditAsset = (assetType, item) => (dispatch) => {
    dispatch(createAction(GLOBAL_ACTION_TYPES.EDIT_ASSET, {assetType, item}));
}

export const handleRemoveAsset = (assetType, item) => (dispatch) => {
    dispatch(createAction(GLOBAL_ACTION_TYPES.REMOVE_ASSET, {assetType, item}));
}
export const handleClearStateGlobal = () => (dispatch) => {
    dispatch(createAction(GLOBAL_ACTION_TYPES.CLEAR_GLOBAL_STATE));
}
export const handleShowDetailAsset = (assetType, item) => (dispatch) => {
    dispatch(createAction(GLOBAL_ACTION_TYPES.SHOW_DETAIL_ASSET, {assetType, item}));
}
export const handleCheckBoxAsset = (assetType, item) => (dispatch) => {
    dispatch(createAction(GLOBAL_ACTION_TYPES.CHECK_ASSET, {assetType, item}));
}
export const handleLoading = (status) => (dispatch) => {
    dispatch(createAction(GLOBAL_ACTION_TYPES.IS_LOADING, status));
}
export const handlePullAndSync = () => dispatch => {
    dispatch(createAction(GLOBAL_ACTION_TYPES.IS_LOADING_SYNC, true));
    PULL_ALL()
        .then(()=>{
            dispatch(handleSync());
        })
        .catch(() => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.SYNC_STATUS, STATUS_TYPE.FAILED));
            dispatch(handleResetNotifyNotConenctedToBackend());
        })

}

export const handleSync = () => dispatch => {
    dispatch(createAction(GLOBAL_ACTION_TYPES.IS_LOADING_SYNC, true));
    SYNC_ALL()
        .then(() => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.SYNC_STATUS, STATUS_TYPE.SUCCESS));
        })
        .catch(() => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.SYNC_STATUS, STATUS_TYPE.FAILED));
            dispatch(handleResetNotifyNotConenctedToBackend());
        })
}
export const handleResetNotifyNotConenctedToBackend = () => dispatch => {
    dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(GLOBAL_ACTION_TYPES.IS_LOADING, false));
}

export const handleResetStatusSync = () => dispatch => {
    dispatch(createAction(GLOBAL_ACTION_TYPES.SYNC_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(GLOBAL_ACTION_TYPES.IS_LOADING_SYNC, false));
}