import {RULER_ACTION_TYPES} from './ruler.types'
import {createAction} from "../../utils/reducer/reducerUtil";
import {
    addMidLabel,
    addPointEntity,
    addPolyline,
    getDistanceString,
    isDuplicateLocation
} from "../../utils/cesium/cesiumUtils";

export const handleRulerButton = () => {
    return createAction(RULER_ACTION_TYPES.HANDLE_RULER_TOGGLE)
}
export const handleRulerDisable = () => {
    return createAction(RULER_ACTION_TYPES.HANDLE_RULER_TOGGLE_DISABLE)
}
export const addEventHandler = (handlerClick, eventListener) => {
    return createAction(RULER_ACTION_TYPES.ADD_EVENT_HANDLER, {handlerClick, eventListener})
}
export const clearRulers = (viewer) => {
    return createAction(RULER_ACTION_TYPES.CLEAR_RULER, viewer)
}

export const addPoint = (points, viewer, cartecian, detailInformation) => {
    if (!isDuplicateLocation(points, viewer, cartecian)) {
        let newPoints = []
        if (points) {
            newPoints = [...points]
        }
        const point = addPointEntity(viewer, cartecian);
        newPoints.push(point)
        if (newPoints.length === 2) {
            // create line
            const point1 = newPoints[newPoints.length - 1];
            const point2 = newPoints[newPoints.length - 2];
            const polylineEntity = addPolyline(viewer, point1, point2);
            // create label
            const text = getDistanceString(point1, point2, false, true, detailInformation);
            const labelEntity = addMidLabel(viewer, point1, point2, text);
            return createAction(RULER_ACTION_TYPES.ADD_A_RULER, {polylineEntity, labelEntity, newPoints})
        }
        return createAction(RULER_ACTION_TYPES.ADD_POINT, newPoints);
    } else {
        return createAction(RULER_ACTION_TYPES.DO_NOTHING);
    }
}


export const putPrimitiveLabel = (primitiveLabel) => {
    return createAction(RULER_ACTION_TYPES.PUT_PRIMITIVE_LABEL, primitiveLabel)
}


export const storePosition = (position) => {
    return createAction(RULER_ACTION_TYPES.PUT_POSITION_ONCLICK, position)
}
