import React from "react";
import {
    RadioIcon,
    Antenna2Icon,
    TowerIcon,
    PabxIcon,
    SupportingIcon,
    ElectronicIcon,
    AdminIcon,
} from "../../assets/svgs";

const RenderIcon = ({icon}) => {
    switch (icon) {
        case "radio":
            return <RadioIcon width={40} height={40}/>;
        case "antenna":
            return <Antenna2Icon width={40} height={40}/>;
        case "tower":
            return <TowerIcon width={40} height={40}/>;
        case "pabx":
            return <PabxIcon width={40} height={40}/>;
        case "supp":
            return <SupportingIcon width={40} height={40}/>;
        case "electronic":
            return <ElectronicIcon width={40} height={40}/>;
        case "user":
            return <AdminIcon width={40} height={40}/>;
        default:
            return "";
    }
};

const HeaderPage = ({title, icon, isBlack = true}) => {
    return (
        <div className="flex flex-row justify-center items-center mx-2 gap-x-2">
            <div className={`pt-3 ${isBlack ? "text-white" : "text-black"}`}>
                <RenderIcon icon={icon}/>
            </div>
            <h1 className={`text-3xl ${isBlack ? "text-white" : "text-black"} text-center pt-6 underline underline-offset-8 mb-3`}>
                {title}
            </h1>
        </div>
    );
};

export default HeaderPage;
