import HeaderPage from "../../../components/header/HeaderPage";
import EyeIcon from "../../../assets/icons/hide.png";
import EyeHideIcon from "../../../assets/icons/view.png";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {handleChangeCurrentUserName, handleClearUpdateUserStatus, handleResetUpdateUser, handleUpdateUser} from "../../../store/user/user.action";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUser, selectStatusUpdateUser} from "../../../store/user/user.selector";
import {EMPTY_STRING, FAILED, SUCCESS} from "../../../store/admin/admin.types";
import {handleClearStateGlobal} from "../../../store/global/global.action";
import {generalAlert} from "../../../utils/notification/notification";
import {
    ALERT_ICON_FAILED,
    ALERT_ICON_SUCCESS,
    ALERT_TEXT_UPDATE_FAILED,
    ALERT_TEXT_UPDATE_SUCCESS,
    ALERT_TITLE_FAILED,
    ALERT_TITLE_SUCCESS
} from "../../../utils/constant/constants";

const Profile = () => {
    const {register, handleSubmit, reset, setValue, getValues, watch, formState: { errors }} = useForm(({
        defaultValues: {
            name: "",
            password: "",
            newPassword: "",
        }
    }));

    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);
    const statusUpdateUser = useSelector(selectStatusUpdateUser);

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    useEffect(() => {
        if (currentUser && currentUser.name) {
            setValue("name", currentUser.name);
        }
        return () => {
            reset();
            setShowOldPassword(false);
            setShowNewPassword(false);
            dispatch(handleClearStateGlobal());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (statusUpdateUser) {
            if (statusUpdateUser !== EMPTY_STRING) {
                if (statusUpdateUser === FAILED) {
                    generalAlert(ALERT_TITLE_FAILED, ALERT_TEXT_UPDATE_FAILED, ALERT_ICON_FAILED, () => dispatch(handleClearUpdateUserStatus()));
                } else if (statusUpdateUser === SUCCESS) {
                    generalAlert(ALERT_TITLE_SUCCESS, ALERT_TEXT_UPDATE_SUCCESS, ALERT_ICON_SUCCESS);
                    dispatch(handleResetUpdateUser());
                    dispatch(handleChangeCurrentUserName(currentUser, getValues("name")));
                    setValue("password", "");
                    setValue("newPassword", "");
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusUpdateUser])

    const handleSave = (data) => {
        dispatch(handleResetUpdateUser());
        
        if (data.password !== "" && data.newPassword !== "" && data.password === data.newPassword) {
            generalAlert(ALERT_TITLE_FAILED, `Old Passwords and New Passwords should not be the same`, ALERT_ICON_FAILED);
        } else {
            if (currentUser && currentUser.id && currentUser.name) {
                if (data.password && data.newPassword) {
                    dispatch(handleUpdateUser(currentUser.id, data));
                } else if (currentUser.name !== data.name) {
                    const newPayload = {name: data.name};
                    dispatch(handleUpdateUser(currentUser.id, newPayload));
                } else {
                    generalAlert(ALERT_TITLE_FAILED, `The input data has not changed`, ALERT_ICON_FAILED);
                }
            }
        }
    }

    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="Update User"/>

            <div className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">
                    <form>
                        <div className="mt-3 mb-5">
                            <label className="flex justify-center items-center text-white font-bold underline underline-offset-8">
                                Change Name
                            </label>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-white font-bold">
                                Name
                            </label>
                            <input
                                {...register("name", {
                                    required: "Required!",
                                    pattern: {
                                        value: /^\S+/,
                                        message: "Entered value cant start or contain only white spacing"
                                    },
                                })}
                                pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                id="name"
                                type="text"
                                className={`input input-bordered text-black text-sm mt-1 mb-4 border-2 focus:border-purple-500 ${errors?.name ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                placeholder={errors?.name?.message}
                            />
                        </div>

                        <div className="mt-3 mb-5">
                            <label className="flex justify-center items-center text-white font-bold underline underline-offset-8">
                                Change Password
                            </label>
                        </div>

                        <div>
                            <label className="text-white font-bold">
                                Old Password
                            </label>
                            <div className="flex">
                                <input
                                    {...register("password", {
                                        required: (() => {
                                                if (watch("newPassword")) {
                                                    return "Required!"
                                                } else return false
                                            })(),
                                        pattern: {
                                            value: /^\S+/,
                                            message: "Entered value cant start or contain only white spacing"
                                        },
                                    })}
                                    id="password"
                                    type={!showOldPassword ? "password" : "text"}
                                    className={`input input-bordered w-full text-black text-sm mt-1 mb-4 border-2 focus:border-purple-500 mr-[-34px] ${errors?.password ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                    placeholder={errors.password ? errors?.password?.message : "Old Password"}
                                />
                                <div
                                    className="cursor-pointer mt-4"
                                    onClick={() => setShowOldPassword(prevShowOldPassword => !prevShowOldPassword)}
                                >
                                    <img
                                        src={showOldPassword ? EyeIcon : EyeHideIcon}
                                        alt="eye"
                                        width={24}
                                        height={24}
                                    />
                                </div>
                            </div>
                        </div>

                        <div>
                            <label className="text-white font-bold">
                                New Password
                            </label>
                            <div className="flex">
                                <input
                                    {...register("newPassword", {
                                        required: (() => {
                                            if (watch("password")) {
                                                return "Required!"
                                            } else return false
                                        })(),
                                        pattern: {
                                            value: /^\S+/,
                                            message: "Entered value cant start or contain only white spacing"
                                        },
                                    })}
                                    id="newPassword"
                                    type={!showNewPassword ? "password" : "text"}
                                    className={`input input-bordered w-full text-black text-sm mt-1 mb-4 border-2 focus:border-purple-500 mr-[-34px] ${errors?.newPassword ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                    placeholder={errors.newPassword ? errors?.newPassword?.message : "New Password"}
                                />
                                <div
                                    className="cursor-pointer mt-4"
                                    onClick={() => setShowNewPassword(prevShowNewPassword => !prevShowNewPassword)}
                                >
                                    <img
                                        src={showNewPassword ? EyeIcon : EyeHideIcon}
                                        alt="eye"
                                        width={24}
                                        height={24}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-x-4 mt-5">
                            <button
                                type="button"
                                className="btn btn-primary text-white px-7"
                                onClick={handleSubmit(handleSave)}
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Profile;