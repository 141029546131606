import {
    CREATE_TOWER,
    DELETE_TOWER_BY_ID,
    GET_ALL_TOWER_SEARCH,
    GET_TOWER_BY_ID,
    SEARCH_TOWER,
    UPDATE_TOWER
} from "../../services/tower";
import {createAction} from "../../utils/reducer/reducerUtil";
import {TOWER_ACTION_TYPES} from "./tower.types";
import {
    GET_ASSET_TOWER,
    GET_BRAND_BY_USAGE,
    GET_CATEGORY_BY_USAGE,
    GET_TYPE_BY_USAGE,
    UPLOAD_FILE,
    UPLOAD_IMAGE
} from "../../services/asset";
import {GET_ALL_SITE} from "../../services/site";
import {MAX_REQUEST_SIZE, STATUS_TYPE} from "../../utils/constant/constants";
import {handleClearStateGlobal, handleLoading} from "../global/global.action";
import {GET_ALL_DISTRIBUTIONASSET} from "../../services/distributionassets";
import {drawTower, removeEntityAndDataSource, removeEntityById} from "../../utils/cesium/cesiumUtils";
import {GLOBAL_ACTION_TYPES} from "../global/global.types";
import {GET_TOWER_CIVIL_FROM_SERVER} from "../../services/coreserverservice";


export const handleRedTower = (viewer, isTowerMiliterActive, towerMiliters, currentUser) => async (dispatch) => {
    if (!isTowerMiliterActive) { // mean activate toggle
        dispatch(createAction(TOWER_ACTION_TYPES.TOWER_MILITER_ACTIVE));

        const res = await GET_ALL_TOWER_SEARCH(0, MAX_REQUEST_SIZE, "", 0);
        const towerMilitersArr = [];
        if (res && res.tower && res.tower.content) {
            const content = res.tower.content;
            for (let i = 0; i < content.length; i++) {
                towerMilitersArr.push(content[i]);
            }
        }

        if (towerMilitersArr.length) {
            if (viewer) {
                const towerDrawer = drawTower(viewer, towerMilitersArr, true);
                dispatch(createAction(TOWER_ACTION_TYPES.FETCH_TOWER_MILITER, towerDrawer));
            }
        }

    } else { // deactivate toggle
        removeEntityById(viewer, towerMiliters);
    }
}

export const removeAllEntites = (viewer) => (dispatch) => {
    removeEntityAndDataSource(viewer);
    dispatch(createAction(TOWER_ACTION_TYPES.REMOVE_ALL));
}
export const handleWhiteTower = (viewer, isTowerDistributionActive, towerDistributions) => async (dispatch) => {
    if (!isTowerDistributionActive) { // mean activate toggle
        const res = await GET_ALL_DISTRIBUTIONASSET();
        const towerDist = [];
        if (res && res.content) {
            const content = res.content;
            for (let i = 0; i < content.length; i++) {
                if (content[i].type.id === 5) {
                    towerDist.push(content[i]);
                }
            }
        }

        const towerFromServer = localStorage.getItem('towerFromServer');
        if (viewer) {
            const towerDistArr = drawTower(
                viewer,
                towerFromServer ? towerDist.concat(JSON.parse(towerFromServer)) : towerDist,
                false);
            dispatch(createAction(TOWER_ACTION_TYPES.FETCH_TOWER_DISTRIBUTION, towerDistArr));
        }

    } else { // deactivate toggle
        removeEntityById(viewer, towerDistributions);
    }
}

export const handleGetTowerCivilFromServer = () => async dispatch => {
    const resDataTower = await GET_TOWER_CIVIL_FROM_SERVER("pullDefault")
    if (resDataTower && resDataTower.length) {
        localStorage.setItem('towerFromServer', JSON.stringify(resDataTower));
    }
}

export const handleUploadFile = (item) => dispatch => {
    const formData = new FormData();
    formData.append("file", item);
    UPLOAD_FILE(formData)
        .then(res => {
            dispatch(createAction(TOWER_ACTION_TYPES.UPLOAD_FILE, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
        });
}

export const handleUploadImage = (item) => dispatch => {
    const formData = new FormData();
    formData.append("file", item);
    UPLOAD_IMAGE(formData)
        .then(res => {
            dispatch(createAction(TOWER_ACTION_TYPES.UPLOAD_IMAGE, res.id));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
        });
}

export const handleFetchStatusTower = () => async (dispatch) => {
    const res = await GET_ASSET_TOWER();
    if (res) {
        dispatch(createAction(TOWER_ACTION_TYPES.GET_STATUS, res));
    }
}
export const handleFetchBrand = () => async (dispatch) => {
    const res = await GET_BRAND_BY_USAGE("radio");
    if (res && res.length > 0) {
        dispatch(createAction(TOWER_ACTION_TYPES.GET_BRAND, res));
    }
}
export const handleFetchCategory = () => async (dispatch) => {
    const res = await GET_CATEGORY_BY_USAGE("radio")
    if (res && res.length > 0) {
        dispatch(createAction(TOWER_ACTION_TYPES.GET_CATEGORY, res));
    }
}
export const handleFetchType = () => async (dispatch) => {
    const res = await GET_TYPE_BY_USAGE("tower")
    if (res && res.length > 0) {
        dispatch(createAction(TOWER_ACTION_TYPES.GET_TYPE, res));
    }
}
export const handleFetchSite = () => async (dispatch) => {
    const res = await GET_ALL_SITE();
    if (res && res.content.length > 0) {
        dispatch(createAction(TOWER_ACTION_TYPES.GET_SITE, res));
    }
}

export const handleClearData = () => (dispatch) => {
    dispatch(createAction(TOWER_ACTION_TYPES.CLEAR_DATA))
}

export const handleClearNotif = () => dispatch => {
    dispatch(createAction(TOWER_ACTION_TYPES.UPLOAD_TOWER_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(TOWER_ACTION_TYPES.UPDATED, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(TOWER_ACTION_TYPES.REMOVED, STATUS_TYPE.NO_STATEMENT));
}

export const handleSaveTower = (data, images, files, siteId) => async (dispatch) => {

    const payload = {
        type: {assetType: data.towerType},
        deployedAntenna: data.deployedAntenna,
        merk: data.merk,
        site: {id: siteId},
        lat: data.lat,
        name: data.name,
        lon: data.lon,
        alt: data.alt,
        notes: data.notes,
        status: data.status,
        imageUrlList: images,
        fileUrlList: files.urlArr,
        fileNameList: files.nameArr,
    };
    CREATE_TOWER(payload).then(() => {
        dispatch(createAction(TOWER_ACTION_TYPES.UPLOAD_TOWER_STATUS, STATUS_TYPE.SUCCESS))
    }).catch(e => {
        dispatch(createAction(TOWER_ACTION_TYPES.UPLOAD_TOWER_STATUS, STATUS_TYPE.FAILED))
    });
    dispatch(handleClearStateGlobal());
    dispatch(createAction(TOWER_ACTION_TYPES.IS_LOADING, false));

}

export const handleStartLoading = () => dispatch => {
    dispatch(createAction(TOWER_ACTION_TYPES.IS_LOADING, true));
}

export const handleRemoveTower = (id, siteId, page) => async (dispatch) => {
    dispatch(createAction(TOWER_ACTION_TYPES.IS_LOADING, true));
    DELETE_TOWER_BY_ID(id)
        .then(() => {
            dispatch(createAction(TOWER_ACTION_TYPES.REMOVED, STATUS_TYPE.SUCCESS))
            dispatch(handleClearStateGlobal());
        })
        .catch(() => {
            dispatch(createAction(TOWER_ACTION_TYPES.REMOVED, STATUS_TYPE.FAILED))
            dispatch(handleClearStateGlobal());
        })
    dispatch(createAction(TOWER_ACTION_TYPES.IS_LOADING, false));
}
export const handleUpdateTower = (id, data, images, files, siteId, page) => async (dispatch) => {

    const payload = {
        type: {assetType: data.towerType},
        deployedAntenna: data.deployedAntenna,
        merk: data.merk,
        site: {id: siteId},
        name: data.name,
        lat: data.lat,
        lon: data.lon,
        alt: data.alt,
        notes: data.notes,
        status: data.status,
        imageUrlList: images,
        fileUrlList: files.urlArr,
        fileNameList: files.nameArr,
    };


    UPDATE_TOWER(id, payload)
        .then(() => {
            dispatch(createAction(TOWER_ACTION_TYPES.UPDATED, STATUS_TYPE.SUCCESS));
        })
        .catch(() => {
            dispatch(createAction(TOWER_ACTION_TYPES.UPDATED, STATUS_TYPE.FAILED));
        })
    dispatch(handleClearStateGlobal());
    dispatch(createAction(TOWER_ACTION_TYPES.IS_LOADING, false));
};

export const handleSearchTower = (q, type, page, size, siteId) => dispatch => {
    SEARCH_TOWER(q, type, page, size, siteId)
        .then(res => {
            dispatch(createAction(TOWER_ACTION_TYPES.SEARCH, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        })
};

export const handleSearchTowerReport = (q, type, page, size, siteId) => dispatch => {
    SEARCH_TOWER(q, type, page, size, siteId)
        .then(res => {
            dispatch(createAction(TOWER_ACTION_TYPES.SEARCH_REPORT, res));
        })
        .catch(() => {
        })
};

export const handleGetTowerById = (id) => dispatch => {
    GET_TOWER_BY_ID(id)
        .then(res => {
            dispatch(createAction(TOWER_ACTION_TYPES.DETAIL_TOWER, res));
        })
        .catch(() => {
        });
};

export const handleClearDetailTower = () => (dispatch) => {
    dispatch(createAction(TOWER_ACTION_TYPES.CLEAR_DETAIL_TOWER));
};

export const handleWhiteTowerToggle = (payload) => (dispatch) => {
    dispatch(createAction(TOWER_ACTION_TYPES.SET_TOGGLE_WHITE_TOWER, payload));
};
export const handleRedTowerToggle = (payload) => (dispatch) => {
    dispatch(createAction(TOWER_ACTION_TYPES.SET_TOGGLE_RED_TOWER, payload));
};
