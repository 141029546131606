import * as React from "react"

const SimulationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 8267.72 11692.91"
    {...props}
  >
    <g stroke="#2B2A29" strokeMiterlimit={22.926}>
      <path
        fill="currentColor"
        strokeWidth={7.87}
        d="M-292.65 165.63h8904.69c677.3 0 1231.45 554.15 1231.45 1231.45v8904.69c0 677.3-554.15 1231.46-1231.45 1231.46H-292.65c-677.3 0-1231.46-554.15-1231.46-1231.46V1397.08c0-677.3 554.15-1231.45 1231.46-1231.45zm304.78 389.1h8295.11c630.96 0 1147.14 516.19 1147.14 1147.14v8295.11c0 630.96-516.19 1147.14-1147.14 1147.14H12.13c-630.96 0-1147.14-516.19-1147.14-1147.14V1701.87c0-630.96 516.19-1147.14 1147.14-1147.14z"
      />
      <g strokeWidth={98.43}>
        <path
          fill="currentColor"
          d="M3984.17 1528.86h2567.52c41.95 0 76.27 34.31 76.27 76.26v1698.33c0 41.94-34.32 76.26-76.27 76.26H5551.31v241.74h761.82c58.9 0 107.09 48.18 107.09 107.07v161.11H4115.65v-161.11c0-58.89 48.19-107.07 107.09-107.07h761.81v-241.74H3984.17c-41.95 0-76.27-34.31-76.27-76.26V1605.12c0-41.94 34.32-76.26 76.27-76.26zm51.93 87.23h2463.66v1676.39H4036.1V1616.09z"
        />
        <path
          fill="none"
          d="M4036.1 1616.09h2463.66v1676.39H4036.1zM5267.93 3889.64 976.3 6809.79l6228.18 2723.04"
        />
        <path
          fill="currentColor"
          d="M5920.72 7172.06h2567.52c41.95 0 76.27 34.31 76.27 76.26v1698.34c0 41.94-34.32 76.26-76.27 76.26H7487.86v241.74h761.82c58.9 0 107.09 48.18 107.09 107.07v161.11H6052.2v-161.11c0-58.89 48.19-107.07 107.09-107.07h761.82v-241.74H5920.72c-41.95 0-76.27-34.31-76.27-76.26V7248.32c0-41.94 34.32-76.26 76.27-76.26zm51.93 87.23h2463.66v1676.39H5972.65V7259.29z"
        />
        <path fill="#FEFEFE" d="M5972.65 7259.29h2463.66v1676.39H5972.65z" />
        <path
          fill="currentColor"
          d="M-307.46 4449.02h2567.52c41.94 0 76.27 34.31 76.27 76.26v1698.33c0 41.94-34.32 76.26-76.27 76.26H1259.67v241.74h761.82c58.9 0 107.09 48.19 107.09 107.07v161.11H-175.99v-161.11c0-58.89 48.19-107.07 107.09-107.07h761.82v-241.74H-307.47c-41.95 0-76.27-34.31-76.27-76.26V4525.28c0-41.94 34.32-76.26 76.27-76.26zm51.93 87.23h2463.67v1676.39H-255.53V4536.25z"
        />
        <path fill="none" d="M-255.54 4536.25h2463.66v1676.39H-255.54z" />
        <path fill="#FEFEFE" d="M-255.54 4536.25h2463.66v1676.39H-255.54z" />
        <path fill="#FEFEFE" d="M4036.1 1616.09h2463.66v1676.39H4036.1z" />
      </g>
    </g>
  </svg>
)

export default SimulationIcon
