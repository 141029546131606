import {createSelector} from "reselect";
import {getPercentage} from "../../utils/calculate/convert";

const selectDSSReducer = (state) => state.dssReducer;

export const selectAllDSS = createSelector(
    [selectDSSReducer],
    (state) => state.dsss
)
export const selectAllDSSReport = createSelector(
    [selectDSSReducer],
    (state) => state.dsssReport
)
export const selectTotalPages = createSelector(
    [selectAllDSS],
    (dsss) => {
        if (dsss && dsss.dss) {
            return dsss.dss.totalPages
        } else {
            return null
        }
    }
)
export const selectCategories = createSelector(
    [selectDSSReducer],
    (state) => state.categories
)
export const selectDetailDSS = createSelector(
    [selectDSSReducer],
    (state) => state.detailDSS
)
export const selectBrands = createSelector(
    [selectDSSReducer],
    (state) => state.brands
)
export const selectTypes = createSelector(
    [selectDSSReducer],
    (state) => state.types
)
export const selectSites = createSelector(
    [selectDSSReducer],
    (state) => state.sites
)
export const selectDSSTypesByBrand = createSelector(
    [selectDSSReducer],
    state => {
        if (state.typesByBrand) {
            return state.typesByBrand;
        } else {
            return null;
        }
    }
);
export const selectDataChart = createSelector(
    [selectAllDSS],
    (state) => {
        if (state && state.summary) {
            const status = state.summary;
            return [{
                datasets: [
                    {
                        label: "Supporting",
                        data: [
                            getPercentage(status.spare, status.total),
                            getPercentage(status.service, status.total),
                            getPercentage(status.unservice, status.total)
                        ],
                        backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                        borderColor: ["#040f0f"],
                        borderWidth: 10,
                    },
                ],
            }]
        } else {
            return null;
        }
    }
)
export const selectStatusDSS = createSelector(
    [selectAllDSS],
    (state) => {
        if (state && state.summary) {
            const status = state.summary;
            return [
                {
                    id: 1,
                    name: "Supporting Total",
                    item: status.total ?? 0,
                    icon: "supp",
                    class: "",
                },
                {
                    id: 2,
                    name: "Supporting Serviceable",
                    item: status.service ?? 0,
                    icon: "supp",
                    class: "serviceable",
                },
                {
                    id: 3,
                    name: "Supporting Spare",
                    item: status.spare ?? 0,
                    icon: "supp",
                    class: "spare",
                },
                {
                    id: 4,
                    name: "Supporting Unserviceable",
                    item: status.unservice ?? 0,
                    icon: "supp",
                    class: "unserviceable",
                },
            ]
        } else {
            return null;
        }
    }
)

export const selectStatusArray = createSelector(
    [selectDSSReducer],
    (state) => state.statusArray
)
export const selectColumn = createSelector(
    [selectDSSReducer],
    (state) => state.column
)
export const selectUploadedImages = createSelector(
    [selectDSSReducer],
    (state) => state.uploadedImages
)
export const selectUploadedFiles = createSelector(
    [selectDSSReducer],
    (state) => state.uploadedFiles
)
export const selectUploadedDSSStatus = createSelector(
    [selectDSSReducer],
    (state) => state.uploadDSSStatus
)
export const selectUpdateStatus = createSelector(
    [selectDSSReducer],
    (state) => state.updateStatus
)
export const selectRemoveStatus = createSelector(
    [selectDSSReducer],
    (state) => state.removeStatus
)