import {Viewer,} from "resium";
import React from "react";
import {useLocation} from "react-router-dom";

export const MapViewer = React.forwardRef(({isPush, onClick, isOpen, children, isScheneModePickerActive = true}, ref) => {
        const location = useLocation();

        return (
            <div className="w-full">
                <Viewer
                    ref={ref}
                    fullscreenButton={false}
                    timeline={false}
                    animation={false}
                    navigationHelpButton={false}
                    className={`${location.pathname.indexOf("/assetdata/fo") > -1 ? "h-[calc(100vh-48px)]" : location.pathname.indexOf("/assetdata") > -1 ? "h-[50vh]" : "h-[calc(100vh-48px)]"} ${isOpen ? "transition-width duration-300 linear" : null}`}
                    style={{width: `${location.pathname.indexOf("/assetdata") > -1 || location.pathname.indexOf("/distribution") > -1 ? "" : isOpen ? "77.2%" : "100%"}`}}
                    sceneModePicker={isScheneModePickerActive}
                    selectionIndicator={false}
                    infoBox={false}
                    useBrowserRecommendedResolution
                >
                    {children}
                </Viewer>
            </div>
        )
            ;
    }
);
