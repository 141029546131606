import {GPS_ACTION_TYPES} from "./gps.types";

const INITIAL_STATE = {
    position: null,
}

export const gpsReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case GPS_ACTION_TYPES.GET_DATA_GPS:
            return {...state, position: payload};
        default:
            return state;
    }
}