export const DSS_ACTION_TYPES = {
    GET_STATUS : 'dss/GET_STATUS',
    GET_ALL_DSS : 'dss/GET_ALL_DSS',
    GET_CATEGORY : 'dss/GET_CATEGORY',
    GET_BRAND : 'dss/GET_BRAND',
    GET_TYPE : 'dss/GET_TYPE',
    GET_SITE : 'dss/GET_SITE',
    GET_DSS_TYPES_BY_BRAND_ID : 'dss/GET_DSS_TYPES_BY_BRAND_ID',
    CLEAR_DSS_TYPES_BY_BRAND_ID : 'dss/CLEAR_DSS_TYPES_BY_BRAND_ID',
    CLEAR_DATA : 'dss/CLEAR_DATA',
    CHANGE_PAGE : 'dss/CHANGE_PAGE',
    UPLOAD_DSS_STATUS : 'dss/UPLOAD_DSS_STATUS',
    UPLOAD_IMAGE_STATUS : 'dss/UPLOAD_IMAGE_STATUS',
    UPLOAD_FILE_STATUS : 'dss/UPLOAD_FILE_STATUS',
    UPLOAD_IMAGE : 'dss/UPLOAD_IMAGE',
    UPLOAD_FILE : 'dss/UPLOAD_FILE',
    IS_LOADING : 'dss/IS_LOADING',
    REMOVED : 'dss/REMOVED',
    UPDATED : 'dss/UPDATED',
    SEARCH : 'dss/SEARCH',
    SEARCH_REPORT : 'dss/SEARCH_REPORT',
    DETAIL_DSS : 'dss/DETAIL_DSS',
    CLEAR_DETAIL_DSS : 'dss/CLEAR_DETAIL_DSS',
}