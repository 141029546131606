export const FREQUENCY_ALLOCATION_ACTION_TYPES = {
    UPLOAD_FREQUENCY_ALLOCATION_STATUS : 'frequencyAllocation/UPLOAD_FREQUENCY_ALLOCATION_STATUS',
    SEARCH : 'frequencyAllocation/SEARCH',
    UPDATED : 'frequencyAllocation/UPDATED',
    REMOVED : 'frequencyAllocation/REMOVED',
    CLEAR_DATA : 'frequencyAllocation/CLEAR_DATA',
    REGISTER_HANDLER_LISTENER : 'frequencyAllocation/REGISTER_HANDLER_LISTENER',
    SELECTED_SITE_ID : 'frequencyAllocation/SELECTED_SITE_ID',
    SITE_ENTITIES : 'frequencyAllocation/SITE_ENTITIES',
    CIRCLE_ENTITY : 'frequencyAllocation/CIRCLE_PRIMITIVE',
}