import {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import Login from "./routes/login/Login";
import NotFoundPage from "./routes/error/NotFoundPage";
import RequiredCredential from "./components/hoc/RequiredCredential";

// Home
import { Dashboard, Home } from "./routes/home";

// Asset Data
import {
    Antenna,
    AssetData,
    MaintenanceHistory,
    PABX,
    Radio,
    Supporting,
    Tower
} from "./routes/assetData";

// Analysis
import {
    GTA,
    GTG,
    Jammer,
    Mesh,
    OperationPlanning,
    RadioLink
} from "./routes/analyze";

// Administration
import {
    AssetBrand,
    AssetCategory,
    AssetType,
    OwnerType,
    Profile,
    Simulation,
    TemplateMaintenance,
    UserManager
} from "./routes/userManagement/administrations";

// Fiber Optic
import { AddRoute, FODistribution } from "./routes/assetData/fiber-optic";

// Maintenance Data
import {
    CreateMaintenance,
    MaintenanceData,
    SelectAssetMaintenance,
    TypeMaintenance
} from "./routes/assetData/maintenanceData";

// Maintenance Schedule
import {
    MaintenanceSchedule,
    SelectAssetSchedule,
    TypeSchedule
} from "./routes/assetData/maintenanceSchedule";

// Distribution Data
import {
    Distribution,
    ElectronicWS,
    FrequencyAllocation,
    TowerCivil
} from "./routes/distributionData";

// Operation Data
import {
    OperationData,
    OperationDataActual,
    OperationDataDetail
} from "./routes/operationData";

// User Management
import { About, CreateUser } from "./routes/userManagement";

import {selectRoleData} from "./store/role/role.selector";
import {selecNotifyNotConnectedToBackend, selectIsLoading} from "./store/global/global.selector";
import LoadingSpinner from "./components/loading/LoadingSpinner";
import {STATUS_TYPE} from "./utils/constant/constants";
import {handleResetNotifyNotConenctedToBackend} from "./store/global/global.action";
import LogActivity from "./routes/userManagement/administrations/LogActivity";
import DataTowerServer from "./routes/userManagement/administrations/DataTowerServer";

const App = () => {
    const dispatch = useDispatch();
    const role = useSelector(selectRoleData);
    const isLoadingFromReducer = useSelector(selectIsLoading);
    const notifyNotConnectedToBackend = useSelector(selecNotifyNotConnectedToBackend);

    useEffect(()=>{
        if (STATUS_TYPE.FAILED === notifyNotConnectedToBackend) {
            dispatch(handleResetNotifyNotConenctedToBackend());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[notifyNotConnectedToBackend])

    return (
        <>
            {isLoadingFromReducer && (
                <LoadingSpinner isLoading={isLoadingFromReducer}/>
            )}
            <Home>
                <Routes>
                    {/*==================== LOGIN ==================*/}
                    <Route path="/login" element={<Login/>}/>

                    {/*==================== DASHBOARD ==================*/}
                    <Route
                        path="/"
                        element={
                            <RequiredCredential>
                                <Dashboard/>
                            </RequiredCredential>
                        }
                    />
                    <Route path="/dashboard">
                        <Route
                            path="gta"
                            element={
                                <RequiredCredential>
                                    <GTA/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="gtg"
                            element={
                                <RequiredCredential>
                                    <GTG/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="jammer"
                            element={
                                <RequiredCredential>
                                    <Jammer/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="radiolink"
                            element={
                                <RequiredCredential>
                                    <RadioLink/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="mesh"
                            element={
                                <RequiredCredential>
                                    <Mesh/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="planning"
                            element={
                                <RequiredCredential>
                                    <OperationPlanning/>
                                </RequiredCredential>
                            }
                        />
                    </Route>

                    {/*======================ASSET DATA========================*/}

                    <Route path="/assetdata">
                        <Route
                            index
                            element={
                                <RequiredCredential>
                                    <AssetData/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="radio"
                            element={
                                <RequiredCredential>
                                    <Radio/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="antenna"
                            element={
                                <RequiredCredential>
                                    <Antenna/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="tower"
                            element={
                                <RequiredCredential>
                                    <Tower/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="pabx"
                            element={
                                <RequiredCredential>
                                    <PABX/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="supporting"
                            element={
                                <RequiredCredential>
                                    <Supporting/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="fo"
                        >
                            <Route
                                index
                                element={
                                    <RequiredCredential>
                                        <FODistribution/>
                                    </RequiredCredential>
                                }
                            />
                            <Route
                                path="addroute"
                                element={
                                    <RequiredCredential>
                                        <AddRoute/>
                                    </RequiredCredential>
                                }
                            />
                        </Route>
                        <Route
                            path="maintenance-data"
                        >
                            <Route
                                index
                                element={
                                    <RequiredCredential>
                                        <MaintenanceData/>
                                    </RequiredCredential>
                                }
                            />
                            <Route
                                path="asset-maintenance"
                                element={
                                    <RequiredCredential>
                                        <SelectAssetMaintenance/>
                                    </RequiredCredential>
                                }
                            />
                            <Route
                                path="type-maintenance"
                                element={
                                    <RequiredCredential>
                                        <TypeMaintenance/>
                                    </RequiredCredential>
                                }
                            />
                            <Route
                                path="create-maintenance"
                                element={
                                    <RequiredCredential>
                                        <CreateMaintenance/>
                                    </RequiredCredential>
                                }
                            />
                        </Route>
                        <Route
                            path="maintenance-schedule"
                        >
                            <Route
                                index
                                element={
                                    <RequiredCredential>
                                        <MaintenanceSchedule/>
                                    </RequiredCredential>
                                }
                            />
                            <Route
                                path="asset-schedule"
                                element={
                                    <RequiredCredential>
                                        <SelectAssetSchedule/>
                                    </RequiredCredential>
                                }
                            />
                            <Route
                                path="type-schedule"
                                element={
                                    <RequiredCredential>
                                        <TypeSchedule/>
                                    </RequiredCredential>
                                }
                            />
                        </Route>
                        <Route
                            path="maintenance-history"
                            element={
                                <RequiredCredential>
                                    <MaintenanceHistory/>
                                </RequiredCredential>
                            }
                        />
                    </Route>

                    {/*=================== OPERATION DATA=====================*/}
                    <Route path="/operationdata">
                        <Route
                            index
                            element={
                                <RequiredCredential>
                                    <OperationData/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="addactualdata/:id"
                            element={
                                <RequiredCredential>
                                    <OperationDataActual/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="detail/:id"
                            element={
                                <RequiredCredential>
                                    <OperationDataDetail/>
                                </RequiredCredential>
                            }
                        />
                    </Route>

                    {/*=================== USER MANAGEMENT=====================*/}


                    <Route path="/usermanagement">
                        <Route
                            path="profile"
                            element={
                                <RequiredCredential>
                                    <Profile/>
                                </RequiredCredential>
                            }
                        />
                        {role && role.id === "1" && (
                            <>
                                <Route
                                    path="createuser"
                                    element={
                                        <RequiredCredential>
                                            <CreateUser/>
                                        </RequiredCredential>
                                    }
                                />

                                <Route path="administration">
                                    <Route
                                        index
                                        element={
                                            <RequiredCredential>
                                                <Simulation/>
                                            </RequiredCredential>
                                        }
                                    />
                                    <Route
                                        path="ownertype"
                                        element={
                                            <RequiredCredential>
                                                <OwnerType/>
                                            </RequiredCredential>
                                        }
                                    />
                                    <Route
                                        path="logactivity"
                                        element={
                                            <RequiredCredential>
                                                <LogActivity/>
                                            </RequiredCredential>
                                        }
                                    />
                                    <Route
                                        path="assetcategory"
                                        element={
                                            <RequiredCredential>
                                                <AssetCategory/>
                                            </RequiredCredential>
                                        }
                                    />
                                    <Route
                                        path="assetbrand"
                                        element={
                                            <RequiredCredential>
                                                <AssetBrand/>
                                            </RequiredCredential>
                                        }
                                    />
                                    <Route
                                        path="assettype"
                                        element={
                                            <RequiredCredential>
                                                <AssetType/>
                                            </RequiredCredential>
                                        }
                                    />
                                    <Route
                                        path="template-maintenance"
                                        element={
                                            <RequiredCredential>
                                                <TemplateMaintenance/>
                                            </RequiredCredential>
                                        }
                                    />
                                    <Route
                                        path="data-tower-server"
                                        element={
                                            <RequiredCredential>
                                                <DataTowerServer />
                                            </RequiredCredential>
                                        }
                                    />
                                    <Route
                                        path="create-user"
                                        element={
                                            <RequiredCredential>
                                                <CreateUser/>
                                            </RequiredCredential>
                                        }
                                    />
                                    <Route
                                        path="user-manager"
                                        element={
                                            <RequiredCredential>
                                                <UserManager/>
                                            </RequiredCredential>
                                        }
                                    />

                                </Route>
                            </>
                        )}
                        <Route
                            path="about"
                            element={
                                <RequiredCredential>
                                    <About/>
                                </RequiredCredential>
                            }
                        />
                    </Route>


                    {/*=====================DISTRIBUTION ASSET ======================*/}
                    <Route path="/distribution">
                        <Route
                            index
                            element={
                                <RequiredCredential>
                                    <Distribution/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="towercivil"
                            element={
                                <RequiredCredential>
                                    <TowerCivil/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="electronicws"
                            element={
                                <RequiredCredential>
                                    <ElectronicWS/>
                                </RequiredCredential>
                            }
                        />
                        <Route
                            path="frequency-allocation"
                            element={
                                <RequiredCredential>
                                    <FrequencyAllocation/>
                                </RequiredCredential>
                            }
                        />
                    </Route>

                    {/*==================PAGE NOT FOUND=======================*/}
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
            </Home>
        </>
    );
};

export default App;
