import {Cartesian3, defined} from "cesium";
import {addMidLabel, createBillboardEntity, createCzmlDataSource} from "../../utils/cesium/cesiumUtils";
import {createAction} from "../../utils/reducer/reducerUtil";
import {MANNET_ACTION_TYPES} from "./mannet.types";
import {handleTransmitterData} from "../area/area.action";
import {CREATE_POINT} from "../../services/analyze";
import {
    DELETE_MANNET,
    GET_ALL_MANNET_BY_NETWORK,
    GET_ALL_MANNET_NETWORK,
    POST_MANNET,
    UPDATE_MANNET
} from "../../services/mannet";
import {STATUS_TYPE} from "../../utils/constant/constants";

export const handleCreateVerticalLine = (viewer, latitude, longitude, height, id) => dispatch => {
    const rx = {
        rxLat: latitude,
        rxLon: longitude,
        rxAlt: 0
    };
    const tx = {
        txLat: latitude,
        txLon: longitude,
        txAlt: height
    };
    const czmlDataSourcePromise = createCzmlDataSource(viewer, rx, tx, true, id);
    const payload = {
        latitude,
        longitude,
        height,
        id
    }

    dispatch(createAction(MANNET_ACTION_TYPES.REGISTER_VERTICAL_LINE, payload));
    dispatch(createAction(MANNET_ACTION_TYPES.REGISTER_VERTICAL_LINE_DATESOURCES, czmlDataSourcePromise));
}

const createDbmLabel = (txLat, txLon, txAlt, rxLat, rxLon, rxAlt, viewer, dbm) => dispatch => {
    const point1 = {latitude: txLat, longitude: txLon, altitude: txAlt}
    const point2 = {latitude: rxLat, longitude: rxLon, altitude: rxAlt}
    const label = addMidLabel(viewer, point1, point2, `${dbm} dBm`, 0.8);

    dispatch(createAction(MANNET_ACTION_TYPES.REGISTER_DBM_LABEL, label));
}
export const handleResetProgressStatus = () => dispatch => {
    dispatch(createAction(MANNET_ACTION_TYPES.DRAW_PROGRESS_STATUS_REPORT, STATUS_TYPE.NO_STATEMENT));
}

export const handleCreateHorizontalLine = (viewer, rxLat, rxLon, rxAlt, txLat, txLon, txAlt, id, dbm) => dispatch => {
    let rx = {rxLat, rxLon, rxAlt};
    let tx = {txLat, txLon, txAlt};
    let payload = {rxLat, rxLon, rxAlt, txLat, txLon, txAlt, id}

    const hLineDataSources = createCzmlDataSource(viewer, rx, tx, false, id,
        (status) => {
            dispatch(createAction(MANNET_ACTION_TYPES.DRAW_PROGRESS_STATUS_REPORT, status));
            dispatch(createAction(MANNET_ACTION_TYPES.PROGRESS_COUNT));
        });
    dispatch(createAction(MANNET_ACTION_TYPES.REGISTER_HORIZONTAL_LINE_DATASOURCES, hLineDataSources));
    dispatch(createAction(MANNET_ACTION_TYPES.REGISTER_HORIZONTAL_LINE, payload));

    dispatch(createDbmLabel(txLat, txLon, txAlt, rxLat, rxLon, rxAlt, viewer, dbm));
}

export const handleCreateBillboard = (viewer, latitude, longitude, height, id, billName) => dispatch => {
    const cartesian = Cartesian3.fromDegrees(longitude, latitude, height);
    const billboardEntity = createBillboardEntity(viewer, cartesian, id, false, billName, true);

    const payload = {
        latitude,
        longitude,
        height,
        id
    }

    dispatch(createAction(MANNET_ACTION_TYPES.REGISTER_BILLBOARD, payload));
    dispatch(handleTransmitterData(latitude, longitude, billboardEntity));
}

export const handleNotifyRightPannel = () => dispatch => {
    dispatch(createAction(MANNET_ACTION_TYPES.NOTIFY_RIGHT_PANNEL));
}

export const handleClearDataStorage = () => dispatch => {
    dispatch(createAction(MANNET_ACTION_TYPES.CLEAR_STORAGE));
}
export const handleClearNotify = () => dispatch => {
    dispatch(createAction(MANNET_ACTION_TYPES.CLEAR_NOTIFY));
}
export const handleClearErrorMessage = () => dispatch => {
    dispatch(createAction(MANNET_ACTION_TYPES.CLEAR_ERROR_MSESAGE));
}
export const handleCopyOriginalPayload = (payload) => dispatch => {
    dispatch(createAction(MANNET_ACTION_TYPES.COPY_ORIGINAL_PAYLOAD, payload))
}

export const handleCreatePoint = (payload) => dispatch => {
    CREATE_POINT(payload)
        .then(res => {
            dispatch(createAction(MANNET_ACTION_TYPES.RESPONSE_POINT, res))
        })
        .catch(e => {
            dispatch(createAction(MANNET_ACTION_TYPES.FAILED_MESSAGE, "Failed to create Mesh Simulation"));
        })
}

export const handleRegisterClickListener = (viewer) => (dispatch) => {

    const addEventListener = viewer.selectedEntityChanged.addEventListener(selectedEntity => {
        if (defined(selectedEntity)) {
            if (defined(selectedEntity.id)) {
                dispatch(createAction(MANNET_ACTION_TYPES.SELECTED_ENTITY, selectedEntity.id));
            } else {
                dispatch(createAction(MANNET_ACTION_TYPES.SELECTED_ENTITY, null));
            }
        } else {
            dispatch(createAction(MANNET_ACTION_TYPES.SELECTED_ENTITY, null));
        }
    });
    dispatch(createAction(MANNET_ACTION_TYPES.REGISTER_HANDLER_LISTENER, addEventListener))
};

export const handleModifyOriginalPayload = (modifiedData) => dispatch => {
    dispatch(createAction(MANNET_ACTION_TYPES.MODIFY_ORIGINAL_PAYLOAD, modifiedData));
}
export const handleModifyBillboardAndVLineData = (modifiedData) => dispatch => {
    dispatch(createAction(MANNET_ACTION_TYPES.MODIFY_BILLBOARD_AND_VLINE_DATA, modifiedData));
}

export const handleFetchMannetNetwork = (page = 0, size = 1000) => dispatch => {
    GET_ALL_MANNET_NETWORK(page, size)
        .then(res => {
            dispatch(createAction(MANNET_ACTION_TYPES.FETCH_MANNET_NETWORKS, res));
        })
        .catch(() => {})
}

export const handleFetchMannetByNetwork = (network) => dispatch => {
    GET_ALL_MANNET_BY_NETWORK(network)
        .then(res => {
            dispatch(createAction(MANNET_ACTION_TYPES.FETCH_MANNET, res));
        })
        .catch(() => {})
}
export const handleCreateMannet = (payload) => dispatch => {
    POST_MANNET(payload)
        .then(res => {
            dispatch(createAction(MANNET_ACTION_TYPES.CREATE_MANNET, res));
        })
        .catch(() => {})
}
export const handleUpdateMannet = (id, payload) => dispatch => {
    UPDATE_MANNET(id, payload)
        .then(res => {
            dispatch(createAction(MANNET_ACTION_TYPES.UPDATE_MANNET, res));
        })
        .catch(() => {})
}
export const handleDeleteMannet = (id) => dispatch => {
    DELETE_MANNET(id)
        .then(res => {
            dispatch(createAction(MANNET_ACTION_TYPES.DELETE_MANNET, res));
        })
        .catch(() => {})
}

export const handleSetTotalProgress = (count) => dispatch => {
    dispatch(createAction(MANNET_ACTION_TYPES.TOTAL_PROGRESS, count));
}
export const handleIsLoading = (isLoading) => dispatch => {
    dispatch(createAction(MANNET_ACTION_TYPES.IS_LOADING, isLoading));
}
export const handleSetTerrainHeigh = (data) => dispatch => {
    dispatch(createAction(MANNET_ACTION_TYPES.TERRAIN_HEIGHT, data));
}
