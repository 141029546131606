export const DISTRIBUTION_ACTION_TYPES = {
    FETCH_DATA_ASSET : 'distribution/FETCH_DATA_ASSET',
    FETCH_DATA_SITE : 'distribution/FETCH_DATA_SITE',
    OPEN_TOOLTIPS : 'distribution/OPEN_TOOLTIPS',
    REGISTER_HANDLER_LISTENER : 'distribution/REGISTER_HANDLER_LISTENER',
    FETCH_TOTAL_ASSET : 'distribution/FETCH_TOTAL_ASSET',
    SELECTED_SITE_ID : 'distribution/SELECTED_SITE_ID',
    FETCH_TOWER_CIVIL : 'distribution/FETCH_TOWER_CIVIL',
    FETCH_WORKSHOP : 'distribution/FETCH_WORKSHOP',
    FETCH_FO : 'distribution/FETCH_FO',
}

export const TYPE = {
    NS: "NS",
    FO: "FO",
    WORKSHOP: "WORKSHOP",
    TOWER_CIVIL: "TOWER_CIVIL",
}