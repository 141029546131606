import * as React from "react"

const OperationPlanningIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 8267.72 11692.91"
    {...props}
  >
    <g strokeMiterlimit={22.926}>
      <path
        fill="currentColor"
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M-292.65 165.63h8904.69c677.3 0 1231.45 554.15 1231.45 1231.45v8904.69c0 677.3-554.15 1231.46-1231.45 1231.46H-292.65c-677.3 0-1231.46-554.15-1231.46-1231.46V1397.08c0-677.3 554.15-1231.45 1231.46-1231.45zm304.78 389.1h8295.11c630.96 0 1147.14 516.19 1147.14 1147.14v8295.11c0 630.96-516.19 1147.14-1147.14 1147.14H12.13c-630.96 0-1147.14-516.19-1147.14-1147.14V1701.87c0-630.96 516.19-1147.14 1147.14-1147.14z"
      />
      <path
        fill="#2B2A29"
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M1023.15 2592.96h6909.81v4791.18H1023.15z"
      />
      <g stroke="#2B2A29">
        <path
          fill="#FEFEFE"
          strokeWidth={98.43}
          d="M1313.94 2592.96h6619.02v4513.37H1313.94V2592.96zm3707.26 186.6h2768.56v2491.01H5021.2V2779.56z"
        />
        <path
          fill="#FEFEFE"
          strokeWidth={7.87}
          d="M5021.2 2779.56h2768.56v2491.01H5021.2V2779.56zm110.92 99.8h2546.71v2291.41H5132.12V2879.36z"
        />
        <path
          fill="#FEFEFE"
          strokeWidth={7.87}
          d="M7535.17 3755.99c0 393.07-299.19 711.72-668.27 711.72-369.07 0-668.26-318.65-668.26-711.72 0-393.07 299.19-711.72 668.26-711.72v711.72h668.27z"
        />
        <path
          fill="#FEFEFE"
          strokeWidth={7.87}
          d="M6928.36 3055.11c330.97 0 599.27 285.75 599.27 638.24h-599.27v-638.24z"
        />
        <path
          fill="#2B2A29"
          strokeWidth={7.87}
          d="M1616.26 3022.55h3102.67v138.87H1616.26zM1616.26 3308.98h3102.67v138.87H1616.26zM1616.26 3595.4h3102.67v138.87H1616.26zM1616.26 3881.83h3102.67v138.87H1616.26z"
        />
        <path
          fill="#FEFEFE"
          strokeWidth={7.87}
          d="M1616.26 4168.26h3102.67v138.87H1616.26z"
        />
        <path
          fill="#2B2A29"
          strokeWidth={7.87}
          d="M1616.26 4168.26h3102.67v138.87H1616.26zM1616.26 4454.68h3102.67v138.87H1616.26zM1616.26 4741.11h3102.67v138.87H1616.26zM1616.26 5027.53h3102.67v138.87H1616.26zM1616.26 5313.96h3102.67v138.87H1616.26zM1616.26 5600.39H7785.8v138.87H1616.26zM1616.26 5886.81H7785.8v138.87H1616.26z"
        />
      </g>
      <path
        fill="#FEFEFE"
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="m5784.88 4007.42 130.42 418.02 130.41 418.01h-521.66l130.41-418.01z"
      />
      <ellipse
        fill="#FEFEFE"
        stroke="#2B2A29"
        strokeWidth={7.87}
        cx={2290.52}
        cy={6269.47}
        rx={1416.57}
        ry={1401.63}
      />
      <ellipse
        fill="#FEFEFE"
        stroke="#2B2A29"
        strokeWidth={7.87}
        cx={5393.84}
        cy={7467.33}
        rx={985.91}
        ry={975.52}
      />
      <g fill="#2B2A29" stroke="#FEFEFE">
        <path
          strokeWidth={118.11}
          d="m2638.59 3968.56 1128.37 651.46-275.98 478.01c143.94 170.77 258.54 367.08 336.04 581.16h551.45v1302.93h-551.45c-77.5 214.07-192.1 410.39-336.04 581.16l275.98 478-1128.37 651.46-275.83-477.75c-108.99 19.28-221.16 29.34-335.68 29.34-114.52 0-226.7-10.06-335.69-29.34l-275.83 477.75-1128.37-651.46 275.98-478c-143.94-170.77-258.54-367.08-336.04-581.16h-551.45V5679.19h551.45c77.5-214.07 192.1-410.39 336.04-581.16l-275.98-478.01 1128.37-651.46 275.83 477.75c108.99-19.29 221.16-29.34 335.69-29.34 114.52 0 226.69 10.06 335.68 29.34l275.83-477.75zm-611.51 1303.5c584.65 0 1058.59 473.95 1058.59 1058.6 0 584.65-473.95 1058.6-1058.59 1058.6-584.65 0-1058.6-473.95-1058.6-1058.6 0-584.65 473.95-1058.6 1058.6-1058.6z"
        />
        <path
          strokeWidth={59.06}
          d="m5807.48 6040.58 702.99 405.87-171.94 297.81c89.68 106.39 161.08 228.7 209.36 362.07h343.56v811.74h-343.56c-48.28 133.37-119.69 255.68-209.36 362.07l171.94 297.81-702.99 405.87-171.85-297.65c-67.9 12.02-137.78 18.28-209.13 18.28s-141.23-6.27-209.13-18.28l-171.85 297.65-702.99-405.87 171.94-297.81c-89.68-106.39-161.08-228.7-209.36-362.07h-343.56v-811.74h343.56c48.28-133.37 119.69-255.68 209.36-362.07l-171.94-297.81 702.99-405.87 171.85 297.65c67.9-12.02 137.78-18.28 209.13-18.28s141.23 6.27 209.13 18.28l171.85-297.65zm-380.98 812.1c364.24 0 659.52 295.28 659.52 659.52 0 364.25-295.28 659.53-659.52 659.53s-659.52-295.28-659.52-659.53c0-364.24 295.28-659.52 659.52-659.52z"
        />
      </g>
    </g>
  </svg>
)

export default OperationPlanningIcon
