import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {handleSignIn, removeError} from "../../store/user/user.action";
import {getErrorMessage, isError} from "../../store/user/user.selector";

import {generalAlert} from "../../utils/notification/notification";

import EyeIcon from "../../assets/icons/hide.png";
import EyeHideIcon from "../../assets/icons/view.png";
import logoTNIEmas from "../../assets/png/logo-tni-emas.png";
import titeLogo from "../../assets/png/title_logo.png";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
    const [credentials, setCredentials] = useState({
        username: JSON.parse(localStorage.getItem("credentials"))?.username,
        password: JSON.parse(localStorage.getItem("credentials"))?.password
    });
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const errorIndicator = useSelector(isError);
    const errorMessage = useSelector(getErrorMessage);

    useEffect(() => {
        // Check login information on localStorage
        const storedCredentials = localStorage.getItem("credentials");
        const storedRememberMe = localStorage.getItem("rememberMe");
    
        if (storedCredentials && storedRememberMe) {
            setCredentials(JSON.parse(storedCredentials))
            setRememberMe(true);
        }
    }, []);

    useEffect(() => {
        if (errorIndicator) {
            if (errorMessage && errorMessage.message) {
                generalAlert("Login", errorMessage.message, "error", () => dispatch(removeError()));
            } else {
                generalAlert("Login", "The Username or Password is Incorrect", "error", () => dispatch(removeError()));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorIndicator]);

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(handleSignIn(credentials, navigate));

        if (rememberMe) {
            // Save login information to localStorage
            localStorage.setItem("credentials", JSON.stringify(credentials));
            localStorage.setItem('rememberMe', true);
        } else {
            // Remove login information from localStorage
            localStorage.removeItem("credentials");
            localStorage.removeItem("rememberMe");
        }
    };

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    };

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value});
    };

    return (
        <div className="flex items-center inset-y-1/2 flex-col">
            <img src={titeLogo} className=" mb-7 mt-3 ml-40" alt="logo"/>
            <form
                className="flex flex-col justify-center items-start gap-y-2  ml-32 bg-tertiary/[.7] border-2 border-[#3384FF] p-8 rounded-xl"
                onSubmit={handleLogin}
            >
                <img
                    src={logoTNIEmas}
                    className="w-[80px] h-[80px] self-center mb-3"
                    alt="logo"
                />
                <label
                    htmlFor="username"
                    className="text-white font-semibold text-[13px]"
                >
                    Username
                </label>
                <input
                    type="text"
                    name="username"
                    placeholder="Input your username"
                    value={credentials.username}
                    onChange={handleChange}
                    autoFocus
                    className="w-[250px] px-3 py-2 rounded-md text-[12px] focus:outline outline-2 focus:outline-[#3384FF]"
                />

                <label
                    htmlFor="password"
                    className="text-white font-semibold text-[13px]"
                >
                    Password
                </label>
                <div className="flex justify-between items-center">
                    <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={credentials.password}
                        onChange={handleChange}
                        placeholder="Input your password"
                        className="w-[250px] px-3 py-2 rounded-md text-[12px] focus:outline outline-2 focus:outline-[#3384FF] mr-[-26px]"
                    />
                    <div className="cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                        <img
                            src={showPassword ? EyeIcon : EyeHideIcon}
                            alt="eye"
                            width={16}
                            height={18}
                        />
                    </div>
                </div>

                <div className="flex items-center mb-4 mt-3">
                    <input
                        type="checkbox"
                        name="rememberMe"
                        className="w-4 h-4 cursor-pointer"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                    />
                    <label htmlFor="rememberMe" className="ml-2 text-[13px] text-white">
                        Remember Me
                    </label>
                </div>

                <button
                    type="submit"
                    className="text-white font-semibold bg-[#031952] w-[250px] p-2 rounded-md text-[13px]"
                >
                    Login
                </button>

                <p className="text-white text-xs self-center mt-5">
                    Powered by signalitix
                </p>
            </form>
        </div>
    );
};

export default LoginForm;
