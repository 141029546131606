import * as React from "react";

const GroundToGround = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1683 1683"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="#FEFEFE"
      strokeWidth={8.738}
      d="M636 752h337V450c-33-11-57-42-57-79 0-46 37-83 83-83s83 37 83 83c0 37-24 68-57 79v302h22c8 0 15 7 15 16v719c0 8-7 15-15 15H636c-8 0-15-7-15-15V768c0-9 7-16 15-16z"
    />
    <rect
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={8.738}
      x={669}
      y={838}
      width={346}
      height={213}
      rx={12}
      ry={7}
    />
    <rect
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={8.738}
      x={686}
      y={1101}
      width={70.947}
      height={43.839}
      rx={2}
      ry={1}
    />
    <rect
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={8.738}
      x={788}
      y={1101}
      width={104.96}
      height={43.839}
      rx={4}
      ry={1}
    />
    <rect
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={8.738}
      x={788}
      y={1189}
      width={104.96}
      height={43.839}
      rx={4}
      ry={1}
    />
    <rect
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={8.738}
      x={649}
      y={1395}
      width={385.452}
      height={14.615}
      rx={13}
      ry={0}
    />
    <rect
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={8.738}
      x={649}
      y={1339}
      width={385.452}
      height={14.615}
      rx={13}
      ry={0}
    />
    <rect
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={8.738}
      x={649}
      y={1279}
      width={385.452}
      height={14.615}
      rx={13}
      ry={0}
    />
    <rect
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={8.738}
      x={925}
      y={1101}
      width={70.947}
      height={43.839}
      rx={2}
      ry={1}
    />
    <rect
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={8.738}
      x={686}
      y={1189}
      width={70.947}
      height={43.839}
      rx={2}
      ry={1}
    />
    <rect
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={8.738}
      x={925}
      y={1189}
      width={70.947}
      height={43.839}
      rx={2}
      ry={1}
    />
    <g fill="currentColor" stroke="currentColor" strokeWidth={1.165}>
      <path d="M1159 361c3 48-27 90-71 103 26-24 41-60 39-99-3-43-26-80-60-100 50 4 89 45 92 96z" />
      <circle transform="scale(3.0645) rotate(50.488 33.419 403.967)" r={18} />
      <path d="M1255 356c4 76-45 144-115 166 41-39 66-97 62-159-4-69-41-128-95-161 79 8 143 72 148 154z" />
      <path d="M1366 351c6 96-55 180-143 208 52-49 82-121 78-199-5-87-52-160-119-201 99 10 178 90 184 192zM826 382c-3-48 28-90 72-104-26 24-41 60-39 99 3 43 26 80 59 101-49-5-89-45-92-96z" />
      <path d="M731 387c-5-77 44-144 114-166-41 39-65 96-62 159 4 69 42 127 95 160-79-8-142-72-147-153z" />
      <path d="M619 391c-5-95 56-180 144-207-52 49-83 121-78 199 5 86 52 159 119 201-99-10-179-90-185-193z" />
    </g>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.165}
      d="M183 1h1317c101 0 183 82 183 182v1317c0 101-82 183-183 183H183c-100 0-182-82-182-183V183C1 83 83 1 183 1zm45 57h1227c94 0 170 77 170 170v1227c0 94-76 170-170 170H228c-93 0-170-76-170-170V228c0-93 77-170 170-170z"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={4.369}
      d="M1025 450v302h22c8 0 15 7 15 16v719c0 8-7 15-15 15H636c-8 0-15-7-15-15V768c0-9 7-16 15-16h337V450c-33-11-57-42-57-79 0-46 37-83 83-83s83 37 83 83c0 37-24 68-57 79z"
    />
  </svg>
);

export default GroundToGround;
