export const ANTENNA_ACTION_TYPES = {
    GET_STATUS : 'antenna/GET_STATUS',
    GET_ALL_ANTENNA : 'antenna/GET_ALL_ANTENA',
    GET_CATEGORY : 'antenna/GET_CATEGORY',
    GET_BRAND : 'antenna/GET_BRAND',
    GET_TYPE : 'antenna/GET_TYPE',
    GET_SITE : 'antenna/GET_SITE',
    CLEAR_DATA : 'antenna/CLEAR_DATA',
    CHANGE_PAGE : 'antenna/CHANGE_PAGE',
    UPLOAD_ANTENNA_STATUS : 'antenna/UPLOAD_ANTENA_STATUS',
    UPLOAD_IMAGE_STATUS : 'antenna/UPLOAD_IMAGE_STATUS',
    UPLOAD_FILE_STATUS : 'antenna/UPLOAD_FILE_STATUS',
    UPLOAD_IMAGE : 'antenna/UPLOAD_IMAGE',
    UPLOAD_FILE : 'antenna/UPLOAD_FILE',
    IS_LOADING : 'antenna/IS_LOADING',
    REMOVED : 'antenna/REMOVED',
    SEARCH : 'antenna/SEARCH',
    SEARCH_REPORT : 'antenna/SEARCH_REPORT',
    UPDATED : 'antenna/UPDATED',
    GET_COAXIAL_TYPE : 'antenna/GET_COAXIAL_TYPE',
    CLEAR_DETAIL_ANTENNA : 'antenna/CLEAR_DETAIL_ANTENNA',
    DETAIL_ANTENNA : 'antenna/DETAIL_ANTENNA',
}