import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";

import { selectViewer } from "../../../store/dashboard/dashboard.selector";
import { report } from "../../../utils/report/report";
import { takeSnap } from "../../../utils/cesium/cesiumUtils";

import LambangTNI from "../../../assets/png/Lambang_TNI_AU.png";

const ReportRadioLinkBudget = ({ selectedData, chartImage, pathRes }) => {
    const [profile, setProfile] = useState([]);

    const viewer = useSelector(selectViewer);

    const detailSelectedData = () => {
        if (selectedData) {
            const { name, network } = selectedData?.data;
            const {
                lat: latitude,
                lon: longitude,
                alt: highTower,
                frq: frequency,
                txw: txPower,
                bwi: bandwidth,
            } = selectedData?.data?.transmitter;
            const {
                txl: loss,
                txg: transmitterGain,
                pol: polarization,
            } = selectedData?.data?.antenna;
            const {
                alt: altitude,
                rxg: rxGain,
                rxs: sensitivity,
            } = selectedData?.data?.receiver;
            const {
                res: resolution,
                col: colorSchema,
                rad: radius,
            } = selectedData?.data?.output;
            const { pm: model, rel: reliability } = selectedData?.data?.model;
            const { area, coverage } = selectedData?.result;
            const {
                coaxialTypeDto: { name: coaxialType },
                coaxialLength,
                connector,
                erp,
                eirp,
                effieciency,
                oriAlt,
                patternDto: { name: patternName, img1: patternImg1, img2: patternImg2 },
            } = selectedData?.data?.additionalDTO;
            return {
                name,
                network,
                latitude,
                longitude,
                highTower,
                frequency,
                txPower,
                bandwidth,
                loss,
                transmitterGain,
                polarization,
                altitude,
                rxGain,
                sensitivity,
                resolution,
                colorSchema,
                radius,
                model,
                reliability,
                area,
                coverage,
                coaxialType,
                coaxialLength,
                connector,
                erp,
                eirp,
                effieciency,
                oriAlt,
                patternName,
                patternImg1,
                patternImg2,
            };
        }
    };

    const detailTxandRx = () => {
        if (pathRes) {
            const {
                distanceToReceiverKm,
                downtiltAngleDeg,
                azimuthToReceiverDeg,
                fieldStrengthAtReceiverdBuVm,
                freeSpacePathLossdB,
                computedPathLossDB,
                signalPowerAtReceiverDBm,
            } = pathRes?.resultPath?.transmitters[0];
            const { latitude, longitude } = pathRes?.resultPath?.receiver[0];
            return {
                distance: distanceToReceiverKm,
                downtiltToRx: downtiltAngleDeg,
                azimuthToRx: azimuthToReceiverDeg,
                fieldStrength: fieldStrengthAtReceiverdBuVm,
                freespaceLoss: freeSpacePathLossdB,
                latitudeRx: latitude,
                longitudeRx: longitude,
                pathLoss: computedPathLossDB,
                receivedPower: signalPowerAtReceiverDBm,
            };
        }
    };

    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem("profile"));
        setProfile(userProfile);
    }, []);

    const handlePdfGenerate = () => {
        // Format
        const doc = new jsPDF("potrait", "px", "a4", "false");

        const {
            distance,
            downtiltToRx,
            azimuthToRx,
            fieldStrength,
            freespaceLoss,
            latitudeRx,
            longitudeRx,
            pathLoss,
            receivedPower,
        } = detailTxandRx();
        
        const {
            name,
            network,
            latitude,
            longitude,
            highTower,
            frequency,
            txPower,
            bandwidth,
            loss,
            transmitterGain,
            polarization,
            rxGain,
            sensitivity,
            resolution,
            colorSchema,
            radius,
            model,
            reliability,
            area,
            coverage,
            coaxialType,
            coaxialLength,
            connector,
            erp,
            eirp,
            effieciency,
            oriAlt,
            patternName,
            patternImg1,
            patternImg2,
        } = detailSelectedData();

        // Template
        report.template(doc, 1, profile, LambangTNI, "Report Radio Link Budget");

        // Ground Transmitter / TX
        report.transmitterRadioLinkBudget(
            doc,
            "RadioLinkBudget",
            name,
            network,
            latitude,
            longitude,
            highTower,
            frequency,
            txPower,
            bandwidth,
            azimuthToRx,
            downtiltToRx,
            distance
        );

        // Feeder
        report.feederRadioLinkBudget(
            doc,
            coaxialType,
            coaxialLength,
            connector,
            loss,
            erp,
            eirp,
            effieciency
        );

        // Airbone
        report.airboneRadioLinkBudget(
            doc,
            latitudeRx,
            longitudeRx,
            oriAlt,
            rxGain,
            sensitivity,
            fieldStrength,
            freespaceLoss,
            pathLoss,
            receivedPower
        );

        // Propagation Model
        report.propagationModelRadioLinkBudget(doc, model, reliability);

        // Antenna
        report.antennaRadioLinkBudget(
            doc,
            transmitterGain,
            polarization,
            patternName,
            patternImg1,
            patternImg2
        );

        // Output Design
        report.outputDesignRadioLinkBudget(doc, resolution, colorSchema, radius);

        // Covearage Information
        report.coverageInfoRadioLinkBudget(doc, "RadioLinkBudget", area, coverage);

        // Add new page
        report.addPage(doc);

        // Template appendix
        report.templateAppendix(doc, 2, profile, LambangTNI);

        // Image Coverage
        report.coverageImg(doc, takeSnap(viewer));

        // Chart Image
        try {
            report.chartImgRadioLink(doc, chartImage);
        } catch (error) {
            console.error(error);
        }

        // Save
        report.savePdf(doc, "Radio Link Budget");
    };

    return (
        <button
            type="button"
            className="btn btn-primary text-white ml-4"
            onClick={handlePdfGenerate}
        >
            Create Report
        </button>
    );
};

export default ReportRadioLinkBudget;
