import * as React from "react";

const TowerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1685 1685"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="#FEFEFE"
      strokeWidth={17.494}
      d="m1099 1247 220 3-245 285-70-254 95-34zm-90 288H352l6-25 363-129h254l34 154zm-212-181 160-57 12 57H797z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.166}
      d="M559 548h243v2l272 985h-65l-34-154H386l-34 154h-64l271-987zm8 9-39 178h305l-39-178H567zm402 797-43-199H436l-44 199h577zm-48-221-41-187H482l-41 187h480zm-45-205-40-180H525l-39 180h390z"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={1.166}
      d="M1395 528h1.976v.687H1395z"
    />
    <g strokeWidth={5.832}>
      <path
        fill="currentColor"
        stroke="#FEFEFE"
        d="m871 473-40 41c-30 31-100 9-157-50s-78-133-48-164l3-3 34-35v-1c-26 33-4 104 51 161 57 59 127 82 157 51z"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        d="M911 453c30-32 9-105-48-164s-127-82-157-51c-30 32-9 105 48 164s127 82 157 51z"
      />
      <path
        fill="#FEFEFE"
        stroke="currentColor"
        d="M721 228c11 45 37 92 76 134 38 39 82 66 122 78 17-36-6-99-56-151-49-51-108-75-142-61z"
      />
      <g fill="currentColor" stroke="#FEFEFE">
        <path d="m921 222-49 31-89 56c9 16 22 32 38 48 4 5 9 9 14 13l65-84 35-46c-7-3-13-10-14-18z" />
        <path d="M940 241c11 0 21-9 22-21 1-11-8-22-19-23-12 0-21 9-22 21-1 11 8 22 19 23z" />
      </g>
      <path
        fill="currentColor"
        stroke="currentColor"
        d="m782 522 8 14H572l55-90 26-43c2 4 5 8 7 13l-18 30-40 65h156c8 4 16 8 24 11z"
      />
    </g>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.166}
      d="M183 1h1319c100 0 182 82 182 182v1319c0 100-82 182-182 182H183c-100 0-182-82-182-182V183C1 83 83 1 183 1zm45 57h1229c93 0 170 77 170 170v1229c0 93-77 170-170 170H228c-93 0-170-77-170-170V228c0-93 77-170 170-170z"
    />
  </svg>
);

export default TowerIcon;
