import * as React from "react"

const FiberOpticIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 8267.72 11692.91"
    {...props}
  >
    <g stroke="#2B2A29" strokeWidth={7.87} strokeMiterlimit={22.926}>
      <path
        fill="currentColor"
        d="M-209.79 185.36H8694.9c677.3 0 1231.45 554.15 1231.45 1231.45v8904.69c0 677.3-554.15 1231.46-1231.45 1231.46H-209.79c-677.3 0-1231.46-554.15-1231.46-1231.46V1416.81c0-677.3 554.15-1231.45 1231.46-1231.45zm304.78 389.1H8390.1c630.96 0 1147.14 516.19 1147.14 1147.14v8295.11c0 630.96-516.19 1147.14-1147.14 1147.14H94.99c-630.96 0-1147.14-516.19-1147.14-1147.14V1721.6c0-630.96 516.19-1147.14 1147.14-1147.14z"
      />
      <circle
        fill="none"
        transform="matrix(.24695 -.24695 .42607 .42607 7311.84 3887.67)"
        r={1444.44}
      />
      <path
        fill="currentColor"
        d="M6696.41 3272.24c79.06-79.06 196.51-107.71 330.75-91.72l-84.32 84.32c-68.43 9.2-127.84 35.66-173.01 80.82-173.5 173.5-71.48 556.81 227.86 856.15 299.34 299.34 682.66 401.36 856.15 227.87 45.18-45.18 71.65-104.59 80.84-173.04l84.3-84.31c15.98 134.25-12.65 251.7-91.72 330.76L4309.35 8121c-197.01 197.01-632.24 81.17-972.13-258.72-339.9-339.9-455.73-775.13-258.73-972.13L6696.4 3272.24zm1100.89 513.02a1180.512 1180.512 0 0 0-31.7-46.98l44.13-44.13c11.33 15.26 22.23 30.59 32.71 45.98l-45.13 45.13zm-336.08-351.35a1184.07 1184.07 0 0 0-46.97-31.71l45.12-45.12c15.38 10.48 30.71 21.39 45.96 32.71l-44.11 44.12zM6172.1 3828.54c146.71-146.71 330.33-200.94 410.14-121.13s25.58 263.43-121.13 410.14L3766.86 6811.8c-146.71 146.71-330.33 200.94-410.14 121.13s-25.57-263.43 121.13-410.14L6172.1 3828.54z"
      />
      <circle
        fill="none"
        transform="rotate(-45 7637.58 -8607.258) scale(.08064 .13913)"
        r={1444.44}
      />
      <path
        fill="currentColor"
        d="m8463.94 3019.76-835.2 835.2c-45.49 45.49-145.98 18.74-224.46-59.74-78.48-78.48-105.23-178.98-59.74-224.46l835.4-835.4c-45.49 45.49-18.74 145.99 59.74 224.47 78.37 78.37 178.69 105.15 224.27 59.93z"
      />
      <circle
        fill="none"
        transform="rotate(-45 8304.528 -8538.582) scale(.08064 .13913)"
        r={1444.44}
      />
      <path
        fill="currentColor"
        d="m8611.94 3513.65-835.2 835.2c-45.49 45.49-145.98 18.74-224.46-59.74-78.48-78.48-105.23-178.98-59.74-224.46l835.4-835.4c-45.49 45.49-18.74 145.99 59.74 224.48 78.37 78.37 178.69 105.15 224.27 59.93z"
      />
      <circle
        fill="#FEFEFE"
        transform="rotate(-45 7865.119 -8049.76) scale(.08064 .13913)"
        r={1444.44}
      />
      <path
        fill="currentColor"
        d="m8137.59 3346.11-835.2 835.2c-45.49 45.49-145.98 18.74-224.46-59.74-78.48-78.48-105.23-178.98-59.74-224.46l835.4-835.4c-45.49 45.49-18.74 145.99 59.74 224.47 78.37 78.37 178.69 105.15 224.27 59.93z"
      />
      <circle
        fill="none"
        transform="rotate(-45 7193.215 -8122.242) scale(.08064 .13913)"
        r={1444.44}
      />
      <path
        fill="currentColor"
        d="m7992.05 2849.77-835.2 835.2c-45.49 45.49-145.98 18.74-224.46-59.74-78.48-78.48-105.23-178.98-59.74-224.46l835.4-835.4c-45.49 45.49-18.74 145.99 59.74 224.47 78.37 78.37 178.69 105.15 224.27 59.93z"
      />
      <circle
        fill="none"
        transform="matrix(.04045 -.04045 .0698 .0698 8454.18 2107.43)"
        r={1444.44}
      />
      <path
        fill="currentColor"
        d="m8554.85 2208.38-592.52 592.52c-32.27 32.27-103.57 13.29-159.24-42.39-55.68-55.68-74.65-126.97-42.39-159.24l592.66-592.66c-32.27 32.27-13.3 103.57 42.39 159.25 55.59 55.59 126.76 74.6 159.11 42.52z"
      />
      <circle
        fill="none"
        transform="matrix(.04045 -.04045 .0698 .0698 8925.8 2279.29)"
        r={1444.44}
      />
      <path
        fill="currentColor"
        d="m9026.47 2380.24-592.52 592.52c-32.27 32.27-103.57 13.29-159.24-42.39-55.68-55.67-74.66-126.97-42.39-159.24l592.66-592.66c-32.28 32.27-13.3 103.57 42.38 159.25 55.6 55.59 126.77 74.6 159.11 42.52z"
      />
      <circle
        fill="none"
        transform="matrix(.04045 -.04045 .0698 .0698 9077.67 2758.9)"
        r={1444.44}
      />
      <path
        fill="currentColor"
        d="m9178.35 2859.85-592.52 592.52c-32.27 32.27-103.57 13.29-159.24-42.39-55.68-55.68-74.65-126.97-42.38-159.24l592.66-592.66c-32.27 32.28-13.3 103.57 42.39 159.25 55.59 55.59 126.77 74.6 159.11 42.52z"
      />
      <circle
        fill="#FEFEFE"
        transform="matrix(.04045 -.04045 .0698 .0698 8606.05 2599.04)"
        r={1444.44}
      />
      <path
        fill="currentColor"
        d="m8706.73 2699.99-592.52 592.52c-32.27 32.27-103.57 13.29-159.24-42.39-55.68-55.68-74.66-126.97-42.39-159.24l592.66-592.66c-32.27 32.28-13.3 103.57 42.39 159.25 55.59 55.59 126.76 74.6 159.11 42.52zM4576.14 8075.88h2910.09v1063.94H-759.25V8428.1h4557.73c166.3 0 408.67 16.78 525.65-100.21l252.01-252z"
      />
      <path
        fill="currentColor"
        d="M-774.03 8221.21V6287.18h4455.5l-602.97 602.97c-197 197-81.17 632.24 258.73 972.13 190.33 190.33 410.55 310.37 602.1 344.6a851.671 851.671 0 0 1-155.63 14.33H-774.03z"
      />
    </g>
  </svg>
)

export default FiberOpticIcon
