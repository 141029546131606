import { ALERT_ICON_FAILED, ALERT_TITLE_FAILED } from "../constant/constants";
import { generalAlert } from "../notification/notification";

export const validationImage = (file) => {
    const validExtensions = ["png", "jpeg", "jpg", "gif", "tiff"];

    let imageExt = [];

    for (let i = 0; i < file.length; i++) {
        const fileExtension = file[i].type.split("/")[1];
        imageExt.push(fileExtension);
    }

    const isValid = imageExt.every((extension) => validExtensions.includes(extension));
    
    if (!isValid) return generalAlert(ALERT_TITLE_FAILED, "Please upload only IMAGE file (jpeg, jpg, png, gif or tiff)", ALERT_ICON_FAILED);

    return isValid;
};

export const validationFile = (file) => {
    const validExtensions = ["pdf"];

    let fileExt = [];

    for (let i = 0; i < file.length; i++) {
        const fileExtension = file[i].type.split("/")[1];
        fileExt.push(fileExtension);
    }

    const isValid = fileExt.every((extension) => validExtensions.includes(extension));

    if (!isValid) return generalAlert(ALERT_TITLE_FAILED, "Please upload only PDF file", ALERT_ICON_FAILED);

    return isValid;
};
