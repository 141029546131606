import {ANTENNA_ACTION_TYPES} from "./antenna.types";
import {STATUS_TYPE} from "../../utils/constant/constants";
import { addIntoArray } from "./antenna.utils";

const INITIAL_STATE = {
    status: null,  // {}
    antennas: null, // {radio:{content:[]}}
    detailAntenna: null, // {}
    categories: null,  // []
    brands: null,  // []
    types: null, // []
    coaxialTypes:null, // []
    sites: null, // {content:[]}
    statusArray: [
        {
            key: "SERVICEABLE",
            name: "SERVICEABLE",
        },
        {
            key: "SPARE",
            name: "SPARE",
        },
        {
            key: "UNSERVICEABLE",
            name: "UNSERVICEABLE",
        },
    ],
    
    column: [
        { heading: "Name", value: "name" },
        { heading: "Brand", value: "brand.name" },
		{ heading: "Type", value: "type.name" },
		{ heading: "Serial Number", value: "serialNo" },
		{ heading: "Owner", value: "site.siteName" },
		{ heading: "Status", value: "status" },
    ],
    uploadAntennaStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedImages: [],
    uploadImageStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedFiles: [],
    uploadFileStatus: STATUS_TYPE.NO_STATEMENT,
    isLoading: false,
    removeStatus: STATUS_TYPE.NO_STATEMENT,
    updateStatus: STATUS_TYPE.NO_STATEMENT,
    images: [],
}

export const antennaReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case ANTENNA_ACTION_TYPES.GET_ALL_ANTENNA:
            return {...state, antennas: payload};
        case ANTENNA_ACTION_TYPES.SEARCH:
            return {...state, antennas: payload};
        case ANTENNA_ACTION_TYPES.SEARCH_REPORT:
            return {...state, antennasReport: payload};
        case ANTENNA_ACTION_TYPES.DETAIL_ANTENNA:
            return {...state, detailAntenna: payload};
        case ANTENNA_ACTION_TYPES.GET_COAXIAL_TYPE:
            return {...state, coaxialTypes: payload};    
        case ANTENNA_ACTION_TYPES.CLEAR_DETAIL_ANTENNA:
            return {...state, detailAntenna: null};
        case ANTENNA_ACTION_TYPES.GET_CATEGORY:
            return {...state, categories: payload};
        case ANTENNA_ACTION_TYPES.GET_TYPE:
            return {...state, types: payload};
        case ANTENNA_ACTION_TYPES.GET_BRAND:
            return {...state, brands: payload};
        case ANTENNA_ACTION_TYPES.GET_SITE:
            return {...state, sites: payload};
        case ANTENNA_ACTION_TYPES.CLEAR_DATA:
            return INITIAL_STATE;
        case ANTENNA_ACTION_TYPES.UPLOAD_ANTENNA_STATUS:
            return {...state, uploadAntennaStatus: payload, uploadedFiles: [], uploadedImages: []}
        case ANTENNA_ACTION_TYPES.UPLOAD_IMAGE:
            return {...state, uploadedImages: payload}
        case ANTENNA_ACTION_TYPES.UPLOAD_IMAGE_STATUS:
            return {...state, uploadImageStatus: payload}
        case ANTENNA_ACTION_TYPES.UPLOAD_FILE:
            const newArray = addIntoArray(state.uploadedFiles, payload);
            return {...state, uploadedFiles: newArray}
        case ANTENNA_ACTION_TYPES.UPLOAD_FILE_STATUS:
            return {...state, uploadFileStatus: payload}
        case ANTENNA_ACTION_TYPES.REMOVED:
            return {...state, removeStatus: payload}
        case ANTENNA_ACTION_TYPES.UPDATED:
            return {...state, updateStatus: payload}
        default:
            return state;
    }
}