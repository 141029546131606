import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ReactPaginate from "react-paginate";

import HeaderPage from "../../../components/header/HeaderPage";
import Table from "../../../components/table/Table";
import {
    ALERT_ICON_FAILED,
    ALERT_ICON_SUCCESS,
    ALERT_TEXT_REMOVE_FAILED,
    ALERT_TEXT_REMOVE_SUCCESS,
    ALERT_TITLE_FAILED,
    ALERT_TITLE_SUCCESS,
    ASSET_TYPE,
    CONFIRMATION_ICON_REMOVE,
    CONFIRMATION_TEXT_REMOVE,
    CONFIRMATION_TITLE_REMOVE
} from "../../../utils/constant/constants";
import {
    handleFetchMannetByNetwork,
    handleFilterByType,
    handleRemoveArchive,
    handleRemoveByNetwork,
    handleResetRemoveByNetworkStatus,
    handleResetRemoveStatus
} from "../../../store/admin/admin.action";
import {
    AREA,
    COLUMN_SIMULATION,
    COLUMN_SIMULATION_MESH,
    EMPTY_STRING,
    FAILED,
    GTA,
    GTG,
    JAMMER,
    MESH,
    PATH,
    SUCCESS
} from "../../../store/admin/admin.types";
import {
    selectDeleteMannetByNetworkStatus,
    selectRemoveArchiveStatus,
    selectResponseData,
    selectTotalPage
} from "../../../store/admin/admin.selector";
import SearchBox from "../../../components/search-box/search-box.component";
import {handleClearStateGlobal, handleLoading} from "../../../store/global/global.action";
import {selectAssetToRemove, selectCurrentItem} from "../../../store/global/global.selector";
import {generalAlert, generalConfirmation} from "../../../utils/notification/notification";

const Simulation = () => {
    const [disableCoverageType, setDisableCoverageType] = useState(false);
    const [selectedCoverageType, setSelectedCoverageType] = useState(GTA);
    const [page, setPage] = useState(0);
    const [selectedAnalyzeType, setSelectedAnalyzeType] = useState(AREA);
    const [searchText, setSearchText] = useState(EMPTY_STRING);
    const [searchTextOnChange, setSearchTextOnChange] = useState(EMPTY_STRING);
    const responseData = useSelector(selectResponseData);
    const totalPages = useSelector(selectTotalPage);
    const assetToRemove = useSelector(selectAssetToRemove);
    const currentItem = useSelector(selectCurrentItem);
    const removeStatus = useSelector(selectRemoveArchiveStatus);
    const deleteMannetByNetworkStatus = useSelector(selectDeleteMannetByNetworkStatus);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(handleLoading(true));
        dispatch(handleFilterByType(GTA, AREA, page, searchText));

        return () => {
            dispatch(handleClearStateGlobal());
            setSelectedCoverageType(GTA);
            setSelectedAnalyzeType(AREA);
            setSearchText(EMPTY_STRING);
            setSearchTextOnChange(EMPTY_STRING);
            setPage(0);
            dispatch(handleLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * REMOVE DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.SIMULATION_RESULT === assetToRemove && currentItem) {
            generalConfirmation(
                CONFIRMATION_TITLE_REMOVE,
                CONFIRMATION_TEXT_REMOVE(currentItem.name),
                CONFIRMATION_ICON_REMOVE,
                () => {
                    if (selectedAnalyzeType === MESH) {
                        dispatch(handleRemoveByNetwork(currentItem.name));
                    } else {
                        dispatch(handleRemoveArchive(currentItem.id));
                    }
                },
                () => {
                    if (selectedAnalyzeType === MESH) {
                        dispatch(handleResetRemoveByNetworkStatus());
                    }
                    dispatch(handleClearStateGlobal());
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToRemove]);

    useEffect(() => {
        if (removeStatus) {
            handleRequestQuery();
            const isSuccess = SUCCESS === removeStatus;
            const isFailed = FAILED === removeStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_REMOVE_SUCCESS : ALERT_TEXT_REMOVE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => {
                        dispatch(handleResetRemoveStatus());
                        dispatch(handleClearStateGlobal());
                    }
                );
            }
            if (responseData && responseData.length) {
                dispatch(handleFilterByType(selectedCoverageType, selectedAnalyzeType, page, searchText));
            } else {
                if (page) {
                    dispatch(handleFilterByType(selectedCoverageType, selectedAnalyzeType, page - 1, searchText));
                    setPage(prevPage => prevPage - 1);
                } else {
                    dispatch(handleFilterByType(selectedCoverageType, selectedAnalyzeType, 0, searchText));
                    setPage(0);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeStatus]);

    useEffect(() => {
        if (deleteMannetByNetworkStatus !== EMPTY_STRING) {
            handleRequestQuery();
            dispatch(handleResetRemoveStatus());
            if (deleteMannetByNetworkStatus === SUCCESS) {
                dispatch(handleLoading(false));
                generalAlert(
                    ALERT_TITLE_SUCCESS,
                    ALERT_TEXT_REMOVE_SUCCESS,
                    ALERT_ICON_SUCCESS,
                    () => dispatch(handleResetRemoveByNetworkStatus())
                );
            } else if (deleteMannetByNetworkStatus === FAILED) {
                dispatch(handleLoading(false));
                generalAlert(
                    ALERT_TITLE_FAILED,
                    ALERT_TEXT_REMOVE_FAILED,
                    ALERT_ICON_FAILED,
                    () => dispatch(handleResetRemoveByNetworkStatus())
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteMannetByNetworkStatus])

    const handleSelectCoverageType = (e) => {
        const selectedFilter = e.target.value;
        if (selectedFilter === GTA) {
            setSelectedCoverageType(GTA);
            dispatch(handleLoading(true));
            dispatch(handleFilterByType(GTA, AREA, page, searchText));
        } else if (selectedFilter === GTG) {
            setSelectedCoverageType(GTG);
            dispatch(handleLoading(true));
            dispatch(handleFilterByType(GTG, AREA, page, searchText));
        } else {
            setSelectedCoverageType(JAMMER);
            dispatch(handleLoading(true));
            dispatch(handleFilterByType(JAMMER, AREA, page, searchText));
        }
    }

    const handleSelectAnalyzeType = (e) => {
        const selectedFilter = e.target.value;
        setPage(0);
        if (selectedFilter === AREA) {
            setSelectedAnalyzeType(AREA);
            setDisableCoverageType(false);
            dispatch(handleLoading(true));
            dispatch(handleFilterByType(selectedCoverageType, AREA, page, searchText));
        } else if (selectedFilter === PATH) {
            setSelectedAnalyzeType(PATH);
            setDisableCoverageType(true);
            dispatch(handleLoading(true));
            dispatch(handleFilterByType(EMPTY_STRING, PATH, page, searchText));
        } else if (selectedFilter === MESH) {
            setSelectedAnalyzeType(MESH);
            setDisableCoverageType(true);
            dispatch(handleLoading(true));
            dispatch(handleFetchMannetByNetwork(0, 10, searchText));
        }
    }

    const handleRequestQuery = (search = searchText) => {
        if (selectedAnalyzeType === PATH) {
            dispatch(handleFilterByType(EMPTY_STRING, selectedAnalyzeType, page, search));
        } else if (selectedAnalyzeType === MESH) {
            dispatch(handleFetchMannetByNetwork(page, 10, search));
        } else {
            dispatch(handleFilterByType(selectedCoverageType, selectedAnalyzeType, page, search));
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));
    
        setSearchText(searchTextOnChange);
    
        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value.toLowerCase();
    
        handleRequestQuery(searchText);
    
        setPage(0);
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    };

    const handlePageClick = (e) => {
        if (selectedAnalyzeType === MESH) {
            dispatch(handleLoading(true));
            dispatch(handleFetchMannetByNetwork(e.selected, 10, searchText));
        } else if(selectedAnalyzeType === PATH){
            dispatch(handleLoading(true));
            dispatch(handleFilterByType(EMPTY_STRING, selectedAnalyzeType, e.selected, searchText));
        } else {
            dispatch(handleLoading(true));
            dispatch(handleFilterByType(selectedCoverageType, selectedAnalyzeType, e.selected, searchText));
        }
        setPage(e.selected);
    };

    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="Simulation"/>

            <div className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">
                    <div className="flex mt-14 mb-3">
                        <select
                            name="filter"
                            className="select select-bordered w-[200px] rounded-3xl h-[30px] mr-2"
                            onChange={handleSelectAnalyzeType}
                        >
                            <option value={AREA} selected>AREA</option>
                            {/* <option value={PATH}>LINK BUDGET</option> */}
                            <option value={MESH}>MESH SIMULATION</option>
                        </select>
                        <select
                            disabled={disableCoverageType}
                            name="filter"
                            className="select select-bordered w-[200px] rounded-3xl h-[30px]"
                            onChange={handleSelectCoverageType}
                        >
                            <option value={GTA} selected>GTA</option>
                            <option value={GTG}>GTG</option>
                            <option value={JAMMER}>JAMMER</option>
                        </select>
                        <form className="flex justify-start mb-6" onSubmit={handleSearch}>
                            <SearchBox
                                name="searchText"
                                value={searchTextOnChange}
                                placeholder="Search Simulation"
                                className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                                onChangeHandler={onSearchChange}
                            />
                            <span className="flex justify-center items-center text-white text-lg px-1 mr-auto">
                                <button
                                    type="submit"
                                    className="btn btn-primary rounded-full text-white text-xs px-7"
                                >
                                    Search
                                </button>
                            </span>
                        </form> 
                    </div>

                    {responseData && responseData.length > 0 ? (
                        <Table
                            data={responseData}
                            column={selectedAnalyzeType !== MESH ? COLUMN_SIMULATION : COLUMN_SIMULATION_MESH}
                            assetType={ASSET_TYPE.SIMULATION_RESULT}
                            analyzeType={selectedAnalyzeType}
                        />
                    ) : (
                        <p className="flex flex-col text-white text-center pt-10">
                            Data is empty
                        </p>
                    )}

                    <div className="text-white flex flex-col">
                        <div className="flex justify-center  my-6 self-end h-14 items-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={page}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Simulation;
