import {
    UPDATE_CIVIL
} from "../../services/towerCivil";
import {createAction} from "../../utils/reducer/reducerUtil";
import {CREATE_TOWER_SERVER, DELETE_TOWER_SERVER_BY_ID, SEARCH_TOWER_SERVER} from "../../services/coreserverservice";
import {STATUS_TYPE} from "../../utils/constant/constants";
import {handleClearStateGlobal, handleLoading} from "../global/global.action";
import { GLOBAL_ACTION_TYPES } from "../global/global.types";
import { TOWER_SERVER_ACTION_TYPES } from "./towerServer.types";

export const handleClearData = () => (dispatch) => {
    dispatch(createAction(TOWER_SERVER_ACTION_TYPES.CLEAR_DATA))
}

export const handleClearNotif = () => dispatch => {
    dispatch(createAction(TOWER_SERVER_ACTION_TYPES.UPLOAD_CIVIL_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(TOWER_SERVER_ACTION_TYPES.UPDATED, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(TOWER_SERVER_ACTION_TYPES.REMOVED, STATUS_TYPE.NO_STATEMENT));
}

export const handleSaveTowerServer = (data, siteId) => async (dispatch) => {

    const payload = {
        name: data.name,
        lon: data.lon,
        lat: data.lat,
        operator: data.operator,
        type: {id: 5}
    };

    CREATE_TOWER_SERVER(payload).then(() => {
        dispatch(createAction(TOWER_SERVER_ACTION_TYPES.UPLOAD_CIVIL_STATUS, STATUS_TYPE.SUCCESS))
    }).catch(e => {
        dispatch(createAction(TOWER_SERVER_ACTION_TYPES.UPLOAD_CIVIL_STATUS, STATUS_TYPE.FAILED))
    });
    dispatch(handleClearStateGlobal());

}

export const handleRemoveTowerServer = (id, siteId, page) => async (dispatch) => {
    dispatch(createAction(TOWER_SERVER_ACTION_TYPES.IS_LOADING, true));
    DELETE_TOWER_SERVER_BY_ID(id)
        .then(() => {
            dispatch(createAction(TOWER_SERVER_ACTION_TYPES.REMOVED, STATUS_TYPE.SUCCESS))
            dispatch(handleClearStateGlobal());
        })
        .catch(() => {
            dispatch(createAction(TOWER_SERVER_ACTION_TYPES.REMOVED, STATUS_TYPE.FAILED))
        })
    dispatch(createAction(TOWER_SERVER_ACTION_TYPES.IS_LOADING, false));
}
export const handleUpdateTowerServer = (id, data, siteId, page) => async (dispatch) => {

    const payload = {
        name: data.name,
        lon: data.lon,
        lat: data.lat,
        operator: data.operator,
        type: {id: 5}
    };

    UPDATE_CIVIL(id, payload)
        .then(() => {
            dispatch(createAction(TOWER_SERVER_ACTION_TYPES.UPDATED, STATUS_TYPE.SUCCESS));
        })
        .catch(() => {
            dispatch(createAction(TOWER_SERVER_ACTION_TYPES.UPDATED, STATUS_TYPE.FAILED));
        })
    dispatch(handleClearStateGlobal());
    dispatch(createAction(TOWER_SERVER_ACTION_TYPES.IS_LOADING, false));
};

export const handleSearchTowerServer = (q, type, page, size, siteId) => dispatch => {
    SEARCH_TOWER_SERVER(q, type, page, size, siteId)
        .then(res => {
            dispatch(createAction(TOWER_SERVER_ACTION_TYPES.SEARCH, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED));
        })
};
