import React, {useEffect, useState} from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {useSelector} from "react-redux";

import {selectViewer} from "../../../store/dashboard/dashboard.selector";
import {report} from "../../../utils/report/report";
import {takeSnap} from "../../../utils/cesium/cesiumUtils";

import LambangTNI from "../../../assets/png/Lambang_TNI_AU.png";

const ReportFO = ({ data }) => {
    const [profile, setProfile] = useState([]);

    const viewer = useSelector(selectViewer);

    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem("profile"));
        setProfile(userProfile);
    }, []);

    const handlePdfGenerate = () => {
        // Format
        const doc = new jsPDF("potrait", "px", "a4", "false");
        
        // Template
        report.templateAsset(doc, 1, profile, LambangTNI, "FO Distribution");

        let pageNumber = 0;
        let itemNumber = 0;
        let totalPageCount = 0; //eslint-disable-line

        autoTable(doc, {
            theme: "grid",
            styles: {
                textColor: [0, 0, 0],
                fontSize: 7,
                overflow: "linebreak",
                cellWidth: 74,
            },
            margin: { left: 30, bottom: 60 },
            startY: 150,
            didParseCell(data) {
                if (data.row.index % 2 !== 0) {
                    data.cell.styles.textColor = [0, 0, 0];
                    data.cell.styles.fillColor = "#D3D3D3";
                }
                if (data.column.index === 0) {
                    data.cell.styles.cellWidth = 15;
                }
                if (data.column.index === 1) {
                    data.cell.styles.cellWidth = 95;
                    data.cell.styles.cellPadding = {top: 3, right: 13, bottom: 3, left: 3};
                }
                if (data.column.index === 2) {
                    data.cell.styles.cellWidth = 90;
                }
                if (data.column.index === 3) {
                    data.cell.styles.cellWidth = 40;
                }
                data.settings.margin.top = 150;
            },
            didDrawPage: (data) => {
                if(data.pageCount >= 2) {
                    totalPageCount = data.pageCount
                    report.templateAsset(doc, data.pageCount, profile, LambangTNI, "FO Distribution");
                }
            },
            tableWidth: 385,
            head: [
                ["No", "Name", "Total Cable Length (m)", "Core", "Number of Points", "Owner"],
            ],
            body: data.map((asset) => {
                itemNumber += 1;
                return [
                    itemNumber,
                    asset?.data.name,
                    asset?.data.totalLength,
                    asset?.data.core,
                    asset?.data.points,
                    asset?.data?.siteName,
                ];
            }),
        });

        // Add new page
        report.addPage(doc)

        // Template appendix
        report.templateAppendix(
            doc,
            pageNumber + 2,
            profile,
            LambangTNI,
        )

        // Image Coverage
        report.coverageImgFO(doc, takeSnap(viewer))

        if (data.length > 0) {
            report.savePdf(doc, "FO Distribution");
        }
    };

    return (
        <button
            type="button"
            className="text-white text-lg absolute right-24"
            onClick={handlePdfGenerate}
        >
            Create Report
        </button>
    );
};

export default ReportFO;