export const FO_ACTION_TYPES = {
    ADD_POINT : `fo/ADD_POINT`,
    REMOVED : `fo/REMOVED`,
    UPDATED : `fo/UPDATED`,
    UPLOAD_FO_STATUS : `fo/UPLOAD_FO_STATUS`,
    SUCCESS_TO_REMOVE_ASSET : `fo/SUCCESS_TO_REMOVE_ASSET`,
    GO_TO_EDIT_ASSET : `fo/GO_TO_EDIT_ASSET`,
    EDIT_ASSET : `fo/EDIT_ASSET`,
    DETAIL_MULTI : `fo/DETAIL_MULTI`,
    SEARCH_RESPONSE : `fo/SEARCH_RESPONSE`,
    DELETE_ASSET : `fo/DELETE_ASSET`,
    ADD_ENTITY_POINT : `fo/ADD_ENTITY_POINT`,
    CLEAR_STORAGE : `fo/CLEAR_STORAGE`,
    CLEAR_STORAGE_EXCEPT_UPDATE : `fo/CLEAR_STORAGE_EXCEPT_UPDATE`,
    REMOVE_A_POINT : `fo/REMOVE_A_POINT`,
    REMOVE_A_POLYLINE_AND_A_MID_LABEL : `fo/REMOVE_A_POLYLINE_AND_A_MID_LABEL`,
    ADD_POLYLINE : `fo/ADD_POLYLINE`,
    ADD_DISTANCE_LABEL : `fo/ADD_DISTANCE_LABEL`,
    ADD_DISTANCE_NUMBER : `fo/ADD_DISTANCE_NUMBER`,
    RESPONSE_POST_FO : `fo/RESPONSE_POST_FO`,
    UPDATE_DATA : `fo/UPDATE_DATA`,
    DELETE_KINETIC_POINT : `fo/DELETE_POINT`,
    ADD_KINETIC_POINT : `fo/ADD_KINETIC_POINT`,
}