import {kmToNauticalMiles} from "../../utils/calculate/convert";

export function getHighLabel(anOpData) {
    const arrTemp = [];
    if (anOpData.simulationDtos.length > 0) {
        arrTemp.push(0);
        anOpData.simulationDtos.forEach(obj => arrTemp.push(obj.high));
        return arrTemp.sort((a, b) => a - b).reverse();
    } else if (anOpData.actualDtos.length > 0) {
        arrTemp.push(0);
        anOpData.actualDtos.forEach(obj => arrTemp.push(obj.high));
        return arrTemp.sort((a, b) => a - b).reverse();
    } else {
        return null;
    }
}

export function getDistanceSimulationLabel(anOpData) {
    let objData = {}
    objData.label = "Simulation"
    objData.data = []
    objData.data.push(0);
    anOpData.simulationDtos.forEach(data => objData.data.push(kmToNauticalMiles(data.distance / 2)));
    objData.data.sort((a, b) => b - a);
    return objData;
}

export function getDistanceActualLabel(anOpData) {
    let objData = {}
    objData.label = "Actual Mission"
    objData.data = []
    objData.data.push(0);
    anOpData.actualDtos.map(data => objData.data.push(data.distance));
    objData.data.sort((a, b) => b - a);
    return objData;
}

