import React, {useEffect, useRef, useState} from "react";
import MouseTooltip from "react-sticky-mouse-tooltip";
import CardAssets from "../../components/card/CardAssets";
import Chart from "../../components/chart/Chart";
import HeaderPage from "../../components/header/HeaderPage";
import {MapViewer} from "../../components/map/MapViewer";
import LabelValue from "../../components/label/LabelValue";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCardData,
    selectChartData,
    selectEventListener,
    selectIsToolTipsOpen,
    selectSelectedSite,
    selectTotalAsset
} from "../../store/asset/asset.selector";
import {fetchDataTotal, handleFetchData, handleFetchDataSite} from "../../store/asset/asset.action";
import {flyToIndnesia, removeImageryProviderForTerrain} from "../../utils/cesium/cesiumUtils";
import {SceneMode, ScreenSpaceEventType} from "cesium";
import {registerViewer} from "../../store/dashboard/dashboard.action";
import CardAssetTotal from "../../components/card/CardAssetTotal";

const AssetData = () => {
    const ref = useRef(null); // viewer
    const [site, setSite] = useState({id: -1});

    const dispatch = useDispatch();
    const cardData = useSelector(selectCardData);
    const chartData = useSelector(selectChartData);
    const eventListener = useSelector(selectEventListener);
    const isToolTipsOpen = useSelector(selectIsToolTipsOpen);
    const totalAsset = useSelector(selectTotalAsset);
    const selectedSite = useSelector(selectSelectedSite);

    useEffect(() => {
        const ces = ref.current.cesiumElement;
        dispatch(registerViewer(ces));
        removeImageryProviderForTerrain(ces);
        ces.scene.mode = SceneMode.COLUMBUS_VIEW;
        flyToIndnesia(ces, true);

        dispatch(handleFetchData());
        dispatch(handleFetchDataSite(ces));


        return () => {
            if (ces) {
                ces.entities.removeAll();
            }
            if (eventListener) {
                eventListener.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedSite) {
            if (selectedSite.id !== site.id) {
                dispatch(fetchDataTotal(selectedSite));
                setSite(selectedSite);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSite]);

    // Filter data only Total Asset
    const filteredCardData = cardData.filter((item) => item.name === "Total Asset");

    // Filter data without Total Asset
    const cardAssetTotal = cardData.filter((item) => item.name !== "Total Asset");

    // Sorting Card Asset Total => Radio, Antenna, Tower, PABX, and Supporting
    const sortedCardAssetTotal = cardAssetTotal.sort((a, b) => {
        if (a.name === "Radio") return -1;
        if (b.name === "Radio") return 1;
        if (a.name === "Antenna") return -1;
        if (b.name === "Antenna") return 1;
        if (a.name === "Tower") return -1;
        if (b.name === "Tower") return 1;
        if (a.name === "PABX") return -1;
        if (b.name === "PABX") return 1;
        return 0;
    });

    return (
        <div className="bg-tertiary h-full px-20 py-5">
            <HeaderPage title="Asset Data"/>

            <div className="flex gap-x-10 mt-5">
                <div className="w-2/3 flex flex-col gap-y-8 ">
                    <div>
                        <h3 className="font-semibold text-white text-lg border-white border-b-2 tracking-wider">OVERALL</h3>

                        <div className="flex gap-x-20">
                            {filteredCardData && (
                                <section className="mt-6">
                                    {filteredCardData.map((asset) => (
                                        <CardAssets
                                            key={asset.id}
                                            asset={asset.name}
                                            item={asset.item ? asset.item : 0}
                                            icon={asset.icon}
                                        />
                                    ))}
                                </section>
                            )}

                            {sortedCardAssetTotal && (
                                <section className="flex w-[600px] mt-6">
                                    {sortedCardAssetTotal.map((asset) => (
                                        <CardAssetTotal
                                            key={asset.id}
                                            asset={asset.name}
                                            item={asset.item ? asset.item : 0}
                                            icon={asset.icon}
                                        />
                                    ))}
                                </section>
                            )}
                        </div>
                    </div>

                    <div className="">
                        <h3 className="font-semibold text-white text-lg border-white border-b-2 tracking-wider">LOCATION</h3>

                        <div className="mt-5 border-solid border-8 border-white rounded-lg">
                            <MapViewer isScheneModePickerActive={false} ref={ref}/>
                        </div>

                        <MouseTooltip visible={isToolTipsOpen} offsetX={15} offsetY={-300}>
                            <div className="bg-[rgba(0,0,0,0.8)] rounded-xl px-4 py-6 z-40">
                                {totalAsset && (
                                    <>
                                        <p className="text-white mb-3 px-4 font-semibold">{totalAsset.siteName}</p>
                                        <LabelValue
                                            data={[
                                                {
                                                    title: "Radio",
                                                    value: totalAsset.radioDetail.total,
                                                },
                                                {
                                                    title: "Antenna",
                                                    value: totalAsset.antennaDetail.total,
                                                },
                                                {
                                                    title: "Tower",
                                                    value: totalAsset.towerDetail.total,
                                                },
                                                {
                                                    title: "PABX",
                                                    value: totalAsset.pabxDetail.total,
                                                },
                                                {
                                                    title: "Supporting",
                                                    value: totalAsset.dssDetail.total,
                                                },
                                            ]}
                                        />
                                    </>
                                )}

                            </div>
                        </MouseTooltip>
                    </div>
                </div>

                <div className="w-1/3">
                    <h3 className="font-semibold text-white text-lg border-white border-b-2 tracking-wider">ASSETS
                        STATUS</h3>

                    <div className="h-[78.5vh] overflow-y-scroll scrollbar-hide mt-5">
                        <Chart datas={chartData} customStyle="wide"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssetData;
