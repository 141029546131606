import {createSelector} from "reselect";

const selectDashboardReducer = (state) => state.dashboardReducer
const selectCoverageRulerReducer = (state) => state.coverageRulerReducer

export const selectAvailableNetwork = createSelector(
    [selectDashboardReducer],
    (state) => state.networkList.map(el => {
        return Object.assign(
            {},
            {
                label: el,
                value: el,
            }
        );
    })
);

export const selectPrimitiveTile = createSelector(
    [selectDashboardReducer],
    (state) => state.primitive
);
export const selectMouseMoveListener = createSelector(
    [selectDashboardReducer],
    (state) => state.mouseMoveListener
);
export const selectResultList = createSelector(
    [selectDashboardReducer],
    (state) => state.resultList
);
export const selectCoverageRuler = createSelector(
    [selectCoverageRulerReducer],
    (state) => state.entityIdArray
);


export const showAllCoverageThicked = createSelector(
    [selectDashboardReducer],
    (state) => state.showAllCoverage
)
export const showAllLabelThicked = createSelector(
    [selectDashboardReducer],
    (state) => state.showAllLabel
)

export const selectViewer = createSelector(
    [selectDashboardReducer],
    (state) => state.viewer
)

export const selectStatusToggleFilterButton = createSelector(
    [selectDashboardReducer],
    (state) => state.toggleFilterBtn
)
export const selectAllSite = createSelector(
    [selectDashboardReducer],
    (state) => {
        if (state.allSite && state.allSite.content && state.allSite.content.length) {
            let result = []
            state.allSite.content.forEach(data => {
                result.push({value: data.id, label: data.siteName})
            })
            return result;
        } else {
            return null;
        }
    }
)