import {createAction} from "../../utils/reducer/reducerUtil";
import {AREA_ACTION_TYPES, NS, SHOW} from "./area.types";
import {
    createBillboardEntity,
    createCzmlDataSource,
    getBearing,
    getDistanceString, getTerrainHeightByDegree,
    handleShowOrHideCoverage,
    labelPosition,
    loadKmlDataSource,
    radsToDegs,
    removeEntityAndDataSource,
} from "../../utils/cesium/cesiumUtils";
import {CREATE_PATH, CREATE_SIMULATE, GET_ALL_USAGE_PM, POST_OP, POST_SITE} from "../../services/analyze";
import {GET_ALL_ANTENA} from "../../services/antena";
import {Cartesian3, Cartographic, defined, ScreenSpaceEventHandler, ScreenSpaceEventType} from "cesium";
import {showDetailEntity} from "../dashboard/dashboard.action";
import {PM} from "../../utils/constant/constants";
import {anyObstacle} from "./area.util";
import {handleLoading} from "../global/global.action";
import {handleStoreBearing, handleStoreDistance} from "../coverageRuler/coverageRuler.action";
import {feetToMeter} from "../../utils/calculate/convert";

export const handleSetTerrainHeight = (terrainHeigh) => dispatch => {
    dispatch(createAction(AREA_ACTION_TYPES.TERRAIN_HEIGHT, terrainHeigh));
}
export const handleTerrainAction = (isTerrainActive) => dispatch => {
    dispatch(createAction(AREA_ACTION_TYPES.IS_TERRAIN_ACTIVE, isTerrainActive));
    if (!isTerrainActive) {
        dispatch(createAction(AREA_ACTION_TYPES.TERRAIN_HEIGHT, 0));
    }
}
export const handleTransmitterData = (latitude, longitude, billboardEntity) => dispatch => {
    dispatch(createAction(AREA_ACTION_TYPES.TRANSMITTER_DATA, {latitude, longitude, billboardEntity}));
}

export const setOnClickPosition = (viewer, latitude, longitude, alt = 0) => (dispatch) => {
    if (typeof latitude === "number" && typeof longitude === "number" && typeof alt === "number" &&
        !isNaN(latitude) && !isNaN(longitude) && !isNaN(alt)) {
        const cartesian = Cartesian3.fromDegrees(longitude, latitude, alt);
        const billboardEntity = createBillboardEntity(viewer, cartesian, "bil");
        dispatch(handleTransmitterData(latitude, longitude, billboardEntity));
    }
}

export const handleGetPmList = () => dispatch => {
    GET_ALL_USAGE_PM()
        .then(res => {
            dispatch(createAction(AREA_ACTION_TYPES.GET_PM_LIST, res));
        })
        .catch(() => {
        })
}
export const fetchData = () => async (dispatch) => {

    const pmList = await GET_ALL_USAGE_PM();
    const allAntenna = await GET_ALL_ANTENA(0, 200, "");

    dispatch(createAction(AREA_ACTION_TYPES.FETCH_DATA, {pmList, allAntenna}))
}

export const clearTransmitterData = () => (dispatch) => {
    dispatch(createAction(AREA_ACTION_TYPES.CLEAR_TRANSMITTER_DATA));
}

export const handlePostDataArea = (point, navigate) => (dispatch) => {
    point.model.rel = Math.round(point.model.rel);
    if (point.output.rad > 370) {
        point.output.rad = 370;
    }

    dispatch(handleLoading(true));
    POST_SITE(point)
        .then(res => {
            dispatch(handleLoading(false))
            dispatch(createAction(AREA_ACTION_TYPES.POST_SUCCESS, res))
            dispatch(createAction(AREA_ACTION_TYPES.SHOW_COVERAGE_NOTIFICATION, SHOW));
            dispatch(handleCopyData(res)); // copy data for other analyze
            dispatch(showDetailEntity(res))
            navigate('/');
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(AREA_ACTION_TYPES.POST_FAILED, e.message))
        })
}

export const handleResetShowCoverageNotification = () => dispatch => {
    dispatch(createAction(AREA_ACTION_TYPES.SHOW_COVERAGE_NOTIFICATION, NS));
}

export const handleClearErrormessage = () => dispatch => {
    dispatch(createAction(AREA_ACTION_TYPES.CLEAR_ERROR_MESSAGE));
}

export const handleCopyData = (data) => (dispatch) => {
    dispatch(createAction(AREA_ACTION_TYPES.COPY_ENTITY_DATA, data))
}

export const handleClearCopyData = () => (dispatch) => {
    dispatch(createAction(AREA_ACTION_TYPES.REMOVE_COPY_ENTITY_DATA))
}

export const handleMultiplyAmount = (amount) => (dispatch) => {
    dispatch(createAction(AREA_ACTION_TYPES.HANDLE_MULTIPLY_AMOUNT, amount))
}


export const handleCreateOP = (body) => async (dispatch) => {
    POST_OP(body)
        .then((res) => {
            dispatch(createAction(AREA_ACTION_TYPES.CREATE_OP, res))
        })
        .catch(() => {
        })
}

export const handleStoreRequestBody = (point) => (dispatch) => {
    dispatch(createAction(AREA_ACTION_TYPES.STORE_REQ_BODY, point));
}

export const handleSetCountOPRequest = (count) => (dispatch) => {
    dispatch(createAction(AREA_ACTION_TYPES.SET_COUNT_OP_REQ, count));
}
export const handleStoreAltitudeToSum = (alt) => (dispatch) => {
    dispatch(createAction(AREA_ACTION_TYPES.ALTITUDE_TO_SUM, alt));
}

export const handleCreateSimulate = (body, navigate, opResponse, altToSum, countReq, multiplyAmount) => (dispatch) => {
    dispatch(createAction(AREA_ACTION_TYPES.START_POST_DATA_AREA));
    dispatch(handleLoading(true));
    let isComplated = Number(countReq) === Number(multiplyAmount);

    if (!isComplated) {

        let payloadToModify = {...body}
        if (Number(countReq) !== 0) {
            // add altitude value
            const {alt, oriAlt} = altToSum;
            payloadToModify.receiver.alt = Number(payloadToModify.receiver.alt) + Number(alt);
            payloadToModify.additionalDTO.oriAlt = Number(payloadToModify.additionalDTO.oriAlt) + Number(oriAlt);
        }

        if (typeof payloadToModify.model.pm !== 'number') {
            payloadToModify.model.pm = PM.find(d => d.label === payloadToModify.model.pm).value;
        }

        let payload = {
            id: opResponse.id,
            areaReqDto: payloadToModify,
        }

        CREATE_SIMULATE(payload)
            .then(res => {
                dispatch(handleSetCountOPRequest(countReq + 1));
            })
            .catch(e => {
                dispatch(handleSetCountOPRequest(0));
                dispatch(handleLoading(false));
                dispatch(createAction(AREA_ACTION_TYPES.POST_FAILED))
                dispatch(handleLoading(false));
                dispatch(handleLoading(false))
                dispatch(createAction(AREA_ACTION_TYPES.CREATE_OP, null))
            })

    } else {
        dispatch(handleSetCountOPRequest(0));
        dispatch(handleLoading(false));
        dispatch(createAction(AREA_ACTION_TYPES.CREATE_OP, null))
        navigate('/operationdata');
    }
}

export const handleRegisterMidLabel = (label) => dispatch => {
    dispatch(createAction(AREA_ACTION_TYPES.REGISTER_MID_LABEL, label));
}

export const handleLinkBudgetAction = (viewer, dataEntity, handlerClick, txAlt) => dispatch => {
    handlerClick.setInputAction((click) => {
        const ray = viewer.camera.getPickRay(click.position);
        const mousePosition = viewer.scene.globe.pick(ray, viewer.scene);
        if (defined(mousePosition)) {
            const cartographic = Cartographic.fromCartesian(mousePosition);
            const longitude = parseFloat(radsToDegs(cartographic.longitude).toFixed(8));
            const latitude = parseFloat(radsToDegs(cartographic.latitude).toFixed(8));
            dispatch(createAction(AREA_ACTION_TYPES.CHANGE_KINETIC_RX, {longitude, latitude}))
        }
    }, ScreenSpaceEventType.LEFT_CLICK);
};

export const handleShowHideChartLinkBudget = (showChart) => dispatch => {
    dispatch(createAction(AREA_ACTION_TYPES.SHOW_HIDE_CHART_LINK_BUDGET, showChart))
}

export const handleAnyDataChartLinkBudget = (payload) => dispatch => {
    dispatch(createAction(AREA_ACTION_TYPES.ANY_DATA_CHART_LINK_BUDGET, payload))
}

export const showCoverageLinkBudget = (dataEntity, latitude, longitude, viewer, txAlt, rxAlt = null) => (dispatch) => {
    const body = JSON.parse(JSON.stringify(dataEntity));
    delete body.transmitter.originalAlt;
    body.receiver.lat = latitude;
    body.receiver.lon = longitude;
    if (rxAlt) {
        body.receiver.alt = feetToMeter(rxAlt);
    }
    dispatch(handleLoading(true));
    if (dataEntity.transmitter.originalAlt) {
        body.transmitter.alt = dataEntity.transmitter.originalAlt;
    }

    if (typeof body.model.pm !== 'number') {
        body.model.pm = PM.find(d => d.label === body.model.pm).value;
    }

    const isTerrainActive = viewer.baseLayerPicker.viewModel.selectedTerrain.name === `Cesium World Terrain`

    body.additionalPathDTO = {
        oriTxAlt : body.transmitter.alt,
        terrainActive : isTerrainActive
    }

    CREATE_PATH(body)
        .then(res => {

            const respRx = res.resultPath.receiver[0];
            const respTx = res.resultPath.transmitters[0];


            getTerrainHeightByDegree(respRx.latitude, respRx.longitude).then((h) => {
                const cartesianRx = Cartesian3.fromDegrees(respRx.longitude, respRx.latitude,
                    isTerrainActive ? respRx.antennaHeightM + h : respRx.antennaHeightM);
                // draw RX
                dispatch(handleDrawRxLinkBudget(viewer, cartesianRx));
                // draw line
                let obstacle = anyObstacle(res).anyObstacle;

                const rx = {
                    rxLat: respRx.latitude,
                    rxLon: respRx.longitude,
                    rxAlt: isTerrainActive ? respRx.antennaHeightM + h: respRx.antennaHeightM
                }
                const tx = {
                    txLat: respTx.latitude,
                    txLon: respTx.longitude,
                    txAlt: txAlt
                }

                const source = createCzmlDataSource(
                    viewer,
                    rx,
                    tx,
                    obstacle, "redLine");
                // register polyline
                dispatch(createAction(AREA_ACTION_TYPES.RX_POSITION, {
                    rx,
                    tx,
                    terrainHeight: isTerrainActive ? respRx.antennaHeightM + h : 0
                }));
                dispatch(createAction(AREA_ACTION_TYPES.REGISTER_POLYLINE, source));
                dispatch(createAction(AREA_ACTION_TYPES.CREATE_PATH, res));
            })
        })
        .catch(e => {
            dispatch(handleLoading(false));
        })
}

export const handleShowCoverageLinkBudget = (sourceId, event) => dispatch => {
    handleShowOrHideCoverage(sourceId, event.target.checked)
    dispatch(createAction(AREA_ACTION_TYPES.SHOW_COVERAGE_TX_LINK_BUDGET, event.target.checked));
}
export const handleRegisterKmz = (viewer, kmz) => dispatch => {
    const kmzSource = loadKmlDataSource(viewer, kmz, false);
    dispatch(createAction(AREA_ACTION_TYPES.STORE_KML_DATASOURCE, kmzSource));
}

export const handleDrawTx = (lon, lat, alt, viewer) => dispatch => {
    const cartesianTx = Cartesian3.fromDegrees(lon, lat, alt);
    let billboardEntityTx = createBillboardEntity(viewer, cartesianTx, "tx", true);
    dispatch(createAction(AREA_ACTION_TYPES.STORE_BILLBOARD_TX, billboardEntityTx));
};

export const handleDrawTxLinkBudget = (data, viewer) => (dispatch) => {
    const {lat, lon, alt} = data.transmitter
    dispatch(handleDrawTx(lon, lat, alt, viewer));
}

export const handleDrawRxLinkBudget = (viewer, cartesian, id = "rx") => (dispatch) => {
    let billboardEntityRx = createBillboardEntity(viewer, cartesian, id);
    dispatch(createAction(AREA_ACTION_TYPES.STORE_BOLLBOARD_RX, billboardEntityRx));
}

export const handleClearLinkBudget = () => dispatch => {
    dispatch(createAction(AREA_ACTION_TYPES.CREATE_PATH, null));
    dispatch(createAction(AREA_ACTION_TYPES.CHANGE_KINETIC_RX, {}))
    dispatch(createAction(AREA_ACTION_TYPES.RX_POSITION, null));
    dispatch(createAction(AREA_ACTION_TYPES.KINETIC, null));
}

export const handleClearData = (viewer) => (dispatch) => {
    removeEntityAndDataSource(viewer);
    dispatch(createAction(AREA_ACTION_TYPES.CLEAR_BILLBOARD));
    dispatch(createAction(AREA_ACTION_TYPES.CLEAR_KML_SOURCE));
}

export const clearAllAnalyze = () => (dispatch) => {
    dispatch(createAction(AREA_ACTION_TYPES.CLEAR_ALL));
}

export const handleSetKineticFromRightPanel = (kinetic) => dispatch => {
    dispatch(createAction(AREA_ACTION_TYPES.KINETIC, kinetic));
}


export const handleMouseMoveEventToGetPositionAndBearing = (viewer, objLat, objLon, showLabel = true) => dispatch => {
    viewer.entities.removeById("ms");
    const entityLabel = labelPosition(viewer);

    const handler = new ScreenSpaceEventHandler(viewer.scene.canvas);

    const listener = (movement) => {
        const ray = viewer.camera.getPickRay(movement.endPosition);
        const mousePosition = viewer.scene.globe.pick(ray, viewer.scene);
        if (defined(mousePosition) && showLabel) {
            const cartographic = Cartographic.fromCartesian(mousePosition);
            const latitude = parseFloat((cartographic.latitude * 180 / Math.PI).toFixed(6));
            const longitude = parseFloat((cartographic.longitude * 180 / Math.PI).toFixed(6));
            const bearing = getBearing(objLat, objLon, latitude, longitude);
            const distance = getDistanceString({latitude: objLat, longitude: objLon}, {
                latitude: latitude,
                longitude: longitude
            });
            entityLabel.position = Cartesian3.fromDegrees(longitude, latitude);
            entityLabel.label.show = true;
            entityLabel.label.text = `lat : ${latitude}\nlon : ${longitude}\nbearing : ${bearing}\ndistance : ${distance}`;

            dispatch(createAction(AREA_ACTION_TYPES.RULER_INFORMATION, {latitude, longitude, bearing}));
            dispatch(handleStoreBearing(bearing));
            dispatch(handleStoreDistance(distance));
        } else {
            entityLabel.label.show = false;
        }
    }
    handler.setInputAction(listener, ScreenSpaceEventType.MOUSE_MOVE);

    dispatch(createAction(AREA_ACTION_TYPES.REGISTER_HANDLER_LISTENER_MOUSE_MOVE, handler));
}
