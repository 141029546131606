import * as React from "react"
const LogActivityIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 500 500" {...props}>
    <g transform="matrix(17.7904 0 0 18.08035 -4253.99 -3446.05)">
      <path
        fillRule="evenodd"
        d="M247.934 195.682a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-12a3 3 0 0 0-3-3h-10Zm11 3a1 1 0 0 0-1-1h-10a1 1 0 0 0-1 1v8h2.937c.513 0 1.01.177 1.407.492.964.765 2.341.771 3.291.017.416-.33.93-.509 1.46-.509h2.905v-8Zm-12 10v2a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-2h-2.905a.346.346 0 0 0-.216.075c-1.693 1.344-4.1 1.316-5.779-.017a.269.269 0 0 0-.163-.058h-2.937Zm2-5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Zm1-4a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2h-6Z"
        clipRule="evenodd"
      />
      <rect
        width={25.235}
        height={25.235}
        x={240.197}
        y={192.292}
        rx={5}
        ry={5}
        style={{
          strokeWidth: 1.5,
          fill: "transparent",
        }}
      />
    </g>
  </svg>
)
export default LogActivityIcon
