import { ACTION_POST, ACTION_GET, ACTION_POST_FILE, BASE_URL, ACTION_DELETE } from "./core";

export const CREATE_ASSETS = (param) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/assets`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_ALL_ASSET = () => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/assets/dashboard`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_TOTAL_ASSET = (id) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/assets/dashboard/${id}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_ASSET_RADIO = () => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/assets/radio`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_ASSET_CIVIL = () => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/distributionassets/search/5`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_ASSET_ELECTRONIC = () => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/distributionassets/search/7`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_ASSET_ANTENA = () => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/assets/antenna`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const GET_ASSET_TOWER = () => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/assets/tower`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_ASSET_PABX = () => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/assets/pabx`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_ASSET_DSS = () => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/assets/dss`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const GET_ASSET_DISTRIBUTION = (siteId) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/distributionassets/dashboard?siteId=${siteId}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_COAXIAL_TYPE = () => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/refcoaxialtype`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_CATEGORY_BY_USAGE = (usage) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/refassetcategory/usage/${usage}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_TYPE_BY_USAGE = (usage) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/refassettype/usage/${usage}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_BRAND_BY_USAGE = (usage) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/refBrand/usage/${usage}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_IMAGE = (imageId, opt) => {
  const url = `${BASE_URL}/image/${imageId}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url, opt)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_FILE = (fileId) => {
  const url = `${BASE_URL}/file/${fileId}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPLOAD_IMAGE = (param) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/image`;
  return new Promise((resolve, reject) => {
    ACTION_POST_FILE(url, param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPLOAD_FILE = (param) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/file`;
  return new Promise((resolve, reject) => {
    ACTION_POST_FILE(url, param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DELETE_FILE = (fileName) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/file/${fileName}`;
  return new Promise((resolve, reject) => {
    ACTION_DELETE(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DELETE_IMAGE = (fileName) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/image/${fileName}`;
  return new Promise((resolve, reject) => {
    ACTION_DELETE(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};