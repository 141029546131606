import React, {useEffect, useRef, useState} from "react";
import {MapViewer} from "../../components/map/MapViewer";
import RedTower from "../../components/toolbars/RedTower";
import WhiteTower from "../../components/toolbars/WhiteTower";
import Recycle from "../../components/toolbars/Recycle";
import Modals from "../../components/modal/Modals";
import {ANALYZE_TYPE,} from "../../utils/constant/constants";
import {useDispatch, useSelector} from "react-redux";
import {flyToIndonesia, registerViewer} from "../../store/dashboard/dashboard.action";
import {
    fetchData,
    handleClearErrormessage,
    handleCreateSimulate,
    handleMouseMoveEventToGetPositionAndBearing,
    handleSetTerrainHeight,
    handleTerrainAction,
    setOnClickPosition
} from "../../store/area/area.action";
import {
    selectAltitudeToSum,
    selectCountOPReq,
    selectDataEntityFromOtherPage,
    selectErrorMessage,
    selectKinetic,
    selectListenerMouseMove,
    selectMultiplyAmount,
    selectOPResponse,
    selectReqBody,
} from "../../store/area/area.selector";
import {Cartographic, defined, ScreenSpaceEventHandler, ScreenSpaceEventType} from "cesium";
import RightPannel from "../../components/drawer/RightPannel";
import {useNavigate} from "react-router-dom";
import {selectAllRadio, selectDetailRadio} from "../../store/radio/radio.selector";
import {handleGetRadioById, handleSearchRadio} from "../../store/radio/radio.action";
import {selectCurrentUser} from "../../store/user/user.selector";
import {handleHeaderModalRadioOrAntenna, handleModalLoadRadioOrAntenna} from "../../store/area/area.util";
import {handleGetAntennaById, handleSearchAntenna} from "../../store/antenna/antenna.action";
import {selectAllAntenna, selectDetailAntenna} from "../../store/antenna/antenna.selector";
import ShowPannel from "../../components/drawer/ShowPannel";
import {generalAlert} from "../../utils/notification/notification";
import {handleRedTowerToggle, handleWhiteTowerToggle, removeAllEntites} from "../../store/tower/tower.action";
import {handleLoading} from "../../store/global/global.action";

const OperationPlanning = () => {
    const ref = useRef(null); // viewer
    const navigate = useNavigate();
    const [showRadioAntena, setShowRadioAntena] = useState(false);
    const [loadMode, setLoadMode] = useState('');
    const [searchText, setSearchText] = useState("");
    const [idRadio, setIdRadio] = useState("");
    const [idAntenna, setIdAntenna] = useState("");
    const [isOpen, setIsOpen] = useState(true);
    const [terrainHeight, setTerrainHeight] = useState(0)
    const [notify, setNotify] = useState(null)
    const [height, setHeight] = useState(0)

    const dispatch = useDispatch();
    const reqBody = useSelector(selectReqBody);
    const countOPReq = useSelector(selectCountOPReq);
    const multiplyAmount = useSelector(selectMultiplyAmount);
    const altToSum = useSelector(selectAltitudeToSum);
    const createOPResponse = useSelector(selectOPResponse);
    const radioData = useSelector(selectAllRadio);
    const currentUser = useSelector(selectCurrentUser);
    const errorMessage = useSelector(selectErrorMessage);
    const detailRadio = useSelector(selectDetailRadio);
    const antennaData = useSelector(selectAllAntenna);
    const detailAntenna = useSelector(selectDetailAntenna);
    const dataEntityFromDashboard = useSelector(selectDataEntityFromOtherPage);
    const kinetickTx = useSelector(selectKinetic);
    const listenerMouseMove = useSelector(selectListenerMouseMove);

    useEffect(() => {
        const viewer = ref.current.cesiumElement;
        dispatch(registerViewer(viewer))
        const handlerClick = new ScreenSpaceEventHandler(viewer.scene.canvas);
        const eventListener = (event) => {
            const ray = viewer.camera.getPickRay(event.position);
            const mousePosition = viewer.scene.globe.pick(ray, viewer.scene);
            if (defined(mousePosition)) {
                const cartographic = Cartographic.fromCartesian(mousePosition);
                const latitude = parseFloat((cartographic.latitude * 180 / Math.PI).toFixed(6));
                const longitude = parseFloat((cartographic.longitude * 180 / Math.PI).toFixed(6));
                let alt = cartographic.height;
                if (viewer.baseLayerPicker.viewModel.selectedTerrain.name !== `Cesium World Terrain`) {
                    dispatch(handleTerrainAction(false));
                    alt = 0;
                } else {
                    dispatch(handleTerrainAction(true));
                }
                dispatch(handleSetTerrainHeight(alt));
                setTerrainHeight(alt);
                setNotify({latitude, longitude});
                // const towerHeight = dataEntityFromDashboard ?
                //     dataEntityFromDashboard.data.transmitter.alt : Number(txData.transmitter.alt);
                // dispatch(setOnClickPosition(viewer, latitude, longitude, alt + towerHeight));
            }
        }
        handlerClick.setInputAction(eventListener, ScreenSpaceEventType.LEFT_CLICK);

        flyToIndonesia(viewer);

        dispatch(fetchData());
        if (currentUser) {
            dispatch(handleSearchRadio("", "name", 0, 10, currentUser.siteId));
            dispatch(handleSearchAntenna("", "name", 0, 10, currentUser.siteId));
        }

        return () => {
            dispatch(removeAllEntites(viewer));

            if (handlerClick) {
                handlerClick.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
            }

            if (listenerMouseMove) {
                listenerMouseMove.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
            }
            // dispatch(createAction(AREA_ACTION_TYPES.FINISH_OP_SIM))
            dispatch(handleLoading(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataEntityFromDashboard) {
            const viewer = ref.current.cesiumElement;
            if (listenerMouseMove) {
                listenerMouseMove.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
                viewer.entities.removeById("ms");

            }

            dispatch(setOnClickPosition(viewer,
                dataEntityFromDashboard.data.transmitter.lat,
                dataEntityFromDashboard.data.transmitter.lon,
                terrainHeight + dataEntityFromDashboard.data.transmitter.alt));

            setHeight(dataEntityFromDashboard.data.transmitter.alt);

            dispatch(handleMouseMoveEventToGetPositionAndBearing(viewer,
                dataEntityFromDashboard.data.transmitter.lat,
                dataEntityFromDashboard.data.transmitter.lon));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataEntityFromDashboard])

    useEffect(() => {
        if (notify) {
            const viewer = ref.current.cesiumElement;
            if (kinetickTx && kinetickTx.for && kinetickTx.for === "tx") {
                const alt = kinetickTx && kinetickTx.alt !== null ? kinetickTx.alt :
                    dataEntityFromDashboard && dataEntityFromDashboard.data &&
                    dataEntityFromDashboard.data.transmitter &&
                    dataEntityFromDashboard.data.transmitter.alt ?
                        dataEntityFromDashboard.data.transmitter.alt : 0;
                setHeight(alt);
                dispatch(setOnClickPosition(viewer, notify.latitude, notify.longitude, terrainHeight + alt));
            } else {
                dispatch(setOnClickPosition(viewer, notify.latitude, notify.longitude, terrainHeight + height));
            }

            if (listenerMouseMove) {
                listenerMouseMove.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
                viewer.entities.removeById("ms");
            }

            dispatch(handleMouseMoveEventToGetPositionAndBearing(viewer, notify.latitude, notify.longitude));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notify])

    useEffect(() => {
        radioData?.radio?.content.map((item) => {
            item.rangeFrequency = item.freqMax < item.freq ?  `${item.freq} MHz` : `${item.freq} - ${item.freqMax} MHz`;
            return item;
        })
    }, [radioData]);

    useEffect(() => {
        if (errorMessage) {
            generalAlert("Failed", errorMessage, "error", () => dispatch(handleClearErrormessage()))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage])

    useEffect(() => {
        if (createOPResponse) {
            dispatch(handleCreateSimulate(reqBody, navigate, createOPResponse, altToSum, countOPReq, multiplyAmount));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createOPResponse, countOPReq])

    const handleLoadRadioOrAntenna = (type) => {
        setShowRadioAntena(!showRadioAntena);
        if (type === `radio`) {
            setLoadMode('radio');
        } else {
            setLoadMode('antenna');
        }
    }

    const handleSearchRadioByName = (e) => {
        e.preventDefault();
        if (loadMode === `radio`) {
            dispatch(handleSearchRadio(searchText.toLowerCase(), "name", 0, 10, currentUser.siteId));
        } else {
            dispatch(handleSearchAntenna(searchText.toLowerCase(), "name", 0, 10, currentUser.siteId));
        }
    };

    const handlePageNumber = (e) => {
        if (loadMode === `radio`) {
            dispatch(handleSearchRadio(searchText.toLowerCase(), "name", e.selected, 10, currentUser.siteId));
        } else {
            dispatch(handleSearchAntenna(searchText.toLowerCase(), "name", e.selected, 10, currentUser.siteId));
        }
    };

    const handleSelectedRow = (id) => {
        if (loadMode === "radio") {
            setIdRadio(id);
        } else {
            setIdAntenna(id);
        }
    };

    const handleFillData = () => {
        if (loadMode === "radio") {
            if (idRadio !== "") {
                dispatch(handleGetRadioById(idRadio));
                setIdRadio("");
                setShowRadioAntena(!showRadioAntena);
            }
        } else {
            if (idAntenna !== "") {
                dispatch(handleGetAntennaById(idAntenna));
                setIdAntenna("");
                setShowRadioAntena(!showRadioAntena);
            }
        }
    }

    const handleClose = () => {
        setShowRadioAntena(false);
    };

    const onSearchChange = (e) => {
        setSearchText(e.target.value)
    }

    const setTxPosition = (latitude, longitude, altitude) => {
        const viewer = ref.current.cesiumElement;
        dispatch(setOnClickPosition(viewer, latitude, longitude, altitude));
    }
    const handleClearMap = () => {
        if (ref.current.cesiumElement) {
            const viewer = ref.current.cesiumElement;
            if (listenerMouseMove) {
                listenerMouseMove.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
                viewer.entities.removeById("ms");
            }

            if (notify) {
                dispatch(handleMouseMoveEventToGetPositionAndBearing(viewer, notify.latitude, notify.longitude));
            }

            dispatch(handleWhiteTowerToggle(false))
            dispatch(handleRedTowerToggle(false))
        }
    }

    return (
        <>
            <div
                style={{
                    height: "calc(100% - 77px)",
                }}
            >
                <div className="flex flex-row">
                    <MapViewer ref={ref} isOpen={isOpen}>
                        <Recycle callBackClearMap={handleClearMap}/>
                        <WhiteTower/>
                        <RedTower/>
                    </MapViewer>

                    <RightPannel
                        analyzeType={ANALYZE_TYPE.OPERATION_PLANNING}
                        handleLoadRadioOrAntenna={handleLoadRadioOrAntenna}
                        detailRadio={detailRadio}
                        detailAntenna={detailAntenna}
                        setTxPosition={setTxPosition}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                    />
                </div>

                <ShowPannel
                    isOpen={isOpen}
                    setIsOpen={() => {
                        setIsOpen(prevIsOpen => !prevIsOpen)
                    }}
                />

                <Modals
                    open={showRadioAntena}
                    onClose={handleClose}
                    onSave={handleFillData}
                    btnCloseTitle="Cancel"
                    btnSubmitTitle="Load Data"
                    title={loadMode === 'radio' ? 'Radio' : 'Antena'}
                    size="w-350"
                    height="h-3/4"
                    isBlack={false}
                >
                    {handleHeaderModalRadioOrAntenna(searchText, loadMode, onSearchChange, handleSearchRadioByName)}
                    {handleModalLoadRadioOrAntenna(loadMode, radioData, handleSelectedRow, handlePageNumber, antennaData)}
                </Modals>
            </div>
        </>
    );
};

export default OperationPlanning;
