import React from "react";
import HeaderPage from "../header/HeaderPage";

const Modals = ({
                    title, 
                    children, 
                    onClose, 
                    onSave, 
                    open = false,
                    btnCloseTitle = 'Close', 
                    btnSubmitTitle = 'Apply',
                    size = 'w-96', 
                    closeBtn = false, 
                    height = 'h-2/4',
                    isBlack = true,
                    isRightPannelOpen
                }) => {
                    
    const renderTitle = () => {
        switch (title) {
            case "Radio":
                return <HeaderPage title={title} icon={title.toLowerCase()} isBlack={isBlack} />;
            case "Antena":
                return <HeaderPage title={title} icon={title.toLowerCase()} isBlack={isBlack} />;
            default:
                return (
                <h5 className={`${isBlack ? 'text-white' : 'text-black'} text-2xl font=semibold`}>
                    {title}
                </h5>
            )
        }
    }

    return (
        <>
            {open && (
                <div
                    className={`modalClass flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ${isRightPannelOpen ? "mr-64" : "mx-auto"}`}>
                    <div className={`relative my-6 mx-auto max-w-5xl ${size} ${height}`}>
                        <div
                            className={`${isBlack ? 'bg-tertiary border-2 border-white' : 'bg-white border-2 border-black'} h-full rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none min-w-[400px] px-5`}
                        >
                            {!!closeBtn && (
                                <button
                                    className="btn btn-sm btn-circle absolute right-2 top-2"
                                    onClick={onClose}
                                >
                                    ✕
                                </button>
                            )}

                            {!!title && (
                                <div className="flex justify-center p-5 rounded-t ">
                                    {renderTitle(title)}
                                </div>
                            )}

                            <div className="relative p-4 flex-auto">{children}</div>

                            <div className="flex items-center justify-center gap-x-5 py-5">
                                <button 
                                    type="button" 
                                    onClick={() => onClose()}
                                    className={`btn btn-outline px-14`}
                                >
                                    {btnCloseTitle}
                                </button>

                                <button 
                                    type="button" 
                                    onClick={() => onSave()}
                                    className="btn btn-primary text-white px-14"
                                >
                                    {btnSubmitTitle}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modals
