import React, { forwardRef } from "react";

// import ReactSlider from "react-slider"

const LabelRange = forwardRef(({ titleName, unit,   ...props }, ref) => {
  return (
    <div className="flex flex-col gap-y-3 my-4">
      <div className="flex justify-between items-center px-4">
        <div className="text-white text-sm">{titleName}</div>
        <div className="flex flex-row w-3/5 items-center justify-start ml-6">
          <div className="flex flex-col">
            <span className="text-center text-white">{props.defaultValue}</span>
            <input
              ref={ref}
              {...props}
              type="range"
              min="0"
              max="200"
              className="range range-xs"
            />
          </div>
          {!!unit && <p className="text-sm text-white ml-2 mt-4">{unit}</p>}
        </div>
      </div>
    </div>
  );
});

export default LabelRange;
