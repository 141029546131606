import { ACTION_GET, ACTION_PUT, ACTION_POST, BASE_URL } from "./core";

export const GET_ALL_SITE = () => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/site?size=100`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_SITE_BY_ID = (id) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/site/${id}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const CREATE_SITE = (param) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/site`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_SITE = (param) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/site`;
  return new Promise((resolve, reject) => {
    ACTION_PUT(url, param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};