import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import ReactPaginate from "react-paginate";
import {SketchPicker} from 'react-color';

import HeaderPage from "../../components/header/HeaderPage";
import {
    ALERT_ICON_FAILED,
    ALERT_ICON_SUCCESS,
    ALERT_TEXT_CREATE_FAILED,
    ALERT_TEXT_CREATE_SUCCESS,
    ALERT_TEXT_REMOVE_FAILED,
    ALERT_TEXT_REMOVE_SUCCESS,
    ALERT_TEXT_UPDATE_FAILED,
    ALERT_TEXT_UPDATE_SUCCESS,
    ALERT_TITLE_FAILED,
    ALERT_TITLE_SUCCESS,
    ASSET_ACTION_TYPE,
    ASSET_TYPE,
    CONFIRMATION_ICON_REMOVE,
    CONFIRMATION_TEXT_REMOVE,
    CONFIRMATION_TITLE_REMOVE,
    MAX_REQUEST_SIZE,
    RADIO_BAND,
    STATUS_TYPE,
    USER_MABES,
} from "../../utils/constant/constants";
import {selectAssetToEdit, selectAssetToRemove, selectCurrentItem,} from "../../store/global/global.selector";
import {PlusIcon} from "../../assets/svgs";
import {ModalForm} from "../../components/modal/ModalForm";
import Table from "../../components/table/Table";
import {selectCurrentUser} from "../../store/user/user.selector";
import {
    handleClearData,
    handleClearNotif,
    handleRegisterCirclePrimitive,
    handleRegisterClickListener,
    handleRegisterSiteEntities,
    handleRemoveFrequencyAllocation,
    handleRemoveSiteEntities,
    handleSaveFrequencyAllocation,
    handleSearchFrequencyAllocation,
    handleUpdateFrequencyAllocation
} from "../../store/frequencyAllocation/frequencyallocation.action";
import {
    selectAllFrequencyAllocation,
    selectHandlerListener,
    selectRemoveFrequencyAllocationStatus,
    selectSelectedSiteId,
    selectSiteEntities,
    selectTotalPages,
    selectUpdateFrequencyAllocationStatus,
    selectUploadedFrequencyAllocationStatus
} from "../../store/frequencyAllocation/frequencyallocation.selector";
import {handleClearStateGlobal, handleLoading} from "../../store/global/global.action";
import {generalAlert, generalConfirmation} from "../../utils/notification/notification";
import SearchBox from "../../components/search-box/search-box.component";
import {
    defineFreqType,
    handleChartData,
    hexToRgb,
    options,
    validationFreq
} from "../../store/frequencyAllocation/frequencyallocation.utils";
import {Viewer} from "resium";
import {registerViewer} from "../../store/dashboard/dashboard.action";
import {Bar} from "react-chartjs-2";
import {
    changeEntityBillboard,
    drawEntity,
    flyToIndnesia,
    removeEntityAndDataSource
} from "../../utils/cesium/cesiumUtils";
import {handleHeaderModalRadioOrAntenna, handleModalLoadRadioOrAntenna} from "../../store/area/area.util";
import {selectAllRadio, selectDetailRadio} from "../../store/radio/radio.selector";
import {handleClearDetailRadio, handleGetRadioById, handleSearchRadio} from "../../store/radio/radio.action";
import Modals from "../../components/modal/Modals";
import {SceneMode} from "cesium";


const FrequencyAllocation = () => {
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [pageLoadRadio, setPageLoadRadio] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [searchTextOnChange, setSearchTextOnChange] = useState("");
    const [searchType, setSearchType] = useState("");
    const [filterSelect, setFilterSelect] = useState("");
    const [actionType, setActionType] = useState(ASSET_ACTION_TYPE.NS);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState('#ffffff');
    const [chartData, setChartData] = useState({labels: [], datasets: []});
    const [oldSiteId, setOldSiteId] = useState(-1);
    const [idRadio, setIdRadio] = useState("");
    const [handleShowLoadRadio, setHandleShowLoadRadio] = useState(false);
    const [entityData, setEntityData] = useState("");
    const [searchByRadius, setSearchByRadius] = useState(0);

    const ref = useRef(null);
    const colorPickerRef = useRef(null);
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);
    const currentItem = useSelector(selectCurrentItem);
    const assetToEdit = useSelector(selectAssetToEdit);
    const assetToRemove = useSelector(selectAssetToRemove);
    const data = useSelector(selectAllFrequencyAllocation);
    const totalPages = useSelector(selectTotalPages);
    const uploadedFrequencyAllocationStatus = useSelector(selectUploadedFrequencyAllocationStatus);
    const removeStatus = useSelector(selectRemoveFrequencyAllocationStatus);
    const updateStatus = useSelector(selectUpdateFrequencyAllocationStatus);
    const selectedSiteId = useSelector(selectSelectedSiteId);
    const handlerListener = useSelector(selectHandlerListener);
    const siteEntities = useSelector(selectSiteEntities);
    const radioData = useSelector(selectAllRadio);
    const detailRadio = useSelector(selectDetailRadio);

    const column = [
        {heading: "Usage", value: "usage"},
        {heading: "Frequency", value: "rangeFrequency"},
        {heading: "Color", value: "color"},
    ];

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
    } = useForm({
        defaultValues: {
            usage: "",
            freq: "",
            freqMax: "",
            lat: "",
            lon: "",
        },
    });

    useEffect(() => {
        const viewer = ref.current.cesiumElement;
        dispatch(registerViewer(viewer));
        viewer.scene.mode = SceneMode.COLUMBUS_VIEW;
        flyToIndnesia(viewer, true);

        dispatch(handleLoading(true));

        if (currentUser && currentUser.siteId) {
            dispatch(handleSearchFrequencyAllocation("", "usage", 0, 10, currentUser.siteId));
        }

        dispatch(handleRegisterClickListener(viewer));

        return () => {
            dispatch(handleClearData());
            dispatch(handleClearStateGlobal());
            dispatch(handleClearNotif());
            resetState();
            setSearchText("");
            setSearchType("ALL");
            setPage(0);
            dispatch(handleLoading(false));

            if (viewer && handlerListener) {
                viewer.selectedEntityChanged.removeEventListener(handlerListener);
            }

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedSiteId && siteEntities) {
            const viewer = ref.current.cesiumElement;
            const entityData = siteEntities.content.find(data => data.id === selectedSiteId);
            const oldEntityData = siteEntities.content.find(data => data.id === oldSiteId);
            changeEntityBillboard(viewer, entityData, oldEntityData);
            if (searchTextOnChange && Number.isInteger(Number(searchTextOnChange)) && Number.isFinite(Number(searchTextOnChange)) && entityData) {
                dispatch(handleRegisterCirclePrimitive(entityData.id, viewer, entityData.lat, entityData.lon, Number(searchTextOnChange)));
                dispatch(handleSearchFrequencyAllocation(Number(searchTextOnChange) * 0.001, filterSelect, page, 10, currentUser.siteId, null, entityData.id));
            }
            setOldSiteId(selectedSiteId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSiteId])

    useEffect(() => {
        if (data) {
            const viewer = ref.current.cesiumElement;

            // draw entity into map
            removeEntityAndDataSource(viewer);
            dispatch(handleRemoveSiteEntities());
            const siteEntities = drawEntity(viewer, createObjectToDrawEntity(data));
            const entityData = siteEntities.content.find(data => data.id === selectedSiteId);
            dispatch(handleRegisterSiteEntities(siteEntities));
            changeEntityBillboard(viewer, entityData);
            if (searchTextOnChange && Number.isInteger(Number(searchTextOnChange)) && Number.isFinite(Number(searchTextOnChange)) && entityData) {
                dispatch(handleRegisterCirclePrimitive(entityData.id, viewer, entityData.lat, entityData.lon, Number(searchTextOnChange)));
            }

            data.content.map((item) => {
                item.rangeFrequency = item.frequency === item.frequencyMax ? `${item.frequency} MHz` : `${item.frequency} - ${item.frequencyMax} MHz`;
                return item;
            })

            const {labels, datasets} = handleChartData(data);

            setChartData({
                labels: labels,
                datasets: datasets
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        radioData?.radio?.content?.map((item) => {
            item.rangeFrequency = item.freq === item.freqMax ? `${item.freq} MHz` : `${item.freq} - ${item.freqMax} MHz`;
            return item;
        });
    }, [radioData])

    /**
     * CREATE FREQUENCY ALLOCATION DATA
     */
    useEffect(() => {
        if (uploadedFrequencyAllocationStatus) {
            if (STATUS_TYPE.SUCCESS === uploadedFrequencyAllocationStatus) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_SUCCESS, ALERT_TEXT_CREATE_SUCCESS, ALERT_ICON_SUCCESS, () => dispatch(handleClearNotif()))
            } else if (STATUS_TYPE.FAILED === uploadedFrequencyAllocationStatus) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_FAILED, ALERT_TEXT_CREATE_FAILED, ALERT_ICON_FAILED, () => dispatch(handleClearNotif()))
            }
            resetState();
            setSearchType("ALL");
            dispatch(handleClearData());
            dispatch(handleSearchFrequencyAllocation("", "usage", 0, 10, currentUser.siteId));
            setPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedFrequencyAllocationStatus]);

    /**
     * EDIT FREQUENCY ALLOCATION DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.FREQUENCY_ALLOCATION === assetToEdit && currentItem) {
            fillDataWithCurrentItem(currentItem);
            setColor(currentItem.color)
            setActionType(ASSET_ACTION_TYPE.UPDATE);
            setOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToEdit]);

    useEffect(() => {
        if (updateStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === updateStatus;
            const isFailed = STATUS_TYPE.FAILED === updateStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_UPDATE_SUCCESS : ALERT_TEXT_UPDATE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => {
                        dispatch(handleClearNotif());
                        handleClose();
                    }
                );

                if (filterSelect === "type") {
                    dispatch(handleSearchFrequencyAllocation(searchType, filterSelect, page, 10, currentUser.siteId));
                } else if (filterSelect === "radius") {
                    dispatch(handleSearchFrequencyAllocation(searchByRadius, filterSelect, page, MAX_REQUEST_SIZE, currentUser.siteId, null, entityData));
                } else {
                    dispatch(handleSearchFrequencyAllocation(searchText, filterSelect, page, 10, currentUser.siteId));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStatus]);

    /**
     * REMOVE FREQUENCY ALLOCATION DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.FREQUENCY_ALLOCATION === assetToRemove && currentItem) {
            generalConfirmation(
                CONFIRMATION_TITLE_REMOVE,
                CONFIRMATION_TEXT_REMOVE(currentItem.usage),
                CONFIRMATION_ICON_REMOVE,
                () => {
                    dispatch(handleLoading(true));
                    dispatch(handleRemoveFrequencyAllocation(currentItem.uuid, currentUser.siteId, page, filterSelect));
                },
                () => dispatch(handleClearStateGlobal())
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToRemove]);

    useEffect(() => {
        if (removeStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === removeStatus;
            const isFailed = STATUS_TYPE.FAILED === removeStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_REMOVE_SUCCESS : ALERT_TEXT_REMOVE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => dispatch(handleClearNotif())
                );
            }

            if (data && data.content && data.content.length) {
                if (filterSelect === "type" && searchType === "ALL") {
                    dispatch(handleSearchFrequencyAllocation("", "usage", page, 10, currentUser?.siteId));
                } else if (filterSelect === "type" && searchType !== "ALL") {
                    dispatch(handleSearchFrequencyAllocation(searchType.toLowerCase(), filterSelect, page, 10, currentUser?.siteId));
                } else {
                    dispatch(handleSearchFrequencyAllocation(searchText, filterSelect, page, 10, currentUser.siteId));
                }
            } else {
                if (page) {
                    if (searchType) {
                        if (searchType === "ALL") {
                            dispatch(handleSearchFrequencyAllocation("", "usage", page - 1, 10, currentUser?.siteId));
                        } else {
                            dispatch(handleSearchFrequencyAllocation(searchType.toLowerCase(), filterSelect, page - 1, 10, currentUser?.siteId));
                        }
                    } else {
                        dispatch(handleSearchFrequencyAllocation(searchText, filterSelect, page - 1, 10, currentUser.siteId));
                    }
                    setPage(prevPage => prevPage - 1);
                } else {
                    if (searchType) {
                        if (searchType === "ALL") {
                            dispatch(handleSearchFrequencyAllocation("", "usage", 0, 10, currentUser?.siteId));
                        } else {
                            dispatch(handleSearchFrequencyAllocation(searchType.toLowerCase(), filterSelect, 0, 10, currentUser?.siteId));
                        }
                    } else {
                        dispatch(handleSearchFrequencyAllocation(searchText, filterSelect, 0, 10, currentUser.siteId));
                    }
                    setPage(0);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeStatus]);

    useEffect(() => {
        if (searchType) {
            if (searchType === "ALL") {
                dispatch(handleSearchFrequencyAllocation("", "usage", 0, 10, currentUser?.siteId));
            } else {
                dispatch(handleSearchFrequencyAllocation(searchType.toLowerCase(), filterSelect, 0, 10, currentUser?.siteId));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchType])

    useEffect(() => {
        if (filterSelect === "radius") {
            if (searchTextOnChange && searchTextOnChange.at(0) !== "0" && Number.isInteger(Number(searchTextOnChange)) &&
                Number.isFinite(Number(searchTextOnChange)) && selectedSiteId && siteEntities
                && Number(searchTextOnChange) <= 500000) {

                const viewer = ref.current.cesiumElement;
                const entityData = siteEntities.content.find(data => data.id === selectedSiteId);
                if (entityData) {
                    dispatch(handleRegisterCirclePrimitive(entityData.id, viewer, entityData.lat, entityData.lon, Number(searchTextOnChange)));
                    const q = Number(searchTextOnChange) * 0.001;
                    dispatch(handleSearchFrequencyAllocation(q, filterSelect, page, MAX_REQUEST_SIZE, currentUser.siteId, null, entityData.id));
                    setEntityData(entityData.id);
                    setSearchByRadius(q);
                }
            } else {
                dispatch(handleSearchFrequencyAllocation("", "usage", 0, 10, currentUser?.siteId));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTextOnChange])

    useEffect(() => {
        if (currentUser) {
            if (filterSelect === "type" || filterSelect === "radius") {
                dispatch(handleSearchFrequencyAllocation(searchText.toLowerCase(), "usage", 0, 10, currentUser.siteId));
            } else {
                dispatch(handleSearchFrequencyAllocation("", filterSelect, 0, 10, currentUser.siteId));
            }
            setPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSelect]);

    useEffect(() => {
        if (!detailRadio) {
            return;
        }

        setValue("usage", detailRadio.name);
        setValue("freq", detailRadio.freq);
        setValue("freqMax", detailRadio.freqMax);

        return () => {
            dispatch(handleClearDetailRadio());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailRadio]);

    useEffect(() => {
        let handler = (e) => {
            if (!colorPickerRef?.current?.contains(e.target)) {
                setDisplayColorPicker(false);
            }
        };

        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [colorPickerRef]);

    const createObjectToDrawEntity = (data) => {
        const objTemp = {};
        const arrTemp = [];
        for (let i = 0; i < data.content.length; i++) {
            arrTemp.push({
                id: data.content[i].uuid,
                siteName: data.content[i].usage,
                lat: data.content[i].lat,
                lon: data.content[i].lon,
            })
        }
        objTemp.content = arrTemp;
        return objTemp;
    };

    const resetState = () => {
        reset();
        setActionType(ASSET_ACTION_TYPE.NS);
        setSearchByRadius(0);
        setEntityData("");
        // setSearchTextOnChange("");
        setOpen(false);
    };

    const fillDataWithCurrentItem = (currentItem) => {
        setValue("usage", currentItem.usage);
        setValue("type", currentItem.type);
        setValue("freq", currentItem.frequency);
        setValue("freqMax", currentItem.frequency === currentItem.frequencyMax ? "" : currentItem.frequencyMax);
        setValue("lat", currentItem.lat);
        setValue("lon", currentItem.lon);
    }

    const handleOpen = () => {
        setActionType(ASSET_ACTION_TYPE.CREATE);
        setDisplayColorPicker(false);
        setColor('#ffffff');
        setOpen(true);
    };

    const handleClose = () => {
        setActionType(ASSET_ACTION_TYPE.NS);
        reset();
        setOpen(false);
        setDisplayColorPicker(false);
        dispatch(handleClearStateGlobal());
    };

    const handleCloseLoadRadio = () => {
        setHandleShowLoadRadio(false);
        dispatch(handleClearStateGlobal());
        setPageLoadRadio(0);
    };

    const handleFilterSelect = (e) => {
        setFilterSelect(e.target.value);
        setSearchTextOnChange("");
        setSearchText("");
    }

    const handleSearchType = (e) => {
        setSearchType(e.target.value);
        setPage(0);
    }

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        if (filterSelect === "type" && searchType === "ALL") {
            dispatch(handleSearchFrequencyAllocation("", "usage", e.selected, 10, currentUser?.siteId));
        } else if (filterSelect === "type" && searchType !== "ALL") {
            dispatch(handleSearchFrequencyAllocation(searchType.toLowerCase(), filterSelect, e.selected, 10, currentUser?.siteId));
        } else if (filterSelect === "radius") {
            dispatch(handleSearchFrequencyAllocation(searchText.toLowerCase(), "usage", e.selected, 10, currentUser?.siteId));
        } else {
            dispatch(handleSearchFrequencyAllocation(searchText.toLowerCase(), filterSelect, e.selected, 10, currentUser?.siteId));
        }

        setPage(e.selected);
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    }

    const onSearch = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));

        setSearchText(searchTextOnChange);

        const searchInput = document.getElementsByName("searchText")[0] ? document.getElementsByName("searchText")[0] : "";
        const searchText = searchInput.value.toLowerCase();
        const filter = searchText === "" ? "usage" : filterSelect;

        const {siteId} = currentUser ?? {};

        let query;
        if (searchText === "" && filterSelect !== "type") {
            query = [searchText, filter, 0, 10, siteId];
        } else if (filterSelect === "type" && searchType !== "") {
            query = [searchType.toLowerCase(), filter, 0, 10, siteId];
        } else {
            query = [searchText, filter, 0, 10, siteId];
        }

        dispatch(handleSearchFrequencyAllocation(...query));
        setPage(0);
    };

    const modifyData = (data) => {
        let copyData = JSON.parse(JSON.stringify(data));

        copyData.color = color;
        copyData.type = defineFreqType(copyData.freq, copyData.freqMax ? copyData.freqMax : copyData.freq);

        return copyData;
    }

    const handleSave = (data) => {
        dispatch(handleClearNotif());

        const copyData = modifyData(data);

        if (validationFreq(Number(copyData.freq), Number(copyData.freqMax))) {
            uploadFrequencyAllocationData(copyData);
        } else {
            generalAlert(
                ALERT_TITLE_FAILED,
                `Allowed Frequency:<br>HF (3 MHz - 30 MHz), VHF (30 MHz - 300 MHz), UHF (300 MHz - 3000 MHz), C-Band (4000 MHz - 8000 MHz)`,
                ALERT_ICON_FAILED
            );
        }
    };

    const uploadFrequencyAllocationData = (payload) => {
        if (currentUser && payload) {
            if (ASSET_ACTION_TYPE.CREATE === actionType) {
                dispatch(handleSaveFrequencyAllocation(payload, currentUser.siteId));
                setPage(0);
            } else if (ASSET_ACTION_TYPE.UPDATE === actionType && currentItem && currentItem.uuid && currentUser && currentUser.siteId) {
                dispatch(handleUpdateFrequencyAllocation(currentItem.uuid, payload, currentUser.siteId, page));
            }
            resetState();
        }
    };

    const handleColorChange = (newColor) => {
        setColor(hexToRgb(newColor.hex));
    };

    const handleFillData = () => {
        if (idRadio !== "") {
            dispatch(handleGetRadioById(idRadio));
            setIdRadio("");
            dispatch(handleSearchRadio("", "name", 0, 10, currentUser.siteId));
            setHandleShowLoadRadio(!handleShowLoadRadio);
        }
    }

    const handleSearchRadioByName = (e) => {
        e.preventDefault();
        dispatch(handleSearchRadio(searchText.toLowerCase(), "name", 0, 10, currentUser.siteId));
        setPageLoadRadio(0);
    };

    const handleSelectedRow = (id) => {
        setIdRadio(id);
    };

    // Modal Load Radio
    const handlePageNumber = (e) => {
        dispatch(handleSearchRadio(searchText.toLowerCase(), "name", e.selected, 10, currentUser.siteId));
        setPageLoadRadio(e.selected);
    };

    return (
        <div className="bg-tertiary min-h-screen px-20 py-4">
            <HeaderPage title="Frequency Allocation"/>

            <div className="flex gap-x-10 mt-10">
                {/* Chart Frequency */}
                <main className="w-1/2 flex flex-col gap-y-8">
                    <h3 className="font-semibold text-white text-lg border-white border-b-2 tracking-wider">
                        MAPS
                    </h3>

                    <div className="border-solid border-8 border-white rounded-lg h-[688px] relative">
                        <Viewer
                            ref={ref}
                            fullscreenButton={false}
                            timeline={false}
                            animation={false}
                            baseLayerPicker={true}
                            navigationHelpButton={false}
                            className="h-full w-auto"
                            scene3DOnly={false}
                            sceneModePicker={false}
                            geocoder={true}
                            selectionIndicator={false}
                            useBrowserRecommendedResolution
                            infoBox={false}
                        >
                        </Viewer>
                    </div>
                </main>

                <aside className="w-1/2">
                    <div className="flex justify-between">
                        <div className="flex">
                            {/* Filter Frequency */}
                            <form className="flex" onSubmit={onSearch}>
                                <select
                                    name="filter"
                                    className="select select-bordered select-sm rounded-3xl text-black"
                                    onChange={handleFilterSelect}
                                >
                                    <option value="" selected disabled>Select filter</option>
                                    <option value="usage">Usage</option>
                                    <option value="frequency">Frequency</option>
                                    <option value="type">Type</option>
                                    <option value="radius">Radius</option>
                                    {currentUser?.siteName === USER_MABES && (
                                        <option value="owner">Owner</option>
                                    )}
                                </select>
                                {filterSelect !== "type" ? (
                                    <SearchBox
                                        name="searchText"
                                        value={searchTextOnChange}
                                        placeholder="Search Frequency Allocation"
                                        className="input input-sm min-w-[300px] mx-3 rounded-full px-3 focus:outline-white focus:outline-1 focus:border-primary"
                                        onChangeHandler={onSearchChange}
                                    />
                                ) : (
                                    <select
                                        name="filter"
                                        className="select select-sm min-w-[300px] mx-3 rounded-full px-3"
                                        onChange={handleSearchType}
                                        value={searchType}
                                    >
                                        <option value="ALL">ALL</option>
                                        <option value={RADIO_BAND.HF.name}>HF</option>
                                        <option value={RADIO_BAND.VHF.name}>VHF</option>
                                        <option value={RADIO_BAND.UHF.name}>UHF</option>
                                        <option value={RADIO_BAND.VUHF.name}>VUHF</option>
                                        <option value={RADIO_BAND.C_BAND.name}>C-Band</option>
                                    </select>
                                )}
                                <button
                                    type="submit"
                                    className="btn btn-sm btn-primary rounded-full text-white text-xs px-7"
                                    disabled={filterSelect === "radius" || filterSelect === "type"}
                                >
                                    Search
                                </button>
                            </form>
                        </div>

                        {/* Button Add Frequency Allocation */}
                        <div className="flex justify-center items-center mb-5">
                            <button
                                type="button"
                                className="flex justify-end justify-items-end items-center text-white gap-x-2 text-lg cursor-pointer"
                                onClick={handleOpen}
                            >
                                <PlusIcon width={18} height={16}/>
                                Add Frequency
                            </button>
                        </div>
                    </div>

                    {/* Form Add Frequency Allocation */}
                    <div className="createModal">
                        <ModalForm
                            open={open}
                            onClose={handleClose}
                            onSave={handleSubmit(handleSave)}
                            title={`${actionType} Data Frequency Allocation`}
                            btnCloseTitle="Cancel"
                            btnSubmitTitle="Save"
                            size="w-2/3"
                            closeBtn={true}
                        >
                            <div>
                                <button
                                    type="button"
                                    className="w-fit block text-white underline underline-offset-1 ml-auto mb-5 mr-9"
                                    onClick={() => {
                                        setHandleShowLoadRadio(prevLoad => !prevLoad);
                                        dispatch(handleSearchRadio("", "name", 0, 10, currentUser.siteId));
                                    }}
                                >
                                    Load Data
                                </button>
                                <div className="flex flex-row items-center my-2">
                                    <label className="text-white w-1/3">Usage</label>
                                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                        <input
                                            {...register("usage", {
                                                required: "Required!",
                                                pattern: {
                                                    value: /^\S+/,
                                                    message:
                                                        "Entered value cant start or contain only white spacing",
                                                },
                                            })}
                                            pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                            title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                            className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${
                                                errors?.usage
                                                    ? "outline-1 focus:outline-red-500 border-0 focus:border-none"
                                                    : ""
                                            }`}
                                            type="text"
                                            autoFocus
                                            placeholder={errors?.usage?.message}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-row  items-center my-2">
                                    <label className="text-white w-1/3">Frequency</label>
                                    <div className="flex flex-row w-full items-center justify-start ml-32">
                                        <input
                                            {...register("freq", {
                                                required: "Required!",
                                            })}
                                            type="number"
                                            step="any"
                                            className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${
                                                errors?.freq
                                                    ? "outline-1 focus:outline-red-500 border-0 focus:border-none"
                                                    : ""
                                            }`}
                                            placeholder={errors?.freq?.message}
                                        />
                                        <span className="text-white mx-4">To</span>
                                        <input
                                            {...register("freqMax")}
                                            type="number"
                                            step="any"
                                            className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${
                                                errors?.freqMax
                                                    ? "outline-1 focus:outline-red-500 border-0 focus:border-none"
                                                    : ""
                                            }`}
                                            placeholder={errors?.freqMax?.message}
                                        />
                                        <span className="text-white ml-4 mr-10">Mhz</span>
                                    </div>
                                </div>

                                <div className="flex flex-row items-center my-2">
                                    <label className="text-white w-1/3">Latitude</label>
                                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                        <input
                                            {...register("lat", {
                                                required: "Required!",
                                                min: -90,
                                                max: 90,
                                                pattern: {
                                                    value: /^\S+/,
                                                    message: "Entered value cant start or contain only white spacing"
                                                },
                                            })}
                                            pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                            title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                            className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.lat ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                            id="lat"
                                            type="text"
                                            placeholder={errors?.lat?.message}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-row items-center my-2">
                                    <label className="text-white w-1/3">Longitude</label>
                                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                        <input
                                            {...register("lon", {
                                                required: "Required!",
                                                min: -180,
                                                max: 180,
                                                pattern: {
                                                    value: /^\S+/,
                                                    message: "Entered value cant start or contain only white spacing"
                                                },
                                            })}
                                            pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                            title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                            className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.lon ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                            id="lon"
                                            type="text"
                                            placeholder={errors?.lon?.message}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-row items-center my-2">
                                    <label className="w-1/3 text-white">Color</label>
                                    <div>
                                        <div className="fixed bottom-[125px] ml-6">
                                            <div className="flex items-center">
                                                <div ref={colorPickerRef}>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm text-white text-xs cursor-pointer inline-flex items-center rounded-md"
                                                        onClick={() => setDisplayColorPicker(prevDisplay => !prevDisplay)}
                                                    >
                                                        Select Color
                                                    </button>
                                                    {displayColorPicker && (
                                                        <div className="absolute text-black mt-2">
                                                            <SketchPicker
                                                                color={color}
                                                                onChange={handleColorChange}
                                                                disableAlpha
                                                                presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div style={{backgroundColor: `${color}`}}
                                                    className="w-7 h-7 rounded-full mx-3 border-white border-2">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalForm>
                    </div>
                    {handleShowLoadRadio && (
                        <Modals
                            open={handleShowLoadRadio}
                            onClose={handleCloseLoadRadio}
                            onSave={handleFillData}
                            btnCloseTitle="Cancel"
                            btnSubmitTitle="Load Data"
                            title={'Radio'}
                            size="w-2/3"
                            height="h-3/4"
                            isBlack={false}
                        >
                            {handleHeaderModalRadioOrAntenna(searchText, "radio", onSearchChange, handleSearchRadioByName)}
                            {handleModalLoadRadioOrAntenna("radio", radioData, handleSelectedRow, handlePageNumber, "", "frequencyAllocation", pageLoadRadio)}
                        </Modals>
                    )}

                    {/* Frequency Data Tabel */}
                    {data && data.content && data.content.length > 0 ? (
                        <div className="mt-3.5 max-h-[690px] overflow-y-auto">
                            <Table
                                data={data.content}
                                column={column}
                                assetType={ASSET_TYPE.FREQUENCY_ALLOCATION}
                            />
                        </div>
                    ) : (
                        <p className="flex flex-col text-white text-xl text-center pt-10">
                            Data is empty
                        </p>
                    )}

                    <div className="text-white flex flex-col">
                        <div className="flex justify-center mt-6 self-end h-14 items-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={page}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </aside>
            </div>

            <div className="w-full mt-10">
                <h3 className="font-semibold text-white text-lg border-white border-b-2 tracking-wider mb-8">
                    FREQUENCY CHART
                </h3>
                <div className="w-full mb-8">
                    {chartData && (
                        <section>
                            <Bar options={options} data={chartData} height={400}/>
                        </section>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FrequencyAllocation;
