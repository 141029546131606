import {CREATE_RADIO, DELETE_RADIO_BY_ID, GET_RADIO_BY_ID, SEARCH_RADIO, UPDATE_RADIO} from "../../services/radio";
import {createAction} from "../../utils/reducer/reducerUtil";
import {RADIO_ACTION_TYPES} from "./radio.types";
import {
    DELETE_FILE,
    DELETE_IMAGE,
    GET_ASSET_RADIO,
    GET_BRAND_BY_USAGE,
    GET_CATEGORY_BY_USAGE,
    GET_TYPE_BY_USAGE,
    UPLOAD_FILE,
    UPLOAD_IMAGE
} from "../../services/asset";
import {GET_ALL_SITE} from "../../services/site";
import {RADIO_BAND, STATUS_TYPE} from "../../utils/constant/constants";
import {handleClearStateGlobal, handleLoading} from "../global/global.action";
import {GLOBAL_ACTION_TYPES} from "../global/global.types";

const defineTypeFreq = (freqMin, freqMax) => {
    let freqType;
    if (freqMin > 400 || (freqMax && freqMin > 400 && freqMax <= 8000)) { // C-Band
        freqType = RADIO_BAND.C_BAND.name.toLowerCase();
    } else if (freqMin > 300 || (freqMax && freqMin > 300 && freqMax <= 3000)) { // UHF
        freqType = RADIO_BAND.UHF.name.toLowerCase();
    } else if (freqMin > 30 || (freqMax && freqMin > 30 && freqMax <= 300)) { // VHF
        freqType = RADIO_BAND.VHF.name.toLowerCase();
    } else if (freqMin >= 3 || (freqMax && freqMin >= 3 && freqMax <= 30)) { // HF
        freqType = RADIO_BAND.HF.name.toLowerCase();
    }
    return freqType;
}

export const handleUploadFile = (item) => dispatch => {
    const formData = new FormData();
    formData.append("file", item);
    UPLOAD_FILE(formData)
        .then(res => {
            dispatch(createAction(RADIO_ACTION_TYPES.UPLOAD_FILE, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
        });
}

export const handleUploadImage = (item) => dispatch => {
    const formData = new FormData();
    formData.append("file", item);
    UPLOAD_IMAGE(formData)
        .then(res => {
            dispatch(createAction(RADIO_ACTION_TYPES.UPLOAD_IMAGE, res.id));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
        });
}

export const handleRemoveUploadedFile = (item) => dispatch => {
    DELETE_FILE(item)
        .then(() => {})
        .catch(() => {});
}

export const handleRemoveUploadedImage = (item) => dispatch => {
    DELETE_IMAGE(item)
        .then(() => {})
        .catch(() => {});
}

export const handleFetchStatusRadio = () => async (dispatch) => {
    const res = await GET_ASSET_RADIO();
    if (res) {
        dispatch(createAction(RADIO_ACTION_TYPES.GET_STATUS, res));
    }
}
export const handleFetchBrand = () => async (dispatch) => {
    const res = await GET_BRAND_BY_USAGE("radio");
    if (res && res.length > 0) {
        dispatch(createAction(RADIO_ACTION_TYPES.GET_BRAND, res));
    }
}
export const handleFetchCategory = () => async (dispatch) => {
    const res = await GET_CATEGORY_BY_USAGE("radio")
    if (res && res.length > 0) {
        dispatch(createAction(RADIO_ACTION_TYPES.GET_CATEGORY, res));
    }
}
export const handleFetchType = () => async (dispatch) => {
    const res = await GET_TYPE_BY_USAGE("radio")
    if (res && res.length > 0) {
        dispatch(createAction(RADIO_ACTION_TYPES.GET_TYPE, res));
    }
}
export const handleFetchSite = () => async (dispatch) => {
    const res = await GET_ALL_SITE();
    if (res && res.content.length > 0) {
        dispatch(createAction(RADIO_ACTION_TYPES.GET_SITE, res));
    }
}

export const handleClearData = () => (dispatch) => {
    dispatch(createAction(RADIO_ACTION_TYPES.CLEAR_DATA))
}

export const handleClearNotif = () => dispatch => {
    dispatch(createAction(RADIO_ACTION_TYPES.UPLOAD_RADIO_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(RADIO_ACTION_TYPES.UPDATED, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(RADIO_ACTION_TYPES.REMOVED, STATUS_TYPE.NO_STATEMENT));
}

export const handleSaveRadio = (data, images, files, siteId) => async (dispatch) => {

    const payload = {
        name: data.name,
        category: {category: data.category},
        type: {assetType: data.type},
        serialNo: data.serialNo,
        partNo: data.partNo,
        site: {id: siteId},
        brand: {id: Number(data.brand)},
        freq: Number(data.freq),
        power: Number(data.power),
        freqMax: Number(data.freqMax),
        bandwith: Number(data.bandwidth),
        notes: data.notes,
        status: data.status,
        imageUrlList: images,
        fileUrlList: files.urlArr,
        fileNameList: files.nameArr,
        typeFreq: defineTypeFreq(data.freq, data.freqMax ? data.freqMax : null)
    };

    CREATE_RADIO(payload).then(() => {
        dispatch(createAction(RADIO_ACTION_TYPES.UPLOAD_RADIO_STATUS, STATUS_TYPE.SUCCESS))
    }).catch(e => {
        dispatch(createAction(RADIO_ACTION_TYPES.UPLOAD_RADIO_STATUS, STATUS_TYPE.FAILED))
    });
    dispatch(handleClearStateGlobal());
    dispatch(createAction(RADIO_ACTION_TYPES.IS_LOADING, false));

}

export const handleStartLoading = () => dispatch => {
    dispatch(createAction(RADIO_ACTION_TYPES.IS_LOADING, true));
}

export const handleRemoveRadio = (id, siteId, page, type) => async (dispatch) => {
    dispatch(createAction(RADIO_ACTION_TYPES.IS_LOADING, true));
    DELETE_RADIO_BY_ID(id)
        .then(() => {
            dispatch(createAction(RADIO_ACTION_TYPES.REMOVED, STATUS_TYPE.SUCCESS))
            dispatch(handleClearStateGlobal());
        })
        .catch(() => {
            dispatch(createAction(RADIO_ACTION_TYPES.REMOVED, STATUS_TYPE.FAILED))
            dispatch(handleClearStateGlobal());
        })
    dispatch(createAction(RADIO_ACTION_TYPES.IS_LOADING, false));
}
export const handleUpdateRadio = (id, data, images, files, siteId, page) => async (dispatch) => {

    const payload = {
        name: data.name,
        category: {category: data.category},
        type: {assetType: data.type},
        serialNo: data.serialNo,
        partNo: data.partNo,
        site: {id: siteId},
        brand: {id: Number(data.brand)},
        freq: Number(data.freq),
        power: Number(data.power),
        freqMax: Number(data.freqMax),
        bandwith: Number(data.bandwidth),
        notes: data.notes,
        status: data.status,
        imageUrlList: images,
        fileUrlList: files.urlArr,
        fileNameList: files.nameArr,
        typeFreq: defineTypeFreq(data.freq, data.freqMax ? data.freqMax : null)
    };

    UPDATE_RADIO(id, payload)
        .then(() => {
            dispatch(createAction(RADIO_ACTION_TYPES.UPDATED, STATUS_TYPE.SUCCESS));
        })
        .catch(() => {
            dispatch(createAction(RADIO_ACTION_TYPES.UPDATED, STATUS_TYPE.FAILED));
        })
    dispatch(handleClearStateGlobal());
    dispatch(createAction(RADIO_ACTION_TYPES.IS_LOADING, false));
};

export const handleSearchRadio = (q, type, page, size, siteId) => dispatch => {
    SEARCH_RADIO(q, type, page, size, siteId)
        .then(res => {
            dispatch(createAction(RADIO_ACTION_TYPES.SEARCH, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        })

};

export const handleSearchRadioReport = (q, type, page, size, siteId) => dispatch => {
    SEARCH_RADIO(q, type, page, size, siteId)
        .then(res => {
            dispatch(createAction(RADIO_ACTION_TYPES.SEARCH_REPORT, res));
        })
        .catch(() => {})
};

export const handleGetRadioById = (id) => dispatch => {
    GET_RADIO_BY_ID(id)
        .then(res => {
            dispatch(createAction(RADIO_ACTION_TYPES.DETAIL_RADIO, res));
        })
        .catch(() => {});
};

export const handleClearDetailRadio = () => (dispatch) => {
    dispatch(createAction(RADIO_ACTION_TYPES.CLEAR_DETAIL_RADIO));
};
