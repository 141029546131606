import * as React from "react"

const FilterIcon = (props) => (
    <svg
    width={27}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.762 1h21.475a1 1 0 0 1 .738 1.675l-8.213 9.038a.975.975 0 0 0-.262.675v7.074a.987.987 0 0 1-.45.838l-4 2.662a1 1 0 0 1-1.55-.825v-9.75a.975.975 0 0 0-.263-.675L2.025 2.675A1 1 0 0 1 2.762 1v0Z"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FilterIcon
