

export function putDrawingData(drawingData, id, pathDtos, line, label) {
    const cpDrawingData = [...drawingData];
    const atIndex = cpDrawingData.findIndex(e => e.id === id);
    if (atIndex === -1) {
        cpDrawingData.push({id, pathDtos, line, label});
    } else {
        cpDrawingData[atIndex].pathDtos = pathDtos;
        cpDrawingData[atIndex].line = line;
        cpDrawingData[atIndex].label = label;
    }
    return cpDrawingData;
}

export function getAllId(responseData) {
    const res = [];
    if (responseData && responseData.content && responseData.content.length) {
        responseData.content.forEach(d => res.push(d.distributionAssetsDto.id));
    }
    return res;
}

export function createPayloadAddKinetic(pointArr) {
    const res = createPathDtos(pointArr);
    return {pathDtos : res};
}

export function createPayloadDeleteKinetic(seqArr) {
    const res = [];
    seqArr.forEach(seq => res.push({seqOrder: seq.seqOrder}));
    return {pathDtos: res};
}

export function createPayloadUpdate(name, core, totalCableLength, numberOfPoints) {
    return {
        distributionAssetsDto: {
            name
        },
        routePathDto: {
            core,
            totalCableLength,
            numberOfPoints
        }
    };
}

export function createPathDtos(points) {
    const pathDtos = []
    for (let i = 0; i < points.length; i++) {
        pathDtos.push({
            seqOrder: i + 1,
            lat: points[i].lat,
            lon: points[i].lon
        })
    }
    return pathDtos;
}

export function createPayload(form, currentUser, totalLength, points) {
    return {
        distributionAssetsDto: {
            name: form.routeName,
            site: {
                id: currentUser.siteId
            },
            type: {
                id: 3
            }
        },
        routePathDto: {
            core: Number(form.core),
            totalCableLength: totalLength
        },
        pathDtos: createPathDtos(points)
    }
}

export const column = [
    {heading: "Name", value: "name"},
    {heading: "Total Cable Length (m)", value: "totalLength"},
    {heading: "Core", value: "core"},
    {heading: "Number of Points", value: "points"},
    {heading: "Owner", value: "siteName"},
]
