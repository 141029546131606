import React, {useEffect, useRef, useState} from "react";
import MouseTooltip from "react-sticky-mouse-tooltip";
import CardAssets from "../../components/card/CardAssets";
import HeaderPage from "../../components/header/HeaderPage";
import {MapViewer} from "../../components/map/MapViewer";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCardData,
    selectEventListener,
    selectFoData,
    selectIsToolTipsOpen,
    selectSelectedSite,
    selectSites,
    selectTotalAsset,
    selectTowerCivilData,
    selectWorkshopData
} from "../../store/distribution/distribution.selector";
import {
    drawSite,
    fetchDataTotal,
    handleFetchData,
    handleFetchDataSite,
    handleFetchDistributionData
} from "../../store/distribution/distribution.action";
import {drawTower, flyToIndnesia, removeImageryProviderForTerrain} from "../../utils/cesium/cesiumUtils";
import {SceneMode, ScreenSpaceEventType} from "cesium";
import {registerViewer} from "../../store/dashboard/dashboard.action";
import LabelValue from "../../components/label/LabelValue";
import {TYPE} from "../../store/distribution/distribution.types";
import {selectCurrentUser} from "../../store/user/user.selector";

const Distribution = () => {
    const ref = useRef(null); // viewer
    const [site, setSite] = useState({id: -1});

    const dispatch = useDispatch();
    const cardData = useSelector(selectCardData)
    const eventListener = useSelector(selectEventListener)
    const isToolTipsOpen = useSelector(selectIsToolTipsOpen)
    const totalAsset = useSelector(selectTotalAsset)
    const selectedSite = useSelector(selectSelectedSite)
    const towerCivilData = useSelector(selectTowerCivilData)
    const workshopData = useSelector(selectWorkshopData)
    const sites = useSelector(selectSites)
    const foData = useSelector(selectFoData)
    const currentUser = useSelector(selectCurrentUser)

    useEffect(() => {
        const viewer = ref.current.cesiumElement;
        dispatch(registerViewer(viewer))
        removeImageryProviderForTerrain(viewer);
        viewer.scene.mode = SceneMode.COLUMBUS_VIEW;
        flyToIndnesia(viewer, true)
        dispatch(handleFetchData(currentUser.siteId));
        dispatch(handleFetchDistributionData());
        if (foData) {
            dispatch(handleFetchDataSite(viewer));
        }
        return () => {
            if (viewer) {
                viewer.entities.removeAll();
            }
            if (eventListener) {
                eventListener.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedSite) {
            if (selectedSite.id !== site.id) {
                dispatch(fetchDataTotal(selectedSite, foData));
                setSite(selectedSite);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSite])

    const handleFilterDistribution = (e) => {
        const viewer = ref.current.cesiumElement;
        viewer.entities.removeAll();

        if (e.target.value === TYPE.TOWER_CIVIL || e.target.value === TYPE.WORKSHOP) {
            if (e.target.value === TYPE.TOWER_CIVIL && towerCivilData) {
                drawTower(viewer, towerCivilData, false);
            } else if (e.target.value === TYPE.WORKSHOP && workshopData) {
                drawTower(viewer, workshopData, false, true);
            }
        } else if (e.target.value === TYPE.FO && workshopData) {
            dispatch(drawSite(viewer, sites));
        }
    }

    return (
        <div className="bg-tertiary min-h-screen px-20 py-4">
            <HeaderPage title="Distribution Data"/>
            <section className="flex gap-x-64 mt-10">
                {cardData.map((asset) => (
                    <CardAssets
                        key={asset.id}
                        asset={asset.name}
                        item={asset.item}
                        icon={asset.icon}
                    />
                ))}
            </section>
            <div className="flex mt-14 mb-6">
                <select
                    name="filter"
                    className="select select-bordered w-[200px] rounded-3xl h-[30px]"
                    onChange={handleFilterDistribution}
                >
                    <option selected disabled>Select filter</option>
                    <option value={TYPE.TOWER_CIVIL}>Tower Civil</option>
                    <option value={TYPE.WORKSHOP}>Electronic Workshop</option>
                </select>
            </div>
            <div className="border-solid border-8 border-white rounded-lg">
                <MapViewer isScheneModePickerActive={false} ref={ref}/>
            </div>

            <MouseTooltip visible={isToolTipsOpen} offsetX={15} offsetY={10}>
                <div className="bg-[rgba(0,0,0,0.4)] rounded-xl px-4 py-6 z-40">
                    {totalAsset && (
                        <>
                            <p className="text-white mb-3 px-4 font-semibold">{totalAsset.siteName}</p>
                            <LabelValue
                                data={[
                                    {
                                        title: "Fiber Optic",
                                        value: totalAsset.totalFo,
                                    },
                                ]}
                            />
                        </>
                    )}
                </div>
            </MouseTooltip>
        </div>
    );
};

export default Distribution;
