export const RULER_ACTION_TYPES = {
    ADD_POINT: 'ruler/ADD_POINT',
    REMOVE_A_RULER: 'ruler/REMOVE_A_RULER',
    ADD_A_RULER: 'ruler/ADD_A_RULER',
    CLEAR_RULER: 'ruler/CLEAR_RULER',
    ADD_POLYLINE: 'ruler/ADD_POLYLINE',
    RESET_POLYLINE: 'ruler/RESET_POLYLINE',
    ADD_LABEL: 'ruler/ADD_LABEL',
    RESET_LABEL: 'ruler/RESET_LABEL',
    RESET_POINT: 'ruler/RESET_POINT',
    REMOVE_EVENT: 'ruler/REMOVE_EVENT',
    ADD_EVENT: 'ruler/ADD_EVENT',
    INCREASE_COUNT: 'ruler/INCREASE_COUNT',
    DECREASE_COUNT: 'ruler/DECREASE_COUNT',
    CLEAR_COUNT: 'ruler/CLEAR_COUNT',
    HANDLE_RULER_TOGGLE: 'ruler/HANDLE_RULER_TOGGLE',
    HANDLE_RULER_TOGGLE_DISABLE: 'ruler/HANDLE_RULER_TOGGLE_DISABLE',
    RULER_INACTIVE: 'ruler/RULER_INACTIVE',
    ADD_EVENT_HANDLER: 'ruler/ADD_EVENT_HANDLER',
    REMOVE_EVENT_HANDLER: 'ruler/REMOVE_EVENT_HANDLER',
    PUT_POSITION_ONCLICK: 'ruler/PUT_POSITION_ONCLICK',
    PUT_PRIMITIVE_LABEL: 'ruler/PUT_PRIMITIVE_LABEL',
    DO_NOTHING: 'ruler/DO_NOTHING',
};
