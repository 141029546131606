import * as React from "react"

const MaintenanceDataIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 8267.72 11692.91"
    {...props}
  >
    <g fill="currentColor" strokeMiterlimit={22.926}>
      <path
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M-292.65 165.63h8904.69c677.3 0 1231.45 554.15 1231.45 1231.45v8904.69c0 677.3-554.15 1231.46-1231.45 1231.46H-292.65c-677.3 0-1231.46-554.15-1231.46-1231.46V1397.08c0-677.3 554.15-1231.45 1231.46-1231.45zm304.78 389.1h8295.11c630.96 0 1147.14 516.19 1147.14 1147.14v8295.11c0 630.96-516.19 1147.14-1147.14 1147.14H12.13c-630.96 0-1147.14-516.19-1147.14-1147.14V1701.87c0-630.96 516.19-1147.14 1147.14-1147.14z"
      />
      <g stroke="#2B2A29" strokeWidth={7.87}>
        <path d="M3013.4 1593.87h174.86v6819.69H3013.4zM1527.06 1593.87h174.86v6819.69h-174.86zM2357.67 1427.75c458.73 0 830.6 74.37 830.6 166.12h-163.06c-151.34-40.81-393.99-67.26-667.55-67.26-273.56 0-516.2 26.46-667.54 67.26h-163.06c0-91.75 371.87-166.12 830.6-166.12z" />
        <path d="M2357.67 8247.44c266.56 0 503.76 25.12 655.74 64.16v98.86c-151.98-39.04-389.18-64.16-655.74-64.16s-503.76 25.12-655.74 64.16v-98.86c151.98-39.04 389.18-64.16 655.74-64.16zM4674.61 1593.87h174.86v6819.69h-174.86zM3188.27 1593.87h174.86v6819.69h-174.86zM4018.87 1427.75c458.73 0 830.61 74.37 830.61 166.12h-163.06c-151.34-40.81-393.99-67.26-667.55-67.26-273.56 0-516.2 26.46-667.54 67.26h-163.06c0-91.75 371.87-166.12 830.6-166.12zM4018.87 8247.44c266.56 0 503.76 25.12 655.74 64.16v98.86c-151.98-39.04-389.18-64.16-655.74-64.16s-503.76 25.12-655.74 64.16v-98.86c151.98-39.04 389.18-64.16 655.74-64.16zM6335.82 1593.87h174.86v6819.69h-174.86zM4849.47 1593.87h174.86v6819.69h-174.86z" />
        <path d="M5680.08 1427.75c458.73 0 830.6 74.37 830.6 166.12h-163.06c-151.34-40.81-393.98-67.26-667.54-67.26-273.56 0-516.2 26.46-667.55 67.26h-163.06c0-91.75 371.87-166.12 830.6-166.12zM5680.08 8247.44c266.56 0 503.75 25.12 655.74 64.16v98.86c-151.98-39.04-389.17-64.16-655.74-64.16-266.56 0-503.76 25.12-655.74 64.16v-98.86c151.98-39.04 389.19-64.16 655.74-64.16zM8714.54 8238.7v174.86H6578.5V8238.7zM8714.54 6752.36v174.86H6578.5v-174.86z" />
        <path d="M8880.66 7582.96c0 458.73-74.37 830.6-166.12 830.6V8250.5c40.81-151.34 67.26-393.98 67.26-667.54 0-273.56-26.46-516.2-67.26-667.55v-163.06c91.75 0 166.12 371.87 166.12 830.6z" />
      </g>
      <g stroke="#FEFEFE" strokeWidth={98.43}>
        <path d="M888.9 4163c379.81 197.96 639.17 595.26 639.17 1053.2 0 141.04-24.66 276.31-69.8 401.81l3944 3620.37a794.714 794.714 0 0 1 50.22 46.1c306.52 306.52 306.52 803.49 0 1110.01s-803.49 306.52-1110.01 0a794.961 794.961 0 0 1-46.1-50.22L643.06 6364.38c-96.42 25.29-197.61 38.81-301.97 38.81-655.55 0-1186.98-531.43-1186.98-1186.98 0-457.94 259.36-855.24 639.17-1053.2v1056.61l555.26 346.08 540.37-379.6V4163.01zm3714.3 5382.2c162.52-162.52 426.03-162.52 588.56 0 162.53 162.53 162.53 426.04 0 588.56-162.53 162.52-426.04 162.52-588.56 0-162.53-162.53-162.53-426.04 0-588.56z" />
        <circle
          transform="scale(.14882) rotate(-45 96261.117 -6665.754)"
          r={2796.41}
        />
        <path d="M2077.9 6543.06 4364.84 8830c131.68 131.68 131.68 345.17 0 476.85-131.68 131.67-345.17 131.67-476.84 0L1601.05 7019.9c-131.68-131.68-131.68-345.17 0-476.84 131.68-131.68 345.17-131.68 476.85 0z" />
      </g>
    </g>
  </svg>
)

export default MaintenanceDataIcon
