import FadeLoader from "react-spinners/FadeLoader";

const LoadingSpinner = ({isLoading}) => {
    return (
        <div className="fixed w-full h-full flex justify-center items-center bg-black opacity-60" style={{zIndex: 99999}}>
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <FadeLoader color={'white'} loading={isLoading} size={150}/>
            </div>
        </div>
    );
};

export default LoadingSpinner;
