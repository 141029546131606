import {COVERAGE_RULER_ACTION_TYPES} from './coverageRuler.types'
import {addIntoArray} from "./coverageRuler.utils";

const INITIAL_STATE = {
    rulerToggle: false,
    entityIdArray: [],
    entityName: "",
    bearing: "",
    distance: "",

};

export const coverageRulerReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case COVERAGE_RULER_ACTION_TYPES.ENTITY_ID_ARRAY:
            const length = state.entityIdArray.length ? state.entityIdArray.length + 1 : 1;
            let name = state.entityName;
            let bearing = state.bearing;
            let distance = state.distance;
            const obj = {
                id: length,
                lat: payload.lat,
                lon: payload.lon,
                alt: payload.alt,
                bearing,
                distance,
                name: name,

            };
            const newArr = addIntoArray(state.entityIdArray, obj);
            return {...state, entityIdArray: newArr};
        case COVERAGE_RULER_ACTION_TYPES.REMOVE_LAST_ARRAY:
            const cpArr = [...state.entityIdArray]
            if (cpArr && cpArr.length) {
                cpArr.pop();
            }
            return {...state, entityIdArray: cpArr};
        case COVERAGE_RULER_ACTION_TYPES.BEARING:
            return {...state, bearing: payload};
        case COVERAGE_RULER_ACTION_TYPES.DISTANCE:
            return {...state, distance: payload};
        case COVERAGE_RULER_ACTION_TYPES.ENTITY_NAME:
            return {...state, entityName: payload};
        case COVERAGE_RULER_ACTION_TYPES.HANDLE_RULER_TOGGLE:
            return {...state, rulerToggle: payload};
        case COVERAGE_RULER_ACTION_TYPES.RESET:
            return {...state, rulerToggle: false, entityIdArray: []};
        default:
            return state;
    }
};