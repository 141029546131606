import {createSelector} from "reselect";
import {getPercentage} from "../../utils/calculate/convert";

const selectDistributionReducer = (state) => state.distributionReducer;

export const selectAssets = createSelector(
    [selectDistributionReducer],
    (state) => state.assets
)

export const selectCardData = createSelector(
    [selectAssets],
    (data) => {
        if (data) {
            const statusT = data.tower;
            const statusW = data.workshop;
            return [
                {
                    id: 1,
                    name: "Tower Civil Total",
                    item: statusT ?? 0,
                    icon: "tower",
                },
                {
                    id: 2,
                    name: "Electronic Workshop Total",
                    item: statusW ?? 0,
                    icon: "electronic",
                },
            ]
        } else {
            return [];
        }
    }
)

export const selectChartData = createSelector(
    [selectAssets],
    (state) => {
        if (state) {
            return [
                {
                    datasets: [
                        {
                            label: "Radio",
                            data: [
                                getPercentage(state.radioDetail.spare, state.radioDetail.total),
                                getPercentage(state.radioDetail.service, state.radioDetail.total),
                                getPercentage(state.radioDetail.unservice, state.radioDetail.total),
                            ],
                            backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                            borderColor: ["#040f0f"],
                            borderWidth: 10,
                        },
                    ],
                },
                {
                    datasets: [
                        {
                            label: "Antenna",
                            data: [
                                getPercentage(state.antennaDetail.spare, state.antennaDetail.total),
                                getPercentage(state.antennaDetail.service, state.antennaDetail.total),
                                getPercentage(state.antennaDetail.unservice, state.antennaDetail.total),
                            ],
                            backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                            borderColor: ["#040f0f"],
                            borderWidth: 10,
                        },
                    ],
                },
                {
                    datasets: [
                        {
                            label: "PABX",
                            data: [
                                getPercentage(state.pabxDetail.spare, state.pabxDetail.total),
                                getPercentage(state.pabxDetail.service, state.pabxDetail.total),
                                getPercentage(state.pabxDetail.unservice, state.pabxDetail.total),
                            ],
                            backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                            borderColor: ["#040f0f"],
                            borderWidth: 10,
                        },
                    ],
                },
            ];
        } else {
            return [];
        }
    }
)

export const selectSites = createSelector(
    [selectDistributionReducer],
    (state) => state.sites
)
export const selectHandler = createSelector(
    [selectDistributionReducer],
    (state) => state.handler
)
export const selectEventListener = createSelector(
    [selectDistributionReducer],
    (state) => state.eventListener
)
export const selectIsToolTipsOpen = createSelector(
    [selectDistributionReducer],
    (state) => state.isTooltipsOpen
)
export const selectTotalAsset = createSelector(
    [selectDistributionReducer],
    (state) => state.totalAsset
)
export const selectSelectedSite = createSelector(
    [selectDistributionReducer],
    (state) => state.selectedSite
)
export const selectTowerCivilData = createSelector(
    [selectDistributionReducer],
    (state) => state.towerCivilData
)
export const selectWorkshopData = createSelector(
    [selectDistributionReducer],
    (state) => state.workshopData
)
export const selectFoData = createSelector(
    [selectDistributionReducer],
    (state) => state.foData
)