import React, { createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux
import { removeAllEntites } from "../../store/tower/tower.action";
import { selectViewer } from "../../store/dashboard/dashboard.selector";

// Asset
import { RecycleIcon } from "../../assets/png";

const Recycle = ({ callBackClearMap }) => {
    const ref = createRef();
    const dispatch = useDispatch();
    const viewer = useSelector(selectViewer);

    useEffect(() => {
        ref.current.classList.add("cesium-button", "cesium-toolbar-button");
        const toolbar = document.querySelector("div.cesium-viewer-toolbar");
        const modeButton = document.querySelector("span.cesium-sceneModePicker-wrapper");
        toolbar.insertBefore(ref.current, modeButton);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => {
        dispatch(removeAllEntites(viewer));
        callBackClearMap();
    }

    return (
        <>
            <div
                id="whitetower"
                ref={ref}
                onClick={handleClick}
            >
                <img
                    src={RecycleIcon}
                    alt="recycleIcon"
                    className="w-[24px] h-[24px] mt-1 ml-[2.5px]"
                />
            </div>
        </>
    );
};

export default Recycle
