import moment from "moment";

import {createSelector} from "reselect";
import {MONTH_NAMES} from "../../utils/constant/constants";

const selectMaintenanceReducer = (state) => state.maintenanceReducer;

const mappingAsset = (assetType, aData, content, i) => {
    let dTemp;
    if (assetType === `radio`) {
        dTemp = aData.radio.length > 0 && aData.radio.find(r => r.id === content[i].assetId ? r : null);
    } else if (assetType === `tower`) {
        dTemp = aData.tower.length > 0 && aData.tower.find(r => r.id === content[i].assetId ? r : null);
    } else if (assetType === `antenna`) {
        dTemp = aData.antenna.length > 0 && aData.antenna.find(r => r.id === content[i].assetId ? r : null);
    } else if (assetType === `pabx`) {
        dTemp = aData.pabx.length > 0 && aData.pabx.find(r => r.id === content[i].assetId ? r : null);
    } else if (assetType === `dss`) {
        dTemp = aData.dss.length > 0 && aData.dss.find(r => r.id === content[i].assetId ? r : null);
    }
    return dTemp;
}


// =========================================================================
// MAINTENANCE DATA
// =========================================================================
export const selectGoToEdit = createSelector(
    [selectMaintenanceReducer],
    (state) => state.goToEdit
)
export const selectSelectedMaintenanceData = createSelector(
    [selectMaintenanceReducer],
    (state) => state.selectedMaintenanceData
)
export const selectSelectedMaintenanceType = createSelector(
    [selectMaintenanceReducer],
    (state) => {
        if (state.selectedMaintenanceType) {
            return state.selectedMaintenanceType // {type, valueType}
        } else {
            return null
        }
    }
)
export const selectSelectedAssetType = createSelector(
    [selectMaintenanceReducer],
    (state) => state.selectedAssetType
)
export const selectSelectedAssetData = createSelector(
    [selectMaintenanceReducer],
    (state) => state.selectedAssetData
)

export const selectDetailSelectedAssetData = createSelector(
    [selectSelectedAssetData],
    (data) => {
        if (data) {
            return {
                assetId: data.id,
                name: data.name,
                owner: data.site.siteName,
                siteId: data.site.id,
                brand: data.brand && data.brand.name ? data.brand.name :
                    data.brand ? data.brand : "-",
                type: data.type && data.type.name ? data.type.name :
                    data.pabxType ? data.pabxType :
                        data.dssType ? data.dssType : "-",
                serialNo: data.serialNo ? data.serialNo : "-",
                docAsset: data.fileUrlList, // []
                docName: data.fileNameList // []
            }
        } else {
            return null;
        }
    }
)
export const selectNameSelectedAssetData = createSelector(
    [selectSelectedAssetData],
    (data) => {
        if (data) {
            return data.name;
        } else {
            return null;
        }
    }
)

export const selectSelectedAsset = createSelector(
    [selectMaintenanceReducer],
    (state) => state.selectedAssetId
)

export const selectAllSite = createSelector(
    [selectMaintenanceReducer],
    (state) => {
        if (state.allSite && state.allSite.content.length > 0) {
            const content = state.allSite.content;
            let result = [];
            for (let i = 0; i < content.length; i++) {
                result.push(
                    {
                        value: content[i].id,
                        label: content[i].siteName
                    });
            }
            return result;
        } else {
            return null;
        }
    }
)

export const selectDataTableAsset = createSelector(
    [selectMaintenanceReducer],
    (state) => {
        if (state.allAsset && state.allAsset.content && state.allAsset.content.length) {
            const assets = state.allAsset;
            const content = assets.content;
            let data = []
            for (let i = 0; i < content.length; i++) {
                data.push({
                    id: content[i].id,
                    name: content[i].name,
                    brand: content[i].brand && content[i].brand.name ? content[i].brand.name :
                        content[i].brand ? content[i].brand : "-",
                    type: content[i].type && content[i].type.name ? content[i].type.name :
                        content[i].pabxType ? content[i].refAssetType.name :
                            content[i].dssType ? content[i].refAssetType.name : "-",
                    serialNo: content[i].serialNo ? content[i].serialNo : "-",
                    owner: content[i].site.siteName,
                    totalPages: assets.totalPages,

                })
            }
            return data;
        } else {
            return null;
        }
    }
)

export const selectAllMaintenanceData = createSelector(
    [selectMaintenanceReducer],
    (state) => state.allMaintenanceData
);


export const selectDataAsset = createSelector(
    [selectMaintenanceReducer],
    (state) => state.dataAsset
);

export const selectDataTable = createSelector(
    [selectMaintenanceReducer],
    (state) => {
        if (state.allMaintenanceData) {
            let dataArr = [];
            const aData = {...state.dataAsset};
            const mData = {...state.allMaintenanceData};
            const content = mData.content;

            for (let i = 0; i < content.length; i++) {
                let assetType = content[i].assetType.toLowerCase();
                const assetTemp = mappingAsset(assetType, aData, content, i);

                if (assetTemp) {
                    dataArr.push({
                        id: content[i].id,
                        name: assetTemp.name,
                        owner: content[i].siteDto.siteName,
                        serialNo: assetTemp.serialNo,
                        condition: content[i].condition,
                        mainStatus: content[i].status,
                        location: content[i].location,
                    });
                }
            }
            return {data: dataArr, totalPage: mData.totalPages};
        } else {
            return null;
        }
    }
);

export const selectDataReport = createSelector(
    [selectMaintenanceReducer],
    (state) => {
        if (state.allMaintenanceDataReport) {
            let dataArr = [];
            const aData = {...state.dataAsset};
            const mData = {...state.allMaintenanceDataReport};
            const content = mData.content;

            for (let i = 0; i < content.length; i++) {
                let assetType = content[i].assetType.toLowerCase();
                const assetTemp = mappingAsset(assetType, aData, content, i);

                if (assetTemp) {
                    dataArr.push({
                        id: content[i].id,
                        name: assetTemp.name,
                        owner: content[i].siteDto.siteName,
                        serialNo: assetTemp.serialNo,
                        condition: content[i].condition,
                        mainStatus: content[i].status,
                        location: content[i].location,
                    });
                }
            }
            return {data: dataArr, totalPage: mData.totalPages};
        } else {
            return null;
        }
    }
);

// =========================================================================
// MAINTENANCE SCHEDULE
// =========================================================================
export const selectMaintenanceScheduleData = createSelector(
    [selectMaintenanceReducer],
    (state) => {
        if (state.maintenanceSchedule && state.maintenanceSchedule.content.length > 0) {
            let data = [];
            const aData = {...state.dataAsset};
            const mData = {...state.maintenanceSchedule};
            const content = mData.content
            for (let i = 0; i < content.length; i++) {
                let assetType = content[i].assetType.toLowerCase();
                const assetTemp = mappingAsset(assetType, aData, content, i);

                if (assetTemp) {
                    const scheduleDate = new Date(content[i].scheduleDate);
                    const day = scheduleDate.getDate();
                    const month = MONTH_NAMES[scheduleDate.getMonth()];
                    const year = scheduleDate.getFullYear();
                    const date = `${day} ${month.toUpperCase()} ${year}`

                    // Scheduler Due Date
                    const dueDate = content[i].dueDate;
                    const covertedDueDate = dueDate.map(item => moment(item).format('YYYY-MM-DD'));

                    const currentDate = new Date();
                    if (currentDate.getMonth() === 11 && currentDate.getDate() > 25) {
                        scheduleDate.setFullYear(scheduleDate.getFullYear() + 1);
                    }
                    const one_day = 1000 * 60 * 60 * 24;
                    const dayLeft = Math.ceil((scheduleDate.getTime() - currentDate.getTime()) / (one_day));
                    const label = dayLeft === 0 ? 'today' : dayLeft < 0 ? 'day(s) ago' : 'day(s) left';
                    const timeLeft = `${dayLeft === 0 ? "" : Math.abs(dayLeft)} ${label}`;
                    data.push({
                        id: content[i].id,
                        name: assetTemp.name,
                        owner: assetTemp.site.siteName,
                        serialNo: assetTemp.serialNo,
                        date: date,
                        timeLeft,
                        type: content[i].preventive ? `Preventive (${content[i].preventive})` : `Corrective (${content[i].corrective})`,
                        status: content[i].status,
                        scheduleDate,
                        scheduleId: content[i].scheduleId,
                        dueDate: covertedDueDate,
                        dueDateStatus: content[i].dueDateStatus
                    })
                }
            }
            return {data, totalPage: mData.totalPages};
        } else {
            return null;
        }
    }
);

export const selectEvents = createSelector(
    [selectMaintenanceScheduleData],
    (data) => {
        if (data && data.data && data.data.length > 0) {
            let arr = [];
            for (let i = 0; i < data.data.length; i++) {
                if (data.data[i].type.includes("Corrective")) {
                    arr.push({
                        scheduleId: [],
                        title: data.data[i].name,
                        date: [moment(data.data[i].date, "DD MMM YYYY").format("YYYY-MM-DD")],
                        dueDateStatus: ["Waiting"],
                    })
                } else {
                    arr.push({
                        scheduleId: data.data[i].scheduleId,
                        title: data.data[i].name,
                        date: data.data[i].dueDate,
                        dueDateStatus: data.data[i].dueDateStatus,
                    })
                }
            }

            return arr;
        } else {
            return null;
        }
    }
)

export const selectSendToLogResponse = createSelector(
    [selectMaintenanceReducer],
    (state) => state.sendToLogResponse
);

// =========================================================================
// MAINTENANCE HISTORY
// =========================================================================

export const selectHistoryData = createSelector(
    [selectMaintenanceReducer],
    (state) => {
        if (state.historyData && state.historyData.content.length > 0) {
            let data = [];
            const aData = {...state.dataAsset};
            const mData = {...state.historyData};
            const content = mData.content
            for (let i = 0; i < content.length; i++) {
                let assetType = content[i].assetType.toLowerCase();
                const assetTemp = mappingAsset(assetType, aData, content, i);

                if (assetTemp) {
                    const historyDate = new Date(content[i].historyDate);
                    const day = historyDate.getDate();
                    const month = MONTH_NAMES[historyDate.getMonth()];
                    const year = historyDate.getFullYear();
                    const date = `${day} ${month.toUpperCase()} ${year}`

                    const currentDate = new Date();
                    if (currentDate.getMonth() === 11 && currentDate.getDate() > 25) {
                        historyDate.setFullYear(historyDate.getFullYear() + 1);
                    }
                    const one_day = 1000 * 60 * 60 * 24;
                    const timeLeft = `${Math.abs(Math.ceil((historyDate.getTime() - currentDate.getTime()) / (one_day)))} day(s) ago`;

                    data.push({
                        id: content[i].id,
                        name: assetTemp.name,
                        owner: assetTemp.site.siteName,
                        serialNo: assetTemp.serialNo,
                        date: date,
                        time: timeLeft,
                        condition: content[i].condition,
                    })
                }
            }

            return {data, totalPage: mData.totalPages};
        } else {
            return null;
        }
    }
);

export const selectHistoryDataReport = createSelector(
    [selectMaintenanceReducer],
    (state) => {
        if (state.historyDataReport && state.historyDataReport.content.length > 0) {
            let data = [];
            const aData = {...state.dataAsset};
            const mData = {...state.historyDataReport};
            const content = mData.content
            for (let i = 0; i < content.length; i++) {
                let assetType = content[i].assetType.toLowerCase();
                const assetTemp = mappingAsset(assetType, aData, content, i);

                if (assetTemp) {
                    const historyDate = new Date(content[i].historyDate);
                    const day = historyDate.getDate();
                    const month = MONTH_NAMES[historyDate.getMonth()];
                    const year = historyDate.getFullYear();
                    const date = `${day} ${month.toUpperCase()} ${year}`

                    const currentDate = new Date();
                    if (currentDate.getMonth() === 11 && currentDate.getDate() > 25) {
                        historyDate.setFullYear(historyDate.getFullYear() + 1);
                    }
                    const one_day = 1000 * 60 * 60 * 24;
                    const timeLeft = `${Math.abs(Math.ceil((historyDate.getTime() - currentDate.getTime()) / (one_day)))} day(s) ago`;

                    data.push({
                        id: content[i].id,
                        name: assetTemp.name,
                        owner: assetTemp.site.siteName,
                        serialNo: assetTemp.serialNo,
                        date: date,
                        time: timeLeft,
                        condition: content[i].condition,
                    })
                }
            }

            return {data, totalPage: mData.totalPages};
        } else {
            return null;
        }
    }
);

export const selectProcess = createSelector(
    [selectMaintenanceReducer],
    (state) => state.processType
)

export const selectRemoveStatus = createSelector(
    [selectMaintenanceReducer],
    (state) => state.removeStatus
);
export const selectCreateStatus = createSelector(
    [selectMaintenanceReducer],
    (state) => state.createStatus
);
export const selectErrorMessage = createSelector(
    [selectMaintenanceReducer],
    (state) => state.errorMessage
);
export const selectUpdateStatus = createSelector(
    [selectMaintenanceReducer],
    (state) => state.updateStatus
);
export const selectSendToLogStatus = createSelector(
    [selectMaintenanceReducer],
    (state) => state.sendToLogStatus
);
export const selectFinishModifyDatasource = createSelector(
    [selectMaintenanceReducer],
    (state) => state.finishModifyDatasource
);

