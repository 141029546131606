import { ACTION_GET, BASE_URL } from "./core";

export const GET_ALL_ROLE = () => {
  const url = `${BASE_URL}/role`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_ROLE_BY_ID = (id) => {
  const url = `${BASE_URL}/role/${id}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


