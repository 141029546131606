import {useDispatch, useSelector} from "react-redux";
import {isAuthed} from "../../store/user/user.selector";
import {useEffect, useState} from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import {checkUserSession} from "../../store/user/user.action";
import {useNavigate} from "react-router-dom";
import {handleLoading} from "../../store/global/global.action";
import {handleGetTowerCivilFromServer} from "../../store/tower/tower.action";

const Home = ({children}) => {
    const [isSidebarOpened, setIsSidebarOpened] = useState(false);

    const authed = useSelector(isAuthed);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(handleGetTowerCivilFromServer());
        dispatch(checkUserSession(navigate));

        return () => {
            dispatch(handleLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {authed ? (
                <div>
                    <Sidebar isSidebarOpened={isSidebarOpened} onCollapse={setIsSidebarOpened} />
                    <div>
                        <Navbar isSidebarOpened={isSidebarOpened} />
                        <div className="ml-[100px]">{children}</div>
                    </div>
                </div>
            ) : (
                <div>{children}</div>
            )}
        </>
    );
}

export default Home;