import { createSelector } from 'reselect';

export const selectUserReducer = (state) => state.userReducer;

export const selectCurrentUser = createSelector(
    [selectUserReducer],
    (state) => state.currentUser
)
export const selectStatusUpdateUser = createSelector(
    [selectUserReducer],
    (state) => state.updatedUser
)
export const selectAllUserData = createSelector(
    [selectUserReducer],
    (state) => {
        if (state.allUserData && state.allUserData.content) {
            return state.allUserData.content
        } else {
            return null;
        }
    }
)
export const selectTotalPage = createSelector(
    [selectUserReducer],
    (state) => {
        if (state.allUserData && state.allUserData.totalPages) {
            return state.allUserData.totalPages
        } else {
            return null;
        }
    }
)
export const isAuthed = createSelector(
    [selectUserReducer],
    (state) => state.authed
)
export const isLoading = createSelector(
    [selectUserReducer],
    (state) => state.isLoading
)
export const selectCreatedUser = createSelector(
    [selectUserReducer],
    (state) => state.createdUser
)
export const isError = createSelector(
    [selectUserReducer],
    (state) => state.error.isError
)
export const getErrorMessage = createSelector(
    [selectUserReducer],
    (state) => state.error.errorMessage
)
export const selectRemoveUserStatus = createSelector(
    [selectUserReducer],
    (state) => state.removeUserStatus
)
export const selectAUserData = createSelector(
    [selectUserReducer],
    (state) => {
        if (state.aUserData) {
            return  state.aUserData
        } else {
            return null;
        }
    }
)