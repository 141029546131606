import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { report } from "../../../utils/report/report";

import LambangTNI from "../../../assets/png/Lambang_TNI_AU.png";

const ReportMultipleMaintenanceHistory = ({ data }) => {
    const [profile, setProfile] = useState([]);

    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem("profile"));
        setProfile(userProfile);
    }, []);

    const handlePdfGenerate = () => {
        // Format
        const doc = new jsPDF("potrait", "px", "a4", "false");

        // Template
        report.templateAsset(doc, 1, profile, LambangTNI, "Maintenance History");

        autoTable(doc, {
            theme: "grid",
            styles: {
                textColor: [0, 0, 0],
                fontSize: 7,
                overflow: "linebreak",
                cellWidth: 62,
            },
            margin: { left: 30, bottom: 60 },
            startY: 150,
            didParseCell(data) {
                if (data.row.index % 2 !== 0) {
                    data.cell.styles.textColor = [0, 0, 0];
                    data.cell.styles.fillColor = "#D3D3D3";
                }
                if (data.column.index === 0) {
                    data.cell.styles.cellWidth = 15;
                }
                if (data.column.index === 1) {
                    data.cell.styles.cellPadding = {top: 3, right: 13, bottom: 3, left: 3};
                }
                if (data.column.index === 3) {
                    data.cell.styles.cellWidth = 50;
                }
                if (data.column.index === 4) {
                    data.cell.styles.cellWidth = 70;
                }
                data.settings.margin.top = 150;
            },
            didDrawPage: (data) => {
                if(data.pageCount >= 2) {
                    report.templateAsset(doc, data.pageCount, profile, LambangTNI, "Maintenance History");
                }
            },
            tableWidth: 385,
            head: [
                [
                    "No",
                    "Name",
                    "Owner",
                    "Serial Number",
                    "Date Maintenance",
                    "Time Maintenance",
                    "Asset Condition",
                ],
            ],
            body: data.map((asset, index) => {
                return [
                    index + 1,
                    asset?.name,
                    asset?.owner,
                    asset?.serialNo,
                    asset?.date,
                    asset?.time,
                    asset?.condition.charAt(0).toUpperCase() + asset?.condition.slice(1),
                ];
            }),
        });

        // Save
        report.savePdf(doc, "Maintenance History");
    };

    return (
        <button
            type="button"
            className="text-white text-lg mr-10"
            onClick={handlePdfGenerate}
        >
            Create Report
        </button>
    );
};

export default ReportMultipleMaintenanceHistory;
