import {RADIO_ACTION_TYPES} from "./radio.types";
import {STATUS_TYPE} from "../../utils/constant/constants";
import {addIntoArray} from "./radio.utils";

const INITIAL_STATE = {
    status: null,  // {}
    radios: null, // {radio:{content:[]}}
    detailRadio: null, // {}
    categories: null,  // []
    brands: null,  // []
    types: null, // []
    sites: null, // {content:[]}
    statusArray: [
        {
            key: "SERVICEABLE",
            name: "SERVICEABLE",
        },
        {
            key: "SPARE",
            name: "SPARE",
        },
        {
            key: "UNSERVICEABLE",
            name: "UNSERVICEABLE",
        },
    ],

    column: [
        {heading: "Name", value: "name"},
        {heading: "Brand", value: "brand.name"},
        {heading: "Type", value: "type.name"},
        {heading: "Serial Number", value: "serialNo"},
        {heading: "Owner", value: "site.siteName"},
        {heading: "Status", value: "status"},
    ],
    uploadRadioStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedImages: [],
    uploadImageStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedFiles: [],
    // uploadedFilesName: [],
    uploadFileStatus: STATUS_TYPE.NO_STATEMENT,
    isLoading: false,
    removeStatus: STATUS_TYPE.NO_STATEMENT,
    updateStatus: STATUS_TYPE.NO_STATEMENT,
    images: [],
}

export const radioReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case RADIO_ACTION_TYPES.GET_ALL_RADIO:
            return {...state, radios: payload};
        case RADIO_ACTION_TYPES.SEARCH:
            return {...state, radios: payload};
        case RADIO_ACTION_TYPES.SEARCH_REPORT:
            return {...state, radiosReport: payload};
        case RADIO_ACTION_TYPES.DETAIL_RADIO:
            return {...state, detailRadio: payload};
        case RADIO_ACTION_TYPES.CLEAR_DETAIL_RADIO:
            return {...state, detailRadio: null};
        case RADIO_ACTION_TYPES.GET_CATEGORY:
            return {...state, categories: payload};
        case RADIO_ACTION_TYPES.GET_TYPE:
            return {...state, types: payload};
        case RADIO_ACTION_TYPES.GET_BRAND:
            return {...state, brands: payload};
        case RADIO_ACTION_TYPES.GET_SITE:
            return {...state, sites: payload};
        case RADIO_ACTION_TYPES.CLEAR_DATA:
            return INITIAL_STATE;
        case RADIO_ACTION_TYPES.UPLOAD_RADIO_STATUS:
            return {...state, uploadRadioStatus: payload, uploadedFiles: [], uploadedImages: []}
        case RADIO_ACTION_TYPES.UPLOAD_IMAGE:
            return {...state, uploadedImages: payload}
        case RADIO_ACTION_TYPES.UPLOAD_IMAGE_STATUS:
            return {...state, uploadImageStatus: payload}
        case RADIO_ACTION_TYPES.UPLOAD_FILE:
            const newArray = addIntoArray(state.uploadedFiles, payload);
            return {...state, uploadedFiles: newArray}
        case RADIO_ACTION_TYPES.UPLOAD_FILE_STATUS:
            return {...state, uploadFileStatus: payload}
        case RADIO_ACTION_TYPES.REMOVED:
            return {...state, removeStatus: payload}
        case RADIO_ACTION_TYPES.UPDATED:
            return {...state, updateStatus: payload}
        default:
            return state;
    }
}