import {createSelector} from "reselect";

const selectGlobalReducer = (state) => state.globalReducer;

export const selectSyncStatus = createSelector(
    [selectGlobalReducer],
    (state) => state.syncStatus
)
export const selectIsLoading = createSelector(
    [selectGlobalReducer],
    (state) => state.isLoading
)
export const selectIsLoadingSync = createSelector(
    [selectGlobalReducer],
    (state) => state.isLoadingSync
)
export const selectAssetToRemove = createSelector(
    [selectGlobalReducer],
    (state) => state.assetToRemove
)
export const selectAssetToEdit = createSelector(
    [selectGlobalReducer],
    (state) => state.assetToEdit
)
export const selectAssetToCheck = createSelector(
    [selectGlobalReducer],
    (state) => state.checkAsset
)
export const selectCurrentItem = createSelector(
    [selectGlobalReducer],
    (state) => state.item
)
export const selectDetailAsset = createSelector(
    [selectGlobalReducer],
    (state) => state.detailAsset
)
export const selecNotifyNotConnectedToBackend = createSelector(
    [selectGlobalReducer],
    (state) => state.notifyNotConnectedToBackend
)