import {persistStore, persistReducer} from 'redux-persist';
import {applyMiddleware, compose, legacy_createStore as createStore} from "redux";
import storage from 'redux-persist/lib/storage'
import logger from "redux-logger";
import thunk from "redux-thunk";

import {rootReducer} from './root-reducer';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['userReducer', 'areaReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWares = process.env.REACT_APP_NODE_ENV !== 'production' ?
    [thunk, logger] :
    [thunk];


const composeEnhancer =
    (process.env.REACT_APP_NODE_ENV !== 'production' &&
        window &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const composedEnhancers = composeEnhancer(applyMiddleware(...middleWares));

export const store = createStore(
    persistedReducer,
    undefined,
    composedEnhancers
);

export const persistor = persistStore(store);