import React from "react";

const CopyIcon = (props) => {
  return (

    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M10.8999 13H1.8999C1.76729 13 1.64012 12.9473 1.54635 12.8536C1.45258 12.7598 1.3999 12.6326 1.3999 12.5V1.5C1.3999 1.36739 1.45258 1.24021 1.54635 1.14645C1.64012 1.05268 1.76729 1 1.8999 1H7.8999L11.3999 4.5V12.5C11.3999 12.6326 11.3472 12.7598 11.2535 12.8536C11.1597 12.9473 11.0325 13 10.8999 13Z" stroke="#040F0F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default CopyIcon;
