import * as React from "react";

const DistributionIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1927 1927"
    {...props}
  >
    <g stroke="currentColor">
      <path
        fill="none"
        strokeWidth={13.338}
        d="m1507 366-328 360-503-3-310 435v462h1142z"
      />
      <path
        fill="currentColor"
        strokeWidth={13.338}
        d="M441 1620h213l18-783-231 318z"
      />
      <path fill="none" strokeWidth={20.007} d="M1561 715V307h-402" />
      <path
        fill="none"
        strokeWidth={16.672}
        d="m366 1158 310-435 503 3 382-419"
      />
      <path
        fill="currentColor"
        strokeWidth={1.334}
        d="M1445 546v1074h-204V765zM748 819h377v801H748z"
      />
      <path
        fill="currentColor"
        strokeWidth={1.334}
        d="M209 1h1509c114 0 208 94 208 208v1509c0 114-94 208-208 208H209c-114 0-208-94-208-208V209C1 95 95 1 209 1zm52 66h1405c107 0 194 87 194 194v1405c0 107-87 194-194 194H261c-107 0-194-87-194-194V261c0-107 87-194 194-194z"
      />
    </g>
  </svg>
);

export default DistributionIcon;
