export const TOWER_ACTION_TYPES = {
    FETCH_TOWER_DISTRIBUTION : "tower/FETCH_TOWER_DISTRIBUTION",
    TOWER_DISTRIBUTION_ACTIVE : "tower/TOWER_DISTRIBUTION_ACTIVE",
    TOWER_DISTRIBUTION_INACTIVE : "tower/TOWER_DISTRIBUTION_INACTIVE",
    TOWER_MILITER_ACTIVE : "tower/TOWER_MILITER_ACTIVE",
    TOWER_MILITER_INACTIVE : "tower/TOWER_MILITER_INACTIVE",
    FETCH_TOWER_MILITER : "tower/FETCH_TOWER_MILITER",
    REMOVE_ALL : "tower/REMOVE_ALL",
    GET_STATUS : 'tower/GET_STATUS',
    GET_ALL_TOWER : 'tower/GET_ALL_TOWER',
    GET_CATEGORY : 'tower/GET_CATEGORY',
    GET_BRAND : 'tower/GET_BRAND',
    GET_TYPE : 'tower/GET_TYPE',
    GET_SITE : 'tower/GET_SITE',
    CLEAR_DATA : 'tower/CLEAR_DATA',
    CHANGE_PAGE : 'tower/CHANGE_PAGE',
    UPLOAD_TOWER_STATUS : 'tower/UPLOAD_TOWER_STATUS',
    UPLOAD_IMAGE_STATUS : 'tower/UPLOAD_IMAGE_STATUS',
    UPLOAD_FILE_STATUS : 'tower/UPLOAD_FILE_STATUS',
    UPLOAD_IMAGE : 'tower/UPLOAD_IMAGE',
    UPLOAD_FILE : 'tower/UPLOAD_FILE',
    IS_LOADING : 'tower/IS_LOADING',
    REMOVED : 'tower/REMOVED',
    UPDATED : 'tower/UPDATED',
    SEARCH : 'tower/SEARCH',
    SEARCH_REPORT : 'tower/SEARCH_REPORT',
    DETAIL_TOWER : 'tower/DETAIL_TOWER',
    CLEAR_DETAIL_TOWER : 'tower/CLEAR_DETAIL_TOWER',
    SET_TOGGLE_WHITE_TOWER : 'tower/SET_TOGGLE_WHITE_TOWER',
    SET_TOGGLE_RED_TOWER : 'tower/SET_TOGGLE_RED_TOWER',
}
