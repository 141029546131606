import {
    ACTION_GET,
    ACTION_POST,
    ACTION_PUT,
    ACTION_DELETE,
    BASE_URL,
} from "./core";

export const GET_ALL_TOWER = () => {
    let url = `${BASE_URL}/tower`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const GET_ALL_TOWER_SEARCH = (page, pageSize, search, siteId) => {
    let url = `${BASE_URL}/tower/search?q=${search.toLowerCase()}&type=name&page=${page}&size=${pageSize}&siteId=${siteId}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const GET_TOWER_BY_ID = (id) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/tower/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const GET_TOWER_BY_SITE = (id) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/tower/site/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const CREATE_TOWER = (param) => {
    const url = `${BASE_URL}/tower`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};


export const UPDATE_TOWER = (id, param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/tower/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const DELETE_TOWER_BY_ID = (param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/tower/${param}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const SEARCH_TOWER = (q, type, page, size, siteId = 0) => {
    const url = `${BASE_URL}/tower/search?q=${q.toLowerCase()}&type=${type}&page=${page}&size=${size}&site=${siteId}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
