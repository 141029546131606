import * as React from "react";

const ArrowUp = (props) => (
  <svg
    width={14}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.483 7.619a1.032 1.032 0 0 1 .07-1.381L6.397.396a1.029 1.029 0 0 1 1.46 0l5.842 5.842a1.032 1.032 0 1 1-1.46 1.46L7.126 2.584 2.013 7.697a1.031 1.031 0 0 1-1.381.071l-.078-.07-.071-.08Z"
      fill="#fff"
    />
  </svg>
);

export default ArrowUp;
