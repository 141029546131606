export const getLatLong = (cartesian, ellipsoid, Cesium) => {
    const cartographic = ellipsoid.cartesianToCartographic(cartesian);
    const longitude = parseFloat(
        Cesium.Math.toDegrees(cartographic.longitude).toFixed(6)
    );
    const latitude = parseFloat(
        Cesium.Math.toDegrees(cartographic.latitude).toFixed(6)
    );
    const height = parseFloat(
        Cesium.Math.toDegrees(cartographic.latitude).toFixed(6)
    );
    return {longitude, latitude, height}
}

export const ddToDmsWithDirection = (lat, lon) => {
    const latitude = convertCalculationToDmsWithDirection(Number(lat), false);
    const longitude = convertCalculationToDmsWithDirection(Number(lon), true);
    return {latitude, longitude}
}

export const ddToDms = (lat, lon) => {
    const latitude = convertCalculationToDms(Number(lat));
    const longitude = convertCalculationToDms(Number(lon));
    return {latitude, longitude}
}

export const ddToDdmWithDirection = (lat, lon) => {
    const latitude = convertCalculationToDdmWithDirection(Number(lat), false);
    const longitude = convertCalculationToDdmWithDirection(Number(lon), true);
    return {latitude, longitude}
}
export const ddToDdm = (lat, lon) => {
    const latitude = convertCalculationToDdm(Number(lat), false);
    const longitude = convertCalculationToDdm(Number(lon), true);
    return {latitude, longitude}
}

export const dmsToDd = (x, y) => {
    const latitude = parseDms(`${x}`);
    const longitude = parseDms(`${y}`);
    return {latitude, longitude}
}

export const ddmToDd = (x, y) => {
    const latitude = parseDdm(`${x}`);
    const longitude = parseDdm(`${y}`);
    return {latitude, longitude}
}

export const dmsToDmm = (x, y) => {
    const {latitude, longitude} = dmsToDd(`${x}`, `${y}`);
    const dmm = ddToDdmWithDirection(latitude, longitude);
    return {latitude: dmm.latitude, longitude: dmm.longitude}
};

export const ddmToDms = (x, y) => {
    const {latitude, longitude} = ddmToDd(`${x}`, `${y}`);
    const dms = ddToDmsWithDirection(latitude, longitude);
    return {latitude: dms.latitude, longitude: dms.longitude}
};

const convertCalculationToDdmWithDirection = (degree, isLongitude) => {
    const dir = degree < 0 ? (isLongitude ? "W" : "S") : isLongitude ? "E" : "N"
    const deg = 0 | (degree < 0 ? (degree = -degree) : degree)
    const min = ((degree - Math.floor(degree)) * 60.0).toFixed(4);
    return `${dir} ${deg}° ${min}'`
}

const convertCalculationToDdm = (degree) => {
    return [0 | degree, '° ', ((degree - Math.floor(degree)) * 60.0).toFixed(4), "' "].join('');
};

const convertCalculationToDmsWithDirection = (degree, isLongitude) => {
    const dir = degree < 0 ? (isLongitude ? "W" : "S") : isLongitude ? "E" : "N"
    const deg = 0 | (degree < 0 ? (degree = -degree) : degree)
    const min = 0 | (((degree += 1e-9) % 1) * 60)
    const sec = (0 | (((degree * 60) % 1) * 6000)) / 100
    return `${dir} ${deg}° ${min}' ${sec}"`
}

const convertCalculationToDms = (degree) => {
    return [0 | degree, '° ', 0 | (degree = (degree < 0 ? -degree : degree) + 1e-4) % 1 * 60, "' ", 0 | degree * 60 % 1 * 60, '"'].join('');
};

const parseDms = (coordinate) => { // exp: S 6 51' 27.28" | -6° 51' 27"
    let {anyDirection, parts, isNegative} = checkInputCoordinate(coordinate, true);
    if (isNegative) {
        return convertDmsToDd(Number(parts[1]), Number(parts[2]), Number(parts[3]), anyDirection, parts[0]);
    } else {
        return convertDmsToDd(Number(parts[0]), Number(parts[1]), Number(parts[2]), anyDirection);
    }
}

const convertDmsToDd = (degrees, minutes, seconds, anyDirection, direction = "") => {
    let dd;
    if (anyDirection) {
        dd = degrees + minutes / 60 + seconds / (60 * 60);
        if (direction.toLowerCase() === "s" || direction.toLowerCase() === "w") {
            dd = dd * -1;
        }
    } else {
        if (direction !== "") {
            dd = degrees + minutes / 60 + seconds / (60 * 60);
        } else {
            dd = (Math.abs(degrees) + minutes / 60 + seconds / (60 * 60)) * -1;
        }
    }
    return Number(dd.toFixed(5));
}

function checkInputCoordinate(coordinate, isFromDms) {
    const anyDirection = !!(coordinate.includes("W") || coordinate.includes("S") || coordinate.includes("E") || coordinate.includes("N"));
    // let parts = coordinate.split(/[^\d\w]+/);
    let parts = coordinate.split(/\W+/);
    let isNegative = false;
    if (isFromDms) {
        if (parts.length > 3) {
            isNegative = true;
        }
    } else {
        if (parts.length > 4) {
            isNegative = true;
        }
    }
    return {anyDirection, parts, isNegative};
}

const parseDdm = (coordinate) => { // -6° 8.5452' | S 6° 51.4548'
    let {anyDirection, parts, isNegative} = checkInputCoordinate(coordinate, false);
    if (isNegative) {
        return convertDdmToDd(Number(parts[1]), Number(`${parts[2]}.${parts[3]}`), anyDirection, parts[0]);
    } else {
        return convertDdmToDd(Number(parts[0]), Number(`${parts[1]}.${parts[2]}`), anyDirection);
    }
}

const convertDdmToDd = (degrees, minutes, anyDirection, direction = "") => {
    let dd;
    if (anyDirection) {
        dd = degrees + minutes / 60;
        if (direction.toLowerCase() === "s" || direction.toLowerCase() === "w") {
            dd = dd * -1;
        }
    } else {
        if (direction !== "") {
            dd = degrees + minutes / 60;
        } else {
            dd = (Math.abs(degrees) + minutes / 60) * -1;
        }
    }
    return Number(dd.toFixed(5));
}
