import * as React from "react";

const FrequencyAllocationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1723 1723"
    {...props}
  >
    <ellipse
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={14.911}
      cx={1177}
      cy={521}
      rx={22}
      ry={28}
    />
    <ellipse
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={14.911}
      cx={1294}
      cy={572}
      rx={22}
      ry={28}
    />
    <path
      fill="none"
      stroke="#332C2B"
      strokeWidth={14.911}
      d="m1241 446-64 75 191 84-171 10z"
    />
    <path
      fill="none"
      stroke="#332C2B"
      strokeWidth={14.911}
      d="m1197 615 97-43-53-126"
    />
    <ellipse
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={14.911}
      cx={1368}
      cy={605}
      rx={22}
      ry={28}
    />
    <ellipse
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={14.911}
      cx={1197}
      cy={615}
      rx={22}
      ry={28}
    />
    <ellipse
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={14.911}
      cx={1241}
      cy={446}
      rx={17}
      ry={21}
    />
    <path
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={1.193}
      d="M215 324h1293c15 0 27 12 27 26v769c0 14-12 26-27 26H215c-15 0-26-12-26-26V350c0-14 11-26 26-26zm61 39h1171c14 0 24 10 24 24v695c0 14-10 24-24 24H276c-13 0-24-10-24-24V387c0-14 11-24 24-24z"
    />
    <path
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={1.193}
      d="M711 392h708c11 0 20 7 20 15v416c0 8-9 15-20 15H711c-11 0-19-7-19-15V407c0-8 8-15 19-15zm20 13h669c10 0 18 6 18 13v394c0 7-8 13-18 13H731c-11 0-19-6-19-13V418c0-7 8-13 19-13z"
    />
    <path
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={1.193}
      transform="matrix(0 -.4618 1.67053 0 833.513 813.619)"
      d="M0 0h149v343H0z"
    />
    <path
      fill="none"
      stroke="#332C2B"
      strokeWidth={14.911}
      d="M1018 436v117h117c0-65-52-117-117-117zM1018 680V563h117c0 64-52 117-117 117z"
    />
    <path
      fill="currentColor"
      d="M841 600h43v101h-43zM899 549h43v152h-43zM957 499h44v202h-44zM782 650h44v51h-44z"
    />
    <circle
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={1.193}
      cx={436}
      cy={553}
      r={117}
    />
    <circle
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={1.193}
      cx={1025}
      cy={983}
      r={117}
    />
    <path
      fill="none"
      stroke="#332C2B"
      strokeWidth={17.893}
      d="M436 553v419h580"
    />
    <path
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={1.193}
      d="M496 1308v91h761v-91h-233c-21-62-79-106-147-106-69 0-127 44-148 106H496z"
    />
    <path
      fill="currentColor"
      stroke="#332C2B"
      strokeWidth={1.193}
      d="M187 1h1349c103 0 187 84 187 186v1349c0 103-84 187-187 187H187c-102 0-186-84-186-187V187C1 85 85 1 187 1zm46 59h1257c96 0 174 78 174 173v1257c0 96-78 174-174 174H233c-95 0-173-78-173-174V233c0-95 78-173 173-173z"
    />
  </svg>
);

export default FrequencyAllocationIcon;
