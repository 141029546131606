import {ACTION_GET, ACTION_PUT, ACTION_POST, ACTION_DELETE, BASE_URL} from "./core";

export const SEARCH_FREQUENCY_ALLOCATION = (q, type, page, size, siteId = 0, cellSize = 10, id = "") => {
    const url = `${BASE_URL}/frequencyAllocation/search?id=${id}&page=${page}&size=${size}&q=${q}&type=${type}&siteId=${siteId}&cellSize=10&sortBy=frequency&sortDir=desc`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const CREATE_FREQUENCY_ALLOCATION = (param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/frequencyAllocation`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const UPDATE_FREQUENCY_ALLOCATION = (id, param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/frequencyAllocation/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const DELETE_FREQUENCY_ALLOCATION_BY_ID = (param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/frequencyAllocation/${param}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};