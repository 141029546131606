import {combineReducers} from "redux";

import {rulerReducer} from './ruler/ruler.reducer'
import {userReducer} from "./user/user.reducer";
import {dashboardReducer} from "./dashboard/dashboard.reducer";
import {areaReducer} from "./area/area.reducer";
import {towerReducer} from "./tower/tower.reducer";
import {towerServerReducer} from "./towerServer/towerServer.reducer";
import {radioReducer} from "./radio/radio.reducer";
import {antennaReducer} from "./antenna/antenna.reducer";
import {pabxReducer} from "./pabx/pabx.reducer";
import {civilReducer} from "./towerCivil/civil.reducer";
import {electronicReducer} from "./electronic/electronic.reducer";
import {dssReducer} from "./dss/dss.reducer";
import {assetReducer} from "./asset/asset.reducer";
import {globalReducer} from "./global/global.reducer";
import {opReducer} from "./op/op.reducer";
import {maintenanceReducer} from "./maintenance/maintenance.reducer";
import {templateReducer} from "./template/template.reducer";
import {frequencyReducer} from "./frequency/frequency.reducer";
import {frequencyAllocationReducer} from "./frequencyAllocation/frequencyallocation.reducer";
import {mannetReducer} from "./mannet/mannet.reducer";
import {distributionReducer} from "./distribution/distribution.reducer";
import {foReducer} from "./fo/fo.reducer";
import {roleReducer} from "./role/role.reducer";
import {adminReducer} from "./admin/admin.reducer";
import {coverageRulerReducer} from "./coverageRuler/coverageRuler.reducer";
import {gpsReducer} from "./gps/gps.reducer";

export const rootReducer = combineReducers({
    gpsReducer,
    roleReducer,
    adminReducer,
    userReducer,
    dashboardReducer,
    rulerReducer,
    areaReducer,
    towerReducer,
    towerServerReducer,
    radioReducer,
    antennaReducer,
    pabxReducer,
    dssReducer,
    assetReducer,
    globalReducer,
    opReducer,
    maintenanceReducer,
    templateReducer,
    frequencyReducer,
    frequencyAllocationReducer,
    civilReducer,
    electronicReducer,
    mannetReducer,
    distributionReducer,
    foReducer,
    coverageRulerReducer,
});