import {
  ACTION_GET,
  ACTION_POST,
  ACTION_PUT,
  ACTION_DELETE,
  BASE_URL,
} from "./core";
export const GET_ALL_DSS = (page, pageSize, search, siteId) => {
  let url = `${BASE_URL}/dss/search?q=${search.toLowerCase()}&type=name&page=${page}&size=${pageSize}&siteId=${siteId}`;
  // let url = `${BASE_URL}/dss`;
  // if (search !== "" && search !== null) {
  //   url = url + `/search?${search}` + `&page=${page}&size=${pageSize}`;
  // } else {
  //   url = url + `?page=${page}&size=${pageSize}`;
  // }
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const GET_DSS_BY_ID = (id) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/dss/${id}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_DSS_BY_SITE = (id) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/dss/site/${id}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_DSS = (param) => {
  const url = `${BASE_URL}/dss`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_DSS = (id,param) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/dss/${id}`;
  return new Promise((resolve, reject) => {
    ACTION_PUT(url, param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DELETE_DSS_BY_ID = (param) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/dss/${param}`;
  return new Promise((resolve, reject) => {
    ACTION_DELETE(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const SEARCH_DSS = (q, type, page, size, siteId = 0) => {
  const url = `${BASE_URL}/dss/search?q=${q.toLowerCase()}&type=${type}&page=${page}&size=${size}&site=${siteId}`;
  return new Promise((resolve, reject) => {
      ACTION_GET(url)
          .then((result) => {
              resolve(result);
          })
          .catch((error) => {
              reject(error);
          });
  });
};
