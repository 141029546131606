import * as React from "react";

const ArrowDown = (props) => (
  <svg
    width={14}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.517.381c.33.406.307 1.003-.07 1.381L7.603 7.604a1.029 1.029 0 0 1-1.46 0L.302 1.762a1.032 1.032 0 1 1 1.46-1.46l5.112 5.113L11.987.303a1.031 1.031 0 0 1 1.381-.071l.078.07.071.08Z"
      fill="#fff"
    />
  </svg>
);

export default ArrowDown;
