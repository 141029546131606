export const COVERAGE_RULER_ACTION_TYPES = {
    RESET: 'coverageRuler/RESET',
    HANDLE_RULER_TOGGLE: 'coverageRuler/HANDLE_RULER_TOGGLE',
    ENTITY_ID_ARRAY: 'coverageRuler/ENTITY_ID_ARRAY',
    CLEAR_ENTITY_ID_ARRAY: 'coverageRuler/CLEAR_ENTITY_ID_ARRAY',
    ENTITY_NAME: 'coverageRuler/ENTITY_NAME',
    BEARING: 'coverageRuler/BEARING',
    DISTANCE: 'coverageRuler/DISTANCE',
    REMOVE_LAST_ARRAY: 'coverageRuler/REMOVE_LAST_ARRAY',
};
