import {GET_AREA_ARCHIEVE} from "../../services/archieve";
import {GET_ALL_NETWORKS, PUT_SITE} from "../../services/analyze";
import {createAction} from "../../utils/reducer/reducerUtil";
import {DASHBOARD_ACTION_TYPES} from "./dashboard.types";
import {
    addOption,
    createEntityAndDataSource,
    createGoogleMapsImageryProvider,
    depthTestAgainstTerrain,
    flyToIndnesia,
    labelPosition,
    registerOSMOfflineProvider,
    removeDoubleClickEvent,
    removeEntityAndDataSource,
    removeImageryProvider,
    setHomeView,
    showAllCoverage,
    showAllLabel,
    showCoverageById
} from "../../utils/cesium/cesiumUtils";
import {Cartesian3, Cartographic, defined, ScreenSpaceEventHandler, ScreenSpaceEventType} from "cesium";
import {GET_ALL_SITE} from "../../services/site";


export const registerViewer = (viewer) => (dispatch) => {
    removeDoubleClickEvent(viewer);
    depthTestAgainstTerrain(viewer);
    addOption(viewer);
    setHomeView(viewer);
    registerOSMOfflineProvider(viewer);
    createGoogleMapsImageryProvider(viewer);
    removeImageryProvider(viewer);
    dispatch(createAction(DASHBOARD_ACTION_TYPES.REGISTER_VIEWER, viewer));
}

export const handleSave3DTileInstance = (activate, primitive) => dispatch => {
    if (activate) {
        dispatch(createAction(DASHBOARD_ACTION_TYPES.PRIMITIVE, primitive));
    } else {
        dispatch(createAction(DASHBOARD_ACTION_TYPES.PRIMITIVE, null));
    }
}
export const handleTickAllCoverage = (resultList, ticked) => (dispatch) => {
    const changeAllCoverage = showAllCoverage(resultList, ticked);
    dispatch(createAction(DASHBOARD_ACTION_TYPES.SHOW_ALL_COVERAGE, {changeAllCoverage, ticked}))
}
export const handleTickShowCoverageById = (resultList, ticked) => (dispatch) => {
    const changeCoverageById = showCoverageById(resultList, ticked);
    const {showCoverage} = ticked
    dispatch(createAction(DASHBOARD_ACTION_TYPES.SHOW_COVERAGE_BY_ID, {changeCoverageById, showCoverage}));
}

export const handleTickAllLabel = (resultList, istTicked) => (dispatch) => {
    const changeAllLabel = showAllLabel(resultList, istTicked);
    dispatch(createAction(DASHBOARD_ACTION_TYPES.SHOW_ALL_LABEL, {changeAllLabel, istTicked}))
}

export const fetchAvailableNetwork = () => async (dispatch) => {
    const getNetworks = await GET_ALL_NETWORKS();
    dispatch(createAction(DASHBOARD_ACTION_TYPES.PUT_NETWORK, getNetworks))
}

export const handleFetchAllSite = () => dispatch => {
    GET_ALL_SITE()
        .then((res) => {
            dispatch(createAction(DASHBOARD_ACTION_TYPES.FETCH_ALL_SITE, res));
        })
        .catch(() => {
        });
}

export const clearViewer = (viewer) => {
    removeEntityAndDataSource(viewer);
}

export const fetchResultFromArchive = (params, viewer, isTerrainActive) => (dispatch) => {
    GET_AREA_ARCHIEVE(params)
        .then(res => {
            removeEntityAndDataSource(viewer);
            createEntityAndDataSource(res, viewer, isTerrainActive);
            dispatch(createAction(DASHBOARD_ACTION_TYPES.PUT_RESULT, {data: res}));
        })
        .catch(() => {
        });
}

export const registerEventListener = (eventListener) => (dispatch) => {
    dispatch(createAction(DASHBOARD_ACTION_TYPES.REGISTER_EVENT_LISTENER, eventListener))
}

export const handleToggleFilter = () => (dispatch) => {
    dispatch(createAction(DASHBOARD_ACTION_TYPES.HANDLE_SHOW_HIDE_FILTER_TOOLBAR))
}
export const handleCloseToggleFilter = () => {
    return createAction(DASHBOARD_ACTION_TYPES.HANDLE_CLOSE_FILTER_TOOLBAR)
}

export const flyToIndonesia = (viewer, is2D = true) => {
    flyToIndnesia(viewer, is2D);
}

export const showDetailEntity = (entity) => (dispatch) => {
    dispatch(createAction(DASHBOARD_ACTION_TYPES.HANDLE_SHOW_DETAIL, entity));
}

export const saveEntity = (id) => async (dispatch) => {
    const res = await PUT_SITE(id);
    if (res.id) {
        dispatch(createAction(DASHBOARD_ACTION_TYPES.SAVE_ENTITY, res))
    }
}

export const handleMouseMoveEventToGetPosition = (viewer) => dispatch => {
    viewer.entities.removeById("ms");
    const entityLabel = labelPosition(viewer);

    const handler = new ScreenSpaceEventHandler(viewer.scene.canvas);

    const listener = (movement) => {
        const ray = viewer.camera.getPickRay(movement.endPosition);
        const mousePosition = viewer.scene.globe.pick(ray, viewer.scene);
        if (defined(mousePosition)) {
            const cartographic = Cartographic.fromCartesian(mousePosition);
            const latitude = parseFloat((cartographic.latitude * 180 / Math.PI).toFixed(6));
            const longitude = parseFloat((cartographic.longitude * 180 / Math.PI).toFixed(6));
            entityLabel.position = Cartesian3.fromDegrees(longitude, latitude);
            entityLabel.label.show = true;
            entityLabel.label.text = `lat : ${latitude}\nlon : ${longitude}`;
        } else {
            entityLabel.label.show = false;
        }
    }
    handler.setInputAction(listener, ScreenSpaceEventType.MOUSE_MOVE);

    dispatch(createAction(DASHBOARD_ACTION_TYPES.REGISTER_HANDLER_LISTENER, handler));
}