export const MAINTENANCE_ACTION_TYPES = {
    GET_ALL : `maintenance/GET_ALL`,
    GET_ALL_REPORT : `maintenance/GET_ALL_REPORT`,
    CLEAR_DATA : `maintenance/CLEAR_DATA`,
    ASSET_DATA : `maintenance/ASSET_DATA`,
    GET_ALL_ASSET : `maintenance/GET_ALL_ASSET`,
    RESET_ALL_ASSET_TABLE : `maintenance/RESET_ALL_ASSET_TABLE`,
    GET_SITE : `maintenance/GET_SITE`,
    SELECTED_ROW_WITH_ID : `maintenance/SELECTED_ROW_WITH_ID`,
    SELECTED_ASSET_TYPE : `maintenance/SELECTED_ASSET_TYPE`,
    SELECTED_ASSET_DATA : `maintenance/SELECTED_ASSET_DATA`,
    SELECTED_MAINTENANCE_TYPE : `maintenance/SELECTED_MAINTENANCE_TYPE`,
    CREATE_MAINTENANCE : `maintenance/CREATE_MAINTENANCE`,
    GET_ALL_SCHEDULE : `maintenance/GET_ALL_SCHEDULE`,
    SEND_TO_LOG : `maintenance/SEND_TO_LOG`,
    REMOVE_STATUS : `maintenance/REMOVE_STATUS`,
    CREATE_STATUS : `maintenance/CREATE_STATUS`,
    UPDATE_STATUS : `maintenance/UPDATE_STATUS`,
    UPDATE_WITH_NEW_TASK : `maintenance/UPDATE_WITH_NEW_TASK`,
    CLEAR_REMOVE_STATUS : `maintenance/CLEAR_REMOVE_STATUS`,
    SEND_TO_LOG_STATUS : `maintenance/SEND_TO_LOG_STATUS`,
    GET_ALL_HISTORY : `maintenance/GET_ALL_HISTORY`,
    GET_ALL_HISTORY_REPORT : `maintenance/GET_ALL_HISTORY_REPORT`,
    HANDLE_EDIT : `maintenance/HANDLE_EDIT`,
    CLEAR_DATA_ASSET : `maintenance/CLEAR_DATA_ASSET`,
    START_CREATE_OR_UPDATE : `maintenance/START_CREATE`,
    DONE_CREATE_OR_UPDATE : `maintenance/DONE_CREATE`,
    ERROR_MESSAGE : `maintenance/ERROR_MESSAGE`,
}