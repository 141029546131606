import * as React from "react";

const RadioIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width ? props.width : 23}
    height={props.height ? props.height : 23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1911 1911"
    {...props}
  >
    <rect
      fill="currentColor"
      stroke="#FEFEFE"
      strokeWidth={6.633}
      x={354}
      y={926}
      width={989}
      height={465}
      rx={43}
      ry={20}
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={4.975}
      d="M763 657h536c10 0 18 7 22 16 0 1 0 2 1 4l54 212c2 3 3 7 3 11v487c0 13-11 23-23 23H336c-13 0-23-10-23-23V900c0-8 4-16 11-20 1 0 2-1 2-1l418-212c5-6 11-10 19-10z"
    />
    <circle
      fill="#183343"
      stroke="currentColor"
      strokeWidth={1.327}
      cx={596}
      cy={1148}
      r={190}
    />
    <circle
      fill="currentColor"
      stroke="#183343"
      strokeWidth={6.633}
      cx={921}
      cy={1089}
      r={84}
    />
    <path
      fill="#183343"
      stroke="#183343"
      strokeWidth={6.633}
      d="M931 976c35 0 64 27 68 61l4 36c-7-39-41-68-82-68-30 0-56 16-71 40l20-32c1-3 3-6 6-9 12-17 32-28 55-28z"
    />
    <circle
      fill="currentColor"
      stroke="#183343"
      strokeWidth={6.633}
      cx={1177}
      cy={1084}
      r={55}
    />
    <path
      fill="#183343"
      stroke="#183343"
      strokeWidth={6.633}
      d="M1183 1009c23 0 43 18 45 41l3 23c-5-25-28-44-54-44-20 0-38 10-48 26l13-21c2-2 3-4 4-6 9-12 22-19 37-19z"
    />
    <path
      fill="currentColor"
      stroke="#183343"
      strokeWidth={9.95}
      d="M1466 802c-36 41-95 51-141 27 40 1 81-16 111-50 32-37 43-86 32-129 36 43 37 108-2 152z"
    />
    <circle
      fill="currentColor"
      stroke="#183343"
      strokeWidth={9.95}
      transform="rotate(95.061 351.15 943.132) scale(3.07287)"
      r={20}
    />
    <path
      fill="currentColor"
      stroke="#183343"
      strokeWidth={9.95}
      d="M1548 874c-58 66-152 82-227 43 65 2 131-26 178-79 52-60 69-137 52-207 58 70 58 173-3 243z"
    />
    <path
      fill="currentColor"
      stroke="#183343"
      strokeWidth={9.95}
      d="M1642 960c-72 82-190 102-283 54 81 1 164-33 223-100 65-74 86-171 64-258 73 87 73 216-4 304zM1178 552c36-41 95-51 142-27-41-1-82 16-112 50-32 37-43 85-32 129-36-44-36-108 2-152z"
    />
    <path
      fill="currentColor"
      stroke="#183343"
      strokeWidth={9.95}
      d="M1097 480c57-66 151-82 226-44-65-1-131 26-178 80-52 59-69 137-52 207-57-70-58-173 4-243z"
    />
    <path
      fill="currentColor"
      stroke="#183343"
      strokeWidth={9.95}
      d="M1002 394c72-83 190-102 283-54-81-2-164 32-223 100-64 74-85 171-64 258-72-87-73-216 4-304z"
    />
    <path
      fill="currentColor"
      stroke="#183343"
      strokeWidth={4.975}
      d="M597 997c102 0 186 80 187 179-13 92-92 162-188 162-93 0-171-67-187-156v-4c0-100 84-181 188-181z"
    />
    <g fill="#183343" stroke="currentColor" strokeWidth={1.658}>
      <rect x={441} y={1191} width={310.079} height={11.757} rx={11} ry={0} />
      <rect x={441} y={1137} width={310.079} height={11.757} rx={11} ry={0} />
      <rect x={491} y={1251} width={209.987} height={11.757} rx={7} ry={0} />
      <rect x={491} y={1070} width={209.987} height={11.757} rx={7} ry={0} />
    </g>
    <rect
      fill="currentColor"
      stroke="#183343"
      strokeWidth={9.95}
      x={969}
      y={1283}
      width={163.016}
      height={68.088}
      rx={6}
      ry={2}
    />
    <rect
      fill="currentColor"
      stroke="#183343"
      strokeWidth={9.95}
      x={809}
      y={1283}
      width={110.19}
      height={68.088}
      rx={4}
      ry={2}
    />
    <rect
      fill="currentColor"
      stroke="#183343"
      strokeWidth={9.95}
      x={1181}
      y={1283}
      width={110.19}
      height={68.088}
      rx={4}
      ry={2}
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.327}
      d="M208 1h1496c113 0 206 93 206 207v1496c0 113-93 206-206 206H208c-114 0-207-93-207-206V208C1 94 94 1 208 1zm51 65h1393c106 0 193 87 193 193v1393c0 106-87 193-193 193H259c-106 0-193-87-193-193V259c0-106 87-193 193-193z"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={3.317}
      d="M1551 631c58 70 58 173-3 243-58 66-152 82-227 43 65 2 131-26 178-79 52-60 69-137 52-207zm-83 19c36 43 37 108-2 152-36 41-95 51-141 27 40 1 81-16 111-50 32-37 43-86 32-129zm178 6c73 87 73 216-4 304-72 82-190 102-283 54 81 1 164-33 223-100 65-74 86-171 64-258zm-262 26c-3 35-33 60-68 57s-60-33-57-68 34-60 68-57c35 3 60 33 57 68zm-206-130c36-41 95-51 142-27-41-1-82 16-112 50-32 37-43 85-32 129-36-44-36-108 2-152zm-81-72c57-66 151-82 226-44-65-1-131 26-178 80-52 59-69 137-52 207-57-70-58-173 4-243zm-95-86c72-83 190-102 283-54-81-2-164 32-223 100-64 74-85 171-64 258-72-87-73-216 4-304z"
    />
  </svg>
);

export default RadioIcon;
