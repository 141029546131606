/* eslint-disable default-case */
import React from "react";
import {
  Antenna2Icon,
  RadioIcon,
  PabxIcon,
  TowerIcon,
  SupportingIcon,
  AssetsIcon,
  ElectronicIcon,
  FiberOpticIcon
} from "../../assets/svgs";

const RenderIcon = ({ icon, fill }) => {
  switch (icon) {
    case "total":
      return <AssetsIcon width={109} height={109} />;
    case "radio":
      return <RadioIcon width={109} height={109} />;
    case "antenna":
      return <Antenna2Icon width={109} height={109} fill={"#183343"} />;
    case "tower":
      return <TowerIcon width={109} height={109} />;
    case "pabx":
      return <PabxIcon width={109} height={109} />;
    case "supp":
      return <SupportingIcon width={109} height={109} />;
    case "electronic":
      return <ElectronicIcon width={109} height={109} />;
    case "fo":
      return <FiberOpticIcon width={109} height={109} />;
    default:
      return "";
  }
};

const CardAssets = ({ asset, item = 0, icon, customClass = null }) => {
  return (
    <div
      className={`flex justify-between items-center text-white bg-secondary w-[373px] h-[209px] px-8 py-6 rounded-2xl select-none ${customClass}`}
    >
      <div>
        <h2 className="text-3xl font-bold mt-2">{item}</h2>
        <h3 className="text-xl max-w-[180px] tracking-wide">{asset}</h3>
      </div>
      <RenderIcon icon={icon} fill={"#183343"} />
    </div>
  );
};

export default CardAssets;
