import Indicator from "../../assets/png/indicator.png";
import {MapViewer} from "../../components/map/MapViewer";
import {useEffect, useRef, useState} from "react";
import Modals from "../../components/modal/Modals";
import Select from "react-select";
import {Ruler} from "../../components/toolbars/Ruler";
import {FilterToolbar} from "../../components/toolbars/Filter";
import {useDispatch, useSelector} from "react-redux";
import {
    selectAllSite,
    selectAvailableNetwork,
    selectCoverageRuler,
    selectMouseMoveListener,
    selectPrimitiveTile,
    selectResultList,
    selectStatusToggleFilterButton,
    showAllCoverageThicked,
    showAllLabelThicked
} from "../../store/dashboard/dashboard.selector";
import {
    clearViewer,
    fetchAvailableNetwork,
    fetchResultFromArchive,
    flyToIndonesia,
    handleCloseToggleFilter,
    handleFetchAllSite,
    handleSave3DTileInstance,
    handleTickShowCoverageById,
    registerEventListener,
    registerViewer,
} from "../../store/dashboard/dashboard.action";
import {defined, SceneMode, ScreenSpaceEventType} from "cesium";
import {customStyles} from "../../utils/customStyles/customStyle";
import {
    handleCopyData,
    handleMouseMoveEventToGetPositionAndBearing,
    handleResetShowCoverageNotification
} from "../../store/area/area.action";
import {selectDataEntityFromOtherPage, selectShowCoverageNotification} from "../../store/area/area.selector";
import {MAX_REQUEST_SIZE, PM, USER_MABES} from "../../utils/constant/constants";
import {handleRulerDisable} from "../../store/ruler/ruler.action";
import ShowPannel from "../../components/drawer/ShowPannel";
import {handleGetAllRole, handleGetRoleById} from "../../store/role/role.action";
import {handleFetchSite} from "../../store/radio/radio.action";
import {selectCurrentUser} from "../../store/user/user.selector";
import RightPannel from "../../components/drawer/RightPannel";
import {
    activate3DTile,
    createEntityAndDataSource,
    deactivate3DTile,
    getTerrainHeightByDegree
} from "../../utils/cesium/cesiumUtils";
import {removeAllEntites} from "../../store/tower/tower.action";
import {SHOW} from "../../store/area/area.types";
import {handleLoading} from "../../store/global/global.action";
import {CoverageRuler} from "../../components/toolbars/CoverageRuler";
import {handleRulerButton, handleStoreName} from "../../store/coverageRuler/coverageRuler.action";

const Dashboard = () => {
    const ref = useRef(null); // viewer
    const [isShowKey, setIsShowKeys] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [unCheckShowAllCoverage, setUnCheckShowAllCoverage] = useState(false);
    const [filterType, setFilterType] = useState("last");
    const [networks, setNetworks] = useState();
    const [selectedSite, setSelectedSite] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const radios = [5, 10, 15, 20];
    const [selectedFilterRadioButton, setSelectedFilterRadioButton] = useState(radios[0]);
    const [isTerrainActive, setIsTerrainActive] = useState(false);
    const [isTileActive, setTileActive] = useState(null);
    const [notifyScane, setNotifyScane] = useState(false);
    const params = {at: "AREA", page: 0, size: selectedFilterRadioButton};

    const dispatch = useDispatch();
    const availableNetworks = useSelector(selectAvailableNetwork);
    const resultList = useSelector(selectResultList);
    const coverageRuler = useSelector(selectCoverageRuler);
    const checkShowAllCoverage = useSelector(showAllCoverageThicked);
    const checkShowAllLabel = useSelector(showAllLabelThicked);
    const showFilter = useSelector(selectStatusToggleFilterButton)
    const dataEntityFromOtherPage = useSelector(selectDataEntityFromOtherPage)
    const currentUser = useSelector(selectCurrentUser)
    const mouseMoveListener = useSelector(selectMouseMoveListener)
    const allSite = useSelector(selectAllSite)
    const showCoverageNotification = useSelector(selectShowCoverageNotification)
    const primitiveTile = useSelector(selectPrimitiveTile)

    useEffect(() => {
        dispatch(handleLoading(true));
        dispatch(handleRulerButton(false)); // coverage ruler tools map is false by default

        const viewer = ref.current.cesiumElement;
        dispatch(registerViewer(viewer))
        viewer.scene.mode = SceneMode.COLUMBUS_VIEW;
        flyToIndonesia(viewer, true)
        let addEventListener = null;
        dispatch(fetchAvailableNetwork());
        // handle when terrain data selected
        viewer.scene.morphStart.addEventListener(
            () => {
                dispatch(handleSave3DTileInstance(false, null));
            }
        );
        viewer.scene.morphComplete.addEventListener(
            () => {
                console.log(`viewer.scene.mode !== SceneMode.SCENE3D = ${viewer.scene.mode}`)
                setNotifyScane(true)
            }
        );
        const tileLoadListener = handleTileLoadListener(viewer);
        viewer.scene.globe.tileLoadProgressEvent.addEventListener(tileLoadListener);

        // fetch archive
        dispatch(fetchResultFromArchive({
            at: "AREA", page: 0, size: 5
        }, viewer, isTerrainActive));

        addEventListener = viewer.selectedEntityChanged.addEventListener(selectedEntity => {
            if (defined(selectedEntity)) {
                if (defined(selectedEntity.id)) {
                    setSelectedId(selectedEntity.id)
                    viewer.selectedEntity = null;

                }
            } else {
                setSelectedId(null);
                setSelectedData(null);
            }
        });
        dispatch(registerEventListener(addEventListener));
        dispatch(handleGetAllRole());
        dispatch(handleFetchSite());
        if (currentUser && currentUser.role) {
            dispatch(handleGetRoleById(currentUser.role));
        }

        if (dataEntityFromOtherPage) {
            setSelectedId(dataEntityFromOtherPage.id)
        }

        timeOut();

        return () => {
            dispatch(handleLoading(false));
            if (mouseMoveListener) {
                mouseMoveListener.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
            }

            if (viewer && addEventListener) {
                viewer.selectedEntityChanged.removeEventListener(addEventListener);
            }

            dispatch(handleRulerDisable());
            removeAllEntites(viewer);

            setSelectedData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const timeOut = () => {
        setTimeout(() => {
            dispatch(handleLoading(false))
        }, 7000);
    };

    useEffect(() => {
        const viewer = ref.current.cesiumElement;
        if (selectedId && resultList) {
            const content = resultList.content;
            let entity;
            for (let i = 0; i < content.length; i++) {
                if (content[i].id === selectedId) {
                    entity = {...content[i]}
                    break
                }
            }

            if (entity) {
                const findPm = PM.find(d => d.value === Number(entity.data.model.pm));
                if (findPm) {
                    entity.data.model.pm = findPm.label;
                }
                if (entity.data.name) {
                    dispatch(handleStoreName(entity.data.name)); // get selected name for coverage ruler
                }
                setIsOpen(true);
                setSelectedData(entity);
                dispatch(handleCopyData(entity)); // copy data for other analyze
                if (showCoverageNotification === SHOW && entity.data && (
                    entity.data.coverageType === "GTA" ||
                    entity.data.coverageType === "GTG" ||
                    entity.data.coverageType === "JAMMER")) {

                    dispatch(handleResetShowCoverageNotification());
                    dispatch(handleTickShowCoverageById(resultList, {
                        id: entity.id,
                        name: entity.data.name,
                        showCoverage: true,
                    }));
                }

                if (mouseMoveListener) {
                    mouseMoveListener.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
                }

                dispatch(handleMouseMoveEventToGetPositionAndBearing(viewer,
                    entity.data.transmitter.lat,
                    entity.data.transmitter.lon));
            }
        } else {
            if (mouseMoveListener) {
                mouseMoveListener.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
            }
            viewer.entities.removeById("ms");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId, resultList]);

    useEffect(() => {
        if (dataEntityFromOtherPage) {
            setSelectedId(dataEntityFromOtherPage.id)
        }
    }, [dataEntityFromOtherPage])

    useEffect(() => {
        const cesiumElement = ref.current.cesiumElement;
        if (isTerrainActive) {
            if (cesiumElement.scene.mode === SceneMode.SCENE3D) {
                const cesium3DTileset = activate3DTile(cesiumElement);
                dispatch(handleSave3DTileInstance(true, cesium3DTileset));
                setTileActive(cesium3DTileset);
            }
            setUnCheckShowAllCoverage(true);
            if (resultList && resultList.content && resultList.content.length) {
                for (let i = 0; i < resultList.content.length; i++) {
                    const lon = resultList.content[i].data.transmitter.lon;
                    const lat = resultList.content[i].data.transmitter.lat;
                    getTerrainHeightByDegree(lat, lon).then((result) => {
                        resultList.content[i].data.transmitter.altWithTerrain = resultList.content[i].data.transmitter.alt + result;
                        dispatch(removeAllEntites(cesiumElement));
                        createEntityAndDataSource(resultList, cesiumElement, true);
                    });
                }
            }
        } else {
            setUnCheckShowAllCoverage(true);
            if (resultList && resultList.content && resultList.content.length) {
                for (let i = 0; i < resultList.content.length; i++) {
                    dispatch(removeAllEntites(cesiumElement));
                    createEntityAndDataSource(resultList, cesiumElement, false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTerrainActive]);

    useEffect(() => {
        if (notifyScane) {
            const cesiumElement = ref.current.cesiumElement;
            if (cesiumElement.baseLayerPicker.viewModel.selectedTerrain.name === `Cesium World Terrain` &&
                cesiumElement.scene.mode === SceneMode.SCENE3D &&
                primitiveTile === null) {
                const cesium3DTileset = activate3DTile(cesiumElement);
                dispatch(handleSave3DTileInstance(true, cesium3DTileset));
                setTileActive(cesium3DTileset);
            }
        }
        setNotifyScane(false);
    }, [notifyScane]);


    useEffect(() => {
        const cesiumElement = ref.current.cesiumElement;
        if (primitiveTile === null && isTileActive) {
            deactivate3DTile(cesiumElement, isTileActive);
            setTileActive(null);
        }

    }, [primitiveTile]);

    const handleTileLoadListener = viewer => () => {
        if (viewer.scene.globe.tilesLoaded) {
            if (viewer.baseLayerPicker.viewModel.selectedTerrain.name === `Cesium World Terrain`) {
                setIsTerrainActive(true);
            } else {
                setIsTerrainActive(false);
                dispatch(handleSave3DTileInstance(false, null));
                setTileActive(null);
            }
        }
    }

    /**
     * The handleChange function is a function that takes in a selectedOption parameter and sets the state
     * of the networks variable to the value of the selectedOption parameter.
     */
    const handleChange = (selectedOption) => {
        setNetworks(selectedOption);

    };

    /**
     * When the user clicks the button, the showFilter state is set to the opposite of what it currently
     * is.
     */
    const handleClose = () => {
        dispatch(handleCloseToggleFilter());
    };

    /**
     * When the user clicks the button, the function will fetch data from the server and then close the
     * modal.
     */
    const handleFilterResult = async () => {
        const viewer = ref.current.cesiumElement;
        let newParam = params;
        if (filterType.toLowerCase() === "network") {
            newParam = {...newParam, network: networks.value, size: MAX_REQUEST_SIZE};
        } else if (filterType.toLowerCase() === "site") {
            newParam = {at: "AREA", page: 0, size: MAX_REQUEST_SIZE, siteId: selectedSite.value}
        } else {
            newParam = {...newParam, size: selectedFilterRadioButton};
        }

        clearViewer(viewer);
        dispatch(fetchResultFromArchive(newParam, viewer, isTerrainActive));
        setSelectedData(null);

        handleClose();
    };

    const handleFilterType = (event) => {
        if (event.target.value === "network") {
            dispatch(fetchAvailableNetwork());
        } else if (event.target.value === "site") {
            dispatch(handleFetchAllSite())
        }
        setFilterType(event.target.value);
    }

    /* A React component. */
    const Panel = () => {
        return (
            <RightPannel
                dashBoard={true}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                setSelectedId={setSelectedId}
                checkShowAllLabel={checkShowAllLabel}
                isShowKey={isShowKey}
                setIsShowKeys={setIsShowKeys}
                resultList={resultList}
                coverageRuler={coverageRuler}
                checkShowAllCoverage={checkShowAllCoverage}
                unCheckShowAllCoverage={unCheckShowAllCoverage}
                setUnCheckShowAllCoverage={setUnCheckShowAllCoverage}
            />
        );
    };

    const showFilterAnalysis = () => {
        if (filterType === "last") {
            return radios.map((el, i) => {
                return (
                    <form key={i} onSubmit={handleFilterResult}>
                        <div
                            className="flex flex-row items-center p-2 m-2"
                        >
                            <input
                                name={`radio-${i}`}
                                className="radio cursor-pointer border-2 mr-2 "
                                style={{borderColor: "white"}}
                                type="radio"
                                value={el}
                                checked={selectedFilterRadioButton === el}
                                onChange={(e) => {
                                    setSelectedFilterRadioButton(parseInt(e.target.value));
                                }}
                            />
                            <label
                                className="form-check-label inline-block text-white text-lg mt-1 ml-2"
                                htmlFor={`radio-${i}`}
                            >
                                {el}
                            </label>
                        </div>
                    </form>
                );
            })
        } else if (filterType === "network") {
            return (
                <Select
                    styles={customStyles}
                    onChange={handleChange}
                    options={availableNetworks}
                    value={networks}
                />
            )
        } else if (filterType === "site" && currentUser.siteName === USER_MABES) {
            return (
                <Select
                    styles={customStyles}
                    onChange={handleChangeSite}
                    options={allSite}
                    value={selectedSite}
                />
            )
        }
    }

    const handleChangeSite = (e) => {
        setSelectedSite(e)
    }

    /* A React component that is a modal. */
    const FilterModal = () => {
        return (
            <>
                <Modals
                    open={showFilter}
                    isRightPannelOpen={isOpen}
                    onClose={handleClose}
                    onSave={handleFilterResult}
                    title="Filter Simulation by"
                    size='w-72'
                    height="h-[500px]"
                >
                    <select
                        className="select select-sm select-ghost h w-full max-w-xs text-white mb-3"
                        aria-label=".form-select-sm example"
                        value={filterType}
                        onChange={handleFilterType}
                    >
                        <option selected value="last">
                            Last updated size{" "}
                        </option>
                        <option value="network">Networks</option>
                        {currentUser && currentUser.siteName === USER_MABES && (
                            <option value="site">Mobile Account</option>)}
                    </select>
                    <div className="my-5">
                        {showFilterAnalysis()}
                    </div>
                </Modals>
            </>
        );
    };
    return (
        <div
            style={{
                height: "calc(100% - 60px)",
            }}
        >
            <div className="flex flex-col">
                <MapViewer ref={ref} isOpen={isOpen}>
                    <FilterToolbar/>
                    <Ruler/>
                    <CoverageRuler/>
                </MapViewer>

                <Panel/>
            </div>

            <ShowPannel
                isOpen={isOpen}
                setIsOpen={() => {
                    setIsOpen(prevIsOpen => !prevIsOpen)
                }}
            />

            <FilterModal/>

            {isShowKey && (
                <div className={`${isOpen ? "right-[450px]" : "right-10"} absolute bottom-10`}>
                    <img src={Indicator} alt="indicator"/>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
