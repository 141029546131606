import {DSS_ACTION_TYPES} from "./dss.types";
import {STATUS_TYPE} from "../../utils/constant/constants";
import { addIntoArray } from "./dss.utils";

const INITIAL_STATE = {
    status: null,  // {}
    dsss: null, // {radio:{content:[]}}
    detailDSS: null, // {}
    categories: null,  // []
    brands: null,  // []
    types: null, // []
    sites: null, // {content:[]}
    typesByBrand: null,
    statusArray: [
        {
            key: "SERVICEABLE",
            name: "SERVICEABLE",
        },
        {
            key: "SPARE",
            name: "SPARE",
        },
        {
            key: "UNSERVICEABLE",
            name: "UNSERVICEABLE",
        },
    ],
    
    column: [
        { heading: "Name", value: "name" },
        { heading: "Brand", value: "refAssetType.brandName" },
        { heading: "Type", value: "refAssetType.name" },
        { heading: "Serial Number", value: "serialNo" },
        { heading: "Owner", value: "site.siteName" },
        { heading: "Status", value: "status" },
    ],

    uploadDSSStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedImages: [],
    uploadImageStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedFiles: [],
    uploadFileStatus: STATUS_TYPE.NO_STATEMENT,
    isLoading: false,
    removeStatus: STATUS_TYPE.NO_STATEMENT,
    updateStatus: STATUS_TYPE.NO_STATEMENT,
}

export const dssReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case DSS_ACTION_TYPES.GET_ALL_DSS:
            return {...state, dsss: payload};
        case DSS_ACTION_TYPES.SEARCH:
            return {...state, dsss: payload};
        case DSS_ACTION_TYPES.SEARCH_REPORT:
            return {...state, dsssReport: payload};
        case DSS_ACTION_TYPES.DETAIL_DSS:
            return {...state, detailDSS: payload};
        case DSS_ACTION_TYPES.CLEAR_DETAIL_DSS:
            return {...state, detailDSS: null};
        case DSS_ACTION_TYPES.GET_CATEGORY:
            return {...state, categories: payload};
        case DSS_ACTION_TYPES.GET_TYPE:
            return {...state, types: payload};
        case DSS_ACTION_TYPES.GET_BRAND:
            return {...state, brands: payload};
        case DSS_ACTION_TYPES.GET_SITE:
            return {...state, sites: payload};
        case DSS_ACTION_TYPES.GET_DSS_TYPES_BY_BRAND_ID:
            return {...state, typesByBrand: payload}
        case DSS_ACTION_TYPES.CLEAR_DSS_TYPES_BY_BRAND_ID:
            return {...state, typesByBrand: INITIAL_STATE.typesByBrand}
        case DSS_ACTION_TYPES.CLEAR_DATA:
            return INITIAL_STATE;
        case DSS_ACTION_TYPES.UPLOAD_DSS_STATUS:
            return {...state, uploadDSSStatus: payload, uploadedFiles: [], uploadedImages: []}
        case DSS_ACTION_TYPES.UPLOAD_IMAGE:
            return {...state, uploadedImages: payload}
        case DSS_ACTION_TYPES.UPLOAD_IMAGE_STATUS:
            return {...state, uploadImageStatus: payload}
        case DSS_ACTION_TYPES.UPLOAD_FILE:
                const newArray = addIntoArray(state.uploadedFiles, payload);
                return {...state, uploadedFiles: newArray}
        case DSS_ACTION_TYPES.UPLOAD_FILE_STATUS:
            return {...state, uploadFileStatus: payload}
        case DSS_ACTION_TYPES.REMOVED:
            return {...state, removeStatus: payload}
        case DSS_ACTION_TYPES.UPDATED:
            return {...state, updateStatus: payload}
        default:
            return state;
    }
}