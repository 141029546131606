import {ACTION_GET, ACTION_PUT, ACTION_POST, BASE_URL, ACTION_DELETE, ACTION_DELETE_WITH_BODY} from "./core";

export const SEARCH = (query, queryType, size, page) => {
    const url = `${BASE_URL}/fo/search?q=${query}&type=${queryType}&size=${size}&page=${page}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const GET_DETAIL_BY_ID = (assetId) => {
    const url = `${BASE_URL}/fo/${assetId}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};


export const CREATE_FO = (param) => {
    const url = `${BASE_URL}/fo`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const GET_DETAIL_MULTI = (param) => {
    const url = `${BASE_URL}/fo/detail`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const ADD_KINETIC = (assetId, param) => {
    const url = `${BASE_URL}/fo/point/${assetId}`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const UPDATE_FO = (assetId, param) => {
    const url = `${BASE_URL}/fo/${assetId}`;
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const DELETE_ASSET = (assetId) => {
    const url = `${BASE_URL}/distributionassets/${assetId}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const DELETE_KINETIC = (assetId, params) => {
    const url = `${BASE_URL}/fo/point/${assetId}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE_WITH_BODY(url, params)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

