import {ROLE_ACTION_TYPES} from "./role.types";

const INITIAL_STATE = {
    allRole: null,
    roleData: null,
}

export const roleReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case ROLE_ACTION_TYPES.GET_ALL:
            return {...state, allRole: payload}
        case ROLE_ACTION_TYPES.GET_BY_ID:
            return {...state, roleData: payload}
        case ROLE_ACTION_TYPES.RESET_ROLE_DATA:
            return {...state, roleData: null}
        default:
            return state;
    }
}
