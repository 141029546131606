import {FO_ACTION_TYPES} from "./fo.types";
import {STATUS_TYPE} from "../../utils/constant/constants";

const INITIAL_STATE = {
    points: [], // [{lat, lon, cartesianPosition}]
    pointEntities: [],
    polylineEntities: [],
    midLabelEntities: [],
    distanceNumbers: [],
    responsePost: null,
    searchResponse: null,
    goToAsset: null, // {data, goToAsset}
    detailAsset: null,
    detailMultiAsset: null,
    removeStatus: STATUS_TYPE.NO_STATEMENT,
    updateStatus: STATUS_TYPE.NO_STATEMENT,
    uploadFoStatus: STATUS_TYPE.NO_STATEMENT,

}

export const foReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;
    let copyPoints = [];
    let copyPointEntities = [];
    let copyPolylineEntities = [];
    let copyMidLabelEntities = [];
    let copyDistanceNumbers = [];

    switch (type) {
        case FO_ACTION_TYPES.CLEAR_STORAGE:
            return INITIAL_STATE;
        case FO_ACTION_TYPES.DETAIL_MULTI:
            return {...state, detailMultiAsset: payload};
        case FO_ACTION_TYPES.CLEAR_STORAGE_EXCEPT_UPDATE:
            return {...INITIAL_STATE, detailAsset: payload};
        case FO_ACTION_TYPES.EDIT_ASSET:
            return {...state, detailAsset: payload};
        case FO_ACTION_TYPES.GO_TO_EDIT_ASSET:
            return {...state, goToAsset: payload};
        case FO_ACTION_TYPES.SEARCH_RESPONSE:
            return {...state, searchResponse: payload};
        case FO_ACTION_TYPES.REMOVE_A_POINT:
            if (state.points.length) {
                copyPoints = [...state.points];
            }
            if (state.pointEntities.length) {
                copyPointEntities = [...state.pointEntities];
            }
            copyPointEntities.pop();
            copyPoints.pop();
            return {...state, points: copyPoints, pointEntities: copyPointEntities}
        case FO_ACTION_TYPES.REMOVE_A_POLYLINE_AND_A_MID_LABEL:
            if (state.polylineEntities.length) {
                copyPolylineEntities = [...state.polylineEntities];
            }
            if (state.midLabelEntities.length) {
                copyMidLabelEntities = [...state.midLabelEntities];
            }
            if (state.distanceNumbers.length) {
                copyDistanceNumbers = [...state.distanceNumbers];
            }
            copyMidLabelEntities.pop();
            copyPolylineEntities.pop();
            copyDistanceNumbers.pop();
            return {
                ...state,
                polylineEntities: copyPolylineEntities,
                midLabelEntities: copyMidLabelEntities,
                distanceNumbers: copyDistanceNumbers
            }
        case FO_ACTION_TYPES.ADD_POINT:
            if (state.points.length) {
                copyPoints = [...state.points];
            }
            copyPoints.push(payload);
            return {...state, points: copyPoints}
        case FO_ACTION_TYPES.ADD_POLYLINE:
            if (state.polylineEntities.length) {
                copyPolylineEntities = [...state.polylineEntities];
            }
            copyPolylineEntities.push(payload);
            return {...state, polylineEntities: copyPolylineEntities}
        case FO_ACTION_TYPES.ADD_DISTANCE_LABEL:
            if (state.midLabelEntities.length) {
                copyMidLabelEntities = [...state.midLabelEntities];
            }
            copyMidLabelEntities.push(payload);
            return {...state, midLabelEntities: copyMidLabelEntities}
        case FO_ACTION_TYPES.ADD_ENTITY_POINT:
            if (state.pointEntities.length) {
                copyPointEntities = [...state.pointEntities];
            }
            copyPointEntities.push(payload);
            return {...state, pointEntities: copyPointEntities};
        case FO_ACTION_TYPES.ADD_DISTANCE_NUMBER:
            if (state.distanceNumbers.length) {
                copyDistanceNumbers = [...state.distanceNumbers];
            }
            copyDistanceNumbers.push(payload);
            return {...state, distanceNumbers: copyDistanceNumbers};
        case FO_ACTION_TYPES.RESPONSE_POST_FO:
            return {...state, responsePost: payload}
        case FO_ACTION_TYPES.REMOVED:
            return {...state, removeStatus: payload}
        case FO_ACTION_TYPES.UPDATED:
            return {...state, updateStatus: payload}
        case FO_ACTION_TYPES.UPLOAD_FO_STATUS:
            return {...state, uploadFoStatus: payload}
        default:
            return state
    }
}