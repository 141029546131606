import {ACTION_DELETE, ACTION_GET, ACTION_POST, ACTION_PUT, BASE_URL} from "./core";

export const GET_ALL_HISTORY_SCHEDULE = (type, page, size) => {
    const url = `${BASE_URL}/maintenance?size=${size}&page=${page}&f=${type}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const GET_ALL_MAINTENANCE = (search, sType, page, size, mType) => {

    const url = `${BASE_URL}/maintenance/search?q=${search.toLowerCase()}&type=${sType}&page=${page}&size=${size}&f=${mType}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const GET_MAINTENANCE_BY_SITE = (siteId) => {
    const url = `${BASE_URL}/maintenance/site/${siteId}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const GET_MAINTENANCE_BY_ID = (id) => {
    const url = `${BASE_URL}/maintenance/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const GET_MAINTENANCE_TASK_BY_ID = (id) => {
    const url = `${BASE_URL}/mt/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const DELETE_MAINTENANCE_TASK_BY_ID = (id) => {
    const url = `${BASE_URL}/mt/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const GET_ALL_MAINTENANCE_BY_REGION = (regionId) => {
    const url = `${BASE_URL}/maintenance/site/${regionId}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};


export const CREATE_MAINTENANCE = (body) => {
    const url = `${BASE_URL}/maintenance`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, body)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const CREATE_MAINTENANCE_TASK = (body, mtId) => {
    const url = `${BASE_URL}/mt?id=${mtId}`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, body)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const CREATE_MAINTENANCE_HISTORY = (maintenanceId) => {
    const url = `${BASE_URL}/maintenance/${maintenanceId}/history`;
    return new Promise((resolve, reject) => {
        ACTION_PUT(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const UPDATE_MAINTENANCE = (id, body) => {
    const url = `${BASE_URL}/maintenance/${id}`
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, body)
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error)
            })
    })
}
export const UPDATE_MAINTENANCE_TASK = (id, body) => {
    const url = `${BASE_URL}/mt?id=${id}`
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, body)
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const DELETE_MAINTENANCE = (id) => {
    const url = `${BASE_URL}/maintenance/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


