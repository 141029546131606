import React, {useCallback, useEffect, useRef, useState} from "react";
import RedTower from "../../components/toolbars/RedTower";
import WhiteTower from "../../components/toolbars/WhiteTower";
import Recycle from "../../components/toolbars/Recycle";
import {MapViewer} from "../../components/map/MapViewer";
import RightPannel from "../../components/drawer/RightPannel";
import {
    flyToIndonesia,
    handleCloseToggleFilter,
    handleMouseMoveEventToGetPosition,
    registerViewer
} from "../../store/dashboard/dashboard.action";
import {useDispatch, useSelector} from "react-redux";
import {selectAllRadio, selectDetailRadio} from "../../store/radio/radio.selector";
import {selectCurrentUser} from "../../store/user/user.selector";
import {
    CallbackProperty,
    Cartesian3,
    Cartographic,
    defined,
    Ellipsoid,
    JulianDate,
    ScreenSpaceEventHandler,
    ScreenSpaceEventType
} from "cesium";
import {handleGetRadioById, handleSearchRadio} from "../../store/radio/radio.action";
import FadeLoader from "react-spinners/FadeLoader";
import {ALERT_ICON_FAILED, ALERT_TITLE_FAILED, ANALYZE_TYPE, patterns} from "../../utils/constant/constants";
import Modals from "../../components/modal/Modals";
import {
    handleClearDataStorage,
    handleClearErrorMessage,
    handleCopyOriginalPayload,
    handleCreateBillboard,
    handleCreateHorizontalLine,
    handleCreateMannet,
    handleCreatePoint,
    handleCreateVerticalLine,
    handleDeleteMannet,
    handleFetchMannetByNetwork,
    handleFetchMannetNetwork,
    handleIsLoading,
    handleModifyBillboardAndVLineData,
    handleModifyOriginalPayload,
    handleNotifyRightPannel,
    handleRegisterClickListener,
    handleSetTerrainHeigh,
    handleSetTotalProgress,
    handleUpdateMannet
} from "../../store/mannet/mannet.action";
import {
    originalPayload,
    selectBillboardData,
    selectChartData,
    selectDbmLabelData,
    selectDrawingProgressStatusReport,
    selectedEntityId,
    selectEntityListener,
    selectFailedMessage,
    selectHLineDataSources,
    selectIsLoading,
    selectMannetNetworks,
    selectMannets,
    selectProgressCount,
    selectResponsePoints,
    selectTerrainHeight,
    selectTotalProgress,
    selectVerticalLineData,
    selectVLineDataSources
} from "../../store/mannet/mannet.selector";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {getNumberIdEntity} from "../../store/mannet/mannet.utility";
import {handleRedTowerToggle, handleWhiteTowerToggle, removeAllEntites} from "../../store/tower/tower.action";
import {
    handleHeaderModalRadioOrAntenna,
    handleModalLoadRadioOrAntenna,
    handleValidationMeshSimulation
} from "../../store/area/area.util";
import {handleGetAntennaById, handleSearchAntenna} from "../../store/antenna/antenna.action";
import {selectAllAntenna, selectDetailAntenna} from "../../store/antenna/antenna.selector";
import {
    getTerrainHeightByDegree,
    getTerrainHeightByDegreeWithSeqNumber,
    radsToDegs
} from "../../utils/cesium/cesiumUtils";
import ShowPannel from "../../components/drawer/ShowPannel";
import {FilterToolbar} from "../../components/toolbars/Filter";
import Select from "react-select";
import {customStyles} from "../../utils/customStyles/customStyle";
import {selectMouseMoveListener, selectStatusToggleFilterButton} from "../../store/dashboard/dashboard.selector";
import {generalAlert} from "../../utils/notification/notification";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        y: {
            ticks: {
                callback: function (value) {
                    return value + '%';
                }
            },
            grid: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
                drawTicks: true,
                color: "white",
            }
        }
    }
};

const CHART_LABEL = ['A', 'B', 'C'];

export const Mesh = () => {
    const ref = useRef(null); // viewer
    const ref2 = useRef(null); // chartmesh
    const [idRadio, setIdRadio] = useState("");
    const [idAntenna, setIdAntenna] = useState("");
    const [nodes, setNodes] = useState(0);
    const [mean, setMean] = useState(0);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [showRadioAntena, setShowRadioAntena] = useState(false);
    const [loadMode, setLoadMode] = useState('');
    const [billboardIdCount, setBillboardIdCount] = useState(1);
    const [kineticPoint, setKineticPoint] = useState(null);
    const [infoDetailEntity, setInfoDetailEntity] = useState(null);
    const [isTerrainOn, setIsTerrainOn] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [dragHandler, setDragHandler] = useState(null);
    const [selectedNumberId, setSelectedNumberId] = useState(0);
    const [currentBillboardPosition, setCurrentBillboardPosition] = useState(null);
    const [previousBillboardPosition, setPreviousBillboardPosition] = useState(null);
    const [currentVLinePosition, setCurrentVLinePosition] = useState(null);
    const [previousVLinePosition, setPreviousVLinePosition] = useState(null);
    const [count, setCount] = useState(0);
    const [networks, setNetworks] = useState({});
    const [loadedMeshData, setLoadedMeshData] = useState(false);

    // const [progressCount, setProgressCount] = useState(0);
    // const [isLoading, setIsLoading] = useState(false);
    // const [terrainHeighCount, setTerrainHeighCount] = useState(null);
    // const [notifyCount, setNotifyCount] = useState(0);

    const dispatch = useDispatch();
    const radioData = useSelector(selectAllRadio);
    const currentUser = useSelector(selectCurrentUser);
    const detailRadio = useSelector(selectDetailRadio);
    const errorMessage = useSelector(selectFailedMessage);
    const responsePoints = useSelector(selectResponsePoints);
    const chartData = useSelector(selectChartData);
    const entityListener = useSelector(selectEntityListener);
    const selectedId = useSelector(selectedEntityId);
    const originalPayloadArray = useSelector(originalPayload);
    const antennaData = useSelector(selectAllAntenna);
    const detailAntenna = useSelector(selectDetailAntenna);
    const vLineDataSources = useSelector(selectVLineDataSources);
    const hLineDataSources = useSelector(selectHLineDataSources);
    const dbmLabelData = useSelector(selectDbmLabelData);
    const billboardData = useSelector(selectBillboardData);
    const verticalLineData = useSelector(selectVerticalLineData);
    const showFilter = useSelector(selectStatusToggleFilterButton)
    const mannetNetworks = useSelector(selectMannetNetworks)
    const mannets = useSelector(selectMannets)
    const drawingProgressStatusReport = useSelector(selectDrawingProgressStatusReport)
    const totalProgress = useSelector(selectTotalProgress)
    const isLoading = useSelector(selectIsLoading)
    const progressCount = useSelector(selectProgressCount)
    const terrainHeight = useSelector(selectTerrainHeight)
    const mouseMoveListener = useSelector(selectMouseMoveListener);

    /**
     * ==========================================================
     * USE_EFFECT
     * ==========================================================
     */

    useEffect(() => {
        timeOut();
        const viewer = ref.current.cesiumElement;
        dispatch(registerViewer(viewer));
        // fetch Mesh Networks
        dispatch(handleFetchMannetNetwork());

        flyToIndonesia(viewer);
        // register event click entity
        dispatch(handleRegisterClickListener(viewer));

        const handlerClick = new ScreenSpaceEventHandler(viewer.scene.canvas);
        setDragHandler(new ScreenSpaceEventHandler(viewer.scene.canvas));
        const eventListener = (event) => {
            const ray = viewer.camera.getPickRay(event.position);
            const mousePosition = viewer.scene.globe.pick(ray, viewer.scene);
            if (defined(mousePosition)) {
                const cartographic = Cartographic.fromCartesian(mousePosition);
                const lon = parseFloat(radsToDegs(cartographic.longitude).toFixed(8));
                const lat = parseFloat(radsToDegs(cartographic.latitude).toFixed(8));
                let alt = cartographic.height;
                if (viewer.baseLayerPicker.viewModel.selectedTerrain.name !== `Cesium World Terrain`) {
                    setIsTerrainOn(false);
                    alt = 0;
                } else {
                    setIsTerrainOn(true);
                }
                setKineticPoint({latitude: lat, longitude: lon, altitude: alt});
            }
        }
        handlerClick.setInputAction(eventListener, ScreenSpaceEventType.LEFT_CLICK);

        if (currentUser) {
            dispatch(handleSearchRadio("", "name", 0, 10, currentUser.siteId));
            dispatch(handleSearchAntenna("", "name", 0, 10, currentUser.siteId));
        }
        dispatch(removeAllEntites(viewer));
        dispatch(handleClearDataStorage());

        dispatch(handleMouseMoveEventToGetPosition(viewer));
        return () => {
            dispatch(handleIsLoading(false));
            setDefaultState();
            resetDragHandler();
            if (handlerClick) {
                handlerClick.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
            }
            if (viewer && entityListener) {
                viewer.selectedEntityChanged.removeEventListener(entityListener);
            }

            if (mouseMoveListener) {
                mouseMoveListener.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
            }
            dispatch(removeAllEntites(viewer));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Handle Kinetic change from click on map
     */
    useEffect(() => {
        if (kineticPoint) {
            if (selectedId) {
                let idBillboard = getNumberIdEntity(selectedId);
                if (selectedNumberId && selectedNumberId !== idBillboard && selectedNumberId !== 0) {
                    setOpen(false);
                    setInfoDetailEntity(null);
                    handleRegisterDragAction(selectedNumberId, true);
                    setCount(0);
                } else {
                    if (idBillboard > 0) {
                        setOpen(true);
                        setInfoDetailEntity(originalPayloadArray[idBillboard - 1]);
                        handleRegisterDragAction(idBillboard, false);
                    }
                }
                setSelectedNumberId(idBillboard);
            } else {
                setOpen(false);
                setInfoDetailEntity(null);
                dispatch(handleNotifyRightPannel());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kineticPoint])


    useEffect(() => {
        if (!open) {
            resetDragHandler();
            resetPositions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
        if (chartData) {
            const {nodes, mean, persentageA, persentageB, persentageC} = chartData;
            setNodes(nodes);
            setMean(mean);
            setData({
                labels: CHART_LABEL,
                datasets: [
                    {
                        label: 'Mannet',
                        data: [persentageA, persentageB, persentageC],
                        backgroundColor: 'rgb(235, 219, 219)',
                    },
                ],
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData])

    /**
     * Handle Response Mesh Simulation
     */
    useEffect(() => {
        if (responsePoints && responsePoints.resultPoint &&
            responsePoints.resultPoint.receiver && responsePoints.resultPoint.receiver.length &&
            responsePoints.resultPoint.transmitters && responsePoints.resultPoint.transmitters.length &&
            ref && ref.current.cesiumElement) {

            const viewer = ref.current.cesiumElement;
            const copyTransmittersResult = [...responsePoints.resultPoint.transmitters];
            const copyReceiverResult = [...responsePoints.resultPoint.receiver];
            const copyTransmittersOri = [...responsePoints.data.points];
            const copyReceiverOri = responsePoints.data.receiver;
            const rxLatOri = copyReceiverOri.lat;
            const rxLonOri = copyReceiverOri.lon;
            getTerrainHeightByDegree(rxLatOri, rxLonOri)
                .then(rxGroundElevationM => {
                    const rxAltAdd = isTerrainOn ? rxGroundElevationM : 0;
                    const rxAlt = copyReceiverResult[0].antennaHeightM + rxAltAdd;

                    for (let i = 0; i < copyTransmittersResult.length; i++) {
                        const dataResult = copyTransmittersResult[i];
                        const dataOri = copyTransmittersOri[i];
                        const {antennaHeightM, signalPowerAtReceiverDBm} = dataResult

                        const txLat = dataOri.lat;
                        const txLon = dataOri.lon;
                        getTerrainHeightByDegree(txLat, txLon)
                            .then((txGroundElevation) => {
                                const txAltAdd = isTerrainOn ? txGroundElevation : 0;
                                const txAlt = antennaHeightM + txAltAdd;
                                const rxs = responsePoints.data.receiver.rxs;
                                const id = responsePoints.id;

                                if (Number(signalPowerAtReceiverDBm) >= Number(rxs)) {
                                    dispatch(handleCreateHorizontalLine(viewer,
                                        Number(rxLatOri), Number(rxLonOri), Number(rxAlt),
                                        Number(txLat), Number(txLon), Number(txAlt),
                                        id, Number(signalPowerAtReceiverDBm)));
                                }
                            })
                            .catch(() => {});
                    }
                }).catch(() => {});


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responsePoints])

    useEffect(() => {
        if (totalProgress === progressCount ||
            totalProgress === hLineDataSources.length) {
            dispatch(handleIsLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalProgress, progressCount])

    useEffect(() => {
    }, [drawingProgressStatusReport])


    useEffect(() => {
        if (errorMessage) {
            generalAlert(
                ALERT_TITLE_FAILED,
                errorMessage,
                ALERT_ICON_FAILED,
                () => {
                    dispatch(removeAllEntites(ref.current.cesiumElement));
                    setDefaultState();
                    dispatch(handleClearErrorMessage());
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage])

    useEffect(() => {
        if (originalPayloadArray) {
            handleDispatchMeshSimulation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalPayloadArray])


    useEffect(() => {
        if (terrainHeight && mannets && terrainHeight.length === mannets.length) {
            const viewer = ref.current.cesiumElement;
            const copyMannets = JSON.parse(JSON.stringify(mannets));
            copyMannets.sort(function (a, b) {
                return a.seqNumber - b.seqNumber;
            });
            terrainHeight.sort(function (a, b) {
                return a.seqNumber - b.seqNumber;
            });

            for (let i = 0; i < copyMannets.length; i++) {
                const mannet = copyMannets[i];
                createBillboardAndVLine(mannet, i, viewer, terrainHeight[i].height);
            }
            setBillboardIdCount(copyMannets.length + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [terrainHeight]);

    useEffect(() => {
        if (mannets && loadedMeshData) {
            timeOut();

            const viewer = ref.current.cesiumElement;
            const copyMannets = JSON.parse(JSON.stringify(mannets));
            copyMannets.sort(function (a, b) {
                return a.seqNumber - b.seqNumber;
            });

            dispatch(handleIsLoading(true));
            const isTerrainActive = viewer.baseLayerPicker.viewModel.selectedTerrain.name === `Cesium World Terrain`
            setIsTerrainOn(isTerrainActive);

            for (let i = 0; i < copyMannets.length; i++) {
                const mannet = copyMannets[i];
                const point = mannet.request;
                const latitude = point.transmitter.lat;
                const longitude = point.transmitter.lon;
                if (!isTerrainActive) {
                    createBillboardAndVLine(mannet, i, viewer);
                } else {
                    getTerrainHeightByDegreeWithSeqNumber(latitude, longitude, mannet.seqNumber)
                        .then(result => {
                            dispatch(handleSetTerrainHeigh(result));
                        })
                        .catch(() => {});
                }
            }

            if (!isTerrainActive) {
                setBillboardIdCount(copyMannets.length + 1);
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mannets])

    useEffect(() => {
        if (showFilter) {
            // fetch Mesh Networks
            dispatch(handleFetchMannetNetwork());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showFilter])


    /**
     * ==========================================================================================
     * FUNCTION
     * ==========================================================================================
     */

    const createBillboardAndVLine = (mannet, i, viewer, terrainHeight = 0) => {
        let hTemp;
        let latitude;
        let longitude;
        if (mannet.request.receiver.alt > mannet.request.transmitter.alt) {
            latitude = mannet.request.receiver.lat;
            longitude = mannet.request.receiver.lon;
            hTemp = mannet.request.receiver.alt + terrainHeight;
        } else {
            latitude = mannet.request.transmitter.lat;
            longitude = mannet.request.transmitter.lon;
            hTemp = mannet.request.transmitter.alt + terrainHeight;
        }

        const billId = `bill-${i + 1}`
        const vLineId = `v-${i + 1}`
        // create billboard
        dispatch(handleCreateBillboard(viewer, Number(latitude), Number(longitude), hTemp, billId, i + 1));
        // create vertical Line
        dispatch(handleCreateVerticalLine(viewer, Number(latitude), Number(longitude), hTemp, vLineId));

        mannet.request.uuid = mannet.id;
        dispatch(handleCopyOriginalPayload(mannet.request));
    };

    const hideDbmLabel = () => {
        if (dbmLabelData) {
            dbmLabelData.forEach(e => {
                if (e && e.show) {
                    e.show = false
                }
            });
        }
    };
    const removeOriginalPayloadByIndex = (index) => {
        const copyOriginalPayloadArray = JSON.parse(JSON.stringify(originalPayloadArray));
        if (index > -1 && copyOriginalPayloadArray.length) {
            if (copyOriginalPayloadArray[index].uuid) {
                dispatch(handleDeleteMannet(copyOriginalPayloadArray[index].uuid));
            }
            copyOriginalPayloadArray[index] = null;
            dispatch(handleModifyOriginalPayload(copyOriginalPayloadArray));
        }
    };
    const removeBillBoardAndVLineDataByIndex = index => {
        const copyBillboardDataArray = JSON.parse(JSON.stringify(billboardData));
        const copyVerticalLineDataArray = JSON.parse(JSON.stringify(verticalLineData));
        if (index > -1 && copyBillboardDataArray.length &&
            copyVerticalLineDataArray.length) {

            copyBillboardDataArray[index] = null
            copyVerticalLineDataArray[index] = null;
            dispatch(handleModifyBillboardAndVLineData({
                billboardData: copyBillboardDataArray, verticalLineData: copyVerticalLineDataArray
            }));
        }
    };

    const handleRemoveEntity = () => {
        const viewer = ref.current.cesiumElement;

        const numberId = getNumberIdEntity(selectedId);
        const billId = `bill-${numberId}`

        const billboardEntity = viewer.entities.getById(billId);
        const vLineDataSource = vLineDataSources[numberId - 1];

        if (billboardEntity && vLineDataSource) {
            viewer.entities.remove(billboardEntity);
            vLineDataSource.then(ds => {
                if (ds) {
                    viewer.dataSources.remove(ds);
                }
            })
        }

        removeOriginalPayloadByIndex(numberId - 1);
        removeBillBoardAndVLineDataByIndex(numberId - 1);

        setOpen(false);
        setInfoDetailEntity(null);
    }

    /**
     * Handle dispatch Mesh Simualtion
     */
    const handleDispatchMeshSimulation = () => {
        const copyOriginalPayloadArrayTmp = JSON.parse(JSON.stringify(originalPayloadArray));
        let copyOriginalPayloadArray = [];
        // filter null data
        copyOriginalPayloadArrayTmp.forEach(data => {
            if (data) {
                copyOriginalPayloadArray.push(data);
            }
        })

        const copyOriginal = JSON.parse(JSON.stringify(copyOriginalPayloadArray));

        if (copyOriginal && copyOriginal.length >= 2) {
            const firstKinetic = {
                lat: copyOriginal[0].transmitter.lat,
                lon: copyOriginal[0].transmitter.lon,
                alt: copyOriginal[0].transmitter.alt
            }

            const secondKinetic = {
                lat: copyOriginal[1].transmitter.lat,
                lon: copyOriginal[1].transmitter.lon,
                alt: copyOriginal[1].transmitter.alt
            }

            for (let i = 0; i < copyOriginalPayloadArray.length; i++) {
                if (i === 0) {
                    copyOriginalPayloadArray[i].transmitter.lat = secondKinetic.lat;
                    copyOriginalPayloadArray[i].transmitter.lon = secondKinetic.lon;
                    copyOriginalPayloadArray[i].transmitter.alt = secondKinetic.alt;
                    const points = []
                    for (let j = 0; j < copyOriginalPayloadArray.length - 1; j++) {
                        points.push({
                            lat: copyOriginal[j + 1].transmitter.lat,
                            lon: copyOriginal[j + 1].transmitter.lon,
                            alt: copyOriginal[j + 1].transmitter.alt
                        });
                    }
                    copyOriginalPayloadArray[i].points = points;
                } else {
                    copyOriginalPayloadArray[i].transmitter.lat = firstKinetic.lat;
                    copyOriginalPayloadArray[i].transmitter.lon = firstKinetic.lon;
                    copyOriginalPayloadArray[i].transmitter.alt = firstKinetic.alt;
                    const points = []
                    for (let j = 0; j < i; j++) {
                        points.push({
                            lat: copyOriginal[j].transmitter.lat,
                            lon: copyOriginal[j].transmitter.lon,
                            alt: copyOriginal[j].transmitter.alt
                        });
                    }
                    for (let j = i + 1; j < copyOriginal.length; j++) {
                        points.push({
                            lat: copyOriginal[j].transmitter.lat,
                            lon: copyOriginal[j].transmitter.lon,
                            alt: copyOriginal[j].transmitter.alt
                        });
                    }
                    copyOriginalPayloadArray[i].points = points;
                }
            }

            // Refresh entities coz of changed entity
            refreshEntities();

            // request progress
            if (copyOriginalPayloadArray.length) {
                dispatch(handleSetTotalProgress(copyOriginalPayloadArray.length * (copyOriginalPayloadArray.length - 1)));
                dispatch(handleIsLoading(true));
            }
            copyOriginalPayloadArray.forEach(data => {
                if (data) {
                    dispatch(handleCreatePoint(data));
                }
            });

        } else if (copyOriginal.length < 2) {
            refreshEntities();
        }
    };


    const refreshEntities = () => {
        const viewer = ref.current.cesiumElement;
        hideDbmLabel();

        // viewer.entities.remove()
        if (hLineDataSources) {
            hLineDataSources.forEach(d => {
                if (d) {
                    d.then(ds => viewer.dataSources.remove(ds));
                }
            });
        }
    };

    const resetPositions = () => {
        setCount(0);
        setPreviousVLinePosition(null);
        setCurrentVLinePosition(null);
        setPreviousBillboardPosition(null);
        setCurrentBillboardPosition(null);
    };
    const backToPreviousPosition = (prevBillPosTmp, billboardEntity, prevVLinePosTmp, index, vLineId) => {
        if (billboardEntity && billboardEntity.position && prevBillPosTmp) {
            billboardEntity.position = prevBillPosTmp;
        }
        if (prevVLinePosTmp) {
            vLineDataSources[index].then(ds => {
                let vLineEntity = ds.entities.getById(vLineId);
                if (vLineEntity) {
                    vLineEntity.polyline.positions.setValue(prevVLinePosTmp);
                }
            });
        }
        // modify Original Array
        if (prevBillPosTmp) {
            const copyOriginalPayloadArray = JSON.parse(JSON.stringify(originalPayloadArray));

            const cartographic = Ellipsoid.WGS84.cartesianToCartographic(prevBillPosTmp.getValue(JulianDate));
            const newLongitude = parseFloat(radsToDegs(cartographic.longitude).toFixed(8));
            const newLatitude = parseFloat(radsToDegs(cartographic.latitude).toFixed(8));
            if (originalPayloadArray[index]) {
                const copyAPayload = JSON.parse(JSON.stringify(originalPayloadArray[index]));
                copyAPayload.transmitter.lat = newLatitude;
                copyAPayload.transmitter.lon = newLongitude;

                copyAPayload.receiver.lat = newLatitude;
                copyAPayload.receiver.lon = newLongitude;

                copyOriginalPayloadArray[index] = copyAPayload;
                dispatch(handleModifyOriginalPayload(copyOriginalPayloadArray));
            }
        }
    };

    const handleRegisterDragAction = (entityId, setPreviousPosition) => {
        const viewer = ref.current.cesiumElement;

        // temporary variable
        let currBillPosTmp = currentBillboardPosition;
        let prevBillPosTmp = previousBillboardPosition;
        let currVLinePosTmp = currentVLinePosition;
        let prevVLinePosTmp = previousVLinePosition;

        if (dragHandler && viewer) {
            dragHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOWN);
            dragHandler.removeInputAction(ScreenSpaceEventType.LEFT_UP);
            dragHandler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
            const billId = `bill-${entityId}`
            const vLineId = `v-${entityId}`
            const numberId = getNumberIdEntity(selectedId);

            // get Entity billboard
            const billboardEntity = viewer.entities.getById(billId);

            // Setup required variables
            const index = entityId - 1;
            let dragging = false;


            const scene = viewer.scene;
            let mousePosition = new Cartesian3();

            if (setPreviousPosition) {
                backToPreviousPosition(prevBillPosTmp, billboardEntity, prevVLinePosTmp, index, vLineId);
            } else {
                /**
                 * Handle MOUSE_DOWN when change entity position
                 */
                dragHandler.setInputAction(
                    click => {
                        let pickedObject = scene.pick(click.position);
                        if (defined(pickedObject) && (numberId === entityId)) {
                            dragging = true;
                            scene.screenSpaceCameraController.enableRotate = false;

                            const ray = viewer.camera.getPickRay(click.position);
                            mousePosition = scene.globe.pick(ray, viewer.scene);
                            const mousePositionProperty = new CallbackProperty((time, result) => {
                                const cartographic = Cartographic.fromCartesian(mousePosition);
                                return Ellipsoid.WGS84.cartographicToCartesian(cartographic);
                            }, false);

                            if (count === 0) {
                                setPreviousBillboardPosition(billboardEntity.position);
                                setCurrentBillboardPosition(mousePositionProperty);

                                prevBillPosTmp = billboardEntity.position;
                                currBillPosTmp = mousePositionProperty;
                            } else if (count === 1) {
                                setCurrentBillboardPosition(mousePositionProperty);
                                currBillPosTmp = mousePositionProperty;
                            } else {
                                setPreviousBillboardPosition(currentBillboardPosition);
                                setCurrentBillboardPosition(mousePositionProperty);

                                prevBillPosTmp = currentBillboardPosition;
                                currBillPosTmp = mousePositionProperty;
                            }
                            billboardEntity.position = currBillPosTmp;

                            vLineDataSources[index].then(ds => {
                                let vLineEntity = ds.entities.getById(vLineId);
                                if (vLineEntity) {
                                    const cartographic = Ellipsoid.WGS84.cartesianToCartographic(mousePositionProperty.getValue(JulianDate));
                                    const longitude = parseFloat(radsToDegs(cartographic.longitude).toFixed(8));
                                    const latitude = parseFloat(radsToDegs(cartographic.latitude).toFixed(8));
                                    const vLinePositions = Cartesian3.fromDegreesArrayHeights([
                                        longitude, latitude, 0.0,
                                        longitude, latitude, mousePositionProperty.getValue(JulianDate).height,
                                    ]);

                                    if (count === 0) {
                                        setPreviousVLinePosition(vLineEntity.polyline.positions.getValue(JulianDate));
                                        setCurrentVLinePosition(vLinePositions);

                                        prevVLinePosTmp = vLineEntity.polyline.positions.getValue(JulianDate);
                                        currVLinePosTmp = vLinePositions;
                                    } else if (count === 1) {
                                        setCurrentVLinePosition(vLinePositions);
                                        currVLinePosTmp = vLinePositions;
                                    } else {
                                        setPreviousVLinePosition(currentVLinePosition);
                                        setCurrentVLinePosition(vLinePositions);

                                        prevVLinePosTmp = currentVLinePosition;
                                        currVLinePosTmp = vLinePositions;
                                    }

                                    vLineEntity.polyline.positions.setValue(currVLinePosTmp);
                                }
                            });
                        }
                    },
                    ScreenSpaceEventType.LEFT_DOWN
                );

                dragHandler.setInputAction(
                    movement => {
                        if (dragging) {

                            const ray = viewer.camera.getPickRay(movement.endPosition);
                            const mypos = scene.globe.pick(ray, scene);

                            const cartographicPosition = Cartographic.fromCartesian(mypos);
                            cartographicPosition.height += originalPayloadArray[index].receiver.alt > originalPayloadArray[index].transmitter.alt ?
                                originalPayloadArray[index].receiver.alt : originalPayloadArray[index].transmitter.alt;
                            billboardEntity.position = Ellipsoid.WGS84.cartographicToCartesian(cartographicPosition);

                            vLineDataSources[index].then(ds => {
                                let vLineEntity = ds.entities.getById(vLineId);
                                if (vLineEntity) {

                                    vLineEntity.polyline.positions.setValue(Cartesian3.fromRadiansArrayHeights([
                                        cartographicPosition.longitude, cartographicPosition.latitude, 0,
                                        cartographicPosition.longitude, cartographicPosition.latitude, cartographicPosition.height
                                    ]));
                                }
                            });
                        }
                    },
                    ScreenSpaceEventType.MOUSE_MOVE
                );

                /**
                 * Handle MOUSE_UP when change entity position
                 */
                dragHandler.setInputAction((event) => {
                        if (dragging) {
                            dragging = false;
                            scene.screenSpaceCameraController.enableRotate = true;
                            const copyOriginalPayloadArray = JSON.parse(JSON.stringify(originalPayloadArray));
                            const copyAPayload = JSON.parse(JSON.stringify(originalPayloadArray[index]));
                            const ray = viewer.camera.getPickRay(event.position);
                            const mypos = scene.globe.pick(ray, scene);
                            if (defined(mypos)) {
                                const cartographicPosition = Cartographic.fromCartesian(mypos);
                                cartographicPosition.height += originalPayloadArray[index].receiver.alt > originalPayloadArray[index].transmitter.alt ?
                                    originalPayloadArray[index].receiver.alt : originalPayloadArray[index].transmitter.alt;

                                billboardEntity.position = Ellipsoid.WGS84.cartographicToCartesian(cartographicPosition);
                                vLineDataSources[index].then(ds => {
                                    let vLineEntity = ds.entities.getById(vLineId);
                                    if (vLineEntity) {

                                        vLineEntity.polyline.positions.setValue(Cartesian3.fromRadiansArrayHeights([
                                            cartographicPosition.longitude, cartographicPosition.latitude, 0,
                                            cartographicPosition.longitude, cartographicPosition.latitude, cartographicPosition.height
                                        ]));
                                    }
                                });
                                // modify Original Array

                                const newLatitude = parseFloat(radsToDegs(cartographicPosition.latitude).toFixed(8));
                                const newLongitude = parseFloat(radsToDegs(cartographicPosition.longitude).toFixed(8));


                                copyAPayload.transmitter.lat = newLatitude;
                                copyAPayload.transmitter.lon = newLongitude;

                                copyAPayload.receiver.lat = newLatitude;
                                copyAPayload.receiver.lon = newLongitude;

                                copyOriginalPayloadArray[index] = copyAPayload;
                                timeOut();
                                dispatch(handleModifyOriginalPayload(copyOriginalPayloadArray));

                                if (loadedMeshData && copyAPayload.uuid) {
                                    const mannetPayload = {
                                        request: copyAPayload
                                    };
                                    dispatch(handleUpdateMannet(copyAPayload.uuid, mannetPayload));
                                }
                            }
                        }
                    },
                    ScreenSpaceEventType.LEFT_UP
                );
            }
        }
    }

    const resetDragHandler = () => {
        if (dragHandler) {
            dragHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOWN);
            dragHandler.removeInputAction(ScreenSpaceEventType.LEFT_UP);
            dragHandler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);

            setDragHandler(new ScreenSpaceEventHandler(ref.current.cesiumElement.scene.canvas));
        }
    }

    const setDefaultState = () => {
        dispatch(handleClearDataStorage());
        setData(null);
        setNodes(0);
        setMean(0);
        setBillboardIdCount(1);
        setInfoDetailEntity(null);
        setSelectedNumberId(0);
        resetPositions();

        if (ref && ref.current && ref.current.cesiumElement) {
            const viewer = ref.current.cesiumElement;
            if (mouseMoveListener) {
                // mouseMoveListener.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
                if (viewer.entities.getById("ms")) {
                    viewer.entities.removeById("ms");
                }
            }

            // dispatch(handleMouseMoveEventToGetPosition(viewer));
            dispatch(handleWhiteTowerToggle(false))
            dispatch(handleRedTowerToggle(false))
        }
    }

    const timeOut = () => {
        setTimeout(() => {
            dispatch(handleIsLoading(false));
        }, 30000);
    };

    /**
     * Handle Create point
     * @param point data or information from righ panel
     */
    const handleMannet = async (point, onTrigger, reject) => {

        await onTrigger();

        if (point && handleValidationMeshSimulation(point, reject) && kineticPoint && selectedId === null) {
            const viewer = ref.current.cesiumElement;
            const {latitude, longitude, altitude} = kineticPoint

            const copyPoint = JSON.parse(JSON.stringify(point));
            copyPoint.transmitter.lat = latitude;
            copyPoint.transmitter.lon = longitude;
            copyPoint.receiver.lat = latitude;
            copyPoint.receiver.lon = longitude;
            copyPoint.model.rel = Math.round(copyPoint.model.rel);
            timeOut();

            // copy original input (for detail when billboard has been clicked)
            dispatch(handleCopyOriginalPayload(copyPoint));

            // save mannet
            const mannetPayload = {
                network: copyPoint.network,
                seqNumber: billboardIdCount,
                request: copyPoint
            }
            dispatch(handleCreateMannet(mannetPayload));

            const rxAlt = point.receiver.alt + altitude;
            const txAlt = copyPoint.transmitter.alt + altitude;

            const highest = rxAlt > txAlt ? rxAlt : txAlt;
            const billId = `bill-${billboardIdCount}`
            const vLineId = `v-${billboardIdCount}`

            dispatch(handleCreateBillboard(viewer, Number(latitude), Number(longitude), highest, billId, billboardIdCount));
            // create vertical Line
            dispatch(handleCreateVerticalLine(viewer, Number(latitude), Number(longitude), highest, vLineId));

            setBillboardIdCount(billboardIdCount + 1);
        }
    }

    const handleLoadRadioOrAntenna = (type) => {
        setShowRadioAntena(!showRadioAntena);
        if (type === `radio`) {
            setLoadMode('radio');
        } else {
            setLoadMode('antenna');
        }
    }

    const handleSearchRadioByName = (e) => {
        e.preventDefault();
        if (loadMode === `radio`) {
            dispatch(handleSearchRadio(searchText.toLowerCase(), "name", 0, 10, currentUser.siteId));
        } else {
            dispatch(handleSearchAntenna(searchText.toLowerCase(), "name", 0, 10, currentUser.siteId));
        }
    };

    const handlePageNumber = (e) => {
        if (loadMode === `radio`) {
            dispatch(handleSearchRadio(searchText.toLowerCase(), "name", e.selected, 10, currentUser.siteId));
        } else {
            dispatch(handleSearchAntenna(searchText.toLowerCase(), "name", e.selected, 10, currentUser.siteId));
        }
    };

    const handleSelectedRow = (id) => {
        if (loadMode === "radio") {
            setIdRadio(id);
        } else {
            setIdAntenna(id);
        }
    };

    const handleFillData = () => {
        if (loadMode === "radio") {
            if (idRadio !== "") {
                dispatch(handleGetRadioById(idRadio));
                setIdRadio("");
                setShowRadioAntena(!showRadioAntena);
            }
        } else {
            if (idAntenna !== "") {
                dispatch(handleGetAntennaById(idAntenna));
                setIdAntenna("");
                setShowRadioAntena(!showRadioAntena);
            }
        }

    }

    const handleClose = () => {
        setShowRadioAntena(false);
    };

    const onSearchChange = (e) => {
        setSearchText(e.target.value)
    }
    //
    // const setTxPosition = (latitude, longitude, altitude) => {
    //     const viewer = ref.current.cesiumElement;
    //     dispatch(setOnClickPosition(viewer, latitude, longitude, altitude));
    // }

    const downloadChart = useCallback(() => {
        return ref2.current.toBase64Image();
    }, []);

    const handleCloseFilter = () => {
        dispatch(handleCloseToggleFilter());
    };

    /**
     * When the user clicks the button, the function will fetch data from the server and then close the
     * modal.
     */
    const handleFilterResult = () => {
        timeOut();
        setLoadedMeshData(true);

        const viewer = ref.current.cesiumElement;
        dispatch(removeAllEntites(viewer));
        setDefaultState();

        dispatch(handleFetchMannetByNetwork(networks.value));
        handleCloseFilter();
    };

    const FilterModal = () => {
        return (
            <>
                <Modals
                    open={showFilter}
                    onClose={handleCloseFilter}
                    onSave={handleFilterResult}
                    title="Filter Simulation by"
                    size='w-72'
                    height="h-[500px]"
                >
                    <select
                        className="select select-sm select-ghost w-full max-w-xs text-white mb-3"
                        aria-label=".form-select-sm example"
                        value="Network"
                    >
                        <option value="network">Mesh Networks</option>
                    </select>
                    <div className="my-5">
                        <>
                            <Select
                                styles={customStyles}
                                onChange={(d) => setNetworks(d)}
                                options={mannetNetworks ? mannetNetworks : null}
                                value={networks}
                            />
                        </>
                    </div>
                </Modals>
            </>
        );
    };

    return (
        <div className="relative">
            <div
                style={{
                    height: "calc(100% - 77px)",
                }}
            >
                {isLoading && (
                    <div className="h-full w-screen bg-black flex justify-center items-center opacity-40 absolute z-10">
                        <FadeLoader color={'white'} loading={isLoading} size={150}/>
                    </div>)}
                <div className="flex flex-row">
                    <MapViewer ref={ref} isOpen={isOpen}>
                        <div>
                            <FilterToolbar/>
                            <Recycle callBackClearMap={setDefaultState}/>
                            <WhiteTower/>
                            <RedTower/>
                        </div>
                    </MapViewer>
                    <FilterModal/>
                    <RightPannel
                        analyzeType={ANALYZE_TYPE.MESH_SIMULATION}
                        handleLoadRadioOrAntenna={handleLoadRadioOrAntenna}
                        detailRadio={detailRadio}
                        detailAntenna={detailAntenna}
                        // setTxPosition={setTxPosition}
                        handleMannet={handleMannet}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        chartImage={downloadChart}
                        originalPayloadArray={originalPayloadArray}
                        meshMean={mean}
                    />
                </div>

                <ShowPannel
                    isOpen={isOpen}
                    setIsOpen={() => {
                        setIsOpen(prevIsOpen => !prevIsOpen)
                    }}
                />

                <Modals
                    open={showRadioAntena}
                    onClose={handleClose}
                    onSave={handleFillData}
                    btnCloseTitle="Cancel"
                    btnSubmitTitle="Load Data"
                    title={loadMode === 'radio' ? 'Radio' : 'Antena'}
                    size="w-350"
                    height="h-3/4"
                    isBlack={false}
                >
                    {handleHeaderModalRadioOrAntenna(searchText, loadMode, onSearchChange, handleSearchRadioByName)}
                    {handleModalLoadRadioOrAntenna(loadMode, radioData, handleSelectedRow, handlePageNumber, antennaData)}
                </Modals>
            </div>

            {open && infoDetailEntity && (
                <div
                    className="bg-[#242627] absolute top-8 left-8 rounded-lg w-[350px] h-[630px] overflow-auto text-white p-5">
                    <button
                        className="btn btn-sm btn-circle absolute right-2 top-2"
                        onClick={() => setOpen(false)}
                        type="button"
                    >
                        ✕
                    </button>

                    <h1 className="font-bold mb-3">{infoDetailEntity.name}</h1>

                    <div className="flex justify-between">
                        <p>Latitude</p>
                        <p>{infoDetailEntity.transmitter.lat}</p>
                    </div>
                    <div className="flex justify-between">
                        <p>Longitude</p>
                        <p>{infoDetailEntity.transmitter.lon}</p>
                    </div>

                    <h3 className="font-bold mt-2">Site / TX</h3>
                    <div className="flex justify-between">
                        <p>Height AGL</p>
                        <p>{`${infoDetailEntity.transmitter.alt} m`}</p>
                    </div>

                    <h3 className="font-bold mt-2">Signal</h3>
                    <div className="flex justify-between">
                        <p>Frequency</p>
                        <p>{`${infoDetailEntity.transmitter.frq} MHz`}</p>
                    </div>
                    <div className="flex justify-between">
                        <p>RF Power</p>
                        <p>{`${infoDetailEntity.transmitter.txw} W`}</p>
                    </div>
                    <div className="flex justify-between">
                        <p>Bandwidth</p>
                        <p>{`${infoDetailEntity.transmitter.bwi} MHz`}</p>
                    </div>

                    <h3 className="font-bold mt-2">Antenna</h3>
                    <div className="flex justify-between">
                        <p>Pattern</p>
                        <p>{infoDetailEntity.model.pm && typeof infoDetailEntity.model.pm === "number" ? patterns[infoDetailEntity.model.pm - 1].label : infoDetailEntity.model.pm}</p>
                    </div>
                    <div className="flex justify-between">
                        <p>Polarization</p>
                        <p>{infoDetailEntity.antenna.pol === "h" ? "Horizontal" : "Vertical"}</p>
                    </div>
                    <div className="flex justify-between">
                        <p>Gain</p>
                        <p>{`${infoDetailEntity.antenna.txg} dBi`}</p>
                    </div>
                    <div className="flex justify-between">
                        <p>Azimuth</p>
                        <p>{`${infoDetailEntity.antenna.azi} `}&#176;</p>
                    </div>
                    <div className="flex justify-between">
                        <p>Tilt</p>
                        <p>{`${infoDetailEntity.antenna.tlt} `}&#176;</p>
                    </div>

                    <h3 className="font-bold mt-2">Mobile / Rx</h3>
                    <div className="flex justify-between">
                        <p>Height AGL</p>
                        <p>{`${infoDetailEntity.receiver.alt} meter`}</p>
                    </div>
                    <div className="flex justify-between">
                        <p>Receiver Gain</p>
                        <p>{`${infoDetailEntity.receiver.rxg} dBi`}</p>
                    </div>
                    <div className="flex justify-between">
                        <p>Sensitivity</p>
                        <p>{`${infoDetailEntity.receiver.rxs} dBm`}</p>
                    </div>
                    <div className="flex justify-between">
                        <p>Noise Floor</p>
                        <p>{`${infoDetailEntity.output.nf} dBm`}</p>
                    </div>
                    <button
                        className="btn btn-error text-white min-w-[100px] absolute right-2 bottom-2"
                        onClick={handleRemoveEntity}
                        type="button"
                    >
                        Remove
                    </button>
                </div>
            )}

            {data && (
                <div
                    className="bg-[#242627] absolute bottom-8 left-8 rounded-lg w-[350px] h-[200px] text-white p-5">
                    <div>
                        <h3>{nodes} Nodes</h3>
                        <p>Mean: {mean} dBm</p>
                    </div>
                    <div className="flex mt-5">
                        <div className="w-3/5">
                            <Bar ref={ref2} options={options} data={data}/>
                        </div>
                        <div className="ml-8 text-sm self-center">
                            <p>A: &gt; -80</p>
                            <p>B: -90 to -80</p>
                            <p>C: &lt; -90</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Mesh
