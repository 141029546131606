import { CREATE_FREQUENCY_ALLOCATION, DELETE_FREQUENCY_ALLOCATION_BY_ID, SEARCH_FREQUENCY_ALLOCATION, UPDATE_FREQUENCY_ALLOCATION } from "../../services/frequencyallocation";
import { STATUS_TYPE } from "../../utils/constant/constants";
import { createAction } from "../../utils/reducer/reducerUtil";
import { handleClearStateGlobal, handleLoading } from "../global/global.action";
import { GLOBAL_ACTION_TYPES } from "../global/global.types";
import { FREQUENCY_ALLOCATION_ACTION_TYPES } from "./frequencyallocation.types";
import {defined} from "cesium";
import {createCircleEntity, removeInCircleEntity} from "../../utils/cesium/cesiumUtils";

export const handleClearNotif = () => dispatch => {
    dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.UPLOAD_FREQUENCY_ALLOCATION_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.UPDATED, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.REMOVED, STATUS_TYPE.NO_STATEMENT));
}

export const handleClearData = () => (dispatch) => {
    dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.CLEAR_DATA))
}

export const handleSaveFrequencyAllocation = (data, siteId) => async (dispatch) => {

    const payload = {
        usage: data.usage,
        frequency: data.freq,
        frequencyMax: data.freqMax ? data.freqMax : data.freq,
        lat: data.lat,
        lon: data.lon,
        type: data.type,
        color: data.color,
        siteId: siteId,
        editable: true,
    };

    CREATE_FREQUENCY_ALLOCATION(payload).then(() => {
        dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.UPLOAD_FREQUENCY_ALLOCATION_STATUS, STATUS_TYPE.SUCCESS))
        // dispatch(handleSearchFrequencyAllocation("", "", 0, 10, siteId));
    }).catch(e => {
        dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.UPLOAD_FREQUENCY_ALLOCATION_STATUS, STATUS_TYPE.FAILED))
    });
    dispatch(handleClearStateGlobal());
}

export const handleSearchFrequencyAllocation = (q, type, page, size, siteId, cellSize, id) => dispatch => {
    SEARCH_FREQUENCY_ALLOCATION(q, type, page, size, siteId, cellSize, id)
        .then(res => {
            dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.SEARCH, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        })

};

export const handleUpdateFrequencyAllocation = (id, data, siteId, page) => async (dispatch) => {

    const payload = {
        usage: data.usage,
        frequency: data.freq,
        frequencyMax: data.freqMax ? data.freqMax : data.freq,
        lat: data.lat,
        lon: data.lon,
        type: data.type,
        color: data.color,
        siteId: siteId,
        editable: true,
    };

    UPDATE_FREQUENCY_ALLOCATION(id, payload)
        .then(() => {
            dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.UPDATED, STATUS_TYPE.SUCCESS));
        })
        .catch(() => {
            dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.UPDATED, STATUS_TYPE.FAILED));
        })
    dispatch(handleClearStateGlobal());
};

export const handleRemoveFrequencyAllocation = (id, siteId, page, type) => async (dispatch) => {
    DELETE_FREQUENCY_ALLOCATION_BY_ID(id)
        .then(() => {
            dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.REMOVED, STATUS_TYPE.SUCCESS))
            dispatch(handleClearStateGlobal());
        })
        .catch(() => {
            dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.REMOVED, STATUS_TYPE.FAILED))
            dispatch(handleClearStateGlobal());
        })
}

export const handleRegisterClickListener = (viewer) => (dispatch) => {
    const addEventListener = viewer.selectedEntityChanged.addEventListener(selectedEntity => {
        if (defined(selectedEntity)) {
            if (defined(selectedEntity.id)) {
                dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.SELECTED_SITE_ID, selectedEntity.id));
            }
        }
    });
    dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.REGISTER_HANDLER_LISTENER, addEventListener))
}

export const handleRegisterSiteEntities = (siteEntities) => dispatch => {
    dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.SITE_ENTITIES, siteEntities));
}

export const handleRemoveSiteEntities = () => dispatch => {
    dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.SITE_ENTITIES, null));
}

export const handleRegisterCirclePrimitive = (id, viewer, lat, lon, radius) => dispatch => {
    const entity = createCircleEntity(id, viewer, lat, lon, radius);
    dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.CIRCLE_ENTITY, entity));
}
export const handleRemoveCirclePrimitive = (circleEntity, viewer) => dispatch => {
    removeInCircleEntity(circleEntity, viewer);
    dispatch(createAction(FREQUENCY_ALLOCATION_ACTION_TYPES.CIRCLE_ENTITY, null));
}