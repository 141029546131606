import * as React from "react";

const SupportingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1126 1126"
    {...props}
  >
    <g fill="currentColor">
      <g stroke="#FEFEFE" strokeWidth={5.845}>
        <path d="M350 126c51 27 86 81 86 143 0 19-3 37-9 54l533 489 7 7c41 41 41 108 0 150-42 41-109 41-150 0l-7-7-493-538c-13 3-27 5-41 5-89 0-161-72-161-160 0-62 35-116 87-143v143l75 47 73-51V126zm502 728c22-22 57-22 79 0s22 57 0 79-57 22-79 0-22-57 0-79z" />
        <circle
          transform="scale(.20325) rotate(-45 7500.349 -3097.63)"
          r={277}
        />
        <path d="m511 448 309 309c17 18 17 47 0 65-18 17-47 17-65 0L446 512c-18-17-18-46 0-64s47-18 65 0z" />
      </g>
      <path
        stroke="currentColor"
        strokeWidth={0.779}
        d="M122 0h882c67 0 121 55 121 122v882c0 67-54 121-121 121H122c-67 0-122-54-122-121V122C0 55 55 0 122 0zm30 39h821c63 0 114 51 114 113v821c0 63-51 114-114 114H152c-62 0-113-51-113-114V152C39 90 90 39 152 39z"
      />
    </g>
  </svg>
);

export default SupportingIcon;
