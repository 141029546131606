import {
    CREATE_ELECTRONIC,
    DELETE_ELECTRONIC_BY_ID,
    GET_ELECTRONIC_BY_ID,
    SEARCH_ELECTRONIC,
    UPDATE_ELECTRONIC
} from "../../services/electronic";
import {createAction} from "../../utils/reducer/reducerUtil";
import {ELECTRONIC_ACTION_TYPES} from "./electronic.types";
import {GET_ASSET_ELECTRONIC, UPLOAD_FILE, UPLOAD_IMAGE} from "../../services/asset";
import {GET_ALL_SITE} from "../../services/site";
import {STATUS_TYPE} from "../../utils/constant/constants";
import {handleClearStateGlobal, handleLoading} from "../global/global.action";
import {GLOBAL_ACTION_TYPES} from "../global/global.types";


export const handleUploadFile = (item) => dispatch => {
    const formData = new FormData();
    formData.append("file", item);
    UPLOAD_FILE(formData)
        .then(res => {
            dispatch(createAction(ELECTRONIC_ACTION_TYPES.UPLOAD_FILE, res.id));
        })
        .catch(() => {});
}

export const handleUploadImage = (item) => dispatch => {
    const formData = new FormData();
    formData.append("file", item);
    UPLOAD_IMAGE(formData)
        .then(res => {
            dispatch(createAction(ELECTRONIC_ACTION_TYPES.UPLOAD_IMAGE, res.id));
        })
        .catch(() => {});
}

export const handleFetchStatusElectronic = () => async (dispatch) => {
    const res = await GET_ASSET_ELECTRONIC();
    if (res) {
        dispatch(createAction(ELECTRONIC_ACTION_TYPES.GET_STATUS, res));
    }
}

export const handleFetchSite = () => async (dispatch) => {
    const res = await GET_ALL_SITE();
    if (res && res.content.length > 0) {
        dispatch(createAction(ELECTRONIC_ACTION_TYPES.GET_SITE, res));
    }
}

export const handleClearData = () => (dispatch) => {
    dispatch(createAction(ELECTRONIC_ACTION_TYPES.CLEAR_DATA))
}

export const handleClearNotif = () => dispatch => {
    dispatch(createAction(ELECTRONIC_ACTION_TYPES.UPLOAD_ELECTRONIC_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(ELECTRONIC_ACTION_TYPES.REMOVED, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(ELECTRONIC_ACTION_TYPES.UPDATED, STATUS_TYPE.NO_STATEMENT));
}

export const handleSaveElectronic = (data,siteId) => async (dispatch) => {

    const payload = {
        name: data.name,
        lon: data.lon,
        lat: data.lat,
        phone: data.phone,
        address: data.address,
        type: {id: 7}
    };

    CREATE_ELECTRONIC(payload).then(() => {
        dispatch(createAction(ELECTRONIC_ACTION_TYPES.UPLOAD_ELECTRONIC_STATUS, STATUS_TYPE.SUCCESS))
    }).catch(e => {
        dispatch(createAction(ELECTRONIC_ACTION_TYPES.UPLOAD_ELECTRONIC_STATUS, STATUS_TYPE.FAILED))
    });
    dispatch(handleClearStateGlobal());
    dispatch(createAction(ELECTRONIC_ACTION_TYPES.IS_LOADING, false));

}

export const handleStartLoading = () => dispatch => {
    dispatch(createAction(ELECTRONIC_ACTION_TYPES.IS_LOADING, true));
}

export const handleRemoveElectronic = (id, siteId, page) => async (dispatch) => {
    dispatch(createAction(ELECTRONIC_ACTION_TYPES.IS_LOADING, true));
    DELETE_ELECTRONIC_BY_ID(id)
        .then(() => {
            dispatch(createAction(ELECTRONIC_ACTION_TYPES.REMOVED, STATUS_TYPE.SUCCESS))
            dispatch(handleClearStateGlobal());
        })
        .catch(() => {
            dispatch(createAction(ELECTRONIC_ACTION_TYPES.REMOVED, STATUS_TYPE.FAILED))
        })
    dispatch(createAction(ELECTRONIC_ACTION_TYPES.IS_LOADING, false));
}
export const handleUpdateElectronic = (id,data,siteId, page) => async (dispatch) => {

    const payload = {
        name: data.name,
        lon: data.lon,
        lat: data.lat,
        phone: data.phone,
        address: data.address,
        type: {id: 7}
    };

    UPDATE_ELECTRONIC(id, payload)
        .then(() => {
            dispatch(createAction(ELECTRONIC_ACTION_TYPES.UPDATED, STATUS_TYPE.SUCCESS));
        })
        .catch(() => {
            dispatch(createAction(ELECTRONIC_ACTION_TYPES.UPDATED, STATUS_TYPE.FAILED));
        })
    dispatch(handleClearStateGlobal());
    dispatch(createAction(ELECTRONIC_ACTION_TYPES.IS_LOADING, false));
};

export const handleSearchElectronic = (q, type, page, size, siteId) => dispatch => {
    SEARCH_ELECTRONIC(q, type, page, size, siteId)
        .then(res => {
            dispatch(createAction(ELECTRONIC_ACTION_TYPES.SEARCH, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        })
};

export const handleGetElectronicById = (id) => dispatch => {
    GET_ELECTRONIC_BY_ID(id)
        .then(res => {
            dispatch(createAction(ELECTRONIC_ACTION_TYPES.DETAIL_ELECTRONIC, res));
        })
        .catch(() => {});
};
