import {createSelector} from "reselect";

const selectGpsReducer = (state) => state.gpsReducer;

export const selectPosition = createSelector(
    [selectGpsReducer],
    (state) => {
        if (state.position) {
            return state.position
        } else {
            return null;
        }
    }
);
