/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {NavLink, useLocation} from "react-router-dom";
import {
    Antenna2Icon,
    ElectronicIcon,
    FiberOpticIcon,
    FrequencyAllocationIcon,
    GroundToAirIcon,
    GroundToGroundIcon,
    JammerIcon,
    LinkBudgetIcon,
    LogActivityIcon,
    MaintenanceDataIcon,
    MaintenanceHistoryIcon,
    MaintenanceScheduleIcon,
    MeshSimulationIcon,
    OperationPlanningIcon,
    OwnerTypeIcon,
    PabxIcon,
    RadioBrandIcon,
    RadioCategoryIcon,
    RadioIcon,
    RadioTypeIcon,
    SimulationIcon,
    SupportingIcon,
    TemplateMaintenanceIcon,
    TowerIcon
} from "../../assets/svgs";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { USER_MABES } from "../../utils/constant/constants";

const Sidebar = ({ isSidebarOpened, onCollapse}) => {
    const location = useLocation();
    const currentUser = useSelector(selectCurrentUser);

    const onMouseEnter = () => {
        onCollapse(true);
    };

    const onMouseLeave = () => {
        onCollapse(false);
    };

    const renderNavigation = (param) => {
        if (param === "/" || param.indexOf("dashboard") > -1) {
            return (
                <ul className="pt-10">
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/dashboard/gta"
                            className={`${
                                location.pathname === "/dashboard/gta"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <GroundToAirIcon
                                    stroke={
                                        location.pathname === "/dashboard/gta"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                GTA Coverage
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/dashboard/gtg"
                            className={`${
                                location.pathname === "/dashboard/gtg"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <GroundToGroundIcon
                                    stroke={
                                        location.pathname === "/dashboard/gtg"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                GTG Coverage
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/dashboard/jammer"
                            className={`${
                                location.pathname === "/dashboard/jammer"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <JammerIcon
                                    stroke={
                                        location.pathname === "/dashboard/jammer"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Jammer Coverage
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/dashboard/radiolink"
                            className={`${
                                location.pathname === "/dashboard/radiolink"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <LinkBudgetIcon
                                    stroke={
                                        location.pathname === "/dashboard/radiolink"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Radio Link Budget
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/dashboard/mesh"
                            className={`${
                                location.pathname === "/dashboard/mesh"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <MeshSimulationIcon
                                    stroke={
                                        location.pathname === "/dashboard/mesh"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Mesh Simulation
                            </span>
                        </NavLink>
                    </li>
                    {/* <li className="px-[28px] my-2">
                        <NavLink
                            to="/dashboard/frequency"
                            className={`${
                                location.pathname === "/dashboard/frequency"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <FrequencyAllocationIcon
                                    stroke={
                                        location.pathname === "/dashboard/frequency"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Frequency Allocation
                            </span>
                        </NavLink>
                    </li> */}
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/dashboard/planning"
                            className={`${
                                location.pathname === "/dashboard/planning"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <OperationPlanningIcon
                                    stroke={
                                        location.pathname === "/dashboard/planning"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Operation Planning
                            </span>
                        </NavLink>
                    </li>
                </ul>
            );
        } else if (param === "/assetdata" || param.indexOf("assetdata") > -1) {
            return (
                <ul className="pt-10">
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/assetdata/radio"
                            className={`${
                                location.pathname === "/assetdata/radio"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <RadioIcon
                                    stroke={
                                        location.pathname === "/assetdata/radio"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Radio
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/assetdata/antenna"
                            className={`${
                                location.pathname === "/assetdata/antenna"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <Antenna2Icon
                                    stroke={
                                        location.pathname === "/assetdata/antena"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Antenna
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/assetdata/tower"
                            className={`${
                                location.pathname === "/assetdata/tower"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <TowerIcon
                                    fill={
                                        location.pathname === "/assetdata/tower"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Tower
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/assetdata/pabx"
                            className={`${
                                location.pathname === "/assetdata/pabx"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <PabxIcon
                                    stroke={
                                        location.pathname === "/assetdata/pabx"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                PABX
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/assetdata/supporting"
                            className={`${
                                location.pathname === "/assetdata/supporting"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <SupportingIcon
                                    stroke={
                                        location.pathname === "/assetdata/supporting"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Supporting
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/assetdata/fo"
                            className={`${
                                location.pathname === "/assetdata/fo"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <FiberOpticIcon
                                    stroke={
                                        location.pathname === "/assetdata/fo"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                FO Distribution
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/assetdata/maintenance-data"
                            className={`${
                                location.pathname === "/assetdata/maintenance-data"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <MaintenanceDataIcon
                                    stroke={
                                        location.pathname === "/assetdata/maintenance-data"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Maintenance Data
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/assetdata/maintenance-schedule"
                            className={`${
                                location.pathname === "/assetdata/maintenance-schedule"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <MaintenanceScheduleIcon
                                    stroke={
                                        location.pathname === "/assetdata/maintenance-schedule"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Maintenance Schedule
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/assetdata/maintenance-history"
                            className={`${
                                location.pathname === "/assetdata/maintenance-history"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <MaintenanceHistoryIcon
                                    stroke={
                                        location.pathname === "/assetdata/maintenance-history"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Maintenance History
                            </span>
                        </NavLink>
                    </li>
                </ul>
            );
        } else if (
            param === "/usermanagement/administration" ||
            param.indexOf("administration") > -1
        ) {
            return (
                <ul className="pt-10">
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/usermanagement/administration"
                            className={`${
                                location.pathname === "/usermanagement/administration"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <SimulationIcon
                                    stroke={
                                        location.pathname === "/usermanagement/administration"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Simulation
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/usermanagement/administration/ownertype"
                            className={`${
                                location.pathname === "/usermanagement/administration/ownertype"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <OwnerTypeIcon
                                    stroke={
                                        location.pathname === "/usermanagement/administration/ownertype"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Site
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/usermanagement/administration/assetcategory"
                            className={`${
                                location.pathname === "/usermanagement/administration/assetcategory"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <RadioCategoryIcon
                                    stroke={
                                        location.pathname === "/usermanagement/administration/assetcategory"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Asset Category
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/usermanagement/administration/assetbrand"
                            className={`${
                                location.pathname === "/usermanagement/administration/assetbrand"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <RadioBrandIcon
                                    stroke={
                                        location.pathname === "/usermanagement/administration/assetbrand"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Asset Brand
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/usermanagement/administration/assettype"
                            className={`${
                                location.pathname === "/usermanagement/administration/assettype"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <RadioTypeIcon
                                    stroke={
                                        location.pathname === "/usermanagement/administration/assettype"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Asset Type
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/usermanagement/administration/template-maintenance"
                            className={`${
                                location.pathname === "/usermanagement/administration/template-maintenance"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <TemplateMaintenanceIcon
                                    stroke={
                                        location.pathname === "/usermanagement/administration/template-maintenance"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Template Maintenance
                            </span>
                        </NavLink>
                    </li>
                    {currentUser && currentUser.siteName === USER_MABES && (
                        <li className="px-[28px] my-2">
                            <NavLink
                                to="/usermanagement/administration/data-tower-server"
                                className={`${
                                    location.pathname === "/usermanagement/administration/data-tower-server"
                                        ? "bg-white text-black"
                                        : ""
                                } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                            >
                                <div className="inline-block pt-2">
                                    <TowerIcon
                                        stroke={
                                            location.pathname === "/usermanagement/administration/data-tower-server"
                                                ? "#000000"
                                                : "#FFFFFF"
                                        }
                                    />
                                </div>
                                <span
                                    className={`${
                                        isSidebarOpened
                                            ? "opacity-100"
                                            : "opacity-0 w-0 h-0 overflow-hidden"
                                    } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                                >
                                    Data Tower Server
                                </span>
                            </NavLink>
                        </li>
                    )}
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/usermanagement/administration/create-user"
                            className={`${
                                location.pathname === "/usermanagement/administration/create-user"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <TemplateMaintenanceIcon
                                    stroke={
                                        location.pathname === "/usermanagement/administration/create-user"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Create User
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/usermanagement/administration/user-manager"
                            className={`${
                                location.pathname === "/usermanagement/administration/user-manager"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <TemplateMaintenanceIcon
                                    stroke={
                                        location.pathname === "/usermanagement/administration/user-manager"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                User Manager
                            </span>
                        </NavLink>
                    </li>
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/usermanagement/administration/logactivity"
                            className={`${
                                location.pathname === "/usermanagement/administration/logactivity"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <LogActivityIcon
                                    stroke={
                                        location.pathname === "/usermanagement/administration/logactivity"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Log Activity
                            </span>
                        </NavLink>
                    </li>
                </ul>
            );
        } else if (
            param === "/usermanagement/profile" ||
            param.indexOf("profile") > -1
        ) {
            return "";
        } else if (
            param === "/usermanagement/about" ||
            param.indexOf("about") > -1
        ) {
            return "";
        } else if (
            param === "/operationdata" ||
            param.indexOf("operationdata") > -1
        ) {
            return "";
        } else {
            return (
                <ul className="pt-10">
                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/distribution/towercivil"
                            className={`${
                                location.pathname === "/distribution/towercivil"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <TowerIcon
                                    stroke={
                                        location.pathname === "/distribution/towercivil"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Tower Civil
                            </span>
                        </NavLink>
                    </li>

                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/distribution/electronicws"
                            className={`${
                                location.pathname === "/distribution/electronicws"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <ElectronicIcon
                                    fill={
                                        location.pathname === "/distribution/electronicws"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Electronic Workshop
                            </span>
                        </NavLink>
                    </li>

                    <li className="px-[28px] my-2">
                        <NavLink
                            to="/distribution/frequency-allocation"
                            className={`${
                                location.pathname === "/distribution/frequency-allocation"
                                    ? "bg-white text-black"
                                    : ""
                            } block font-semibold cursor-pointer whitespace-nowrap pl-[10px] rounded-md transition-all duration-200 ease-linear`}
                        >
                            <div className="inline-block pt-2">
                                <FrequencyAllocationIcon
                                    stroke={
                                        location.pathname === "/distribution/frequency-allocation"
                                            ? "#000000"
                                            : "#FFFFFF"
                                    }
                                />
                            </div>
                            <span
                                className={`${
                                    isSidebarOpened
                                        ? "opacity-100"
                                        : "opacity-0 w-0 h-0 overflow-hidden"
                                } transition-opacity duration-500 ease-in absolute inline-block ml-3 leading-[40px] text-sm`}
                            >
                                Frequency Allocation
                            </span>
                        </NavLink>
                    </li>
                </ul>
            );
        }
    };

    return (
        <div
            className={`${
                isSidebarOpened ? "w-[300px]" : "w-[100px]"
            } fixed bg-[#000046] h-[100%] box-border transition-width duration-500 ease-in-out text-white z-20`}
            onMouseOver={onMouseEnter}
            onMouseOut={onMouseLeave}
        >
            <main className="my-6 select-none">{renderNavigation(location.pathname)}</main>

            <div className={`${isSidebarOpened ? "absolute" : "hidden"} bottom-4 left-[50%] translate-x-[-50%] text-xs text-center select-none`}>
                <p>Powered by signalitix</p>
                <p className="mt-2">Version 1.0</p>
            </div>
        </div>
    );
};

export default Sidebar;
