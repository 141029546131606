import {RADIO_BAND} from "../../utils/constant/constants";

export function defineFreqType(freqMin, freqMax) {

    let type;

    if (freqMin >= RADIO_BAND.HF.min && freqMax <= RADIO_BAND.HF.max) {
        type = "HF";
    } else if (freqMin >= RADIO_BAND.VHF.min && freqMax <= RADIO_BAND.VHF.max) {
        type = "VHF";
    } else if (freqMin >= RADIO_BAND.UHF.min && freqMax <= RADIO_BAND.UHF.max) {
        type = "UHF";
    } else if (freqMin >= RADIO_BAND.VUHF.min && freqMax <= RADIO_BAND.VUHF.max) {
        type = "VUHF";
    } else if (freqMin >= RADIO_BAND.C_BAND.min && freqMax <= RADIO_BAND.C_BAND.max) {
        type = "C-BAND";
    }

    return type;
}

export function hexToRgb(hex) {
    // Remove the '#' character if it exists
    hex = hex.replace('#', '');

    // Convert the hex value to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Return the RGB color value as a string
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
}

export const validationFreq = (freqMin, freqMax) => {
    let validation = false;

    if ((freqMax && freqMin < freqMax) || (!freqMax && freqMin)) {
        if (freqMin >= RADIO_BAND.C_BAND.min && (!freqMax || freqMax <= RADIO_BAND.C_BAND.max)) {
            validation = true; // C-Band
        }

        if (freqMin >= RADIO_BAND.VUHF.min && (!freqMax || freqMax <= RADIO_BAND.VUHF.max)) {
            validation = true; // VHF/UHF
        }

        if (freqMin >= RADIO_BAND.HF.min && (!freqMax || freqMax <= RADIO_BAND.HF.max)) {
            validation = true; // HF
        }
    }

    return validation;
}

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",
    scales: {
        x: {
            stacked: true,
            ticks: {
                stepSize: 1,
            },
            title: {
                display: true,
                text: 'MHz',
                color: "white",
            }
        },
        y: {
            stacked: true,
            display: false
        }
    },
    plugins: {
        title: {
            display: true,
            text: 'Frequency Allocation'
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    return context.dataset.label || '';
                }
            }
        }
    },
};

const collectAndSortFreqIntoSet = (content, freqArr, freqMaxArr) => {
    for (let i = 0; i < content.length; i++) {
        freqArr.push(content[i].frequency);
        if (content[i].frequency === content[i].frequencyMax) {
            const signatureGap = content[i].frequencyMax + (getDouble(content[i].frequencyMax) / 391);
            freqArr.push(signatureGap); // 25 + (1 / 391) = 25.xxx
            freqMaxArr.push(signatureGap);
            content[i].frequencyMax = signatureGap
        } else {
            freqArr.push(content[i].frequencyMax);
            freqMaxArr.push(content[i].frequencyMax);
        }
    }
    const sortedArray = Array.from(freqArr).sort((a, b) => a - b);

    return [...new Set(sortedArray)];
};

const getDouble = digit => {
    let s = `0.${Math.round(digit)}`;
    return Number(s);
};

const createLabelChart = (freqSet, labelChart) => {
    for (let i = 0; i < freqSet.length; i++) {
        if (freqSet[i + 1]) {
            labelChart.push(`${freqSet[i]}-${freqSet[i + 1]}`);
        }
    }
};

const findHighestIntersectValue = (freqMaxArr, intersect, freqArr) => {
    let maxVal = 0;
    for (let i = 0; i < freqMaxArr.length; i++) {
        intersect[freqArr[i]] = (intersect[freqArr[i]] || 0) + 1;
    }
    for (let key in intersect) {
        if (intersect[key] > maxVal) {
            maxVal = intersect[key];
        }
    }
    return maxVal;
};

const fillDataRadio = (maxVal, slotCount, firstIndex, dataChart, content, contentIndex, bgChart) => {
    const slot = 1;

    for (let k = 0; k < slotCount; k++) {

        if (dataChart[`${content[contentIndex].usage}`] &&
            bgChart[`${content[contentIndex].usage}`]) {
            dataChart[`${content[contentIndex].usage}`][firstIndex + k] = slot;
            bgChart[`${content[contentIndex].usage}`][firstIndex + k] = content[contentIndex].color;
            bgChart[`${content[contentIndex].usage}`][0] = content[contentIndex].color;
        }
    }
    return firstIndex;
};

const createDataAllocation = (content, dataChart, labelChart, bgChart, maxVal) => {
    for (let i = 0; i < content.length; i++) {
        dataChart[`${content[i].usage}`] = new Array(labelChart.length).fill(0);
        bgChart[`${content[i].usage}`] = new Array(labelChart.length).fill('rgba(0, 0, 0, 0)')
    }

    if (labelChart.length) {
        for (let i = 0; i < content.length; i++) {
            let slotCount = 0;
            let firstIndex = 0;
            let firstIndexFilled = false;
            for (let j = 0; j < labelChart.length; j++) {
                const labelChartElement = labelChart[j];
                if (labelChartElement.includes("-")) {
                    let pos = labelChartElement.split("-");
                    let minLabel = pos[0];
                    let maxLabel = pos[1];

                    const gapDiff = getDouble(maxLabel);
                    const gapMaxLabel = (maxLabel - minLabel) * 391; // 25.xxx - 25 = 0.xxx     0.xxx * 391 = gapDiff
                    const compare = compareFractions(gapDiff, gapMaxLabel, 0.9);
                    if ((content[i].frequencyMax >= maxLabel && content[i].frequency <= minLabel) ||
                        (compare === 0 && i + 1 === content.length && j + 1 === labelChart.length)) { // last index slot data && last index data
                        slotCount += 1;
                        if (!firstIndexFilled) {
                            firstIndex = j;
                            firstIndexFilled = true;
                        }

                        if (j + 1 === labelChart.length) { // last index
                            fillDataRadio(maxVal, slotCount, firstIndex, dataChart, content, i, bgChart);
                        }
                    } else {
                        if (slotCount) {
                            fillDataRadio(maxVal, slotCount, firstIndex, dataChart, content, i, bgChart);
                        }
                    }
                }
            }
        }
    }
};

const columnCorrection = (labelChart, content, dataChart, maxVal) => {
    let colSlot = 0;
    const cpLabelArr = [];
    for (let i = 0; i < labelChart.length; i++) {
        for (let j = 0; j < content.length; j++) {
            const indexData = dataChart[content[j].usage][i];
            if (indexData) {
                colSlot += 1;
            }
        }
        cpLabelArr.push(colSlot);
        colSlot = 0;
    }
    for (let i = 0; i < cpLabelArr.length; i++) {
        cpLabelArr[i] = maxVal / cpLabelArr[i];
    }

    for (let i = 0; i < cpLabelArr.length; i++) {
        for (let j = 0; j < content.length; j++) {
            const indexData = dataChart[content[j].usage][i];
            if (indexData) {
                dataChart[content[j].usage][i] = cpLabelArr[i];
            }
        }
    }
};

const generateDatasets = (content, datasetArray, dataChart, bgChart) => {
    for (let i = 0; i < content.length; i++) {
        datasetArray.push({
            label: content[i].usage,
            data: dataChart[content[i].usage],
            borderWidth: 0,
            fill: false,
            barPercentage: 1.25,
            backgroundColor: bgChart[content[i].usage],
        },)
    }
};

const labelsCorrection = labelChart => {
    let maxTemp = -1;
    let minTemp = -1;
    for (let i = 0; i < labelChart.length; i++) {
        if (labelChart[i].includes("-")) {
            let pos = labelChart[i].split("-");
            let minLabel = pos[0];
            let maxLabel = pos[1];
            if (labelChart[i + 1] && labelChart[i + 1].includes("-")) {
                if (minLabel === maxTemp) { // run second

                    labelChart[i] = `${minTemp}-${maxLabel}`;
                }
                const gapDiff = getDouble(minLabel);
                const gapMaxLabel = (maxLabel - minLabel) * 391; // 4.xxx - 4 = 0.xxx     0.xxx * 391 = gapDiff
                const compare = compareFractions(gapMaxLabel, gapDiff, 0.001);
                if (compare === 0) { // run first
                    labelChart[i] = minLabel;
                    maxTemp = maxLabel;
                    minTemp = minLabel;
                }
            } else { // LAST
                if (minLabel === maxTemp) {
                    labelChart[i] = `${minTemp}-${maxLabel}`;
                } else {
                    const gapDiff = getDouble(Number(maxLabel));
                    const gapMaxLabel = (maxLabel - minLabel) * 391; // 25.xxx - 25 = 0.xxx     0.xxx * 391 = gapDiff
                    const compare = compareFractions(gapDiff, gapMaxLabel, 0.001);

                    if (compare === 0) {
                        labelChart[i] = `${minLabel}`; // last slot, WITH SINGLE FREQUENCY
                    } else {
                        labelChart[i] = `${minLabel}-${maxLabel}`; // last slot, WITH RANGE FREQUENCY
                    }

                }
            }
        }
    }
};

const compareFractions = (fraction1, fraction2, tolerance) => {
    // Menghitung selisih absolut antara dua pecahan
    const selisih = Math.abs(fraction1 - fraction2);

    // Membandingkan selisih dengan toleransi yang telah ditentukan
    if (selisih <= tolerance) {
        return 0; // Jika selisih kurang dari atau sama dengan toleransi, maka dua pecahan dianggap sama
    } else if (fraction1 < fraction2) {
        return -1; // Jika fraction1 kurang dari fraction2, return -1
    } else {
        return 1; // Jika fraction1 lebih dari fraction2, return 1
    }
};

export const handleChartData = (resp) => {

    const cpResp = JSON.parse(JSON.stringify(resp));
    const content = cpResp.content.slice().reverse();

    // collect freq and freqMax into array
    let freqArr = [];
    let freqMaxArr = [];
    const freqSet = collectAndSortFreqIntoSet(content, freqArr, freqMaxArr);

    // create label chart
    let labelChart = [];
    createLabelChart(freqSet, labelChart);

    // find higgest intersect value
    let intersect = {};
    const maxVal = findHighestIntersectValue(freqMaxArr, intersect, freqArr);

    // create data chart
    let dataChart = {}
    let bgChart = {}
    createDataAllocation(content, dataChart, labelChart, bgChart, maxVal);

    // coloumn correction
    columnCorrection(labelChart, content, dataChart, maxVal);

    // label correction
    labelsCorrection(labelChart);

    let result = {};
    let datasetArray = [];
    generateDatasets(content, datasetArray, dataChart, bgChart);

    result.datasets = datasetArray;
    result.labels = labelChart;

    return result;
}
