import {createSelector} from 'reselect';
import {anyObstacle} from "./area.util";

const selectAreaReducer = (state) => state.areaReducer;

/**
 * =============================================
 * TERRAIN SELECTOR
 * =============================================
 */

export const selectTerrainHeight = createSelector(
    [selectAreaReducer],
    (state) => state.terrainHeight
);
export const selectIsTerrainActive = createSelector(
    [selectAreaReducer],
    (state) => state.isTerrainActive
);
/**
 * ============================================================
 * END TERRAIN SELECTOR
 * ============================================================
 */

export const selectRxPosition = createSelector(
    [selectAreaReducer],
    (state) => state.rxPosition
);
export const selectTransmitterPotition = createSelector(
    [selectAreaReducer],
    (state) => state.transmitterPosition
);
export const selectTransmitterData = createSelector(
    [selectAreaReducer],
    (state) => state.transmitterData
);
export const selectOpenInputDataRightPannel = createSelector(
    [selectAreaReducer],
    (state) => state.openInputDataRightPannel
);
export const selectBillboardEntity = createSelector(
    [selectAreaReducer],
    (state) => state.billboardEntity
);
export const selectPmData = createSelector(
        [selectAreaReducer],
        (state) => {
            let newArr = [{}];
            if (state.pmData) {
                for (let i = 0; i < state.pmData.length; i++) {
                    newArr.push({value: state.pmData[i].value, label: state.pmData[i].value, key: state.pmData[i].key});
                }
                return newArr
            } else {
                return null;
            }
        }
    )
;
export const selectRadioData = createSelector(
    [selectAreaReducer],
    (state) => state.radioData
);
export const selectAntennaData = createSelector(
    [selectAreaReducer],
    (state) => state.antennaData
);

export const selectResponseData = createSelector(
    [selectAreaReducer],
    (state) => state.responseDataArea
)

export const selectShowCoverageNotification = createSelector(
    [selectAreaReducer],
    (state) => state.showCoverageNotification
)
export const selectKinetic = createSelector(
    [selectAreaReducer],
    (state) => state.kinetic
)
export const selectRuleInformation = createSelector(
    [selectAreaReducer],
    (state) => state.ruleInformation
)
export const selectDataEntityFromOtherPage = createSelector(
    [selectAreaReducer],
    (state) => state.copyData
)
export const selectErrorMessage = createSelector(
    [selectAreaReducer],
    (state) => state.failedResponse
)

export const selectMultiplyAmount = createSelector(
    [selectAreaReducer],
    (state) => state.multiplyAmount
)
export const selectOPResponse = createSelector(
    [selectAreaReducer],
    (state) => state.createOPResponse
)
export const selectReqBody = createSelector(
    [selectAreaReducer],
    (state) => {
        if (state.requestBody) {
            return state.requestBody;
        } else {
            return null;
        }
    }
)
export const selectCountOPReq = createSelector(
    [selectAreaReducer],
    (state) => state.countOPReq
)

export const selectAltitudeToSum = createSelector(
    [selectAreaReducer],
    (state) => state.altitudeToSum
)
export const selectOPSim = createSelector(
    [selectAreaReducer],
    (state) => state.opSim
)
export const selectPathResponse = createSelector(
    [selectAreaReducer],
    (state) => state.pathResponse
)
export const selectShowChartLinkBudget = createSelector(
    [selectAreaReducer],
    (state) => state.showChartLinkBudget
)
export const selectAnyDataCharLinkBudget = createSelector(
    [selectAreaReducer],
    (state) => state.anyDataCharLinkBudget
)
export const selectListenerMouseMove = createSelector(
    [selectAreaReducer],
    (state) => state.listenerMouseMove
)
export const selectChangeKineticRx = createSelector(
    [selectAreaReducer],
    (state) => state.changeKineticRx
)
export const selectTxCorrection = createSelector(
    [selectPathResponse],
    (d) => {
        if (d && d.resultPath &&
            d.resultPath.transmitters && d.resultPath.transmitters.length) {
            const {latitude, longitude, groundElevationM, antennaHeightM} = d.resultPath.transmitters[0];
            return {latitude, longitude, groundElevationM, antennaHeightM}
        }
    }
)
export const selectChartData = createSelector(
    [selectPathResponse],
    (d) => {
        const obstacle = anyObstacle(d);
        if (obstacle.dist.length) {
            return {
                distanceArr: obstacle.dist,
                terrainArr: obstacle.ter,
                losArr: obstacle.los,
                fresnelArr: obstacle.fre,
                obstacle: obstacle.anyObstacle
            }
        } else{
            return null;
        }

        // if (d && d.resultPath && d.resultPath.receiver && d.resultPath.receiver.length &&
        //     d.resultPath.transmitters && d.resultPath.transmitters.length &&
        //     d.resultPath.transmitters[0].terrain && d.resultPath.transmitters[0].terrain.length &&
        //     d.resultPath.transmitters[0].distance && d.resultPath.transmitters[0].distance.length) {
            // return {distanceArr, terrainArr, losArr, fresnelArr, obstacle};
            // // const terrainActive = d.pathReqDto.additionalPathDTO.terrainActive;
            // const groundElevationMRX = d.resultPath.receiver[0].groundElevationM;
            // const antennaHeightMRX = d.resultPath.receiver[0].antennaHeightM;
            // const groundElevationMTX = d.resultPath.transmitters[0].groundElevationM;
            // const antennaHeightMTX = d.resultPath.transmitters[0].antennaHeightM;
            // // const totalHeightRX = terrainActive ? groundElevationMRX + antennaHeightMRX : antennaHeightMRX;
            // // const totalHeightTX = terrainActive ? groundElevationMTX + antennaHeightMTX : antennaHeightMTX;
            // // const terrainActive = d.pathReqDto.additionalPathDTO.terrainActive;
            // // const totalHeightRX = terrainActive ? groundElevationMRX + antennaHeightMRX : antennaHeightMRX;
            // // const totalHeightTX = terrainActive ? groundElevationMTX + antennaHeightMTX : antennaHeightMTX;
            // const totalHeightRX = groundElevationMRX + antennaHeightMRX ;
            // const totalHeightTX = groundElevationMTX + antennaHeightMTX ;
            // const terrainArr = d.resultPath.transmitters[0].terrain;
            // const terrainArrLength = terrainArr.length;
            // const distanceArr = d.resultPath.transmitters[0].distance;
            // const fresnelData = d.resultPath.transmitters[0].fresnel;
            //
            // const dataLength = Math.abs(totalHeightTX - totalHeightRX);
            // const diff = totalHeightTX - totalHeightRX;
            // const countBy = dataLength / terrainArrLength;
            //
            // const losArr = [totalHeightTX]
            // // console.log("before = " + losArr)
            // for (let i = 0; i < terrainArrLength - 1; i++) {
            //     if (diff > 0) {
            //         losArr.push(losArr[i] - countBy);
            //     } else {
            //         losArr.push(countBy + losArr[i]);
            //     }
            // }
            // // console.log("after = " + losArr)
            // let fresnelArr = [];
            // for (let i = 0; i < terrainArrLength; i++) {
            //     fresnelArr.push(losArr[i] - Math.abs(fresnelData[i]));
            // }
            //
            // console.log(`DIS SIZE = ${distanceArr.length}`)
            // console.log(`LOS SIZE = ${losArr.length}`)
            // console.log(`TER SIZE = ${terrainArr.length}`)
            // let obstacle = false;
            // for (let i = 0; i < distanceArr.length; i++) {
            //     if (terrainArr[i] > losArr[i]) {
            //         obstacle = true;
            //     }
            // }
            // // return {distanceArr, terrainArr, losArr, fresnelArr, obstacle: anyObstacle(d)};
            // return {distanceArr, terrainArr, losArr, fresnelArr, obstacle};
            // // return {distanceArr, terrainArr, losArr, fresnelData, obstacle: anyObstacle(d)};
        // } else {
        //     return null;
        // }
    }
);
export const selectSignalPowerAtReceiverDBm = createSelector(
    [selectPathResponse],
    (d) => {
        if (d && d.pathReqDto && d.pathReqDto.transmitter && d.pathReqDto.receiver &&
            d.resultPath && d.resultPath.transmitters && d.resultPath.transmitters.length) {
            return {
                txLat: d.pathReqDto.transmitter.lat,
                txLon: d.pathReqDto.transmitter.lon,
                txAlt: d.pathReqDto.transmitter.alt,
                rxLat: d.pathReqDto.receiver.lat,
                rxLon: d.pathReqDto.receiver.lon,
                rxAlt: d.pathReqDto.receiver.alt,
                dbm: d.resultPath.transmitters[0].signalPowerAtReceiverDBm
            };
        } else {
            return null;
        }
    }
)
export const selectKmlPath = createSelector(
    [selectAreaReducer],
    (state) => state.kmlPath
)
export const selectAnyObstacle = createSelector(
    [selectAreaReducer],
    (state) => state.anyObstacle
)
