import React, {createRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

// Redux
import {handleToggleFilter} from "../../store/dashboard/dashboard.action";
import {selectStatusToggleFilterButton} from "../../store/dashboard/dashboard.selector";

// Asset
import { FilterIcon } from "../../assets/svgs";

export const FilterToolbar = () => {
    const ref = createRef();
    const dispatch = useDispatch()
    const showFilter = useSelector(selectStatusToggleFilterButton);

    useEffect(()=>{
        ref.current.classList.add("cesium-button", "cesium-toolbar-button");
        const toolbar = document.querySelector("div.cesium-viewer-toolbar");
        const modeButton = document.querySelector("span.cesium-sceneModePicker-wrapper");
        toolbar.insertBefore(ref.current, modeButton);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div
                ref={ref}
                id="filter"
                style={{background: !showFilter ? '#303336' : 'white'}}
                onClick={() => dispatch(handleToggleFilter())}
            >
                <div className="ml-[1px]">
                    <FilterIcon className="mt-1" style={{stroke: showFilter ? '#303336' : '#fff'}}/>
                </div>
            </div>
        </>

    );
};
