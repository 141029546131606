import {
    ACTION_GET,
    ACTION_POST,
    ACTION_PUT,
    ACTION_DELETE,
    BASE_URL,
} from "./core";

export const GET_ALL_ANTENA = (page, pageSize, search, siteId) => {
    let url = `${BASE_URL}/antenna/search?q=${search.toLowerCase()}&type=name&page=${page}&size=${pageSize}&siteId=${siteId}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};


export const GET_ANTENNA_BY_ID = (id) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/antenna/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const GET_ANTENNA_BY_SITE = (id) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/antenna/site/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const CREATE_ANTENA = (param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/antenna`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const UPDATE_ANTENA = (id, param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/antenna/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const DELETE_ANTENA_BY_ID = (param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/antenna/${param}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const GET_COAXIAL_TYPE = () => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/refcoaxialtype?size=50`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const SEARCH_ANTENNA = (q, type, page, size, siteId = 0) => {
    const url = `${BASE_URL}/antenna/search?q=${q.toLowerCase()}&type=${type}&page=${page}&size=${size}&site=${siteId}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
