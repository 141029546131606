import {DISTRIBUTION_ACTION_TYPES} from "./distribution.types";
import {GET_ASSET_DISTRIBUTION} from "../../services/asset";
import {GET_ALL_SITE} from "../../services/site";
import {createAction} from "../../utils/reducer/reducerUtil";
import {drawEntity, removeEntityById} from "../../utils/cesium/cesiumUtils";
import {defined, ScreenSpaceEventHandler, ScreenSpaceEventType} from "cesium";
import {GET_ALL_DISTRIBUTIONASSET} from "../../services/distributionassets";
import { handleLoading } from "../global/global.action";
import { GLOBAL_ACTION_TYPES } from "../global/global.types";
import { STATUS_TYPE } from "../../utils/constant/constants";


export const handleFetchData = (siteId) => async (dispatch) => {
    const res = await GET_ASSET_DISTRIBUTION(siteId);
    if (res) {
        dispatch(createAction(DISTRIBUTION_ACTION_TYPES.FETCH_DATA_ASSET, res));
    }
}

export const handleFetchDataSite = (viewer) => async (dispatch) => {
    const res = await GET_ALL_SITE({page: 0, size: 300});
    if (res && res.content) {
        dispatch(createAction(DISTRIBUTION_ACTION_TYPES.FETCH_DATA_SITE, res))
        dispatch(handleRegisterHoverListener(viewer, res));
    }
}

export const handleClearSite = (viewer, sites) => {
    removeEntityById(viewer, sites);
};

export const drawSite = (viewer, sites) => (dispatch) => {
    const newSites = drawEntity(viewer, sites);
    dispatch(createAction(DISTRIBUTION_ACTION_TYPES.FETCH_DATA_SITE, newSites));
}

export const handleFetchDistributionData = () => dispatch => {
    GET_ALL_DISTRIBUTIONASSET()
        .then(res => {
            const towerDist = [];
            const workshop = [];
            const fo = [];
            res.content.forEach(d => {
                if (d.type.id === 5) {
                    towerDist.push(d);
                } else if (d.type.id === 7) {
                    workshop.push(d);
                }else if (d.type.id === 3) {
                    fo.push(d);
                }
            })
            dispatch(createAction(DISTRIBUTION_ACTION_TYPES.FETCH_TOWER_CIVIL, towerDist));
            dispatch(createAction(DISTRIBUTION_ACTION_TYPES.FETCH_WORKSHOP, workshop));
            dispatch(createAction(DISTRIBUTION_ACTION_TYPES.FETCH_FO, fo));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        });
}


export const fetchDataTotal = (site, foData) => dispatch => {
    GET_ASSET_DISTRIBUTION(site.id)
        .then(res => {
            const copyRes = {...res}
            const filter = foData.filter(d => d.id === site.id);
            copyRes.totalFo = filter.length;
            dispatch(createAction(DISTRIBUTION_ACTION_TYPES.FETCH_TOTAL_ASSET, copyRes))
            dispatch(createAction(DISTRIBUTION_ACTION_TYPES.OPEN_TOOLTIPS, true));
        })
        .catch(() => {});
}


export const handleRegisterHoverListener = (viewer, sites) => async (dispatch) => {
    if (viewer && sites && sites.content) {
        const handler = new ScreenSpaceEventHandler(viewer.scene.canvas);
        const eventListener = (movement) => {
            const pickedObject = viewer.scene.pick(movement.endPosition);
            if (pickedObject && pickedObject.id) {
                const site = sites.content.find(e => e.entityId === pickedObject.id);
                if (defined(pickedObject) && site) {
                    if (site && site.id) {
                        dispatch(createAction(DISTRIBUTION_ACTION_TYPES.OPEN_TOOLTIPS, true));
                        dispatch(createAction(DISTRIBUTION_ACTION_TYPES.SELECTED_SITE_ID, site));
                    }
                }
            } else {
                dispatch(createAction(DISTRIBUTION_ACTION_TYPES.OPEN_TOOLTIPS, false));
            }
        }
        handler.setInputAction(eventListener, ScreenSpaceEventType.MOUSE_MOVE);

        dispatch(createAction(DISTRIBUTION_ACTION_TYPES.REGISTER_HANDLER_LISTENER, {handler, eventListener}));
    } else {
        dispatch(createAction(DISTRIBUTION_ACTION_TYPES.OPEN_TOOLTIPS, false));
    }
}