import {createSelector} from "reselect";
import {getPercentage} from "../../utils/calculate/convert";

const selectAntennaReducer = (state) => state.antennaReducer;

export const selectAllAntenna = createSelector(
    [selectAntennaReducer],
    (state) => state.antennas
)
export const selectAllAntennaReport = createSelector(
    [selectAntennaReducer],
    (state) => state.antennasReport
)
export const selectTotalPages = createSelector(
    [selectAllAntenna],
    (antennas) => {
        if (antennas && antennas.antenna) {
            return antennas.antenna.totalPages
        } else {
            return null
        }
    }
)
export const selectCategories = createSelector(
    [selectAntennaReducer],
    (state) => state.categories
)
export const selectcoaxialType = createSelector(
    [selectAntennaReducer],
    (state) => state.coaxialTypes
)

export const selectDetailAntenna = createSelector(
    [selectAntennaReducer],
    (state) => state.detailAntenna
)
export const selectBrands = createSelector(
    [selectAntennaReducer],
    (state) => state.brands
)
export const selectTypes = createSelector(
    [selectAntennaReducer],
    (state) => state.types
)
export const selectSites = createSelector(
    [selectAntennaReducer],
    (state) => state.sites
)
export const selectDataChart = createSelector(
    [selectAllAntenna],
    (state) => {
        if (state && state.summary) {
            const status = state.summary;
            return [{
                datasets: [
                    {
                        label: "Antenna",
                        data: [
                            getPercentage(status.spare, status.total),
                            getPercentage(status.service, status.total),
                            getPercentage(status.unservice, status.total)
                        ],
                        backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                        borderColor: ["#040f0f"],
                        borderWidth: 10,
                    },
                ],
            }]
        } else {
            return null;
        }
    }
)
export const selectStatusAntenna = createSelector(
    [selectAllAntenna],
    (state) => {
        if (state && state.summary) {
            const status = state.summary;
            return [
                {
                    id: 1,
                    name: "Antenna Total",
                    item: status.total ?? 0,
                    icon: "antenna",
                    class: "",
                },
                {
                    id: 2,
                    name: "Antenna Serviceable",
                    item: status.service ?? 0,
                    icon: "antenna",
                    class: "serviceable",
                },
                {
                    id: 3,
                    name: "Antenna Spare",
                    item: status.spare ?? 0,
                    icon: "antenna",
                    class: "spare",
                },
                {
                    id: 4,
                    name: "Antenna Unserviceable",
                    item: status.unservice ?? 0,
                    icon: "antenna",
                    class: "unserviceable",
                },
            ]
        } else {
            return null;
        }
    }
)

export const selectStatusArray = createSelector(
    [selectAntennaReducer],
    (state) => state.statusArray
)
export const selectColumn = createSelector(
    [selectAntennaReducer],
    (state) => state.column
)
export const selectUploadedImages = createSelector(
    [selectAntennaReducer],
    (state) => state.uploadedImages
)
export const selectUploadedFiles = createSelector(
    [selectAntennaReducer],
    (state) => state.uploadedFiles
)
export const selectUploadedAntennaStatus = createSelector(
    [selectAntennaReducer],
    (state) => state.uploadAntennaStatus
)
export const selectUpdateStatus = createSelector(
    [selectAntennaReducer],
    (state) => state.updateStatus
)
export const selectRemoveStatus = createSelector(
    [selectAntennaReducer],
    (state) => state.removeStatus
)