import {createSelector} from "reselect";
import {getPercentage} from "../../utils/calculate/convert";

const selectTowerServerReducer = (state) => state.towerServerReducer;

export const selectAllTowerServer= createSelector(
    [selectTowerServerReducer],
    (state) => state?.towerServers
)
export const selectTotalPages = createSelector(
    [selectAllTowerServer],
    (towerServers) => {
        if (towerServers) {
            return towerServers?.totalPages
        } else {
            return null
        }
    }
)

export const selectDetailTowerServer = createSelector(
    [selectTowerServerReducer],
    (state) => state?.detailTowerServer
)

export const selectSites = createSelector(
    [selectTowerServerReducer],
    (state) => state?.sites
)
export const selectDataChart = createSelector(
    [selectAllTowerServer],
    (state) => {
        if (state && state.summary) {
            const status = state.summary;
            return [{
                datasets: [
                    {
                        label: "Data Tower Server",
                        data: [
                            getPercentage(status.spare, status.total),
                            getPercentage(status.service, status.total),
                            getPercentage(status.unservice, status.total)
                        ],
                        backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                        borderColor: ["#040f0f"],
                        borderWidth: 10,
                    },
                ],
            }]
        } else {
            return null;
        }
    }
)

export const selectStatusTowerServer = createSelector(
    [selectAllTowerServer],
    (state) => {
        if (state && state.totalElements) {
            const status = state.totalElements;
            return [
                {
                    id: 1,
                    name: "Total Tower Server",
                    item: status ?? 0,
                    icon: "tower",
                    class: "",
                }
            ]
        } else {
            return null;
        }
    }
)



export const selectColumn = createSelector(
    [selectTowerServerReducer],
    (state) => state?.column
)
export const selectUploadedImages = createSelector(
    [selectTowerServerReducer],
    (state) => state?.uploadedImages
)
export const selectUploadedFiles = createSelector(
    [selectTowerServerReducer],
    (state) => state?.uploadedFiles
)
export const selectUploadedTowerServerStatus = createSelector(
    [selectTowerServerReducer],
    (state) => state?.uploadTowerServerStatus
)
export const selectRemoveTowerServerStatus = createSelector(
    [selectTowerServerReducer],
    (state) => state?.removeStatus
)

export const selectUpdateTowerServerStatus = createSelector(
    [selectTowerServerReducer],
    (state) => state?.updateStatus
)