import NotFoundImage from '../../assets/png/5203299.jpg'

const NotFoundPage = () => {
    return (
        <>
            <img src={NotFoundImage} alt="notfound"/>
        </>

    );
};

export default NotFoundPage;
