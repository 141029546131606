import {ACTION_GET, ACTION_PUT, ACTION_POST, ACTION_DELETE, BASE_URL} from "./core";

export const GET_ALL_CIVIL = (page, pageSize, search) => {
    //const setQuery = serialize(query);
    let url = `${BASE_URL}/distributionassets`;
    if (search !== "" && search !== null) {
        url = url + `/search/5?${search}&page=${page}&size=${pageSize}`;
    } else {
        url = url + `?page=${page}&size=${pageSize}`;
    }
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const GET_CIVIL_BY_ID = (id) => {
    const url = `${BASE_URL}/distributionassets/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const SEARCH_CIVIL = (q, type, page, size, siteId) => {
    const url = `${BASE_URL}/distributionassets/search/5?q=${q.toLowerCase()}&type=${type}&page=${page}&size=${size}&site=${siteId}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const GET_CIVIL_BY_SITE = (id) => {
    const url = `${BASE_URL}/radio/site/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};


export const CREATE_CIVIL = (param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/distributionassets`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const UPDATE_CIVIL = (id, param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/distributionassets/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const DELETE_CIVIL_BY_ID = (param) => {
    //const setQuery = serialize(query);
    const url = `${BASE_URL}/distributionassets/${param}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

