import {ACTION_DELETE, ACTION_GET, ACTION_POST, ACTION_PUT, BASE_URL} from "./core";

export const GET_ALL_MANNET_NETWORK = (page, size, q = "") => {
    const url = `${BASE_URL}/mannet/network?page=${page}&size=${size}&q=${q}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const GET_ALL_MANNET_BY_NETWORK= (network) => {
    const url = `${BASE_URL}/mannet?network=${network}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const POST_MANNET = (body) => {
    const url = `${BASE_URL}/mannet`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, body)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const UPDATE_MANNET = (id, payload) => {
    const url = `${BASE_URL}/mannet/${id}`
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, payload)
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error)
            })
    })
}
export const DELETE_MANNET = (id) => {
    const url = `${BASE_URL}/mannet/${id}`
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url, {})
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error)
            })
    })
}
export const DELETE_MANNET_BY_NETWORK = (network) => {
    const url = `${BASE_URL}/mannet/network/${network}`
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url, {})
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error)
            })
    })
}



