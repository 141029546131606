export const GLOBAL_ACTION_TYPES = {
    REMOVE_ASSET : "global/REMOVE_ASSET",
    EDIT_ASSET : "global/EDIT_ASSET",
    SHOW_DETAIL_ASSET : "global/SHOW_DETAIL_ASSET",
    CHECK_ASSET : "global/CHECK_ASSET",
    CLEAR_GLOBAL_STATE : "global/CLEAR_GLOBAL_STATE",
    SYNC_STATUS : "global/SYNC_STATUS",
    IS_LOADING : "global/IS_LOADING",
    IS_LOADING_SYNC : "global/IS_LOADING_SYNC",
    NOTIFY_NOT_CONNECTED_TO_BACKEND : "global/NOTIFY_NOT_CONNECTED_TO_BACKEND",
}