import {
    CREATE_ANTENA,
    DELETE_ANTENA_BY_ID,
    GET_ANTENNA_BY_ID,
    SEARCH_ANTENNA,
    UPDATE_ANTENA,
    GET_COAXIAL_TYPE
} from "../../services/antena";
import {createAction} from "../../utils/reducer/reducerUtil";
import {ANTENNA_ACTION_TYPES} from "./antenna.types";
import {
    GET_ASSET_ANTENA,
    GET_BRAND_BY_USAGE,
    GET_CATEGORY_BY_USAGE,
    GET_TYPE_BY_USAGE,
    UPLOAD_FILE,
    UPLOAD_IMAGE
} from "../../services/asset";
import {GET_ALL_SITE} from "../../services/site";
import {STATUS_TYPE} from "../../utils/constant/constants";
import {handleClearStateGlobal, handleLoading} from "../global/global.action";
import { GLOBAL_ACTION_TYPES } from "../global/global.types";

/*
const defineTypeFreq = (freqMin, freqMax) => {
    let freqType;
    if (freqMin > 400 || (freqMax && freqMin > 400 && freqMax <= 8000)) { // C-Band
        freqType = ANTENNA_BAND.C_BAND.name.toLowerCase();
    } else if (freqMin > 300 || (freqMax && freqMin > 300 && freqMax <= 3000)) { // UHF
        freqType = ANTENNA_BAND.UHF.name.toLowerCase();
    } else if (freqMin > 30 || (freqMax && freqMin > 30 && freqMax <= 300)) { // VHF
        freqType = ANTENNA_BAND.VHF.name.toLowerCase();
    } else if (freqMin >= 3 || (freqMax && freqMin >= 3 && freqMax <= 30)) { // HF
        freqType = ANTENNA_BAND.HF.name.toLowerCase();
    }
    return freqType;
}
*/

export const handleUploadFile = (item) => dispatch => {
    const formData = new FormData();
    formData.append("file", item);
    UPLOAD_FILE(formData)
        .then(res => {
            dispatch(createAction(ANTENNA_ACTION_TYPES.UPLOAD_FILE, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
        });
}
export const handleFetchcoaxialType = () => async (dispatch) => {
    const res = await GET_COAXIAL_TYPE();
    if (res && res.content.length > 0) {
        dispatch(createAction(ANTENNA_ACTION_TYPES.GET_COAXIAL_TYPE, res));
    }
}

export const handleUploadImage = (item) => dispatch => {
    const formData = new FormData();
    formData.append("file", item);
    UPLOAD_IMAGE(formData)
        .then(res => {
            dispatch(createAction(ANTENNA_ACTION_TYPES.UPLOAD_IMAGE, res.id));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
        });
}

export const handleFetchStatusAntenna = () => async (dispatch) => {
    const res = await GET_ASSET_ANTENA();
    if (res) {
        dispatch(createAction(ANTENNA_ACTION_TYPES.GET_STATUS, res));
    }
}
export const handleFetchBrand = () => async (dispatch) => {
    const res = await GET_BRAND_BY_USAGE("antenna");
    if (res && res.length > 0) {
        dispatch(createAction(ANTENNA_ACTION_TYPES.GET_BRAND, res));
    }
}
export const handleFetchCategory = () => async (dispatch) => {
    const res = await GET_CATEGORY_BY_USAGE("radio")
    if (res && res.length > 0) {
        dispatch(createAction(ANTENNA_ACTION_TYPES.GET_CATEGORY, res));
    }
}
export const handleFetchType = () => async (dispatch) => {
    const res = await GET_TYPE_BY_USAGE("antenna")
    if (res && res.length > 0) {
        dispatch(createAction(ANTENNA_ACTION_TYPES.GET_TYPE, res));
    }
}
export const handleFetchSite = () => async (dispatch) => {
    const res = await GET_ALL_SITE();
    if (res && res.content.length > 0) {
        dispatch(createAction(ANTENNA_ACTION_TYPES.GET_SITE, res));
    }
}

export const handleClearData = () => (dispatch) => {
    dispatch(createAction(ANTENNA_ACTION_TYPES.CLEAR_DATA))
}

export const handleClearDetailAntenna = () => (dispatch) => {
    dispatch(createAction(ANTENNA_ACTION_TYPES.CLEAR_DETAIL_ANTENNA))
}

export const handleClearNotif = () => dispatch => {
    dispatch(createAction(ANTENNA_ACTION_TYPES.UPLOAD_ANTENNA_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(ANTENNA_ACTION_TYPES.UPDATED, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(ANTENNA_ACTION_TYPES.REMOVED, STATUS_TYPE.NO_STATEMENT));
}

export const handleSaveAntenna = (data, images, files, siteId) => async (dispatch) => {

    const payload = {
        antennaPower: Number(data.antennaPower),
		type: {assetType:data.type},
		serialNo: data.serialNo,
		partNo: data.partNo,
        name: data.name,
        site: {id: siteId},
        brand: {id: data.brand},
		freq: data.freq,
		power: Number(data.power),
		freqMax: data.freqMax,
		bandwidth: data.bandwidth,
		notes: data.notes,
		connector: data.connector,
		coaxialType: { id: data.coaxialType },
		status: data.status,
        imageUrlList: images,
        fileUrlList: files.urlArr,
        fileNameList: files.nameArr,
    };

    const copyPayload = JSON.parse(JSON.stringify(payload));
    if (copyPayload.coaxialType.id === "") {
        copyPayload.coaxialType = null
    }

    CREATE_ANTENA(copyPayload).then(() => {
        dispatch(createAction(ANTENNA_ACTION_TYPES.UPLOAD_ANTENNA_STATUS, STATUS_TYPE.SUCCESS))
    }).catch(e => {
        dispatch(createAction(ANTENNA_ACTION_TYPES.UPLOAD_ANTENNA_STATUS, STATUS_TYPE.FAILED))
    });
    dispatch(handleClearStateGlobal());
    dispatch(createAction(ANTENNA_ACTION_TYPES.IS_LOADING, false));

}

export const handleStartLoading = () => dispatch => {
    dispatch(createAction(ANTENNA_ACTION_TYPES.IS_LOADING, true));
}

export const handleRemoveAntenna = (id, siteId, page) => async (dispatch) => {
    dispatch(createAction(ANTENNA_ACTION_TYPES.IS_LOADING, true));
    DELETE_ANTENA_BY_ID(id)
        .then(() => {
            dispatch(createAction(ANTENNA_ACTION_TYPES.REMOVED, STATUS_TYPE.SUCCESS))
            // dispatch(handleSearchAntenna("", "owner", page, 10, siteId));
            // dispatch(handleSearchAntennaReport("", "owner", page, 100, siteId));
            dispatch(handleClearStateGlobal());
        })
        .catch(() => {
            dispatch(createAction(ANTENNA_ACTION_TYPES.REMOVED, STATUS_TYPE.FAILED))
            dispatch(handleClearStateGlobal());
        })
    dispatch(createAction(ANTENNA_ACTION_TYPES.IS_LOADING, false));
}
export const handleUpdateAntenna = (id, data, images, files, siteId, page) => async (dispatch) => {

    const payload = {
        antennaPower: Number(data.antennaPower),
		type: {assetType:data.type},
		serialNo: data.serialNo,
		partNo: data.partNo,
        name: data.name,
        site: {id: siteId},
        brand: {id: data.brand},
		freq: data.freq,
		power: data.power,
		freqMax: data.freqMax,
		bandwidth: data.bandwidth,
		notes: data.notes,
		connector: data.connector,
		coaxialType: { id: data.coaxialType },
		status: data.status,
        imageUrlList: images,
        fileUrlList: files.urlArr,
        fileNameList: files.nameArr,
    };


    UPDATE_ANTENA(id, payload)
        .then(() => {
            // dispatch(handleSearchAntenna("", "name", 0, 10, siteId));
            // dispatch(handleSearchAntennaReport("", "name", 0, 100, siteId));
            dispatch(createAction(ANTENNA_ACTION_TYPES.UPDATED, STATUS_TYPE.SUCCESS));
        })
        .catch(() => {
            dispatch(createAction(ANTENNA_ACTION_TYPES.UPDATED, STATUS_TYPE.FAILED));
        })
    dispatch(handleClearStateGlobal());
    dispatch(createAction(ANTENNA_ACTION_TYPES.IS_LOADING, false));
};

export const handleSearchAntenna = (q, type, page, size, siteId) => dispatch => {
    SEARCH_ANTENNA(q, type, page, size, siteId)
        .then(res => {
            dispatch(createAction(ANTENNA_ACTION_TYPES.SEARCH, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED));
        })
};

export const handleSearchAntennaReport = (q, type, page, size, siteId) => dispatch => {
    SEARCH_ANTENNA(q, type, page, size, siteId)
        .then(res => {
            dispatch(createAction(ANTENNA_ACTION_TYPES.SEARCH_REPORT, res));
        })
        .catch(() => {});
};

export const handleGetAntennaById = (id) => dispatch => {
    GET_ANTENNA_BY_ID(id)
        .then(res => {
            dispatch(createAction(ANTENNA_ACTION_TYPES.DETAIL_ANTENNA, res));
        })
        .catch(() => {});
};

