import * as React from "react"

const OwnerTypeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 8267.72 11692.91"
    {...props}
  >
    <g
      fill="currentColor"
      stroke="#2B2A29"
      strokeWidth={7.87}
      strokeMiterlimit={22.926}
    >
      <path d="M-292.65 165.63h8904.69c677.3 0 1231.45 554.15 1231.45 1231.45v8904.69c0 677.3-554.15 1231.46-1231.45 1231.46H-292.65c-677.3 0-1231.46-554.15-1231.46-1231.46V1397.08c0-677.3 554.15-1231.45 1231.46-1231.45zm304.78 389.1h8295.11c630.96 0 1147.14 516.19 1147.14 1147.14v8295.11c0 630.96-516.19 1147.14-1147.14 1147.14H12.13c-630.96 0-1147.14-516.19-1147.14-1147.14V1701.87c0-630.96 516.19-1147.14 1147.14-1147.14z" />
      <path d="M3438.54 2622.65c-304.13-314.58-735.17-510.97-1213.24-510.97-921.22 0-1668.02 729.02-1668.02 1628.3 0 461.02 196.31 877.23 511.72 1173.47l2369.54-2290.8zM4639.16 5675.65c362.59-397.27 349.67-925.56 5.52-1280.81 88.24 350.58-8.52 742.15-304.04 1065.94-325.66 356.79-815.55 546.87-1294.15 543.59 528.6 223.68 1207.08 93.76 1592.68-328.72zm-543.05-321.83c289.82-317.54 279.48-739.8 4.4-1023.76 70.54 280.21-6.81 593.2-243.02 852.02-260.3 285.17-651.86 437.11-1034.4 434.48 422.5 178.79 964.8 74.95 1273.02-262.73zm-1256.4-114.28c264.2 111.79 603.31 46.86 796.03-164.3 181.23-198.57 174.78-462.61 2.75-640.18 44.13 175.22-4.25 370.94-151.95 532.77-162.76 178.33-407.62 273.33-646.83 271.7z" />
      <circle cx={2848.55} cy={4456.69} r={479.3} />
      <rect
        transform="matrix(.78204 .12052 -.2544 .0392 3017.5 1494.79)"
        width={5666.67}
        height={4611.11}
        rx={1210.27}
        ry={1210.27}
      />
      <rect
        transform="matrix(-.15176 -.78176 -.04841 .25449 807.157 7408.91)"
        width={5666.67}
        height={4611.11}
        rx={1210.27}
        ry={1210.27}
      />
      <rect
        transform="matrix(.78204 .12052 -.2544 .0392 4418.02 2925.26)"
        width={5666.67}
        height={4611.11}
        rx={1210.27}
        ry={1210.27}
      />
      <rect
        transform="matrix(-.15176 -.78176 -.04841 .25449 2755.91 8985.83)"
        width={5666.67}
        height={4611.11}
        rx={1210.27}
        ry={1210.27}
      />
    </g>
  </svg>
)

export default OwnerTypeIcon
