import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { selectRoleData } from "../../store/role/role.selector";
import { resetUserReducer } from "../../store/user/user.action";

import {
    AboutIcon,
    AdminIcon,
    LogoutIcon,
    UserIcon,
} from "../../assets/svgs";
import { handleClearStateGlobal, handleLoading } from "../../store/global/global.action";

const UserManagement = ({setShowUserManagement}) => {
    const role = useSelector(selectRoleData);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        Swal.fire({
            title: "Logout",
            text: "Are you sure want to Logout?",
            icon: "warning",
            showClass: {
                backdrop: "swal2-noanimation",
                popup: "scale-in-center",
            },
            hideClass: {
                popup: "",
            },
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#000153",
            cancelButtonColor: "#E3DAC9",
            confirmButtonText: "<p class='font-semibold'>Yes, Logout!</p>",
            cancelButtonText: "<p class='font-semibold text-black'>Cancel</p>",
            iconColor: "#FF3A30",
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem("profile");
                localStorage.removeItem("accesstoken_nccsa");
                navigate("/login", { replace: true });
                dispatch(resetUserReducer());
                dispatch(handleClearStateGlobal());
                dispatch(handleLoading(false));
            }
        });
    };

    return (
        <div
            id="dropdown"
            className="absolute top-9 right-[-8px] w-44 bg-tertiary rounded-lg divide-y divide-gray-100 shadow dark:bg-gray-700 scale-in-ver-top z-30"
        >
            <ul
                className="py-1 text-sm text-[#FFFFFF] dark:text-gray-200"
                aria-labelledby="dropdownDefault"
            >
                <li>
                    <Link
                        to="/usermanagement/profile"
                        className="block py-4 px-4 hover:bg-[#ffffff47] dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => setShowUserManagement(false)}
                    >
                        <div className="flex gap-x-5">
                            <UserIcon/>
                            <span>Profile</span>
                        </div>
                    </Link>
                </li>

                {role && role.id === "1" && (
                    <>
                        <li>
                            <Link
                                to="/usermanagement/administration"
                                className="block py-4 px-4 hover:bg-[#ffffff47] dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={() => setShowUserManagement(false)}
                            >
                                <div className="flex gap-x-5">
                                    <AdminIcon/>
                                    <span>Administration</span>
                                </div>
                            </Link>
                        </li>
                    </>
                )}

                <li>
                    <Link
                        to="/usermanagement/about"
                        className="block py-4 px-4 hover:bg-[#ffffff47] dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => setShowUserManagement(false)}
                    >
                        <div className="flex gap-x-5">
                            <AboutIcon/>
                            <span>About</span>
                        </div>
                    </Link>
                </li>

                <li>
                    <button
                        type="button"
                        className="w-full flex gap-x-5 py-4 px-4 hover:bg-[#ffffff47] dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => {
                            setShowUserManagement(false)
                            handleLogout()
                        }}
                    >
                        <LogoutIcon/>
                        <span>Logout</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default UserManagement;
