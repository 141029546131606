import {createSelector} from "reselect";

const selectAdminReducer = (state) => state.adminReducer;

export const selectTotalPage = createSelector(
    [selectAdminReducer],
    (state) => {
        if (state.responseData && state.responseData.totalPages) {
            return state.responseData.totalPages;
        } else {
            return null;
        }
    }
)
export const selectRemoveArchiveStatus = createSelector(
    [selectAdminReducer],
    (state) => state.removeArchiveStatus
)

export const selectResponseData = createSelector(
    [selectAdminReducer],
    (state) => {
        if (state.responseData && state.responseData.content) {
            const content = state.responseData.content;
            if (content.length) {
                const result = []
                content.forEach(data => {
                    if (data && data.data && data.data.name && data.data.network) {
                        result.push({id: data.id, name: data.data.name, network: data.network, siteName: data.siteName});
                    } else {
                        result.push({name: data.network, id: 0});
                    }
                });
                return result;
            }
        } else {
            return null;
        }
    }
);

// ===========================================================================
// ============================ CATEGORY SELECTOR ============================
// ===========================================================================

export const selectCategories = createSelector(
    [selectAdminReducer],
    state => {
        if (state.categories) {
            return state.categories;
        } else {
            return null;
        }
    }
);
export const selectCategoriesTotalPages = createSelector(
    [selectAdminReducer],
    state => {
        if (state.categories && state.categories.totalPages) {
            return state.categories.totalPages;
        } else {
            return null;
        }
    }
);
export const selectACategory = createSelector(
    [selectAdminReducer],
    state => {
        if (state.aCategory) {
            return state.aCategory;
        } else {
            return null;
        }
    }
);
export const selectCreateCategoryStatus = createSelector(
    [selectAdminReducer],
    state => {
        if (state.createCategory && state.createCategory.status) {
            return state.createCategory.status
        }
    }
);
export const selectCreateCategoryMessage = createSelector(
    [selectAdminReducer],
    state => {
        if (state.createCategory && state.createCategory.message) {
            return state.createCategory.message
        }
    }
);
export const selectUpdateCategoryStatus = createSelector(
    [selectAdminReducer],
    state => {
        if (state.updateCategory && state.updateCategory.status) {
            return state.updateCategory.status;
        }
    }
);
export const selectUpdateCategoryMessage = createSelector(
    [selectAdminReducer],
    state => {
        if (state.updateCategory && state.updateCategory.message) {
            return  state.updateCategory.message;
        }
    }
);
export const selectRemoveCategoryStatus = createSelector(
    [selectAdminReducer],
    state => {
        if (state.removeCategory && state.removeCategory.status) {
            return state.removeCategory.status;
        }
    }
);
export const selectRemoveCategoryMessage = createSelector(
    [selectAdminReducer],
    state => {
        if (state.removeCategory && state.removeCategory.message) {
            return state.removeCategory.message;
        }
    }
);
export const selectResetFailed= createSelector(
    [selectAdminReducer],
    state => state.resetFailed
);

// =========================================================================
// ============================ BRAND SELECTOR ============================
// =========================================================================

export const selectAllBrands = createSelector(
    [selectAdminReducer],
    state => {
        if (state.brands) {
            const usages = new Set();
            for (const item of state.brands.content) {
                usages.add(item.usage);
            }
            let res = []
            const usagesArr = Array.from(usages);
            for (let i = 0; i < usagesArr.length; i++) {
                res.push({
                    name: usagesArr[i].charAt(0).toUpperCase() + usagesArr[i].slice(1),
                    value: usagesArr[i]
                })
            }
            return res;
        } else {
            return null;
        }
    }
);

export const selectBrandNames = createSelector(
    [selectAdminReducer],
    state => {
        if (state.usageName) {
            let res = [];
            for (const item of state.usageName.content) {
                res.push(
                    {name: item.name, value: item.id});
            }
            return res;
        } else {
            return null;
        }
    }
);

export const selectBrands = createSelector(
    [selectAdminReducer],
    state => {
        if (state.brands) {
            return state.brands;
        } else {
            return null;
        }
    }
);
export const selectBrandsTotalPages = createSelector(
    [selectAdminReducer],
    state => {
        if (state.brands && state.brands.totalPages) {
            return state.brands.totalPages;
        } else {
            return null;
        }
    }
);
export const selectABrand = createSelector(
    [selectAdminReducer],
    state => {
        if (state.aBrand) {
            return state.aBrand;
        } else {
            return null;
        }
    }
);
export const selectCreateBrandStatus = createSelector(
    [selectAdminReducer],
    state => {
        if (state.createBrand && state.createBrand.status) {
            return state.createBrand.status
        }
    }
);
export const selectCreateBrandMessage = createSelector(
    [selectAdminReducer],
    state => {
        if (state.createBrand && state.createBrand.message) {
            return state.createBrand.message
        }
    }
);
export const selectUpdateBrandStatus = createSelector(
    [selectAdminReducer],
    state => {
        if (state.updateBrand && state.updateBrand.status) {
            return state.updateBrand.status
        }
    }
);
export const selectUpdateBrandMessage = createSelector(
    [selectAdminReducer],
    state => {
        if (state.updateBrand && state.updateBrand.message) {
            return state.updateBrand.message
        }
    }
);
export const selectRemoveBrandStatus = createSelector(
    [selectAdminReducer],
    state => {
        if (state.removeBrand && state.removeBrand.status) {
            return state.removeBrand.status
        }
    }
);
export const selectRemoveBrandMessage = createSelector(
    [selectAdminReducer],
    state => {
        if (state.removeBrand && state.removeBrand.message) {
            return state.removeBrand.message
        }
    }
);


// =========================================================================
// ============================ TYPE SELECTOR ============================
// =========================================================================


export const selectTypesByBrand = createSelector(
    [selectAdminReducer],
    state => {
        if (state.typesByBrand) {
            return state.typesByBrand;
        } else {
            return null;
        }
    }
);
export const selectTypes = createSelector(
    [selectAdminReducer],
    state => {
        if (state.types) {
            return state.types;
        } else {
            return null;
        }
    }
);
export const selectTypesTotalPages = createSelector(
    [selectAdminReducer],
    state => {
        if (state.types && state.types.totalPages) {
            return state.types.totalPages;
        } else {
            return null;
        }
    }
);
export const selectAType = createSelector(
    [selectAdminReducer],
    state => {
        if (state.aType) {
            return state.aType;
        } else {
            return null;
        }
    }
);
export const selectCreateTypeStatus = createSelector(
    [selectAdminReducer],
    state => {
        if (state.createType && state.createType.status) {
            return state.createType.status;
        }
    }
);
export const selectCreateTypeMessage = createSelector(
    [selectAdminReducer],
    state => {
        if (state.createType && state.createType.message) {
            return state.createType.message;
        }
    }
);
export const selectUpdateTypeStatus = createSelector(
    [selectAdminReducer],
    state => {
        if (state.updateType && state.updateType.status) {
            return state.updateType.status;
        }
    }
);
export const selectUpdateTypeMessage = createSelector(
    [selectAdminReducer],
    state => {
        if (state.updateType && state.updateType.message) {
            return state.updateType.message;
        }
    }
);
export const selectRemoveTypeStatus = createSelector(
    [selectAdminReducer],
    state => {
        if (state.removeType && state.removeType.status) {
            return state.removeType.status;
        }
    }
);
export const selectRemoveTypeMessage = createSelector(
    [selectAdminReducer],
    state => {
        if (state.removeType && state.removeType.message) {
            return state.removeType.message;
        }
    }
);

// =========================================================================
// ============================ MANNET ============================
// =========================================================================
export const selectDeleteMannetByNetworkStatus = createSelector(
    [selectAdminReducer],
    state => state.deleteMannetByNetworkStatus
);

// =========================================================================
// ============================ LOGGING ============================
// =========================================================================
export const selectLogResponse = createSelector(
    [selectAdminReducer],
    state => {
        if (state.logResponse) {
            const content = state.logResponse.content;
            if (content && content.length) {
                for (let i = 0; i < content.length; i++) {
                    const createdAt = content[i].createdAt;
                    const date = new Date(Date.parse(createdAt));
                    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour12:false, hour:'2-digit', minute:'2-digit', second:'2-digit' };
                    content[i].createdAt = date.toLocaleDateString('id-ID', options).replace(/\//g, '-').replace(/,/g," ").replace(/\./g,":");
                    const usage = content[i].usage.split("#");
                    if (usage.length > 1) {
                        content[i].usage = usage[0].toUpperCase();
                        content[i].usageName = usage[1].toUpperCase();
                    }

                }
            }
            return state.logResponse;
        } else {
            return null;
        }
    }
);
export const selectTotalPages = createSelector(
    [selectAdminReducer],
    state => {
        if (state.logResponse && state.logResponse.totalPages) {
            return state.logResponse.totalPages;
        } else {
            return 0;
        }
    }
);