import {ACTION_DELETE, ACTION_GET, ACTION_POST, BASE_URL} from "./core";

export const GET_ALL_OP = (page, size) => {
    const url = `${BASE_URL}/op?page=${page}&size=${size}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const DELETE_MAINTENANCE = (id) => {
    const url = `${BASE_URL}/op/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const GET_OP_BY_ID = (id) => {
    const url = `${BASE_URL}/op/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const GET_OP_BY_DATE = (id, date) => {
    const url = `${BASE_URL}/op/${id}?date=${date}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const GET_ACTUAL_DATE_BY_OP_ID = (id) => {
    const url = `${BASE_URL}/op/${id}/actual/date`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const POST_ACTUAL_OP = (body) => {
    const url = `${BASE_URL}/op/create/actual`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, body)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const DELETE_ACTUAL_BY_OP_ID = (id) => {
    const url = `${BASE_URL}/op/delete/actual/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

