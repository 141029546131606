import * as React from "react"

const RulerIcon = (props) => (
  <svg
    width={33}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 27a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM24.5 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM22.375 10.125l-11.75 11.75"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default RulerIcon
