import {ASSET_ACTION_TYPES} from "./asset.types";
import {GET_ALL_ASSET, GET_TOTAL_ASSET} from "../../services/asset";
import {GET_ALL_SITE} from "../../services/site";
import {createAction} from "../../utils/reducer/reducerUtil";
import {drawEntity, removeEntityById} from "../../utils/cesium/cesiumUtils";
import {defined, ScreenSpaceEventHandler, ScreenSpaceEventType} from "cesium";


export const handleFetchData = () => async (dispatch) => {
    const res = await GET_ALL_ASSET();
    if (res && res.totalCount && res.totalCount > 0) {
        dispatch(createAction(ASSET_ACTION_TYPES.FETCH_DATA_ASSET, res));
    }
}

export const handleFetchDataSite = (viewer) => async (dispatch) => {
    const res = await GET_ALL_SITE();
    if (res && res.content) {
        dispatch(drawSite(viewer, res));
        dispatch(handleRegisterHoverListener(viewer, res));
    }
}

export const handleClearSite = (viewer, sites) => {
    removeEntityById(viewer, sites);
};

export const drawSite = (viewer, sites) => (dispatch) => {
    const newSites = drawEntity(viewer, sites);
    dispatch(createAction(ASSET_ACTION_TYPES.FETCH_DATA_SITE, newSites));
}


export const fetchDataTotal = (site) => dispatch => {
    GET_TOTAL_ASSET(site.id)
        .then(res => {
            const copyRes = {...res}
            copyRes.siteName = site.siteName;
            dispatch(createAction(ASSET_ACTION_TYPES.FETCH_TOTAL_ASSET, copyRes))
        })
        .catch(() => {});
}

export const handleRegisterHoverListener = (viewer, sites) => async (dispatch) => {
    if (viewer && sites && sites.content) {
        const handler = new ScreenSpaceEventHandler(viewer.scene.canvas);
        const eventListener = (movement) => {
            const pickedObject = viewer.scene.pick(movement.endPosition);
            if (pickedObject && pickedObject.id) {
                const site = sites.content.find(e => e.entityId === pickedObject.id);
                if (defined(pickedObject) && site) {
                    if (site && site.id) {
                        dispatch(createAction(ASSET_ACTION_TYPES.OPEN_TOOLTIPS, true));
                        dispatch(createAction(ASSET_ACTION_TYPES.SELECTED_SITE_ID, site));
                    }
                }
            } else {
                dispatch(createAction(ASSET_ACTION_TYPES.OPEN_TOOLTIPS, false));
            }
        }
        handler.setInputAction(eventListener, ScreenSpaceEventType.MOUSE_MOVE);

        dispatch(createAction(ASSET_ACTION_TYPES.REGISTER_HANDLER_LISTENER, {handler, eventListener}));
    } else {
        dispatch(createAction(ASSET_ACTION_TYPES.OPEN_TOOLTIPS, false));
    }
}