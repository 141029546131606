import {ASSET_TYPE, STATUS_TYPE} from "../../utils/constant/constants";
import {GLOBAL_ACTION_TYPES} from "./global.types";

const INITIAL_STATE = {
    assetToRemove: ASSET_TYPE.NS,
    assetToEdit: ASSET_TYPE.NS,
    detailAsset: ASSET_TYPE.NS,
    checkAsset: ASSET_TYPE.NS,
    item: null,
    syncStatus: STATUS_TYPE.NO_STATEMENT,
    isLoading: false,
    isLoadingSync: false,
    notifyNotConnectedToBackend: STATUS_TYPE.NO_STATEMENT,
}

export const globalReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND:
            return {...state, notifyNotConnectedToBackend: payload}
        case GLOBAL_ACTION_TYPES.REMOVE_ASSET:
            return {...state, assetToRemove: payload.assetType, item: payload.item}
        case GLOBAL_ACTION_TYPES.SYNC_STATUS:
            return {...state, syncStatus: payload}
        case GLOBAL_ACTION_TYPES.IS_LOADING:
            return {...state, isLoading: payload}
        case GLOBAL_ACTION_TYPES.IS_LOADING_SYNC:
            return {...state, isLoadingSync: payload}
        case GLOBAL_ACTION_TYPES.EDIT_ASSET:
            return {...state, assetToEdit: payload.assetType, item: payload.item}
        case GLOBAL_ACTION_TYPES.SHOW_DETAIL_ASSET:
            return {...state, detailAsset: payload.assetType, item: payload.item}
        case GLOBAL_ACTION_TYPES.CHECK_ASSET:
            return {...state, checkAsset: payload.assetType, item: payload.item}
        case GLOBAL_ACTION_TYPES.CLEAR_GLOBAL_STATE:
            return {...state,
                assetToRemove: INITIAL_STATE.assetToRemove,
                assetToEdit: INITIAL_STATE.assetToEdit,
                detailAsset: INITIAL_STATE.detailAsset,
                checkAsset: INITIAL_STATE.checkAsset,
                item: INITIAL_STATE.item
            }
        default :
            return state;
    }
}