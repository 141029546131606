import React, { useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import ReportMultipleRadio from "../report/asset/ReportMultipleRadio";
import ReportMultipleAntenna from "../report/asset/ReportMultipleAntenna";
import ReportMultipleTower from "../report/asset/ReportMultipleTower";
import ReportMultiplePABX from "../report/asset/ReportMultiplePABX";
import ReportMultipleSupporting from "../report/asset/ReportMultipleSupporting";

ChartJS.register(ArcElement, Tooltip, Legend);

const RenderReport = ({ report, data, assetList, statusAsset, chartImage }) => {
  switch (report) {
    case "radio":
        return (
            <ReportMultipleRadio
                assetList={assetList}
                data={data}
                statusAsset={statusAsset}
                chartImage={chartImage}
            />
        );
    case "antenna":
        return (
            <ReportMultipleAntenna
                assetList={assetList}
                data={data}
                statusAsset={statusAsset}
                chartImage={chartImage}
            />
        );
    case "tower":
        return (
            <ReportMultipleTower
                assetList={assetList}
                data={data}
                statusAsset={statusAsset}
                chartImage={chartImage}
            />
        );
    case "pabx":
        return (
            <ReportMultiplePABX
                assetList={assetList}
                data={data}
                statusAsset={statusAsset}
                chartImage={chartImage}
            />
        );
    case "dss":
        return (
            <ReportMultipleSupporting
                assetList={assetList}
                data={data}
                statusAsset={statusAsset}
                chartImage={chartImage}
            />
        );
    default:
        return "";
  }
};

const Chart = ({ datas, report, assetList, statusAsset, customStyle }) => {
    const location = useLocation();

    let ref = useRef(null);

    const downloadChart = useCallback(() => {
        const chartPNG = ref.current.toBase64Image();
        return chartPNG;
    }, []);

    const renderChart = (data, index, customStyle) => {
        if (customStyle === "wide") {
            return (
                <div
                    className={`${
                        location.pathname === "/assetdata" ? "w-full" : "w-[320px]"
                    }`}
                    key={index}
                >
                    
                    <div className="flex items-center justify-between">
                        <p className="text-2xl text-white mb-2">{data.datasets[0].label}</p>
                        <p
                            className={`${
                                location.pathname === "/assetdata" ? "hidden" : ""
                            }`}
                        >
                            <RenderReport
                                report={report}
                                data={data}
                                assetList={assetList}
                                statusAsset={statusAsset}
                                chartImage={downloadChart}
                            />
                        </p>
                    </div>

                    <div className="flex justify-around items-center mb-5">
                        <div className="flex flex-col gap-y-5 mt-2 w-2/4">
                            <div
                                className="text-white flex border-solid border-b-[1px] border-white pb-2"
                            >
                                <span
                                    className="text-sm pt-0.5 text-serviceable bg-white rounded-xl px-3"
                                >
                                    SERVICEABLE
                                </span>
                                <span className="ml-auto">{`${data.datasets[0].data[1]}%`}</span>
                            </div>

                            <div className="text-white flex border-solid border-b-[1px] border-white pb-2">
                                <span
                                    className="text-sm pt-0.5 text-spare bg-white rounded-xl px-3"
                                >
                                    SPARE
                                </span>
                                <span className="ml-auto">{`${data.datasets[0].data[0]}%`}</span>
                            </div>

                            <div className="text-white flex border-solid border-b-[1px] border-white pb-2">
                                <span
                                    className="text-sm pt-0.5 text-unserviceable bg-white rounded-xl px-3"
                                >
                                    UNSERVICEABLE
                                </span>
                                <span className="ml-auto">{`${data.datasets[0].data[2]}%`}</span>
                            </div>
                        </div>

                        <Doughnut
                            ref={ref}
                            data={data}
                            className="max-w-[180px] max-h-[180px]"
                        />
                    </div>
                </div>
            );
        }

        if (customStyle !== "wide") {
        return (
            <div
                className={`${
                    location.pathname === "/assetdata" ? "w-[280px]" : "w-[320px]"
                } ml-6`}
                key={index}
            >
                <div className="flex items-center justify-between">
                    <p className="text-2xl text-white mb-3">{data.datasets[0].label}</p>
                    <p
                        className={`${
                            location.pathname === "/assetdata" ? "hidden" : ""
                        }`}
                    >
                        <RenderReport
                            report={report}
                            data={data}
                            assetList={assetList}
                            statusAsset={statusAsset}
                            chartImage={downloadChart}
                        />
                    </p>
                </div>
                <p className="text-xl text-white text-center">Overview Status</p>
                <Doughnut
                    ref={ref}
                    data={data}
                    className={`${
                    location.pathname === "/assetdata"
                        ? "max-w-[180px] max-h-[180px]"
                        : "max-w-[220px] max-h-[220px]"
                    } m-auto`}
                />
                <div className="flex flex-col gap-5 mt-2">
                    <div
                        className="text-white flex border-solid border-b-[1px] border-white pb-2"
                    >
                        <span
                            className={`${
                            location.pathname === "/assetdata"
                                ? "text-xs pt-1"
                                : "text-sm pt-0.5"
                            } text-[#3384FF] bg-white rounded-xl px-3`}
                        >
                            SERVICEABLE
                        </span>
                        <span className="ml-auto">{`${data.datasets[0].data[1]}%`}</span>
                    </div>

                    <div className="text-white flex border-solid border-b-[1px] border-white pb-2">
                        <span
                            className={`${
                            location.pathname === "/assetdata"
                                ? "text-xs pt-1"
                                : "text-sm pt-0.5"
                            } text-[#48B527] bg-white rounded-xl px-3`}
                        >
                            SPARE
                        </span>
                        <span className="ml-auto">{`${data.datasets[0].data[0]}%`}</span>
                    </div>

                    <div className="text-white flex border-solid border-b-[1px] border-white pb-2">
                        <span
                            className={`${
                            location.pathname === "/assetdata"
                                ? "text-xs pt-1"
                                : "text-sm pt-0.5"
                            } text-[#FF3A30] bg-white rounded-xl px-3`}
                        >
                            UNSERVICEABLE
                        </span>
                        <span className="ml-auto">{`${data.datasets[0].data[2]}%`}</span>
                    </div>
                </div>
            </div>
        );
        }
    };

    return (
        <>{datas && datas.map((data, index) => renderChart(data, index, customStyle))}</>
    );
};

export default Chart;
