import React from "react";

const EditIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0988 3.11426L13.2186 0.234232C13.0686 0.0842531 12.8651 0 12.6529 0C12.4408 0 12.2373 0.0842531 12.0873 0.234232L2.00656 10.3143C1.9186 10.402 1.85255 10.5092 1.81374 10.6271L0.373637 14.9472C0.333599 15.0674 0.322684 15.1954 0.341791 15.3206C0.360897 15.4459 0.40948 15.5648 0.483538 15.6676C0.557596 15.7704 0.655012 15.8541 0.767766 15.9119C0.880521 15.9697 1.00539 15.9999 1.13209 16C1.21799 15.9999 1.30333 15.9861 1.38491 15.9592L5.70523 14.5192C5.82296 14.48 5.92983 14.4136 6.01725 14.3256L16.098 4.24547C16.2481 4.09556 16.3325 3.89217 16.3326 3.68003C16.3328 3.4679 16.2487 3.26439 16.0988 3.11426ZM5.02038 13.0608L2.39699 13.9352L3.27145 11.3119L12.6529 1.93105L14.4019 3.67987L5.02038 13.0608Z"
        fill="#031952"
      />
    </svg>
  );
};

export default EditIcon;
