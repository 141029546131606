import * as React from "react"

const RadioTypeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 8267.72 11692.91"
    {...props}
  >
    <g strokeMiterlimit={22.926}>
      <path
        fill="currentColor"
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M-292.65 165.63h8904.69c677.3 0 1231.45 554.15 1231.45 1231.45v8904.69c0 677.3-554.15 1231.46-1231.45 1231.46H-292.65c-677.3 0-1231.46-554.15-1231.46-1231.46V1397.08c0-677.3 554.15-1231.45 1231.46-1231.45zm304.78 389.1h8295.11c630.96 0 1147.14 516.19 1147.14 1147.14v8295.11c0 630.96-516.19 1147.14-1147.14 1147.14H12.13c-630.96 0-1147.14-516.19-1147.14-1147.14V1701.87c0-630.96 516.19-1147.14 1147.14-1147.14z"
      />
      <rect
        fill="currentColor"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        x={480.57}
        y={4942.05}
        width={6994.7}
        height={3289.27}
        rx={303.87}
        ry={142.9}
      />
      <path
        fill="none"
        stroke="#2B2A29"
        strokeWidth={29.53}
        d="M3376.66 3041.33h3789.22c71.29 0 132.33 46.15 154.72 110.04 3.07 8.75 5.41 17.83 6.95 27.17l383.61 1500.45c13.95 24.07 21.96 51.99 21.96 81.68v3443.89c0 90.11-73.73 163.85-163.85 163.85H353.8c-90.11 0-163.85-73.73-163.85-163.85V4760.67c0-60.63 33.37-113.85 82.67-142.18 4.87-2.8 9.91-5.36 15.09-7.67L3244.66 3108.4c29.9-40.6 78-67.07 132-67.07z"
      />
      <circle
        fill="#FEFEFE"
        stroke="#2B2A29"
        strokeWidth={7.87}
        cx={2196.2}
        cy={6517.15}
        r={1342.08}
      />
      <circle
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        cx={4494.96}
        cy={6096.99}
        r={592.47}
      />
      <path
        fill="#FEFEFE"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        d="M4563.67 5296.95c249.92 0 455.84 188.34 483.57 430.84.06.48.11.96.16 1.43l28.97 253.29-.04-.01c-53.34-272.41-293.33-477.98-581.37-477.98-212.32 0-398.51 111.7-503.12 279.53l-.05-.01 138.65-222.45a486.96 486.96 0 0 1 42.57-68.31c88.72-119.17 230.68-196.35 390.66-196.35z"
      />
      <circle
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        cx={6301.28}
        cy={6061.97}
        r={392.53}
      />
      <path
        fill="#FEFEFE"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        d="M6346.81 5531.91c165.58 0 302.01 124.78 320.39 285.44.04.31.07.64.11.95l19.19 167.81-.03-.01c-35.34-180.48-194.34-316.68-385.18-316.68-140.67 0-264.03 74.01-333.33 185.2h-.03l91.86-147.38a322.36 322.36 0 0 1 28.2-45.26c58.78-78.95 152.83-130.09 258.83-130.09z"
      />
      <path
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={29.53}
        d="M2199.48 5443.02c725.67 0 1315 565.18 1325.07 1266.07-93.13 650.27-652.32 1150.14-1328.35 1150.14-661.42 0-1211.04-478.48-1321.72-1108.17-.15-7.91-.31-15.82-.31-23.76 0-709.3 593.36-1284.28 1325.31-1284.28z"
      />
      <g fill="#FEFEFE" stroke="#2B2A29" strokeWidth={9.84}>
        <rect
          x={1099.42}
          y={6816.92}
          width={2193.55}
          height={83.17}
          rx={74.92}
          ry={2.84}
        />
        <rect
          x={1099.42}
          y={6433.99}
          width={2193.55}
          height={83.17}
          rx={74.92}
          ry={2.84}
        />
        <rect
          x={1453.46}
          y={7243.22}
          width={1485.48}
          height={83.17}
          rx={50.73}
          ry={2.84}
        />
        <rect
          x={1453.46}
          y={5960.73}
          width={1485.48}
          height={83.17}
          rx={50.73}
          ry={2.84}
        />
      </g>
      <rect
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={59.06}
        x={4832.56}
        y={7470.6}
        width={1153.2}
        height={481.66}
        rx={39.38}
        ry={16.45}
      />
      <rect
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={59.06}
        x={3702.84}
        y={7470.6}
        width={779.5}
        height={481.66}
        rx={26.62}
        ry={16.45}
      />
      <rect
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={59.06}
        x={6336.02}
        y={7470.6}
        width={779.5}
        height={481.66}
        rx={26.62}
        ry={16.45}
      />
    </g>
  </svg>
)

export default RadioTypeIcon
