import {ELECTRONIC_ACTION_TYPES} from "./electronic.types";
import {STATUS_TYPE} from "../../utils/constant/constants";

const INITIAL_STATE = {
    status: null,  // {}
    electronics: null, // {radio:{content:[]}}
    detailElectronic: null,
    sites: null, // {content:[]}
    
    column: [
        {heading: "Name", value: "name"},
        {heading: "Latitude", value: "lat"},
        {heading: "Longitude", value: "lon"},
        {heading: "Address", value: "address"},
        {heading: "Phone", value: "phone"},
    ],
    uploadElectronicStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedImages: [],
    uploadImageStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedFiles: [],
    uploadFileStatus: STATUS_TYPE.NO_STATEMENT,
    isLoading: false,
    removeStatus: STATUS_TYPE.NO_STATEMENT,
    updateStatus: STATUS_TYPE.NO_STATEMENT,
}

export const electronicReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case ELECTRONIC_ACTION_TYPES.GET_ALL_ELECTRONIC:
            return {...state, electronics: payload};
        case ELECTRONIC_ACTION_TYPES.SEARCH:
            return {...state, electronics: payload};
        case ELECTRONIC_ACTION_TYPES.DETAIL_ELECTRONIC:
            return {...state, detailElectronic: payload};
        case ELECTRONIC_ACTION_TYPES.CLEAR_DETAIL_ELECTRONIC:
            return {...state, detailElectronic: null};
        case ELECTRONIC_ACTION_TYPES.GET_CATEGORY:
            return {...state, categories: payload};
        case ELECTRONIC_ACTION_TYPES.GET_TYPE:
            return {...state, types: payload};
        case ELECTRONIC_ACTION_TYPES.GET_BRAND:
            return {...state, brands: payload};
        case ELECTRONIC_ACTION_TYPES.GET_SITE:
            return {...state, sites: payload};
        case ELECTRONIC_ACTION_TYPES.CLEAR_DATA:
            return INITIAL_STATE;
        case ELECTRONIC_ACTION_TYPES.UPLOAD_ELECTRONIC_STATUS:
            return {...state, uploadElectronicStatus: payload, uploadedFiles: [], uploadedImages: []}
        case ELECTRONIC_ACTION_TYPES.UPLOAD_IMAGE:
            return {...state, uploadedImages: payload}
        case ELECTRONIC_ACTION_TYPES.UPLOAD_IMAGE_STATUS:
            return {...state, uploadImageStatus: payload}
        case ELECTRONIC_ACTION_TYPES.UPLOAD_FILE:
            return {...state, uploadedFiles: payload}
        case ELECTRONIC_ACTION_TYPES.UPLOAD_FILE_STATUS:
            return {...state, uploadFileStatus: payload}
        case ELECTRONIC_ACTION_TYPES.REMOVED:
            return {...state, removeStatus: payload}
        case ELECTRONIC_ACTION_TYPES.UPDATED:
            return {...state, updateStatus: payload}
        default:
            return state;
    }
}