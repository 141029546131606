import * as React from "react"

const Propagation = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 10.125V20.25M12 3.75v2.625M12 10.125a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM18.75 17.625v2.625M18.75 3.75v10.125M18.75 17.625a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM5.25 14.625v5.625M5.25 3.75v7.125M5.25 14.625a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Propagation
