import {MAINTENANCE_ACTION_TYPES} from "./maintenance.types";
import {PROCESS_TYPE, STATUS_TYPE} from "../../utils/constant/constants";

const INITIAL_STATE = {
    allMaintenanceData: null, // {content[{id, siteId, assetId, assetType, status, condition, location, preventive, corrective, note,action}], pageable{sort{empty, sorted, unsorted}, offset, pageNumber, pageSize, paged, unpaged}, last, totalElements,totalPages,number,sort, size, first, numberOfElements, empty}
    dataAsset: {
        radio: [],
        antenna: [],
        tower: [],
        pabx: [],
        dss: [],
    },
    selectedSite: null, // site name
    allAsset: null, // {content:[]}
    allSite: null, // {content:[]}
    selectedAssetId: null, // asset id
    selectedAssetType: null, // name
    selectedAssetData: null, // {content:[]}
    selectedMaintenanceType: null, // {type, value}
    maintenanceData: null, // {}
    maintenanceSchedule: null, // {content:[]}
    sendToLogResponse: null, // {content:[]}
    historyData: null, // {content:[]}
    goToEdit: false, // {content:[]}
    selectedMaintenanceData: null, // {content:[]}
    processType: PROCESS_TYPE.NS,
    removeStatus: STATUS_TYPE.NO_STATEMENT,
    createStatus: STATUS_TYPE.NO_STATEMENT,
    updateStatus: STATUS_TYPE.NO_STATEMENT,
    sendToLogStatus: STATUS_TYPE.NO_STATEMENT,
    updateWithNewTask: false,
    errorMessage: "",

}

export const maintenanceReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case MAINTENANCE_ACTION_TYPES.ERROR_MESSAGE:
            return {...state, errorMessage: payload}
        case MAINTENANCE_ACTION_TYPES.SEND_TO_LOG_STATUS:
            return {...state, sendToLogStatus: payload}
        case MAINTENANCE_ACTION_TYPES.REMOVE_STATUS:
            return {...state, removeStatus: payload}
        case MAINTENANCE_ACTION_TYPES.CREATE_STATUS:
            return {...state, createStatus: payload}
        case MAINTENANCE_ACTION_TYPES.UPDATE_STATUS:
            return {...state, updateStatus: payload}
        case MAINTENANCE_ACTION_TYPES.GET_ALL:
            return {...state, allMaintenanceData: payload}
        case MAINTENANCE_ACTION_TYPES.GET_ALL_REPORT:
            return {...state, allMaintenanceDataReport: payload}
        case MAINTENANCE_ACTION_TYPES.CLEAR_DATA:
            return INITIAL_STATE
        case MAINTENANCE_ACTION_TYPES.ASSET_DATA:
            if (payload) {
                const {res, type} = payload;
                const newDataAsset = {...state.dataAsset};

                if (type === "antenna") {
                    newDataAsset.antenna.push(res);
                } else if (type === "radio") {
                    newDataAsset.radio.push(res);
                } else if (type === "pabx") {
                    newDataAsset.pabx.push(res);
                } else if (type === "tower") {
                    newDataAsset.tower.push(res);
                } else if (type === "dss") {
                    newDataAsset.dss.push(res);
                }
                return {...state, dataAsset: newDataAsset}
            } else {
                return {...state}
            }

        case MAINTENANCE_ACTION_TYPES.CLEAR_DATA_ASSET:
            const newDataAsset = {
                radio: [],
                antenna: [],
                tower: [],
                pabx: [],
                dss: [],
            }
            return {...state, dataAsset: newDataAsset}

        case MAINTENANCE_ACTION_TYPES.GET_ALL_ASSET:
            return {...state, allAsset: payload}
        case MAINTENANCE_ACTION_TYPES.RESET_ALL_ASSET_TABLE:
            return {...state, allAsset: null}
        case MAINTENANCE_ACTION_TYPES.GET_SITE:
            return {...state, allSite: payload}
        case MAINTENANCE_ACTION_TYPES.SELECTED_ROW_WITH_ID:
            return {...state, selectedAssetId: payload}
        case MAINTENANCE_ACTION_TYPES.SELECTED_ASSET_TYPE:
            return {...state, selectedAssetType: payload}
        case MAINTENANCE_ACTION_TYPES.SELECTED_ASSET_DATA:
            return {...state, selectedAssetData: payload}
        case MAINTENANCE_ACTION_TYPES.SELECTED_MAINTENANCE_TYPE:
            return {...state, selectedMaintenanceType: payload}
        case MAINTENANCE_ACTION_TYPES.CREATE_MAINTENANCE:
            return {...state, maintenanceData: payload}
        case MAINTENANCE_ACTION_TYPES.GET_ALL_SCHEDULE:
            return {...state, maintenanceSchedule: payload}
        case MAINTENANCE_ACTION_TYPES.SEND_TO_LOG:
            return {...state, sendToLogResponse: payload}
        case MAINTENANCE_ACTION_TYPES.GET_ALL_HISTORY:
            return {...state, historyData: payload}
        case MAINTENANCE_ACTION_TYPES.GET_ALL_HISTORY_REPORT:
            return {...state, historyDataReport: payload}
        case MAINTENANCE_ACTION_TYPES.START_CREATE_OR_UPDATE:
            return {...state, processType: payload}
        case MAINTENANCE_ACTION_TYPES.DONE_CREATE_OR_UPDATE:
            return {...state, processType: payload}
        case MAINTENANCE_ACTION_TYPES.HANDLE_EDIT:
            const {go, res} = payload
            return {...state, goToEdit: go, selectedMaintenanceData: res}
        default:
            return state;
    }
}