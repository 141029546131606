import {ACTION_GET, ACTION_PUT, ACTION_POST, ACTION_DELETE, BASE_URL} from "./core";


export const SEARCH_BRAND = (page, size, type, q) => {
    const url = `${BASE_URL}/refBrand?page=${page}&size=${size}&q=${q}&type=${type}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const GET_BRAND_BY_ID = (id) => {
    const url = `${BASE_URL}/refBrand/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const CREATE_BRAND = (param) => {
    const url = `${BASE_URL}/refBrand`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const UPDATE_BRAND = (id, param) => {
    const url = `${BASE_URL}/refBrand/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, param)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const DELETE_BRAND_BY_ID = (id) => {
    const url = `${BASE_URL}/refBrand/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

