export const ADMIN_ACTION_TYPES = {
    GET_TYPE_ARCHIVE : "admin/GET_TYPE_ARCHIVE",
    REMOVE_ARCHIVE : "admin/REMOVE_ARCHIVE",
    // CATEGORY
    CATEGORIES_DATA : "admin/CATEGORIES_DATA",
    GET_A_CATEGORY : "admin/GET_A_CATEGORY",
    FAILED_TO_GET_A_CATEGORY : "admin/FAILED_TO_GET_A_CATEGORY",
    ACTION_CREATE_STATUS_CATEGORY : "admin/ACTION_CREATE_STATUS_CATEGORY",
    ACTION_UPDATE_STATUS_CATEGORY : "admin/ACTION_UPDATE_STATUS_CATEGORY",
    ACTION_REMOVE_STATUS_CATEGORY : "admin/ACTION_REMOVE_STATUS_CATEGORY",
    CLEAR_ACTION_STATUS_CATEGORY: "admin/CLEAR_ACTION_STATUS_CATEGORY",
    // BRAND
    BRANDS_DATA: "admin/BRANDS_DATA",
    GET_A_BRAND : "admin/GET_A_BRAND",
    ACTION_CREATE_STATUS_BRAND : "admin/ACTION_CREATE_STATUS_BRAND",
    ACTION_UPDATE_STATUS_BRAND : "admin/ACTION_UPDATE_STATUS_BRAND",
    ACTION_REMOVE_STATUS_BRAND : "admin/ACTION_REMOVE_STATUS_BRAND",
    CLEAR_ACTION_STATUS_BRAND: "admin/CLEAR_ACTION_STATUS_BRAND",
    // TYPE
    USAGE_NAME: "admin/USAGE_NAME",
    TYPES_BY_BRAND_ID: "admin/TYPES_BY_BRAND_ID",
    TYPES_DATA: "admin/TYPES_DATA",
    GET_A_TYPE : "admin/GET_A_TYPE",
    ACTION_CREATE_STATUS_TYPE : "admin/ACTION_CREATE_STATUS_TYPE",
    ACTION_UPDATE_STATUS_TYPE : "admin/ACTION_UPDATE_STATUS_TYPE",
    ACTION_REMOVE_STATUS_TYPE : "admin/ACTION_REMOVE_STATUS_TYPE",
    CLEAR_TYPES_BY_BRAND_ID: "admin/CLEAR_TYPES_BY_BRAND_ID",
    CLEAR_ACTION_STATUS_TYPE: "admin/CLEAR_ACTION_STATUS_TYPE",
    // MANNET
    DELETE_MANNET_BY_NETWORK_STATUS: "admin/DELETE_MANNET_BY_NETWORK_STATUS",
    FETCH_MANNET: "admin/FETCH_MANNET",
    // LOGGING
    FETCH_LOGS:"admin/FETCH_LOG"
}

export const COLUMN_SIMULATION = [
    {heading: "Name", value: "name"},
    {heading: "Network", value: "network"}
];
export const COLUMN_SIMULATION_MESH = [{heading: "Network Name", value: "name"}];
export const COLUMN_USER = [{heading: "Name", value: "name"}];
export const COLUMN_CATEGORY = [{ heading: "Category Name", value: "name" }, { heading: "Usage", value: "usage" }];
export const COLUMN_BRAND = [{ heading: "Brand Name", value: "name" }, { heading: "Usage", value: "usage" }];
export const COLUMN_TYPE = [{ heading: "Type Name", value: "name" }, { heading: "Usage", value: "usage" }, { heading: "Brand Name", value: "brandName" }];
export const LOGGING_TYPE = [{ heading: "User", value: "user" }, { heading: "Action", value: "action" }, { heading: "Usage", value: "usage" }, { heading: "Name", value: "usageName" }, { heading: "Time", value: "createdAt"}];
export const AREA = "AREA";
export const PATH = "PATH";
export const MESH = "MESH";
export const GTA = "GTA";
export const GTG = "GTG";
export const JAMMER = "JAMMER";
export const EMPTY_STRING = "";
export const NUMBER_ZERO = 0;
export const NUMBER_TEN = 10;
export const SUCCESS = "SUCCESS";
export const FAILED = "FAILED";
export const FILTER_BY_ACTION = "action"
export const FILTER_BY_NAME = "name"
export const FILTER_BY_BRAND = "brand"
export const FILTER_BY_USAGE = "usage"
export const ACTION_TYPE_DATA_CREATE = "CREATE"
export const ACTION_TYPE_DATA_UPDATE = "UPDATE"
export const ACTION_TYPE_DATA_REMOVE = "REMOVE"
export const ACTION_TYPE_DATA_NS = "No Statement"