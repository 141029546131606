import {RULER_ACTION_TYPES} from './ruler.types'

const INITIAL_STATE = {
    rulers: [{
        points: [],
        polyline: null,
        label: null,
        primitiveLabel: null
    }], // id entity
    points: [], // array of object entityPoint, latitude, longitude
    eventListener: null, // input handler
    isRulerActive: false,
    privimiveLabel: null,
    position: null, // cartesian
    handlerEventListener: null // screenSpaceEventHandler
};

export const rulerReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case RULER_ACTION_TYPES.HANDLE_RULER_TOGGLE:
            return {...state, isRulerActive: !state.isRulerActive};
        case RULER_ACTION_TYPES.HANDLE_RULER_TOGGLE_DISABLE:
            return {...state, isRulerActive: false};
        case RULER_ACTION_TYPES.ADD_EVENT_HANDLER:
            const {handlerClick, eventListener} = payload
            return {...state, eventListener: eventListener, handlerEventListener: handlerClick};
        case RULER_ACTION_TYPES.CLEAR_RULER:
            const rulers = state.rulers;
            const points = state.points;
            const viewer  = payload;

            if (rulers) {
                for (let i = 0; i < rulers.length; i++) {
                    if (rulers[i].points) {
                        for (let j = 0; j < rulers[i].points.length; j++) {
                            viewer.entities.remove(rulers[i].points[j].entityPoint)
                        }
                        viewer.entities.remove(rulers[i].polyline)
                        viewer.entities.remove(rulers[i].label)
                        viewer.entities.remove(rulers[i].primitiveLabel)
                    }
                }
            }
            if (points) {
                for (let i = 0; i < points.length; i++) {
                    viewer.entities.remove(points[i].entityPoint)
                }
            }

            return INITIAL_STATE;
        case RULER_ACTION_TYPES.ADD_POINT:
            return {...state, points: payload};
        case RULER_ACTION_TYPES.ADD_A_RULER:
            const copyRulers = [...state.rulers]
            const {polylineEntity, labelEntity, newPoints} = payload

            const newObj = {
                points: newPoints,
                polyline: polylineEntity,
                label: labelEntity,
                primitiveLabel: state.privimiveLabel ? state.privimiveLabel : null
            }
            copyRulers.push(newObj)
            return {...state, rulers: copyRulers, points: [], primitiveLabel: null};
        case RULER_ACTION_TYPES.PUT_PRIMITIVE_LABEL:
            return {...state, privimiveLabel: payload}
        case RULER_ACTION_TYPES.PUT_POSITION_ONCLICK:
            return {...state, position: payload}
        case RULER_ACTION_TYPES.DO_NOTHING:
            return {...state}
        default:
            return state;
    }
};