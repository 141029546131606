import {ACTION_DELETE, ACTION_GET, ACTION_POST, ACTION_PUT, BASE_URL} from "./core";

export const GET_ALL_USER = (page, size, q, siteId) => {
  const url = `${BASE_URL}/users?page=${page}&size=${size}&q=${q}&siteId=${siteId}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_USER_BY_ID = (id) => {
  const url = `${BASE_URL}/users/${id}/detail`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const CREATE_USER = (param) => {
  const url = `${BASE_URL}/users`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_USER = (id, param) => {
  const url = `${BASE_URL}/users/${id}`;
  return new Promise((resolve, reject) => {
    ACTION_PUT(url, param)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
  });
};

export const DELETE_USER = (id) => {
  const url = `${BASE_URL}/users/${id}`;
  return new Promise((resolve, reject) => {
    ACTION_DELETE(url)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
  });
};

export const LOGIN = (param) => {
  const url = `${BASE_URL}/login`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
