import {createSelector} from "reselect";
import {getPercentage} from "../../utils/calculate/convert";

const selectTowerReducer = (state) => state.towerReducer;

export const selectAllTower = createSelector(
    [selectTowerReducer],
    (state) => state.towers
)
export const selectAllTowerReport = createSelector(
    [selectTowerReducer],
    (state) => state.towersReport
)
export const selectTotalPages = createSelector(
    [selectAllTower],
    (towers) => {
        if (towers && towers.tower) {
            return towers.tower.totalPages
        } else {
            return null
        }
    }
)

export const selectCategories = createSelector(
    [selectTowerReducer],
    (state) => state.categories
)
export const selectTowerDistributionData = createSelector(
    [selectTowerReducer],
    (state) => state.towerDistributionData
)
export const selectTowerMiliterData = createSelector(
    [selectTowerReducer],
    (state) => state.towerMiliterData
)

export const selectDetailTower = createSelector(
    [selectTowerReducer],
    (state) => state.detailTower
)
export const selectBrands = createSelector(
    [selectTowerReducer],
    (state) => state.brands
)
export const selectTypes = createSelector(
    [selectTowerReducer],
    (state) => state.types
)
export const selectSites = createSelector(
    [selectTowerReducer],
    (state) => state.sites
)
export const selectDataChart = createSelector(
    [selectAllTower],
    (state) => {
        if (state && state.summary) {
            const status = state.summary;
            return [{
                datasets: [
                    {
                        label: "Tower",
                        data: [
                            getPercentage(status.spare, status.total),
                            getPercentage(status.service, status.total),
                            getPercentage(status.unservice, status.total)
                        ],
                        backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                        borderColor: ["#040f0f"],
                        borderWidth: 10,
                    },
                ],
            }]
        } else {
            return null;
        }
    }
)
export const selectStatusTower = createSelector(
    [selectAllTower],
    (state) => {
        if (state && state.summary) {
            const status = state.summary;
            return [
                {
                    id: 1,
                    name: "Tower Total",
                    item: status.total ?? 0,
                    icon: "tower",
                    class: "",
                },
                {
                    id: 2,
                    name: "Tower Serviceable",
                    item: status.service ?? 0,
                    icon: "tower",
                    class: "serviceable",
                },
                {
                    id: 3,
                    name: "Tower Spare",
                    item: status.spare ?? 0,
                    icon: "tower",
                    class: "spare",
                },
                {
                    id: 4,
                    name: "Tower Unserviceable",
                    item: status.unservice ?? 0,
                    icon: "tower",
                    class: "unserviceable",
                },
            ]
        } else {
            return null;
        }
    }
)

export const selectStatusArray = createSelector(
    [selectTowerReducer],
    (state) => state.statusArray
)
export const selectColumn = createSelector(
    [selectTowerReducer],
    (state) => state.column
)
export const selectUploadedImages = createSelector(
    [selectTowerReducer],
    (state) => state.uploadedImages
)
export const selectUploadedFiles = createSelector(
    [selectTowerReducer],
    (state) => state.uploadedFiles
)
export const selectUploadedTowerStatus = createSelector(
    [selectTowerReducer],
    (state) => state.uploadTowerStatus
)
export const selectUpdateStatus = createSelector(
    [selectTowerReducer],
    (state) => state.updateStatus
)
export const selectRemoveStatus = createSelector(
    [selectTowerReducer],
    (state) => state.removeStatus
)
export const selectIsWhiteToggleActive = createSelector(
    [selectTowerReducer],
    (state) => state.isWhiteToggleActive
)
export const selectIsRedToggleActive = createSelector(
    [selectTowerReducer],
    (state) => state.isRedToggleActive
)