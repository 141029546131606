import * as React from "react"

const OperationDataIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 8267.72 11692.91"
    {...props}
  >
    <g strokeMiterlimit={22.926}>
      <path
        fill="currentColor"
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M-292.65 165.63h8904.69c677.3 0 1231.45 554.15 1231.45 1231.45v8904.69c0 677.3-554.15 1231.46-1231.45 1231.46H-292.65c-677.3 0-1231.46-554.15-1231.46-1231.46V1397.08c0-677.3 554.15-1231.45 1231.46-1231.45zm304.78 389.1h8295.11c630.96 0 1147.14 516.19 1147.14 1147.14v8295.11c0 630.96-516.19 1147.14-1147.14 1147.14H12.13c-630.96 0-1147.14-516.19-1147.14-1147.14V1701.87c0-630.96 516.19-1147.14 1147.14-1147.14z"
      />
      <g fill="#2B2A29" stroke="#2B2A29" strokeWidth={7.87}>
        <path d="M1297.59 1857.09h195.49v7623.94h-195.49zM-364.03 1857.09h195.49v7623.94h-195.49z" />
        <path d="M564.52 1671.38c512.83 0 928.56 83.15 928.56 185.71h-182.29c-169.19-45.62-440.45-75.2-746.27-75.2s-577.08 29.57-746.27 75.2h-182.29c0-102.57 415.73-185.71 928.56-185.71zM564.52 9295.32c297.99 0 563.16 28.08 733.07 71.72v110.52c-169.91-43.65-435.07-71.73-733.07-71.73-298 0-563.16 28.08-733.07 71.73v-110.52c169.91-43.65 435.08-71.72 733.07-71.72z" />
      </g>
      <g fill="#2B2A29" stroke="#2B2A29" strokeWidth={7.87}>
        <path d="M3154.71 1857.09h195.49v7623.94h-195.49zM1493.08 1857.09h195.49v7623.94h-195.49z" />
        <path d="M2421.64 1671.38c512.83 0 928.56 83.15 928.56 185.71h-182.29c-169.19-45.62-440.45-75.2-746.27-75.2s-577.08 29.57-746.27 75.2h-182.29c0-102.57 415.73-185.71 928.56-185.71zM2421.64 9295.32c297.99 0 563.16 28.08 733.07 71.72v110.52c-169.91-43.65-435.07-71.73-733.07-71.73-298 0-563.17 28.08-733.07 71.73v-110.52c169.91-43.65 435.08-71.72 733.07-71.72z" />
      </g>
      <g fill="#2B2A29" stroke="#2B2A29" strokeWidth={7.87}>
        <path d="M5011.82 1857.09h195.49v7623.94h-195.49zM3350.19 1857.09h195.49v7623.94h-195.49z" />
        <path d="M4278.75 1671.38c512.83 0 928.56 83.15 928.56 185.71h-182.29c-169.19-45.62-440.45-75.2-746.27-75.2s-577.08 29.57-746.27 75.2h-182.29c0-102.57 415.73-185.71 928.56-185.71zM4278.75 9295.32c297.99 0 563.16 28.08 733.07 71.72v110.52c-169.9-43.65-435.07-71.73-733.07-71.73-298 0-563.17 28.08-733.07 71.73v-110.52c169.91-43.65 435.08-71.72 733.07-71.72z" />
      </g>
      <g fill="#2B2A29" stroke="#2B2A29" strokeWidth={7.87}>
        <path d="M7671.07 9285.54v195.49H5283.12v-195.49zM7671.07 7623.92v195.49H5283.12v-195.49zM7856.78 8552.47c0 512.83-83.15 928.56-185.71 928.56v-182.29c45.62-169.19 75.2-440.45 75.2-746.27s-29.57-577.08-75.2-746.27v-182.29c102.57 0 185.71 415.73 185.71 928.56z" />
      </g>
      <circle
        fill="#FEFEFE"
        stroke="#2B2A29"
        strokeWidth={7.87}
        cx={3430.41}
        cy={5785.7}
        r={1847.34}
      />
      <path
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={118.11}
        d="m4190.43 2913.46 1300.33 750.75-318.04 550.85c165.87 196.8 297.94 423.02 387.26 669.73h635.49v1501.5h-635.49c-89.31 246.7-221.38 472.93-387.26 669.73l318.04 550.85-1300.33 750.75-317.87-550.56c-125.6 22.22-254.87 33.82-386.84 33.82-131.98 0-261.24-11.59-386.84-33.82l-317.87 550.56-1300.33-750.75 318.04-550.85c-165.88-196.8-297.95-423.03-387.26-669.73H775.97v-1501.5h635.49c89.31-246.7 221.38-472.93 387.26-669.73l-318.04-550.85 1300.33-750.75 317.87 550.56c125.6-22.23 254.87-33.82 386.84-33.82 131.97 0 261.24 11.59 386.84 33.82l317.87-550.56zm-704.7 1502.15c673.75 0 1219.93 546.18 1219.93 1219.93 0 673.75-546.18 1219.93-1219.93 1219.93-673.75 0-1219.93-546.18-1219.93-1219.93 0-673.75 546.18-1219.93 1219.93-1219.93z"
      />
      <circle
        fill="#FEFEFE"
        stroke="#2B2A29"
        strokeWidth={7.87}
        cx={7549.61}
        cy={7046.63}
        r={1036.97}
      />
      <path
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={59.06}
        d="m7842.26 5301.25 810.13 467.73-198.14 343.19c103.34 122.61 185.62 263.55 241.27 417.25h395.92v935.46h-395.92c-55.65 153.7-137.93 294.64-241.27 417.25l198.14 343.19-810.13 467.73-198.04-343.01c-78.25 13.85-158.78 21.07-241.01 21.07-82.22 0-162.76-7.22-241-21.07l-198.04 343.01-810.13-467.73 198.14-343.19c-103.34-122.61-185.63-263.55-241.27-417.25h-395.92v-935.46h395.92c55.64-153.7 137.93-294.64 241.27-417.25l-198.14-343.19 810.13-467.73 198.04 343.01c78.25-13.85 158.78-21.07 241-21.07s162.76 7.22 241.01 21.07l198.04-343.01zm-439.04 935.87c419.76 0 760.04 340.28 760.04 760.04s-340.28 760.04-760.04 760.04-760.04-340.28-760.04-760.04 340.28-760.04 760.04-760.04z"
      />
    </g>
  </svg>
)

export default OperationDataIcon
