import {
    DELETE_ACTUAL_BY_OP_ID,
    DELETE_MAINTENANCE,
    GET_ACTUAL_DATE_BY_OP_ID,
    GET_ALL_OP,
    GET_OP_BY_DATE,
    GET_OP_BY_ID,
    POST_ACTUAL_OP
} from "../../services/operationPlanning";
import {createAction} from "../../utils/reducer/reducerUtil";
import {OP_ACTION_TYPES} from "./op.types";
import {STATUS_TYPE} from "../../utils/constant/constants";
import {GLOBAL_ACTION_TYPES} from "../global/global.types";
import { handleLoading } from "../global/global.action";

export const handleNotifPageNumber = (notif) => dispatch => {
    dispatch(createAction(OP_ACTION_TYPES.NOTIF_PAGE_NUMBER_FROM_OP_PAGE, notif));
}
export const handleStorePage = (page) => dispatch => {
    dispatch(createAction(OP_ACTION_TYPES.PAGE_NUMBER, page));
}

export const handleFetchAllOpData = (page, size) => (dispatch) => {
    GET_ALL_OP(page, size)
        .then(res => {
            dispatch(createAction(OP_ACTION_TYPES.GET_ALL_SIMULATION, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        });
}

export const handleDeactivateOP = () => (dispatch) => {
    dispatch(createAction(OP_ACTION_TYPES.CLEAR_ALL_STATE));
}

export const handleRemoveOP = (id, page, size) => dispatch => {
    DELETE_MAINTENANCE(id)
        .then(() => {
            dispatch(createAction(OP_ACTION_TYPES.REMOVE_OP_STATUS, STATUS_TYPE.SUCCESS));
            dispatch(createAction(OP_ACTION_TYPES.CLEAR_AN_OP_DATA));
            dispatch(handleFetchAllOpData(page, size));
        })
        .catch(e => {
            dispatch(createAction(OP_ACTION_TYPES.REMOVE_OP_STATUS, STATUS_TYPE.FAILED));
        });
}


export const handleGetActualDto = (id) => (dispatch) => {
    GET_ACTUAL_DATE_BY_OP_ID(id)
        .then(res => {
            if (res.length) {
                dispatch(handleGetOPDataByIdAndDate(id, res[res.length - 1]));
            } else {
                dispatch(handleGetOPDataById(id));
            }
            dispatch(createAction(OP_ACTION_TYPES.ACTUAL_DTO_DATES_RESPONSE, res));
        })
        .catch(e => {
            // todo: hapus dispatch ini klo di BE udah di handle
            dispatch(handleGetOPDataById(id));
            dispatch(createAction(OP_ACTION_TYPES.ACTUAL_DTO_DATES_RESPONSE, null));
        });
}
export const handleGetOPDataById = (id) => dispatch => {
    GET_OP_BY_ID(id)
        .then(res => {
            dispatch(createAction(OP_ACTION_TYPES.GET_OP_BY_ID, res));
        })
        .catch(() => {});
};
export const handleGetOPDataByIdAndDate = (id, date) => dispatch => {
    GET_OP_BY_DATE(id, date)
        .then(res => {
            dispatch(createAction(OP_ACTION_TYPES.GET_OP_BY_ID, res));
        })
        .catch(() => {});
};

export const handleCreateActual = (body) => (dispatch) => {
    POST_ACTUAL_OP(body)
        .then(res => {
            dispatch(createAction(OP_ACTION_TYPES.POST_ACTUAL_SUCCESS, res));
            dispatch(handleGetActualDto(body.id));
        })
        .catch(() => {})
}

export const handleRemoveActualByOPId = id => dispatch => {
    DELETE_ACTUAL_BY_OP_ID(id)
        .then(() => {
            dispatch(createAction(OP_ACTION_TYPES.REMOVE_ACTUAL_STATUS, STATUS_TYPE.SUCCESS));
        })
        .catch((e) => {
            dispatch(createAction(OP_ACTION_TYPES.REMOVE_ACTUAL_STATUS, STATUS_TYPE.FAILED))
        });
}

export const handleResetActionStatus = () => dispatch => {
    dispatch(createAction(OP_ACTION_TYPES.REMOVE_ACTUAL_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(OP_ACTION_TYPES.REMOVE_OP_STATUS, STATUS_TYPE.NO_STATEMENT));
}
export const handleCurrentSite = (site) => dispatch => {
    dispatch(createAction(OP_ACTION_TYPES.SHOW_CURRENT_SITE, site));
}