import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Cartesian3, ScreenSpaceEventHandler, ScreenSpaceEventType} from "cesium";
import {Viewer} from "resium";

import HeaderPage from "../../../components/header/HeaderPage";
import {flyToIndonesia, registerViewer} from "../../../store/dashboard/dashboard.action";
import {
    handleAddAPoint,
    handleAfterGoToEdit,
    handleClearExcForUpdate,
    handleClearStorage,
    handleDeletePoint,
    handleEntityLine,
    handleEntityPoint,
    handleGetDetailAsset,
    handlePostFO,
    handleRegisterClickEvent,
    handleRemoveAPoint,
    handleRemoveAPolylineAndAMidlabel,
    handleClearNotif
} from "../../../store/fo/fo.action";
import {
    selectDataFromList,
    selectDetailAsset,
    selectDistanceNumber,
    selectGoToAsset,
    selectMidLabelEntities,
    selectPointEntities,
    selectPoints,
    selectPolylineEntities
} from "../../../store/fo/fo.selector";
import {selectCurrentUser} from "../../../store/user/user.selector";
import {createPayload, createPayloadDeleteKinetic, createPayloadUpdate} from "../../../store/fo/fo.util";
import {handleClearStateGlobal, handleLoading} from "../../../store/global/global.action";


const AddRoute = () => {
    const points = useSelector(selectPoints);
    const pointEntities = useSelector(selectPointEntities);
    const midLabelEntities = useSelector(selectMidLabelEntities);
    const polylineEntities = useSelector(selectPolylineEntities);
    const distanceNumbers = useSelector(selectDistanceNumber);
    const currentUser = useSelector(selectCurrentUser);
    const goToAsset = useSelector(selectGoToAsset);
    const dataFormList = useSelector(selectDataFromList);
    const detailAsset = useSelector(selectDetailAsset);

    const ref = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [handler, setHandler] = useState(null);
    const [totalLength, setTotalLength] = useState(0);
    const [dataPoints, setDataPoints] = useState([]);
    const [notToProcessPoint, setNotToProcessPoint] = useState(true);
    const [forUpdate, setForUpdate] = useState(false);

    const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm(({
        defaultValues: {
            routeName: null,
            core: 0,
        }
    }));


    useEffect(() => {
        const viewer = ref.current.cesiumElement;
        dispatch(registerViewer(viewer));
        flyToIndonesia(viewer);

        const handlerClick = new ScreenSpaceEventHandler(viewer.scene.canvas);
        setHandler(handlerClick);

        return () => {
            handlerClick.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
            if (viewer) {
                if (pointEntities && pointEntities.length) {
                    viewer.entities.removeAll();
                }
            }
            reset()
            setNotToProcessPoint(false);
            setHandler(null);
            setTotalLength(0);
            setForUpdate(false);
            dispatch(handleClearStorage())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (handler) {
            dispatch(handleRegisterClickEvent(ref.current.cesiumElement, handler));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handler])

    useEffect(() => {
        if (distanceNumbers) {
            const sum = distanceNumbers.reduce((partialSum, a) => partialSum + a, 0);
            setTotalLength(sum);
        } else {
            setTotalLength(0);
        }
    }, [distanceNumbers])

    useEffect(() => {
        if (points && points.length && notToProcessPoint) {
            const viewer = ref.current.cesiumElement;
            const latestIndex = points.length - 1;
            // draw point & label
            const cartesianPosition = points[latestIndex].cartesianPosition;
            dispatch(handleEntityPoint(viewer, cartesianPosition, latestIndex + 1));
            // draw line and draw distance label if point.length > 1
            if (points.length > 1) {
                const point1 = {latitude: points[latestIndex - 1].lat, longitude: points[latestIndex - 1].lon}
                const point2 = {latitude: points[latestIndex].lat, longitude: points[latestIndex].lon}
                dispatch(handleEntityLine(viewer, point1, point2));
            }

            // add to array
            const copyDataPoints = [...dataPoints];
            const latitude = points[latestIndex].lat;
            const longitude = points[latestIndex].lon;
            copyDataPoints.push({latitude, longitude});
            setDataPoints(copyDataPoints);
        } else {
            setNotToProcessPoint(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [points])

    useEffect(() => {
        if (goToAsset && dataFormList) {
            dispatch(handleGetDetailAsset(dataFormList.id));
            dispatch(handleClearStateGlobal());
            dispatch(handleAfterGoToEdit());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goToAsset])

    /**
     * Handle update
     */
    useEffect(() => {
        if (detailAsset) {
            setForUpdate(true);
            setValue("routeName", detailAsset.distributionAssetsDto.name)
            setValue("core", detailAsset.routePathDto.core)
            const dataPointTemp = [];
            detailAsset.pathDtos.forEach(d => dataPointTemp.push({
                latitude: d.lat,
                longitude: d.lon,
            }))
            setDataPoints(dataPointTemp);

            // draw point & label
            const viewer = ref.current.cesiumElement;
            dataPointTemp.forEach((p, index) => {
                setNotToProcessPoint(false); // do not process points
                const cartesianPosition = Cartesian3.fromDegrees(p.longitude, p.latitude, 0);
                dispatch(handleEntityPoint(viewer, cartesianPosition, index + 1));
                dispatch(handleAddAPoint(p.latitude, p.longitude, cartesianPosition));
                // draw line and draw distance label if point.length > 1
                if (index > 0) {
                    const point1 = {latitude: p.latitude, longitude: p.longitude}
                    const point2 = {
                        latitude: dataPointTemp[index - 1].latitude,
                        longitude: dataPointTemp[index - 1].longitude
                    }
                    dispatch(handleEntityLine(viewer, point1, point2));
                }
            })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailAsset])

    const onRemovePoint = (e, index) => {
        e.preventDefault();
        const viewer = ref.current.cesiumElement;
        // remove by index from viewer entities
        viewer.entities.remove(pointEntities[index]);
        // remove from points data and pointEntities data in reducer
        dispatch(handleRemoveAPoint(index));
        // remove from data array
        const copyArr = [...dataPoints];
        copyArr.pop();
        setDataPoints(copyArr);
        // remove line and mid-label if points.length > 1
        if (points && points.length > 1 && polylineEntities && polylineEntities.length
            && midLabelEntities && midLabelEntities.length
            && distanceNumbers && distanceNumbers.length) {
            // remove from entities
            viewer.entities.remove(polylineEntities[index - 1]);
            viewer.entities.remove(midLabelEntities[index - 1]);
            // remove from reducer (polyline, label and distanceNumber)
            dispatch(handleRemoveAPolylineAndAMidlabel());
        }
        // flag : do not process point
        setNotToProcessPoint(false);
    }

    const onRemoveAllPoint = (e) => {
        e.preventDefault();
        const viewer = ref.current.cesiumElement;
        // remove from viewer enitities
        viewer.entities.removeAll();

        if (forUpdate && detailAsset) { // for update only clear field
            dispatch(handleClearExcForUpdate(detailAsset));
        } else { // clear all data in reducer when create
            dispatch(handleClearStorage());
        }
        // clear dataPoints array
        setDataPoints([]);
        // reset total length
        setTotalLength(0);
        // flag : do not process point
        setNotToProcessPoint(false);
    }

    const handleSaveFO = (data) => {
        dispatch(handleClearNotif());

        if (forUpdate) {
            const assetId = detailAsset.distributionAssetsDto.id;
            const updatePayload = createPayloadUpdate(data.routeName, data.core, totalLength, points.length);
            if (!points) { // remove all kinetic point
                dispatch(handleLoading(true));
                dispatch(handleDeletePoint(assetId, createPayloadDeleteKinetic(detailAsset.pathDtos), null, updatePayload, navigate));
            } else {
                dispatch(handleLoading(true));
                dispatch(handleDeletePoint(assetId, createPayloadDeleteKinetic(detailAsset.pathDtos), points, updatePayload, navigate));
            }
        } else {
            if (points && points.length) {
                const payload = createPayload(data, currentUser, totalLength, points);
                dispatch(handleLoading(true));
                dispatch(handlePostFO(payload, navigate));
                reset()
            }
        }
    }

    return (
        <div className="bg-tertiary px-14 py-4 min-h-screen">
            <HeaderPage title="Fiber Optic" icon="tower"/>

            <div
                className="flex gap-x-3 items-center text-white text-2xl w-fit cursor-pointer"
                onClick={() => navigate(-1)}
            >
                <button
                    type="button"
                    className="btn btn-sm btn-circle"
                >
                    &#10094;
                </button>
                <span>Back</span>
            </div>

            <section className="flex gap-x-12">
                <div className="w-[60%]">
                    <div className="mt-14 border-solid border-8 border-white rounded-lg h-[662px] relative">
                        <Viewer
                            ref={ref}
                            timeline={false}
                            animation={false}
                            baseLayerPicker={true}
                            navigationHelpButton={false}
                            className="h-full w-auto"
                            scene3DOnly={false}
                            sceneModePicker={false}
                            geocoder={true}
                            selectionIndicator={false}
                            useBrowserRecommendedResolution
                            infoBox={false}
                        >
                        </Viewer>
                    </div>
                </div>

                <div className="w-[40%] mt-14">
                    <div className="h-[660px] border-2 border-white rounded-md px-10 relative">
                        <HeaderPage title={forUpdate ? `Update Route` : `Add Route`} icon="tower"/>

                        <form className="flex flex-col mt-5">
                            <div className="flex items-center mb-4">
                                <label
                                    htmlFor="routeName"
                                    className="text-white min-w-[160px]"
                                >
                                    Route Name
                                </label>
                                <input
                                    {...register("routeName", {
                                        required: "Required!",
                                        pattern: {
                                            value: /^\S+/,
                                            message: "Entered value cant start or contain only white spacing"
                                        },
                                    })}
                                    pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                    title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                    id="routeName"
                                    name="routeName"
                                    type="text"
                                    autoFocus
                                    className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.routeName ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                    placeholder={errors?.routeName?.message}
                                />
                            </div>

                            <div className="flex items-center mb-4">
                                <label
                                    htmlFor="core"
                                    className="text-white min-w-[160px]"
                                >
                                    Core
                                </label>
                                <input
                                    {...register("core", {
                                        required: "Required!",
                                        pattern: {
                                            value: /^\S+/,
                                            message: "Entered value cant start or contain only white spacing"
                                        },
                                    })}
                                    id="core"
                                    type="number"
                                    name="core"
                                    className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.core ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                    placeholder={errors?.core?.message}
                                />
                            </div>

                            <div className="flex items-center mb-4">
                                <label
                                    htmlFor="totalLength"
                                    className="text-white min-w-[160px]"
                                >
                                    Total Cable Length
                                </label>
                                <input
                                    id="totalLength"
                                    type="number"
                                    step="any"
                                    value={totalLength}
                                    disabled
                                    className="input input-bordered w-full text-black text-sm border-2 focus:border-purple-500"
                                />
                                <span className="text-white ml-7">m</span>
                            </div>

                            <div className="flex items-center mb-4 w-4/5">
                                <label
                                    htmlFor="owner"
                                    className="text-white min-w-[160px]"
                                >
                                    Owner
                                </label>
                                <select
                                    className="select select-bordered w-[380px] bg-white text-black font-thin border-2 focus:border-purple-500"
                                    disabled
                                >
                                    <option selected disabled>{currentUser && currentUser.siteName}</option>
                                </select>
                            </div>

                            <div className="border-2 border-white rounded-md my-1 p-3 max-h-[210px] overflow-auto">
                                <div className="flex items-center justify-between mb-4 text-white">
                                    <h2>Points</h2>
                                    <button type="button" onClick={onRemoveAllPoint}>Remove All Point</button>
                                </div>

                                {dataPoints && dataPoints.map((point, index) => (
                                    <div key={index} className="flex items-center mb-4">
                                        <label
                                            htmlFor="point"
                                            className="text-white min-w-[160px]"
                                        >
                                            Point {index + 1}
                                        </label>
                                        <input
                                            id="point"
                                            type="number"
                                            className="bg-gray-200 text-sm appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 mr-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            defaultValue={point.latitude}
                                        />
                                        <input
                                            id="point"
                                            type="number"
                                            className="bg-gray-200 text-sm appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 ml-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            defaultValue={point.longitude}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-xs btn-circle ml-3"
                                            disabled={dataPoints.length - 1 !== index}
                                            onClick={(e) => onRemovePoint(e, index)}
                                        >
                                            ✕
                                        </button>
                                    </div>
                                ))}
                            </div>


                            <div className="flex gap-x-4 mt-5 absolute bottom-6">
                                <button
                                    type="button"
                                    className="btn btn-outline text-white"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary text-white px-7"
                                    onClick={handleSubmit(handleSaveFO)}
                                >
                                    {forUpdate ? `Update` : `Save`}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

            </section>
        </div>
    )
}

export default AddRoute;