/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import ReactPaginate from "react-paginate";

import CardAssets from "../../../components/card/CardAssets";
import HeaderPage from "../../../components/header/HeaderPage";
import {ModalForm} from "../../../components/modal/ModalForm";
import Table from "../../../components/table/Table";

import PlusIcon from "../../../assets/icons/PlusIcon";
import {
    handleClearData,
    handleClearNotif,
    handleRemoveTowerServer,
    handleSaveTowerServer,
    handleSearchTowerServer,
    handleUpdateTowerServer
} from "../../../store/towerServer/towerServer.action";
import {
    selectAllTowerServer,
    selectColumn,
    selectRemoveTowerServerStatus,
    selectStatusTowerServer,
    selectTotalPages,
    selectUpdateTowerServerStatus,
    selectUploadedTowerServerStatus
} from "../../../store/towerServer/towerServer.selector";

import {
    ALERT_ICON_FAILED,
    ALERT_ICON_SUCCESS,
    ALERT_TEXT_CREATE_FAILED,
    ALERT_TEXT_CREATE_SUCCESS,
    ALERT_TEXT_REMOVE_FAILED,
    ALERT_TEXT_REMOVE_SUCCESS,
    ALERT_TEXT_UPDATE_FAILED,
    ALERT_TEXT_UPDATE_SUCCESS,
    ALERT_TITLE_FAILED,
    ALERT_TITLE_SUCCESS,
    ASSET_ACTION_TYPE,
    ASSET_TYPE,
    CONFIRMATION_ICON_REMOVE,
    CONFIRMATION_TEXT_REMOVE,
    CONFIRMATION_TITLE_REMOVE,
    STATUS_TYPE,
    USER_MABES
} from "../../../utils/constant/constants";
import {
    selectAssetToEdit,
    selectAssetToRemove,
    selectCurrentItem,
    selectDetailAsset
} from "../../../store/global/global.selector";
import {handleClearStateGlobal, handleLoading} from "../../../store/global/global.action";
import {selectCurrentUser} from "../../../store/user/user.selector";
import SearchBox from "../../../components/search-box/search-box.component";
import {generalAlert, generalConfirmation} from "../../../utils/notification/notification";


const DataTowerServer = () => {
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm(({
        defaultValues: {
            name: "",
            category: "Select Category",
            brand: "Select Brand",
            type: "Select Type",
            serialNo: "",
            partNo: "",
            status: "Select Status",
            freq: "",
            freqMax: "",
            power: "",
            bandwidth: "",
            site: "Select Site",
            notes: ""
        }
    }));
    const [open, setOpen] = useState(false);
    const [filterSelect, setFilterSelect] = useState();
    const [searchText, setSearchText] = useState("");
    const [searchTextOnChange, setSearchTextOnChange] = useState("");
    const [page, setPage] = useState(0);
    const [actionType, setActionType] = useState(ASSET_ACTION_TYPE.NS);

    const dispatch = useDispatch();
    const totalPages = useSelector(selectTotalPages);
    const statusTowerServer = useSelector(selectStatusTowerServer);
    const column = useSelector(selectColumn);
    const uploadedTowerServerStatus = useSelector(selectUploadedTowerServerStatus);
    const assetToRemove = useSelector(selectAssetToRemove);
    const assetToEdit = useSelector(selectAssetToEdit);
    const currentItem = useSelector(selectCurrentItem);
    const detailAsset = useSelector(selectDetailAsset);
    const currentUser = useSelector(selectCurrentUser);
    const data = useSelector(selectAllTowerServer);
    const removeStatus = useSelector(selectRemoveTowerServerStatus);
    const updateStatus = useSelector(selectUpdateTowerServerStatus);


    useEffect(() => {
        dispatch(handleLoading(true));

        if (currentUser) {
            dispatch(handleSearchTowerServer("", "name", 0, 10, currentUser.siteId));
        }

        return () => {
            dispatch(handleClearData());
            dispatch(handleClearStateGlobal());
            dispatch(handleClearNotif())
            resetState();
            setPage(0);
            dispatch(handleLoading(false));
        };
    }, []);

    /**
     * CREATE TOWER CIVIL
     */
    useEffect(() => {
        if (uploadedTowerServerStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === uploadedTowerServerStatus;
            const isFailed = STATUS_TYPE.FAILED === uploadedTowerServerStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_CREATE_SUCCESS : ALERT_TEXT_CREATE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => {
                        dispatch(handleClearNotif());
                        handleClose();
                    }
                );
                dispatch(handleSearchTowerServer(searchText, filterSelect, page, 10, currentUser.siteId));
            }
        }
    }, [uploadedTowerServerStatus]);

    /**
     * EDIT TOWER CIVIL
     */
    useEffect(() => {
        if (ASSET_TYPE.TOWER_CIVIL === assetToEdit && currentItem) {
            fillDataWithCurrentItem(currentItem);
            setActionType(ASSET_ACTION_TYPE.UPDATE);
            setOpen(true);
        }
    }, [assetToEdit]);

    useEffect(() => {
        if (updateStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === updateStatus;
            const isFailed = STATUS_TYPE.FAILED === updateStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_UPDATE_SUCCESS : ALERT_TEXT_UPDATE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => {
                        dispatch(handleClearNotif());
                        handleClose();
                    }
                );
                dispatch(handleSearchTowerServer(searchText, filterSelect, page, 10, currentUser.siteId));
            }
        }
    }, [updateStatus]);

    /**
     * REMOVE TOWER CIVIL
     */
    useEffect(() => {
        if (ASSET_TYPE.TOWER_CIVIL === assetToRemove && currentItem) {
            generalConfirmation(
                CONFIRMATION_TITLE_REMOVE,
                CONFIRMATION_TEXT_REMOVE(currentItem.name),
                CONFIRMATION_ICON_REMOVE,
                () => dispatch(handleRemoveTowerServer(currentItem.id, currentUser.siteId, page)),
                () => dispatch(handleClearStateGlobal()));
        }
    }, [assetToRemove]);

    useEffect(() => {
        if (removeStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === removeStatus;
            const isFailed = STATUS_TYPE.FAILED === removeStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_REMOVE_SUCCESS : ALERT_TEXT_REMOVE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => {
                        dispatch(handleClearNotif());
                        handleClose();
                    }
                );
            }
            if (data && data.content && data.content.length) {
                dispatch(handleSearchTowerServer(searchText, filterSelect, page, 10, currentUser.siteId));
            } else {
                if (page) {
                    dispatch(handleSearchTowerServer(searchText, filterSelect, page - 1, 10, currentUser.siteId));
                    setPage(prevPage => prevPage - 1);
                } else {
                    dispatch(handleSearchTowerServer(searchText, filterSelect, 0, 10, currentUser.siteId));
                    setPage(0);
                }
            }
        }
    }, [removeStatus]);

    /**
     * DETAIL TOWER CIVIL
     */
    useEffect(() => {
        if (ASSET_TYPE.TOWER_CIVIL === detailAsset && currentItem) {
            fillDataWithCurrentItem(currentItem);
            setActionType(ASSET_ACTION_TYPE.DETAIL);
            setOpen(true);
        }
    }, [detailAsset]);

    const resetState = () => {
        reset();
        setOpen(false);
        setActionType(ASSET_ACTION_TYPE.NS);
        dispatch(handleClearStateGlobal());
    }

    const fillDataWithCurrentItem = (currentItem) => {
        setValue("name", currentItem.name);
        setValue("lat", currentItem.lat);
        setValue("lon", currentItem.lon);
        setValue("operator", currentItem.operator);
    }

    const onSearch = (e) => {
        e.preventDefault()
        dispatch(handleLoading(true));

        setSearchText(searchTextOnChange);

        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value.toLowerCase();
        const filter = searchText === "" ? "name" : filterSelect;

        dispatch(handleSearchTowerServer(searchText, filter, 0, 10, currentUser.siteId));

        setPage(0);
    };

    const uploadCivilData = (payload) => {
        if (currentUser && (payload)) {
            if (ASSET_ACTION_TYPE.CREATE === actionType) {
                dispatch(handleSaveTowerServer(payload, currentUser.siteId));
                setPage(0);
            } else if (ASSET_ACTION_TYPE.UPDATE === actionType) {
                dispatch(handleUpdateTowerServer(currentItem.id, payload, currentUser.siteId, page));
            }
            resetState();
        }
    }

    const handleSave = (data) => {
        if (currentUser) {
            dispatch(handleClearNotif());
            dispatch(handleLoading(true));
            uploadCivilData(data);
        }
    };

    const handleClose = () => {
        resetState();
    };

    const handleOpen = () => {
        setActionType(ASSET_ACTION_TYPE.CREATE);
        setOpen(true);
    }

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        dispatch(handleSearchTowerServer(searchText, filterSelect, e.selected, 10, currentUser.siteId));
        setPage(e.selected);
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    }

    console.log("data444", data);

    return (
        <div className="bg-tertiary min-h-screen px-20 py-4">
            <HeaderPage title="Data Tower Server" icon="tower"/>

            <div className="mt-10">
                {statusTowerServer &&
                    statusTowerServer.map((asset) => (
                        <CardAssets
                            key={asset.id}
                            asset={asset.name}
                            item={asset.item}
                            icon={asset.icon}
                            customClass={`back-${asset.class}`}
                        />
                    ))}
            </div>

            <div className="pb-20 mx-auto mt-24">
                <form className="flex mb-6" onSubmit={onSearch}>
                    <select
                        name="filter"
                        className="select select-bordered w-[200px] rounded-3xl h-[30px]"
                        onChange={(e) => setFilterSelect(e.target.value)}
                    >
                        <option selected>Select filter</option>
                        <option value="name">Filter by Name</option>
                        {currentUser?.siteName === USER_MABES && (
                            <option value="owner">Filter by Owner</option>
                        )}
                        <option value="operator">Filter by Operator</option>
                    </select>
                    <SearchBox
                        name="searchText"
                        value={searchTextOnChange}
                        placeholder="Search Tower Server"
                        className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                        onChangeHandler={onSearchChange}
                    />
                    <span className="flex justify-center items-center text-white text-lg px-5 mr-auto">
                        <button
                            type="submit"
                            className="btn btn-primary rounded-full text-white text-xs px-7"
                        >
                            Search
                        </button>
                    </span>
                    <span
                        className="flex justify-center items-center text-white gap-x-2 text-lg cursor-pointer"
                        onClick={handleOpen}
                    >
                        <button className="cursor-pointer">
                            <PlusIcon width={18} height={16}/>
                        </button>
                        Add Tower Server
                    </span>
                </form>

                <div className="createModal">
                    <ModalForm
                        open={open}
                        onClose={handleClose}
                        onSave={
                            ASSET_ACTION_TYPE.DETAIL !== actionType && handleSubmit(handleSave)
                        }
                        title={`${actionType} Data Tower Civil`}
                        icon="tower"
                        btnCloseTitle="Cancel"
                        btnSubmitTitle="Save"
                        actionType={
                            ASSET_ACTION_TYPE.DETAIL === actionType ? actionType : ASSET_ACTION_TYPE.NS
                        }
                        size="w-2/3"
                        closeBtn={true}
                    >
                        <div>
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3" htmlFor="name">
                                    Name
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <input
                                        {...register("name", {
                                            required: "Required!",
                                            pattern: {
                                                value: /^\S+/,
                                                message: "Entered value cant start or contain only white spacing"
                                            },
                                        })}
                                        pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                        title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.name ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                        id="name"
                                        type="text"
                                        autoFocus
                                        placeholder={errors?.name?.message}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3" htmlFor="lat">
                                    Latitude
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <input
                                        {...register("lat", {
                                            required: "Required!",
                                            min: -90,
                                            max: 90,
                                            pattern: {
                                                value: /^\S+/,
                                                message: "Entered value cant start or contain only white spacing"
                                            },
                                        })}
                                        pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                        title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.lat ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                        id="lat"
                                        type="text"
                                        placeholder={errors?.lat?.message}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3" htmlFor="lon">
                                    Longitude
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <input
                                        {...register("lon", {
                                            required: "Required!",
                                            min: -180,
                                            max: 180,
                                            pattern: {
                                                value: /^\S+/,
                                                message: "Entered value cant start or contain only white spacing"
                                            },
                                        })}
                                        pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                        title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.lon ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                        id="lon"
                                        type="text"
                                        placeholder={errors?.lon?.message}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3" htmlFor="operator">
                                    Operator
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <input
                                        {...register("operator", {
                                            required: "Required!",
                                            pattern: {
                                                value: /^\S+/,
                                                message: "Entered value cant start or contain only white spacing"
                                            },
                                        })}
                                        pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                        title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.operator ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                        id="operator"
                                        type="text"
                                        placeholder={errors?.operator?.message}
                                    />
                                </div>
                            </div>
                        </div>

                    </ModalForm>
                </div>
                {data && data.content && data.content.length > 0 ? (
                    <Table data={data.content} column={column} assetType={ASSET_TYPE.TOWER_CIVIL}/>
                ) : (
                    <p className="flex flex-col text-white text-xl text-center pt-10">
                        Data is empty
                    </p>
                )}

                <div className="text-white flex flex-col">
                    <div className="flex justify-center  my-6 self-end h-14 items-center">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={totalPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            forcePage={page}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataTowerServer;
