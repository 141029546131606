import {AREA_ACTION_TYPES, NS} from "./area.types";
import {
    ANALYZE_TYPE as analyZeType,
    CloaxialType,
    ColorScheme,
    Connectors,
    patterns,
    PM,
    Profile
} from "../../utils/constant/constants";

const INITIAL_STATE = {
    transmitterData: {
        name: "",
        network: "",
        coverageType: "GTA",
        transmitter: {
            lat: 0,
            lon: 0,
            alt: 0,
            frq: 868,
            txw: 10,
            bwi: 0.1
        },
        receiver: {
            lat: 0,
            lon: 0,
            alt: 0,
            rxg: 2.15,
            rxs: -100
        },
        antenna: {
            txg: 2.15,
            txl: 0,
            ant: 39,
            azi: 0,
            tlt: 0,
            hbw: 120,
            vbw: 120,
            pol: "h"
        },
        output: {
            units: "metric",
            col: ColorScheme[0].label,
            out: 2,
            ber: 1,
            mod: 1,
            nf: -120,
            res: 300,
            rad: 215.98
        },
        model: {
            pm: PM[1].value,
            pe: 2,
            ked: 1,
            rel: 90,
        },
        environment: {
            clt: Profile[0].value,
            cll: 1,
            clm: 0
        },
        additionalDTO: {
            patternDto: {
                id: patterns[0].id,
                name: patterns[0].label,
                img1: patterns[0].image_left,
                img2: patterns[0].image_right
            },
            coaxialTypeDto: {
                id: CloaxialType[0].id,
                name: CloaxialType[0].label
            },
            connector: Connectors[0].label,
            coaxialLength: 0,
            erp: "",
            eirp: "",
            effieciency: "",
            oriAlt: 0,
            oriRad: 0
        }
    },
    transmitterPosition: null, // {longitude, latitude, height}
    openInputDataRightPannel: true,
    analyzeType: analyZeType.NS,
    billboardEntity: null,
    pmData: null,
    radioData: null,
    antennaData: null,
    responseDataArea: null,
    failedResponse: null,
    copyData: null,
    multiplyAmount: 3,
    createOPResponse: null, // {id, name}
    requestBody: null,
    countOPReq: 0,
    altitudeToSum: 0,
    opSim: [], // [{id, opId, heigh, distance, area}]
    pathResponse: null,
    kmlPath: null,
    czmlSource: null,
    midLabel: null,
    showCoverageLinkBudget: false,
    showChartLinkBudget: true,
    isTerrainActive: false,
    terrainHeight: 0,
    rxPosition: null,
    kinetic: null,
    listenerMouseMove: null,
    showCoverageNotification: NS,
    anyDataCharLinkBudget: false,
    changeKineticRx: {},
    ruleInformation: {},
}

export const areaReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        /**
         * TERRAIN ACTION
         */
        case AREA_ACTION_TYPES.RULER_INFORMATION:
            return {...state, ruleInformation: payload};
        case AREA_ACTION_TYPES.CHANGE_KINETIC_RX:
            return {...state, changeKineticRx: payload};
        case AREA_ACTION_TYPES.IS_TERRAIN_ACTIVE:
            return {...state, isTerrainActive: payload};
        case AREA_ACTION_TYPES.RX_POSITION:
            return {...state, rxPosition: payload};
        case AREA_ACTION_TYPES.TERRAIN_HEIGHT:
            return {...state, terrainHeight: payload};
        /**
         * END TERRAIN ACTION
         */
        case AREA_ACTION_TYPES.SHOW_COVERAGE_NOTIFICATION:
            return {...state, showCoverageNotification: payload};
        case AREA_ACTION_TYPES.REGISTER_MID_LABEL:
            return {...state, midLabel: payload};
        case AREA_ACTION_TYPES.SHOW_COVERAGE_TX_LINK_BUDGET:
            const modifyCopyData = {...state.copyData}
            modifyCopyData.coverageStatus = payload;
            return {...state, copyData: modifyCopyData};
        case AREA_ACTION_TYPES.REGISTER_POLYLINE:
            return {...state, czmlSource: payload};
        case AREA_ACTION_TYPES.CLOSE_INPUT_DATA:
            return {...state, openInputDataRightPannel: false};
        case AREA_ACTION_TYPES.CLEAR_ERROR_MESSAGE:
            return {...state, failedResponse: null};
        case AREA_ACTION_TYPES.TRANSMITTER_POSITION:
            return {...state, transmitterPosition: payload};
        case AREA_ACTION_TYPES.CLEAR_TRANSMITTER_DATA:
            const copyTD = INITIAL_STATE.transmitterData;
            return {...state, transmitterData: copyTD};
        case AREA_ACTION_TYPES.GET_PM_LIST:
            return {...state, pmData: payload};
        case AREA_ACTION_TYPES.SHOW_HIDE_CHART_LINK_BUDGET:
            return {...state, showChartLinkBudget: payload};
        case AREA_ACTION_TYPES.FETCH_DATA:
            const {allAntenna} = payload;
            let antennaData;

            if (!!allAntenna) {
                antennaData = allAntenna;
            }

            return {...state, antennaData};
        case AREA_ACTION_TYPES.TRANSMITTER_DATA:
            const {latitude, longitude, billboardEntity} = payload

            const newData = {...INITIAL_STATE.transmitterData};

            newData.transmitter.lat = latitude;
            newData.transmitter.lon = longitude;

            return {
                ...state,
                transmitterData: newData,
                openInputDataRightPannel: true,
                billboardEntity: billboardEntity
            }
        case AREA_ACTION_TYPES.POST_SUCCESS:
            return {...state, responseDataArea: payload}
        case AREA_ACTION_TYPES.POST_FAILED:
            return {
                ...state,
                failedResponse: payload
            }
        case AREA_ACTION_TYPES.COPY_ENTITY_DATA:
                return {...state, copyData: payload}
        case AREA_ACTION_TYPES.REMOVE_COPY_ENTITY_DATA:
            return {...state, copyData: null}
        case AREA_ACTION_TYPES.HANDLE_MULTIPLY_AMOUNT:
            return {...state, multiplyAmount: payload}
        case AREA_ACTION_TYPES.MULTIPLE_ANALYZE_REQUEST:
            return {...state, responseDataArea: payload}
        case AREA_ACTION_TYPES.CREATE_OP:
            return {...state, createOPResponse: payload}
        case AREA_ACTION_TYPES.STORE_REQ_BODY:
            return {...state, requestBody: payload}
        case AREA_ACTION_TYPES.SET_COUNT_OP_REQ:
            return {...state, countOPReq: payload}
        case AREA_ACTION_TYPES.ALTITUDE_TO_SUM:
            return {...state, altitudeToSum: payload}
        case AREA_ACTION_TYPES.CREATE_SIMULATE:
            return {...state, opSim: payload}
        case AREA_ACTION_TYPES.CREATE_PATH:
            return {...state, pathResponse: payload}
        case AREA_ACTION_TYPES.STORE_KML_DATASOURCE:
            return {...state, kmlPath: payload}
        case AREA_ACTION_TYPES.STORE_BOLLBOARD_RX:
            return {...state, rxBillboard: payload}
        case AREA_ACTION_TYPES.STORE_BILLBOARD_TX:
            return {...state, txBillboard: payload}
        case AREA_ACTION_TYPES.CLEAR_BILLBOARD:
            return {...state, rxBillboard: null, txBillboard: null}
        case AREA_ACTION_TYPES.CLEAR_KML_SOURCE:
            return {...state, kmlPath: null, pathResponse: null}
        case AREA_ACTION_TYPES.KINETIC:
            return {...state, kinetic: payload}
        case AREA_ACTION_TYPES.REGISTER_HANDLER_LISTENER_MOUSE_MOVE:
            return {...state, listenerMouseMove: payload}
        case AREA_ACTION_TYPES.ANY_DATA_CHART_LINK_BUDGET:
            return {...state, anyDataCharLinkBudget: payload}
        default:
            return state;
    }
}