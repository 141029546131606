import {DELETE_ARCHIVE, FILTER_ARCHIVE} from "../../services/archieve";
import {createAction} from "../../utils/reducer/reducerUtil";
import {ACTION_TYPE_DATA_NS, ADMIN_ACTION_TYPES, EMPTY_STRING, FAILED, SUCCESS} from "./admin.types";
import {
    CREATE_CATEGORY,
    DELETE_CATEGORY_BY_ID,
    GET_CATEGORY_BY_ID,
    SEARCH_CATEGORY,
    UPDATE_CATEGORY
} from "../../services/category";
import {CREATE_BRAND, DELETE_BRAND_BY_ID, GET_BRAND_BY_ID, SEARCH_BRAND, UPDATE_BRAND} from "../../services/brand";
import {
    CREATE_TYPE,
    DELETE_TYPE_BY_ID,
    GET_TYPE_BY_BRAND,
    GET_TYPE_BY_ID,
    SEARCH_TYPE,
    UPDATE_TYPE
} from "../../services/type";
import {DELETE_MANNET_BY_NETWORK, GET_ALL_MANNET_NETWORK} from "../../services/mannet";
import {GLOBAL_ACTION_TYPES} from "../global/global.types";
import {STATUS_TYPE} from "../../utils/constant/constants";
import {handleLoading} from "../global/global.action";
import {GET_LOGS} from "../../services/logging";


export const handleFilterByType = (coverageType, analyzeType, page, q) => dispatch => {
    FILTER_ARCHIVE(coverageType, analyzeType, page, q.toLowerCase())
        .then(res => {
            dispatch(createAction(ADMIN_ACTION_TYPES.GET_TYPE_ARCHIVE, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        })
};

export const handleRemoveArchive = (id) => dispatch => {
    dispatch(handleLoading(true));

    DELETE_ARCHIVE(id)
        .then(() => {
            dispatch(handleLoading(false));
            dispatch(createAction(ADMIN_ACTION_TYPES.REMOVE_ARCHIVE, SUCCESS));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(ADMIN_ACTION_TYPES.REMOVE_ARCHIVE, FAILED));
        })
};

export const handleResetRemoveStatus = () => dispatch => {
    dispatch(createAction(ADMIN_ACTION_TYPES.REMOVE_ARCHIVE, ACTION_TYPE_DATA_NS));
}

// =========================================================================
// ============================ CATEGORY ACTION ============================
// =========================================================================

export const handleSearchCategory = (page, size, type, q) => dispatch => {
    SEARCH_CATEGORY(page, size, type, q)
        .then(res => {
            dispatch(handleLoading(false));
            dispatch(createAction(ADMIN_ACTION_TYPES.CATEGORIES_DATA, res));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED));
        });
};

export const handleCreateCategory = (payload) => dispatch => {
    CREATE_CATEGORY(payload)
        .then(() => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_CREATE_STATUS_CATEGORY, {
                status: SUCCESS,
                message: "Category has been created"
            }))
        })
        .catch(e => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_CREATE_STATUS_CATEGORY, {
                status: FAILED,
                message: e.message
            }));
        })
}

export const handleUpdateCategory = (id, payload) => dispatch => {
    const {category, ...restPayload} = payload;
    UPDATE_CATEGORY(id, restPayload)
        .then(() => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_UPDATE_STATUS_CATEGORY, {
                status: SUCCESS,
                message: "Category has been updated"
            }));
        })
        .catch(e => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_UPDATE_STATUS_CATEGORY, {
                status: FAILED,
                message: e.message
            }));
        })
}

export const handleDeleteCategory = (id) => dispatch => {
    DELETE_CATEGORY_BY_ID(id)
        .then(() => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_REMOVE_STATUS_CATEGORY, {
                status: SUCCESS,
                message: "Category has been removed"
            }));
        })
        .catch(e => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_REMOVE_STATUS_CATEGORY, {
                status: FAILED,
                message: e.message
            }));
        })
}

export const handleGetCategoryById = (id) => dispatch => {
    GET_CATEGORY_BY_ID(id)
        .then(res => {
            dispatch(createAction(ADMIN_ACTION_TYPES.GET_A_CATEGORY, res));
        })
        .catch(e => {
            dispatch(createAction(ADMIN_ACTION_TYPES.FAILED_TO_GET_A_CATEGORY, FAILED));
        });
}

export const handleResetFailedStatus = () => dispatch => {
    dispatch(createAction(ADMIN_ACTION_TYPES.FAILED_TO_GET_A_CATEGORY, EMPTY_STRING));
}

export const handleClearCategoryStatus = () => dispatch => {
    dispatch(createAction(ADMIN_ACTION_TYPES.CLEAR_ACTION_STATUS_CATEGORY));
}


// =========================================================================
// ============================ BRAND ACTION ============================
// =========================================================================

export const handleSearchBrand = (page, size, type, q) => dispatch => {
    SEARCH_BRAND(page, size, type, q)
        .then(res => {
            dispatch(handleLoading(false));
            dispatch(createAction(ADMIN_ACTION_TYPES.BRANDS_DATA, res));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED));
        });
};

export const handleCreateBrand = (payload) => dispatch => {
    CREATE_BRAND(payload)
        .then(() => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_CREATE_STATUS_BRAND, {
                status: SUCCESS,
                message: "Brand has been created"
            }))
        })
        .catch(e => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_CREATE_STATUS_BRAND, {status: FAILED, message: e.message}));
        })
}

export const handleUpdateBrand = (id, payload) => dispatch => {
    UPDATE_BRAND(id, payload)
        .then(() => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_UPDATE_STATUS_BRAND, {
                status: SUCCESS,
                message: "Brand has been updated"
            }));
        })
        .catch(e => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_UPDATE_STATUS_BRAND, {status: FAILED, message: e.message}));
        })
}

export const handleDeleteBrand = (id) => dispatch => {
    DELETE_BRAND_BY_ID(id)
        .then(() => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_REMOVE_STATUS_BRAND, {
                status: SUCCESS,
                message: "Brand has been removed"
            }));
        })
        .catch(e => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_REMOVE_STATUS_BRAND, {status: FAILED, message: e.message}));
        })
}

export const handleGetBrandById = (id) => dispatch => {
    GET_BRAND_BY_ID(id)
        .then(res => {
            dispatch(createAction(ADMIN_ACTION_TYPES.GET_A_BRAND, res));
        })
        .catch(() => {
        });
}

export const handleClearBrandStatus = () => dispatch => {
    dispatch(createAction(ADMIN_ACTION_TYPES.CLEAR_ACTION_STATUS_BRAND));
}


// =========================================================================
// ============================ TYPE ACTION ============================
// =========================================================================

export const handleSearchBrandForType = (q) => dispatch => {
    SEARCH_BRAND(0, 1000, "usage", q)
        .then(res => {
            dispatch(createAction(ADMIN_ACTION_TYPES.USAGE_NAME, res));
        })
        .catch(() => {
        });
};

export const handleSearchTypeByBrandId = (brandId) => dispatch => {
    GET_TYPE_BY_BRAND(brandId)
        .then(res => {
            dispatch(createAction(ADMIN_ACTION_TYPES.TYPES_BY_BRAND_ID, res));
        })
        .catch(() => {
        });
};

export const handleSearchType = (page, size, type, q) => dispatch => {
    SEARCH_TYPE(page, size, type, q)
        .then(res => {
            dispatch(handleLoading(false));
            dispatch(createAction(ADMIN_ACTION_TYPES.TYPES_DATA, res));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED));
        });
};

export const handleCreateType = (payload) => dispatch => {
    CREATE_TYPE(payload)
        .then(() => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_CREATE_STATUS_TYPE, {
                status: SUCCESS,
                message: "Type has been created"
            }))
        })
        .catch(e => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_CREATE_STATUS_TYPE, {status: FAILED, message: e.message}));
        })
}

export const handleUpdateType = (id, aType, payload) => dispatch => {
    const {name: oldName, brandId: oldBrandId} = aType;
    const {name, usage, brandId} = payload;

    const newPayload = {
        usage,
        ...(name !== oldName && {name}),
        ...(brandId !== oldBrandId && {brandId}),
    };

    UPDATE_TYPE(id, newPayload)
        .then(() => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_UPDATE_STATUS_TYPE, {
                status: SUCCESS,
                message: "Type has been updated"
            }));
        })
        .catch(e => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_UPDATE_STATUS_TYPE, {status: FAILED, message: e.message}));
        })
}

export const handleDeleteType = (id) => dispatch => {
    DELETE_TYPE_BY_ID(id)
        .then(() => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_REMOVE_STATUS_TYPE, {
                status: SUCCESS,
                message: "Type has been removed"
            }));
        })
        .catch(e => {
            dispatch(createAction(ADMIN_ACTION_TYPES.ACTION_REMOVE_STATUS_TYPE, {status: FAILED, message: e.message}));
        })
}

export const handleGetTypeById = (id) => dispatch => {
    GET_TYPE_BY_ID(id)
        .then(res => {
            dispatch(createAction(ADMIN_ACTION_TYPES.GET_A_TYPE, res));
        })
        .catch(() => {
        });
}

export const handleClearTypeStatus = () => dispatch => {
    dispatch(createAction(ADMIN_ACTION_TYPES.CLEAR_ACTION_STATUS_TYPE));
}

/**
 * MANNET
 */
export const handleFetchMannetByNetwork = (page, size, q) => dispatch => {
    GET_ALL_MANNET_NETWORK(page, size, q.toLowerCase())
        .then(res => {
            dispatch(createAction(ADMIN_ACTION_TYPES.GET_TYPE_ARCHIVE, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        })
}
export const handleRemoveByNetwork = (network) => dispatch => {
    dispatch(handleLoading(true));

    DELETE_MANNET_BY_NETWORK(network)
        .then(() => {
            dispatch(handleLoading(false));
            dispatch(createAction(ADMIN_ACTION_TYPES.DELETE_MANNET_BY_NETWORK_STATUS, SUCCESS))
        })
        .catch(() => {
            dispatch(handleLoading(false));
            dispatch(createAction(ADMIN_ACTION_TYPES.DELETE_MANNET_BY_NETWORK_STATUS, FAILED))
        });
}
export const handleResetRemoveByNetworkStatus = () => dispatch => {
    dispatch(createAction(ADMIN_ACTION_TYPES.DELETE_MANNET_BY_NETWORK_STATUS, EMPTY_STRING));
}
export const handleClearTypeByBrandId = () => dispatch => {
    dispatch(createAction(ADMIN_ACTION_TYPES.CLEAR_TYPES_BY_BRAND_ID));
}

// =========================================================================
// ============================ LOGGING ============================
// =========================================================================

export const handleGetLogging = (type, q, page, size = 10) => dispatch => {
    GET_LOGS(type, q, page, size)
        .then((res) => {
            dispatch(handleLoading(false));
            dispatch(createAction(ADMIN_ACTION_TYPES.FETCH_LOGS, res));
        })
        .catch((e) => {
            dispatch(handleLoading(false));
        })
}