import {TOWER_ACTION_TYPES} from "./tower.types";
import {STATUS_TYPE} from "../../utils/constant/constants";
import {addIntoArray} from "./tower.utils";

const INITIAL_STATE = {
    status: null,  // {}
    towers: null, // {radio:{content:[]}}
    detailTower: null, // {}
    categories: null,  // []
    brands: null,  // []
    types: null, // []
    sites: null, // {content:[]}
    isWhiteToggleActive: false,
    isRedToggleActive: false,
    statusArray: [
        {
            key: "SERVICEABLE",
            name: "SERVICEABLE",
        },
        {
            key: "SPARE",
            name: "SPARE",
        },
        {
            key: "UNSERVICEABLE",
            name: "UNSERVICEABLE",
        },
    ],
    
    column: [
        { heading: "Name", value: "name" },
        { heading: "Deployed Antenna", value: "deployedAntenna" },
        { heading: "Type", value: "type.name" },
        { heading: "Latitude", value: "lat" },
        { heading: "Longitude", value: "lon" },
        { heading: "Owner", value: "site.siteName" },
        { heading: "Status", value: "status" },
    ],
    uploadTowerStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedImages: [],
    uploadImageStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedFiles: [],
    uploadFileStatus: STATUS_TYPE.NO_STATEMENT,
    isLoading: false,
    removeStatus: STATUS_TYPE.NO_STATEMENT,
    updateStatus: STATUS_TYPE.NO_STATEMENT,
    towerDistributionData: null,
    towerMiliterData: null,
    towerFromServer: null,
}

export const towerReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case TOWER_ACTION_TYPES.GET_ALL_TOWER:
            return {...state, towers: payload};
        case TOWER_ACTION_TYPES.SEARCH:
            return {...state, towers: payload};
        case TOWER_ACTION_TYPES.SEARCH_REPORT:
            return {...state, towersReport: payload};
        case TOWER_ACTION_TYPES.DETAIL_TOWER:
            return {...state, detailTower: payload};
        case TOWER_ACTION_TYPES.CLEAR_DETAIL_TOWER:
            return {...state, detailTower: null};
        case TOWER_ACTION_TYPES.GET_CATEGORY:
            return {...state, categories: payload};
        case TOWER_ACTION_TYPES.GET_TYPE:
            return {...state, types: payload};
        case TOWER_ACTION_TYPES.GET_BRAND:
            return {...state, brands: payload};
        case TOWER_ACTION_TYPES.GET_SITE:
            return {...state, sites: payload};
        case TOWER_ACTION_TYPES.CLEAR_DATA:
            return INITIAL_STATE;
        case TOWER_ACTION_TYPES.UPLOAD_TOWER_STATUS:
            return {...state, uploadTowerStatus: payload, uploadedFiles: [], uploadedImages: []}
        case TOWER_ACTION_TYPES.UPLOAD_IMAGE:
            return {...state, uploadedImages: payload}
        case TOWER_ACTION_TYPES.UPLOAD_IMAGE_STATUS:
            return {...state, uploadImageStatus: payload}
        case TOWER_ACTION_TYPES.UPLOAD_FILE:
            const newArray = addIntoArray(state.uploadedFiles, payload);
            return {...state, uploadedFiles: newArray}
        case TOWER_ACTION_TYPES.UPLOAD_FILE_STATUS:
            return {...state, uploadFileStatus: payload}
        case TOWER_ACTION_TYPES.REMOVED:
            return {...state, removeStatus: payload}
        case TOWER_ACTION_TYPES.UPDATED:
            return {...state, updateStatus: payload}
        case TOWER_ACTION_TYPES.FETCH_TOWER_MILITER:
            return {...state, updateStatus: payload, towerMiliterData: payload}
        case TOWER_ACTION_TYPES.FETCH_TOWER_DISTRIBUTION:
            return {...state, towerDistributionData: payload}
        case TOWER_ACTION_TYPES.SET_TOGGLE_WHITE_TOWER:
            return {...state, isWhiteToggleActive: payload}
        case TOWER_ACTION_TYPES.SET_TOGGLE_RED_TOWER:
            return {...state, isRedToggleActive: payload}
        default:
            return state;
    }
}