export const RADIO_ACTION_TYPES = {
    GET_STATUS : 'radio/GET_STATUS',
    GET_ALL_RADIO : 'radio/GET_ALL_RADIO',
    GET_CATEGORY : 'radio/GET_CATEGORY',
    GET_BRAND : 'radio/GET_BRAND',
    GET_TYPE : 'radio/GET_TYPE',
    GET_SITE : 'radio/GET_SITE',
    CLEAR_DATA : 'radio/CLEAR_DATA',
    CHANGE_PAGE : 'radio/CHANGE_PAGE',
    UPLOAD_RADIO_STATUS : 'radio/UPLOAD_RADIO_STATUS',
    UPLOAD_IMAGE_STATUS : 'radio/UPLOAD_IMAGE_STATUS',
    UPLOAD_FILE_STATUS : 'radio/UPLOAD_FILE_STATUS',
    UPLOAD_IMAGE : 'radio/UPLOAD_IMAGE',
    UPLOAD_FILE : 'radio/UPLOAD_FILE',
    UPLOAD_FILE_NAME : 'radio/UPLOAD_FILE_NAME',
    IS_LOADING : 'radio/IS_LOADING',
    REMOVED : 'radio/REMOVED',
    UPDATED : 'radio/UPDATED',
    SEARCH : 'radio/SEARCH',
    SEARCH_REPORT : 'radio/SEARCH_REPORT',
    DETAIL_RADIO : 'radio/DETAIL_RADIO',
    CLEAR_DETAIL_RADIO : 'radio/CLEAR_DETAIL_RADIO',
}