import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ReactPaginate from "react-paginate";
import {useForm} from "react-hook-form";

import HeaderPage from "../../../components/header/HeaderPage";
import {COLUMN_USER, EMPTY_STRING, FAILED, NUMBER_ZERO, SUCCESS} from "../../../store/admin/admin.types";
import SearchBox from "../../../components/search-box/search-box.component";
import Table from "../../../components/table/Table";
import {
    ASSET_TYPE,
    ALERT_TITLE_SUCCESS,
    ALERT_TITLE_FAILED ,
    ALERT_ICON_SUCCESS,
    ALERT_ICON_FAILED,
    ALERT_TEXT_REMOVE_SUCCESS,
    ALERT_TEXT_REMOVE_FAILED,
    CONFIRMATION_TITLE_REMOVE,
    CONFIRMATION_TEXT_REMOVE,
    CONFIRMATION_ICON_REMOVE,
    ALERT_TEXT_UPDATE_SUCCESS,
    ALERT_TEXT_UPDATE_FAILED
} from "../../../utils/constant/constants";
import {
    handleChangeCurrentUserName,
    handleClearAllUserData,
    handleClearAUserData,
    handleDeleteUserById,
    handleFetchUserData,
    handleGetUserById,
    handleResetDeleteUserStatus,
    handleResetUpdateUser,
    handleUpdateUser
} from "../../../store/user/user.action";
import {
    selectAllUserData,
    selectAUserData,
    selectCurrentUser,
    selectRemoveUserStatus,
    selectStatusUpdateUser,
    selectTotalPage
} from "../../../store/user/user.selector";
import {handleClearStateGlobal, handleLoading} from "../../../store/global/global.action";
import {selectAssetToRemove, selectCurrentItem} from "../../../store/global/global.selector";
import {ModalForm} from "../../../components/modal/ModalForm";
import { generalAlert, generalConfirmation } from "../../../utils/notification/notification";

const UserManager = () => {
    const {register, handleSubmit, reset, setValue, getValues, formState: { errors }} = useForm(({
        defaultValues: {
            name: "",
            nrp: "",
        }
    }));


    const [page, setPage] = useState(NUMBER_ZERO);
    const [searchText, setSearchText] = useState(EMPTY_STRING);
    const [searchTextOnChange, setSearchTextOnChange] = useState(EMPTY_STRING);
    const [open, setOpen] = useState(false);
    const [updateLoggedInUser, setUpdateLoggedInUser] = useState(false);

    const dispatch = useDispatch();

    const currentUser = useSelector(selectCurrentUser);
    const allUserData = useSelector(selectAllUserData);
    const totalPages = useSelector(selectTotalPage);
    const assetToRemove = useSelector(selectAssetToRemove);
    const currentItem = useSelector(selectCurrentItem);
    const removeStatus = useSelector(selectRemoveUserStatus);
    const aUserData = useSelector(selectAUserData);
    const statusUpdateUser = useSelector(selectStatusUpdateUser);

    useEffect(() => {
        dispatch(handleLoading(true));
        dispatch(handleFetchUserData(page, searchText, currentUser.siteId));

        return () => {
            dispatch(handleClearAllUserData());
            dispatch(handleClearAUserData());
            dispatch(handleClearStateGlobal());
            setSearchText(EMPTY_STRING);
            setSearchTextOnChange(EMPTY_STRING);
            dispatch(handleLoading(false));
            resetState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (aUserData && aUserData.name) {
            setValue("name", aUserData.name);
            setValue("nrp", aUserData.nrp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aUserData])

    useEffect(() => {
        if (statusUpdateUser) {
            if (statusUpdateUser !== EMPTY_STRING) {
                if (statusUpdateUser === FAILED) {
                    dispatch(handleLoading(false));
                    generalAlert(
                        ALERT_TITLE_FAILED,
                        ALERT_TEXT_UPDATE_FAILED,
                        ALERT_ICON_FAILED,
                    )
                } else if (statusUpdateUser === SUCCESS) {
                dispatch(handleLoading(false));
                    generalAlert(
                        ALERT_TITLE_SUCCESS,
                        ALERT_TEXT_UPDATE_SUCCESS,
                        ALERT_ICON_SUCCESS,
                        () => dispatch(handleResetUpdateUser())
                    )
                    if (updateLoggedInUser) {
                        dispatch(handleChangeCurrentUserName(currentUser, getValues("name")));
                        setUpdateLoggedInUser(false);
                    }
                    dispatch(handleFetchUserData(page, searchText, currentUser.siteId));
                    reset();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusUpdateUser])

    /**
     * REMOVE DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.USER_MANAGER === assetToRemove && currentItem) {
            if (currentItem.id === currentUser.id) {
                dispatch(handleLoading(false));
                generalAlert(
                    ALERT_TITLE_FAILED,
                    "Cannot delete currently logged in user",
                    ALERT_ICON_FAILED,
                    () => dispatch(handleClearStateGlobal())
                )
            } else {
                generalConfirmation(
                    CONFIRMATION_TITLE_REMOVE,
                    CONFIRMATION_TEXT_REMOVE(currentItem.name),
                    CONFIRMATION_ICON_REMOVE,
                    () => {
                        dispatch(handleLoading(true));
                        dispatch(handleDeleteUserById(currentItem.id));
                    },
                    () => dispatch(handleClearStateGlobal()),
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToRemove]);


    useEffect(() => {
        if (removeStatus !== EMPTY_STRING) {
            dispatch(handleFetchUserData(page, searchText, currentUser.siteId));
            dispatch(handleResetDeleteUserStatus());
            if (removeStatus === SUCCESS) {
                dispatch(handleLoading(false));
                generalAlert(
                    ALERT_TITLE_SUCCESS,
                    ALERT_TEXT_REMOVE_SUCCESS,
                    ALERT_ICON_SUCCESS,
                    // () => dispatch(handleClearNotif())
                )
            } else if (removeStatus === FAILED) {
                dispatch(handleLoading(false));
                generalAlert(
                    ALERT_TITLE_FAILED,
                    ALERT_TEXT_REMOVE_FAILED,
                    ALERT_ICON_FAILED,
                    // () => dispatch(handleClearNotif())
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeStatus])

    const handleClose = () => {
        reset();
        setOpen(false);
    };

    const handlePageClick = (e) => {
        dispatch(handleFetchUserData(page, searchText, currentUser.siteId));
        setPage(e.selected);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));
    
        setSearchText(searchTextOnChange);
    
        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value;
    
        dispatch(handleFetchUserData(page, searchText, currentUser.siteId));
    
        setPage(NUMBER_ZERO);
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    };

    const handleSave = (data) => {
        if (aUserData && aUserData.name && aUserData.id && aUserData.name !== data.name && currentUser && currentUser.id) {
            if (currentUser.id === aUserData.id) {
                setUpdateLoggedInUser(true);
            }
            dispatch(handleLoading(true));
            dispatch(handleUpdateUser(aUserData.id, data));
            setOpen(false);
        } else {
            generalAlert("Error input", `The input data has not changed`, "error");
        }
    }

    const getUserById = (id) => {
        setOpen(true);
        dispatch(handleGetUserById(id));
    }


    const resetState = () => {
        setSearchText(EMPTY_STRING);
        setSearchTextOnChange(EMPTY_STRING);
        setPage(NUMBER_ZERO);
        setOpen(false);
    };

    /**
     * fetch user data
     */
    // const fetchUserData = () => {
    //     if (currentUser && currentUser.siteId) {
    //         dispatch(handleFetchUserData(page, search, currentUser.siteId));
    //     }
    // };

    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="User Manager"/>

            <div className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">
                    <div className="flex mt-14 mb-3">
                        <form className="flex justify-start mb-6" onSubmit={handleSearch}>
                            <SearchBox
                                name="searchText"
                                value={searchTextOnChange}
                                placeholder="Search User"
                                className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                                onChangeHandler={onSearchChange}
                            />
                            <span className="flex justify-center items-center text-white text-lg px-1 mr-auto">
                                <button
                                    type="submit"
                                    className="btn btn-primary rounded-full text-white text-xs px-7"
                                >
                                    Search
                                </button>
                            </span>
                        </form>
                    </div>

                    <div className="createModal">
                        <ModalForm
                            open={open}
                            onClose={handleClose}
                            onSave={handleSubmit(handleSave)}
                            title="Edit User Name"
                            icon="user"
                            btnCloseTitle="Cancel"
                            btnSubmitTitle="Save"
                            size="w-2/3"
                            closeBtn={true}
                        >
                            <div>
                                <h1 className="font-bold mt-2">Change Name</h1>
                                <div className="flex flex-row  items-center my-2">
                                    <label className="text-white w-1/3" htmlFor="inline-full-name">
                                        Name
                                    </label>
                                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                        <input
                                            {...register("name", {
                                                required: "Required!",
                                                pattern: {
                                                    value: /^\S+/,
                                                    message: "Entered value cant start or contain only white spacing"
                                                },
                                            })}
                                            pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                            title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                            className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.name ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                            id="inline-full-name"
                                            type="text"
                                            placeholder={errors?.name?.message}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-row  items-center my-2">
                                    <label className="text-white w-1/3" htmlFor="inline-full-name">
                                        NRP
                                    </label>
                                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                        <input
                                            {...register("nrp", {
                                                required: "Required!",
                                                pattern: {
                                                    value: /^\S+/,
                                                    message: "Entered value cant start or contain only white spacing"
                                                },
                                            })}
                                            pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                            title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                            className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.name ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                            id="inline-full-name"
                                            type="text"
                                            disabled
                                            placeholder={errors?.nrp?.message}
                                        />
                                    </div>
                                </div>
                            </div>


                        </ModalForm>
                    </div>

                    {allUserData && allUserData.length > 0 ? (
                        <Table
                            data={allUserData}
                            column={COLUMN_USER}
                            assetType={ASSET_TYPE.USER_MANAGER}
                            assetDetail={getUserById}
                        />
                    ) : (
                        <p className="flex flex-col text-white text-center pt-10">
                            Data is empty
                        </p>
                    )}

                    <div className="text-white flex flex-col">
                        <div className="flex justify-center  my-6 self-end h-14 items-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserManager;