import { ACTION_GET, ACTION_PUT, ACTION_POST, BASE_URL } from "./core";

export const GET_ALL_DISTRIBUTIONASSET = () => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/distributionassets`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_DISTRIBUTIONASSET_BY_ID = (id) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/distributionassets/${id}`;
  return new Promise((resolve, reject) => {
    ACTION_GET(url)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const CREATE_DISTRIBUTIONASSET = (param) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/distributionassets`;
  return new Promise((resolve, reject) => {
    ACTION_POST(url, param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_DISTRIBUTIONASSET = (param) => {
  //const setQuery = serialize(query);
  const url = `${BASE_URL}/distributionassets`;
  return new Promise((resolve, reject) => {
    ACTION_PUT(url, param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

