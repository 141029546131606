import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ReactPaginate from "react-paginate";
import {useForm} from "react-hook-form";

import HeaderPage from "../../../components/header/HeaderPage";
import Table from "../../../components/table/Table";
import SearchBox from "../../../components/search-box/search-box.component";
import PlusIcon from "../../../assets/icons/PlusIcon";
import {ModalForm} from "../../../components/modal/ModalForm";
import {
    ACTION_TYPE_DATA_CREATE,
    ACTION_TYPE_DATA_NS,
    ACTION_TYPE_DATA_UPDATE,
    COLUMN_TYPE,
    EMPTY_STRING,
    FAILED,
    FILTER_BY_BRAND,
    FILTER_BY_NAME,
    FILTER_BY_USAGE,
    NUMBER_TEN,
    NUMBER_ZERO,
    SUCCESS
} from "../../../store/admin/admin.types";
import {ASSET_TYPE, CONFIRMATION_ICON_REMOVE, CONFIRMATION_TEXT_REMOVE, CONFIRMATION_TITLE_REMOVE, ALERT_TITLE_SUCCESS, ALERT_TITLE_FAILED, ALERT_ICON_SUCCESS, ALERT_ICON_FAILED, adminUsage} from "../../../utils/constant/constants";
import {
    selectAType,
    selectBrandNames,
    selectCreateTypeMessage,
    selectCreateTypeStatus,
    selectRemoveTypeMessage,
    selectRemoveTypeStatus,
    selectTypes,
    selectTypesTotalPages,
    selectUpdateTypeMessage,
    selectUpdateTypeStatus,
} from "../../../store/admin/admin.selector";
import {selectAssetToEdit, selectAssetToRemove, selectCurrentItem} from "../../../store/global/global.selector";
import {
    handleClearTypeStatus,
    handleCreateType,
    handleGetTypeById,
    handleSearchBrand,
    handleSearchBrandForType,
    handleSearchType,
    handleUpdateType,
    handleDeleteType,
} from "../../../store/admin/admin.action";
import {handleClearStateGlobal, handleLoading} from "../../../store/global/global.action";
import { generalAlert, generalConfirmation } from "../../../utils/notification/notification";

const AssetType = () => {
    const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm(({
        defaultValues: {
            name: EMPTY_STRING,
            usage: EMPTY_STRING,
            brandName: EMPTY_STRING,
        }
    }));
    const [filterSelect, setFilterSelect] = useState(FILTER_BY_USAGE);
    const [searchText, setSearchText] = useState(EMPTY_STRING);
    const [searchTextOnChange, setSearchTextOnChange] = useState(EMPTY_STRING);
    const [page, setPage] = useState(NUMBER_ZERO);
    const [open, setOpen] = useState(false);
    const [actionType, setActionType] = useState(ACTION_TYPE_DATA_NS);

    const allBrandNames = useSelector(selectBrandNames);
    const types = useSelector(selectTypes);
    const aType = useSelector(selectAType);
    const createTypeStatus = useSelector(selectCreateTypeStatus);
    const updateTypeStatus = useSelector(selectUpdateTypeStatus);
    const removeTypeStatus = useSelector(selectRemoveTypeStatus);
    const createTypeMessage = useSelector(selectCreateTypeMessage);
    const updateTypeMessage = useSelector(selectUpdateTypeMessage);
    const removeTypeMessage = useSelector(selectRemoveTypeMessage);
    const typesTotalPages = useSelector(selectTypesTotalPages);
    const assetToRemove = useSelector(selectAssetToRemove);
    const assetToEdit = useSelector(selectAssetToEdit);
    const currentItem = useSelector(selectCurrentItem);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(handleLoading(true));
        dispatch(handleSearchType(page, NUMBER_TEN, filterSelect, searchText));

        return () => {
            reset();
            setFilterSelect(FILTER_BY_USAGE);
            setSearchText(EMPTY_STRING);
            setSearchTextOnChange(EMPTY_STRING);
            setPage(NUMBER_ZERO);
            setActionType(ACTION_TYPE_DATA_NS)
            dispatch(handleClearTypeStatus());
            dispatch(handleClearStateGlobal());
            dispatch(handleLoading(false));
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (aType && actionType === ACTION_TYPE_DATA_UPDATE) {
            setValue("name", aType.name);
            setValue("usage", aType.usage);
            setValue("brandName", aType.brandId)
            setOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aType])

    useEffect(() => {
        handleStatus(createTypeStatus, createTypeMessage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createTypeStatus])

    useEffect(() => {
        handleStatus(updateTypeStatus, updateTypeMessage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateTypeStatus])

    useEffect(() => {
        handleStatus(removeTypeStatus, removeTypeMessage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeTypeStatus])

    /**
     * EDIT DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.ADMIN_TYPE === assetToEdit && currentItem) {
            dispatch(handleSearchBrand(NUMBER_ZERO, 1000, "usage", EMPTY_STRING));
            setActionType(ACTION_TYPE_DATA_UPDATE);
            dispatch(handleGetTypeById(currentItem.assetType));
            dispatch(handleSearchBrandForType(currentItem.usage));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToEdit]);

    /**
     * REMOVE DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.ADMIN_TYPE === assetToRemove && currentItem) {
            generalConfirmation(
                CONFIRMATION_TITLE_REMOVE,
                CONFIRMATION_TEXT_REMOVE(currentItem.name),
                CONFIRMATION_ICON_REMOVE,
                () => {
                    dispatch(handleLoading(true));
                    dispatch(handleDeleteType(currentItem.assetType))
                },
                () => dispatch(handleClearStateGlobal()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetToRemove]);

    const handleStatus = (status, textMessage) => {
        if (status !== EMPTY_STRING) {
            if (status === SUCCESS) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_SUCCESS, textMessage, ALERT_ICON_SUCCESS, () => dispatch(handleClearTypeStatus()));
            } else if (status === FAILED) {
                dispatch(handleLoading(false));
                generalAlert(ALERT_TITLE_FAILED, textMessage, ALERT_ICON_FAILED, () => dispatch(handleClearTypeStatus()));
            }
            reset();
            setActionType(ACTION_TYPE_DATA_NS);
            if (types && types.content && types.content.length) {
                dispatch(handleSearchType(page, NUMBER_TEN, filterSelect, searchText));
            } else {
                if (page) {
                    dispatch(handleSearchType(page - 1, NUMBER_TEN, filterSelect, searchText));
                    setPage(prevPage => prevPage - 1);
                } else {
                    dispatch(handleSearchType(0, NUMBER_TEN, filterSelect, searchText));
                    setPage(0);
                }
            }
            dispatch(handleClearStateGlobal());
            setOpen(false);
        }
    };

    const handleSelectFilter = (e) => {
        dispatch(handleSearchType(page, NUMBER_TEN, e.target.value, searchText));
        setFilterSelect(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));
    
        setSearchText(searchTextOnChange);
    
        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value;
    
        dispatch(handleSearchType(NUMBER_ZERO, NUMBER_TEN, filterSelect, searchText));
    
        setPage(NUMBER_ZERO);
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    };

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        dispatch(handleSearchType(e.selected, NUMBER_TEN, filterSelect, searchText));
        setPage(e.selected);
    };

    const handleOpen = () => {
        dispatch(handleSearchBrand(NUMBER_ZERO, 1000, "usage", searchText));
        setActionType(ACTION_TYPE_DATA_CREATE);
        setOpen(true);
    }

    const handleClose = () => {
        reset();
        setActionType(ACTION_TYPE_DATA_NS);
        dispatch(handleClearTypeStatus());
        dispatch(handleClearStateGlobal());
        dispatch(handleSearchType(page, NUMBER_TEN, filterSelect, searchText));
        setOpen(false);
    };

    const handleSave = (data) => {
        dispatch(handleClearTypeStatus());

        const {name, usage, brandName} = data;
        const trimName = name.trimEnd();
        const payload = {name: trimName, usage, brandId: brandName};
        if (actionType === ACTION_TYPE_DATA_CREATE && trimName && usage && brandName) {
            dispatch(handleLoading(true));
            dispatch(handleCreateType(payload));
        } else if (actionType === ACTION_TYPE_DATA_UPDATE && currentItem) {
            const {assetType, name: currentName, usage: currentUsage, brandName: currentBrandName} = currentItem;
            if (assetType && (currentName !== trimName || currentUsage !== usage || currentBrandName !== brandName)) {
                dispatch(handleLoading(true));
                dispatch(handleUpdateType(assetType, aType, payload));
            } else {
                generalAlert(ALERT_TITLE_FAILED, `The input data has not changed`, ALERT_ICON_FAILED);
            }
        }
    }

    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="Asset Type"/>

            <div className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">
                    <form className="flex mt-14 mb-3" onSubmit={handleSearch}>
                        <select
                            name="filter"
                            className="select select-bordered w-[200px] rounded-3xl h-[30px] mr-2"
                            onChange={handleSelectFilter}
                        >
                            <option value={FILTER_BY_USAGE} selected>USAGE</option>
                            <option value={FILTER_BY_BRAND}>BRAND NAME</option>
                            <option value={FILTER_BY_NAME}>TYPE NAME</option>
                        </select>
                        <div className="flex justify-start mb-6">
                            <SearchBox
                                name="searchText"
                                value={searchTextOnChange}
                                placeholder="Search Brand"
                                className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                                onChangeHandler={onSearchChange}
                            />
                            <span className="flex justify-center items-center text-white text-lg px-1 mr-auto">
                                <button
                                    type="submit"
                                    className="btn btn-primary rounded-full text-white text-xs px-7"
                                >
                                    Search
                                </button>
                            </span>
                            <button
                                type="button"
                                className="flex justify-center items-center text-white gap-x-2 text-lg ml-[90px]"
                                onClick={handleOpen}
                            >
                                <PlusIcon width={18} height={16}/>
                                Add Type
                            </button>
                        </div>
                    </form>

                    <div className="createModal">
                        <ModalForm
                            open={open}
                            onClose={handleClose}
                            onSave={handleSubmit(handleSave)}
                            title={actionType === ACTION_TYPE_DATA_CREATE ? `Create Type` : `Update Type`}
                            icon="user"
                            btnCloseTitle="Cancel"
                            btnSubmitTitle={actionType === ACTION_TYPE_DATA_CREATE ? `Save` : `Update`}
                            size="w-2/3"
                            closeBtn={true}
                        >
                            <div>
                                <div className="flex flex-row  items-center my-2">
                                    <label className="text-white w-1/3" htmlFor="name">
                                        Name
                                    </label>
                                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                        <input
                                            {...register("name", {
                                                required: "Required!",
                                                pattern: {
                                                    value: /^\S+/,
                                                    message: "Entered value cant start or contain only white spacing"
                                                },
                                            })}
                                            pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                            title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                            className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.name ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                            id="name"
                                            type="text"
                                            autoFocus
                                            placeholder={errors?.name?.message}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row  items-center my-2">
                                    <label className="text-white w-1/3" htmlFor="usage">
                                        Usage
                                    </label>
                                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                        <select
                                            {...register("usage", {
                                                required: "Required!",
                                                onChange: (e) => {
                                                    dispatch(handleSearchBrandForType(e.target.value));
                                                }
                                            })}
                                            className={`select select-bordered w-full bg-white text-black font-thin border-2 focus:border-purple-500 ${errors?.usage ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                                            disabled={actionType === ACTION_TYPE_DATA_UPDATE}
                                        >
                                            <option value="" selected disabled>Select Usage</option>
                                            {adminUsage && adminUsage.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.value}>
                                                        {item.label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="flex flex-row  items-center my-2">
                                    <label className="text-white w-1/3" htmlFor="brandName">
                                        Brand Name
                                    </label>
                                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                        <select
                                            {...register("brandName", {
                                                required: "Required!",
                                            })}
                                            className={`select select-bordered w-full bg-white text-black font-thin border-2 focus:border-purple-500 ${errors?.brandName ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                                        >
                                            <option value="" selected disabled>Select Brand</option>
                                            {allBrandNames && allBrandNames.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.value}>
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </ModalForm>
                    </div>

                    {types && types.content.length > 0 ? (
                        <Table
                            data={types.content}
                            column={COLUMN_TYPE}
                            assetType={ASSET_TYPE.ADMIN_TYPE}
                        />
                    ) : (
                        <p className="flex flex-col text-white text-center pt-10">
                            Data is empty
                        </p>
                    )}

                    <div className="text-white flex flex-col">
                        <div className="flex justify-center  my-6 self-end h-14 items-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={typesTotalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={page}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssetType;
