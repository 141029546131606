import React, {createRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

// Redux
import {selectIsRedToggleActive, selectTowerMiliterData} from "../../store/tower/tower.selector";
import {selectViewer} from "../../store/dashboard/dashboard.selector";
import {handleRedTower, handleRedTowerToggle} from "../../store/tower/tower.action";
import {selectCurrentUser} from "../../store/user/user.selector";

// Asset
import {ColorRedTower} from "../../assets/png";

const RedTower = () => {
    const ref = createRef();
    const dispatch = useDispatch();
    const towerMiliters = useSelector(selectTowerMiliterData);
    const currentUser = useSelector(selectCurrentUser);
    const viewer = useSelector(selectViewer);
    const isToggleActive = useSelector(selectIsRedToggleActive);

    useEffect(() => {
        dispatch(handleRedTowerToggle(false));
        
        ref.current.classList.add("cesium-button", "cesium-toolbar-button");
        const toolbar = document.querySelector("div.cesium-viewer-toolbar");
        const modeButton = document.querySelector("span.cesium-projectionPicker-wrapper");
        toolbar.insertBefore(ref.current, modeButton);

        return()=> {
            dispatch(handleRedTowerToggle(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = async () => {
        dispatch(handleRedTowerToggle(!isToggleActive));
        dispatch(handleRedTower(viewer, isToggleActive, towerMiliters, currentUser));
    }

    return (
        <>
            <div
                id="redTower"
                style={{background: isToggleActive ? 'white' : '#303336'}} ref={ref}
                onClick={handleClick}
            >
                <img
                    src={ColorRedTower}
                    alt="colorRedTower"
                    width={24}
                    height={20}
                    className="h-[28px] ml-[2.5px]"
                />
            </div>
        </>
    );
};

export default RedTower
