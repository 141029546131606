/* Styling the dropdown menu. */
export const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        padding: 20,
        background: "white",
        borderRadius: 10,
    }),
    menuList: (provided, state) => ({
        ...provided,
        maxHeight: 150,
        borderRadius: 10,
        pointer: "cursor",
    }),
    container: (provided, state) => ({
        ...provided,
        width: 320,
        borderRadius: 10,
    }),
    control: (provided, state) => ({
        ...provided,
        background: "black",
        borderRadius: 10,
    }),

    option: (provided, state) => ({
        ...provided,
        borderBottom: "1px solid #031952",
        color: state.isSelected ? "white" : "black",
        background: state.isSelected ? "#031952" : "white",
        pointer: "cursor",
    }),
    singleValue: (provided, state) => ({...provided, color: "white"}),
};