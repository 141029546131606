import React, {useState, useEffect} from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { report } from "../../../utils/report/report";

import LambangTNI from "../../../assets/png/Lambang_TNI_AU.png";
import { PM } from "../../../utils/constant/constants";

const ReportOperation = ({ title, date, dataSim, dataAct, chartImage, parameterData }) => {
    const [profile, setProfile] = useState([]);

    const detailParamsData = () => {
        if (parameterData) {
            const { name, network, coverageType } = parameterData;
            const {
                lat: latitude,
                lon: longitude,
                alt: highTower,
                frq: frequency,
                txw: txPower,
                bwi: bandwidth,
            } = parameterData.transmitter;
            const {
                txl: loss,
                txg: transmitterGain,
                pol: polarization,
            } = parameterData.antenna;
            const {
                rxg: rxGain,
                rxs: sensitivity,
            } = parameterData.receiver;
            const {
                res: resolution,
                col: colorSchema,
                rad: radius,
            } = parameterData.output;
            const { pm: model, rel: reliability } = parameterData.model;
            const {
                coaxialTypeDto: { name: coaxialType },
                coaxialLength,
                connector,
                erp,
                eirp,
                effieciency,
                patternDto: { name: patternName, img1: patternImg1, img2: patternImg2 },
            } = parameterData.additionalDTO;
            return {
                name,
                network,
                coverageType,
                latitude,
                longitude,
                highTower,
                frequency,
                txPower,
                bandwidth,
                loss,
                transmitterGain,
                polarization,
                rxGain,
                sensitivity,
                resolution,
                colorSchema,
                radius,
                model,
                reliability,
                coaxialType,
                coaxialLength,
                connector,
                erp,
                eirp,
                effieciency,
                patternName,
                patternImg1,
                patternImg2,
            };
        }
    };

    const detailModel = (inputModel) => {
        return PM[inputModel - 1].label
    }

    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem("profile"));
        setProfile(userProfile);
    }, []);

    const handlePdfGenerate = () => {
        // Format
        const doc = new jsPDF("potrait", "px", "a4", "false");

        const {
            name,
            network,
            coverageType,
            latitude,
            longitude,
            highTower,
            frequency,
            txPower,
            bandwidth,
            loss,
            transmitterGain,
            polarization,
            rxGain,
            sensitivity,
            resolution,
            colorSchema,
            radius,
            model,
            reliability,
            coaxialType,
            coaxialLength,
            connector,
            erp,
            eirp,
            effieciency,
            patternName,
            patternImg1,
            patternImg2,
        } = detailParamsData();

        // Template
        report.templateAsset(
            doc,
            1,
            profile,
            LambangTNI,
            "Operation Data",
        )

        doc.setFontSize(10);
        doc.setFont(undefined, "normal").text(30, 150, `Name : ${title}`);
        doc.setFont(undefined, "normal").text(30, 165, `Actual Mission : ${date ? date : "-"}`);

        // Chart Image
        report.chartImgOperation(doc, chartImage);

        // Simulation Data Table
        doc.setFontSize(8);
        doc.setFont(undefined, "normal").text(30, 420, "Simulation Data");
        autoTable(doc, {
            theme: "grid",
            styles: { textColor: [0, 0, 0], fontSize: 7, overflow: 'linebreak'},
            margin: { left: 30 },
            startY: 425,
            didParseCell(data) {
                if (data.row.index % 2 !== 0) {
                    data.cell.styles.textColor = [0, 0, 0];
                    data.cell.styles.fillColor = '#D3D3D3';
                }
                if (data.column.index === 0) {
                    data.cell.styles.cellWidth = 15;
                }
            },
            tableWidth: 185,
            head: [["No", "Altitude", "Distance"]],
            body: dataSim.map((asset, index) => {
                // pageNumber += 1
                return [index + 1, asset?.altitude, asset?.distance]
            }),
        });

        // Actual Mission Data Table
        doc.setFontSize(8);
        doc.setFont(undefined, "normal").text(235, 420, "Actual Mission");
        autoTable(doc, {
            theme: "grid",
            styles: { textColor: [0, 0, 0], fontSize: 7, overflow: 'linebreak'},
            margin: { left: 235 },
            startY: 425,
            didParseCell(data) {
                if (data.row.index % 2 !== 0) {
                    data.cell.styles.textColor = [0, 0, 0];
                    data.cell.styles.fillColor = '#D3D3D3';
                }
                if (data.column.index === 0) {
                    data.cell.styles.cellWidth = 15;
                }
            },
            tableWidth: 185,
            head: [["No", "Altitude", "Distance"]],
            body: dataAct.map((asset, index) => {
                // pageNumber += 1
                return [index + 1, asset?.altitude, asset?.distance]
            }),
        });

        report.addPage(doc);

        report.template(
            doc,
            2,
            profile,
            LambangTNI,
            "Operation Data",
        )

        // Ground Transmitter / TX
        report.transmitter(
            doc,
            coverageType,
            name,
            network,
            latitude,
            longitude,
            highTower,
            frequency,
            txPower,
            bandwidth,
        )

        // Feeder
        report.feeder(
            doc,
            coaxialType,
            coaxialLength,
            connector,
            loss,
            erp,
            eirp,
            effieciency,
        )

        // Airbone
        report.airboneOP(
            doc,
            rxGain,
            sensitivity,
        )

        // Propagation Model
        report.propagationModelOP(
            doc,
            detailModel(model),
            reliability,
        )

        // Antenna
        report.antenna(
            doc,
            transmitterGain,
            polarization,
            patternName,
            patternImg1,
            patternImg2,
        )

        // Output Design
        report.outputDesign(
            doc,
            resolution,
            colorSchema,
            radius,
        )

        report.savePdf(doc, "Operation Data")
        
    };

    return (
        <button
            type="button"
            className="text-white text-lg underline underline-offset-4 float-right"
            onClick={handlePdfGenerate}
        >
            Create Report
        </button>
    )
};

export default ReportOperation;
