import * as React from "react";

const DashboardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 1503 1503"
    {...props}
  >
    <g stroke="currentColor">
      <path
        fill="none"
        strokeWidth={7.805}
        d="M860 509v214c-56 0-107-21-145-56l145-158zM290 756h337l71-78c47 43 110 70 179 70 146 0 264-118 264-264h73v541H877l-250 272H290V756zm609-250h220c0 121-99 220-220 220V506z"
      />
      <circle
        fill="currentColor"
        strokeWidth={1.041}
        cx={877}
        cy={484}
        r={278}
      />
      <path
        fill="currentColor"
        strokeWidth={1.041}
        d="M307 785h301v484H307zM645 767l62-68c41 38 95 61 155 61v240l-217 236V767zM1142 527h49v470H899V756c136-14 223-105 243-229z"
      />
      <circle fill="#FEFEFE" strokeWidth={1.041} cx={877} cy={484} r={264} />
      <path
        fill="#FEFEFE"
        strokeWidth={7.805}
        d="M851 252c-119 7-212 108-212 230 0 78 38 148 96 189h116V252z"
      />
      <g fill="currentColor" strokeWidth={1.041}>
        <path d="M1080 337c-14-21-32-39-53-54H924c-4 0-6 3-6 6v175c0 4 2 7 6 7h150c4 0 6-3 6-7V337zm-10 5c-13-18-28-34-46-47h-91c-3 0-5 2-5 5v153c0 3 2 6 5 6h132c3 0 5-3 5-6V342z" />
        <path d="M936 309h92.549v5.744H936zM936 327h92.549v5.744H936zM936 355h121.909v5.744H936zM936 372h121.909v5.744H936zM936 394h121.909v5.744H936zM936 420h121.909v5.744H936z" />
      </g>
      <path
        fill="currentColor"
        strokeWidth={1.041}
        d="m715 667 145-158v214c-56 0-107-21-145-56zM899 506h220c0 121-99 220-220 220V506z"
      />
      <path
        fill="currentColor"
        strokeWidth={1.041}
        d="M163 1h1177c90 0 163 73 163 162v1177c0 90-73 163-163 163H163c-89 0-162-73-162-163V163C1 74 74 1 163 1zm41 51h1096c83 0 151 68 151 152v1096c0 83-68 151-151 151H204c-84 0-152-68-152-151V204c0-84 68-152 152-152z"
      />
    </g>
  </svg>
);

export default DashboardIcon;
