import {createSelector} from "reselect";

const selectCoverageRulerReducer = (state) => state.coverageRulerReducer;

export const selectRulerToggle = createSelector(
    [selectCoverageRulerReducer],
    (state) => state.rulerToggle
);
export const selectEntityIdArray = createSelector(
    [selectCoverageRulerReducer],
    (state) => state.entityIdArray
);
export const selectEntityName = createSelector(
    [selectCoverageRulerReducer],
    (state) => state.entityName
);
export const selectEntityId = createSelector(
    [selectCoverageRulerReducer],
    (state) => {
        let result = null
        if (state.entityIdArray && state.entityIdArray.length) {
            result = state.entityIdArray[state.entityIdArray.length - 1]; // get value in last index
        }
        return result;
    }
);
