import {ACTION_GET, BASE_URL,} from "./core";

export const GET_LOGS = (type, q, page, size ) => {
    let url = `${BASE_URL}/logs?type=${type}&q=${q}&page=${page}&size=${size}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

