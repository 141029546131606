import {createSelector} from "reselect";
import {getPercentage} from "../../utils/calculate/convert";

const selectPABXReducer = (state) => state.pabxReducer;

export const selectAllPABX = createSelector(
    [selectPABXReducer],
    (state) => state.pabxs
)
export const selectAllPABXReport = createSelector(
    [selectPABXReducer],
    (state) => state.pabxsReport
)
export const selectTotalPages = createSelector(
    [selectAllPABX],
    (pabxs) => {
        if (pabxs && pabxs.pabx) {
            return pabxs.pabx.totalPages
        } else {
            return null
        }
    }
)
export const selectCategories = createSelector(
    [selectPABXReducer],
    (state) => state.categories
)
export const selectDetailPABX = createSelector(
    [selectPABXReducer],
    (state) => state.detailPABX
)
export const selectBrands = createSelector(
    [selectPABXReducer],
    (state) => state.brands
)
export const selectTypes = createSelector(
    [selectPABXReducer],
    (state) => state.types
)
export const selectPABXTypesByBrand = createSelector(
    [selectPABXReducer],
    state => {
        if (state.typesByBrand) {
            return state.typesByBrand;
        } else {
            return null;
        }
    }
);
export const selectSites = createSelector(
    [selectPABXReducer],
    (state) => state.sites
)
export const selectDataChart = createSelector(
    [selectAllPABX],
    (state) => {
        if (state && state.summary) {
            const status = state.summary;
            return [{
                datasets: [
                    {
                        label: "PABX",
                        data: [
                            getPercentage(status.spare, status.total),
                            getPercentage(status.service, status.total),
                            getPercentage(status.unservice, status.total)
                        ],
                        backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                        borderColor: ["#040f0f"],
                        borderWidth: 10,
                    },
                ],
            }]
        } else {
            return null;
        }
    }
)
export const selectStatusPABX = createSelector(
    [selectAllPABX],
    (state) => {
        if (state && state.summary) {
            const status = state.summary;
            return [
                {
                    id: 1,
                    name: "PABX Total",
                    item: status.total ?? 0,
                    icon: "pabx",
                    class: "",
                },
                {
                    id: 2,
                    name: "PABX Serviceable",
                    item: status.service ?? 0,
                    icon: "pabx",
                    class: "serviceable",
                },
                {
                    id: 3,
                    name: "PABX Spare",
                    item: status.spare ?? 0,
                    icon: "pabx",
                    class: "spare",
                },
                {
                    id: 4,
                    name: "PABX Unserviceable",
                    item: status.unservice ?? 0,
                    icon: "pabx",
                    class: "unserviceable",
                },
            ]
        } else {
            return null;
        }
    }
)

export const selectStatusArray = createSelector(
    [selectPABXReducer],
    (state) => state.statusArray
)
export const selectColumn = createSelector(
    [selectPABXReducer],
    (state) => state.column
)
export const selectUploadedImages = createSelector(
    [selectPABXReducer],
    (state) => state.uploadedImages
)
export const selectUploadedFiles = createSelector(
    [selectPABXReducer],
    (state) => state.uploadedFiles
)
export const selectUploadedPABXStatus = createSelector(
    [selectPABXReducer],
    (state) => state.uploadPABXStatus
)
export const selectUpdateStatus = createSelector(
    [selectPABXReducer],
    (state) => state.updateStatus
)
export const selectRemoveStatus = createSelector(
    [selectPABXReducer],
    (state) => state.removeStatus
)