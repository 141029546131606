import {createSelector} from "reselect";
import {getPercentage} from "../../utils/calculate/convert";

const selectRadioReducer = (state) => state.radioReducer;

export const selectAllRadio = createSelector(
    [selectRadioReducer],
    (state) => {
        if (state.radios) {
            return state.radios
        } else {
            return null;
        }
    }
)
export const selectAllRadioReport = createSelector(
    [selectRadioReducer],
    (state) => state.radiosReport
)
export const selectTotalPages = createSelector(
    [selectAllRadio],
    (radios) => {
        if (radios && radios.radio) {
            return radios.radio.totalPages
        } else {
            return null
        }
    }
)
export const selectCategories = createSelector(
    [selectRadioReducer],
    (state) => state.categories
)
export const selectDetailRadio = createSelector(
    [selectRadioReducer],
    (state) => state.detailRadio
)
export const selectBrands = createSelector(
    [selectRadioReducer],
    (state) => state.brands
)
export const selectTypes = createSelector(
    [selectRadioReducer],
    (state) => state.types
)
export const selectSites = createSelector(
    [selectRadioReducer],
    (state) => state.sites
)
export const selectDataChart = createSelector(
    [selectAllRadio],
    (state) => {
        if (state && state.summary) {
            const status = state.summary;
            return [{
                datasets: [
                    {
                        label: "Radio",
                        data: [
                            getPercentage(status.spare, status.total),
                            getPercentage(status.service, status.total),
                            getPercentage(status.unservice, status.total)
                        ],
                        backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                        borderColor: ["#040f0f"],
                        borderWidth: 10,
                    },
                ],
            }]
        } else {
            return null;
        }
    }
)
export const selectStatusRadio = createSelector(
    [selectAllRadio],
    (state) => {
        if (state && state.summary) {
            const status = state.summary;
            return [
                {
                    id: 1,
                    name: "Radio Total",
                    item: status.total ?? 0,
                    icon: "radio",
                    class: "",
                },
                {
                    id: 2,
                    name: "Radio Serviceable",
                    item: status.service ?? 0,
                    icon: "radio",
                    class: "serviceable",
                },
                {
                    id: 3,
                    name: "Radio Spare",
                    item: status.spare ?? 0,
                    icon: "radio",
                    class: "spare",
                },
                {
                    id: 4,
                    name: "Radio Unserviceable",
                    item: status.unservice ?? 0,
                    icon: "radio",
                    class: "unserviceable",
                },
            ]
        } else {
            return null;
        }
    }
)

export const selectStatusArray = createSelector(
    [selectRadioReducer],
    (state) => state.statusArray
)
export const selectColumn = createSelector(
    [selectRadioReducer],
    (state) => state.column
)
export const selectUploadedImages = createSelector(
    [selectRadioReducer],
    (state) => state.uploadedImages
)
export const selectUploadedFiles = createSelector(
    [selectRadioReducer],
    (state) => state.uploadedFiles
)

export const selectUploadedRadioStatus = createSelector(
    [selectRadioReducer],
    (state) => state.uploadRadioStatus
)
export const selectUpdateStatus = createSelector(
    [selectRadioReducer],
    (state) => state.updateStatus
)
export const selectRemoveStatus = createSelector(
    [selectRadioReducer],
    (state) => state.removeStatus
)