import React from "react";

import {
    Airbone,
    AntennaIcon,
    ArrowVertical,
    ClutterIcon,
    CoverageIcon,
    FilterIcon,
    PalleteIcon,
    ReceiverIcon,
    PlusIcon,
    Propagation,
    RadioAsset, 
} from "../../assets/svgs";

const RenderIcon = ({type}) => {
    switch (type) {
        case "at":
            return <AntennaIcon />;
        case "cv":
            return <CoverageIcon />;
        case "rc":
            return <ReceiverIcon />;
        case "fd":
            return <ArrowVertical />;
        case "ab":
            return <Airbone />;
        case "fp":
            return <FilterIcon stroke="white" />;
        case "plus":
            return <PlusIcon />;
        case "pg":
            return <Propagation />;
        case "pl":
            return <PalleteIcon />;
        case "ct":
            return <ClutterIcon />;
        case "ra":
            return <RadioAsset />;
        default:
            return "";
    }
};
const Titlebar = ({titleName, type, link, onLink, onClick}) => {
    return (
        <div className="flex gap-x-2 py-6 items-center justify-between">
            <div
                className="flex flex-row justify-start gap-x-2 items-center"
                onClick={onClick}
            >
                <RenderIcon type={type} />
                <h1 className="text-white">{titleName}</h1>
            </div>
            {!!link && (
                <h5
                    className="underline  underline-offset-1 text-white mr-5 cursor-pointer"
                    onClick={onLink}
                >
                    {link}
                </h5>
            )}
        </div>
    );
};

export default Titlebar;
