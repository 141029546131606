import * as React from "react"

const RadioCategoryIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 8267.72 11692.91"
    {...props}
  >
    <g strokeMiterlimit={22.926}>
      <path
        fill="currentColor"
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M-292.65 165.63h8904.69c677.3 0 1231.45 554.15 1231.45 1231.45v8904.69c0 677.3-554.15 1231.46-1231.45 1231.46H-292.65c-677.3 0-1231.46-554.15-1231.46-1231.46V1397.08c0-677.3 554.15-1231.45 1231.46-1231.45zm304.78 389.1h8295.11c630.96 0 1147.14 516.19 1147.14 1147.14v8295.11c0 630.96-516.19 1147.14-1147.14 1147.14H12.13c-630.96 0-1147.14-516.19-1147.14-1147.14V1701.87c0-630.96 516.19-1147.14 1147.14-1147.14z"
      />
      <path
        fill="currentColor"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        d="M249.75 3326.42h6220.94c72.88 0 132.5 59.62 132.5 132.5V4530.4H4579.53c-478.61 0-870.21 391.59-870.21 870.2v975.84H249.74c-72.88 0-132.5-59.62-132.5-132.5V3458.91c0-72.88 59.62-132.5 132.5-132.5z"
      />
      <path
        fill="none"
        stroke="#2B2A29"
        strokeWidth={29.53}
        d="M2802.69 1563.94h3513.62c66.11 0 122.71 42.79 143.46 102.04 2.84 8.11 5.02 16.54 6.44 25.2l355.7 1391.31c12.94 22.32 20.36 48.2 20.36 75.73v1372.17H4579.53c-478.61 0-870.21 391.59-870.21 870.2v1102.96H-.31c-83.56 0-151.93-68.37-151.93-151.93v-3193.4c0-56.22 30.95-105.56 76.66-131.83 4.52-2.6 9.19-4.97 13.99-7.11l2741.88-1393.15c27.72-37.65 72.33-62.19 122.39-62.19z"
      />
      <path
        fill="#FEFEFE"
        stroke="#2B2A29"
        strokeWidth={7.87}
        d="M1708.09 3542.5c687.3 0 1244.46 557.16 1244.46 1244.46 0 687.29-557.16 1244.46-1244.46 1244.46-687.29 0-1244.46-557.17-1244.46-1244.46 0-687.3 557.17-1244.46 1244.46-1244.46z"
      />
      <path
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        d="M3839.66 3847.98c303.42 0 549.37 245.96 549.37 549.37 0 55.63-8.3 109.31-23.67 159.91-221.75 56.67-410.13 199.09-527.37 389.44-302.65-.9-547.71-246.5-547.71-549.35 0-303.41 245.96-549.37 549.37-549.37z"
      />
      <path
        fill="#FEFEFE"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        d="M3903.37 3655.5c231.74 0 422.69 174.65 448.4 399.5l.15 1.33 26.87 234.87-.04-.01c-49.46-252.6-272-443.21-539.09-443.21-196.88 0-369.52 103.58-466.53 259.2h-.04l128.56-206.27a451.062 451.062 0 0 1 39.47-63.34c82.27-110.5 213.91-182.06 362.25-182.06z"
      />
      <path
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        d="M5514.59 4000.9c201.02 0 363.98 162.96 363.98 363.98 0 59.61-14.33 115.87-39.72 165.52h-648.51c-25.4-49.65-39.72-105.91-39.72-165.52 0-201.02 162.95-363.98 363.98-363.98z"
      />
      <path
        fill="#FEFEFE"
        stroke="#FEFEFE"
        strokeWidth={39.37}
        d="M5556.81 3873.37c153.54 0 280.05 115.71 297.08 264.69.04.29.07.59.11.88l17.8 155.61h-.03c-32.77-167.36-180.2-293.64-357.17-293.64-130.44 0-244.83 68.63-309.09 171.73l-.03-.01 85.18-136.66a299.157 299.157 0 0 1 26.15-41.97c54.51-73.21 141.72-120.63 240-120.63z"
      />
      <path
        fill="#2B2A29"
        stroke="#FEFEFE"
        strokeWidth={29.53}
        d="M1711.14 3790.95c672.88 0 1219.35 524.07 1228.69 1173.98-86.36 602.98-604.88 1066.49-1231.74 1066.49-613.31 0-1122.95-443.68-1225.58-1027.56-.13-7.34-.28-14.67-.28-22.04 0-657.71 550.2-1190.87 1228.91-1190.87z"
      />
      <g fill="#FEFEFE" stroke="#2B2A29" strokeWidth={9.84}>
        <path d="M693.72 5142.04h2028.74c1.45 0 2.63-1.18 2.63-2.63v-71.86a2.64 2.64 0 0 0-2.63-2.63H693.72a2.64 2.64 0 0 0-2.63 2.63v71.86c0 1.45 1.18 2.63 2.63 2.63zM693.72 4786.96h2028.74a2.64 2.64 0 0 0 2.63-2.63v-71.85a2.64 2.64 0 0 0-2.63-2.63H693.72a2.64 2.64 0 0 0-2.63 2.63v71.85c0 1.45 1.18 2.63 2.63 2.63zM1022.01 5537.33h1372.17c1.44 0 2.63-1.19 2.63-2.63v-71.85a2.64 2.64 0 0 0-2.63-2.63H1022.01c-1.44 0-2.63 1.19-2.63 2.63v71.85a2.64 2.64 0 0 0 2.63 2.63zM1022.01 4348.12h1372.17a2.64 2.64 0 0 0 2.63-2.63v-71.86a2.64 2.64 0 0 0-2.63-2.63H1022.01c-1.44 0-2.63 1.19-2.63 2.63v71.86a2.64 2.64 0 0 0 2.63 2.63z" />
      </g>
      <path
        fill="currentColor"
        stroke="#FEFEFE"
        strokeWidth={59.06}
        d="M3709.33 5671.05H3120.4c-8.39 0-15.25 6.86-15.25 15.25v416.13c0 8.39 6.86 15.25 15.25 15.25h588.93v-446.63z"
      />
      <g fill="currentColor" stroke="#2B2A29" strokeWidth={7.87}>
        <path d="M4456.37 5371.3h618.84v558.95h-618.84zM5297.29 5371.3h2787.26v558.95H5297.29zM4456.37 8744.96h618.84v558.95h-618.84zM5297.29 8744.96h2787.26v558.95H5297.29zM4456.37 7607.1h618.84v558.95h-618.84zM5297.29 7607.1h2787.26v558.95H5297.29z" />
        <path d="M4456.37 7607.1h618.84v558.95h-618.84zM5297.29 7607.1h2787.26v558.95H5297.29zM4456.37 6469.24h618.84v558.95h-618.84zM5297.29 6469.24h2787.26v558.95H5297.29z" />
        <path d="M4509.22 4684.81H8031.7c328.24 0 596.8 268.56 596.8 596.8V9393.6c0 328.24-268.56 596.8-596.8 596.8H4509.22c-328.24 0-596.8-268.56-596.8-596.8V5281.61c0-328.24 268.56-596.8 596.8-596.8zm91.31 137.53h3339.86c311.22 0 565.86 254.64 565.86 565.87v3898.81c0 311.22-254.64 565.87-565.86 565.87H4600.53c-311.23 0-565.87-254.64-565.87-565.87V5388.21c0-311.22 254.64-565.87 565.87-565.87z" />
      </g>
    </g>
  </svg>
)

export default RadioCategoryIcon
