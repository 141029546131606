import {PABX_ACTION_TYPES} from "./pabx.types";
import {STATUS_TYPE} from "../../utils/constant/constants";
import {addIntoArray} from "./pabx.utils";

const INITIAL_STATE = {
    status: null,  // {}
    pabxs: null, // {radio:{content:[]}}
    detailPABX: null, // {}
    categories: null,  // []
    brands: null,  // []
    types: null, // []
    sites: null, // {content:[]}
    typesByBrand: null,
    statusArray: [
        {
            key: "SERVICEABLE",
            name: "SERVICEABLE",
        },
        {
            key: "SPARE",
            name: "SPARE",
        },
        {
            key: "UNSERVICEABLE",
            name: "UNSERVICEABLE",
        },
    ],
    
    column: [
        { heading: "Name", value: "name" },
        { heading: "Brand", value: "refAssetType.brandName" },
        { heading: "Type", value: "refAssetType.name" },
        { heading: "Serial Number", value: "serialNo" },
        { heading: "Owner", value: "site.siteName" },
        { heading: "Status", value: "status" },
    ],

    uploadPABXStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedImages: [],
    uploadImageStatus: STATUS_TYPE.NO_STATEMENT,
    uploadedFiles: [],
    uploadFileStatus: STATUS_TYPE.NO_STATEMENT,
    isLoading: false,
    removeStatus: STATUS_TYPE.NO_STATEMENT,
    updateStatus: STATUS_TYPE.NO_STATEMENT,
}

export const pabxReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case PABX_ACTION_TYPES.GET_ALL_PABX:
            return {...state, pabxs: payload};
        case PABX_ACTION_TYPES.SEARCH:
            return {...state, pabxs: payload};
        case PABX_ACTION_TYPES.SEARCH_REPORT:
            return {...state, pabxsReport: payload};
        case PABX_ACTION_TYPES.DETAIL_PABX:
            return {...state, detailPABX: payload};
        case PABX_ACTION_TYPES.CLEAR_DETAIL_PABX:
            return {...state, detailPABX: null};
        case PABX_ACTION_TYPES.GET_CATEGORY:
            return {...state, categories: payload};
        case PABX_ACTION_TYPES.GET_TYPE:
            return {...state, types: payload};
        case PABX_ACTION_TYPES.GET_BRAND:
            return {...state, brands: payload};
        case PABX_ACTION_TYPES.GET_SITE:
            return {...state, sites: payload};
        case PABX_ACTION_TYPES.GET_PABX_TYPES_BY_BRAND_ID:
            return {...state, typesByBrand: payload}
        case PABX_ACTION_TYPES.CLEAR_PABX_TYPES_BY_BRAND_ID:
            return {...state, typesByBrand: INITIAL_STATE.typesByBrand}
        case PABX_ACTION_TYPES.CLEAR_DATA:
            return INITIAL_STATE;
        case PABX_ACTION_TYPES.UPLOAD_PABX_STATUS:
            return {...state, uploadPABXStatus: payload, uploadedFiles: [], uploadedImages: []}
        case PABX_ACTION_TYPES.UPLOAD_IMAGE:
            return {...state, uploadedImages: payload}
        case PABX_ACTION_TYPES.UPLOAD_IMAGE_STATUS:
            return {...state, uploadImageStatus: payload}
        case PABX_ACTION_TYPES.UPLOAD_FILE:
            const newArray = addIntoArray(state.uploadedFiles, payload);
            return {...state, uploadedFiles: newArray}
        case PABX_ACTION_TYPES.UPLOAD_FILE_STATUS:
            return {...state, uploadFileStatus: payload}
        case PABX_ACTION_TYPES.REMOVED:
            return {...state, removeStatus: payload}
        case PABX_ACTION_TYPES.UPDATED:
            return {...state, updateStatus: payload}
        default:
            return state;
    }
}