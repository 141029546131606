import {createSelector} from "reselect";
import {getPercentage} from "../../utils/calculate/convert";

const selectElectronicReducer = (state) => state.electronicReducer;

export const selectAllElectronic = createSelector(
    [selectElectronicReducer],
    (state) => state.electronics
)
export const selectTotalPages = createSelector(
    [selectAllElectronic],
    (electronics) => {
        if (electronics) {
            return electronics.totalPages
        } else {
            return null
        }
    }
)

export const selectDetailElectronic = createSelector(
    [selectElectronicReducer],
    (state) => state.detailElectronic
)

export const selectSites = createSelector(
    [selectElectronicReducer],
    (state) => state.sites
)
export const selectDataChart = createSelector(
    [selectAllElectronic],
    (state) => {
        if (state && state.summary) {
            const status = state.summary;
            return [{
                datasets: [
                    {
                        label: "Tower Civil",
                        data: [
                            getPercentage(status.spare, status.total),
                            getPercentage(status.service, status.total),
                            getPercentage(status.unservice, status.total)
                        ],
                        backgroundColor: ["#48B527", "#3384FF", "#FF3A30"],
                        borderColor: ["#040f0f"],
                        borderWidth: 10,
                    },
                ],
            }]
        } else {
            return null;
        }
    }
)

export const selectStatusElectronic = createSelector(
    [selectAllElectronic],
    (state) => {
        if (state && state.totalElements) {
            const status = state.totalElements;
            return [
                {
                    id: 1,
                    name: "Total Electronic Workshop",
                    item: status ?? 0,
                    icon: "electronic",
                    class: "",
                }
            ]
        } else {
            return null;
        }
    }
)



export const selectColumn = createSelector(
    [selectElectronicReducer],
    (state) => state.column
)
export const selectUploadedImages = createSelector(
    [selectElectronicReducer],
    (state) => state.uploadedImages
)
export const selectUploadedFiles = createSelector(
    [selectElectronicReducer],
    (state) => state.uploadedFiles
)
export const selectUploadedElectronicStatus = createSelector(
    [selectElectronicReducer],
    (state) => state.uploadElectronicStatus
)

export const selectRemoveElectronicStatus = createSelector(
    [selectElectronicReducer],
    (state) => state.removeStatus
)

export const selectUpdateElectronicStatus = createSelector(
    [selectElectronicReducer],
    (state) => state.updateStatus
)