import * as React from "react";

const LinkBudgetIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={23}
    height={23}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 11779 11779"
    {...props}
  >
    <g fill="currentColor" stroke="currentColor">
      <path
        strokeWidth={30.574}
        d="m6187 3321 2235 599c144-201 380-332 646-332 438 0 793 355 793 793s-355 793-793 793c-413 0-751-314-790-716l-2235-599c-144 201-379 332-645 332-118 0-229-25-329-71L3433 5756c46 100 71 211 71 329 0 438-355 793-793 793s-793-355-793-793c0-439 355-794 793-794 117 0 229 26 329 71l1636-1635c-46-101-71-212-71-329 0-438 355-793 793-793 412 0 751 314 789 716zm2881 723c186 0 337 151 337 337 0 187-151 338-337 338-187 0-338-151-338-338 0-186 151-337 338-337zm-3670-984c186 0 337 152 337 338s-151 337-337 337c-187 0-338-151-338-337s151-338 338-338zM2711 5747c186 0 338 151 338 338 0 186-152 337-338 337s-337-151-337-337c0-187 151-338 337-338z"
      />
      <g strokeWidth={8.153}>
        <path d="M5736 4622v5287h-676V4635l9-8c100 45 211 71 329 71 121 0 235-27 338-76zM9411 5603v4306h-675V5608c101 47 213 73 332 73 123 0 239-28 343-78zM3049 7309v2600h-676V7309c103 48 217 75 338 75s235-27 338-75z" />
      </g>
      <path
        strokeWidth={8.153}
        d="M1279 4h9221c701 0 1275 574 1275 1275v9221c0 701-574 1275-1275 1275H1279c-701 0-1275-574-1275-1275V1279C4 578 578 4 1279 4zm316 403h8589c653 0 1188 534 1188 1188v8589c0 653-535 1188-1188 1188H1595c-654 0-1188-535-1188-1188V1595c0-654 534-1188 1188-1188z"
      />
    </g>
  </svg>
);

export default LinkBudgetIcon;
