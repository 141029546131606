import {ACTION_TYPE_DATA_NS, ADMIN_ACTION_TYPES, EMPTY_STRING} from "./admin.types";

const INITIAL_STATE = {
    responseData: null,
    removeArchiveStatus: ACTION_TYPE_DATA_NS,
    // CATEGORY
    categories: null,
    aCategory: null,
    createCategory: EMPTY_STRING,
    updateCategory: EMPTY_STRING,
    removeCategory: EMPTY_STRING,
    // BRAND
    brands: null,
    aBrand: null,
    createBrand: EMPTY_STRING,
    updateBrand: EMPTY_STRING,
    removeBrand: EMPTY_STRING,
    // TYPE
    types: null,
    typesByBrand: null,
    usageName: null,
    aType: null,
    createType: EMPTY_STRING,
    updateType: EMPTY_STRING,
    removeType: EMPTY_STRING,
    deleteMannetByNetworkStatus: EMPTY_STRING,
    resetFailed: EMPTY_STRING,
    // LOGGING
    logResponse: null,

}

export const adminReducer = (state = INITIAL_STATE, actions) => {
    const {type, payload} = actions;

    switch (type) {
        case ADMIN_ACTION_TYPES.GET_TYPE_ARCHIVE:
            return {...state, responseData: payload}
        case ADMIN_ACTION_TYPES.REMOVE_ARCHIVE:
            return {...state, removeArchiveStatus: payload}
        // ================= CATEGORY =================
        case ADMIN_ACTION_TYPES.CATEGORIES_DATA:
            return {...state, categories: payload}
        case ADMIN_ACTION_TYPES.GET_A_CATEGORY:
            return {...state, aCategory: payload}
        case ADMIN_ACTION_TYPES.FAILED_TO_GET_A_CATEGORY:
            return {...state, resetFailed: payload}
        case ADMIN_ACTION_TYPES.CLEAR_ACTION_STATUS_CATEGORY:
            return {
                ...state,
                createCategory: EMPTY_STRING,
                updateCategory: EMPTY_STRING,
                removeCategory: EMPTY_STRING,
                usageName: INITIAL_STATE.usageName
            }
        case ADMIN_ACTION_TYPES.ACTION_CREATE_STATUS_CATEGORY:
            return {...state, createCategory: payload}
        case ADMIN_ACTION_TYPES.ACTION_UPDATE_STATUS_CATEGORY:
            return {...state, updateCategory: payload}
        case ADMIN_ACTION_TYPES.ACTION_REMOVE_STATUS_CATEGORY:
            return {...state, removeCategory: payload}
        // ================= BRAND =================
        case ADMIN_ACTION_TYPES.BRANDS_DATA:
            return {...state, brands: payload}
        case ADMIN_ACTION_TYPES.GET_A_BRAND:
            return {...state, aBrand: payload}
        case ADMIN_ACTION_TYPES.CLEAR_ACTION_STATUS_BRAND:
            return {
                ...state,
                createBrand: EMPTY_STRING,
                updateBrand: EMPTY_STRING,
                removeBrand: EMPTY_STRING,
                usageName: INITIAL_STATE.usageName
            }
        case ADMIN_ACTION_TYPES.ACTION_CREATE_STATUS_BRAND:
            return {...state, createBrand: payload}
        case ADMIN_ACTION_TYPES.ACTION_UPDATE_STATUS_BRAND:
            return {...state, updateBrand: payload}
        case ADMIN_ACTION_TYPES.ACTION_REMOVE_STATUS_BRAND:
            return {...state, removeBrand: payload}
        // ================= TYPE =================
        case ADMIN_ACTION_TYPES.TYPES_BY_BRAND_ID:
            return {...state, typesByBrand: payload}
        case ADMIN_ACTION_TYPES.CLEAR_TYPES_BY_BRAND_ID:
            return {...state, typesByBrand: INITIAL_STATE.typesByBrand}
        case ADMIN_ACTION_TYPES.TYPES_DATA:
            return {...state, types: payload}
        case ADMIN_ACTION_TYPES.GET_A_TYPE:
            return {...state, aType: payload}
        case ADMIN_ACTION_TYPES.CLEAR_ACTION_STATUS_TYPE:
            return {
                ...state,
                createType: EMPTY_STRING,
                updateType: EMPTY_STRING,
                removeType: EMPTY_STRING,
                usageName: INITIAL_STATE.usageName
            }
        case ADMIN_ACTION_TYPES.ACTION_CREATE_STATUS_TYPE:
            return {...state, createType: payload}
        case ADMIN_ACTION_TYPES.ACTION_UPDATE_STATUS_TYPE:
            return {...state, updateType: payload}
        case ADMIN_ACTION_TYPES.ACTION_REMOVE_STATUS_TYPE:
            return {...state, removeType: payload}
        case ADMIN_ACTION_TYPES.USAGE_NAME:
            return {...state, usageName: payload}
        // ================= MANNET =================
        case ADMIN_ACTION_TYPES.DELETE_MANNET_BY_NETWORK_STATUS:
            return {...state, deleteMannetByNetworkStatus: payload}
        // ================= LOGGING =================
        case ADMIN_ACTION_TYPES.FETCH_LOGS:
            return {...state, logResponse: payload}
        default:
            return state;
    }
};