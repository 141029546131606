import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import {useDispatch, useSelector} from "react-redux";
import HeaderPage from "../../../components/header/HeaderPage";
import Table from "../../../components/table/Table";
import {selectLogResponse, selectTotalPages} from "../../../store/admin/admin.selector";
import {handleGetLogging, handleSearchBrand} from "../../../store/admin/admin.action";
import {
    EMPTY_STRING,
    FILTER_BY_ACTION,
    FILTER_BY_NAME,
    FILTER_BY_USAGE,
    LOGGING_TYPE,
    NUMBER_ZERO
} from "../../../store/admin/admin.types";
import SearchBox from "../../../components/search-box/search-box.component";
import { handleLoading } from "../../../store/global/global.action";

const LogActivity = () => {

    const [filterSelect, setFilterSelect] = useState(FILTER_BY_USAGE);
    const [searchText, setSearchText] = useState(EMPTY_STRING);
    const [searchTextOnChange, setSearchTextOnChange] = useState(EMPTY_STRING);
    const [page, setPage] = useState(NUMBER_ZERO);

    const dispatch = useDispatch();
    const logResponse = useSelector(selectLogResponse);
    const totalPages = useSelector(selectTotalPages);

    useEffect(() => {
        dispatch(handleGetLogging(filterSelect, searchText, page));

        return () => {
            setSearchText(EMPTY_STRING);
            setFilterSelect(FILTER_BY_USAGE);
            setSearchTextOnChange(EMPTY_STRING)
            setPage(NUMBER_ZERO);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();

        setSearchText(searchTextOnChange);

        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value;

        dispatch(handleGetLogging(filterSelect, searchText, NUMBER_ZERO));

        setPage(NUMBER_ZERO);
    };

    const handleSelectFilter = (e) => {
        dispatch(handleSearchBrand(e.target.value, searchText, page));
        setFilterSelect(e.target.value);
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    };

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        dispatch(handleGetLogging(filterSelect, searchText, e.selected));
        setPage(e.selected);
    };

    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="Log Activity" />

            <div className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">

                    <form className="flex mt-14 mb-3" onSubmit={handleSearch}>
                        <select
                            name="filter"
                            className="select select-bordered w-[200px] rounded-3xl h-[30px] mr-2"
                            onChange={handleSelectFilter}
                        >
                            <option value={FILTER_BY_USAGE} selected>USAGE</option>
                            <option value={FILTER_BY_NAME}>NAME</option>
                            <option value={FILTER_BY_ACTION}>ACTION</option>
                        </select>
                        <div className="flex justify-start mb-6">
                            <SearchBox
                                name="searchText"
                                value={searchTextOnChange}
                                placeholder="Search Log"
                                className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                                onChangeHandler={onSearchChange}
                            />
                            <span className="flex justify-center items-center text-white text-lg px-1 mr-auto">
                                <button
                                    type="submit"
                                    className="btn btn-primary rounded-full text-white text-xs px-7"
                                >
                                    Search
                                </button>
                            </span>
                        </div>
                    </form>

                    {logResponse && logResponse.content.length > 0 ? (
                        <Table
                            data={logResponse.content}
                            column={LOGGING_TYPE}
                            action={false}
                        />
                    ) : (
                        <p className="flex flex-col text-white text-center pt-10">
                            Data is empty
                        </p>
                    )}

                    <div className="text-white flex flex-col">
                        <div className="flex justify-center  my-6 self-end h-14 items-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={page}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogActivity;
