import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import Drawer from "./Panel";
import Titlebar from "../navbar/Titlebar";
import LabelInput from "../label/LabelInput";
import LabelSelect from "../label/LabelSelect";
import LabelRange from "../label/LabelRange";
import LabelValue from "../label/LabelValue";

import {
    handleCreateOP,
    handleMultiplyAmount,
    handlePostDataArea,
    handleSetKineticFromRightPanel,
    handleShowCoverageLinkBudget,
    handleShowHideChartLinkBudget,
    handleStoreAltitudeToSum,
    handleStoreRequestBody,
} from "../../store/area/area.action";
import {
    selectAnyDataCharLinkBudget,
    selectDataEntityFromOtherPage,
    selectKmlPath,
    selectPathResponse,
    selectShowChartLinkBudget,
    selectTerrainHeight,
    selectTransmitterData,
} from "../../store/area/area.selector";
import {selectNotifyRightPanel} from "../../store/mannet/mannet.selector";
import {NOTIFY_ACTION_TYPES} from "../../store/mannet/mannet.types";
import {handleClearNotify} from "../../store/mannet/mannet.action";
import {
    handleTickAllCoverage,
    handleTickAllLabel,
    handleTickShowCoverageById,
} from "../../store/dashboard/dashboard.action";
import {handleValueValidation} from "../../store/area/area.util";
import {handleClearDetailRadio} from "../../store/radio/radio.action";
import {handleClearDetailAntenna} from "../../store/antenna/antenna.action";

import {
    ANALYZE_TYPE,
    CloaxialType,
    ColorScheme,
    Connectors,
    Context,
    COORDINATE_FORMAT,
    COORDINATE_TYPE,
    Diffraction,
    DIRECTION,
    Landcover,
    MultiplyAltitude,
    patterns,
    PM,
    Profile,
    Resolution,
    UNIT,
} from "../../utils/constant/constants";
import {feetToMeter, km2ToNauticalMiles2, meterToFeet, nauticalMilesToKm,} from "../../utils/calculate/convert";
import {
    calculateEfficiency,
    calculateEIRP,
    calculateERP,
    calucateFeederLoss,
    dBmToWatts,
} from "../../utils/calculate/calculateLoss";
import {
    ddmToDd,
    ddmToDms,
    ddToDdmWithDirection,
    ddToDmsWithDirection,
    dmsToDd,
    dmsToDmm,
} from "../../utils/cesium/coordinate";

import ReportMesh from "../report/analysis/ReportMesh";
import ReportRadioLinkBudget from "../report/analysis/ReportRadioLinkBudget";
import ReportAnalysis from "../report/analysis/ReportAnalysis";
import {handleFetchGpsData} from "../../store/gps/gps.action";
import {selectPosition} from "../../store/gps/gps.selector";

const RightPannel = ({
                         dashBoard,
                         analyzeType,
                         handleLoadRadioOrAntenna,
                         selectedData,
                         setSelectedData,
                         setSelectedId,
                         detailRadio,
                         setTxPosition,
                         handleMannet,
                         detailAntenna,
                         isOpen,
                         setIsOpen,
                         chartImage,
                         checkShowAllLabel,
                         isShowKey,
                         setIsShowKeys,
                         resultList,
                         coverageRuler,
                         checkShowAllCoverage,
                         originalPayloadArray,
                         meshMean,
                         unCheckShowAllCoverage,
                         setUnCheckShowAllCoverage,
                     }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const txData = useSelector(selectTransmitterData);
    const dataEntityFromDashboard = useSelector(selectDataEntityFromOtherPage);
    const kmlDataSource = useSelector(selectKmlPath);
    const notifyRightPannel = useSelector(selectNotifyRightPanel);
    const showChartLinkBudget = useSelector(selectShowChartLinkBudget);
    const terrainHeight = useSelector(selectTerrainHeight);
    const pathRes = useSelector(selectPathResponse);
    const anyDataCharLinkBudget = useSelector(selectAnyDataCharLinkBudget);
    const gpsPosition = useSelector(selectPosition);

    const [pattern, setPattern] = useState({});
    const [effColor, setEffColor] = useState("");
    const [effLabel, setEffLabel] = useState("");
    const [altRXUnit, setAltRXUnit] = useState(UNIT.FEET);
    const [altTXUnit, setAltTXUnit] = useState(UNIT.FEET);
    const [radUnit, setRadUnit] = useState(UNIT.NM);
    const [coordinateFormat, setCoordinateFormat] = useState(
        COORDINATE_FORMAT[0]
    );
    const [lastCoordinateFormat, setLastCoordinateFormat] = useState(
        COORDINATE_FORMAT[0]
    );

    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue,
        reset,
        watch,
        getValues,
        trigger,
    } = useForm({
        defaultValues: {
            name: "",
            network: "",
            coverageType: ((type) => {
                let label = "";
                if (ANALYZE_TYPE.GTA === type) {
                    label = "GTA";
                } else if (ANALYZE_TYPE.GTG === type) {
                    label = "GTG";
                } else if (ANALYZE_TYPE.JAMMER === type) {
                    label = "JAMMER";
                }
                return label;
            })(),
            transmitter: {
                lat: 0,
                lon: 0,
                frq: 868,
                alt: 0,
                txw: 10,
                bwi: 0.1,
            },
            antenna: {
                txg: 2.15,
                txl: 0,
                ant: 39,
                azi: 0,
                tlt: 0,
                hbw: 120,
                vbw: 120,
                pol: "h",
            },
            receiver: {
                lat: 0,
                lon: 0,
                alt: 0,
                rxg: 2.15,
                rxs: -100,
            },
            output: {
                units: "metric",
                col: ColorScheme[0].label,
                out: 2,
                ber: null,
                mod: null,
                nf: -120,
                res: 300,
                rad: 0,
            },
            model: {
                pm: PM[1].value,
                pe: 2,
                cli: 0,
                ked: 0,
                rel: 85,
            },
            environment: {
                clt: Profile[0].value,
                cll: 1,
                clm: 0,
            },
            additionalDTO: {
                patternDto: {
                    id: patterns[0].id,
                    name: patterns[0].label,
                    img1: patterns[0].image_left,
                    img2: patterns[0].image_right,
                },
                coaxialTypeDto: {
                    id: CloaxialType[0].id,
                    name: CloaxialType[0].value,
                },
                connector: Connectors[0].label,
                coaxialLength: 0,
            },
        },
    });

    useEffect(() => {
        copyDataFromDashboard();
        calculateLoss();
        if (ANALYZE_TYPE.LINK_BUDGET === analyzeType && dataEntityFromDashboard) {
            dataEntityFromDashboard.coverageStatus = false;

            let copyFromDashboard = JSON.parse(
                JSON.stringify(dataEntityFromDashboard.data)
            );
            copyFromDashboard.receiver.alt = copyFromDashboard.additionalDTO.oriAlt;
            copyFromDashboard.output.rad = copyFromDashboard.additionalDTO.oriRad;
            copyFromDashboard.model.rel = copyFromDashboard.additionalDTO.oriRel;
            setPattern({
                left: copyFromDashboard.additionalDTO.patternDto.img1,
                right: copyFromDashboard.additionalDTO.patternDto.img2,
            });
            Object.entries(copyFromDashboard).forEach(([name, value]) => {
                setValue(name, value);
                if (name === "additionalDTO") {
                    const {id} = value.coaxialTypeDto;
                    setValue(
                        "additionalDTO.coaxialTypeDto.name",
                        CloaxialType[id - 1].value
                    );
                }
            });
        }

        if (!watch("output.rad")) {
            handleRadValue();
        }

        return () => {
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (ANALYZE_TYPE.LINK_BUDGET === analyzeType && dataEntityFromDashboard) {
            let copyFromDashboard = JSON.parse(
                JSON.stringify(dataEntityFromDashboard.data)
            );
            copyFromDashboard.receiver.alt = copyFromDashboard.additionalDTO.oriAlt;
            copyFromDashboard.output.rad = copyFromDashboard.additionalDTO.oriRad;
            copyFromDashboard.model.rel = copyFromDashboard.additionalDTO.oriRel;
            setPattern({
                left: copyFromDashboard.additionalDTO.patternDto.img1,
                right: copyFromDashboard.additionalDTO.patternDto.img2,
            });
        }

        return () => {
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataEntityFromDashboard]);


    /**
     * Get Laitude Longitude when click TX on map
     */
    useEffect(() => {
        if (txData) {
            if (analyzeType && ANALYZE_TYPE.LINK_BUDGET !== analyzeType) {
                const lat = txData.transmitter.lat;
                const lon = txData.transmitter.lon;
                setLatLonPosition(lat, lon);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [txData]);

    /**
     * Fill frequency, tx power and bandwitdh when load data radio
     */
    useEffect(() => {
        if (!detailRadio) {
            return;
        }

        setValue("transmitter.frq", detailRadio.freq);
        setValue("transmitter.txw", detailRadio.power);
        setValue(
            "transmitter.bwi",
            detailRadio.bandwith ? detailRadio.bandwith : 0.1
        );

        return () => {
            dispatch(handleClearDetailRadio());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailRadio]);

    /**
     * Fill Power when load data Antenna
     */
    useEffect(() => {
        if (!detailAntenna) {
            return;
        }

        setValue("antenna.txg", detailAntenna.antennaPower);
        setValue("additionalDTO.coaxialTypeDto.name", detailAntenna?.coaxialType?.name);

        return () => {
            dispatch(handleClearDetailAntenna());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailAntenna]);

    useEffect(() => {
        if (NOTIFY_ACTION_TYPES.CREATE_POINT === notifyRightPannel &&
            handleMannet) {
            handleMannet(modifyPoint(), trigger, errors);
            dispatch(handleClearNotify()); // set notifyRightPannel to NS
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifyRightPannel]);

    useEffect(() => {
        if (unCheckShowAllCoverage && resultList) {
            dispatch(handleTickAllCoverage(resultList, false));
            setUnCheckShowAllCoverage(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unCheckShowAllCoverage])

    useEffect(() => {
        if (gpsPosition) {
            setLatLonPosition(gpsPosition.latitude, gpsPosition.longitude);
            onSetTxPosition();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gpsPosition])

    const setLatLonPosition = (lat, lon) => {
        let newLat = lat;
        let newLon = lon;

        if (coordinateFormat.value === COORDINATE_TYPE.DMS) {
            const {latitude, longitude} = ddToDmsWithDirection(lat, lon);
            newLat = latitude;
            newLon = longitude;
        } else if (coordinateFormat.value === COORDINATE_TYPE.DDM) {
            const {latitude, longitude} = ddToDdmWithDirection(lat, lon);
            newLat = latitude;
            newLon = longitude;
        }

        setValue("transmitter.lon", newLon);
        setValue("transmitter.lat", newLat);
    };

    const copyDataFromDashboard = () => {
        if (dataEntityFromDashboard && dataEntityFromDashboard.data) {
            let copyFromDashboard = JSON.parse(
                JSON.stringify(dataEntityFromDashboard.data)
            );
            Object.entries(copyFromDashboard).forEach(([name, value]) => {
                setValue(name, value);
            });

            copyFromDashboard.transmitter = JSON.parse(
                JSON.stringify(dataEntityFromDashboard.data.transmitter)
            );
            Object.entries(copyFromDashboard.transmitter).forEach(([name, value]) => {
                setValue(name, value);

            });

            if (ANALYZE_TYPE.JAMMER === analyzeType) {
                setValue("transmitter.alt", meterToFeet(getValues("transmitter.alt")))
            }

            copyFromDashboard.receiver = JSON.parse(
                JSON.stringify(dataEntityFromDashboard.data.receiver)
            );
            Object.entries(copyFromDashboard.receiver).forEach(([name, value]) => {
                setValue(name, value);
            });
            setValue("receiver.alt", dataEntityFromDashboard.data.additionalDTO.oriAlt);

            copyFromDashboard.antenna = JSON.parse(
                JSON.stringify(dataEntityFromDashboard.data.antenna)
            );
            Object.entries(copyFromDashboard.antenna).forEach(([name, value]) => {
                setValue(name, value);
            });

            copyFromDashboard.output = JSON.parse(
                JSON.stringify(dataEntityFromDashboard.data.output)
            );
            Object.entries(copyFromDashboard.output).forEach(([name, value]) => {
                setValue(name, value);
            });
            setValue("output.rad", dataEntityFromDashboard.data.additionalDTO.oriRad);

            copyFromDashboard.environment = JSON.parse(
                JSON.stringify(dataEntityFromDashboard.data.environment)
            );
            Object.entries(copyFromDashboard.environment).forEach(([name, value]) => {
                setValue(name, value);
            });
            setValue("environment.clt", Profile[copyFromDashboard.environment.mat].value);

            copyFromDashboard.model = JSON.parse(
                JSON.stringify(dataEntityFromDashboard.data.model)
            );
            Object.entries(copyFromDashboard.model).forEach(([name, value]) => {
                setValue(name, value);
            });

            if(dataEntityFromDashboard.data.additionalDTO.oriRel) {
                setValue("model.rel", dataEntityFromDashboard.data.additionalDTO.oriRel);
            }

            if (isNaN(copyFromDashboard.model.pm)) {
                setValue("model.pm", PM[PM.find((d) => d.label === copyFromDashboard.model.pm).value - 1].value)
            }
            copyFromDashboard.additionalDTO = JSON.parse(
                JSON.stringify(dataEntityFromDashboard.data.additionalDTO)
            );

            setPattern({
                left: copyFromDashboard.additionalDTO.patternDto.img1,
                right: copyFromDashboard.additionalDTO.patternDto.img2,
            });

            if (setTxPosition) {
                setTxPosition(
                    copyFromDashboard.transmitter.lat,
                    copyFromDashboard.transmitter.lon,
                    copyFromDashboard.transmitter.alt
                );
            }
        }
    };

    const calculateLoss = () => {
        let coaxialTmp = CloaxialType[0];
        let coaxialTypeId = getValues("additionalDTO.coaxialTypeDto.id");
        for (let i = 0; i < CloaxialType.length; i++) {
            if (coaxialTypeId === CloaxialType[i].id) {
                coaxialTmp = CloaxialType[i];
                break;
            }
        }
        const frequency = Number(watch("transmitter.frq"));
        const powerInWatt = Number(watch("transmitter.txw"));
        const cloaxialType = coaxialTmp;
        const cloaxialLength = Number(watch("additionalDTO.coaxialLength"));
        const connector = Number(watch("additionalDTO.connector"));
        const antennaGain = Number(watch("antenna.txg"));

        // if (frequency && powerInWatt && cloaxialType && cloaxialLength && connector && antennaGain) {
        // }
        const loss = calucateFeederLoss(
            frequency,
            cloaxialLength,
            connector,
            cloaxialType.k1,
            cloaxialType.k2
        );
        const eirp = calculateEIRP(loss, powerInWatt, antennaGain, true);
        const erp = calculateERP(eirp);
        const efficiency = calculateEfficiency(dBmToWatts(erp), powerInWatt);

        if (Number(efficiency) > 75) {
            setEffLabel("Good");
            setEffColor("green-800");
        } else if (Number(efficiency) < 75 && Number(efficiency) > 50) {
            setEffLabel("Average");
            setEffColor("orange-500");
        } else if (Number(efficiency) < 50) {
            setEffLabel("Poor");
            setEffColor("red-500");
        }

        if (!isNaN(loss)) {
            setValue("antenna.txl", Number(loss));
        }

        if (isFinite(erp) && isFinite(erp)) {
            setValue(
                "additionalDTO.erp",
                `${dBmToWatts(erp)} w / ${erp.toFixed(2)} dBm`
            );
            setValue(
                "additionalDTO.eirp",
                `${dBmToWatts(eirp)} w / ${eirp.toFixed(2)} dBm`
            );
        }

        if (!isNaN(efficiency)) {
            setValue("additionalDTO.effieciency", Number(efficiency));
        }
    };

    const handleRadValue = () => {
        if (ANALYZE_TYPE.GTG === analyzeType) {
            setValue("output.rad", 50);
        } else {
            setValue("output.rad", 215.98);
        }
    }

    const onSetTxPosition = (unit = altTXUnit) => {
        const lat = watch("transmitter.lat");
        const lon = watch("transmitter.lon");
        let newLat = lat;
        let newLon = lon;

        if (coordinateFormat.value === COORDINATE_TYPE.DMS) {
            const {latitude, longitude} = dmsToDd(lat, lon);
            newLat = latitude;
            newLon = longitude;
        } else if (coordinateFormat.value === COORDINATE_TYPE.DDM) {
            const {latitude, longitude} = ddmToDd(lat, lon);
            newLat = latitude;
            newLon = longitude;
        }
        const splitLat = `${newLat}`.split("");
        const splitLon = `${newLon}`.split("");

        if (splitLat[(splitLat.length - 1)] !== "." &&
            splitLon[(splitLon.length - 1)] !== "."
            &&
            (
                ((splitLat[0] === "-" && ((splitLat[1] === "0" && splitLat.length > 3) ||
                    splitLat[1] !== "0")) || (newLat >= 0 && splitLat[0] !== "-"))
                &&
                ((splitLon[0] === "-" && ((splitLon[1] === "0" && splitLon.length > 3) ||
                    splitLon[1] !== "0")) || (newLon >= 0 && splitLon[0] !== "-"))
            )
        ) {

            let txAlt = 0;
            if (setTxPosition) {
                if (ANALYZE_TYPE.JAMMER !== analyzeType) {
                    unit = UNIT.METER;
                }
                txAlt = unit === UNIT.FEET ? feetToMeter(getValues("transmitter.alt")) : getValues("transmitter.alt");

                if (typeof newLat === "string" || typeof newLon === "string") {
                    setTxPosition(
                        Number(Number(newLat).toFixed(6)),
                        Number(Number(newLon).toFixed(6)),
                        terrainHeight + txAlt
                    );
                } else {
                    setTxPosition(
                        Number(newLat.toFixed(6)),
                        Number(newLon.toFixed(6)),
                        terrainHeight + txAlt
                    );
                }
            }
            return terrainHeight + txAlt

        }
    };

    const modifyPoint = (data) => {
        let copyPoint

        if (data) {
            copyPoint = JSON.parse(JSON.stringify(data));
        } else {
            copyPoint = JSON.parse(JSON.stringify(getValues()));
        }

        copyPoint.additionalDTO.oriAlt = copyPoint.receiver.alt;
        copyPoint.additionalDTO.oriRad = copyPoint.output.rad;

        const lat = copyPoint.transmitter.lat;
        const lon = copyPoint.transmitter.lon;
        if (coordinateFormat.value === COORDINATE_TYPE.DMS) {
            const {latitude, longitude} = dmsToDd(lat, lon);
            copyPoint.transmitter.lat = latitude;
            copyPoint.transmitter.lon = longitude;
        }
        if (coordinateFormat.value === COORDINATE_TYPE.DDM) {
            const {latitude, longitude} = ddmToDd(lat, lon);
            copyPoint.transmitter.lat = latitude;
            copyPoint.transmitter.lon = longitude;
        }

        if (
            ANALYZE_TYPE.GTA === analyzeType ||
            ANALYZE_TYPE.OPERATION_PLANNING === analyzeType
        ) {
            copyPoint.coverageType = "GTA";
            copyPoint.output.rad = nauticalMilesToKm(copyPoint.output.rad);
            copyPoint.receiver.alt = feetToMeter(copyPoint.receiver.alt);
        } else if (ANALYZE_TYPE.GTG === analyzeType) {
            copyPoint.coverageType = "GTG";
        } else if (ANALYZE_TYPE.JAMMER === analyzeType) {
            copyPoint.coverageType = "JAMMER";
            if (UNIT.FEET === altTXUnit) {
                copyPoint.transmitter.alt = feetToMeter(copyPoint.transmitter.alt);
                copyPoint.transmitter.altUnit = UNIT.FEET;
            }
            if (UNIT.METER === altTXUnit) {
                copyPoint.transmitter.altUnit = UNIT.METER;
            }
            if (UNIT.FEET === altRXUnit) {
                copyPoint.receiver.alt = feetToMeter(copyPoint.receiver.alt);
                copyPoint.receiver.altUnit = UNIT.FEET;
            }
            if (UNIT.METER === altRXUnit) {
                copyPoint.receiver.altUnit = UNIT.METER;
            }
            if (UNIT.NM === radUnit) {
                copyPoint.output.rad = nauticalMilesToKm(copyPoint.output.rad);
                copyPoint.output.radUnit = UNIT.NM;
            }
            if (UNIT.KM === radUnit) {
                copyPoint.output.radUnit = UNIT.KM;
            }
        }
        copyPoint.antenna.ant = patterns[getValues("additionalDTO.patternDto.id") - 1].crfId;

        const splitName = copyPoint.name.split('');
        if (splitName[splitName.length - 1] === " ") {
            splitName.pop();
            copyPoint.name = splitName.join('');
        }

        const splitNetwork = copyPoint.network.split('');
        if (splitNetwork[splitNetwork.length - 1] === " ") {
            splitNetwork.pop();
            copyPoint.network = splitNetwork.join('');
        }

        const reliability = copyPoint.model.rel;
        copyPoint.additionalDTO.oriRel = reliability
        copyPoint.model.rel = 50 + (49 * (reliability / 100));

        return copyPoint;
    };

    const handleClearButton = (e) => {
        e.preventDefault();
        reset();
        calculateLoss();
        handleRadValue();
        onSetTxPosition();
        setPattern({
            left: patterns[0].image_left,
            right: patterns[0].image_right,
        });
    };

    /**
     * handle save when its not dashboard
     */
    const onSubmit = (data) => {
        const copyPoint = modifyPoint(data);
        if (
            analyzeType === ANALYZE_TYPE.GTG ||
            analyzeType === ANALYZE_TYPE.GTA ||
            analyzeType === ANALYZE_TYPE.JAMMER
        ) {
            if (handleValueValidation(copyPoint)) {
                dispatch(handlePostDataArea(copyPoint, navigate));
            }
        } else if (analyzeType === ANALYZE_TYPE.OPERATION_PLANNING) {
            if (handleValueValidation(copyPoint)) {
                dispatch(handleStoreRequestBody(copyPoint));
                dispatch(
                    handleStoreAltitudeToSum({
                        alt: copyPoint.receiver.alt,
                        oriAlt: copyPoint.additionalDTO.oriAlt,
                    })
                );
                dispatch(handleCreateOP({name: copyPoint.name}));
            }
        } else if (ANALYZE_TYPE.LINK_BUDGET === analyzeType) {
            if (anyDataCharLinkBudget) {
                dispatch(handleShowHideChartLinkBudget(!showChartLinkBudget));
            }
        }
    };

    const handleCheckBoxShowHideCoverageRadioLinkBudget = (e) => {
        if (kmlDataSource) {
            dispatch(handleShowCoverageLinkBudget(kmlDataSource, e));
        }
    };

    const handleReceiverTitle = () => {
        if (
            ANALYZE_TYPE.GTG === analyzeType ||
            ANALYZE_TYPE.JAMMER === analyzeType ||
            ANALYZE_TYPE.MESH_SIMULATION === analyzeType
        ) {
            return <Titlebar titleName="Receiver / RX" type="ab"/>;
        } else if (
            ANALYZE_TYPE.GTA === analyzeType ||
            ANALYZE_TYPE.OPERATION_PLANNING === analyzeType ||
            ANALYZE_TYPE.LINK_BUDGET === analyzeType
        ) {
            return <Titlebar titleName="Airbone" type="ab"/>;
        }
    };

    const handleCoordinateFormat = (e) => {
        e.preventDefault();
        let i = -1;
        for (let j = 0; j < COORDINATE_FORMAT.length; j++) {
            if (COORDINATE_FORMAT[j].value === e.target.value) {
                i = j;
                break;
            }
        }
        if (i !== -1) {
            setCoordinateFormat(COORDINATE_FORMAT[i]);
            const lat = watch("transmitter.lat");
            const lon = watch("transmitter.lon");
            if (lastCoordinateFormat.value === COORDINATE_TYPE.DD) {
                if (e.target.value === COORDINATE_TYPE.DMS) {
                    const {latitude, longitude} = ddToDmsWithDirection(lat, lon);
                    setValue("transmitter.lat", latitude);
                    setValue("transmitter.lon", longitude);
                } else if (e.target.value === COORDINATE_TYPE.DDM) {
                    const {latitude, longitude} = ddToDdmWithDirection(lat, lon);
                    setValue("transmitter.lat", latitude);
                    setValue("transmitter.lon", longitude);
                }
            } else if (lastCoordinateFormat.value === COORDINATE_TYPE.DMS) {
                if (e.target.value === COORDINATE_TYPE.DD) {
                    const {latitude, longitude} = dmsToDd(lat, lon);
                    setValue("transmitter.lat", latitude);
                    setValue("transmitter.lon", longitude);
                } else if (e.target.value === COORDINATE_TYPE.DDM) {
                    const {latitude, longitude} = dmsToDmm(lat, lon);
                    setValue("transmitter.lat", latitude);
                    setValue("transmitter.lon", longitude);
                }
            } else if (lastCoordinateFormat.value === COORDINATE_TYPE.DDM) {
                if (e.target.value === COORDINATE_TYPE.DD) {
                    const {latitude, longitude} = ddmToDd(lat, lon);
                    setValue("transmitter.lat", latitude);
                    setValue("transmitter.lon", longitude);
                } else if (e.target.value === COORDINATE_TYPE.DMS) {
                    const {latitude, longitude} = ddmToDms(lat, lon);
                    setValue("transmitter.lat", latitude);
                    setValue("transmitter.lon", longitude);
                }
            }
            setLastCoordinateFormat(COORDINATE_FORMAT[i]);
        }
    };

    const radJammer = () => {
        return (
            <div className="flex flex-col gap-y-3 my-2">
                <div className="flex justify-between items-center px-4">
                    <div className="max-w-[90px] text-white text-sm self-start items-center mt-2">
                        Radius
                    </div>
                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                        <div className="flex gap-x-3">
                            <div
                                className="w-[80px] h-[32px] bg-transparent text-white p-1 px-2 text-sm rounded-2xl border-2 border-white self-start">
                                <input
                                    type="number"
                                    id="rad"
                                    name="rad"
                                    step=".01"
                                    {...register("output.rad", {
                                        valueAsNumber: true,
                                        required: "Required!",
                                    })}
                                    className="block w-full shadow-sm sm:text-sm text-white bg-transparent border-none"
                                    placeholder={errors.output?.rad?.message}
                                />
                            </div>
                            <select
                                className="select select-sm w-[80px] max-h-[32px] bg-transparent text-white p-1 px-2 text-sm rounded-2xl border-2 border-white self-start"
                                onChange={(e) => setRadUnit(e.target.value)}
                            >
                                <option
                                    value={UNIT.NM}
                                    className="h-[40px] text-black focus:text-gray"
                                    selected
                                >
                                    {UNIT.NM}
                                </option>
                                <option
                                    value={UNIT.KM}
                                    className="h-[40px] text-black focus:text-gray"
                                >
                                    {UNIT.KM}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const highJammer = () => {
        return (
            <div className="flex flex-col gap-y-3 my-2">
                <div className="flex justify-between items-center px-4">
                    <div className="max-w-[90px] text-white text-sm self-start items-center mt-2">
                        TX Height
                    </div>
                    <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                        <div className="flex gap-x-3">
                            <div
                                className="w-[80px] h-[32px] bg-transparent text-white p-1 px-2 text-sm rounded-2xl border-2 border-white self-start">
                                <input
                                    type="number"
                                    id="alt"
                                    name="alt"
                                    step=".01"
                                    {...register("transmitter.alt", {
                                        valueAsNumber: true,
                                        required: "Required!",
                                        onChange: (e) => {
                                            const altTx = onSetTxPosition();
                                            dispatch(handleSetKineticFromRightPanel({
                                                lat: Number(getValues("transmitter.lat")),
                                                lon: Number(getValues("transmitter.lon")),
                                                alt: altTx,
                                                for:"tx"
                                                // alt: Number(e.target.value)
                                            }));
                                        },
                                    })}
                                    className="block w-full shadow-sm sm:text-sm text-white bg-transparent border-none"
                                    placeholder={errors.transmitter?.alt?.message}
                                />
                            </div>
                            <select
                                className="select select-sm w-[80px] max-h-[32px] bg-transparent text-white p-1 px-2 text-sm rounded-2xl border-2 border-white self-start"
                                onChange={(e) => {
                                    setAltTXUnit(e.target.value);
                                    const altTx = onSetTxPosition(e.target.value);
                                    dispatch(handleSetKineticFromRightPanel({
                                        lat: Number(getValues("transmitter.lat")),
                                        lon: Number(getValues("transmitter.lon")),
                                        alt: altTx,
                                        for:"tx"
                                    }));
                                }}
                            >
                                <option
                                    value={UNIT.FEET}
                                    selected
                                    className="h-[40px] text-black focus:text-gray"
                                >
                                    {UNIT.FEET}
                                </option>
                                <option
                                    value={UNIT.METER}
                                    className="h-[40px] text-black focus:text-gray"
                                >
                                    {UNIT.METER}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const handleAltUnit = () => {
        if (
            ANALYZE_TYPE.OPERATION_PLANNING === analyzeType ||
            ANALYZE_TYPE.JAMMER === analyzeType
        ) {
            // for OP and JAMMER
            return (
                <div className="flex flex-col gap-y-3 my-2">
                    <div className="flex justify-between items-center px-4">
                        <div className="max-w-[90px] text-white text-md self-start items-center mt-2">
                            Altitude
                        </div>
                        <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                            <div className="flex gap-x-3">
                                <div
                                    className="w-[80px] h-[32px] bg-transparent text-white p-1 px-2 text-sm rounded-2xl border-2 border-white self-start">
                                    <input
                                        type="number"
                                        id="alt"
                                        name="alt"
                                        step=".01"
                                        {...register("receiver.alt", {
                                            valueAsNumber: true,
                                            required: "Required!",
                                        })}
                                        className="block w-full shadow-sm sm:text-sm text-white bg-transparent border-none"
                                        placeholder={errors.receiver?.alt?.message}
                                    />
                                </div>
                                {ANALYZE_TYPE.JAMMER === analyzeType ? (
                                    <select
                                        className="select select-sm w-[80px] max-h-[32px] bg-transparent text-white p-1 px-2 text-sm rounded-2xl border-2 border-white self-start"
                                        onChange={(e) => setAltRXUnit(e.target.value)}
                                    >
                                        <option
                                            value={UNIT.FEET}
                                            selected
                                            className="h-[40px] text-black focus:text-gray"
                                        >
                                            {UNIT.FEET}
                                        </option>
                                        <option
                                            value={UNIT.METER}
                                            className="h-[40px] text-black focus:text-gray"
                                        >
                                            {UNIT.METER}
                                        </option>
                                    </select>
                                ) : (
                                    <select
                                        className="select select-sm w-[80px] max-h-[32px] bg-transparent text-white p-1 px-2 text-sm rounded-2xl border-2 border-white self-start"
                                        onChange={(e) => {
                                            dispatch(handleMultiplyAmount(e.target.value));
                                        }}
                                    >
                                        {MultiplyAltitude.map((el) => {
                                            return (
                                                <option
                                                    value={el.value}
                                                    key={el.value}
                                                    className="h-[40px] text-black focus:text-gray"
                                                >
                                                    {el.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                )}
                            </div>
                            {ANALYZE_TYPE.JAMMER !== analyzeType && (
                                <p className="text-sm text-white ml-2">{"feet"}</p>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <LabelInput
                    titleName="Altitude"
                    type="number"
                    id="rxAlt"
                    unit={
                        ANALYZE_TYPE.GTG === analyzeType ||
                        ANALYZE_TYPE.MESH_SIMULATION === analyzeType
                            ? UNIT.METER
                            : UNIT.FEET
                    }
                    step=".01"
                    {...register("receiver.alt", {
                        valueAsNumber: true,
                        required: "Required!",
                        onChange: (e) => {
                            dispatch(handleSetKineticFromRightPanel({
                                lat: Number(getValues("transmitter.lat")),
                                lon: Number(getValues("transmitter.lon")),
                                alt: Number(e.target.value),
                                for:"rx",
                            }));
                        }
                    })}
                    error={errors.receiver?.alt}
                />
            );
        }
    };

    /**
     * If the checkbox is checked, add the value to the list, otherwise filter the list to remove the
     * value.
     */
    const handleCheckBoxShowHideCoverage = (e, id, name) => {
        if (e.target.checked) {
            if (name === "All") {
                dispatch(handleTickAllCoverage(resultList, true));
            } else {
                dispatch(
                    handleTickShowCoverageById(resultList, {
                        id,
                        name,
                        showCoverage: true,
                    })
                );
            }
        } else {
            if (name === "All") {
                dispatch(handleTickAllCoverage(resultList, false));
            } else {
                dispatch(
                    handleTickShowCoverageById(resultList, {
                        id,
                        name,
                        showCoverage: false,
                    })
                );
            }
        }
    };

    const handleCheckBoxShowHideLabel = (e) => {
        if (e.target.checked) {
            dispatch(handleTickAllLabel(resultList, true));
        } else {
            dispatch(handleTickAllLabel(resultList, false));
        }
    };

    const isCoverageChecked = () => {
        return true;
    };

    const filteredResultList = resultList?.content.filter(
        (el) => el.coverageStatus,
        isCoverageChecked
    );

    const renderReport = (report) => {
        switch (report) {
            case ANALYZE_TYPE.LINK_BUDGET:
                return (
                    <ReportRadioLinkBudget
                        chartImage={chartImage}
                        selectedData={dataEntityFromDashboard}
                        pathRes={pathRes ? pathRes : null}
                    />
                );
            case ANALYZE_TYPE.MESH_SIMULATION:
                return (
                    <ReportMesh
                        selectedData={originalPayloadArray}
                        meshMean={meshMean}
                        chartImage={chartImage}
                    />
                );
            default:
                return "";
        }
    };

    const onSelectedData = () => {
        return (
            <div className="flex flex-col overflow-y-scroll">
                <div className="flex flex-row justify-between items-center">
                    <div className="flex items-center">
                        <input
                            // key={selectedData.id}
                            className="checkbox checkbox-md border-2 border-slate-50 checked:bg-blue-500 default:ring-2"
                            type="checkbox"
                            value={selectedData.data.name}
                            style={{borderColor: "white"}}
                            checked={selectedData.coverageStatus}
                            onChange={(e) =>
                                handleCheckBoxShowHideCoverage(
                                    e,
                                    selectedData.id,
                                    selectedData.data.name
                                )
                            }
                        />
                        <label
                            className="form-check-label inline-block text-white mx-2"
                            htmlFor="flexCheckDefault"
                        >
                            Show Coverage
                        </label>
                    </div>
                </div>
                {/* title bar */}
                <Titlebar titleName="Ground Transmitter / TX" type="tx"/>

                {/* value with label */}
                <LabelValue
                    data={[
                        {
                            title: "Name",
                            value: selectedData.data?.name,
                        },
                        {
                            title: "Network",
                            value: selectedData.data?.network,
                        },
                        {
                            title: "Latitude",
                            value: selectedData.data?.transmitter.lat,
                            unit: "D.D",
                        },
                        {
                            title: "Longitude",
                            value: selectedData.data?.transmitter.lon,
                            unit: "D.D",
                        },
                        {
                            title:
                                selectedData.data.coverageType === "JAMMER"
                                    ? "TX Height"
                                    : "Tower Height",
                            value: (() => {
                                const data = selectedData.data;
                                if (data.coverageType === "JAMMER") {
                                    if (data.receiver.alt === data.additionalDTO.oriAlt) {
                                        return selectedData.data?.transmitter.alt;
                                    } else
                                        return meterToFeet(
                                            selectedData.data?.transmitter.alt
                                        );
                                } else {
                                    return selectedData.data?.transmitter.alt;
                                }
                            })(),
                            unit: (() => {
                                const data = selectedData.data;
                                if (data.coverageType === "JAMMER") {
                                    if (data.receiver.alt === data.additionalDTO.oriAlt) {
                                        return UNIT.METER;
                                    } else return UNIT.FEET;
                                } else {
                                    return UNIT.METER;
                                }
                            })(),
                        },
                        {
                            title: "Frequency",
                            value: selectedData.data?.transmitter.frq,
                            unit: "MHz",
                        },
                        {
                            title: "TX Power",
                            value: selectedData.data?.transmitter.txw,
                            unit: "W",
                        },
                        {
                            title: "Bandwidth",
                            value: selectedData.data?.transmitter.bwi,
                            unit: "MHz",
                        },
                    ]}
                />

                <Titlebar titleName="Antenna" type="at"/>
                <LabelValue
                    data={[
                        {
                            title: "Transmitter Gain",
                            value: selectedData.data?.antenna.txg,
                            unit: "dBi",
                        },
                    ]}
                />
                <LabelSelect
                    titleName="Polarization"
                    value={selectedData.data?.antenna.pol}
                    options={[
                        {value: "h", label: "Horizontal"},
                        {value: "v", label: "Vertical"},
                    ]}
                />
                <LabelValue
                    data={[
                        {
                            title: "Pattern",
                            value: selectedData.data?.additionalDTO.patternDto.name,
                            images: [
                                selectedData.data?.additionalDTO.patternDto.img1,
                                selectedData.data?.additionalDTO.patternDto.img2,
                            ],
                        },
                    ]}
                />

                <Titlebar titleName="Feeder" type="fd"/>
                <LabelValue
                    data={[
                        {
                            title: "Coaxial Type",
                            value:
                            selectedData.data?.additionalDTO.coaxialTypeDto.name,
                        },
                    ]}
                />
                <LabelRange
                    titleName="Coaxial Length"
                    value={selectedData.data?.additionalDTO.coaxialLength}
                    unit="m"
                    defaultValue={selectedData.data?.additionalDTO.coaxialLength}
                />
                <LabelValue
                    data={[
                        {
                            title: "Connectors",
                            value: selectedData.data?.additionalDTO.connector,
                        },

                        {
                            title: "Loss",
                            value: selectedData.data?.antenna.txl,
                            unit: "dB",
                        },
                        {
                            title: "ERP",
                            value: selectedData.data?.additionalDTO.erp,
                        },
                        {
                            title: "EIRP",
                            value: selectedData.data?.additionalDTO.eirp,
                        },
                        {
                            title: "Efficiency",
                            value: selectedData.data?.additionalDTO.effieciency,
                            bg:
                                Math.ceil(
                                    selectedData.data?.additionalDTO.effieciency
                                ) > 75
                                    ? "green-800"
                                    : Math.ceil(
                                        selectedData.data?.additionalDTO.effieciency
                                    ) < 75 &&
                                    Math.ceil(
                                        selectedData.data?.additionalDTO.effieciency
                                    ) > 50
                                        ? "orange-500"
                                        : "red-500",
                        },
                    ]}
                />
                {(() => {
                    if (selectedData.data.coverageType === "GTA") {
                        return <Titlebar titleName="Airbone" type="ab"/>;
                    } else {
                        return <Titlebar titleName="Receiver / RX" type="ab"/>;
                    }
                })()}

                <LabelValue
                    data={[
                        {
                            title: "Altitude",
                            value: selectedData.data?.additionalDTO.oriAlt,
                            unit: (() => {
                                const data = selectedData.data;
                                if (data.coverageType === "GTA") {
                                    return UNIT.FEET;
                                } else if (data.coverageType === "GTG") {
                                    return UNIT.METER;
                                } else if (data.coverageType === "JAMMER") {
                                    if (data.receiver.alt === data.additionalDTO.oriAlt) {
                                        return UNIT.METER;
                                    } else return UNIT.FEET;
                                }
                            })(),
                        },
                        {
                            title: "RX Antenna Gain",
                            value: selectedData.data?.receiver.rxg,
                            unit: "dBi",
                        },
                        {
                            title: "Sensitivity",
                            value: selectedData.data?.receiver.rxs,
                            unit: "dBm",
                        },
                    ]}
                />

                <Titlebar titleName="Propagation Model" type="pg"/>
                <LabelValue
                    data={[
                        {
                            title: "Model",
                            value: selectedData.data?.model.pm,
                        },
                        {
                            title: "Realibility",
                            value: selectedData.data?.additionalDTO.oriRel,
                            unit: "%",
                        },
                    ]}
                />

                <Titlebar titleName="Coverage Information" type="cv"/>
                <LabelValue
                    handleShowKeys={() => setIsShowKeys(!isShowKey)}
                    data={[
                        {
                            title: "Area",
                            value: (() => {
                                const data = selectedData.data;
                                const area = selectedData.result.area;
                                if (data.coverageType === "GTA") {
                                    return km2ToNauticalMiles2(area);
                                } else if (data.coverageType === "GTG") {
                                    return area;
                                } else if (data.coverageType === "JAMMER") {
                                    if (data.output.rad === data.additionalDTO.oriRad) {
                                        return area;
                                    } else return km2ToNauticalMiles2(area);
                                }
                            })(),
                            unit: (() => {
                                const data = selectedData.data;
                                if (data.coverageType === "GTA") {
                                    return UNIT.NM2;
                                } else if (data.coverageType === "GTG") {
                                    return UNIT.KM2;
                                } else if (data.coverageType === "JAMMER") {
                                    if (data.output.rad === data.additionalDTO.oriRad) {
                                        return UNIT.KM2;
                                    } else return UNIT.NM2;
                                }
                            })(),
                        },
                        {
                            title: "Coverage",
                            value: selectedData.result?.coverage,
                            unit: "%",
                        },
                        {
                            title: "Keys",
                            value: isShowKey ? "Hide" : "Show",
                            type: "click",
                        },
                    ]}
                />
            </div>
        )
    }

    const handleCurrentPosition = () => {
        dispatch(handleFetchGpsData());
    }

    return (
        <>
            <Drawer
                titleName={dashBoard && !selectedData ? "Simulation" : ""}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                setSelectedId={setSelectedId}
            >
                {!dashBoard ? (
                    <>
                        {ANALYZE_TYPE.LINK_BUDGET === analyzeType &&
                            dataEntityFromDashboard && (
                                <div className="flex flex-row justify-between items-center">
                                    <div className="flex items-center">
                                        <input
                                            // key={dataEntityFromDashboard.id}
                                            className="checkbox checkbox-md border-2 border-slate-50 checked:bg-blue-500 default:ring-2"
                                            type="checkbox"
                                            value={dataEntityFromDashboard.data?.name}
                                            style={{borderColor: "white"}}
                                            checked={dataEntityFromDashboard.coverageStatus}
                                            onChange={(e) =>
                                                handleCheckBoxShowHideCoverageRadioLinkBudget(e)
                                            }
                                        />
                                        <label
                                            className="form-check-label inline-block text-white mx-2"
                                            htmlFor="flexCheckDefault"
                                        >
                                            Show Coverage
                                        </label>
                                    </div>
                                </div>
                            )}

                        <form
                            className="flex flex-col overflow-y-scroll"
                        >
                            <Titlebar
                                titleName="Ground Transmitter / TX"
                                type="tx"
                                link={"Load Data"}
                                onLink={() => handleLoadRadioOrAntenna("radio")}
                            />
                            <LabelInput
                                titleName="Name"
                                autoFocus
                                id="name"
                                name="name"
                                type="text"
                                {...register("name", {
                                    required: "Required!",
                                    pattern: {
                                        value: /^(?=\S)[^\[\]\{\}\^&#\?\\\|<>]+$/, //eslint-disable-line
                                        message: "Entered value can't start or contain only white spacing and can't contain these symbols [, ], {, }, ^, &, #, ?, \\, |, <, >"
                                    },
                                })}
                                error={errors.name}
                            />
                            <LabelInput
                                titleName="Network"
                                id="network"
                                name="network"
                                type="text"
                                {...register("network", {
                                    required: "Required!",
                                    pattern: {
                                        value: /^(?=\S)[^\[\]\{\}\^&#\?\\\|<>]+$/, //eslint-disable-line
                                        message: "Entered value can't start or contain only white spacing and can't contain these symbols [, ], {, }, ^, &, #, ?, \\, |, <, >"
                                    },
                                })}
                                error={errors.network}
                            />
                            <LabelSelect
                                titleName="Coordinates"
                                type="cl"
                                value={coordinateFormat.value}
                                options={COORDINATE_FORMAT}
                                onChange={handleCoordinateFormat}
                                getCurrentPosition={handleCurrentPosition}
                            />
                            <LabelInput
                                titleName="Latitude"
                                type="text"
                                unit={lastCoordinateFormat.unit}
                                {...register("transmitter.lat", {
                                    required: "Required!",
                                    min: -90,
                                    max: 90,
                                    pattern: {
                                        value: /^(?=\S)[^\[\]\{\}\^&#\?\\\|<>]+$/, //eslint-disable-line
                                        message: "Entered value can't start or contain only white spacing and can't contain these symbols [, ], {, }, ^, &, #, ?, \\, |, <, >"
                                    },
                                    onChange: () => {
                                        onSetTxPosition();
                                    },
                                })}
                                error={errors.transmitter?.lat}
                            />
                            <LabelInput
                                titleName="Longitude"
                                type="text"
                                unit={lastCoordinateFormat.unit}
                                {...register("transmitter.lon", {
                                    required: "Required!",
                                    min: -180,
                                    max: 180,
                                    pattern: {
                                        value: /^(?=\S)[^\[\]\{\}\^&#\?\\\|<>]+$/, //eslint-disable-line
                                        message: "Entered value can't start or contain only white spacing and can't contain these symbols [, ], {, }, ^, &, #, ?, \\, |, <, >"
                                    },
                                    onChange: () => {
                                        onSetTxPosition();
                                    },
                                })}
                                error={errors.transmitter?.lon}
                            />
                            {ANALYZE_TYPE.JAMMER === analyzeType ? (
                                highJammer()
                            ) : (
                                <LabelInput
                                    titleName={ANALYZE_TYPE.MESH_SIMULATION === analyzeType ? "TX Height" : "Tower Height"}
                                    type="number"
                                    unit="m"
                                    step="any"
                                    {...register("transmitter.alt", {
                                        valueAsNumber: true,
                                        required: "Required!",
                                        onChange: (e) => {
                                            const value = e.target.value;
                                            if (!value || value < 0) {
                                                e.target.value = 0;
                                            }
                                            setValue("transmitter.alt", e.target.value);
                                            onSetTxPosition();
                                            dispatch(handleSetKineticFromRightPanel({
                                                lat: Number(getValues("transmitter.lat")),
                                                lon: Number(getValues("transmitter.lon")),
                                                alt: Number(e.target.value),
                                                for: "tx",
                                            }))
                                        },
                                    })}
                                    error={errors.transmitter?.alt}
                                />
                            )}
                            <LabelInput
                                titleName="Frequency"
                                type="number"
                                unit="MHz"
                                step=".01"
                                {...register("transmitter.frq", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                    onChange: (e) => {
                                        calculateLoss();
                                    },
                                })}
                                error={errors.transmitter?.frq}
                            />
                            <LabelInput
                                titleName="TX Power"
                                type="number"
                                unit="W"
                                step=".01"
                                {...register("transmitter.txw", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                })}
                                error={errors.transmitter?.txw}
                            />
                            <LabelInput
                                titleName="Bandwidth"
                                type="number"
                                unit="MHz"
                                step=".01"
                                {...register("transmitter.bwi", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                })}
                                error={errors.transmitter?.bwi}
                            />
                            <Titlebar
                                titleName="Antenna"
                                type="at"
                                link="Load Data"
                                onLink={() => handleLoadRadioOrAntenna(`antenna`)}
                                name="txg"
                                value={getValues("antenna.txg")}
                                unit="dB"
                            />
                            <LabelInput
                                titleName="Transmitter Gain"
                                type="number"
                                unit="dBi"
                                step=".01"
                                {...register("antenna.txg", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                    onChange: (e) => {
                                        calculateLoss();
                                    }
                                })}
                                error={errors.antenna?.txg}
                            />
                            <LabelInput
                                titleName="Antenna Azimuth"
                                type="number"
                                unit="D"
                                step=".01"
                                {...register("antenna.azi", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                })}
                                error={errors.antenna?.azi}
                            />
                            <LabelInput
                                titleName="Antenna Tilt"
                                type="number"
                                unit="D"
                                step=".01"
                                {...register("antenna.tlt", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                })}
                                error={errors.antenna?.tlt}
                            />
                            <LabelSelect
                                titleName="Polarization"
                                type="text"
                                options={DIRECTION}
                                {...register("antenna.pol", {
                                    required: "Required!",
                                })}
                                error={errors.antenna?.pol}
                            />
                            <LabelSelect
                                titleName="Pattern"
                                type="text"
                                options={patterns}
                                {...register("additionalDTO.patternDto.name", {
                                    required: "Required!",
                                    onChange: (e) => {
                                        for (let i = 0; i < patterns.length; i++) {
                                            if (patterns[i].value === e.target.value) {
                                                setValue("additionalDTO.patternDto.id", patterns[i].id);
                                                setValue(
                                                    "additionalDTO.patternDto.img1",
                                                    patterns[i].image_left
                                                );
                                                setValue(
                                                    "additionalDTO.patternDto.img2",
                                                    patterns[i].image_right
                                                );
                                                setPattern({
                                                    left: patterns[i].image_left,
                                                    right: patterns[i].image_right,
                                                });
                                                break;
                                            }
                                        }
                                    },
                                })}
                                error={errors.additionalDTO?.patternDto?.name}
                            />
                            <div className="flex flex-row gap-x-3 max-w-[382px] p-4">
                                <img
                                    src={pattern?.left ?? patterns[0].image_left}
                                    alt="img_left"
                                    className="w-[50%]"
                                />
                                <img
                                    src={pattern?.right ?? patterns[0].image_right}
                                    alt="img_right"
                                    className="w-[50%]"
                                />
                            </div>

                            <Titlebar titleName="Feeder" type="fd"/>
                            <LabelSelect
                                titleName="Coaxial Type"
                                type="text"
                                options={CloaxialType}
                                {...register("additionalDTO.coaxialTypeDto.name", {
                                    required: "Required!",
                                    onChange: (e) => {
                                        for (let i = 0; i < CloaxialType.length; i++) {
                                            if (CloaxialType[i].value === e.target.value) {
                                                setValue(
                                                    "additionalDTO.coaxialTypeDto.id",
                                                    CloaxialType[i].id
                                                );
                                                break;
                                            }
                                        }
                                        calculateLoss();
                                    },
                                })}
                                error={errors.additionalDTO?.coaxialTypeDto?.name}
                            />
                            <LabelRange
                                titleName="Coaxial Length"
                                unit="m"
                                {...register("additionalDTO.coaxialLength", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                    onChange: () => {
                                        calculateLoss();
                                    },
                                })}
                                defaultValue={watch("additionalDTO.coaxialLength")}
                                error={errors.additionalDTO?.coaxialLength}
                            />
                            <LabelSelect
                                titleName="Connectors"
                                {...register("additionalDTO.connector", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                    onChange: () => {
                                        calculateLoss();
                                    },
                                })}
                                options={Connectors}
                                error={errors.additionalDTO?.connector}
                            />
                            <LabelValue
                                data={[
                                    {
                                        title: "Loss",
                                        value: watch("antenna.txl"),
                                        unit: "dB",
                                        disabled: true,
                                    },
                                    {
                                        title: "ERP",
                                        value: watch("additionalDTO.erp"),
                                        disabled: true,
                                    },
                                    {
                                        title: "EIRP",
                                        value: watch("additionalDTO.eirp"),
                                        disabled: true,
                                    },
                                    {
                                        title: "Efficiency",
                                        value: `${effLabel} ${watch("additionalDTO.effieciency")}`,
                                        disabled: true,
                                        bg: effColor,
                                    },
                                ]}
                            />

                            {handleReceiverTitle()}
                            {handleAltUnit()}

                            <LabelInput
                                titleName="RX Antenna Gain"
                                type="number"
                                unit="dBi"
                                step=".01"
                                {...register("receiver.rxg", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                })}
                                error={errors.receiver?.rxg}
                            />
                            <LabelInput
                                titleName="Sensitivity"
                                type="number"
                                unit="dBm"
                                step=".01"
                                {...register("receiver.rxs", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                })}
                                error={errors.receiver?.rxs}
                            />
                            <Titlebar titleName="Propagation Model" type="pg"/>
                            {ANALYZE_TYPE.LINK_BUDGET === analyzeType ? (
                                <LabelValue
                                    data={[
                                        {
                                            title: "Model",
                                            value: isNaN(getValues("model.pm"))
                                                ? getValues("model.pm")
                                                : PM.find((d) => d.value === getValues("model.pm"))
                                                    .label,
                                        },
                                    ]}
                                />
                            ) : (
                                <LabelSelect
                                    titleName="Model"
                                    options={PM}
                                    {...register("model.pm", {
                                        valueAsNumber: true,
                                        required: "Required!",
                                    })}
                                    error={errors.model?.pm}
                                />
                            )}

                            <LabelInput
                                titleName="Realibility"
                                type="number"
                                unit="%"
                                step=".01"
                                {...register("model.rel", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                })}
                                error={errors.model?.rel}
                            />
                            <LabelSelect
                                titleName="Context"
                                options={Context}
                                {...register("model.pe", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                })}
                                error={errors.model?.pe}
                            />
                            <LabelSelect
                                titleName="Diffraction"
                                options={Diffraction}
                                {...register("model.ked", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                })}
                                error={errors.model?.ked}
                            />

                            <Titlebar titleName="Clutter" type="ct"/>
                            <LabelSelect
                                titleName="Profile"
                                options={Profile}
                                {...register("environment.clt", {
                                    required: "Required!",
                                })}
                                error={errors.environment?.clt}
                            />
                            <LabelSelect
                                titleName="Landcover"
                                options={Landcover}
                                {...register("environment.cll", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                })}
                                error={errors.environment?.cll}
                            />
                            {/* <LabelSelect
                                titleName="Custom Clutter"
                                options={CustomCluster}
                                {...register("environment.clm", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                })}
                                error={errors.environment?.clm}
                            /> */}

                            <Titlebar titleName="Output Design" type="pl"/>
                            <LabelSelect
                                titleName="Resolution"
                                options={Resolution}
                                {...register("output.res", {
                                    valueAsNumber: true,
                                    required: "Required!",
                                })}
                                error={errors.output?.res}
                                className="right-panel"
                                disabled={ANALYZE_TYPE.OPERATION_PLANNING === analyzeType}
                            />
                            <LabelSelect
                                titleName="Color Schema"
                                options={ColorScheme}
                                {...register("output.col", {
                                    required: "Required!",
                                })}
                                error={errors.output?.col}
                            />
                            {ANALYZE_TYPE.JAMMER !== analyzeType ? (
                                <LabelInput
                                    titleName="Radius"
                                    type="number"
                                    unit={(() => {
                                        if (ANALYZE_TYPE.GTG !== analyzeType) {
                                            return UNIT.NM;
                                        } else {
                                            return UNIT.KM;
                                        }
                                    })()}
                                    step=".01"
                                    {...register("output.rad", {
                                        valueAsNumber: true,
                                        required: "Required!",
                                    })}
                                    error={errors.output?.rad}
                                />
                            ) : (
                                radJammer()
                            )}

                            <div className="flex items-center justify-start pt-8 pl-4 rounded-b">
                                {ANALYZE_TYPE.LINK_BUDGET !== analyzeType &&
                                    ANALYZE_TYPE.MESH_SIMULATION !== analyzeType && (
                                        <button
                                            type="button"
                                            onClick={handleClearButton}
                                            className="btn btn-outline text-white"
                                        >
                                            Clear
                                        </button>
                                    )}
                                {ANALYZE_TYPE.MESH_SIMULATION !== analyzeType && (
                                    <button
                                        type="button"
                                        onClick={handleSubmit(onSubmit)}
                                        className="btn btn-primary text-white ml-4"
                                    >
                                        {ANALYZE_TYPE.LINK_BUDGET === analyzeType ? showChartLinkBudget ? "Hide Chart" : "Show Chart" : "Apply"}
                                    </button>
                                )}
                                {ANALYZE_TYPE.GTA !== analyzeType &&
                                    ANALYZE_TYPE.GTG !== analyzeType &&
                                    ANALYZE_TYPE.JAMMER !== analyzeType &&
                                    ANALYZE_TYPE.FREQUENCY_ALLOCATION !== analyzeType &&
                                    ANALYZE_TYPE.OPERATION_PLANNING !== analyzeType &&
                                    renderReport(analyzeType)}
                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        {selectedData ? onSelectedData() : (
                            <div className="flex flex-col overflow-y-auto">
                                <div className="items-center flex justify-between p-4 mb-5">
                                    <div className="items-center flex flex-row">
                                        <input
                                            className="checkbox checkbox-md border-2 border-slate-50 checked:bg-blue-500 default:ring-2"
                                            type="checkbox"
                                            value="Label"
                                            style={{borderColor: "white"}}
                                            defaultChecked={false}
                                            checked={checkShowAllLabel}
                                            onChange={handleCheckBoxShowHideLabel}
                                        />
                                        <label
                                            className="form-check-label inline-block text-white mx-2"
                                            htmlFor="flexCheckDefault"
                                        >
                                            Label
                                        </label>
                                    </div>
                                </div>

                                <h1 className="ml-4 text-md text-white font-semibold">
                                    Coverage Area
                                </h1>
                                <div className="items-center flex flex-row p-4">
                                    <input
                                        className="checkbox checkbox-md border-2 border-slate-50 checked:bg-blue-500 default:ring-2"
                                        type="checkbox"
                                        value="All"
                                        style={{borderColor: "white"}}
                                        checked={checkShowAllCoverage}
                                        onChange={(e) =>
                                            handleCheckBoxShowHideCoverage(e, 1, "All")
                                        }
                                    />
                                    <label
                                        className="form-check-label inline-block text-white mx-2"
                                        htmlFor="flexCheckDefault"
                                    >
                                        All
                                    </label>
                                </div>

                                {!!resultList &&
                                    resultList.content.map((el) => {
                                        return (
                                            <div key={el.id} className="items-center flex flex-row p-4">
                                                <input
                                                    className="checkbox checkbox-md border-2 border-slate-50 checked:bg-blue-500 default:ring-2"
                                                    type="checkbox"
                                                    value={el.data.name}
                                                    style={{borderColor: "white"}}
                                                    checked={el.coverageStatus}
                                                    onChange={(e) =>
                                                        handleCheckBoxShowHideCoverage(
                                                            e,
                                                            el.id,
                                                            el.data.name
                                                        )
                                                    }
                                                />
                                                <label
                                                    className="form-check-label inline-block text-white mx-2"
                                                    htmlFor="flexCheckDefault"
                                                >
                                                    {el.data.name}
                                                </label>
                                            </div>
                                        );
                                    })}

                                <div className="absolute bottom-0 right-[25%] mb-3">
                                    <ReportAnalysis resultList={filteredResultList} coverageRuler={coverageRuler}/>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Drawer>
        </>
    );
};

export default RightPannel;
