import {createSelector} from "reselect";

const selectTemplateReducer = (state) => state.templateReducer;

export const selectAllMasterTemplate = createSelector(
    [selectTemplateReducer],
    (state) => {
        if (state.allMasterTemplate && state.allMasterTemplate.content && state.allMasterTemplate.content.length > 0) {
            return state.allMasterTemplate.content;
        } else {
            return [];
        }
    }
);
export const selectTotalPages = createSelector(
    [selectTemplateReducer],
    (state) => {
        if (state.allMasterTemplate && state.allMasterTemplate.totalPages) {
            return state.allMasterTemplate.totalPages;
        } else {
            return null;
        }
    }
);
export const selectAllTaskByTemplateId = createSelector(
    [selectTemplateReducer],
    (state) => state.taskByTemplateId
);
export const selectUpdateTaskByTemplateId = createSelector(
    [selectTemplateReducer],
    (state) => state.updateTaskByTemplateId
);

export const selectCreateStatus = createSelector(
    [selectTemplateReducer],
    (state) => state.createStatus
);
export const selectUpdateStatus = createSelector(
    [selectTemplateReducer],
    (state) => state.updateStatus
);
export const selectRemoveStatus = createSelector(
    [selectTemplateReducer],
    (state) => state.removeStatus
);
