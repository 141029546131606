/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import ReactPaginate from "react-paginate";

import CardAssets from "../../components/card/CardAssets";
import Chart from "../../components/chart/Chart";
import HeaderPage from "../../components/header/HeaderPage";
import {ModalForm} from "../../components/modal/ModalForm";
import Table from "../../components/table/Table";
import PlusIcon from "../../assets/icons/PlusIcon";
import {
    handleClearData,
    handleClearDetailAntenna,
    handleClearNotif,
    handleFetchBrand,
    handleFetchCategory,
    handleFetchcoaxialType,
    handleFetchSite,
    handleFetchType,
    handleGetAntennaById,
    handleRemoveAntenna,
    handleSaveAntenna,
    handleSearchAntenna,
    handleSearchAntennaReport,
    handleUpdateAntenna,
    handleUploadFile,
    handleUploadImage
} from "../../store/antenna/antenna.action";
import {
    selectAllAntenna,
    selectAllAntennaReport,
    selectBrands,
    selectcoaxialType,
    selectColumn,
    selectDataChart,
    selectDetailAntenna,
    selectRemoveStatus,
    selectStatusAntenna,
    selectStatusArray,
    selectTotalPages,
    selectUpdateStatus,
    selectUploadedAntennaStatus,
    selectUploadedFiles,
    selectUploadedImages,
} from "../../store/antenna/antenna.selector";
import {
    ALERT_ICON_FAILED,
    ALERT_ICON_SUCCESS,
    ALERT_TEXT_CREATE_FAILED,
    ALERT_TEXT_CREATE_SUCCESS,
    ALERT_TEXT_REMOVE_FAILED,
    ALERT_TEXT_REMOVE_SUCCESS,
    ALERT_TEXT_UPDATE_FAILED,
    ALERT_TEXT_UPDATE_SUCCESS,
    ALERT_TITLE_FAILED,
    ALERT_TITLE_SUCCESS,
    ASSET_ACTION_TYPE,
    ASSET_TYPE,
    CONFIRMATION_ICON_REMOVE,
    CONFIRMATION_TEXT_REMOVE_ASSET,
    CONFIRMATION_TITLE_REMOVE,
    MAX_REQUEST_SIZE,
    STATUS_TYPE,
    USER_MABES
} from "../../utils/constant/constants";
import {
    selectAssetToEdit,
    selectAssetToRemove,
    selectCurrentItem,
    selectDetailAsset
} from "../../store/global/global.selector";
import {handleClearStateGlobal, handleLoading} from "../../store/global/global.action";
import {selectCurrentUser} from "../../store/user/user.selector";
import SearchBox from "../../components/search-box/search-box.component";
import {generalAlert, generalConfirmation} from "../../utils/notification/notification";
import {handleClearTypeByBrandId, handleSearchTypeByBrandId} from "../../store/admin/admin.action";
import {selectTypesByBrand} from "../../store/admin/admin.selector";
import {validationFile, validationImage} from "../../utils/validation/validation";
import {handleRemoveUploadedFile, handleRemoveUploadedImage} from "../../store/radio/radio.action";
import {downloadImage, downloadPdf} from "../../utils/download/download";
import {DownloadIcon} from "../../assets/svgs";

const Antenna = () => {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [filterSelect, setFilterSelect] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [searchTextOnChange, setSearchTextOnChange] = useState("");
    const [page, setPage] = useState(0);
    const [actionType, setActionType] = useState(ASSET_ACTION_TYPE.NS);
    const [isUploadImageComplated, setIsUploadImageComplated] = useState(false);
    const [isUploadFileComplated, setIsUploadFileComplated] = useState(false);
    const [imagesId, setImageId] = useState([]);
    const [payload, setPayload] = useState({});
    const [fileList, setFileList] = useState([]);
    const [urlImages, setUrlImages] = useState([]);
    const [urlFiles, setUrlFiles] = useState([]);
    const [imagesToRemove, setImagesToRemove] = useState([]);
    const [filesToRemove, setFilesToRemove] = useState([]);

    const dispatch = useDispatch();
    const statusArray = useSelector(selectStatusArray);
    const brandAsset = useSelector(selectBrands);
    const totalPages = useSelector(selectTotalPages);
    const statusAntenna = useSelector(selectStatusAntenna);
    const dataChart = useSelector(selectDataChart);
    const typesByBrand = useSelector(selectTypesByBrand);
    const column = useSelector(selectColumn);
    const uploadedImages = useSelector(selectUploadedImages);
    const uploadedFiles = useSelector(selectUploadedFiles);
    const uploadedAntennaStatus = useSelector(selectUploadedAntennaStatus);
    const updateStatus = useSelector(selectUpdateStatus);
    const removeStatus = useSelector(selectRemoveStatus);
    const assetToRemove = useSelector(selectAssetToRemove);
    const assetToEdit = useSelector(selectAssetToEdit);
    const currentItem = useSelector(selectCurrentItem);
    const detailAsset = useSelector(selectDetailAsset);
    const currentUser = useSelector(selectCurrentUser);
    const data = useSelector(selectAllAntenna);
    const dataReport = useSelector(selectAllAntennaReport);
    const detailAntenna = useSelector(selectDetailAntenna);
    const coaxialType = useSelector(selectcoaxialType);

    const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm(({
        defaultValues: {
            name: "",
            connector: "",
            serialNo: "",
            partNo: "",
            antennaPower: "",
            notes: ""
        }
    }));

    useEffect(() => {
        dispatch(handleLoading(true));

        if (currentUser) {
            dispatch(handleSearchAntenna("", "name", 0, 10));
            dispatch(handleFetchBrand());
            dispatch(handleFetchCategory());
            dispatch(handleFetchType());
            dispatch(handleFetchSite());
            dispatch(handleFetchcoaxialType());
            dispatch(handleSearchAntennaReport("", "name", 0, MAX_REQUEST_SIZE));
        }

        return () => {
            dispatch(handleClearData());
            dispatch(handleClearStateGlobal());
            resetState();
            setPage(0);
            setSearchText("");
            setFilterSelect("");
            dispatch(handleLoading(false));
        };
    }, []);

    useEffect(() => {
        if (uploadedAntennaStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === uploadedAntennaStatus;
            const isFailed = STATUS_TYPE.FAILED === uploadedAntennaStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_CREATE_SUCCESS : ALERT_TEXT_CREATE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => {
                        dispatch(handleClearNotif());
                        handleClose();
                    }
                );
                dispatch(handleSearchAntenna(searchText, filterSelect, page, 10));
                dispatch(handleSearchAntennaReport(searchText, filterSelect, 0, MAX_REQUEST_SIZE));
            }
        }
    }, [uploadedAntennaStatus]);


    /**
     * EDIT ANTENNA DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.ANTENNA === assetToEdit && currentItem) {
            fillDataWithCurrentItem(currentItem);
            dispatch(handleSearchTypeByBrandId(currentItem.brand.id));
            setActionType(ASSET_ACTION_TYPE.UPDATE);
            setOpen(true);
        }
    }, [assetToEdit, detailAntenna]);

    useEffect(() => {
        if (updateStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === updateStatus;
            const isFailed = STATUS_TYPE.FAILED === updateStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_UPDATE_SUCCESS : ALERT_TEXT_UPDATE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => {
                        dispatch(handleClearNotif());
                        handleClose();
                    }
                );
                dispatch(handleSearchAntenna(searchText, filterSelect, page, 10));
                dispatch(handleSearchAntennaReport(searchText, filterSelect, 0, MAX_REQUEST_SIZE));
            }
        }
    }, [updateStatus]);

    /**
     * REMOVE ANTENNA DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.ANTENNA === assetToRemove && currentItem) {
            generalConfirmation(
                CONFIRMATION_TITLE_REMOVE,
                CONFIRMATION_TEXT_REMOVE_ASSET(currentItem.name),
                CONFIRMATION_ICON_REMOVE,
                () => {
                    dispatch(handleLoading(true));
                    dispatch(handleRemoveAntenna(currentItem.id, currentUser.siteId, page));
                },
                () => dispatch(handleClearStateGlobal()));
        }
    }, [assetToRemove]);

    useEffect(() => {
        if (removeStatus) {
            const isSuccess = STATUS_TYPE.SUCCESS === removeStatus;
            const isFailed = STATUS_TYPE.FAILED === removeStatus;
            if (isSuccess || isFailed) {
                dispatch(handleLoading(false));
                generalAlert(
                    isSuccess ? ALERT_TITLE_SUCCESS : ALERT_TITLE_FAILED,
                    isSuccess ? ALERT_TEXT_REMOVE_SUCCESS : ALERT_TEXT_REMOVE_FAILED,
                    isSuccess ? ALERT_ICON_SUCCESS : ALERT_ICON_FAILED,
                    () => {
                        dispatch(handleClearNotif());
                        handleClose();
                    }
                );
            }
            if (data && data.antenna && data.antenna.content && data.antenna.content.length) {
                dispatch(handleSearchAntenna(searchText, filterSelect, page, 10));
                dispatch(handleSearchAntennaReport(searchText, filterSelect, 0, MAX_REQUEST_SIZE));
            } else {
                if (page) {
                    dispatch(handleSearchAntenna(searchText, filterSelect, page - 1, 10));
                    dispatch(handleSearchAntennaReport(searchText, filterSelect, 0, MAX_REQUEST_SIZE));
                    setPage(prevPage => prevPage - 1);
                } else {
                    dispatch(handleSearchAntenna(searchText, filterSelect, 0, 10));
                    dispatch(handleSearchAntennaReport(searchText, filterSelect, 0, MAX_REQUEST_SIZE));
                    setPage(0);
                }
            }
        }
    }, [removeStatus]);

    /**
     * DETAIL ANTENNA DATA
     */
    useEffect(() => {
        if (ASSET_TYPE.ANTENNA === detailAsset && currentItem) {
            fillDataWithCurrentItem(currentItem);
            dispatch(handleSearchTypeByBrandId(currentItem.brand.id));
            setActionType(ASSET_ACTION_TYPE.DETAIL);
            setOpen(true);
        }
    }, [detailAsset, detailAntenna]);

    useEffect(() => {
        if (uploadedImages.length) {
            imagesId.push(uploadedImages);
            if (imagesId.length === selectedImage.length) {
                setIsUploadImageComplated(true);
                if (isUploadFileComplated || selectedFile.length === 0) {
                    uploadAntennaData(payload);
                }
            }
        }
    }, [uploadedImages])

    useEffect(() => {
        if (uploadedFiles.length) {
            if (uploadedFiles.length === selectedFile.length) {
                setIsUploadFileComplated(true);
                if (isUploadImageComplated || selectedImage.length === 0) {
                    uploadAntennaData(payload);
                }
            }
        }
    }, [uploadedFiles])

    useEffect(() => {
        if (detailAntenna) {
            let imgs = [];
            let fls = [];
            if (detailAntenna.imageUrlList && detailAntenna.imageUrlList.length) {
                detailAntenna.imageUrlList.forEach(data => imgs.push(data));
                setUrlImages(imgs);
            }

            // Receive detail Antenna File
            if (detailAntenna.fileUrlList && detailAntenna.fileNameList && detailAntenna.fileUrlList.length
                && detailAntenna.fileUrlList.length === detailAntenna.fileNameList.length) {

                const label = detailAntenna.fileNameList;
                const value = detailAntenna.fileUrlList;
                fls = label.map((item, index) => {
                    return {
                        label: item,
                        value: value[index],
                    }
                });
                setUrlFiles(fls);
            }
        }

    }, [detailAntenna])


    const resetState = () => {
        reset();
        setSelectedFile([]);
        setSelectedImage([]);
        setOpen(false);
        setIsUploadImageComplated(false);
        setImageId([]);
        setPayload({});
        setActionType(ASSET_ACTION_TYPE.NS);
        setIsUploadFileComplated(false);
        setFileList([]);
        setUrlImages([]);
        setUrlFiles([]);
        setImagesToRemove([]);
        setFilesToRemove([]);
        dispatch(handleClearDetailAntenna());
        dispatch(handleClearStateGlobal());
        dispatch(handleClearTypeByBrandId());
    }

    const fillDataWithCurrentItem = (currentItem) => {
        setValue("antennaPower", currentItem.antennaPower);
        setValue("type", currentItem.type.assetType);
        setValue("serialNo", currentItem.serialNo);
        setValue("partNo", currentItem.serialNo);
        setValue("site", currentItem.site.id);
        setValue("brand", currentItem.brand.id);
        setValue("freq", currentItem.freq);
        setValue("power", currentItem.power);
        setValue("freqMax", currentItem.freqMax);
        setValue("bandwidth", currentItem.bandwidth);
        setValue("notes", currentItem.notes);
        setValue("connector", currentItem.connector);
        setValue("coaxialType", currentItem.coaxialType && currentItem.coaxialType.id ? currentItem.coaxialType.id : "");
        setValue("status", currentItem.status);
        setValue("name", currentItem.name);
    }

    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(handleLoading(true));
    
        setSearchText(searchTextOnChange);
    
        const searchInput = document.getElementsByName("searchText")[0];
        const searchText = searchInput.value.toLowerCase();
        const filter = searchText === "" ? "name" : filterSelect;
    
        dispatch(handleSearchAntenna(searchText, filter, 0, 10));
        dispatch(handleSearchAntennaReport(searchText, filter, 0, MAX_REQUEST_SIZE));
    
        setPage(0);
    };

    const imageHandler = (e) => {
        if (e.target.files[0] === undefined) return;
        if (selectedImage.length < (5 - urlImages.length)) {
            setSelectedImage([...selectedImage, e.target.files[0]]);
        }
    };

    const fileHandler = (e) => {
        if (e.target.files[0] === undefined) return;
        if (selectedFile.length < (5 - urlFiles.length)) {
            setSelectedFile([...selectedFile, e.target.files[0]]);
        }
    };

    const handleSave = (data) => {
        dispatch(handleClearNotif());

        if (currentUser) {
            dispatch(handleLoading(true));
            setPayload(data);

            let isImageEmpty = false;
            let isFileEmpty = false;

            // Upload Image
            if (selectedImage && selectedImage.length) {
                if (validationImage(selectedImage) && validationFile(selectedFile)) {
                    selectedImage.forEach(item => {
                        dispatch(handleUploadImage(item));
                    });
                } else {
                    dispatch(handleLoading(false));
                }
            } else {
                isImageEmpty = true;
            }

            // Upload File
            if (selectedFile && selectedFile.length) {
                if (validationFile(selectedFile) && validationImage(selectedImage)) {
                    selectedFile.forEach(item => {
                        dispatch(handleUploadFile(item));
                    });
                } else {
                    dispatch(handleLoading(false));
                }
            } else {
                isFileEmpty = true;
            }

            // Remove Image
            if (imagesToRemove && imagesToRemove.length) {
                imagesToRemove.forEach(item => {
                    dispatch(handleRemoveUploadedImage(item))
                })
            }

            // Remove File
            if (filesToRemove && filesToRemove.length) {
                filesToRemove.forEach(item => {
                    dispatch(handleRemoveUploadedFile(item))
                })
            };

            if (isFileEmpty && isImageEmpty) {
                uploadAntennaData(data);
            }
        }
    };

    const getUrlFileArray = () => {
        let urlArr = []
        let nameArr = []
        if (uploadedFiles.length) {
            for (const uploadedFile of uploadedFiles) {
                urlArr.push(uploadedFile.id);
                nameArr.push(uploadedFile.message);
            }
        }
        return {urlArr, nameArr}
    }

    const uploadAntennaData = (payload) => {
        if (currentUser && payload) {
            if (ASSET_ACTION_TYPE.CREATE === actionType) {
                dispatch(handleSaveAntenna(payload, imagesId, getUrlFileArray(), currentUser.siteId));
                setPage(0);
            } else if (ASSET_ACTION_TYPE.UPDATE === actionType && currentItem) {
                dispatch(handleUpdateAntenna(currentItem.id, payload, imagesId, getUrlFileArray(), currentUser.siteId, page));
            }
        }
        resetState();
    }

    const handleClose = () => {
        reset();
        resetState();
    };

    const handleOpen = () => {
        reset();
        resetState();
        setActionType(ASSET_ACTION_TYPE.CREATE);
        setOpen(true);
    }

    const handlePageClick = (e) => {
        dispatch(handleLoading(true));
        dispatch(handleSearchAntenna(searchText, filterSelect, e.selected, 10));
        setPage(e.selected);
    };

    const onSearchChange = (e) => {
        setSearchTextOnChange(e.target.value)
    }

    const getAntennaDetailById = (id) => {
        dispatch(handleGetAntennaById(id))
    }

    const handleRemoveSelectedImage = (imageToRemove) => {
        const filteredSelectedImage = selectedImage.filter((item) => item.name !== imageToRemove.name);
        setSelectedImage(filteredSelectedImage);
    }

    const handleRemoveSelectedFile = (fileToRemove) => {
        const filteredSelectedFile = selectedFile.filter((item) => item.name !== fileToRemove.name);
        setSelectedFile(filteredSelectedFile);
    }

    const handleRemoveUrlImage = (imageToRemove) => {
        setUrlImages(prevUrlImages => prevUrlImages.filter(item => item !== imageToRemove));

        // Get the file value
        const imgValue = imageToRemove.split("/").pop();

        if (!imagesToRemove.includes(imgValue)) {
            setImagesToRemove(prevImg => [...prevImg, imgValue]);
        } else {
            setImagesToRemove(prevImg => prevImg.filter(item => item !== imgValue));
        }
    };

    const handleRemoveUrlFile = (fileToRemove) => {
        setUrlFiles(prevUrlFiles => prevUrlFiles.filter(item => item !== fileToRemove));

        // Get the file value
        const url = fileToRemove.value;
        const fileValue = url.split("/").pop();

        if (!filesToRemove.includes(fileValue)) {
            setFilesToRemove(prevFile => [...prevFile, fileValue]);
        } else {
            setFilesToRemove(prevFile => prevFile.filter(item => item !== fileValue));
        }
    };

    return (
        <div className="bg-tertiary min-h-screen py-4">
            <HeaderPage title="Antenna" icon="antenna"/>
            <div className="flex gap-x-5 px-8 mt-10">
                <div className="pl-5">
                    <Chart
                        datas={dataChart}
                        report="antenna"
                        assetList={dataReport?.antenna?.content}
                        statusAsset={statusAntenna}
                    />
                </div>
                <section className="grid grid-rows-2 grid-flow-col gap-y-10 items-end gap-x-72 ml-72">
                    {statusAntenna &&
                        statusAntenna.map((asset) => (
                            <CardAssets
                                key={asset.id}
                                asset={asset.name}
                                item={asset.item}
                                icon={asset.icon}
                                customClass={`back-${asset.class}`}
                            />
                        ))}
                </section>
            </div>
            <div className="pb-20 w-[92%] mx-auto mt-24">
                <div className="flex justify-between">
                    <form className="flex mb-6" onSubmit={handleSearch}>
                        <select
                            name="filter"
                            className="select select-bordered w-[200px] rounded-3xl h-[30px]"
                            onChange={(e) => setFilterSelect(e.target.value)}
                        >
                            <option selected disabled>Select filter</option>
                            <option value="name">Filter by Name</option>
                            <option value="brand">Filter by Brand</option>
                            <option value="type">Filter by Type</option>
                            {currentUser?.siteName === USER_MABES && (
                                <option value="owner">Filter by Owner</option>
                            )}
                            <option value="status">Filter by Status</option>
                        </select>
                        <SearchBox
                            name="searchText"
                            value={searchTextOnChange}
                            placeholder="Search Antenna"
                            className="input min-w-[300px] mx-3 rounded-full px-5 focus:outline-white focus:outline-1 focus:border-primary"
                            onChangeHandler={onSearchChange}
                        />
                        <span className="flex justify-center items-center text-white text-lg px-5 mr-auto">
                            <button
                                type="submit"
                                className="btn btn-primary rounded-full text-white text-xs px-7"
                            >
                                Search
                            </button>
                        </span>
                    </form>

                    <div className="flex justify-center items-center">
                        <button
                            type="button"
                            className="flex justify-center items-center text-white gap-x-2 text-lg cursor-pointer"
                            onClick={handleOpen}
                        >
                            <PlusIcon width={18} height={16}/>
                            Add Antenna
                        </button>
                    </div>
                </div>

                <div className="createModal">
                    <ModalForm
                        open={open}
                        onClose={handleClose}
                        onSave={
                            ASSET_ACTION_TYPE.DETAIL !== actionType && handleSubmit(handleSave)
                        }
                        title={`${actionType} Data Antenna`}
                        type="antenna"
                        btnCloseTitle="Cancel"
                        btnSubmitTitle="Save"
                        actionType={
                            ASSET_ACTION_TYPE.DETAIL === actionType ? actionType : ASSET_ACTION_TYPE.NS
                        }
                        size="w-2/3"
                        closeBtn={true}
                        selectedData={currentItem}
                        selectedImage={urlImages}
                    >
                        <div className="flex gap-2 overflow-auto">
                            {/*Display Image*/}
                            {urlImages && urlImages.map((item, index) => {
                                return (
                                    <div className="grid mt-7" key={index}>
                                        <div
                                            className="h-24 w-[95px] relative flex justify-center items-center border-2 rounded-md overflow-hidden mb-3">
                                            <div className="absolute flex justify-center items-center">
                                                <img
                                                    className={`opacity-1`}
                                                    src={item}
                                                    alt="opacity"
                                                />
                                            </div>
                                            {ASSET_ACTION_TYPE.DETAIL === actionType ? (
                                                <div className="flex">
                                                    <button
                                                        type="button"
                                                        onClick={() => downloadImage(item, index + 1)}
                                                        className="bg-white rounded-md p-1 absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2"
                                                    >
                                                        <DownloadIcon width={18} height={16}/>
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="flex">
                                                    <button
                                                        type="button"
                                                        className="btn btn-xs btn-circle bg-error hover:bg-red-800 absolute right-1 top-1"
                                                        onClick={() => handleRemoveUrlImage(item)}
                                                    >
                                                        ✕
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                            {/*Remove Image*/}
                            {selectedImage && selectedImage.map((item, index) => {
                                return (
                                    <div className="grid mt-7" key={index}>
                                        <div
                                            className="h-24 w-[95px] relative flex justify-center items-center border-2 bg-tertiary rounded-md overflow-hidden mb-3">
                                            <div className="absolute flex justify-center items-center">
                                                <img
                                                    className={`opacity-1`}
                                                    src={URL.createObjectURL(item)}
                                                    alt="opacity"
                                                />
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-xs btn-circle bg-error hover:bg-red-800 absolute right-1 top-1"
                                                onClick={() => handleRemoveSelectedImage(item)}
                                            >
                                                ✕
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                            {/*Select Image*/}
                            {selectedImage && selectedImage.length < (5 - urlImages.length) && (
                                <div className="w-full">
                                    <h1 className="font-bold mb-1">{ASSET_ACTION_TYPE.DETAIL !== actionType ? "Photo (Max 5 files)" : null}</h1>
                                    <div className="w-[95px] grid mb-3">
                                        {ASSET_ACTION_TYPE.DETAIL !== actionType && (
                                            <div
                                                className="h-24 cursor-pointer relative flex justify-center items-center border-2 bg-tertiary rounded-md">
                                                <input
                                                    type="file"
                                                    name="file"
                                                    onChange={ASSET_ACTION_TYPE.DETAIL === actionType ? null : imageHandler}
                                                    className="z-20 opacity-0 cursor-pointer h-full w-full"
                                                />
                                                <div className="absolute flex justify-center items-center">
                                                    <PlusIcon width={24} height={22}/>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                        </div>

                        <div className="mt-3">
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3">
                                    Name
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <input
                                        {...register("name", {
                                            required: "Required!",
                                            pattern: {
                                                value: /^\S+/,
                                                message: "Entered value cant start or contain only white spacing"
                                            },
                                        })}
                                        pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                        title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.name ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                        type="text"
                                        autoFocus
                                        placeholder={errors?.name?.message}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3">
                                    Brand
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <select
                                        {...register("brand", {
                                            required: "Required!",
                                            onChange: (e) => {
                                                dispatch(handleSearchTypeByBrandId(e.target.value));
                                                setValue("type", "");
                                            }
                                        })}
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        className={`select select-bordered w-full bg-white text-black font-thin border-2 focus:border-purple-500 ${errors?.brand ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                                    >
                                        <option value="" selected disabled>Select Brand</option>
                                        {brandAsset && brandAsset.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3">
                                    Type
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <select
                                        {...register("type", {
                                            required: "Required!",
                                        })}
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        className={`select select-bordered w-full bg-white text-black font-thin border-2 focus:border-purple-500 ${errors?.type ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                                    >
                                        <option value="" selected disabled>Select Type</option>
                                        {typesByBrand && typesByBrand.map((item, index) => {
                                            return (
                                                <option key={index} value={item.assetType}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3">
                                    Connector
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <input
                                        {...register("connector", {
                                            required: "Required!",
                                            pattern: {
                                                value: /^\S+/,
                                                message: "Entered value cant start or contain only white spacing"
                                            },
                                        })}
                                        pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                        title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.connector ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                        type="text"
                                        placeholder={errors?.connector?.message}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3">
                                    Coaxial Type
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <select
                                        {...register("coaxialType")}
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        className={`select select-bordered w-full bg-white text-black font-thin border-2 focus:border-purple-500 ${errors?.coaxialType ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                                    >
                                        <option value="" selected disabled>Select Coaxial Type</option>
                                        {coaxialType && coaxialType.content && coaxialType.content.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3">
                                    Serial Number
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <input
                                        {...register("serialNo", {
                                            required: "Required!",
                                            pattern: {
                                                value: /^\S+/,
                                                message: "Entered value cant start or contain only white spacing"
                                            },
                                        })}
                                        pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                        title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.serialNo ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                        type="text"
                                        placeholder={errors?.serialNo?.message}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3">
                                    Part Number
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <input
                                        {...register("partNo", {
                                            required: "Required!",
                                            pattern: {
                                                value: /^\S+/,
                                                message: "Entered value cant start or contain only white spacing"
                                            },
                                        })}
                                        pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                        title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.partNo ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                        type="text"
                                        placeholder={errors?.partNo?.message}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3">
                                    Status
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <select
                                        {...register("status", {
                                            required: "Required!",
                                        })}
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        className={`select select-bordered w-full bg-white text-black font-thin border-2 focus:border-purple-500 ${errors?.status ? "outline-1 focus:outline-red-500 border-0 focus:border-none": ""}`}
                                    >
                                        <option value="" selected disabled>Select Status</option>
                                        {statusArray && statusArray.map((item, index) => {
                                            return (
                                                <option key={index} value={item.key}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="mt-3">
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3">
                                    Transmitter Gain
                                </label>
                                <div className="flex flex-row w-full items-center justify-start ml-32">
                                    <input
                                        {...register("antennaPower")}
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        type="number"
                                        step="any"
                                        className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.antennaPower ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                        placeholder={errors?.antennaPower?.message}
                                    />
                                    <span className="text-white ml-4 mr-14">dBi</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3" htmlFor="site">
                                    Owner
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <select
                                        disabled
                                        className="select select-bordered w-full bg-white text-black font-thin"
                                    >
                                        <option selected disabled>{currentUser && currentUser.siteName}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex flex-row  items-center my-2">
                                <label className="text-white w-1/3">
                                    Notes
                                </label>
                                <div className="flex flex-row w-3/5 items-center justify-start ml-6">
                                    <textarea
                                        {...register("notes", {
                                            pattern: {
                                                value: /^\S+/,
                                                message: "Entered value cant start or contain only white spacing"
                                            },
                                        })}
                                        pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                        title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                        disabled={ASSET_ACTION_TYPE.DETAIL === actionType}
                                        rows="4"
                                        className={`textarea w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.notes ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                        placeholder="Notes"
                                    ></textarea>
                                </div>
                            </div>

                            {/*Upload File*/}
                            <div className="flex flex-row items-center my-2">
                                <label className={`text-white w-1/3 ${selectedFile.length < (5 - urlFiles.length) ? "mt-0" : "mt-4"}`} htmlFor="file_input">
                                    {ASSET_ACTION_TYPE.DETAIL !== actionType ? "Upload Pdf (Max 5 files)" : "Pdf List"}
                                </label>
                                {ASSET_ACTION_TYPE.DETAIL !== actionType && selectedFile && selectedFile.length < (5 - urlFiles.length) && (
                                    <div className="flex flex-row w-3/5 items-center justify-start ml-6 my-3">
                                        <label
                                            className="btn btn-primary btn-sm text-white text-xs cursor-pointer inline-flex items-center rounded-md"
                                            htmlFor="file_input"
                                        >
                                            Upload here
                                        </label>
                                        <input
                                            type="file"
                                            name="file"
                                            onChange={fileHandler}
                                            id="file_input"
                                            className="w-1 h-1 opacity-0 overflow-hidden absolute z-[-1]"
                                        />
                                    </div>
                                )}
                            </div>

                            {/*Show File List with action to Download or Remove file*/}
                            {urlFiles && urlFiles.map((item, index) => {
                                return (
                                    <div className="flex mt-2" key={index}>
                                        <div className="w-[65%]"></div>
                                        <div className="w-full">
                                            <span>{item.label}</span>
                                        </div>

                                        {ASSET_ACTION_TYPE.DETAIL === actionType ? (
                                            <div className="flex">
                                                <button
                                                    type="button"
                                                    onClick={() => downloadPdf(item)}
                                                    className="bg-white rounded-md p-1 mx-2 max-h-6"
                                                >
                                                    <DownloadIcon width={18} height={16}/>
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="flex">
                                                <button
                                                    type="button"
                                                    className="btn btn-xs btn-circle bg-error hover:bg-red-800"
                                                    onClick={() => handleRemoveUrlFile(item)}
                                                >
                                                    ✕
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )
                            })}

                            {/*Button Remove File*/}
                            {selectedFile && selectedFile.map((item, index) => {
                                return (
                                    <div className="flex mt-2" key={index}>
                                        <div className="w-[65%]"></div>
                                        <div className="w-full">
                                            <span>{item.name}</span>
                                        </div>

                                        <button
                                            type="button"
                                            className="btn btn-xs btn-circle bg-error hover:bg-red-800"
                                            onClick={() => handleRemoveSelectedFile(item)}
                                        >
                                            ✕
                                        </button>
                                    </div>
                                );
                            })}

                            {fileList && fileList.map((item, index) => {
                                if (item && typeof item === 'string' && item.includes("_")) {
                                    const newItem = item.replace('_', "&&&").split("&&&")[1];
                                    return (
                                        <div className="flex" key={index}>
                                            <div className="w-[57%]"></div>
                                            <p className="w-full">{newItem}</p>
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </div>

                    </ModalForm>
                </div>
                {data && data.antenna && data.antenna.content && data.antenna.content.length > 0 ? (
                    <Table
                        data={data.antenna.content}
                        column={column}
                        assetType={ASSET_TYPE.ANTENNA}
                        assetDetail={getAntennaDetailById}
                    />
                ) : (
                    <p className="flex flex-col text-white text-xl text-center pt-10">
                        Data is empty
                    </p>
                )}

                <div className="text-white flex flex-col">
                    <div className="flex justify-center  my-6 self-end h-14 items-center">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={totalPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            forcePage={page}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Antenna;
