import React, {useState, useEffect} from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useSelector } from "react-redux";

import {selectViewer} from "../../../store/dashboard/dashboard.selector";
import { report } from "../../../utils/report/report";
import { takeSnap } from "../../../utils/cesium/cesiumUtils";

import LambangTNI from "../../../assets/png/Lambang_TNI_AU.png";
import { PM } from "../../../utils/constant/constants";

const ReportAnalysis = ({ resultList, coverageRuler }) => {
    const [profile, setProfile] = useState([]);

    const viewer = useSelector(selectViewer);

    const detailModel = (inputModel) => {
        if (typeof(inputModel) === "string") {
            return inputModel
        } else {
            return PM[inputModel - 1].label
        }
    }

    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem("profile"));
        setProfile(userProfile);
    }, []);

    const handlePdfGenerate = () => {
        // Format
        const doc = new jsPDF("potrait", "px", "a4", "false");

        resultList.map((item, index) => {
            return (
                // Template
                report.template(
                    doc,
                    index + 1,
                    profile,
                    LambangTNI,
                    "Analysis Report",
                ),

                // Ground Transmitter / TX
                report.transmitter(
                    doc,
                    item?.data?.coverageType,
                    item?.data?.name,
                    item?.data?.network,
                    item?.data?.transmitter?.lat,
                    item?.data?.transmitter?.lon,
                    item?.data?.transmitter?.alt,
                    item?.data?.transmitter?.frq,
                    item?.data?.transmitter?.txw,
                    item?.data?.transmitter?.bwi,
                ),
                
                // Feeder
                report.feeder(
                    doc,
                    item?.data?.additionalDTO?.coaxialTypeDto?.name,
                    item?.data?.additionalDTO?.coaxialLength,
                    item?.data?.additionalDTO?.connector,
                    item?.data?.antenna?.txl,
                    item?.data?.additionalDTO?.erp,
                    item?.data?.additionalDTO?.eirp,
                    item?.data?.additionalDTO?.effieciency,
                ),
        
                item.data.coverageType === "GTA" ? (
                    // Airbone
                    report.airbone(
                        doc,
                        item?.data?.additionalDTO?.oriAlt,
                        item?.data?.receiver?.rxg,
                        item?.data?.receiver?.rxs,
                    )
                ) : (
                    // Receiver / RX
                    report.receiver(
                        doc,
                        item?.data?.coverageType,
                        item?.data?.additionalDTO?.oriAlt,
                        item?.data?.receiver?.rxg,
                        item?.data?.receiver?.rxs,
                    )
                ),
        
                // Propagation Model
                report.propagationModel(
                    doc,
                    detailModel(item?.data?.model?.pm),
                    item?.data?.model?.rel,
                ),
        
                // Antenna
                report.antenna(
                    doc,
                    item?.data?.antenna?.txg,
                    item?.data?.antenna?.pol,
                    item?.data?.additionalDTO?.patternDto?.name,
                    item?.data?.additionalDTO?.patternDto?.img1,
                    item?.data?.additionalDTO?.patternDto?.img2,
                ),
        
                // Output Design
                report.outputDesign(
                    doc,
                    item?.data?.output?.res,
                    item?.data?.output?.col,
                    item?.data?.output?.rad,
                ),
                
                // Covearage Information
                report.coverageInfo(
                    doc,
                    item?.data?.coverageType,
                    item?.result?.area,
                    item?.result?.coverage,
                ),

                // Add new page
                report.addPage(doc)
            )
        });

        if (coverageRuler && coverageRuler.length > 0) {
            doc.setTextColor("#040F0F");
            doc.setFontSize(8);
            doc.setFont(undefined, "normal").text(170, 150, "Coverage Ruler Information Table");
    
            report.templateAppendix(
                doc,
                resultList.length + 1,
                profile,
                LambangTNI,
                "Analysis Report",
            );
    
            autoTable(doc, {
                theme: "grid",
                styles: {
                    textColor: [0, 0, 0],
                    fontSize: 7,
                    overflow: "linebreak",
                    cellWidth: 80,
                },
                margin: { left: 55, bottom: 60 },
                startY: 160,
                didParseCell(data) {
                    if (data.row.index % 2 !== 0) {
                        data.cell.styles.textColor = [0, 0, 0];
                        data.cell.styles.fillColor = "#D3D3D3";
                    }
                    if (data.column.index === 1) {
                        data.cell.styles.cellPadding = {top: 3, right: 13, bottom: 3, left: 3};
                    }
                    if (data.column.index === 0) {
                        data.cell.styles.cellWidth = 15;
                    }
                    data.settings.margin.top = 150;
                },
                didDrawPage: (data) => {
                    if(data.pageCount >= 2) {
                        report.templateAppendix(doc, data.pageCount, profile, LambangTNI, "Analysis Report");
                    }
                },
                tableWidth: 385,
                head: [
                    ["No", "Latitude", "Longitude", "Bearing", "Distance"],
                ],
                body: coverageRuler.map((asset, index) => {
                    return [
                        index + 1,
                        asset?.lat,
                        asset?.lon,
                        asset?.bearing,
                        asset?.distance,
                    ];
                }),
            });

            // Add new page
            report.addPage(doc);
        }

        // Template appendix
        report.templateAppendix(
            doc,
            coverageRuler.length > 0 ? resultList.length + 2 : resultList.length + 1,
            profile,
            LambangTNI,
        )

        // Image Coverage
        report.coverageImg(doc, takeSnap(viewer))

        report.savePdf(doc, "Analysis")
    }

    return (
        <button
                type="button"
                className="btn btn-primary text-white min-w-[100px]"
                onClick={handlePdfGenerate}
                disabled={resultList && resultList.length === 0 ? true : false}
            >
                Create Report
        </button>
    )
}

export default ReportAnalysis