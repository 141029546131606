import * as React from "react"

const Airbone = (props) => (
  <svg
    width={22}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.586 12.414a2 2 0 1 1 2.828 0m-5.657 2.829a6 6 0 1 1 8.486 0M3.929 18.07C.024 14.166.024 7.834 3.929 3.93 7.834.024 14.166.024 18.07 3.929c3.905 3.905 3.905 10.237 0 14.142"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Airbone
