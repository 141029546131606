import {createSelector} from "reselect";

const selectFrequencyAllocationReducer = (state) => state.frequencyAllocationReducer;

export const selectAllFrequencyAllocation = createSelector(
    [selectFrequencyAllocationReducer],
    (state) => state.frequencyAllocations
)
export const selectSiteEntities = createSelector(
    [selectFrequencyAllocationReducer],
    (state) => state.siteEntities
)
export const selectSelectedSiteId = createSelector(
    [selectFrequencyAllocationReducer],
    (state) => state.selectedSiteId
)
export const selectHandlerListener = createSelector(
    [selectFrequencyAllocationReducer],
    (state) => state.handlerListener
)

export const selectUploadedFrequencyAllocationStatus = createSelector(
    [selectFrequencyAllocationReducer],
    (state) => state.uploadFrequencyAllocationStatus
)

export const selectUpdateFrequencyAllocationStatus = createSelector(
    [selectFrequencyAllocationReducer],
    (state) => state.updateStatus
)

export const selectRemoveFrequencyAllocationStatus = createSelector(
    [selectFrequencyAllocationReducer],
    (state) => state.removeStatus
)

export const selectTotalPages = createSelector(
    [selectAllFrequencyAllocation],
    (frequencyAllocations) => {
        if (frequencyAllocations) {
            return frequencyAllocations.totalPages
        } else {
            return null
        }
    }
)