import {
    Antenna2Icon,
    RadioIcon,
    PabxIcon,
    TowerIcon,
    SupportingIcon,
    AssetsIcon,
    ElectronicIcon,
    FiberOpticIcon
} from "../../assets/svgs";

const RenderIcon = ({ icon }) => {
    switch (icon) {
        case "total":
            return <AssetsIcon width={90} height={90} />;
        case "radio":
            return <RadioIcon width={90} height={90} />;
        case "antenna":
            return <Antenna2Icon width={90} height={90} fill={"#183343"} />;
        case "tower":
            return <TowerIcon width={90} height={90} />;
        case "pabx":
            return <PabxIcon width={90} height={90} />;
        case "supp":
            return <SupportingIcon width={90} height={90} />;
        case "electronic":
            return <ElectronicIcon width={90} height={90} />;
        case "fo":
            return <FiberOpticIcon width={90} height={90} />;
        default:
            return "";
    }
};

const CardAssetTotal = ({ asset, item = 0, icon }) => {
    return (
        <div className="cardTotal select-none">
            <div className="absolute left-9 top-6">
                <RenderIcon icon={icon} />
            </div>
            <h3 className="text-white absolute left-6 bottom-[60px] tracking-wide">{asset}</h3>
            <div className="absolute bottom-[55px] left-[20px] h-[2px] w-[120px]">
                <div className="bg-white w-full h-full"></div>
            </div>
            <span className="text-2xl font-bold absolute bottom-3 left-2/4 translate-x-[-50%]">{item}</span>
        </div>
    );
};

export default CardAssetTotal;