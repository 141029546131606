import autoTable from "jspdf-autotable";

import { getCurrentDate, getCurrentTime } from "../time/time";
import { km2ToNauticalMiles2, kmToNauticalMiles, meterToFeet } from "../calculate/convert";
import colorIndicator from "../../assets/png/indicator.png"

export const report = {
    template: (
        doc,
        page,
        profile,
        logo,
        title,
    ) => {
        return (
            // Letter Spacing
            doc.setCharSpace(0.5),
    
            // Border Page
            doc.line(8, 8, 8, 622, 'F'),
            doc.line(438, 8, 438, 622, 'F'),
            doc.line(8, 8, 438, 8, 'F'),
            doc.line(8, 622, 438, 622, 'F'),
    
            // Header
            doc.addImage(logo, "PNG", 25, 15, 45, 50),
            doc.setFontSize(18),
            doc.setFont(undefined, "bold").text(130, 38, "RADIO DIAGNOSTIC"),
            doc.text(113, 58, "COVERAGE SIMULATION"),
            doc.setFontSize(10),
            doc.setFont(undefined, "bold").text(340, 31, "Report"),
            doc.setFontSize(8),
            doc.setFont(undefined, "normal"),
            doc.text(340, 43, `Created by: ${profile.name}`),
            doc.text(340, 53, `Date: ${getCurrentDate()}`),
            doc.text(340, 63, `Time: ${getCurrentTime()}`),
    
            // Top Divider
            doc.setFont(undefined, "normal"),
            doc.setDrawColor("black"),
            doc.setLineWidth(1 / 2),
            doc.line(328, 14, 328, 70),
    
            // 3 Line Top
            doc.setLineWidth(0.5),
            doc.line(18, 77, 428, 77),
            doc.setLineWidth(1.5),
            doc.line(18, 79, 428, 79),
            doc.setLineWidth(0.5),
            doc.line(18, 81, 428, 81),
    
            // 3 Line Bottom
            doc.setLineWidth(0.5),
            doc.line(18, 580, 428, 580),
            doc.setLineWidth(1.5),
            doc.line(18, 582, 428, 582),
            doc.setLineWidth(0.5),
            doc.line(18, 584, 428, 584),

            // Title
            doc.setTextColor("#040F0F"),
            doc.setFontSize(16),
            doc.setFont(undefined, "bold").text(title.length < 8 ? 200 : 165, 110, title),
    
            // Middle Line
            doc.setLineWidth(1 / 2),
            doc.line(223, 135, 223, 570),

            // Footer
            doc.setFontSize(8),
            doc.text(20, 600, "Powered by:"),
            doc.text(20, 612, "signalitix"),

            // RDCS Logo
            doc.setFontSize(12),
            doc.setFont(undefined, "bold"),
            doc.setTextColor("#031952"),
            doc.text(400, 608, "RDCS"),

            // Page Number
            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.text(220, 608, `${page}`)
        )
    },

    templateAsset: (
        doc,
        page,
        profile,
        logo,
        title,
    ) => {
        return (
            // Letter Spacing
            doc.setCharSpace(0.5),
    
            // Border Page
            doc.line(8, 8, 8, 622, 'F'),
            doc.line(438, 8, 438, 622, 'F'),
            doc.line(8, 8, 438, 8, 'F'),
            doc.line(8, 622, 438, 622, 'F'),
    
            // Header
            doc.addImage(logo, "PNG", 25, 15, 45, 50),
            doc.setFontSize(18),
            doc.setFont(undefined, "bold").text(130, 38, "RADIO DIAGNOSTIC"),
            doc.text(113, 58, "COVERAGE SIMULATION"),
            doc.setFontSize(10),
            doc.setFont(undefined, "bold").text(340, 31, "Report"),
            doc.setFontSize(8),
            doc.setFont(undefined, "normal"),
            doc.text(340, 43, `Created by: ${profile.name}`),
            doc.text(340, 53, `Date: ${getCurrentDate()}`),
            doc.text(340, 63, `Time: ${getCurrentTime()}`),
    
            // Top Divider
            doc.setFont(undefined, "normal"),
            doc.setDrawColor("black"),
            doc.setLineWidth(1 / 2),
            doc.line(328, 14, 328, 70),
    
            // 3 Line Top
            doc.setLineWidth(0.5),
            doc.line(18, 77, 428, 77),
            doc.setLineWidth(1.5),
            doc.line(18, 79, 428, 79),
            doc.setLineWidth(0.5),
            doc.line(18, 81, 428, 81),
    
            // 3 Line Bottom
            doc.setLineWidth(0.5),
            doc.line(18, 580, 428, 580),
            doc.setLineWidth(1.5),
            doc.line(18, 582, 428, 582),
            doc.setLineWidth(0.5),
            doc.line(18, 584, 428, 584),

            // Title
            doc.setTextColor("#040F0F"),
            doc.setFontSize(16),
            doc.setFont(undefined, "bold").text(title.length < 8 ? 200 : 180, 110, `${title}`),

            // Footer
            doc.setFontSize(8),
            doc.text(20, 600, "Powered by:"),
            doc.text(20, 612, "signalitix"),

            // RDCS Logo
            doc.setFontSize(12),
            doc.setFont(undefined, "bold"),
            doc.setTextColor("#031952"),
            doc.text(400, 608, "RDCS"),

            // Page Number
            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.text(220, 608, `${page}`)
        )
    },

    templateAppendix: (
        doc,
        page,
        profile,
        logo,
    ) => {
        return (
            // Letter Spacing
            doc.setCharSpace(0.5),
    
            // Border Page
            doc.line(8, 8, 8, 622, 'F'),
            doc.line(438, 8, 438, 622, 'F'),
            doc.line(8, 8, 438, 8, 'F'),
            doc.line(8, 622, 438, 622, 'F'),
    
            // Header
            doc.addImage(logo, "PNG", 25, 15, 45, 50),
            doc.setFontSize(18),
            doc.setFont(undefined, "bold").text(130, 38, "RADIO DIAGNOSTIC"),
            doc.text(113, 58, "COVERAGE SIMULATION"),
            doc.setFontSize(10),
            doc.setFont(undefined, "bold").text(340, 31, "Report"),
            doc.setFontSize(8),
            doc.setFont(undefined, "normal"),
            doc.text(340, 43, `Created by: ${profile.name}`),
            doc.text(340, 53, `Date: ${getCurrentDate()}`),
            doc.text(340, 63, `Time: ${getCurrentTime()}`),
    
            // Top Divider
            doc.setFont(undefined, "normal"),
            doc.setDrawColor("black"),
            doc.setLineWidth(1 / 2),
            doc.line(328, 14, 328, 70),
    
            // 3 Line Top
            doc.setLineWidth(0.5),
            doc.line(18, 77, 428, 77),
            doc.setLineWidth(1.5),
            doc.line(18, 79, 428, 79),
            doc.setLineWidth(0.5),
            doc.line(18, 81, 428, 81),
    
            // 3 Line Bottom
            doc.setLineWidth(0.5),
            doc.line(18, 580, 428, 580),
            doc.setLineWidth(1.5),
            doc.line(18, 582, 428, 582),
            doc.setLineWidth(0.5),
            doc.line(18, 584, 428, 584),

            // Title
            doc.setTextColor("#040F0F"),
            doc.setFontSize(16),
            doc.setFont(undefined, "bold").text(193, 110, "Appendix"),
    
            // Footer
            doc.setFontSize(8),
            doc.text(20, 600, "Powered by:"),
            doc.text(20, 612, "signalitix"),

            // RDCS
            doc.setFontSize(12),
            doc.setFont(undefined, "bold"),
            doc.setTextColor("#031952"),
            doc.text(400, 608, "RDCS"),

            // Page Number
            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.text(220, 608, `${page}`)
        )
    },

    transmitter: (
        doc,
        type,
        name,
        network,
        latitude,
        longitude,
        altitude,
        frequency,
        txpower,
        bandwidth
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Ground Transmitter / TX", 30, 150),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Name", 30, 165),
            doc.setFont(undefined, "normal").text(name, 125, 165),
            doc.setFont(undefined, "bold").text("Network", 30, 180),
            doc.setFont(undefined, "normal").text(network, 125, 180),
            doc.setFont(undefined, "bold").text("Coordinates", 30, 195),
            doc.setFont(undefined, "normal").text("Decimal Degrees", 125, 195),
            doc.setFont(undefined, "bold").text("Latitude", 30, 210),
            doc.setFont(undefined, "normal").text(`${latitude} D.D`, 125, 210),
            doc.setFont(undefined, "bold").text("Longitude", 30, 225),
            doc.setFont(undefined, "normal").text(`${longitude} D.D`, 125, 225),
            doc.setFont(undefined, "bold").text(`${type === "JAMMER" ? "Jammer Height" : "Tower Height"}`, 30, 240),
            doc.setFont(undefined, "normal").text(`${type === "JAMMER" ? meterToFeet(altitude) : altitude} ${type === "JAMMER" ? "feet" : "m"}`, 125, 240),
            doc.setFont(undefined, "bold").text("Frequency", 30, 255),
            doc.setFont(undefined, "normal").text(`${frequency} MHz`, 125, 255),
            doc.setFont(undefined, "bold").text("TX Power", 30, 270),
            doc.setFont(undefined, "normal").text(`${txpower} W`, 125, 270),
            doc.setFont(undefined, "bold").text("Bandwidth", 30, 285),
            doc.setFont(undefined, "normal").text(`${bandwidth} MHz`, 125, 285)
        )
    },

    transmitterMesh: (
        doc,
        type,
        name,
        network,
        node,
        latitude,
        longitude,
        altitude,
        frequency,
        txpower,
        bandwidth
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Ground Transmitter / TX", 30, 150),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Name", 30, 165),
            doc.setFont(undefined, "normal").text(name, 125, 165),
            doc.setFont(undefined, "bold").text("Network", 30, 180),
            doc.setFont(undefined, "normal").text(network, 125, 180),
            doc.setFont(undefined, "bold").text("Node", 30, 195),
            doc.setFont(undefined, "normal").text(`${node}`, 125, 195),
            doc.setFont(undefined, "bold").text("Coordinates", 30, 210),
            doc.setFont(undefined, "normal").text("Decimal Degrees", 125, 210),
            doc.setFont(undefined, "bold").text("Latitude", 30, 225),
            doc.setFont(undefined, "normal").text(`${latitude} D.D`, 125, 225),
            doc.setFont(undefined, "bold").text("Longitude", 30, 240),
            doc.setFont(undefined, "normal").text(`${longitude} D.D`, 125, 240),
            doc.setFont(undefined, "bold").text(`${type === "JAMMER" ? "Jammer Height" : "Tower Height"}`, 30, 255),
            doc.setFont(undefined, "normal").text(`${type === "JAMMER" ? meterToFeet(altitude) : altitude} ${type === "JAMMER" ? "feet" : "m"}`, 125, 255),
            doc.setFont(undefined, "bold").text("Frequency", 30, 270),
            doc.setFont(undefined, "normal").text(`${frequency} MHz`, 125, 270),
            doc.setFont(undefined, "bold").text("TX Power", 30, 285),
            doc.setFont(undefined, "normal").text(`${txpower} W`, 125, 285),
            doc.setFont(undefined, "bold").text("Bandwidth", 30, 300),
            doc.setFont(undefined, "normal").text(`${bandwidth} MHz`, 125, 300)
        )
    },

    transmitterRadioLinkBudget: (
        doc,
        type,
        name,
        network,
        latitude,
        longitude,
        altitude,
        frequency,
        txpower,
        bandwidth,
        azimuthToRx,
        downTilt,
        distance
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Ground Transmitter / TX", 30, 150),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Name", 30, 165),
            doc.setFont(undefined, "normal").text(name, 125, 165),
            doc.setFont(undefined, "bold").text("Network", 30, 180),
            doc.setFont(undefined, "normal").text(network, 125, 180),
            doc.setFont(undefined, "bold").text("Coordinates", 30, 195),
            doc.setFont(undefined, "normal").text("Decimal Degrees", 125, 195),
            doc.setFont(undefined, "bold").text("Latitude", 30, 210),
            doc.setFont(undefined, "normal").text(`${latitude} D.D`, 125, 210),
            doc.setFont(undefined, "bold").text("Longitude", 30, 225),
            doc.setFont(undefined, "normal").text(`${longitude} D.D`, 125, 225),
            doc.setFont(undefined, "bold").text(`${type === "JAMMER" ? "Jammer Height" : "Tower Height"}`, 30, 240),
            doc.setFont(undefined, "normal").text(`${type === "JAMMER" ? meterToFeet(altitude) : altitude} ${type === "JAMMER" ? "feet" : "m"}`, 125, 240),
            doc.setFont(undefined, "bold").text("Frequency", 30, 255),
            doc.setFont(undefined, "normal").text(`${frequency} MHz`, 125, 255),
            doc.setFont(undefined, "bold").text("TX Power", 30, 270),
            doc.setFont(undefined, "normal").text(`${txpower} W`, 125, 270),
            doc.setFont(undefined, "bold").text("Bandwidth", 30, 285),
            doc.setFont(undefined, "normal").text(`${bandwidth} MHz`, 125, 285),
            doc.setFont(undefined, "bold").text("Azimuth To RX", 30, 300),
            doc.setFont(undefined, "normal").text(`${azimuthToRx}°`, 125, 300),
            doc.setFont(undefined, "bold").text("Downtilt", 30, 315),
            doc.setFont(undefined, "normal").text(`${downTilt}°`, 125, 315),
            doc.setFont(undefined, "bold").text("Distance", 30, 330),
            doc.setFont(undefined, "normal").text(`${distance} Km`, 125, 330)
        )
    },

    antenna: (
        doc,
        transmitterGain,
        polarization,
        pattern,
        patternImg1,
        patternImg2
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Antenna", 245, 150),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Transmitter Gain", 245, 165),
            doc.setFont(undefined, "normal").text(`${transmitterGain} dBi`, 353, 165),
            doc.setFont(undefined, "bold").text("Polarization", 245, 180),
            doc.setFont(undefined, "normal").text(`${polarization === "h" ? "Horizontal" : "Vertical" }`, 353, 180),
            doc.setFont(undefined, "bold").text("Pattern", 245, 195),
            doc.setFont(undefined, "normal").text(`${pattern.slice(0, 14)}`, 353, 195),
            doc.text(`${pattern.slice(14, 30)}`, 353, 205),
            doc.addImage(patternImg1, "PNG", 235, 220, 90, 90),
            doc.addImage(patternImg2, "PNG", 340, 220, 90, 90)
        )
    },

    antennaMesh: (
        doc,
        transmitterGain,
        azimuth,
        tilt,
        polarization,
        pattern,
        patternImg1,
        patternImg2
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Antenna", 245, 150),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Transmitter Gain", 245, 165),
            doc.setFont(undefined, "normal").text(`${transmitterGain} dBi`, 353, 165),
            doc.setFont(undefined, "bold").text("Antenna Azimuth", 245, 180),
            doc.setFont(undefined, "normal").text(`${azimuth}°`, 353, 180),
            doc.setFont(undefined, "bold").text("Antenna Tilt", 245, 195),
            doc.setFont(undefined, "normal").text(`${tilt}°`, 353, 195),
            doc.setFont(undefined, "bold").text("Polarization", 245, 210),
            doc.setFont(undefined, "normal").text(`${polarization === "h" ? "Horizontal" : "Vertical" }`, 353, 210),
            doc.setFont(undefined, "bold").text("Pattern", 245, 225),
            doc.setFont(undefined, "normal").text(`${pattern.slice(0, 14)}`, 353, 225),
            doc.text(`${pattern.slice(14, 30)}`, 353, 235),
            doc.addImage(patternImg1, "PNG", 235, 250, 90, 90),
            doc.addImage(patternImg2, "PNG", 340, 250, 90, 90)
        )
    },

    antennaRadioLinkBudget: (
        doc,
        transmittergain,
        polarization,
        pattern,
        patternImg1,
        patternImg2
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Antenna", 245, 285),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Transmitter Gain", 245, 300),
            doc.setFont(undefined, "normal").text(`${transmittergain} dBi`, 353, 300),
            doc.setFont(undefined, "bold").text("Polarization", 245, 315),
            doc.setFont(undefined, "normal").text(`${polarization === "h" ? "Horizontal" : "Vertical" }`, 353, 315),
            doc.setFont(undefined, "bold").text("Pattern", 245, 330),
            doc.setFont(undefined, "normal").text(`${pattern.slice(0, 14)}`, 353, 330),
            doc.text(`${pattern.slice(14, 30)}`, 353, 340),
            doc.addImage(patternImg1, "PNG", 235, 355, 90, 90),
            doc.addImage(patternImg2, "PNG", 340, 355, 90, 90)
        )
    },

    feeder: (
        doc,
        coaxialType,
        coaxialLength,
        connectors,
        loss,
        erp,
        eirp,
        effieciency
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Feeder", 30, 310),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Coaxial Type", 30, 325),
            coaxialType.length > 20 && doc.setFontSize(8),
            doc.setFont(undefined, "normal").text(`${coaxialType}`, 125, 325),
            doc.setFontSize(10),
            doc.setFont(undefined, "bold").text("Coaxial Length", 30, 340),
            doc.setFont(undefined, "normal").text(`${coaxialLength} m`, 125, 340),
            doc.setFont(undefined, "bold").text("Connectors", 30, 355),
            doc.setFont(undefined, "normal").text(`${connectors}`, 125, 355),
            doc.setFont(undefined, "bold").text("Loss", 30, 370),
            doc.setFont(undefined, "normal").text(`${loss} dB`, 125, 370),
            doc.setFont(undefined, "bold").text("ERP", 30, 385),
            doc.setFont(undefined, "normal").text(`${erp.length > 0 ? erp : "-"}`, 125, 385),
            doc.setFont(undefined, "bold").text("EIRP", 30, 400),
            doc.setFont(undefined, "normal").text(`${eirp.length > 0 ? eirp : "-"}`, 125, 400),
            doc.setFont(undefined, "bold").text("Efficiency", 30, 415),
            doc.setFont(undefined, "normal").text(`${effieciency} %`, 125, 415)
        )
    },

    feederMesh: (
        doc,
        coaxialType,
        coaxialLength,
        connectors,
        loss,
        erp,
        eirp,
        effieciency
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Feeder", 30, 325),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Coaxial Type", 30, 340),
            coaxialType.length > 20 && doc.setFontSize(8),
            doc.setFont(undefined, "normal").text(`${coaxialType}`, 125, 340),
            doc.setFontSize(10),
            doc.setFont(undefined, "bold").text("Coaxial Length", 30, 355),
            doc.setFont(undefined, "normal").text(`${coaxialLength} m`, 125, 355),
            doc.setFont(undefined, "bold").text("Connectors", 30, 370),
            doc.setFont(undefined, "normal").text(`${connectors}`, 125, 370),
            doc.setFont(undefined, "bold").text("Loss", 30, 385),
            doc.setFont(undefined, "normal").text(`${loss} dB`, 125, 385),
            doc.setFont(undefined, "bold").text("ERP", 30, 400),
            doc.setFont(undefined, "normal").text(`${erp.length > 0 ? erp : "-"}`, 125, 400),
            doc.setFont(undefined, "bold").text("EIRP", 30, 415),
            doc.setFont(undefined, "normal").text(`${eirp.length > 0 ? eirp : "-"}`, 125, 415),
            doc.setFont(undefined, "bold").text("Efficiency", 30, 430),
            doc.setFont(undefined, "normal").text(`${effieciency} %`, 125, 430)
        )
    },

    feederRadioLinkBudget: (
        doc,
        coaxialType,
        coaxialLength,
        connectors,
        loss,
        erp,
        eirp,
        efficiency
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Feeder", 30, 355),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Coaxial Type", 30, 370),
            coaxialType.length > 20 && doc.setFontSize(8),
            doc.setFont(undefined, "normal").text(`${coaxialType}`, 125, 370),
            doc.setFontSize(10),
            doc.setFont(undefined, "bold").text("Coaxial Length", 30, 385),
            doc.setFont(undefined, "normal").text(`${coaxialLength} m`, 125, 385),
            doc.setFont(undefined, "bold").text("Connectors", 30, 400),
            doc.setFont(undefined, "normal").text(`${connectors}`, 125, 400),
            doc.setFont(undefined, "bold").text("Loss", 30, 415),
            doc.setFont(undefined, "normal").text(`${loss} dB`, 125, 415),
            doc.setFont(undefined, "bold").text("ERP", 30, 430),
            doc.setFont(undefined, "normal").text(`${erp}`, 125, 430),
            doc.setFont(undefined, "bold").text("EIRP", 30, 445),
            doc.setFont(undefined, "normal").text(`${eirp}`, 125, 445),
            doc.setFont(undefined, "bold").text("Efficiency", 30, 460),
            doc.setFont(undefined, "normal").text(`${efficiency}`, 125, 460)
        )
    },

    airbone: (
        doc,
        altitude,
        rxantennagain,
        sensitivity
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Airbone", 30, 440),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Altitude", 30, 455),
            doc.setFont(undefined, "normal").text(`${altitude} feet`, 125, 455),
            doc.setFont(undefined, "bold").text("RX Antenna Gain", 30, 470),
            doc.setFont(undefined, "normal").text(`${rxantennagain} dBi`, 125, 470),
            doc.setFont(undefined, "bold").text("Sensitivity", 30, 485),
            doc.setFont(undefined, "normal").text(`${sensitivity} dBm`, 125, 485)
        )
    },

    airboneRadioLinkBudget: (
        doc,
        latitude,
        longitude,
        altitude,
        rxantennagain,
        sensitivity,
        fieldStrength,
        freespaceLoss,
        pathLoss,
        receivedPower
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Airbone", 30, 485),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Latitude", 30, 500),
            doc.setFont(undefined, "normal").text(`${latitude}`, 125, 500),
            doc.setFont(undefined, "bold").text("Longitude", 30, 515),
            doc.setFont(undefined, "normal").text(`${longitude} feet`, 125, 515),
            doc.setFont(undefined, "bold").text("Altitude", 30, 530),
            doc.setFont(undefined, "normal").text(`${altitude} feet`, 125, 530),
            doc.setFont(undefined, "bold").text("RX Antenna Gain", 30, 545),
            doc.setFont(undefined, "normal").text(`${rxantennagain} dBi`, 125, 545),
            doc.setFont(undefined, "bold").text("Sensitivity", 30, 560),
            doc.setFont(undefined, "normal").text(`${sensitivity} dBm`, 125, 560),
            doc.setFont(undefined, "bold").text("Field Strength", 245, 150),
            doc.setFont(undefined, "normal").text(`${fieldStrength} dBuV/m`, 353, 150),
            doc.setFont(undefined, "bold").text("Freespace Loss", 245, 165),
            doc.setFont(undefined, "normal").text(`${freespaceLoss} dB`, 353, 165),
            doc.setFont(undefined, "bold").text("Path Loss", 245, 180),
            doc.setFont(undefined, "normal").text(`${pathLoss} dB`, 353, 180),
            doc.setFont(undefined, "bold").text("Received Power", 245, 195),
            doc.setFont(undefined, "normal").text(`${receivedPower} dBm`, 353, 195)
        )
    },

    airboneOP: (
        doc,
        rxantennagain,
        sensitivity
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Airbone", 30, 440),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("RX Antenna Gain", 30, 455),
            doc.setFont(undefined, "normal").text(`${rxantennagain} dBi`, 125, 455),
            doc.setFont(undefined, "bold").text("Sensitivity", 30, 470),
            doc.setFont(undefined, "normal").text(`${sensitivity} dBm`, 125, 470)
        )
    },

    receiver: (
        doc,
        type,
        altitude,
        rxantennagain,
        sensitivity
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Receiver / RX", 30, 440),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Altitude", 30, 455),
            doc.setFont(undefined, "normal").text(`${altitude} ${type === "JAMMER" ? "feet" : "m"}`, 125, 455),
            doc.setFont(undefined, "bold").text("RX Antenna Gain", 30, 470),
            doc.setFont(undefined, "normal").text(`${rxantennagain} dBi`, 125, 470),
            doc.setFont(undefined, "bold").text("Sensitivity", 30, 485),
            doc.setFont(undefined, "normal").text(`${sensitivity} dBm`, 125, 485)
        )
    },

    receiverMesh: (
        doc,
        type,
        altitude,
        rxantennagain,
        sensitivity,
        noiseFloor
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Receiver / RX", 30, 455),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Altitude", 30, 470),
            doc.setFont(undefined, "normal").text(`${altitude} ${type === "JAMMER" ? "feet" : "m"}`, 125, 470),
            doc.setFont(undefined, "bold").text("RX Antenna Gain", 30, 485),
            doc.setFont(undefined, "normal").text(`${rxantennagain} dBi`, 125, 485),
            doc.setFont(undefined, "bold").text("Sensitivity", 30, 500),
            doc.setFont(undefined, "normal").text(`${sensitivity} dBm`, 125, 500),
            doc.setFont(undefined, "bold").text("Noise Floor", 30, 515),
            doc.setFont(undefined, "normal").text(`${noiseFloor} dBm`, 125, 515)
        )
    },

    propagationModel: (
        doc,
        model,
        reliability
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Propagation Model", 30, 510),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Model", 30, 525),
            doc.setFont(undefined, "normal").text(`${model.slice(0, 14)}`, 125, 525),
            doc.text(`${model.slice(14, 50)}`, 125, 535),
            doc.setFont(undefined, "bold").text("Reliability", 30, 550),
            doc.setFont(undefined, "normal").text(`${reliability} %`, 125, 550)
        )
    },

    propagationModelRadioLinkBudget: (
        doc,
        model,
        reliability
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Propagation Model", 245, 220),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Model", 245, 235),
            doc.setFont(undefined, "normal").text(`${model.slice(0, 14)}`, 353, 235),
            doc.text(`${model.slice(14, 50)}`, 353, 245),
            doc.setFont(undefined, "bold").text("Reliability", 245, 260),
            doc.setFont(undefined, "normal").text(`${reliability} %`, 353, 260)
        )
    },

    propagationModelMesh: (
        doc,
        model,
        reliability
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Propagation Model", 30, 535),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Model", 30, 550),
            doc.setFont(undefined, "normal").text(`${model.slice(0, 14)}`, 125, 550),
            doc.text(`${model.slice(14, 50)}`, 125, 560),
            doc.setFont(undefined, "bold").text("Reliability", 30, 575),
            doc.setFont(undefined, "normal").text(`${reliability} %`, 125, 575)
        )
    },

    propagationModelOP: (
        doc,
        model,
        reliability
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Propagation Model", 30, 495),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Model", 30, 510),
            doc.setFont(undefined, "normal").text(`${model.slice(0, 14)}`, 125, 510),
            doc.text(`${model.slice(14, 50)}`, 125, 520),
            doc.setFont(undefined, "bold").text("Reliability", 30, 535),
            doc.setFont(undefined, "normal").text(`${reliability} %`, 125, 535)
        )
    },

    outputDesign: (
        doc,
        resolution,
        colorschema,
        radius
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Output Design", 245, 335),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Resolution", 245, 350),
            doc.setFont(undefined, "normal").text(`${resolution} m / ${meterToFeet(resolution)}ft`, 353, 350),
            doc.setFont(undefined, "bold").text("Color Schema", 245, 365),
            doc.setFont(undefined, "normal").text(`${colorschema}`, 353, 365),
            doc.setFont(undefined, "bold").text("Radius", 245, 380),
            doc.setFont(undefined, "normal").text(`${kmToNauticalMiles(radius)} NM`, 353, 380)
        )
    },

    outputDesignRadioLinkBudget: (
        doc,
        resolution,
        colorschema,
        radius
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Output Design", 245, 470),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Resolution", 245, 485),
            doc.setFont(undefined, "normal").text(`${resolution} m / ${meterToFeet(resolution)}ft`, 353, 485),
            doc.setFont(undefined, "bold").text("Color Schema", 245, 500),
            doc.setFont(undefined, "normal").text(`${colorschema}`, 353, 500),
            doc.setFont(undefined, "bold").text("Radius", 245, 515),
            doc.setFont(undefined, "normal").text(`${kmToNauticalMiles(radius)} NM`, 353, 515)
        )
    },

    coverageInfo: (
        doc,
        type,
        area,
        coverage
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Coverage Information", 245, 405),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Area", 245, 420),
            doc.setFont(undefined, "normal").text(`${type === "GTA" ? km2ToNauticalMiles2(area) : area} ${type === "GTA" ? "NM2" : "KM2"}`, 353, 420),
            doc.setFont(undefined, "bold").text("Coverage", 245, 435),
            doc.setFont(undefined, "normal").text(`${coverage} %`, 353, 435)
        )
    },

    coverageInfoRadioLinkBudget: (
        doc,
        type,
        area,
        coverage
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Coverage Information", 245, 540),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Area", 245, 555),
            doc.setFont(undefined, "normal").text(`${type === "GTA" ? km2ToNauticalMiles2(area) : area} ${type === "GTA" ? "NM2" : "KM2"}`, 353, 555),
            doc.setFont(undefined, "bold").text("Coverage", 245, 570),
            doc.setFont(undefined, "normal").text(`${coverage} %`, 353, 570)
        )
    },

    assetCommon: (
        doc,
        category,
        brand,
        type,
        serialNumber,
        partNumber,
        status
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Common", 30, 150),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Category", 30, 165),
            doc.setFont(undefined, "normal").text(`${category}`, 125, 165),
            doc.setFont(undefined, "bold").text("Brand", 30, 180),
            doc.setFont(undefined, "normal").text(`${brand}`, 125, 180),
            doc.setFont(undefined, "bold").text("Type", 30, 195),
            doc.setFont(undefined, "normal").text(`${type}`, 125, 195),
            doc.setFont(undefined, "bold").text("Serial Number", 30, 210),
            doc.setFont(undefined, "normal").text(`${serialNumber}`, 125, 210),
            doc.setFont(undefined, "bold").text("Part Number", 30, 225),
            doc.setFont(undefined, "normal").text(`${partNumber}`, 125, 225),
            doc.setFont(undefined, "bold").text("Status", 30, 240),
            doc.setFont(undefined, "normal").text(`${status}`, 125, 240)
        )
    },

    assetTransmitter: (
        doc,
        frequency,
        frequencyMax,
        txPower,
        bandwidth
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Transmitter", 30, 265),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Frequency", 30, 280),
            doc.setFont(undefined, "normal").text(`${frequency} MHz`, 125, 280),
            doc.setFont(undefined, "bold").text("Range", 30, 295),
            doc.setFont(undefined, "normal").text(`${frequency} To ${frequencyMax} MHz`, 125, 295),
            doc.setFont(undefined, "bold").text("Transmit Power", 30, 310),
            doc.setFont(undefined, "normal").text(`${txPower} W`, 125, 310),
            doc.setFont(undefined, "bold").text("Bandwidth", 30, 325),
            doc.setFont(undefined, "normal").text(`${bandwidth}`, 125, 325)
        )
    },

    assetOwner: (
        doc,
        owner
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Owner", 30, 350),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Radio Owner", 30, 365),
            doc.setFont(undefined, "normal").text(`${owner}`, 125, 365)
        )
    },

    coverageRuler: (
        doc,
        index,
        coverageRuler,
    ) => {
        return (
            doc.setFontSize(12),
            doc.setTextColor("#031952"),
            doc.setFont(undefined, "bold").text("Coverage Ruler", 30, 150),

            doc.setFontSize(10),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text("Latitude", 30, `${index === 1 ? 165 : index * 140}`),
            doc.setFont(undefined, "normal").text(`${coverageRuler.lat}`, 125, `${index === 1 ? 165 : index * 140}`),
            doc.setFont(undefined, "bold").text("Longitude", 30, `${index === 1 ? 180 : index * 155}`),
            doc.setFont(undefined, "normal").text(`${coverageRuler.lon}`, 125, `${index === 1 ? 180 : index * 155}`),
            doc.setFont(undefined, "bold").text("Bearing", 30, `${index === 1 ? 195 : index * 170}`),
            doc.setFont(undefined, "normal").text(`${coverageRuler.bearing}`, 125, `${index === 1 ? 195 : index * 170}`),
            doc.setFont(undefined, "bold").text("Distance", 30, `${index === 1 ? 210 : index * 185}`),
            doc.setFont(undefined, "normal").text(`${coverageRuler.distance}`, 125, `${index === 1 ? 210 : index * 185}`)
        )
    },

    chartDetail: (doc, data) => {
        return (
            doc.setFillColor("#3384FF"),
            doc.rect(320, 170, 27, 10, "F"),
            doc.setFontSize(8),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text(322, 177.5, `${data.datasets[0].data[1]}%`),
            doc.setFontSize(10),
            doc.setFont(undefined, "normal").text(360, 178, "Serviceable"),
            doc.setFillColor("#48B527"),
            doc.rect(320, 195, 27, 10, "F"),
            doc.setFontSize(8),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text(322, 202.5, `${data.datasets[0].data[0]}%`),
            doc.setFontSize(10),
            doc.setFont(undefined, "normal").text(360, 203, "Spare"),
            doc.setFillColor("#FF3A30"),
            doc.rect(320, 220, 27, 10, "F"),
            doc.setFontSize(8),
            doc.setTextColor("#040F0F"),
            doc.setFont(undefined, "bold").text(322, 227.5, `${data.datasets[0].data[2]}%`),
            doc.setFontSize(10),
            doc.setFont(undefined, "normal").text(360, 228, "Unserviceable")
        )
    },

    tableStatus: (doc, type, statusAsset) => {
        autoTable(doc, {
            theme: "grid",
            styles: { textColor: [0, 0, 0], fontSize: 7, overflow: 'linebreak'},
            margin: { left: 30 },
            startY: 170,
            didParseCell(data) {
                if (data.row.index % 2 !== 0) {
                    data.cell.styles.textColor = [0, 0, 0];
                    data.cell.styles.fillColor = '#D3D3D3';
                }
                if (data.row.index === 3) {
                    data.cell.styles.fontStyle = 'bold';
                }
            },
            tableWidth: 150,
            head: [[`${type} Status`, "Jumlah"]],
            body: [
                ["Serviceable", `${statusAsset[1].item}`],
                ["Spare", `${statusAsset[2].item}`],
                ["Unserviceable", `${statusAsset[3].item}`],
                ["Total", `${statusAsset[0].item}`],
            ],
        });

        doc.setTextColor("#040F0F")
        doc.setFontSize(8)
        doc.setFont(undefined, "normal").text(78, 165, `${type} Status Table`)
    },

    coverageImg: (doc, img) => {
        return (
            doc.addImage(img, "PNG", 50, 150, 330, 200),
            doc.addImage(colorIndicator, "PNG", 380, 150, 20, 200)
        )
    },

    coverageImgFO: (doc, img) => {
        return (
            doc.addImage(img, "PNG", 50, 150, 350, 200)
        )
    },

    chartImg: (doc, chartImg) => {
        return (
            doc.addImage(chartImg(), "PNG", 210, 150, 100, 100)
        )
    },

    chartImgOperation: (doc, chartImg) => {
        return (
            doc.addImage(chartImg(), "PNG", 30, 190, 380, 200)
        )
    },

    chartImgRadioLink: (doc, chartImg) => {
        return (
            doc.addImage(chartImg(), "PNG", 50, 370, 350, 120)
        )
    },

    chartImgMesh: (doc, chartImg) => {
        return (
            doc.addImage(chartImg(), "PNG", 65, 313, 50, 30)
        )
    },

    assetImg: (doc, index, img, titlePosition, position1, position2, position3) => {
        return (
            // Title
            doc.setTextColor("#040F0F"),
            doc.setFontSize(12),
            doc.setFont(undefined, "bold").text(titlePosition,  index > 2 ? 330 : 160, `Image ${index + 1}`),

            // Right
            doc.line(position2, index > 2 ? 340 : 170, position2, index > 2 ? 460 : 290),
            // Left
            doc.line(position3, index > 2 ? 340 : 170, position3, index > 2 ? 460 : 290),
            // Top
            doc.line(position2, index > 2 ? 340 : 170, position3, index > 2 ? 340 : 170),
            // Bottom
            doc.line(position2, index > 2 ? 460 : 290, position3, index > 2 ? 460 : 290),

            doc.addImage(img, "PNG", position1, index > 2 ? 350 : 180, 100, 100)
        )
    },

    addPage: (doc) => {
        return doc.addPage()
    },

    savePdf: (doc, name) => {
        return doc.save(`Report ${name}`)
    }
}

export const getNewPositionImage = (arr, n) => Array(n).fill(arr).flat();