import {ACTION_DELETE, ACTION_GET, ACTION_POST, ACTION_PUT, BASE_URL} from "./core";

export const CREATE_NEW_TASK = (body) => {
    const url = `${BASE_URL}/template/task/create`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, body)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const CREATE_TEMPLATE_TASK = (body) => {
    const url = `${BASE_URL}/template/create`;
    return new Promise((resolve, reject) => {
        ACTION_POST(url, body)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const GET_ALL_MASTER_TEMPLATE = (size, page) => {
    const url = `${BASE_URL}/template?size=${size}&page=${page}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export const SEARCH_MASTER_TEMPLATE = (q, page, size) => {
    const url = `${BASE_URL}/template/search?q=${q.toLowerCase()}&page=${page}&size=${size}`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export const GET_ALL_TASK_BY_TEMPLATE_ID = (id) => {
    const url = `${BASE_URL}/template/${id}/task`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const UPDATE_TEMPLATE = (id, body) => {
    const url = `${BASE_URL}/template/${id}`
    return new Promise((resolve, reject) => {
        ACTION_PUT(url, body)
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error)
            })
    })
};

export const DELETE_TEMPLATE = (id) => {
    const url = `${BASE_URL}/template/${id}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const DELETE_TASK_IN_TEMPLATE = (idTemplate, idTask) => {
    const url = `${BASE_URL}/template/task/${idTemplate}/delete/${idTask}`;
    return new Promise((resolve, reject) => {
        ACTION_DELETE(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};


