import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { PulseLoader } from "react-spinners";

// Component
import UserManagement from "./UserManagement";

// Redux
import { selectCurrentUser } from "../../store/user/user.selector";
import { selectIsLoadingSync, selectSyncStatus } from "../../store/global/global.selector";
import {
    handlePullAndSync, handleResetNotifyNotConenctedToBackend,
    handleResetStatusSync,
    handleSync
} from "../../store/global/global.action";

// Utils
import {MAX_TIMEOUT, STATUS_TYPE, USER_MABES} from "../../utils/constant/constants";
import {generalAlert} from "../../utils/notification/notification";

// Asset
import {
    AssetsIcon,
    DashboardIcon,
    DistributionIcon,
    OperationDataIcon,
    ArrowDown,
    ArrowUp,
    Sync
} from "../../assets/svgs";
import { RDCSLogo } from "../../assets/png";


const Navbar = ({ isSidebarOpened }) => {
    const [profile, setProfile] = useState("");
    const [showUserManagement, setShowUserManagement] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();

    const currentUser = useSelector(selectCurrentUser);
    const syncStatus = useSelector(selectSyncStatus);
    const isLoadingSync = useSelector(selectIsLoadingSync);

    const menuRef = useRef();

    useEffect(() => {
        let handler = (e) => {
            if(!menuRef.current.contains(e.target)){
                setShowUserManagement(false);
            }      
        };

        document.addEventListener("mousedown", handler);

        return() =>{
            document.removeEventListener("mousedown", handler);
        }
    }, [menuRef]);

    useEffect(() => {
        updateProfileName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    useEffect(() => {
        if (STATUS_TYPE.NO_STATEMENT !== syncStatus) {
            if (STATUS_TYPE.SUCCESS === syncStatus) {
                generalAlert(`Success`, `Data sync successfully`, `success`, () => {
                    dispatch(handleResetStatusSync());
                    setTimeout(() => {
                        window.location.reload();
                    }, 100)
                });
            } else if (STATUS_TYPE.FAILED === syncStatus) {
                generalAlert(`Failed`, `Data sync failed, maybe you are not connected to the server`, `error`, () => dispatch(handleResetStatusSync()));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [syncStatus])

    const updateProfileName = () => {
        if (currentUser && currentUser.name) {
            setProfile(currentUser.name);
        }
    }

    const handleSyncPressed = () => {
        if (currentUser.siteName === USER_MABES) {
            dispatch(handlePullAndSync());
        } else {
            dispatch(handleSync());
        }

        setTimeout(()=>{
            dispatch(handleResetNotifyNotConenctedToBackend());
        }, MAX_TIMEOUT);
    }

    return (
        <div className="sticky top-0 z-20">
            <nav className="bg-primary h-[52.3px] transition-pl duration-500 ease-in-out select-none">
                <ul className="flex h-full items-center gap-x-3 text-sm font-semibold">
                    <header
                        className={`${isSidebarOpened ? "w-[300px]" : "w-[100px]"} bg-[#031952] transition-width duration-500 ease-in-out py-2.5 text-center`}>
                        <div className="flex flex-col items-center relative">
                            <h1 className="font-normal text-white p-1.5 title-rdcs">RDCS</h1>
                            <img
                                src={RDCSLogo}
                                alt="RDCSLogo"
                                className={`${isSidebarOpened ? "right-[105px]" : "right-[6px]"} w-[10px] absolute transition-right duration-500 ease-in-out`}
                            />
                        </div>
                    </header>

                    <li
                        className={`${
                            location.pathname === "/" ||
                            location.pathname.indexOf("/dashboard") > -1
                                ? "bg-white text-black"
                                : ""
                        }text-white px-3 py-1 rounded-md cursor-pointer transition-all duration-200 ease-linear ml-3`}
                    >
                        <NavLink to="/" className="flex justify-center items-center">
                            <DashboardIcon/>
                            <span className="px-2">Dashboard</span>
                        </NavLink>
                    </li>
                    <li
                        className={`${
                            location.pathname.indexOf("/assetdata") > -1
                                ? "bg-white text-black"
                                : ""
                        } text-white px-3 py-1 rounded-md cursor-pointer transition-all duration-200 ease-linear`}
                    >
                        <NavLink
                            to="/assetdata"
                            className="flex justify-center items-center"
                        >
                            <AssetsIcon/>
                            <span className="px-2">Asset Data</span>
                        </NavLink>
                    </li>
                    <li
                        className={`${
                            location.pathname.indexOf("/distribution") > -1
                                ? "bg-white text-black"
                                : ""
                        } text-white px-3 py-1 rounded-md cursor-pointer transition-all duration-200 ease-linear`}
                    >
                        <NavLink
                            to="/distribution"
                            className="flex justify-center items-center"
                        >
                            <DistributionIcon/>
                            <span className="px-2">Distribution Data</span>
                        </NavLink>
                    </li>
                    <li
                        className={`${
                            location.pathname.indexOf("/operationdata") > -1
                                ? "bg-white text-black"
                                : ""
                        } text-white px-3 py-1 rounded-md cursor-pointer transition-all duration-200 ease-linear`}
                    >
                        <NavLink
                            to="/operationdata"
                            className="flex justify-center items-center"
                        >
                            <OperationDataIcon/>
                            <span className="px-2">Operation Data</span>
                        </NavLink>
                    </li>
                    <div className="flex items-center justify-center gap-x-10 ml-auto">
                        {isLoadingSync && (
                            <div className="opacity-60 mt-2">
                                <PulseLoader color={'white'} size={10} margin={5}/>
                            </div>
                        )}
                        <div className="pr-11 border-r-2 border-white">
                            <li className="hover:cursor-pointer">
                                <button
                                    className="flex items-center"
                                    type="button"
                                    disabled={isLoadingSync}
                                    onClick={handleSyncPressed}>
                                    <Sync/>
                                    <p className="text-green-300">Sync</p>
                                </button>
                            </li>
                        </div>
                        <li className="relative text-white mr-6 cursor-pointer">
                            <div ref={menuRef}>
                                <div
                                    className="flex items-center gap-x-3"
                                    onClick={()=> setShowUserManagement(prevShowUserManagement => !prevShowUserManagement)}
                                >
                                    <span>{profile}</span>
                                    {showUserManagement ? <ArrowUp/> : <ArrowDown/>}
                                </div>

                                {showUserManagement && (
                                    <UserManagement setShowUserManagement={setShowUserManagement} />
                                )}
                            </div>
                        </li>
                    </div>
                </ul>
            </nav>
        </div>
    );
};

export default Navbar;
