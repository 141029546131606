import Cesium, {
    buildModuleUrl,
    CallbackProperty,
    Cartesian2,
    Cartesian3,
    Cartographic,
    Color,
    createOsmBuildings,
    createWorldTerrain,
    CzmlDataSource,
    EllipsoidGeodesic,
    HorizontalOrigin,
    KmlDataSource,
    NearFarScalar,
    ProviderViewModel,
    Rectangle,
    sampleTerrainMostDetailed,
    ScreenSpaceEventType,
    UrlTemplateImageryProvider,
    VerticalOrigin
} from "cesium";
import viewerCesiumNavigationMixin from "@znemz/cesium-navigation";
import BilboardPoint from "../../assets/png/titik simulasi 1.png";
import BilboardPointSelected from "../../assets/png/titik simulasi 2.png";
import BilboardPointSelected2 from "../../assets/png/titik simulasi 13.png";
import {getLatLong} from "./coordinate";
import RXPoint from "../../assets/png/rx.png";
import TXPoint from "../../assets/png/tx.png";
import StarYellow from "../../assets/png/mapyellowstart.png";
import FilterIconWhite from "../../assets/png/whitetower.png";
import ElectronicWorkshop from "../../assets/png/ElectronicWorkshop.png"
import FilterIconRed from "../../assets/png/coloredTower.png";
import {kmToNauticalMiles} from "../calculate/convert";
import {BASE_OSM_SERVER, STATUS_TYPE} from "../constant/constants";

export const radsToDegs = rad => rad * 180 / Math.PI;
export const degsToRads = deg => deg * Math.PI / 180;

const label = {
    font: '20px monospace',
    showBackground: true,
    horizontalOrigin: HorizontalOrigin.CENTER,
    verticalOrigin: VerticalOrigin.CENTER,
    pixelOffset: new Cartesian2(0.0, -10.0),
    pixelOffsetScaleByDistance: new NearFarScalar(
        1.5e2,
        3.0,
        1.5e7,
        0.5
    ),
    disableDepthTestDistance: Number.POSITIVE_INFINITY,
}


export const getDistanceString = (point1, point2, meterOnly = false, detailInformation = false, detail = {}) => {
    const p1Position = Cartographic.fromDegrees(point1.longitude, point1.latitude)
    const p2Position = Cartographic.fromDegrees(point2.longitude, point2.latitude)
    const geodesic = new EllipsoidGeodesic();
    geodesic.setEndPoints(p1Position, p2Position);
    const meters = Math.round(geodesic.surfaceDistance);

    let stringBuilder;
    if (meters >= 1000 && !meterOnly) {
        stringBuilder = `${(kmToNauticalMiles(meters / 1000)).toFixed(1)} NM / ${(meters / 1000).toFixed(1)} KM`;
        // return (kmToNauticalMiles(meters / 1000)).toFixed(1) + ' NM / ' + (meters / 1000).toFixed(1) + ' KM';
    } else {
        stringBuilder = `${meters} m`;
    }

    if (detailInformation) {
        if (detail && detail.latitude && detail.longitude && detail.bearing) {
            // let result = "";
            // const latString = `Lat : ${detail.latitude}`;
            // const lonString = `Lon: ${detail.longitude}`;
            // const bearing = `Bearing : ${detail.bearing}`
            // const distance = `Distance : ${stringBuilder}`
            // result = result.concat(latString, "\n", lonString, "\n", bearing, "\n", distance);
            // stringBuilder = result
        }

    }
    return stringBuilder;
    // return meters + ' m';
}
export const getBearing = (startLat, startLng, destLat, destLng) => {
    startLat = degsToRads(startLat);
    startLng = degsToRads(startLng);
    destLat = degsToRads(destLat);
    destLng = degsToRads(destLng);

    const y = Math.sin(destLng - startLng) * Math.cos(destLat);
    const x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    const brng = radsToDegs(Math.atan2(y, x));
    return ((brng + 360) % 360).toFixed(2) + "\xB0";
}

export const getMidpoint = (point1, point2, fraction) => {
    const scratch = new Cartographic();
    const p1Position = Cartographic.fromDegrees(point1.longitude, point1.latitude, point1.altitude ? point1.altitude : 0)
    const p2Position = Cartographic.fromDegrees(point2.longitude, point2.latitude, point2.altitude ? point2.altitude : 0)
    const geodesic = new EllipsoidGeodesic();
    geodesic.setEndPoints(p1Position, p2Position);
    const midpointCartographic = geodesic.interpolateUsingFraction(
        fraction,
        scratch
    );
    let alt = 0;
    if (point1.altitude && point1.altitude !== 0 &&
        point2.altitude && point2.altitude !== 0) {
        let hst;
        if (point1.altitude > point2.altitude) {
            hst = point1.altitude;
        } else {
            hst = point2.altitude;
        }
        alt = hst - Math.abs((point1.altitude - point2.altitude) / 2);
    }

    return Cartesian3.fromRadians(midpointCartographic.longitude, midpointCartographic.latitude, alt);
}
export const createGoogleMapsImageryProvider = (viewer) => {
    viewer.baseLayerPicker.viewModel.imageryProviderViewModels.push(new ProviderViewModel({
        name: 'Google Map',
        iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/mapboxSatellite.png'),
        tooltip: 'Google Map',
        category: "Other",
        creationFunction: function () {
            return new UrlTemplateImageryProvider({
                url: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
                credit: "Google Satellite",
            });
        }
    }))
};

export const removeImageryProvider = (viewer) => {
    const imageryProviderViewModels = viewer.baseLayerPicker.viewModel.imageryProviderViewModels;
    if (imageryProviderViewModels.length) {
        for (let i = 0; i < imageryProviderViewModels.length; i++) {
            if (imageryProviderViewModels[i].name === 'Earth at night' ||
                imageryProviderViewModels[i].name === 'Blue Marble' ||
                imageryProviderViewModels[i].name === 'Sentinel-2'||
                imageryProviderViewModels[i].name === 'Stamen Watercolor'||
                imageryProviderViewModels[i].name === 'Open­Street­Map') {
                imageryProviderViewModels.splice(i, 1);
                i--;
            }
        }
    }
}
export const removeImageryProviderForTerrain = (viewer) => {
    viewer.baseLayerPicker.viewModel.terrainProviderViewModels.pop()
    // const worldTerrainOptions = viewer.baseLayerPicker.container.getElementsByClassName(
    //     "cesium-baseLayerPicker-item"
    // );
    // const worldTerrain = worldTerrainOptions[worldTerrainOptions.length - 1];
    // worldTerrain.parentNode?.removeChild(worldTerrain);
}

export const activate3DTile = (viewer) => {
    const primitive = createOsmBuildings();
    viewer.scene.primitives.add(primitive);
    return primitive;
};
export const deactivate3DTile = (viewer, primitive) => {
    viewer.scene.primitives.remove(primitive);
};
export const registerOSMOfflineProvider = (viewer) => {
    viewer.baseLayerPicker.viewModel.imageryProviderViewModels.push(new ProviderViewModel({
        name: 'Offline Map',
        iconUrl: buildModuleUrl('Widgets/Images/ImageryProviders/openStreetMap.png'),
        tooltip: 'OpenStreetMap Offline',
        category: "Other",
        creationFunction: function () {
            return new UrlTemplateImageryProvider({
                url: BASE_OSM_SERVER,
                layers: 'osm_layers',
                parameters: {
                    transparent: true,
                    format: 'image/png'
                }
            });
        }
    }))
}

export const removeDoubleClickEvent = (viewer) => {
    viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
}
export const depthTestAgainstTerrain = (viewer) => {
    if (viewer.baseLayerPicker.viewModel.selectedTerrain.name === `Cesium World Terrain`) {
        viewer.scene.globe.depthTestAgainstTerrain = true;
    }
}

export const setHomeView = (viewer) => {
    viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
        (e) => {
            e.cancel = true;
            viewer.scene.camera.flyTo({
                destination: Cartesian3.fromDegrees(117.592137, -11.59893, 4000000),
                orientation: {
                    heading: degsToRads(5),
                    pitch: degsToRads(-75),
                },
            })
        }
    )
}

export const addOption = (viewer) => {
    let options = {};
    options.defaultResetView = Rectangle.fromDegrees(71, 0, 160, 0);
// Only the compass will show on the map
    options.enableCompass = true;
    options.enableZoomControls = true;
    options.enableDistanceLegend = true;
    options.units = 'kilometers' // default is kilometers;
    // options.distanceLabelFormatter = (convertedDistance, units : Units): string => { ... } // custom label formatter
    viewer.extend(viewerCesiumNavigationMixin, options);
    // extend our view by the cesium navigaton mixin
}

export const isDuplicateLocation = (points, viewer, cartecian) => {
    let result = false
    if (points && points.length > 0) {
        const ellipsoid = viewer.scene.globe.ellipsoid
        const cartographic = ellipsoid.cartesianToCartographic(cartecian);
        const longitude = parseFloat(radsToDegs(cartographic.longitude).toFixed(8));
        const latitude = parseFloat(radsToDegs(cartographic.latitude).toFixed(8));

        result = latitude === points[points.length - 1].latitude && longitude === points[points.length - 1].longitude
    }
    return result
}

export const addPointEntity = (viewer, cartesian, color = Color.RED) => {
    const ellipsoid = viewer.scene.globe.ellipsoid

    const cartographic = ellipsoid.cartesianToCartographic(cartesian);
    const longitude = parseFloat(radsToDegs(cartographic.longitude).toFixed(8));
    const latitude = parseFloat(radsToDegs(cartographic.latitude).toFixed(8));

    const entityPoint = viewer.entities.add({
        name: 'Red point',
        position: Cartesian3.fromDegrees(longitude, latitude),
        point: {
            pixelSize: 8,
            color: color
        }
    });

    return {entityPoint, latitude, longitude}
}

export const createEntityPointWithLabel = (viewer, cartesian, textLabel) => {
    return viewer.entities.add({
        name: 'FO Point',
        position: cartesian,
        point: {
            pixelSize: 8,
            color: Color.RED,
            clampToGround: true,
        },
        label: {
            text: `${textLabel}`,
            font: '20px monospace',
            color: Color.RED,
            horizontalOrigin: HorizontalOrigin.LEFT,
            verticalOrigin: VerticalOrigin.BOTTOM,
            pixelOffset: new Cartesian2(2.0, -2.0),
            pixelOffsetScaleByDistance: new NearFarScalar(
                1.5e2,
                3.0,
                1.5e7,
                0.5
            ),
            clampToGround: true,
        },
    });
}

export const createCzmlDataSource = (viewer, rx, tx, anyObstacle = false, id = "RedLine", callBackMannet) => {
    const {txLat, txLon, txAlt} = tx;
    const {rxLat, rxLon, rxAlt} = rx;
    const positions = {
        cartographicDegrees:
            [txLon, txLat, txAlt, rxLon, rxLat, rxAlt]

    };
    const czml = [
        {
            id: "document",
            name: "CZML Geometries: Polyline",
            version: "1.0",
        },
        {
            id,
            name: "Red line clamped to terain",
            polyline: {
                positions,
                material: {
                    solidColor: {
                        color: {
                            rgba: anyObstacle ? [255, 0, 0, 100] : [0, 0, 255, 100],
                        },
                    },
                },
                width: 4,
                clampToGround: false,
            },
            disableDepthTestDistance: 1000
        },
    ]

    const dataSourcePromise = CzmlDataSource.load(czml);
    viewer.dataSources.add(dataSourcePromise)
        .then(() => {
            if (callBackMannet) {
                callBackMannet(STATUS_TYPE.SUCCESS);
            }
        })
        .catch(e => {
            if (callBackMannet) {
                callBackMannet(STATUS_TYPE.FAILED);
            }
        });
    return dataSourcePromise;
}

export const addPolyline = (viewer, point1, point2) => {

    return viewer.entities.add({
        polyline: {
            clampToGround: true,
            material: Color.RED,
            width: 3,
            positions: Cartesian3.fromDegreesArray([
                point1.longitude, point1.latitude,
                point2.longitude, point2.latitude,
            ])
        },
    })
}

export const addPolylineMoreThenTwoPoint = (viewer, points) => {

    return viewer.entities.add({
        polyline: {
            clampToGround: true,
            width: 5,
            material: Color.RED,
            positions: Cartesian3.fromDegreesArray(points)
        },
    })
}

export const entityLabel = (viewer, position, textLabel) => {
    return viewer.entities.add({
        label: textLabel,
        name: 'Distance label',
        position: position,
    })
}

export const addMidLabel = (viewer, point1, point2, text, fraction = 0.5) => {
    label.text = text;
    const midpoint = getMidpoint(point1, point2, fraction);
    return entityLabel(viewer, midpoint, label);
}

export const removeEntityAndDataSource = (viewer) => {
    if (viewer.dataSources && viewer.entities) {
        viewer.dataSources.removeAll();
        viewer.entities.removeAll();
    }
}
export const removeEntityAndDataSourceExcept = (viewer, selectedId, entities) => {
    for (let i = 0; i < entities.content.length; i++) {
        if (entities.content[0].id !== selectedId) {
            viewer.entities.removeById(entities.content[0].id);
        }
    }
}

const modifyValues = d => {
    d.entities.values.forEach(dt => {
        if (dt) {
            if (dt.billboard && dt.billboard.image && dt.billboard.image.setValue) {
                dt.billboard.image.setValue("");
            }
            if (dt.label) {
                dt.label.text.setValue("");
                dt.label.font.setValue("24px Helvetica");
            }
        }
    })
};

export const loadKmlDataSource = (viewer, kmz, showCoverage) => {

    const dataSourceId = viewer.dataSources.add(KmlDataSource.load(kmz, {
        clampToGround: true,
    }));
    dataSourceId
        .then((d) => {
            d.show = showCoverage;
            modifyValues(d);
        })
        .catch(() => {
        })
    return dataSourceId;
}

export const drawEntityAndDataSource = (name, lat, lon, alt, kmz, viewer, id, obj, showCoverage) => {

    if (name && lat && lon && kmz && viewer && id) {
        const position = Cartesian3.fromDegrees(lon, lat, alt ? alt : 250);

        let entityId = viewer.entities.add({
            id,
            show: showCoverage,
            name: name,
            position: position,
            billboard: {
                image: BilboardPointSelected,
                width: 35,
                height: 35,
                disableDepthTestDistance: 1000,
            },
            label: {
                text: `${name}`,
                font: '12px Helvetica',
                showBackground: true,
                show: true,
                fillColor: Color.WHITE,
                horizontalOrigin: HorizontalOrigin.LEFT,
                verticalOrigin: VerticalOrigin.BOTTOM,
                pixelOffset: new Cartesian2(5.0, -5.0),
                pixelOffsetScaleByDistance: new NearFarScalar(
                    1.5e2,
                    3.0,
                    1.5e7,
                    0.5
                ),
                clampToGround: true,
            },
        });

        const dataSourceId = loadKmlDataSource(viewer, kmz, showCoverage);

        obj.coverageStatus = false
        obj.dataSourceId = dataSourceId
        obj.entityId = entityId
    }
    return obj;
}

export const createEntityAndDataSource = (data, viewer, isTerrainActive = false) => {
    const content = data.content;
    for (let i = 0; i < content.length; i++) {
        let name = content[i].data.name
        let lat = content[i].data.transmitter.lat
        let lon = content[i].data.transmitter.lon
        let alt;
        if (isTerrainActive) {
            alt = content[i].data.transmitter.altWithTerrain
        } else {
            alt = content[i].data.transmitter.alt
        }
        // let alt = content[i].data.transmitter.alt !== 0 ? content[i].data.transmitter.alt : 250;
        let kmz = content[i].result.kmz
        let id = content[i].id

        drawEntityAndDataSource(name, lat, lon, alt, kmz, viewer, id, content[i], false);
    }

}

export const changeEntityBillboard = (viewer, entity, oldEntity = null) => {
    if (viewer && entity && entity.entityId) {
        entity.entityId.billboard.image = BilboardPointSelected2;
        if (oldEntity && oldEntity.entityId) {
            oldEntity.entityId.billboard.image = BilboardPoint;
        }
    }
}

export const drawEntity = (viewer, object) => {
    if (viewer && object && object.content) {
        const newObj = {...object};
        const content = newObj.content;
        for (let i = 0; i < content.length; i++) {
            const {id, siteName, lat, lon} = content[i];
            if (lat && lon) {
                content[i].entityId = viewer.entities.add({
                    id,
                    name: siteName,
                    position: Cartesian3.fromDegrees(lon, lat, 0),
                    label: {
                        text: `${siteName}`,
                        font: '16px Helvetica',
                        showBackground: true,
                        show: true,
                        fillColor: Color.WHITE,
                        horizontalOrigin: HorizontalOrigin.LEFT,
                        verticalOrigin: VerticalOrigin.BOTTOM,
                        pixelOffset: new Cartesian2(5.0, -5.0),
                        pixelOffsetScaleByDistance: new NearFarScalar(
                            1.5e2,
                            3.0,
                            1.5e7,
                            0.5
                        ),
                        clampToGround: true,
                    },
                    billboard: {
                        image: BilboardPoint,
                        width: 20,
                        height: 20,
                        disableDepthTestDistance: 10000,
                        clampToGround: true,
                    },
                });
            }
        }
        return newObj;
    }
}


export const showAllCoverage = (data, showOrHide) => {
    const content = data.content;
    const newContent = [...content];
    for (let i = 0; i < newContent.length; i++) {
        newContent[i].entityId.show = showOrHide;
        const dataSourceId = newContent[i].dataSourceId;
        if (dataSourceId) {
            dataSourceId
                .then((d) => {
                    d.show = showOrHide
                    modifyValues(d);
                })
                .catch(() => {
                });
            newContent[i].coverageStatus = showOrHide
        }
    }
    return {content: newContent}
}
export const showAllLabel = (data, showOrHide) => {
    const content = data.content;
    const newContent = [...content];
    for (let i = 0; i < newContent.length; i++) {
        const entityId = newContent[i].entityId;
        entityId.label.show = showOrHide
    }
    return {content: newContent}
}

export const flyToIndnesia = (viewer, is2D = false) => {
    if (is2D) {
        viewer.camera.flyTo({
            // destination: Cartesian3.fromDegrees(116.92137, 0.7893, 5700000),
            destination: Cartesian3.fromDegrees(117.592137, -11.59893, 4000000),
            orientation: {
                heading: degsToRads(5),
                pitch: degsToRads(-75),
            },
        });
    } else {
        viewer.camera.flyTo({
            // destination: Cartesian3.fromDegrees(116.92137, 0.7893, 5700000),
            destination: Cartesian3.fromDegrees(117.592137, -11.59893, 4000000),
            orientation: {
                heading: degsToRads(5),
                pitch: degsToRads(-75),
            },
        });
    }
}

export const handleShowOrHideCoverage = (dataSourceId, showCoverage) => {
    if (dataSourceId) {
        dataSourceId
            .then((d) => {
                if (d) {
                    d.show = showCoverage
                }
            })
            .catch(() => {
            });
    }
}

export const showCoverageById = (data, anEntity) => {
    const content = data.content;
    const newContent = [...content]
    const {id, showCoverage} = anEntity;
    for (let i = 0; i < newContent.length; i++) {
        if (newContent[i].id === id) {
            const dataSourceId = newContent[i].dataSourceId;
            handleShowOrHideCoverage(dataSourceId, showCoverage);
            newContent[i].coverageStatus = showCoverage
            newContent[i].entityId.show = showCoverage;
            break;
        }
    }
    return {content: newContent}
}

export const getPositionMouse = (e, viewer) => {
    let mousePosition = new Cartesian2(e.clientX, e.clientY);

    const cartesian = viewer.camera.pickEllipsoid(mousePosition, viewer.scene.globe.ellipsoid);
    return getLatLong(cartesian, viewer.scene.globe.ellipsoid, Cesium)
}

export const clearEntity = (viewer, billboard) => {
    viewer.entities.remove(billboard);
}

export const createPolylinePointAndLabel = (viewer, id, cartesian, cartesianDegreesArray, label) => {
    return viewer.entities.add({
        id: `${id}ruler`,
        position: cartesian,
        point: {
            pixelSize: 8,
            color: Color.RED,
            clampToGround: true,
        },
        polyline: {
            clampToGround: true,
            material: Color.RED,
            width: 3,
            positions: cartesianDegreesArray
        },
        label: {
            text: `${label}`,
            font: '12px Helvetica',
            showBackground: true,
            show: true,
            fillColor: Color.WHITE,
            horizontalOrigin: HorizontalOrigin.LEFT,
            verticalOrigin: VerticalOrigin.BOTTOM,
            pixelOffset: new Cartesian2(5.0, -5.0),
            pixelOffsetScaleByDistance: new NearFarScalar(
                1.5e2,
                3.0,
                1.5e7,
                0.5
            ),
            clampToGround: true,
        },
    });
}

export const createBillboardEntity = (viewer, cartesian, id, removeId = true,
                                      billLabel = "", showLabel = false) => {
    let mannetId = false;
    if (removeId) {
        viewer.entities.removeById(id);
    } else { // only for mannet: split mannet id
        mannetId = true;
    }

    return viewer.entities.add({
        id: id,
        position: cartesian,
        billboard: {
            image: id === "rx" ? RXPoint : mannetId ? StarYellow : TXPoint,
            scale: 0.5,
            scaleByDistance: new NearFarScalar(1.5e2, 2.0, 1.5e7, 0.5),
            verticalOrigin: VerticalOrigin.BOTTOM,
            disableDepthTestDistance: 100,
        },
        label: {
            text: `${billLabel}`,
            font: '14px Helvetica',
            showBackground: true,
            show: showLabel,
            fillColor: Color.WHITE,
            horizontalOrigin: HorizontalOrigin.LEFT,
            verticalOrigin: VerticalOrigin.BOTTOM,
            pixelOffset: new Cartesian2(5.0, -5.0),
            pixelOffsetScaleByDistance: new NearFarScalar(
                1.5e2,
                3.0,
                1.5e7,
                0.5
            ),
            clampToGround: true,
        },
    });
}

export const drawTower = (viewer, data, isMiliter, isWorkshop = false) => {
    const newArr = [...data];
    for (let i = 0; i < newArr.length; i++) {
        const {id, lat, lon, name} = newArr[i];
        if (lat && lon) {
            newArr[i].entityId = viewer.entities.add({
                id,
                name: name,
                position: Cartesian3.fromDegrees(lon, lat, 0),
                label: {
                    text: `${name}`,
                    font: '16 Helvetica',
                    showBackground: true,
                    show: true,
                    fillColor: Color.WHITE,
                    horizontalOrigin: HorizontalOrigin.LEFT,
                    verticalOrigin: VerticalOrigin.BOTTOM,
                    pixelOffset: new Cartesian2(5.0, -5.0),
                    pixelOffsetScaleByDistance: new NearFarScalar(
                        1.5e2,
                        3.0,
                        1.5e7,
                        0.5
                    ),
                    clampToGround: true,
                },
                billboard: {
                    image: isMiliter ? FilterIconRed : isWorkshop ? ElectronicWorkshop : FilterIconWhite,
                    width: 25,
                    height: 30,
                    disableDepthTestDistance: 10000,
                    clampToGround: true,
                },
            });
        }
    }
    return newArr;
}

export const removeEntityById = (viewer, data) => {
    if (!!viewer && !!data) {
        for (let i = 0; i < data.length; i++) {
            const {id} = data[i];
            viewer.entities.removeById(id);
        }
    }
}

export const takeSnap = (viewer) => {
    viewer.render();
    const image = viewer.canvas.toDataURL();
    return image;
}

export const getTerrainHeightByDegree = (lat, lon) => {
    const terrainProvider = createWorldTerrain();
    const positions = [
        Cartographic.fromDegrees(lon, lat),
    ];
    return sampleTerrainMostDetailed(terrainProvider, positions)
        .then((updatedPositions) => updatedPositions[0].height)
        .catch(() => 0);
}
export const getTerrainHeightByDegreeWithSeqNumber = (lat, lon, seqNumber) => {
    const terrainProvider = createWorldTerrain();
    const positions = [
        Cartographic.fromDegrees(lon, lat),
    ];
    return sampleTerrainMostDetailed(terrainProvider, positions)
        .then((updatedPositions) => {
            return {seqNumber, height: updatedPositions[0].height}
        })
        .catch(() => 0);
}
export const getTerrainHeightByCartographic = (cartographic) => {
    const terrainProvider = createWorldTerrain();
    const positions = [
        cartographic,
    ];
    return sampleTerrainMostDetailed(terrainProvider, positions)
        .then((updatedPositions) => updatedPositions[0].height)
        .catch(() => 0);
}

export const labelPosition = (viewer) => {
    return viewer.entities.add({
        id: 'ms',
        label: {
            show: true,
            font: '16px monospace',
            showBackground: true,
            horizontalOrigin: HorizontalOrigin.LEFT,
            verticalOrigin: VerticalOrigin.BOTTOM,
            pixelOffset: new Cartesian2(5.0, -5.0),
            pixelOffsetScaleByDistance: new NearFarScalar(
                1.5e2,
                3.0,
                1.5e7,
                0.5
            ),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
        }
    });
}

export const createCircleEntity = (id, viewer, lat, lon, radiusInMeters = 0.1) => {
    const center = Cartesian3.fromDegrees(lon, lat);

    viewer.entities.removeById(`${id}c`);

    return viewer.entities.add({
        id: `${id}c`,
        position: center,
        ellipse: {
            clampToGround: true,
            semiMajorAxis: new CallbackProperty(function () {
                return radiusInMeters;
            }, false),
            semiMinorAxis: new CallbackProperty(function () {
                return radiusInMeters;
            }, false),
            material: new Color(1.0, 0.0, 0.0, 0.2)
        }
    })
}
export const removeInCircleEntity = (entity, viewer) => {
    viewer.entities.remove(entity);
}

