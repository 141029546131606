export const OP_ACTION_TYPES = {
    GET_ALL_SIMULATION : `op/GET_ALL_SIMULATION`,
    GET_OP_BY_ID : `op/GET_OP_BY_ID`,
    CREATE_OP_ACTUAL : `op/CREATE_OP_ACTUAL`,
    CLEAR_ALL_STATE : `op/CLEAR_ALL_STATE`,
    CLEAR_AN_OP_DATA : `op/CLEAR_AN_OP_DATA`,
    POST_ACTUAL_SUCCESS : `op/POST_ACTUAL_SUCCESS`,
    ACTUAL_DTO_DATES_RESPONSE : `op/ACTUAL_DTO_DATES_RESPONSE`,
    SHOW_CURRENT_SITE : `op/SHOW_CURRENT_SITE`,
    REMOVE_ACTUAL_STATUS : `op/REMOVE_ACTUAL_STATUS`,
    PAGE_NUMBER : `op/PAGE_NUMBER`,
    NOTIF_PAGE_NUMBER_FROM_OP_PAGE : `op/NOTIF_PAGE_NUMBER_FROM_OP_PAGE`,
    REMOVE_OP_STATUS : `op/REMOVE_OP_STATUS`,
}