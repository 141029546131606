import React from "react";
import {useLocation} from "react-router-dom";

const Drawer = ({
                    children,
                    isOpen,
                    selectedData,
                    setSelectedData,
                    setSelectedId,
                    setIsOpen,
                    titleName,
                }) => {
    const location = useLocation();

    const handleClose = () => {
        if (!selectedData) {
            setIsOpen(false);
        }
        if (setSelectedId) {
            setSelectedId(null);
        }
        if (setSelectedData) {
            setSelectedData(null);
        }
    };

    return (
        <div
            className={
                "fixed bottom-0 flex flex-col bg-tertiary bg-clip-padding shadow-sm outline-none text-gray-700 top-0 right-0 border-none overflow-hidden z-10 mt-[40px] max-w-[424px]" +
                (isOpen
                    ? " transform translate-x-0 transition duration-300 "
                    : " transform translate-x-full transition duration-300 ")
            }
        >
            <section className="w-full right-0 absolute bg-tertiary h-full shadow-xl p-4 overflow-hidden">
                <article
                    className={`${
                        location.pathname === "/" ? "pb-14" : "pb-10"
                    } relative w-full flex flex-col space-y-1 h-full overflow-hidden`}
                >
                    <div className="flex flex-row items-center justify-start px-4 text-white text-center">
                        <button
                            type="button"
                            className="btn-close box-content w-4  my-5 mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            onClick={handleClose}
                        >
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="white"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L13.7071 1.70711ZM0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L0.292893 12.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L12.2929 13.7071ZM12.2929 0.292893L0.292893 12.2929L1.70711 13.7071L13.7071 1.70711L12.2929 0.292893ZM0.292893 1.70711L12.2929 13.7071L13.7071 12.2929L1.70711 0.292893L0.292893 1.70711Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                        <header className="text-md text-white text-center mx-auto">
                            {titleName}
                        </header>
                    </div>
                    {children}
                </article>
            </section>

            <section
                className=" w-screen h-full cursor-pointer "
                onClick={() => {
                    setIsOpen(false);
                }}
            ></section>
        </div>
    );
};

export default Drawer;
