import {ACTION_GET, BASE_URL,} from "./core";

export const GET_GPS_DATA = () => {
    let url = `${BASE_URL}/gps`;
    return new Promise((resolve, reject) => {
        ACTION_GET(url)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

