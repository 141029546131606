import {createSelector} from "reselect";
import {getDistanceActualLabel, getDistanceSimulationLabel, getHighLabel} from "./op.utils";
import {kmToNauticalMiles} from "../../utils/calculate/convert";

const selectOpReducer = (state) => state.opReducer;

export const selectNotifFromOpPage = createSelector(
    [selectOpReducer],
    (state) => state.notifFromOpPage
)
export const selectPageNumber = createSelector(
    [selectOpReducer],
    (state) => state.pageNumber
)
export const selectRemoveActualStatus = createSelector(
    [selectOpReducer],
    (state) => state.removeActualStatus
)
export const selectRemoveOPStatus = createSelector(
    [selectOpReducer],
    (state) => state.removeOPStatus
)
export const selectAllOpData = createSelector(
    [selectOpReducer],
    (state) => state.allOpData
)
export const selectCurrentSite = createSelector(
    [selectOpReducer],
    (state) => state.currentSite
)

export const selectActualDateResponse = createSelector(
    [selectOpReducer],
    (state) => {
        if (state) {
            return state.actualDtosDateResponse;
        } else {
            return null
        }
    }
)
export const selectTotalPage = createSelector(
    [selectAllOpData],
    (allOpData) => {
        if (allOpData) {
            return allOpData.totalPages;
        } else {
            return null
        }
    }
)
export const selectSizePage = createSelector(
    [selectAllOpData],
    (allOpData) => {
        if (allOpData) {
            return allOpData.size;
        } else {
            return null
        }
    }
)
export const selectDataTable = createSelector(
    [selectAllOpData],
    (allOpData) => {
        if (allOpData && allOpData.content.length > 0) {
            return allOpData.content; // [{id, name}]
        } else {
            return null;
        }
    }
)
export const selectAnOpData = createSelector(
    [selectOpReducer],
    (state) => state.anOpData
)

export const selectHeaderColumn = createSelector(
    [selectOpReducer],
    (state) => state.headerColumn
)

export const selectFilterDate = createSelector(
    [selectAnOpData],
    (anOpData) => {
        if (anOpData && anOpData.actualDtos && anOpData.actualDtos.length > 0) {
            const dateData = [...new Map(anOpData.actualDtos.map(v => [v.date, v])).values()]
            const dateArr = [];
            dateData.forEach(d => dateArr.push({value: d.date, label: d.date}));
            return dateArr;
        } else {
            return null;
        }
    }
)



export const selectHighLabel = createSelector(
    [selectAnOpData],
    (anOpData) => {
        if (anOpData && anOpData.simulationDtos) {
            return getHighLabel(anOpData);
        } else {
            return null
        }
    }
)

export const selectDistanceSimulationLabel = createSelector(
    [selectAnOpData],
    (anOpData) => {
        if (anOpData) {
            return getDistanceSimulationLabel(anOpData);
        } else {
            return null
        }
    }
)


export const selectDistanceActualLabel = createSelector(
    [selectAnOpData],
    (anOpData) => {
        if (anOpData) {
            return getDistanceActualLabel(anOpData);
        } else {
            return null
        }
    }
)
export const selectDataTableSim = createSelector(
    [selectAnOpData],
    (anOpData) => {
        if (anOpData) {
            let data = []
            const simData = anOpData.simulationDtos;
            for (let i = 0; i < simData.length; i++) {
                data.push({
                    id: simData[i].id,
                    altitude: simData[i].high,
                    distance: kmToNauticalMiles(simData[i].distance / 2),
                });
            }
            return data;
        } else {
            return null;
        }
    }
)
export const selectDataTableAct = createSelector(
    [selectAnOpData],
    (anOpData) => {
        if (anOpData) {
            let data = []
            const simData = anOpData.actualDtos;
            for (let i = 0; i < simData.length; i++) {
                data.push({
                    id: simData[i].id,
                    altitude: simData[i].high,
                    distance: simData[i].distance
                });
            }
            return data;
        } else {
            return null;
        }
    }
)

export const selectHeaderSim = createSelector(
    [selectOpReducer],
    (state) => state.headerColSimPageDetail
)

export const selectIsCreateActualSuccess = createSelector(
    [selectOpReducer],
    (state) => state.postActualSuccess
)