import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";

import {
    handleCreateActual,
    handleGetActualDto, handleNotifPageNumber,
    handleRemoveActualByOPId,
    handleResetActionStatus
} from "../../store/op/op.action";
import {useDispatch, useSelector} from "react-redux";
import {
    selectActualDateResponse,
    selectAnOpData,
    selectDataTableAct,
    selectDistanceActualLabel,
    selectDistanceSimulationLabel,
    selectHeaderSim,
    selectHighLabel,
    selectIsCreateActualSuccess,
    selectRemoveActualStatus
} from "../../store/op/op.selector";
import {
    ALERT_ICON_FAILED,
    ALERT_ICON_SUCCESS,
    ALERT_TEXT_REMOVE_FAILED,
    ALERT_TEXT_REMOVE_SUCCESS,
    ALERT_TITLE_FAILED,
    ALERT_TITLE_SUCCESS,
    CONFIRMATION_ICON_REMOVE,
    CONFIRMATION_TITLE_REMOVE,
    STATUS_TYPE
} from "../../utils/constant/constants";

import {generalAlert, generalConfirmation} from "../../utils/notification/notification";

import ChartOperation from "../../components/chart/ChartOperation";
import HeaderPage from "../../components/header/HeaderPage";
import Table from "../../components/table/Table";

const OperationDataActual = () => {
    const [chartLabel, setChartLabel] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [dataTable, setDatatable] = useState([]);
    const [done, setDone] = useState(false);
    const [disableCalculate, setDisableCalucate] = useState(false);
    const [disableApplyBtn, setDisableApplyBtn] = useState(false);

    const navigate = useNavigate();
    const id = useParams();
    const dispatch = useDispatch();

    const anOpData = useSelector(selectAnOpData);
    const heighLabel = useSelector(selectHighLabel);
    const distanceSimLabel = useSelector(selectDistanceSimulationLabel);
    const distanceActLabel = useSelector(selectDistanceActualLabel);
    const headerColSim = useSelector(selectHeaderSim);
    const isCreateActualSuccess = useSelector(selectIsCreateActualSuccess);
    const actualDateResponse = useSelector(selectActualDateResponse);
    const removeActualStatus = useSelector(selectRemoveActualStatus);
    const dataTableAct = useSelector(selectDataTableAct);

    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm(({
        defaultValues: {
            altitude: "",
            distance: "",
        }
    }));

    useEffect(() => {
        dispatch(handleGetActualDto(id.id)); // get actual dto get lates date if any, or display just sumulation data
        return () => {
            setDisableCalucate(false);
            setDone(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (dataTableAct) {
            const newDataTable = dataTableAct.map(item => {
                const { id, ...newItem } = item;
                return newItem;
            });

            setDatatable(newDataTable);
        }
    }, [dataTableAct])

    useEffect(() => {
        if (STATUS_TYPE.NO_STATEMENT !== removeActualStatus) {
            if (STATUS_TYPE.SUCCESS === removeActualStatus) {
                generalAlert(ALERT_TITLE_SUCCESS, ALERT_TEXT_REMOVE_SUCCESS, ALERT_ICON_SUCCESS, () => dispatch(handleResetActionStatus()))
                setDisableApplyBtn(false);
                dispatch(handleGetActualDto(id.id));
            } else if (STATUS_TYPE.FAILED === removeActualStatus) {
                generalAlert(ALERT_TITLE_FAILED, ALERT_TEXT_REMOVE_FAILED, ALERT_ICON_FAILED, () => dispatch(handleResetActionStatus()))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeActualStatus])

    useEffect(() => {
        if (anOpData) {
            setChartLabel(heighLabel)
            let dataArr = []
            if (distanceSimLabel) {
                dataArr.push(distanceSimLabel)
            }
            if (distanceActLabel) {
                dataArr.push(distanceActLabel)
            }
            setChartData(dataArr)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anOpData])

    useEffect(() => {
        if (isCreateActualSuccess) {
            dispatch(handleGetActualDto(id.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreateActualSuccess])


    useEffect(() => {
        checkTodayDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualDateResponse])

    useEffect(() => {
        if (!disableCalculate && heighLabel) {
            const dataTableLength = dataTable.length;
            const cpHeigh = [...heighLabel];
            cpHeigh.pop();
            cpHeigh.sort((a, b) => a - b);

            for (let i = 0; i < cpHeigh.length; i++) {
                if (i === dataTableLength) {
                    setValue("altitude", cpHeigh[i]);
                }
            }
            if (dataTableLength === cpHeigh.length) {
                setDisableApplyBtn(true);
                setDisableCalucate(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableCalculate, dataTable, heighLabel]);

    const checkTodayDate = () => {
        if (actualDateResponse) {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();
            const todayString = `${yyyy}-${mm}-${dd}`;

            if (actualDateResponse[actualDateResponse.length - 1] === todayString) {
                setDisableCalucate(true);
            } else {
                setDisableCalucate(false);
            }
        } else {
            setDisableCalucate(false);
        }
    };

    const handleAppy = (data) => {
        setDatatable([...dataTable, data]);
        reset();
    }

    const handleOnClick = () => {
        if (!disableCalculate) {
            if (dataTable.length > 0) {
                let modifyDataTable = [];
                for (let i = 0; i < dataTable.length; i++) {
                    modifyDataTable.push({
                        high: dataTable[i].altitude,
                        distance: dataTable[i].distance,
                    })
                }
                let body = {
                    id: id.id,
                    actualDtos: modifyDataTable
                }
                dispatch(handleCreateActual(body));
                setDone(true);
            }
        } else {
            generalConfirmation(
                CONFIRMATION_TITLE_REMOVE,
                `Are you sure want to remove actual data`,
                CONFIRMATION_ICON_REMOVE,
                () => {
                    dispatch(handleRemoveActualByOPId(anOpData.id));
                    setDatatable([]);
                    reset();
                    setDone(false);
                });
        }
    }

    const handleDone = () => {
        dispatch(handleNotifPageNumber(true));
        navigate("/operationdata");
    }

    const handleCancelOrClearData = () => {
        if (dataTable.length === 0) { // Cancel
            dispatch(handleNotifPageNumber(true));
            navigate("/operationdata");
        } else { // Clear Data
            setDisableCalucate(false);
            setDisableApplyBtn(false);
            setDatatable([]);
            reset();
        }
    }

    return (
        <div className="bg-tertiary min-h-screen px-16 py-4">
            <HeaderPage title="Add Actual Data"/>

            <div
                className="flex gap-x-3 items-center text-white text-2xl w-fit cursor-pointer"
                onClick={() => {
                    dispatch(handleNotifPageNumber(true));
                    navigate(-1)
                }}
            >
                <button
                    type="button"
                    className="btn btn-sm btn-circle"
                >
                    &#10094;
                </button>
                <span>Back</span>
            </div>

            <div className="flex mt-10 gap-x-16">
                <div className="w-[70%] mt-10">
                    <div className="bg-[#F8F8F8] rounded-lg pr-10 pl-5 py-5">
                        {chartLabel && chartData &&
                            chartLabel.length > 0 && chartData.length > 0 && (
                                <ChartOperation chartLabel={chartLabel} chartData={chartData}/>
                            )}
                    </div>
                </div>

                <div className="w-[40%] mt-10">
                    <div className="flex flex-col mb-5">
                        <label htmlFor="altitude" className="font-bold text-white mb-1">Altitude</label>
                        <input
                            {...register("altitude", {
                                required: "Required!",
                                min: 0,
                                pattern: {
                                    value: /^\S+/,
                                    message: "Entered value cant start or contain only white spacing"
                                },
                            })}
                            disabled={true}
                            type="number"
                            name="altitude"
                            placeholder={errors?.altitude ? errors?.altitude?.message : "Input altitude here"}
                            className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.altitude ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="distance" className="font-bold text-white mb-1">Distance</label>
                        <input
                            {...register("distance", {
                                required: disableApplyBtn ? false : "Required!",
                                min: 0,
                                pattern: {
                                    value: /^\S+/,
                                    message: "Entered value cant start or contain only white spacing"
                                },
                            })}
                            type="number"
                            name="distance"
                            placeholder={errors?.distance ? errors?.distance?.message : "Input distance here"}
                            className={`input input-bordered w-full text-black text-sm border-2 focus:border-purple-500 ${errors?.distance ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                        />
                    </div>
                    <div className="flex gap-x-4 my-7 justify-end">
                        <button
                            type="button"
                            onClick={handleSubmit(handleAppy)}
                            className="btn btn-primary text-white text-xs px-7"
                            disabled={disableCalculate || disableApplyBtn}
                        >
                            Apply
                        </button>
                    </div>

                    <div className="max-h-[240px] overflow-y-auto mt-10">
                        <Table
                            data={dataTable}
                            column={headerColSim}
                            action={false}
                        />
                    </div>
                    <div className="flex gap-x-4 mt-7 justify-end">
                        {done ? (
                            <>
                                <button
                                    type="button"
                                    onClick={handleDone}
                                    className="btn btn-outline text-white text-xs px-7">
                                    Done
                                </button>
                                <button
                                    type="button"
                                    onClick={handleOnClick}
                                    className={`${disableCalculate ? "btn-error" : "btn-primary"} btn text-white text-xs px-7`}
                                >
                                    {disableCalculate ? "Remove actual data" : "Calculate"}
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    type="button"
                                    onClick={handleCancelOrClearData}
                                    disabled={disableCalculate}
                                    className="btn btn-outline text-white text-xs px-7">
                                    {dataTable.length === 0 ? `Cancel` : `Clear Data`}
                                </button>
                                <button
                                    type="button"
                                    onClick={handleOnClick}
                                    className={`${disableCalculate ? "btn-error" : "btn-primary"} btn text-white text-xs px-7`}
                                >
                                    {disableCalculate ? "Remove actual data" : "Calculate"}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OperationDataActual;