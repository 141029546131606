import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";

import HeaderPage from "../../components/header/HeaderPage";
import EyeIcon from "../../assets/icons/hide.png";
import EyeHideIcon from "../../assets/icons/view.png";
import {handleCreateUser, handleResetCreatedUserData} from "../../store/user/user.action";
import {selectCreatedUser, selectCurrentUser} from "../../store/user/user.selector";
import { generalAlert } from "../../utils/notification/notification";
import { ALERT_ICON_FAILED, ALERT_ICON_SUCCESS, ALERT_TITLE_FAILED, ALERT_TITLE_SUCCESS } from "../../utils/constant/constants";

const CreateUser = () => {
    const createdUser = useSelector(selectCreatedUser);
    const currentUser = useSelector(selectCurrentUser);
    const [show, setShow] = useState(false);

    const dispatch = useDispatch();

    const {register, handleSubmit, reset, formState: { errors }} = useForm(({
        defaultValues: {
            name: "",
            nrp: "",
            email: "",
            password: "",
            role: "2"
        }
    }));

    useEffect(() => {

        return () => {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (createdUser === "ERROR") {
            generalAlert(ALERT_TITLE_FAILED, `Failed to Create User`, ALERT_ICON_FAILED, () => {
                reset();
                dispatch(handleResetCreatedUserData());
            })
        } else if (createdUser.id) {
            generalAlert(ALERT_TITLE_SUCCESS, `User ${createdUser.email} Has Been Created`, ALERT_ICON_SUCCESS, () => {
                reset();
                dispatch(handleResetCreatedUserData());
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createdUser])

    const handleSave = (data) => {
        dispatch(handleResetCreatedUserData());
        
        const payload = {...data};
        if (currentUser && currentUser.siteId) {
            payload.siteId = Number(currentUser.siteId);
            dispatch(handleCreateUser(payload));
        }
    }

    const handleClear = (e) => {
        e.preventDefault();
        reset();
    }

    return (
        <div className="bg-tertiary min-h-screen px-48 py-4">
            <HeaderPage title="Create User"/>

            <div className="flex mt-10 gap-x-16">
                <div className="w-[60%] mt-10">
                    <form>
                        <div>
                            <label className="text-white font-bold" htmlFor="name">
                                Name
                            </label>
                            <input
                                {...register("name", {
                                    required: "Required!",
                                    pattern: {
                                        value: /^\S+/,
                                        message: "Entered value cant start or contain only white spacing"
                                    },
                                })}
                                pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                id="name"
                                type="text"
                                className={`input input-bordered w-full text-black text-sm mt-1 mb-4 border-2 focus:border-purple-500 ${errors?.name ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                placeholder={errors?.name?.message}
                            />
                        </div>

                        <div>
                            <label className="text-white font-bold" htmlFor="email">
                                Username
                            </label>
                            <input
                                {...register("email", {
                                    required: "Required!",
                                    pattern: {
                                        value: /^\S+/,
                                        message: "Entered value cant start or contain only white spacing"
                                    },
                                })}
                                pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                id="email"
                                type="text"
                                className={`input input-bordered w-full text-black text-sm mt-1 mb-4 border-2 focus:border-purple-500 ${errors?.email ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                placeholder={errors?.email?.message}
                            />
                        </div>

                        <div>
                            <label className="text-white font-bold" htmlFor="name">
                                NRP
                            </label>
                            <input
                                {...register("nrp", {
                                    required: "Required!",
                                    pattern: {
                                        value: /^\S+/,
                                        message: "Entered value cant start or contain only white spacing"
                                    },
                                })}
                                pattern="[^\[\]\{\}\^&#\?\\\|<>]+"
                                title="Entered value cant contain these symbols [, ], {, }, ^, &, #, ?, \, |, <, >"
                                id="nrp"
                                type="text"
                                className={`input input-bordered w-full text-black text-sm mt-1 mb-4 border-2 focus:border-purple-500 ${errors?.nrp ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                placeholder={errors?.nrp?.message}
                            />
                        </div>

                        <div>
                            <label className="text-white font-bold">
                                Password
                            </label>
                            <div className="flex">
                                <input
                                    {...register("password", {
                                        required: "Required!",
                                        pattern: {
                                            value: /^\S+/,
                                            message: "Entered value cant start or contain only white spacing"
                                        },
                                    })}
                                    id="password"
                                    type={!show ? "password" : "text"}
                                    className={`input input-bordered w-full text-black text-sm mt-1 mb-4 border-2 focus:border-purple-500 mr-[-34px] ${errors?.password ? "outline-1 focus:outline-red-500 border-0 focus:border-none" : ""}`}
                                    placeholder={errors?.password?.message}
                                />
                                <div
                                    className="cursor-pointer mt-4"
                                    onClick={() => setShow(!show)}
                                >
                                    <img
                                        src={show ? EyeIcon : EyeHideIcon}
                                        alt="eye"
                                        width={24}
                                        height={24}
                                    />
                                </div>
                            </div>
                        </div>

                        <div>
                            <label className="text-white font-bold">
                                Site
                            </label>
                            <div className="flex">
                                <select
                                    disabled
                                    className="select select-bordered w-full bg-white text-black font-thin mt-1 mb-4 border-2 focus:border-purple-500"
                                >
                                    <option selected>{currentUser && currentUser.siteName}</option>
                                </select>
                            </div>
                        </div>

                        <div className="flex gap-x-4 mt-5">
                            <button className="btn btn-outline text-white"
                                    onClick={handleClear}>Clear
                            </button>
                            <button className="btn btn-primary text-white px-7"
                                    onClick={handleSubmit(handleSave)}>
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateUser;
