import {GET_GPS_DATA} from "../../services/gps";
import {createAction} from "../../utils/reducer/reducerUtil";
import {GPS_ACTION_TYPES} from "./gps.types";
import {AREA_ACTION_TYPES} from "../area/area.types";

export const handleFetchGpsData = () => dispatch => {
    GET_GPS_DATA()
        .then((res) => {
            dispatch(createAction(GPS_ACTION_TYPES.GET_DATA_GPS, res));
        })
        .catch((e) => {
            dispatch(createAction(AREA_ACTION_TYPES.POST_FAILED, "Failed to get current position"));
        })
};
