import {EMPTY_STRING, NO_STATEMENT, TEMPLATE_ACTION_TYPES} from "./template.types";

export const INITIAL_STATE = {
    allMasterTemplate: null, // {content: []}
    taskByTemplateId: null, // [{id, name}]
    updateTaskByTemplateId: null,
    newTask: null, // {id,name}
    createStatus: EMPTY_STRING,
    updateStatus: EMPTY_STRING,
    removeStatus: NO_STATEMENT,
}

export const templateReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case TEMPLATE_ACTION_TYPES.ACTION_CREATE_STATUS_TEMPLATE:
            return {...state, createStatus: payload}
        case TEMPLATE_ACTION_TYPES.ACTION_UPDATE_STATUS_TEMPLATE:
            return {...state, updateStatus: payload}
        case TEMPLATE_ACTION_TYPES.ACTION_REMOVE_STATUS_TEMPLATE:
            return {...state, removeStatus: payload}
        case TEMPLATE_ACTION_TYPES.GET_ALL_MASTER_TEMPLATE:
            return {...state, allMasterTemplate: payload}
        case TEMPLATE_ACTION_TYPES.CLEAR_ALL_MASTER_TEMPLATE:
            return {...state, allMasterTemplate: null}
        case TEMPLATE_ACTION_TYPES.GET_ALL_TASK_BY_TEMPALTE_ID:
            return {...state, taskByTemplateId: payload}
        case TEMPLATE_ACTION_TYPES.GET_TASK_BY_TEMPALTE_ID:
            return {...state, updateTaskByTemplateId: payload}
        case TEMPLATE_ACTION_TYPES.CLEAR_ALL_DATA:
            return INITIAL_STATE;
        case TEMPLATE_ACTION_TYPES.CLEAR_TASK_BY_TEMPLATE_ID:
            return {...state, updateTaskByTemplateId: null}
        case TEMPLATE_ACTION_TYPES.REMOVE_TASK:
            return {...state, taskByTemplateId: null}
        default:
            return {...state}
    }
}