import React from "react";
import HeaderPage from "../header/HeaderPage";
import {ASSET_ACTION_TYPE} from "../../utils/constant/constants";
import ReportRadio from "../report/asset/ReportRadio";
import ReportAntenna from "../report/asset/ReportAntenna";
import ReportTower from "../report/asset/ReportTower";
import ReportPABX from "../report/asset/ReportPABX";
import ReportSupporting from "../report/asset/ReportSupporting";

export const ModalForm = ({
                            title,
                            type,
                            children,
                            onClose,
                            onSave,
                            open = false,
                            btnCloseTitle = "Close",
                            btnSubmitTitle = "Apply",
                            size = "w-96",
                            closeBtn = false,
                            selectedData,
                            selectedImage,
                            actionType,
                            btnCreateReport = "Create Report",
                            btn = true
                        }) => {

    const RenderReport = ({ type, selectedData, selectedImage, btnCreateReport }) => {
        switch (type) {
            case "radio":
                return <ReportRadio
                            selectedData={selectedData}
                            selectedImage={selectedImage}
                            btnCreateReport={btnCreateReport}
                        />
            case "antenna":
                return <ReportAntenna
                            selectedData={selectedData}
                            selectedImage={selectedImage}
                            btnCreateReport={btnCreateReport}
                        />
            case "tower":
                return <ReportTower
                            selectedData={selectedData}
                            selectedImage={selectedImage}
                            btnCreateReport={btnCreateReport}
                        />
            case "pabx":
                return <ReportPABX
                            selectedData={selectedData}
                            selectedImage={selectedImage}
                            btnCreateReport={btnCreateReport}
                        />
            case "supp":
                return <ReportSupporting
                            selectedData={selectedData}
                            selectedImage={selectedImage}
                            btnCreateReport={btnCreateReport}
                        />
            default:
                return "";
            }
    };
    return (
        <>
            {open && (
                <div
                    className="modalClass flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/40">
                    <div className={`relative my-6 mx-auto max-w-5xl ${size}`}>
                        <div
                            className="bg-tertiary relative w-11/12 max-w-5xl max-h-[800px] rounded-lg text-white border-2 border-white px-5 pb-3 scale-in-center mt-20">
                            {!!closeBtn && (
                                <button
                                    className="btn btn-sm btn-circle absolute right-2 top-2"
                                    onClick={onClose}
                                >
                                    ✕
                                </button>
                            )}
                            {!!title && (
                                <div className="flex justify-center pb-3">
                                    <HeaderPage title={title} icon={type}/>
                                </div>
                            )}
                            <form className="text-white" onSubmit={onSave}>
                                <div className="relative p-4 flex-auto">{children}</div>
                                {btn && (
                                    <div className="flex items-center justify-start px-4 py-6 gap-x-4">
                                        <button
                                            type="button"
                                            onClick={onClose}
                                            className="btn btn-outline text-white"
                                        >
                                            {btnCloseTitle}
                                        </button>

                                        {ASSET_ACTION_TYPE.DETAIL === actionType ? (
                                            <RenderReport
                                                type={type}
                                                selectedData={selectedData}
                                                selectedImage={selectedImage}
                                                btnCreateReport={btnCreateReport}
                                            />
                                        ) : (
                                            <button
                                                type="submit"
                                                className="btn btn-primary text-white min-w-[100px]"
                                            >
                                                {btnSubmitTitle}
                                            </button>
                                        )}
                                    </div>
                                )}
                            </form>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
