import {DEFAULT_SIZE_REQUEST, USER_ACTION_TYPES} from "./user.types";
import {createAction} from "../../utils/reducer/reducerUtil";
import {login} from "../../utils/axios/login";
import {CREATE_USER, DELETE_USER, GET_ALL_USER, GET_USER_BY_ID, UPDATE_USER} from "../../services/user";
import {EMPTY_STRING, FAILED, SUCCESS} from "../admin/admin.types";
import { handleLoading } from "../global/global.action";
import { GLOBAL_ACTION_TYPES } from "../global/global.types";
import { STATUS_TYPE } from "../../utils/constant/constants";
import {PLATFORM} from "../../utils/constant/constants";

export const signInSucess = (res) => (dispatch) => {
    dispatch(createAction(USER_ACTION_TYPES.SIGN_IN_SUCCESS, res));
}

export const signInFailed = (errMessage) => (dispatch) => {
    dispatch(createAction(USER_ACTION_TYPES.SIGN_IN_FAILED, {isError: true, errorMessage: errMessage}));
}

export const removeError = () => (dispatch) => {
    dispatch(createAction(USER_ACTION_TYPES.REMOVE_ERROR));
}

export const startSignIn = () => (dispatch) => {
    dispatch(createAction(USER_ACTION_TYPES.SIGN_IN_START));
}

export const handleSignIn = (form, navigate) => async (dispatch) => {
    dispatch(startSignIn())
    const res = await login(form);
    if (res.isAuthed) {
        if (res.data.siteName === PLATFORM || PLATFORM === "ALL") {
            dispatch(signInSucess(res.data));
            navigate("/");
        } else {
            const errMessage = {
                error: true,
                message: `This Platform for ${PLATFORM}, Please use ${PLATFORM} User to Login`
            }
            localStorage.removeItem("profile");
            localStorage.removeItem("accesstoken_nccsa");
            dispatch(signInFailed(errMessage))
        }
    } else {
        const errMessage = {
            error: true,
            message: "Invalid Username or Password"
        }
        localStorage.removeItem("profile");
        localStorage.removeItem("accesstoken_nccsa");
        dispatch(signInFailed(errMessage))
    }
}

export const checkUserSession = (navigate) => (dispatch) => {
    const payload = {
        active: false,
    }
    if (localStorage.getItem('accesstoken_nccsa') &&
        localStorage.getItem('profile')) {

        payload.active = true
        payload.profile = JSON.parse(localStorage.getItem('profile'))
        dispatch((createAction(USER_ACTION_TYPES.CHECK_USER_SESSION, payload)));
        dispatch(handleLoading(false));
    } else {
        dispatch((createAction(USER_ACTION_TYPES.CHECK_USER_SESSION, payload)));
        dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        navigate("/login", { replace: true });
    }
}

export const handleCreateUser = (bodyParam) => dispatch => {
    CREATE_USER(bodyParam)
        .then(res => {
            dispatch(createAction(USER_ACTION_TYPES.CREATE_USER, res));
        })
        .catch(e => {
            dispatch(createAction(USER_ACTION_TYPES.CREATE_USER, "ERROR"));
        });
}

export const handleResetCreatedUserData = () => dispatch => {
    dispatch(createAction(USER_ACTION_TYPES.RESET_CREATE_USER_DATA));
}

export const handleUpdateUser = (userId, payload) => dispatch => {
    UPDATE_USER(userId, payload)
        .then(() => {
            dispatch(createAction(USER_ACTION_TYPES.UPDATE_USER, SUCCESS));
        })
        .catch(e => {
            dispatch(createAction(USER_ACTION_TYPES.UPDATE_USER, FAILED));
        })
}

export const handleResetUpdateUser = () => dispatch => {
    dispatch(createAction(USER_ACTION_TYPES.UPDATE_USER, EMPTY_STRING));
}

export const handleChangeCurrentUserName = (currentUser, name) => dispatch => {
    const updatedUser = {...currentUser};
    updatedUser.name = name;
    dispatch(createAction(USER_ACTION_TYPES.CHANGED_NAME, updatedUser));
}

export const resetUserReducer = () => dispatch => {
    dispatch(createAction(USER_ACTION_TYPES.RESET_REDUCER));
}


export const handleFetchUserData = (page, q, siteId) => dispatch => {
    GET_ALL_USER(page, DEFAULT_SIZE_REQUEST, q, siteId)
        .then(res => {
            dispatch(createAction(USER_ACTION_TYPES.GET_ALL_USER, res));
            dispatch(handleLoading(false));
        })
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED));
        });
}

export const handleDeleteUserById = (userId) => dispatch => {
    DELETE_USER(userId)
        .then(() => {
            dispatch(createAction(USER_ACTION_TYPES.REMOVE_USER_STATUS, SUCCESS));
        })
        .catch(e => {
            dispatch(createAction(USER_ACTION_TYPES.REMOVE_USER_STATUS, FAILED));
        });
}

export const handleResetDeleteUserStatus = () => dispatch => {
    dispatch(createAction(USER_ACTION_TYPES.REMOVE_USER_STATUS, EMPTY_STRING));
}

export const handleGetUserById = (id) => dispatch => {
    GET_USER_BY_ID(id)
        .then(res => {
            dispatch(createAction(USER_ACTION_TYPES.GET_A_USER, res));
        })
        .catch(() => {})
}

export const handleClearAUserData = () => dispatch => {
    dispatch(createAction(USER_ACTION_TYPES.CLEAR_A_USER_DATA));
}

export const handleClearAllUserData = () => dispatch => {
    dispatch(createAction(USER_ACTION_TYPES.CLEAR_ALL_USER_DATA));
}

export const handleClearUpdateUserStatus = () => dispatch => {
    dispatch(createAction(USER_ACTION_TYPES.CLEAR_UPDATE_USER_STATUS));
}