import {
    CREATE_MAINTENANCE,
    CREATE_MAINTENANCE_HISTORY,
    CREATE_MAINTENANCE_TASK,
    DELETE_MAINTENANCE,
    GET_ALL_MAINTENANCE,
    GET_MAINTENANCE_BY_ID,
    GET_MAINTENANCE_TASK_BY_ID,
    UPDATE_MAINTENANCE,
    UPDATE_MAINTENANCE_TASK
} from "../../services/maintenance";
import {createAction} from "../../utils/reducer/reducerUtil";
import {MAINTENANCE_ACTION_TYPES} from "./maintenance.types";
import {GET_RADIO_BY_ID, SEARCH_RADIO} from "../../services/radio";
import {GET_ALL_ANTENA, GET_ANTENNA_BY_ID} from "../../services/antena";
import {GET_ALL_PABX, GET_PABX_BY_ID} from "../../services/pabx";
import {GET_ALL_DSS, GET_DSS_BY_ID} from "../../services/dss";
import {GET_ALL_TOWER_SEARCH, GET_TOWER_BY_ID} from "../../services/tower";
import {GET_ALL_SITE} from "../../services/site";
import {handleClearStateGlobal, handleLoading} from "../global/global.action";
import {MAINTENANCE_STATUS, MAINTENANCE_TYPE, PROCESS_TYPE, STATUS_TYPE} from "../../utils/constant/constants";
import {TEMPLATE_ACTION_TYPES} from "../template/template.types";
import { GLOBAL_ACTION_TYPES } from "../global/global.types";
import {EMPTY_STRING} from "../admin/admin.types";

const handleCreateMaintenanceTask = (taskBody, id) => {
    CREATE_MAINTENANCE_TASK(taskBody, id)
        .then(() => {})
        .catch(() => {})
};

export const handleSelectMaintenanceType = (maintenanceType, maintenanceTypeValue) => (dispatch) => {
    dispatch(createAction(MAINTENANCE_ACTION_TYPES.SELECTED_MAINTENANCE_TYPE, {
        type: maintenanceType.toLowerCase(),
        valueType: maintenanceTypeValue
    }));
}

export const handleCollectAssetData = (id, assetType) => async (dispatch) => {
    switch (assetType.toLowerCase()) {
        case `radio`:
            await GET_RADIO_BY_ID(id)
                .then(res => dispatch(createAction(MAINTENANCE_ACTION_TYPES.SELECTED_ASSET_DATA, res)))
                .catch(() => {});
            break;
        case `antenna`:
            await GET_ANTENNA_BY_ID(id)
                .then(res => dispatch(createAction(MAINTENANCE_ACTION_TYPES.SELECTED_ASSET_DATA, res)))
                .catch(() => {});
            break;
        case `pabx`:
            await GET_PABX_BY_ID(id)
                .then(res => dispatch(createAction(MAINTENANCE_ACTION_TYPES.SELECTED_ASSET_DATA, res)))
                .catch(() => {});
            break;
        case `dss`:
            await GET_DSS_BY_ID(id)
                .then(res => dispatch(createAction(MAINTENANCE_ACTION_TYPES.SELECTED_ASSET_DATA, res)))
                .catch(() => {});
            break;
        case `tower`:
            await GET_TOWER_BY_ID(id)
                .then(res => dispatch(createAction(MAINTENANCE_ACTION_TYPES.SELECTED_ASSET_DATA, res)))
                .catch(() => {});
            break;
        default:
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.SELECTED_ASSET_DATA, null));
    }
}

export const handleSaveAssetType = (assetType) => (dispatch) => {
    dispatch(createAction(MAINTENANCE_ACTION_TYPES.SELECTED_ASSET_TYPE, assetType.toLowerCase()));
}

export const handleGetSite = () => async (dispatch) => {
    await GET_ALL_SITE()
        .then(res => dispatch(createAction(MAINTENANCE_ACTION_TYPES.GET_SITE, res)))
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        });
}

export const handleSearchAsset = (searchText, assetType, siteId, page) => async (dispatch) => {
    switch (assetType.toLowerCase()) {
        case `radio`:
            await SEARCH_RADIO(searchText, "name", page, 10, siteId)
                .then(res => {
                    dispatch(createAction(MAINTENANCE_ACTION_TYPES.GET_ALL_ASSET, res.radio))
                    dispatch(handleLoading(false));
                })
                .catch(() => {});
            break;
        case `antenna`:
            await GET_ALL_ANTENA(page, 10, searchText, siteId)
                .then(res => {
                    dispatch(createAction(MAINTENANCE_ACTION_TYPES.GET_ALL_ASSET, res.antenna))
                    dispatch(handleLoading(false));
                })
                .catch(() => {});
            break;
        case `pabx`:
            await GET_ALL_PABX(page, 10, searchText, siteId)
                .then(res => {
                    dispatch(createAction(MAINTENANCE_ACTION_TYPES.GET_ALL_ASSET, res.pabx))
                    dispatch(handleLoading(false));
                })
                .catch(() => {});
            break;
        case `dss`:
            await GET_ALL_DSS(page, 10, searchText, siteId)
                .then(res => {
                    dispatch(createAction(MAINTENANCE_ACTION_TYPES.GET_ALL_ASSET, res.dss))
                    dispatch(handleLoading(false));
                })
                .catch(() => {});
            break;
        case `tower`:
            await GET_ALL_TOWER_SEARCH(page, 10, searchText, siteId)
                .then(res => {
                    dispatch(createAction(MAINTENANCE_ACTION_TYPES.GET_ALL_ASSET, res.tower))
                    dispatch(handleLoading(false));
                })
                .catch(() => {});
            break;
        default:
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.GET_ALL_ASSET, null));
    }
}

export const handleResetAllAsset = () => dispatch => {
    dispatch(createAction(MAINTENANCE_ACTION_TYPES.RESET_ALL_ASSET_TABLE));
}
export const handleGetAssetById = (assetType, assetId) => async (dispatch) => {
    switch (assetType.toLowerCase()) {
        case `radio`:
            await GET_RADIO_BY_ID(assetId)
                .then(res => dispatch(createAction(MAINTENANCE_ACTION_TYPES.ASSET_DATA, {res, type: `radio`})))
                .catch(() => {})
            break;
        case `antenna`:
            await GET_ANTENNA_BY_ID(assetId)
                .then(res => dispatch(createAction(MAINTENANCE_ACTION_TYPES.ASSET_DATA, {res, type: `antenna`})))
                .catch(() => {});
            break;
        case `pabx`:
            await GET_PABX_BY_ID(assetId)
                .then(res => dispatch(createAction(MAINTENANCE_ACTION_TYPES.ASSET_DATA, {res, type: `pabx`})))
                .catch(() => {});
            break;
        case `dss`:
            await GET_DSS_BY_ID(assetId)
                .then(res => dispatch(createAction(MAINTENANCE_ACTION_TYPES.ASSET_DATA, {res, type: `dss`})))
                .catch(() => {});
            break;
        case `tower`:
            await GET_TOWER_BY_ID(assetId)
                .then(res => dispatch(createAction(MAINTENANCE_ACTION_TYPES.ASSET_DATA, {res, type: `tower`})))
                .catch(() => {});
            break;
        default:
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.ASSET_DATA, null))
    }
}

export const handleGetAllMaintenanceData = (maintenanceType, q, type, page, size = 10, mType) => async (dispatch) => {
    await GET_ALL_MAINTENANCE(q, type, page, size, mType)
        .then(res => {

            if (MAINTENANCE_TYPE.DATA === maintenanceType) {
                dispatch(createAction(MAINTENANCE_ACTION_TYPES.GET_ALL, res));
            } else if (MAINTENANCE_TYPE.SCHEDULE === maintenanceType) {
                dispatch(createAction(MAINTENANCE_ACTION_TYPES.GET_ALL_SCHEDULE, res));
                dispatch(handleLoading(false));
            } else if (MAINTENANCE_TYPE.HISTORY === maintenanceType) {
                dispatch(createAction(MAINTENANCE_ACTION_TYPES.GET_ALL_HISTORY, res));
            }

            dispatch(handleLoading(false));

            if (res.content.length > 0) {
                const content = res.content;
                for (let i = 0; i < content.length; i++) {
                    dispatch(handleGetAssetById(content[i].assetType, content[i].assetId));
                }
            }
        })
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        });
}

export const handleGetAllMaintenanceDataReport = (maintenanceType, q, type, page, size = 10, mType) => async (dispatch) => {
    await GET_ALL_MAINTENANCE(q, type, page, size, mType)
        .then(res => {

            if (MAINTENANCE_TYPE.DATA === maintenanceType) {
                dispatch(createAction(MAINTENANCE_ACTION_TYPES.GET_ALL_REPORT, res));
            } else if (MAINTENANCE_TYPE.HISTORY === maintenanceType) {
                dispatch(createAction(MAINTENANCE_ACTION_TYPES.GET_ALL_HISTORY_REPORT, res));
            }

            dispatch(handleLoading(false));

            if (res.content.length > 0) {
                const content = res.content;
                for (let i = 0; i < content.length; i++) {
                    dispatch(handleGetAssetById(content[i].assetType, content[i].assetId));
                }
            }
        })
        .catch(e => {
            dispatch(createAction(GLOBAL_ACTION_TYPES.NOTIFY_NOT_CONNECTED_TO_BACKEND, STATUS_TYPE.FAILED))
        });
}

export const handleClearMaintenanceData = () => (dispatch) => {
    dispatch(createAction(MAINTENANCE_ACTION_TYPES.CLEAR_DATA));
}


export const handleCreateMaintenance = (body, navigate, type, taskBody) => (dispatch) => {

    CREATE_MAINTENANCE(body)
        .then(res => {
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.CREATE_MAINTENANCE, res));
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.CREATE_STATUS, STATUS_TYPE.SUCCESS));
            dispatch(handleLoading(false));
            if (MAINTENANCE_TYPE.DATA === type) {
                if (body.status === MAINTENANCE_STATUS.DONE) {
                    dispatch(handleSendToLog(res.id));
                }
                if (taskBody) {
                    handleCreateMaintenanceTask(taskBody, res.id);
                }
                navigate("/assetdata/maintenance-data");
            } else if (MAINTENANCE_TYPE.SCHEDULE === type) {
                navigate("/assetdata/maintenance-schedule");
            }
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.DONE_CREATE_OR_UPDATE, PROCESS_TYPE.SUCCESS));
        })
        .catch(e => {
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.CREATE_STATUS, STATUS_TYPE.FAILED));
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.DONE_CREATE_OR_UPDATE, PROCESS_TYPE.FAILED));
        })
}

export const handleUpdateMaintenance = (id, body, navigate, mType, taskBody, fromSchedule = false, updateWithNewTask = null) => async (dispatch) => {
    await UPDATE_MAINTENANCE(id, body)
        .then(res => {
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.UPDATE_STATUS, STATUS_TYPE.SUCCESS));
            dispatch(handleLoading(false));
            if (MAINTENANCE_TYPE.DATA === mType) {
                if (body.status === MAINTENANCE_STATUS.DONE) {
                    dispatch(handleSendToLog(res.id));
                }
                if (taskBody) {
                    if (fromSchedule) {
                        handleCreateMaintenanceTask(taskBody, id);
                    } else {

                        let sameTask = false;
                        if (taskBody.length === updateWithNewTask.length) {
                            for (let i = 0; i < taskBody.length; i++) {
                                if (taskBody[i].id === updateWithNewTask[i].id) {
                                    sameTask = true;
                                } else {
                                    sameTask = false;
                                    break;
                                }
                            }
                        }

                        if (updateWithNewTask && !sameTask) {
                            handleCreateMaintenanceTask(taskBody, id);
                        } else {
                            UPDATE_MAINTENANCE_TASK(id, taskBody)
                                .then(() => {})
                                .catch(() => {});
                        }
                    }
                }
                navigate("/assetdata/maintenance-data");
            } else if (MAINTENANCE_TYPE.SCHEDULE === mType) {
                navigate("/assetdata/maintenance-schedule");
            }
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.DONE_CREATE_OR_UPDATE, PROCESS_TYPE.SUCCESS));
        })
        .catch(e => {
            dispatch(handleLoading(false));
            if (MAINTENANCE_TYPE.DATA === mType) {
                navigate("/assetdata/maintenance-data");
            } else if (MAINTENANCE_TYPE.SCHEDULE === mType) {
                navigate("/assetdata/maintenance-schedule");
            }
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.UPDATE_STATUS, STATUS_TYPE.FAILED));
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.ERROR_MESSAGE, e.message));
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.DONE_CREATE_OR_UPDATE, PROCESS_TYPE.FAILED));
        });
}

export const handleSendToLog = (maintenanceId) => async (dispatch) => {
    await CREATE_MAINTENANCE_HISTORY(maintenanceId)
        .then(res => {
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.SEND_TO_LOG_STATUS, STATUS_TYPE.SUCCESS));
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.SEND_TO_LOG, res));
            dispatch(handleClearStateGlobal());
        })
        .catch(e => {
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.SEND_TO_LOG_STATUS, STATUS_TYPE.FAILED));
            dispatch(handleClearStateGlobal());
        });
}

export const handleClearNotif = () => dispatch => {
    dispatch(createAction(MAINTENANCE_ACTION_TYPES.UPDATE_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(MAINTENANCE_ACTION_TYPES.CREATE_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(MAINTENANCE_ACTION_TYPES.REMOVE_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(MAINTENANCE_ACTION_TYPES.SEND_TO_LOG_STATUS, STATUS_TYPE.NO_STATEMENT));
    dispatch(createAction(MAINTENANCE_ACTION_TYPES.ERROR_MESSAGE, EMPTY_STRING));

}

export const handleRemoveMaintenanceData = (mType, maintenanceId, type, page) => async (dispatch) => {
    await DELETE_MAINTENANCE(maintenanceId)
        .then(() => {
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.REMOVE_STATUS, STATUS_TYPE.SUCCESS));
            if (MAINTENANCE_TYPE.DATA === mType) {
                dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.DATA, "", type, page, 10, mType));
            } else if (MAINTENANCE_TYPE.SCHEDULE === mType || MAINTENANCE_TYPE.HISTORY) {
                dispatch(handleGetAllMaintenanceData(MAINTENANCE_TYPE.SCHEDULE, "", type, page, 10, mType));
            }
            dispatch(handleClearStateGlobal());
        })
        .catch(e => {
            dispatch(createAction(MAINTENANCE_ACTION_TYPES.REMOVE_STATUS, STATUS_TYPE.FAILED));
            dispatch(handleClearStateGlobal());
        });
}

export const handleEditMaintenanceData = (mType, go, selectedItem, navigate, scheduleTask = false, detailHistory = false) => async (dispatch) => {
    if (selectedItem) {
        GET_MAINTENANCE_BY_ID(selectedItem.id)
            .then(res => {
                dispatch(handleSaveAssetType(res.assetType)); // set asset type
                dispatch(handleCollectAssetData(res.assetId, res.assetType)); // get asset data
                const maintenanceType = res.preventive ? `preventive` : `corrective`;
                const maintenanceTypeValue = res.preventive ? res.preventive : res.corrective;
                dispatch(handleSelectMaintenanceType(maintenanceType, maintenanceTypeValue)); // set mainetnance type
                dispatch(createAction(MAINTENANCE_ACTION_TYPES.HANDLE_EDIT, {go, res}));
                dispatch(createAction(MAINTENANCE_ACTION_TYPES.START_CREATE_OR_UPDATE, PROCESS_TYPE.UPDATE));
                if (MAINTENANCE_TYPE.DATA === mType) {
                    GET_MAINTENANCE_TASK_BY_ID(selectedItem.id)
                        .then(res => {
                            if (scheduleTask) {
                                const copyRes = [...res, {maintenanceDataFromSchedule: true}]
                                dispatch(createAction(TEMPLATE_ACTION_TYPES.GET_TASK_BY_TEMPALTE_ID, copyRes));
                            } else if (detailHistory) {
                                const copyRes = [...res, {maintenanceDataFromHistory: true}]
                                dispatch(createAction(TEMPLATE_ACTION_TYPES.GET_TASK_BY_TEMPALTE_ID, copyRes));
                            } else {
                                dispatch(createAction(TEMPLATE_ACTION_TYPES.GET_TASK_BY_TEMPALTE_ID, res));
                            }
                            navigate(`/assetdata/maintenance-data/create-maintenance`);
                        })
                        .catch(() => {})
                } else if (MAINTENANCE_TYPE.SCHEDULE === mType) {
                    navigate(`/assetdata/maintenance-schedule/type-schedule`);
                }
                dispatch(handleClearStateGlobal());
            })
            .catch(e => {
                dispatch(createAction(MAINTENANCE_ACTION_TYPES.HANDLE_EDIT, {go: false, res: e}));
                dispatch(handleClearStateGlobal());
            })
    }
};

export const handleNewMaintenance = () => (dispatch) => {
    dispatch(createAction(MAINTENANCE_ACTION_TYPES.START_CREATE_OR_UPDATE, PROCESS_TYPE.START));
}
